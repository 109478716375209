import React, { useState, useEffect, useCallback } from 'react';
import jwt_decode from 'jwt-decode';
import { getUserDetails } from '../components/Layout/api/accountapi';
import navigate from './history';

let logoutTimer;

const AuthContext = React.createContext({
  token: '',
  isLoggedIn: false,
  authRole: '',
  login: (token) => {},
  logout: () => {},
  hasAuth: (roleName) => {},
});

const calculateRemainingTime = (expirationTime) => {
  const currentTime = new Date().getTime();

  const remainingDuration = expirationTime - currentTime;
  return remainingDuration;
};

const userData = async () => {
  const accountInfo = await getUserDetails();

  localStorage.setItem('accountInfo', JSON.stringify(accountInfo));
};

const getAuthority = (token) => {
  var decoded = jwt_decode(token);
  console.log('decoded:', decoded);
  return decoded.auth;
};

const retrieveStoredToken = () => {
  const storedToken = localStorage.getItem('token');
  const storedExpirationDate = localStorage.getItem('expirationTime');

  const remainingTime = calculateRemainingTime(storedExpirationDate);

  if (remainingTime <= 3600) {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationTime');
    localStorage.removeItem('ntu');
    // navigate.push('/login');
    return null;
  }

  return {
    token: storedToken,
    duration: remainingTime,
  };
};

export const AuthContextProvider = (props) => {
  const tokenData = retrieveStoredToken();

  let initialToken;
  let authorizationRole;
  if (tokenData) {
    initialToken = tokenData.token;
    var decoded = jwt_decode(initialToken);
    console.log(decoded);
    authorizationRole = decoded.auth;
  }

  const [token, setToken] = useState(initialToken);

  const userIsLoggedIn = !!token;
  const [authRole, setAuthRole] = useState(authorizationRole);

  const logoutHandler = useCallback(() => {
    setToken(null);
    localStorage.removeItem('token');
    localStorage.removeItem('expirationTime');
    localStorage.setItem('ntu', '');
    localStorage.setItem('subMenuItem', '');
    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
  }, []);

  const authHandler = (roleName) => {
    let rolefound = false;
    if (roleName.length > 0) {
      roleName.forEach((element) => {
        if (authRole.indexOf(element) > -1) {
          rolefound = true;
        } else rolefound = false;
      });
    }
    return rolefound;
  };
  const loginHandler = async (data, expirationTime) => {
    localStorage.setItem('token', data);
    localStorage.setItem('expirationTime', expirationTime);
    const accountInfo = await getUserDetails();

    localStorage.setItem('accountInfo', JSON.stringify(accountInfo));
    // get account info - and set localStorage.

    const authority = getAuthority(data);
    setAuthRole(authority);
    const remainingTime = calculateRemainingTime(expirationTime);
    logoutTimer = setTimeout(logoutHandler, remainingTime);
    setToken(data);
  };

  useEffect(() => {
    if (tokenData) {
      //console.log(tokenData.duration);
      logoutTimer = setTimeout(logoutHandler, tokenData.duration);
    }
  }, [tokenData, logoutHandler]);

  const contextValue = {
    token: token,
    isLoggedIn: userIsLoggedIn,
    authRole: authRole,
    login: loginHandler,
    logout: logoutHandler,
    hasAuth: authHandler,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
