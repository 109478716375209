import React, { useEffect, useState, Fragment } from 'react';
import { Routes, Route, useParams, useLocation, Link } from 'react-router-dom';

import EmailAddresses from './EmailAddresses';
import EmailAddressDetail from './EmailAddressDetail';
import EmailAddressUpdate from './EmailAddressUpdate';
// import TenantDelete from "./TenantDeleteDialog";
import { getEmailAddresses } from './api/emailAddressesApi';

const EmailAddressesContainer = () => {
  const location = useLocation();
  const [entities, setEntities] = useState([]);
  const [appState, setAppState] = useState({
    loading: false,
    err: false,
  });

  useEffect(() => {
    (async () => {
      try {
        setAppState({ loading: true, err: false });
        const res = await getEmailAddresses().then((res) => res.json());
        console.log(res);
        setAppState({ loading: false });
        setEntities(res);
      } catch (err) {
        setAppState({ loading: false, err: true });
      }
    })();
  }, [location.state]);

  return (
    <Fragment>
      <ol className="breadcrumb page-breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">ActivePort</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to="/administration">Administration</Link>
        </li>
        <li className="breadcrumb-item active">Email Setup</li>
        <li className="position-absolute pos-top pos-right d-none d-sm-block">
          <span className="js-get-date"></span>
        </li>
      </ol>
      <div className="subheader">
        <h1 className="subheader-title">
          <i className="subheader-icon fal fa-table"></i> Email Setup
          <small>
            Create headache free searching, sorting and pagination tables
            without any complex configuration
          </small>
        </h1>
      </div>
      {!appState.loading && !appState.err && (
        <Routes>
          <Route exact path={`/new`} element={EmailAddressUpdate} />
          <Route exact path={`:id/edit`} element={EmailAddressUpdate} />
          <Route exact path={`:id`} element={EmailAddressDetail} />

          <Route path={`/`} element={<EmailAddresses entities={entities} />} />
        </Routes>
      )}
      {appState.loading && !appState.error && (
        <div className="border p-3">
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      )}
      {!appState.loading && appState.err && (
        <div className="alert alert-danger" role="alert">
          <strong>Oh snap!</strong> We are not able to connect to the database.
          Try again in few minutes. again.
        </div>
      )}
      {/* <Route
          exact
          path={`/:id/delete`}
          element={TenantDelete}
        /> */}
    </Fragment>
  );
};

export default EmailAddressesContainer;
