export const getSDWANAggregators = () => {
  const token = localStorage.getItem("token");
  const baseUrl =
    "https://test.activeport.com.au/api/aggregators?size=99999999";

  return fetch(baseUrl, {
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getSDWANAggregator = (id) => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/api/aggregators/";

  const requestUrl = `${baseUrl}${id}`;
  return fetch(requestUrl, {
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const createSDWANAggregator = (sdwanAggregator) => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/api/aggregators";

  return fetch(baseUrl, {
    method: "POST",
    body: JSON.stringify(sdwanAggregator),
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const updateSDWANAggregator = (sdwanAggregator) => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/api/aggregators/";

  return fetch(baseUrl, {
    method: "PUT",
    body: JSON.stringify(sdwanAggregator),
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const deleteSDWANAggregator = (id) => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/api/aggregators/";
  const requestUrl = `${baseUrl}${id}`;
  return fetch(requestUrl, {
    method: "DELETE",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getPEForDropdown = () => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/api/central-switches";
  return fetch(baseUrl, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};
