import React, { useState, useEffect } from 'react';
import Panel from '../../../common/Panel/Panel';
import DrawGraph from './DrawGraph';
import { getElasticSearchData } from './api/elasticsearch';
import moment from 'moment';
import tz from 'moment-timezone';
import RepDataTable from './RepDataTable';
import PublicRepDataTable from './PublicRepDataTable';
import RiskDataTable from './RiskDataTable';
import BigRepsDataTable from './BigRepsDataTable';
import threats_ip_reputations_bar from '../../../common/graphs/vega/applications/threats_ip_reputations_bar.json';
import threats_ip_reputations_bar_request from '../../../common/graphs/vega/applications/threats_ip_reputations_bar_request.json';

import threats_ip_reputations_table_request from '../../../common/graphs/vega/applications/threats_ip_reputations_table_request.json';
import threats_ip_reputations_public_table_request from '../../../common/graphs/vega/applications/threats_ip_reputations_public_table_request.json';
import threats_ip_reputations_big_table_request from '../../../common/graphs/vega/applications/threats_ip_reputations_big_table_request.json';
import threats_ip_reputations_at_risk_request from '../../../common/graphs/vega/applications/threats_ip_reputations_at_risk_request.json';

const Threats = ({ lte, gte, ntu }) => {
  var serial;

  const [isLoading, setIsLoading] = useState(true);
  const [isLoading1, setIsLoading1] = useState(false);

  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const [isLoading4, setIsLoading4] = useState(false);
  const [repData, setRepData] = useState({});
  const [repPublicData, setRepPublicData] = useState({});
  const [riskData, setRiskData] = useState({});
  const [repBigData, setRepBigData] = useState({});
  const [threats_ip_reputations_bar_data, set_threats_ip_reputations_bar_data] =
    useState({});
  var ignore_throttled = 1;
  const getLTE = () => {
    if (lte == null || lte == '') {
      lte = new Date();
      lte = moment();
      lte = lte._d.getTime();
    }
    return lte;
  };
  const getGTE = () => {
    if (gte == null || gte == '') {
      gte = new Date();
      gte = moment().subtract(3, 'hours');
      gte = gte._d.getTime();
    }
    return gte;
  };

  const getIgnoreThrottled = () => {
    var now = new Date();
    //var lte_diff = moment(now).diff(moment(vm.lte), 'days')
    var gte_diff = moment(now).diff(moment(getGTE()), 'months');
    //             console.log("lte_diff = " + lte_diff);
    console.log('gte_diff = ' + gte_diff);
    if (gte_diff > 0) {
      ignore_throttled = 0; // searchable snapshot
    }
    return ignore_throttled;
  };
  const TableReps = async () => {
    serial = ntu.serialNumber;
    if (ntu.mode == 'VIRTUAL') {
      serial = ntu.peSerialNumber;
    }
    threats_ip_reputations_table_request.query.bool.filter.push({
      term: { 'serial-number.keyword': ntu.serialNumber },
    });
    //data.query.bool.must[0].query_string["query"] = "serial-number: " + ntu.serialNumber;
    threats_ip_reputations_table_request.query.bool.must[0].range[
      '@timestamp'
    ].gte = getGTE();
    threats_ip_reputations_table_request.query.bool.must[0].range[
      '@timestamp'
    ].lte = getLTE();

    try {
      let response = await getElasticSearchData('search', {
        ignoreThrottled: ignore_throttled,
        index: 'apptrack-*',
        size: 0,

        body: JSON.stringify(threats_ip_reputations_table_request),
      }).then((res) => res.json());
      console.log(response);

      setRepData(response.aggregations.results.buckets);

      setIsLoading1(true);
    } catch (error) {
      console.log(error);
    }
  };
  const TablePublicReps = async () => {
    serial = ntu.serialNumber;
    if (ntu.mode == 'VIRTUAL') {
      serial = ntu.peSerialNumber;
    }
    threats_ip_reputations_public_table_request.query.bool.filter.push({
      term: { 'serial-number.keyword': ntu.serialNumber },
    });
    threats_ip_reputations_public_table_request.query.bool.must_not.push({
      term: { 'src_geoip.keyword': 'private' },
    });
    //data.query.bool.must[0].query_string["query"] = "_exists_:flow.rep_tags AND !flow.src_autonomous_system:private";
    threats_ip_reputations_public_table_request.query.bool.must[0].range[
      '@timestamp'
    ].gte = getGTE();
    threats_ip_reputations_public_table_request.query.bool.must[0].range[
      '@timestamp'
    ].lte = getLTE();

    try {
      let response = await getElasticSearchData('search', {
        ignoreThrottled: ignore_throttled,
        index: 'apptrack-*',
        size: 0,

        body: JSON.stringify(threats_ip_reputations_public_table_request),
      }).then((res) => res.json());
      console.log('Public Rep', response);
      setRepPublicData(response.aggregations.results.buckets);
      setIsLoading2(true);
    } catch (error) {
      console.log(error);
    }
  };
  const TableRisk = async () => {
    serial = ntu.serialNumber;
    if (ntu.mode == 'VIRTUAL') {
      serial = ntu.peSerialNumber;
    }
    threats_ip_reputations_at_risk_request.query.bool.filter.push({
      term: { 'serial-number.keyword': ntu.serialNumber },
    });
    //data.query.bool.must[0].query_string["query"] = "flow.dst_autonomous_system:private AND _exists_:flow.rep_tags";
    threats_ip_reputations_at_risk_request.query.bool.must[0].range[
      '@timestamp'
    ].gte = getGTE();
    threats_ip_reputations_at_risk_request.query.bool.must[0].range[
      '@timestamp'
    ].lte = getLTE();

    try {
      let response = await getElasticSearchData('search', {
        ignoreThrottled: ignore_throttled,
        index: 'apptrack-*',
        size: 0,

        body: JSON.stringify(threats_ip_reputations_at_risk_request),
      }).then((res) => res.json());
      console.log(response);

      setRiskData(response.aggregations.results.buckets);
      setIsLoading3(true);
    } catch (error) {
      console.log(error);
    }
  };
  const TableBigReps = async () => {
    threats_ip_reputations_big_table_request.query.bool.must[0].query_string[
      'query'
    ] = '_exists_:rep_tags';
    threats_ip_reputations_big_table_request.query.bool.filter.push({
      term: { 'serial-number.keyword': ntu.serialNumber },
    });
    //data.query.bool.must[0].query_string["query"] = "_exists_:flow.rep_tags";
    threats_ip_reputations_big_table_request.query.bool.must[1].range[
      '@timestamp'
    ].gte = getGTE();
    threats_ip_reputations_big_table_request.query.bool.must[1].range[
      '@timestamp'
    ].lte = getLTE();

    try {
      let response = await getElasticSearchData('search', {
        ignoreThrottled: 1,
        index: 'apptrack-*',
        size: 0,

        body: JSON.stringify(threats_ip_reputations_big_table_request),
      }).then((res) => res.json());
      console.log('BIG TABLE', response);

      //                         }
      setRepBigData(response.hits.hits);
      setIsLoading4(true);
    } catch (error) {
      console.log(error);
    }
  };

  const elasticRequest = async (request, div) => {
    request.query.bool.filter.push({
      term: { 'serial-number.keyword': ntu.serialNumber },
    });
    //data.query.bool.must[0].query_string["query"] = "serial-number: " + vm.serial;
    request.query.bool.must[0].range['@timestamp'].gte = getGTE();
    request.query.bool.must[0].range['@timestamp'].lte = getLTE();

    try {
      let response = await getElasticSearchData('search', {
        ignoreThrottled: getIgnoreThrottled(),
        index: 'apptrack-*',
        size: 0,

        body: JSON.stringify(request),
      }).then((res) => res.json());

      if (div == '#reputation') {
        set_threats_ip_reputations_bar_data(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(async () => {
    await elasticRequest(threats_ip_reputations_bar_request, '#reputation');

    await TableReps();
    await TablePublicReps();
    await TableRisk();
    await TableBigReps();
    setIsLoading(false);
  }, []);
  if (isLoading)
    return (
      <div class="LoadingContainer text-center">
        <p>Loading</p>
      </div>
    );
  else
    return (
      <div className="row">
        <div className="col-lg-12  col-md-12 col-sm-12 col-xs-12">
          <Panel heading="Threats">
            <div>
              <DrawGraph
                esData={threats_ip_reputations_bar_data}
                graphDiv={'#reputation'}
                vegaGraph={threats_ip_reputations_bar}
              />
            </div>
          </Panel>
        </div>
        <div className="col-md-3 col-sm-12 col-xs-12">
          <Panel heading="Reputation">
            {isLoading1 && <RepDataTable data={repData} />}
          </Panel>
        </div>
        <div className="col-md-5 col-sm-12 col-xs-12">
          <Panel heading="Public Reputation">
            {isLoading2 && <PublicRepDataTable data={repPublicData} />}
          </Panel>
        </div>
        <div className="col-md-4 col-sm-12 col-xs-12">
          <Panel heading="Risks">
            {isLoading3 && <RiskDataTable data={riskData} />}
          </Panel>
        </div>
        <div className="col-md-12 col-sm-12 col-xs-12">
          <Panel heading="Reputation Big Data">
            {isLoading4 && <BigRepsDataTable data={repBigData} />}
          </Panel>
        </div>
      </div>
    );
};
export default Threats;
