import React, { useEffect, useState, Fragment } from "react";
import { Routes, Route, useLocation, Link } from "react-router-dom";

import TemplateConfigurations from "./TemplateConfigurations";
import TemplateConfigurationDetail from "./TemplateConfigurationDetail";
import TemplateConfigurationUpdate from "./TemplateConfigurationUpdate";
// import TemplateConfigurationDelete from "./TemplateConfigurationDeleteDialog";
import { getTemplateConfigurations } from "./api/templateConfigurationsApi";
import { useAuth } from "../../../hooks";

const TemplateConfigurationsContainer = () => {
  const location = useLocation();

  const [entities, setEntities] = useState([]);
  const [appState, setAppState] = useState({
    loading: false,
    err: false,
  });

  const { hasAuthority } = useAuth();

  useEffect(() => {
    (async () => {
      try {
        setAppState({ loading: true, err: false });
        const res = await getTemplateConfigurations().then((res) => res.data);
        setAppState({ loading: false });
        setEntities(res);
      } catch (err) {
        setAppState({ loading: false, err: true });
      }
    })();
  }, []);

  return (
    <Fragment>
      <ol className="breadcrumb page-breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">ActivePort</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to="/administration">Administration</Link>
        </li>
        <li className="breadcrumb-item active">TemplateConfigurations</li>
        <li className="position-absolute pos-top pos-right d-none d-sm-block">
          <span className="js-get-date"></span>
        </li>
      </ol>
      <div className="subheader">
        <h1 className="subheader-title">
          <i className="subheader-icon fal fa-table"></i> TemplateConfigurations
        </h1>

        {hasAuthority &&
          !location.pathname.includes("/new") &&
          !location.pathname.includes("/edit") && (
            <Link to={`new`}>
              <button
                type="button"
                className="btn btn-success waves-effect waves-themed"
              >
                <span className="fal fa-plus mr-1"></span>
                Create
              </button>
            </Link>
          )}
      </div>
      {!appState.loading && !appState.err && (
        <Routes>
          {hasAuthority && (
            <>
              <Route
                exact
                path={`/new`}
                element={<TemplateConfigurationUpdate />}
              />
              <Route
                exact
                path={`/edit/:id`}
                element={<TemplateConfigurationUpdate />}
              />
              <Route
                exact
                path={`:id`}
                element={
                  <TemplateConfigurationDetail hasAuthority={hasAuthority} />
                }
              />
            </>
          )}
          <Route
            path={`/`}
            element={
              <TemplateConfigurations
                entities={entities}
                hasAuthority={hasAuthority}
              />
            }
          />
        </Routes>
      )}
      {appState.loading && !appState.error && (
        <div className="border p-3">
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      )}
      {!appState.loading && appState.err && (
        <div className="alert alert-danger" role="alert">
          <strong>Oh snap!</strong> We are not able to connect to the database.
          Try again in few minutes. again.
        </div>
      )}
      {/* <Route
          exact
          path={`/:id/delete`}
          element={TemplateConfigurationDelete}
        /> */}
    </Fragment>
  );
};

export default TemplateConfigurationsContainer;
