import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';

import * as Yup from 'yup';
import Panel from '../../../common/Panel/Panel';
import Alert from '../../../common/AlertControls/Alert';
import { handleCreateNewAlert } from '../../../common/Alerts/CreateNewAlert';
import { handleUpdateAlert } from '../../../common/Alerts/EditAlert';
import { MyTextArea } from '../../../common/Form/FormFields';

import {
  createNtuType,
  getNtuType,
  updateNtuType,
  getNtuTypeAttributes,
} from './api/ntuTypesApi';

const NtuTypeUpdate = () => {
  const match = useParams();
  const navigate = useNavigate();
  let defaultValues = {
    id: '',
    attributes: {},
    description: '',
    etherPrefix: '',
    ethernetPorts: '',
    model: '',
    osType: '',
    pictureContentType: '',
    portServiceType: '',
    portTemplate: '',
    sfpPorts: '',
    sfpPrefix: '',
  };
  const [ntuTypeAttributes, setNtuTypeAttributes] = useState(null);
  const [initialData, setInitialData] = useState(defaultValues);
  const [appState, setAppState] = useState({
    loading: true,
  });

  const isNew = !match.id;
  const [alertShow, setAlertShow] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  // let alertShow = false;

  const schema = Yup.object().shape({
    model: Yup.string()
      .min(2, 'Too Short!')
      .max(250, 'Too Long!')
      .required('Required'),
    etherPrefix: Yup.string()
      .min(2, 'Too Short!')
      .max(250, 'Too Long!')
      .required('Required'),
    ethernetPorts: Yup.string()
      .min(1, 'Too Short!')
      .max(250, 'Too Long!')
      .required('Required'),
    osType: Yup.string()
      .min(2, 'Too Short!')
      .max(250, 'Too Long!')
      .required('Required'),
    portServiceType: Yup.string()
      .min(2, 'Too Short!')
      .max(250, 'Too Long!')
      .required('Required'),
    sfpPorts: Yup.string()
      .min(1, 'Too Short!')
      .max(250, 'Too Long!')
      .required('Required'),
    sfpPrefix: Yup.string()
      .min(2, 'Too Short!')
      .max(250, 'Too Long!')
      .required('Required'),
  });

  async function createNewNtuType(ntuType) {
    const response = await createNtuType(ntuType).then((res) => res.json());
    if (response.id) {
      handleCreateNewAlert(
        'New ntuType "' + ntuType.name + '" added successfully!'
      );
      navigate('../');
    }
  }

  async function updateNtuTypes(ntuType) {
    const response = await updateNtuType(ntuType).then((res) => res.json());
    if (response.id) {
      handleUpdateAlert('ntuType', response.id);
      navigate('../' + response.id);
    }
  }

  function submitForm(formValues) {
    isNew ? createNewNtuType(formValues) : updateNtuTypes(formValues);
  }

  // effect runs on component mount
  useEffect(() => {
    let unmounted = false;
    // simulate async api call with set timeout
    let ntuTypeAttributes;
    const fetcher = async () => {
      try {
        ntuTypeAttributes = await getNtuTypeAttributes().then((res) =>
          res.json()
        );
        console.log('dsd', ntuTypeAttributes);
        if (!unmounted) {
          setNtuTypeAttributes(ntuTypeAttributes);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetcher();

    const fetchNtuType = async () => {
      try {
        const result = await getNtuType(match.id).then((res) => res.json());
        if (!unmounted) {
          console.log('result: ', result);
          if (result.description == null) result.description = '';
          setInitialData(result);
          //reset(result);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (!isNew) fetchNtuType();
    setAppState({ loading: false });
    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-xl-12">
          {alertShow && (
            <Alert
              alertType="alert-success"
              msg="The ntuType has been updated."
            />
          )}

          {!appState.loading && initialData && (
            <Panel heading={initialData.name}>
              <Formik
                enableReinitialize
                initialValues={initialData}
                validationSchema={schema}
                validateOnMount
                onSubmit={submitForm}
              >
                {({ errors, touched, isValid, setFieldValue, values }) => {
                  return (
                    <Form>
                      {!isNew && (
                        <div className="form-group row m-b-15">
                          <label className="col-form-label col-md-3">ID</label>
                          <div className="col-md-9">
                            <Field
                              type="text"
                              name="id"
                              label="id"
                              className="form-control m-b-5"
                              readOnly
                            />
                          </div>
                        </div>
                      )}
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Model <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="model"
                            label="model"
                            className={`form-control m-b-5  ${
                              errors.model && touched.model ? 'is-invalid' : ''
                            }`}
                            placeholder="Enter NtuType Model"
                          />
                          {errors.model && touched.model ? (
                            <div className="invalid-feedback">
                              {errors.model}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Ethernet Name Prefix{' '}
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="etherPrefix"
                            label="etherPrefix"
                            className={`form-control m-b-5  ${
                              errors.etherPrefix && touched.etherPrefix
                                ? 'is-invalid'
                                : ''
                            }`}
                            placeholder="Enter NtuType Ethernet Prefix"
                          />
                          {errors.etherPrefix && touched.etherPrefix ? (
                            <div className="invalid-feedback">
                              {errors.etherPrefix}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Ethernet Ports
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="ethernetPorts"
                            label="ethernetPorts"
                            className={`form-control m-b-5  ${
                              errors.ethernetPorts && touched.ethernetPorts
                                ? 'is-invalid'
                                : ''
                            }`}
                            placeholder="Enter NtuType Ethernet Ports"
                          />
                          {errors.ethernetPorts && touched.ethernetPorts ? (
                            <div className="invalid-feedback">
                              {errors.ethernetPorts}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          SFP Name Prefix
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="sfpPrefix"
                            label="sfpPrefix"
                            className={`form-control m-b-5  ${
                              errors.sfpPrefix && touched.sfpPrefix
                                ? 'is-invalid'
                                : ''
                            }`}
                            placeholder="Enter NtuType SFP Name Prefix"
                          />
                          {errors.sfpPrefix && touched.sfpPrefix ? (
                            <div className="invalid-feedback">
                              {errors.sfpPrefix}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          SFP Ports
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="sfpPorts"
                            label="sfpPorts"
                            className={`form-control m-b-5  ${
                              errors.sfpPorts && touched.sfpPorts
                                ? 'is-invalid'
                                : ''
                            }`}
                            placeholder="Enter NtuType SFP Ports"
                          />
                          {errors.sfpPorts && touched.sfpPorts ? (
                            <div className="invalid-feedback">
                              {errors.sfpPorts}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Description
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="textarea"
                            as="textarea"
                            name="description"
                            label="description"
                            className={`form-control m-b-5`}
                            placeholder="Enter Description"
                          />
                          {errors.description && touched.description ? (
                            <div className="invalid-feedback">
                              {errors.description}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Port Service Type{' '}
                          <span className="text-danger"></span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            as="select"
                            name="portServiceType"
                            className={`form-control m-b-5  ${
                              errors.portServiceType && touched.portServiceType
                                ? 'is-invalid'
                                : ''
                            }`}
                          >
                            <option value=""></option>
                            {['NTU', 'PE', 'VIRTUAL NTU', 'SD_WAN'].map(
                              (port, i) => (
                                <option key={i} value={port}>
                                  {port}
                                </option>
                              )
                            )}
                          </Field>
                          {errors.portServiceType && touched.portServiceType ? (
                            <div className="invalid-feedback">
                              {errors.portServiceType}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          OS Type
                          <span className="text-danger"></span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            as="select"
                            name="osType"
                            className={`form-control m-b-5  ${
                              errors.osType && touched.osType
                                ? 'is-invalid'
                                : ''
                            }`}
                          >
                            <option value=""></option>
                            {[
                              'JUNOS',
                              'MIKROTIK',
                              'CISCO IOS XE',
                              'DEBIAN',
                            ].map((osType, i) => (
                              <option key={i} value={osType}>
                                {osType}
                              </option>
                            ))}
                          </Field>
                          {errors.osType && touched.osType ? (
                            <div className="invalid-feedback">
                              {errors.osType}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <MyTextArea
                        label="Port Template"
                        name="portTemplate"
                        rows="10"
                        placeholder="Enter your Port Template"
                      />

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Default Api Port
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="defaultApiPort"
                            label="defaultApiPort"
                            className={`form-control m-b-5  ${
                              errors.defaultApiPort && touched.defaultApiPort
                                ? 'is-invalid'
                                : ''
                            }`}
                            placeholder="Enter NtuType Default Api Port"
                          />
                          {errors.defaultApiPort && touched.defaultApiPort ? (
                            <div className="invalid-feedback">
                              {errors.defaultApiPort}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Default Rest Port
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="defaultRestPort"
                            label="defaultRestPort"
                            className={`form-control m-b-5  ${
                              errors.defaultRestPort && touched.defaultRestPort
                                ? 'is-invalid'
                                : ''
                            }`}
                            placeholder="Enter NtuType Default Rest Port"
                          />
                          {errors.defaultRestPort && touched.defaultRestPort ? (
                            <div className="invalid-feedback">
                              {errors.defaultRestPort}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <button
                        type="submit"
                        className="btn btn-primary btn-block btn-lg m-b-5"
                        disabled={!isValid || isSubmitted}
                      >
                        Submit
                      </button>
                    </Form>
                  );
                }}
              </Formik>
            </Panel>
          )}
        </div>
      </div>
    </div>
  );
};

export default NtuTypeUpdate;
