/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";

import * as Yup from "yup";
import Panel from "../../../common/Panel/Panel";
import Alert from "../../../common/AlertControls/Alert";
import { handleCreateNewAlert } from "../../../common/Alerts/CreateNewAlert";
import { handleUpdateAlert } from "../../../common/Alerts/EditAlert";

import {
  createSDWANAggregator,
  getSDWANAggregator,
  updateSDWANAggregator,
} from "./api/sdwanAggregatorsApi";

const SDWANAggregatorUpdate = ({ locations, pe }) => {
  const match = useParams();
  const navigate = useNavigate();
  let defaultValues = {
    id: "",
    aggregator: "",
    adminType: "",
    managementIp: "",
    state: "ACTIVE",
    rank: 1,
    configType: "PRIMARY",
    pe: "",
    pePort: "",
    location: "",
  };

  const [initialData, setInitialData] = useState(defaultValues);
  const [appState, setAppState] = useState({
    loading: true,
  });
  const [activeTab, setActiveTab] = useState("Admin");

  const isNew = !match.id;
  const [alertShow, setAlertShow] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  // let alertShow = false;

  const schema = Yup.object().shape({
    memory: Yup.number()
      .min(2, "Too Short!")
      .max(250, "Too Long!")
      .required("Required"),
  });

  async function createNewSDWANAggregator(sdwanAggregator) {
    const response = await createSDWANAggregator(sdwanAggregator).then((res) =>
      res.json()
    );
    if (response.id) {
      handleCreateNewAlert(
        'New sdwanAggregator "' + sdwanAggregator.name + '" added successfully!'
      );
      navigate("../");
    }
  }

  async function updateSDWANAggregators(sdwanAggregator) {
    const response = await updateSDWANAggregator(sdwanAggregator).then((res) =>
      res.json()
    );
    if (response.id) {
      handleUpdateAlert("sdwanAggregator", sdwanAggregator.id);
      navigate("../" + sdwanAggregator.id);
    }
  }

  function submitForm(formValues) {
    isNew
      ? createNewSDWANAggregator(formValues)
      : updateSDWANAggregators(formValues);
  }

  // effect runs on component mount
  useEffect(() => {
    let unmounted = false;
    // simulate async api call with set timeout
    const fetchSDWANAggregator = async () => {
      try {
        const result = await getSDWANAggregator(match.id).then((res) =>
          res.json()
        );
        if (!unmounted) {
          if (result.description == null) result.description = "";
          setInitialData(result);
          //reset(result);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (!isNew) fetchSDWANAggregator();
    setAppState({ loading: false });
    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-xl-9">
          {alertShow && (
            <Alert
              alertType="alert-success"
              msg="The sdwanAggregator has been updated."
            />
          )}

          {!appState.loading && initialData && (
            <Panel>
              <ul className="nav nav-pills mb-3" role="tablist">
                {["Admin", "Configuration"].map((item, i) => (
                  <li
                    key={i}
                    className="nav-item cursor-pointer"
                    onClick={() => setActiveTab(item)}
                  >
                    <a
                      className={`nav-link ${activeTab === item && "active"}`}
                      dataToggle="pill"
                    >
                      {item}
                    </a>
                  </li>
                ))}
              </ul>

              <Formik
                enableReinitialize
                initialValues={initialData}
                // validationSchema={schema}
                validateOnMount
                onSubmit={submitForm}
              >
                {({ errors, touched, isValid }) => {
                  let ActiveForm = null;
                  switch (activeTab) {
                    case "Admin":
                      ActiveForm = (
                        <>
                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Aggregator
                              <span className="text-danger"></span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                as="select"
                                name="aggregator"
                                className={`form-control m-b-5  ${
                                  errors.aggregator && touched.aggregator
                                    ? "is-invalid"
                                    : ""
                                }`}
                              >
                                <option value=""></option>
                              </Field>
                              {errors.aggregator && touched.aggregator ? (
                                <div className="invalid-feedback">
                                  {errors.aggregator}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Type
                              <span className="text-danger"></span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                as="select"
                                name="adminType"
                                className={`form-control m-b-5  ${
                                  errors.adminType && touched.adminType
                                    ? "is-invalid"
                                    : ""
                                }`}
                              >
                                <option value=""></option>
                                {["PHYSICAL", "VM"].map((type, i) => (
                                  <option key={i} value={type}>
                                    {type}
                                  </option>
                                ))}
                              </Field>
                              {errors.adminType && touched.adminType ? (
                                <div className="invalid-feedback">
                                  {errors.adminType}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Aggregator Interface
                              <span className="text-danger"></span>
                            </label>
                            <div className="col-md-9">
                              <table width="100%" className="mt-2">
                                <thead>
                                  <tr>
                                    <th>Id</th>
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th>Service ID</th>
                                    <th>VLAN</th>
                                  </tr>
                                </thead>
                              </table>
                            </div>
                          </div>

                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Management Ip{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                type="text"
                                name="managementIp"
                                className={`form-control m-b-5  ${
                                  errors.managementIp && touched.managementIp
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Enter Management Ip"
                              />
                              {errors.managementIp && touched.managementIp ? (
                                <div className="invalid-feedback">
                                  {errors.managementIp}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </>
                      );
                      break;
                    case "Configuration":
                      ActiveForm = (
                        <>
                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              State
                              <span className="text-danger"></span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                as="select"
                                name="state"
                                className={`form-control m-b-5  ${
                                  errors.state && touched.state
                                    ? "is-invalid"
                                    : ""
                                }`}
                              >
                                {["ACTIVE", "DISABLED", "FULL"].map(
                                  (state, i) => (
                                    <option key={i} value={state}>
                                      {state}
                                    </option>
                                  )
                                )}
                              </Field>
                              {errors.adminType && touched.adminType ? (
                                <div className="invalid-feedback">
                                  {errors.adminType}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Rank
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                type="number"
                                name="rank"
                                className={`form-control m-b-5  ${
                                  errors.rank && touched.rank
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Enter Rank"
                              />
                              {errors.rank && touched.rank ? (
                                <div className="invalid-feedback">
                                  {errors.rank}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Type
                              <span className="text-danger"></span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                as="select"
                                name="configType"
                                className={`form-control m-b-5  ${
                                  errors.configType && touched.configType
                                    ? "is-invalid"
                                    : ""
                                }`}
                              >
                                {["PRIMARY", "SECONDARY"].map((state, i) => (
                                  <option key={i} value={state}>
                                    {state}
                                  </option>
                                ))}
                              </Field>
                              {errors.configType && touched.configType ? (
                                <div className="invalid-feedback">
                                  {errors.configType}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              PE
                              <span className="text-danger"></span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                as="select"
                                name="pe"
                                className={`form-control m-b-5  ${
                                  errors.pe && touched.pe ? "is-invalid" : ""
                                }`}
                              >
                                <option value=""></option>
                                {pe?.map(({ id, name }) => (
                                  <option key={id} value={name}>
                                    {name}
                                  </option>
                                ))}
                              </Field>
                              {errors.pe && touched.pe ? (
                                <div className="invalid-feedback">
                                  {errors.pe}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              PE Port
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                type="text"
                                name="pePort"
                                className={`form-control m-b-5  ${
                                  errors.pePort && touched.pePort
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Enter PE Port"
                              />
                              {errors.pePort && touched.pePort ? (
                                <div className="invalid-feedback">
                                  {errors.pePort}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Location
                              <span className="text-danger"></span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                as="select"
                                name="location"
                                className={`form-control m-b-5  ${
                                  errors.location && touched.location
                                    ? "is-invalid"
                                    : ""
                                }`}
                              >
                                <option value=""></option>
                                {locations?.map(({ id, name }) => (
                                  <option key={id} value={name}>
                                    {name}
                                  </option>
                                ))}
                              </Field>
                              {errors.location && touched.location ? (
                                <div className="invalid-feedback">
                                  {errors.location}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </>
                      );
                      break;
                    default:
                      break;
                  }
                  return (
                    <Form>
                      {ActiveForm}

                      <button
                        type="submit"
                        className="btn btn-primary btn-block btn-lg m-b-5"
                        disabled={!isValid || isSubmitted}
                      >
                        Submit
                      </button>
                    </Form>
                  );
                }}
              </Formik>
            </Panel>
          )}
        </div>
      </div>
    </div>
  );
};

export default SDWANAggregatorUpdate;
