/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';

import * as Yup from 'yup';
import Panel from '../../../common/Panel/Panel';
import Alert from '../../../common/AlertControls/Alert';
import { handleCreateNewAlert } from '../../../common/Alerts/CreateNewAlert';
import { handleUpdateAlert } from '../../../common/Alerts/EditAlert';
import { MyTextArea } from '../../../common/Form/FormFields';
import HelpText from '../../../common/Form/HelpText/HelpText';

import {
  createNFVProfile,
  getNFVProfile,
  updateNFVProfile,
} from './api/nfvProfilesApi';

const NFVProfileUpdate = () => {
  const match = useParams();
  const navigate = useNavigate();
  let defaultValues = {
    id: '',
    uid: '',
    name: '',
    memory: '',
    cpus: null,
    storage: null,
    ifaceType: '',
    description: '',
    config: '',
  };
  const [initialData, setInitialData] = useState(defaultValues);
  const [appState, setAppState] = useState({
    loading: true,
  });
  const [activeTab, setActiveTab] = useState('Details');

  const isNew = !match.id;
  const [alertShow, setAlertShow] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  // let alertShow = false;

  const schema = Yup.object().shape({
    memory: Yup.number()
      .min(2, 'Too Short!')
      .max(250, 'Too Long!')
      .required('Required'),
  });

  async function createNewNFVProfile(nfvProfile) {
    const response = await createNFVProfile(nfvProfile).then((res) =>
      res.json()
    );
    if (response.id) {
      handleCreateNewAlert(
        'New nfvProfile "' + nfvProfile.name + '" added successfully!'
      );
      navigate('../');
    }
  }

  async function updateNFVProfiles(nfvProfile) {
    const response = await updateNFVProfile(nfvProfile).then((res) =>
      res.json()
    );
    if (response.id) {
      handleUpdateAlert('nfvProfile', nfvProfile.id);
      navigate('../' + nfvProfile.id);
    }
  }

  function submitForm(formValues) {
    isNew ? createNewNFVProfile(formValues) : updateNFVProfiles(formValues);
  }

  // effect runs on component mount
  useEffect(() => {
    let unmounted = false;
    // simulate async api call with set timeout
    const fetchNFVProfile = async () => {
      try {
        const result = await getNFVProfile(match.id).then((res) => res.json());
        if (!unmounted) {
          if (result.description == null) result.description = '';
          setInitialData(result);
          //reset(result);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (!isNew) fetchNFVProfile();
    setAppState({ loading: false });
    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-xl-9">
          {alertShow && (
            <Alert
              alertType="alert-success"
              msg="The nfvProfile has been updated."
            />
          )}

          {!appState.loading && initialData && (
            <Panel>
              <ul className="nav nav-pills mb-3" role="tablist">
                {['Details', 'Template'].map((item, i) => (
                  <li
                    key={i}
                    className="nav-item cursor-pointer"
                    onClick={() => setActiveTab(item)}
                  >
                    <a
                      className={`nav-link ${activeTab === item && 'active'}`}
                      dataToggle="pill"
                    >
                      {item}
                    </a>
                  </li>
                ))}
              </ul>
              <Formik
                enableReinitialize
                initialValues={initialData}
                // validationSchema={schema}
                validateOnMount
                onSubmit={submitForm}
              >
                {({ errors, touched, isValid }) => {
                  return (
                    <Form>
                      {activeTab === 'Details' ? (
                        <>
                          {!isNew && (
                            <div className="form-group row m-b-15">
                              <label className="col-form-label col-md-3">
                                ID
                              </label>
                              <div className="col-md-9">
                                <Field
                                  type="text"
                                  name="id"
                                  label="id"
                                  className="form-control m-b-5"
                                  readOnly
                                />
                              </div>
                            </div>
                          )}

                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Uid <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                type="text"
                                name="uid"
                                label="uid"
                                className={`form-control m-b-5  ${
                                  errors.uid && touched.uid ? 'is-invalid' : ''
                                }`}
                                placeholder="Enter NFV Profile Uid"
                              />
                              {errors.uid && touched.uid ? (
                                <div className="invalid-feedback">
                                  {errors.uid}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Name <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                type="text"
                                name="name"
                                label="name"
                                className={`form-control m-b-5  ${
                                  errors.name && touched.name
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                placeholder="Enter NFV Profile Name"
                              />
                              {errors.name && touched.name ? (
                                <div className="invalid-feedback">
                                  {errors.name}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Memory <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                type="number"
                                name="memory"
                                label="memory"
                                className={`form-control m-b-5  ${
                                  errors.memory && touched.memory
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                placeholder="Enter NFV Profile Memory"
                              />
                              <HelpText text="Maximum memory allocation to Guest in KiB." />
                              {errors.memory && touched.memory ? (
                                <div className="invalid-feedback">
                                  {errors.memory}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              VCPUs <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                type="number"
                                name="cpus"
                                label="cpus"
                                className={`form-control m-b-5  ${
                                  errors.cpus && touched.cpus
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                placeholder="Enter NFV Profile VCPU"
                              />
                              <HelpText text="VCPUs allocated to Guest." />
                              {errors.cpus && touched.cpus ? (
                                <div className="invalid-feedback">
                                  {errors.cpus}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Storage <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                type="number"
                                name="storage"
                                label="storage"
                                className={`form-control m-b-5  ${
                                  errors.storage && touched.storage
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                placeholder="Enter NFV Profile Storage"
                              />
                              <HelpText text="Maximum Storage allocation for Guest in MiB." />
                              {errors.storage && touched.storage ? (
                                <div className="invalid-feedback">
                                  {errors.storage}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Interfaces Type{' '}
                              <span className="text-danger"></span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                as="select"
                                name="ifaceType"
                                className={`form-control m-b-5  ${
                                  errors.ifaceType && touched.ifaceType
                                    ? 'is-invalid'
                                    : ''
                                }`}
                              >
                                <option value=""></option>
                                {['virtio', 'e1000'].map((ifaceType, i) => (
                                  <option key={i} value={ifaceType}>
                                    {ifaceType}
                                  </option>
                                ))}
                              </Field>
                              {errors.ifaceType && touched.ifaceType ? (
                                <div className="invalid-feedback">
                                  {errors.ifaceType}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Description <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                type="textarea"
                                as="textarea"
                                name="description"
                                label="description"
                                className={`form-control m-b-5  ${
                                  errors.description && touched.description
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                placeholder="Enter Description"
                              />
                              {errors.description && touched.description ? (
                                <div className="invalid-feedback">
                                  {errors.description}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </>
                      ) : (
                        <MyTextArea
                          label="Configuration"
                          name="config"
                          rows="30"
                          placeholder="Enter Configuration"
                        />
                      )}

                      <button
                        type="submit"
                        className="btn btn-primary btn-block btn-lg m-b-5"
                        disabled={!isValid || isSubmitted}
                      >
                        Submit
                      </button>
                    </Form>
                  );
                }}
              </Formik>
            </Panel>
          )}
        </div>
      </div>
    </div>
  );
};

export default NFVProfileUpdate;
