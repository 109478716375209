import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
//import LocizeBackend from 'i18next-locize-backend';

import LocizeEditor from 'locize-editor';

i18n
  .use(Backend)

  .use(LanguageDetector)
  .use(initReactI18next)
  //.use(LocizeEditor)

  .init({
    debug: false,
    supportedLngs: ['en', 'fr', 'es', 'ja'],
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    // ** Enterprise https://locize.com **
    //returnEmptyString: false,
    //saveMissing: true,
    //saveMissingTo: 'all',

    //  backend: {
    //    projectId: '301bb624-a351-4e4c-bc39-4814089ca2f3',
    //   apiKey: '8f8c0077-df6f-4d7c-8204-5021eb157140',
    //  referenceLng: 'en',
    //  },
  });

export { i18n };
