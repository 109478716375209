import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';
import Panel from '../../../common/Panel/Panel';
import DrawGraph from './DrawGraph';
import { getElasticSearchData } from './api/elasticsearch';
import moment from 'moment';
import tz from 'moment-timezone';
import ContainerDimensions from 'react-container-dimensions';

import geoip_country_pie from '../../../common/graphs/vega/applications/geoip_country_pie.json';
import geoip_map_country_src_request from '../../../common/graphs/vega/applications/geoip_map_country_src_request.json';
import geoip_map_country_dst_request from '../../../common/graphs/vega/applications/geoip_map_country_dst_request.json';

import geoip_cities_pie from '../../../common/graphs/vega/applications/geoip_cities_pie.json';
import geoip_map_city_src_request from '../../../common/graphs/vega/applications/geoip_map_city_src_request.json';
import geoip_map_city_dst_request from '../../../common/graphs/vega/applications/geoip_map_city_dst_request.json';

import geoip_map_request from '../../../common/graphs/vega/applications/geoip_map_request.json';
import geoip_map from '../../../common/graphs/vega/applications/geoip_map.json';

const GeoIP = ({ lte, gte, ntu }) => {
  var ignore_throttled = 1;
  const [isLoading, setIsLoading] = useState(true);
  const [geoip_map_country_src_data, set_geoip_map_country_src_data] = useState(
    {}
  );
  const [geoip_map_country_dst_data, set_geoip_map_country_dst_data] = useState(
    {}
  );
  const [geoip_map_city_src_data, set_geoip_map_city_src_data] = useState({});
  const [geoip_map_city_dst_data, set_geoip_map_city_dst_data] = useState({});
  const [geoip_map_data, set_geoip_map_data] = useState({});

  const getLTE = () => {
    if (lte == null || lte == '') {
      lte = new Date();
      lte = moment();
      lte = lte._d.getTime();
    }
    return lte;
  };
  const getGTE = () => {
    if (gte == null || gte == '') {
      gte = new Date();
      gte = moment().subtract(3, 'hours');
      gte = gte._d.getTime();
    }
    return gte;
  };

  const getIgnoreThrottled = () => {
    var now = new Date();
    //var lte_diff = moment(now).diff(moment(vm.lte), 'days')
    var gte_diff = moment(now).diff(moment(getGTE()), 'months');
    //             console.log("lte_diff = " + lte_diff);
    console.log('gte_diff = ' + gte_diff);
    if (gte_diff > 0) {
      ignore_throttled = 0; // searchable snapshot
    }
    return ignore_throttled;
  };

  const updateVegaSize = () => {
    setIsLoading(true);
    setIsLoading(false);
  };

  function handleResize() {
    // Set window width/height to state

    updateVegaSize();
  }

  const elasticRequest = async (request, div) => {
    request.query.bool.filter.push({
      term: { 'serial-number.keyword': ntu.serialNumber },
    });
    //data.query.bool.must[0].query_string["query"] = "serial-number: " + vm.serial;
    request.query.bool.must[0].range['@timestamp'].gte = getGTE();
    request.query.bool.must[0].range['@timestamp'].lte = getLTE();

    try {
      let response = await getElasticSearchData('search', {
        ignoreThrottled: getIgnoreThrottled(),
        index: 'apptrack-*',
        size: 0,

        body: JSON.stringify(request),
      }).then((res) => res.json());

      if (div == '#countries_source') {
        set_geoip_map_country_src_data(response);
      }
      if (div == '#countries_dest') {
        set_geoip_map_country_dst_data(response);
      }
      if (div == '#cities_source') {
        set_geoip_map_city_src_data(response);
      }
      if (div == '#cities_dest') {
        set_geoip_map_city_dst_data(response);
      }
      if (div == '#application_geoip') {
        set_geoip_map_data(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(async () => {
    await elasticRequest(geoip_map_country_src_request, '#countries_source');
    await elasticRequest(geoip_map_country_dst_request, '#countries_dest');
    await elasticRequest(geoip_map_city_src_request, '#cities_source');
    await elasticRequest(geoip_map_city_dst_request, '#cities_dest');
    await elasticRequest(geoip_map_request, '#application_geoip');

    setIsLoading(false);

    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, [lte, gte]);

  if (isLoading)
    return (
      <div class="LoadingContainer text-center">
        <p>Loading</p>
      </div>
    );
  else
    return (
      <div>
        <div className="row">
          <div className="col-lg-3  col-md-6 col-sm-12 col-xs-12">
            <Panel heading="Countries Source">
              <div>
                <DrawGraph
                  esData={geoip_map_country_src_data}
                  graphDiv={'#countries_source'}
                  vegaGraph={geoip_country_pie}
                />
              </div>
            </Panel>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
            <Panel heading="Countries Destination">
              <div>
                <DrawGraph
                  esData={geoip_map_country_dst_data}
                  graphDiv={'#countries_dest'}
                  vegaGraph={geoip_country_pie}
                />
              </div>
            </Panel>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
            <Panel heading="Cities Source">
              <div>
                <DrawGraph
                  esData={geoip_map_city_src_data}
                  graphDiv={'#cities_source'}
                  vegaGraph={geoip_cities_pie}
                />
              </div>
            </Panel>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
            <Panel heading="Cities Destination">
              <div>
                <DrawGraph
                  esData={geoip_map_city_dst_data}
                  graphDiv={'#cities_dest'}
                  vegaGraph={geoip_cities_pie}
                />
              </div>
            </Panel>
          </div>
          <div className="col-md-12 col-sm-12 col-xs-12">
            <Panel heading="Internet Geo IP Source">
              <div>
                <DrawGraph
                  esData={geoip_map_data}
                  graphDiv={'#application_geoip'}
                  vegaGraph={geoip_map}
                />
              </div>
            </Panel>
          </div>
        </div>
      </div>
    );
};

export default GeoIP;
