import React, { useState } from 'react';

const SettingsContext = React.createContext({
  NTU: {},
  workingORG: '',
  serviceList: '',
  setServiceList: () => {},
  setWorkingOrg: () => {},
  setNTU: (selectedNtu) => {},
  getNTU: () => {},
});

export const SettingsContextProvider = (props) => {
  const [ntu, setNtu] = useState('');
  const [workingOrg, setWorkingOrg] = useState('');
  const [serviceList, setServiceList] = useState('');

  const setNTUHandler = (selectedNtu) => {
    setNtu(selectedNtu);
  };

  const setWorkingOrgHandler = (selectedOrg) => {
    // console.log('setting new Org:', selectedOrg);

    setWorkingOrg(selectedOrg);
  };

  const setServiceListHandler = (serviceList) => {
    setServiceList(serviceList);
  };

  const getNTUHandler = () => {
    return JSON.parse(localStorage.getItem('ntu'));
  };
  const contextValue = {
    NTU: ntu,
    workingORG: workingOrg,
    serviceList: serviceList,
    setWorkingOrg: setWorkingOrgHandler,
    setServiceList: setServiceListHandler,
    setNTU: setNTUHandler,
    getNTU: getNTUHandler,
  };
  return (
    <SettingsContext.Provider value={contextValue}>
      {props.children}
    </SettingsContext.Provider>
  );
};

export default SettingsContext;
