export const getPolicersSchedules = async () => {
  const token = localStorage.getItem("token");
  const ntuId = JSON.parse(localStorage.getItem("ntu")).id;
  return fetch(
    `https://test.activeport.com.au/api/policer-schedules-ntu/${ntuId}`,
    {
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    }
  );
};
