/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';

import * as Yup from 'yup';
import Panel from '../../../common/Panel/Panel';
import Alert from '../../../common/AlertControls/Alert';
import { handleCreateNewAlert } from '../../../common/Alerts/CreateNewAlert';
import { handleUpdateAlert } from '../../../common/Alerts/EditAlert';
import { MyTextArea, MyCheckbox1 } from '../../../common/Form/FormFields';

import {
  createNFVConfig,
  getNFVConfig,
  updateNFVConfig,
} from './api/nfvConfigsApi';

const NFVConfigUpdate = () => {
  const match = useParams();
  const navigate = useNavigate();
  let defaultValues = {
    id: '',
    uid: '',
    appType: '',
    name: '',
    entryType: '',
    agentUrl: '',
    version: '',
    buildVersion: '',
    serverKeyPath: '',
    description: '',
    storage_pool: '',
    proxyIp: '',
    upgradeLatest: false,
  };
  const [initialData, setInitialData] = useState(defaultValues);
  const [appState, setAppState] = useState({
    loading: true,
  });
  const [activeTab, setActiveTab] = useState('Details');

  const isNew = !match.id;
  const [alertShow, setAlertShow] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  // let alertShow = false;

  const schema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Too Short!')
      .max(250, 'Too Long!')
      .required('Required'),
    agentUrl: Yup.string()
      .min(2, 'Too Short!')
      .max(250, 'Too Long!')
      .required('Required'),
    version: Yup.string()
      .min(2, 'Too Short!')
      .max(250, 'Too Long!')
      .required('Required'),
    buildVersion: Yup.string()
      .min(2, 'Too Short!')
      .max(250, 'Too Long!')
      .required('Required'),
    serverKeyPath: Yup.string()
      .min(2, 'Too Short!')
      .max(250, 'Too Long!')
      .required('Required'),

    storage_pool: Yup.string()
      .min(2, 'Too Short!')
      .max(250, 'Too Long!')
      .required('Required'),
    proxyIp: Yup.string()
      .min(2, 'Too Short!')
      .max(250, 'Too Long!')
      .required('Required'),
    config: Yup.string().required(),
    script: Yup.string().required(),
    agentInstaller: Yup.string().required(),
    decommission: Yup.string().required(),
    releaseNotes: Yup.string().required(),
  });

  async function createNewNFVConfig(nfvConfig) {
    const response = await createNFVConfig(nfvConfig).then((res) => res.json());
    if (response.id) {
      handleCreateNewAlert(
        'New nfvConfig "' + nfvConfig.name + '" added successfully!'
      );
      navigate('../');
    }
  }

  async function updateNFVConfigs(nfvConfig) {
    const response = await updateNFVConfig(nfvConfig).then((res) => res.json());
    if (response.id) {
      handleUpdateAlert('nfvConfig', nfvConfig.id);
      navigate('../' + nfvConfig.id);
    }
  }

  function submitForm(formValues) {
    isNew ? createNewNFVConfig(formValues) : updateNFVConfigs(formValues);
  }

  // effect runs on component mount
  useEffect(() => {
    let unmounted = false;
    // simulate async api call with set timeout
    const fetchNFVConfig = async () => {
      try {
        const result = await getNFVConfig(match.id).then((res) => res.json());
        if (!unmounted) {
          if (result.description == null) result.description = '';
          setInitialData(result);
          //reset(result);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (!isNew) fetchNFVConfig();
    setAppState({ loading: false });
    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-xl-9">
          {alertShow && (
            <Alert
              alertType="alert-success"
              msg="The nfvConfig has been updated."
            />
          )}

          {!appState.loading && initialData && (
            <Panel heading={initialData.name}>
              <ul className="nav nav-pills mb-3" role="tablist">
                {[
                  'Details',
                  'Agent',
                  'Provisioning',
                  'Installer',
                  'Decommission',
                  'Release Notes',
                ].map((item, i) => (
                  <li
                    key={i}
                    className="nav-item cursor-pointer"
                    onClick={() => setActiveTab(item)}
                  >
                    <a
                      className={`nav-link ${activeTab === item && 'active'}`}
                      dataToggle="pill"
                    >
                      {item}
                    </a>
                  </li>
                ))}
              </ul>

              <Formik
                enableReinitialize
                initialValues={initialData}
                validationSchema={schema}
                validateOnMount
                onSubmit={submitForm}
              >
                {({ errors, touched, isValid, values }) => {
                  let ActiveForm = null;
                  switch (activeTab) {
                    case 'Details':
                      ActiveForm = (
                        <>
                          {!isNew && (
                            <>
                              <div className="form-group row m-b-15">
                                <label className="col-form-label col-md-3">
                                  ID
                                </label>
                                <div className="col-md-9">
                                  <Field
                                    type="text"
                                    name="id"
                                    label="id"
                                    className="form-control m-b-5"
                                    readOnly
                                  />
                                </div>
                              </div>
                              <div className="form-group row m-b-15">
                                <label className="col-form-label col-md-3">
                                  Uid <span className="text-danger">*</span>
                                </label>
                                <div className="col-md-9">
                                  <Field
                                    type="text"
                                    name="uid"
                                    label="uid"
                                    className={`form-control m-b-5  ${
                                      errors.uid && touched.uid
                                        ? 'is-invalid'
                                        : ''
                                    }`}
                                    placeholder="Enter NFV Config Uid"
                                    readOnly
                                  />
                                  {errors.uid && touched.uid ? (
                                    <div className="invalid-feedback">
                                      {errors.uid}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </>
                          )}

                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Application <span className="text-danger"></span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                as="select"
                                name="appType"
                                className={`form-control m-b-5  ${
                                  errors.tiles && touched.tiles
                                    ? 'is-invalid'
                                    : ''
                                }`}
                              >
                                <option value="" disabled selected hidden>
                                  Enter Application
                                </option>
                                {['NFV Agent', 'GPU Agent'].map((type, i) => (
                                  <option key={i} value={type}>
                                    {type}
                                  </option>
                                ))}
                              </Field>
                              {errors.appType && touched.appType ? (
                                <div className="invalid-feedback">
                                  {errors.appType}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Name <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                type="text"
                                name="name"
                                label="name"
                                className={`form-control m-b-5  ${
                                  errors.name && touched.name
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                placeholder="Enter NFV Config Name"
                              />
                              {errors.name && touched.name ? (
                                <div className="invalid-feedback">
                                  {errors.name}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          {!isNew && (
                            <div className="form-group row m-b-15">
                              <label className="col-form-label col-md-3">
                                Type <span className="text-danger">*</span>
                              </label>
                              <div className="col-md-9">
                                <Field
                                  type="text"
                                  name="entryType"
                                  label="entryType"
                                  className={`form-control m-b-5  ${
                                    errors.entryType && touched.entryType
                                      ? 'is-invalid'
                                      : ''
                                  }`}
                                  placeholder="Enter NFV Config Type"
                                  readOnly
                                />
                                {errors.memory && touched.memory ? (
                                  <div className="invalid-feedback">
                                    {errors.memory}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          )}

                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Agent Url <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                type="text"
                                name="agentUrl"
                                label="agentUrl"
                                className={`form-control m-b-5  ${
                                  errors.agentUrl && touched.agentUrl
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                placeholder="Enter NFV Config Agent Url"
                              />
                              {errors.agentUrl && touched.agentUrl ? (
                                <div className="invalid-feedback">
                                  {errors.agentUrl}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Repository Group ID{' '}
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                type="text"
                                name="repoGroupId"
                                label="repoGroupId"
                                className={`form-control m-b-5  ${
                                  errors.repoGroupId && touched.repoGroupId
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                placeholder="Enter NFV Config  Repository Group ID"
                              />
                              {errors.repoGroupId && touched.repoGroupId ? (
                                <div className="invalid-feedback">
                                  {errors.repoGroupId}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Repository Artifact ID{' '}
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                type="text"
                                name="repoArtifactId"
                                label="repoArtifactId"
                                className={`form-control m-b-5  ${
                                  errors.repoArtifactId &&
                                  touched.repoArtifactId
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                placeholder="Enter NFV Config  Repository Artifact ID"
                              />
                              {errors.repoArtifactId &&
                              touched.repoArtifactId ? (
                                <div className="invalid-feedback">
                                  {errors.repoArtifactId}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Repository URL
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                type="text"
                                name="repoUrl"
                                label="repoUrl"
                                className={`form-control m-b-5  ${
                                  errors.repoUrl && touched.repoUrl
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                placeholder="Enter NFV Config  Repository URL"
                              />
                              {errors.repoUrl && touched.repoUrl ? (
                                <div className="invalid-feedback">
                                  {errors.repoUrl}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Repository Auth
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                type="text"
                                name="repoAuth"
                                label="repoAuth"
                                className={`form-control m-b-5  ${
                                  errors.repoAuth && touched.repoAuth
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                placeholder="Enter NFV Config Repository Auth"
                              />
                              {errors.repoAuth && touched.repoAuth ? (
                                <div className="invalid-feedback">
                                  {errors.repoAuth}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Upgrade to latest
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                type="checkbox"
                                component={MyCheckbox1}
                                name="upgradeLatest"
                                checked={values.upgradeLatest}
                                className={`form-control m-b-5  ${
                                  errors.upgradeLatest && touched.upgradeLatest
                                    ? 'is-invalid'
                                    : ''
                                }`}
                              />
                              {errors.upgradeLatest && touched.upgradeLatest ? (
                                <div className="invalid-feedback">
                                  {errors.upgradeLatest}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Release Version{' '}
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                type="text"
                                name="version"
                                label="version"
                                className={`form-control m-b-5  ${
                                  errors.version && touched.version
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                placeholder="Enter NFV Config Release Version"
                              />
                              {errors.version && touched.version ? (
                                <div className="invalid-feedback">
                                  {errors.version}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Build Version{' '}
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                type="text"
                                name="buildVersion"
                                label="buildVersion"
                                className={`form-control m-b-5  ${
                                  errors.buildVersion && touched.buildVersion
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                placeholder="Enter NFV Config Build Version"
                              />
                              {errors.buildVersion && touched.buildVersion ? (
                                <div className="invalid-feedback">
                                  {errors.buildVersion}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Server SSH Key{' '}
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                type="text"
                                name="serverKeyPath"
                                label="serverKeyPath"
                                className={`form-control m-b-5  ${
                                  errors.serverKeyPath && touched.serverKeyPath
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                placeholder="Enter NFV Config Server SSH Key"
                              />
                              {errors.serverKeyPath && touched.serverKeyPath ? (
                                <div className="invalid-feedback">
                                  {errors.serverKeyPath}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Description <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                type="textarea"
                                as="textarea"
                                name="description"
                                label="description"
                                className={`form-control m-b-5  ${
                                  errors.description && touched.description
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                placeholder="Enter Description"
                              />
                              {errors.description && touched.description ? (
                                <div className="invalid-feedback">
                                  {errors.description}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Storage Path{' '}
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                type="text"
                                name="storage_pool"
                                label="storage_pool"
                                className={`form-control m-b-5  ${
                                  errors.storage_pool && touched.storage_pool
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                placeholder="Enter NFV Config Storage Path"
                              />
                              {errors.storage_pool && touched.storage_pool ? (
                                <div className="invalid-feedback">
                                  {errors.storage_pool}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Proxy Tunnel IP{' '}
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                type="text"
                                name="proxyIp"
                                label="proxyIp"
                                className={`form-control m-b-5  ${
                                  errors.proxyIp && touched.proxyIp
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                placeholder="Enter NFV Config Proxy Tunnel IP"
                              />
                              {errors.proxyIp && touched.proxyIp ? (
                                <div className="invalid-feedback">
                                  {errors.proxyIp}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </>
                      );
                      break;
                    case 'Agent':
                      ActiveForm = (
                        <MyTextArea
                          label="Agent Configuration"
                          name="config"
                          rows="30"
                          placeholder="Enter Agent Configuration"
                        />
                      );
                      break;
                    case 'Provisioning':
                      ActiveForm = (
                        <MyTextArea
                          label="Provisioning Script"
                          name="script"
                          rows="30"
                          placeholder="Enter Provisioning Script"
                        />
                      );
                      break;
                    case 'Installer':
                      ActiveForm = (
                        <MyTextArea
                          label="Agent Installer Script"
                          name="agentInstaller"
                          rows="30"
                          placeholder="Enter Agent Installer Script"
                        />
                      );
                      break;
                    case 'Decommission':
                      ActiveForm = (
                        <MyTextArea
                          label="Decommission Agent Commands"
                          name="decommission"
                          rows="30"
                          placeholder="Enter Decommission Agent Commands"
                        />
                      );
                      break;
                    case 'Release Notes':
                      ActiveForm = (
                        <MyTextArea
                          label="Agent Release Notes"
                          name="releaseNotes"
                          rows="30"
                          placeholder="Enter Agent Release Notes"
                        />
                      );
                      break;
                    default:
                      break;
                  }

                  return (
                    <Form>
                      {ActiveForm}
                      <button
                        type="submit"
                        className="btn btn-primary btn-block btn-lg m-b-5"
                        disabled={!isValid || isSubmitted}
                      >
                        Submit
                      </button>
                    </Form>
                  );
                }}
              </Formik>
            </Panel>
          )}
        </div>
      </div>
    </div>
  );
};

export default NFVConfigUpdate;
