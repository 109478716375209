import React, { useEffect, useState } from 'react';
import Panel from '../../../common/Panel/Panel';
import DrawGraph from './DrawGraph';
import { getElasticSearchData } from './api/elasticsearch';
import moment from 'moment';
import tz from 'moment-timezone';

import autonomous_systems_flow_pie from '../../../common/graphs/vega/autonomous_systems_flow_pie.json';
import autonomous_systems_flow_dest_pie from '../../../common/graphs/vega/autonomous_systems_flow_dest_pie.json';

import client_bits_bar from '../../../common/graphs/vega/client_bits_bar.json';
import server_bits_bar from '../../../common/graphs/vega/server_bits_bar.json';

import client_packets_bar from '../../../common/graphs/vega/client_packets_bar.json';
import server_packets_bar from '../../../common/graphs/vega/server_packets_bar.json';

import services_bits_bar from '../../../common/graphs/vega/services_bits_bar.json';
import services_packets_bar from '../../../common/graphs/vega/services_packets_bar.json';

import autonomous_systems_flow_pie_request from '../../../common/graphs/vega/autonomous_systems_flow_pie_request.json';
import autonomous_systems_flow_dest_pie_request from '../../../common/graphs/vega/autonomous_systems_flow_dest_pie_request.json';

import client_bits_bar_request from '../../../common/graphs/vega/client_bits_bar_request.json';
import server_bits_bar_request from '../../../common/graphs/vega/server_bits_bar_request.json';

import client_packets_bar_request from '../../../common/graphs/vega/client_packets_bar_request.json';
import server_packets_bar_request from '../../../common/graphs/vega/server_packets_bar_request.json';

import services_bits_bar_request from '../../../common/graphs/vega/services_bits_bar_request.json';
import services_packets_bar_request from '../../../common/graphs/vega/services_packets_bar_request.json';

import autonomous_systems_flow_dest_table_request from '../../../common/graphs/vega/autonomous_systems_flow_dest_table_request.json';
import autonomous_systems_flow_table_request from '../../../common/graphs/vega/autonomous_systems_flow_table_request.json';

const TrafficDetails = ({ lte, gte, ntu }) => {
  const [tableClientFlow, setTableClientFlow] = useState(0);
  const [tableServerFlow, setTableServerFlow] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  var serial;
  var ignore_throttled = 1;
  const getLTE = () => {
    if (lte == null || lte == '') {
      lte = new Date();
      lte = moment();
      lte = lte._d.getTime();
    }
    return lte;
  };
  const getGTE = () => {
    if (gte == null || gte == '') {
      gte = new Date();
      gte = moment().subtract(3, 'hours');
      gte = gte._d.getTime();
    }
    return gte;
  };
  const TableClientFlow = async () => {
    serial = ntu.serialNumber;
    if (ntu.mode == 'VIRTUAL') {
      serial = ntu.peSerialNumber;
    }
    autonomous_systems_flow_dest_table_request.query.bool.filter.push({
      term: { 'serial-number': serial },
    });
    //data.query.bool.must[0].query_string["query"] = "serial-number: " + serial;
    autonomous_systems_flow_dest_table_request.query.bool.must[0].range[
      '@timestamp'
    ].gte = getGTE();
    autonomous_systems_flow_dest_table_request.query.bool.must[0].range[
      '@timestamp'
    ].lte = getLTE();
    try {
      let response = await getElasticSearchData('search', {
        ignoreThrottled: ignore_throttled,
        index: 'elastiflow-3.5.1-*',
        size: 0,

        body: JSON.stringify(autonomous_systems_flow_dest_table_request),
      }).then((res) => res.json());
      console.log(response);

      setTableClientFlow(response.aggregations.results.buckets.length);

      setIsLoading(true);
    } catch (error) {
      console.log(error);
    }
  };

  const TableServerFlow = async () => {
    serial = ntu.serialNumber;
    if (ntu.mode == 'VIRTUAL') {
      serial = ntu.peSerialNumber;
    }
    autonomous_systems_flow_table_request.query.bool.filter.push({
      term: { 'serial-number': serial },
    });
    //data.query.bool.must[0].query_string["query"] = "serial-number: " + serial;
    autonomous_systems_flow_table_request.query.bool.must[0].range[
      '@timestamp'
    ].gte = getGTE();
    autonomous_systems_flow_table_request.query.bool.must[0].range[
      '@timestamp'
    ].lte = getLTE();
    try {
      let response = await getElasticSearchData('search', {
        ignoreThrottled: ignore_throttled,
        index: 'elastiflow-3.5.1-*',
        size: 0,

        body: JSON.stringify(autonomous_systems_flow_table_request),
      }).then((res) => res.json());
      console.log(response);

      setTableServerFlow(response.aggregations.results.buckets.length);

      setIsLoading2(true);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    TableClientFlow();
    TableServerFlow();
  }, []);
  return (
    <div>
      <div className="row">
        <div className="col-lg-4  col-md-6 col-sm-12 col-xs-12">
          <Panel heading="Client flow">
            <div>
              <DrawGraph
                lte={lte}
                gte={gte}
                ntu={ntu}
                esRequest={autonomous_systems_flow_pie_request}
                graphDiv={'#source_autonomous_bits'}
                vegaGraph={autonomous_systems_flow_pie}
              />
            </div>
          </Panel>
        </div>

        <div className="col-lg-4  col-md-6 col-sm-12 col-xs-12">
          <Panel heading="Server flow">
            <div>
              <DrawGraph
                lte={lte}
                gte={gte}
                ntu={ntu}
                esRequest={autonomous_systems_flow_dest_pie_request}
                graphDiv={'#destination_autonomous_bits'}
                vegaGraph={autonomous_systems_flow_dest_pie}
              />
            </div>
            <div></div>
          </Panel>
        </div>
        <div className="col-lg-4">
          <div className="p-3 bg-warning-700 rounded overflow-hidden position-relative text-white mb-1">
            <div className>
              <h3 className="display-4 d-block l-h-n m-0 fw-500">
                {tableClientFlow}
                <small className="m-0 l-h-n">Clients</small>
              </h3>
            </div>
            <i
              className="fal fa-poll-people position-absolute pos-right pos-bottom opacity-15 mb-n1 mr-n1"
              style={{ fontSize: '6rem' }}
            />
          </div>
          <div className="p-3 bg-success-700 rounded overflow-hidden position-relative text-white mb-1">
            <div className>
              <h3 className="display-4 d-block l-h-n m-0 fw-500">
                {tableServerFlow}
                <small className="m-0 l-h-n">Servers</small>
              </h3>
            </div>
            <i
              className="fal fa-server position-absolute pos-right pos-bottom opacity-15 mb-n1 mr-n1"
              style={{ fontSize: '6rem' }}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6  col-md-6 col-sm-12 col-xs-12">
          <Panel heading="Client bits per second">
            <div>
              <DrawGraph
                lte={lte}
                gte={gte}
                ntu={ntu}
                esRequest={client_bits_bar_request}
                graphDiv={'#client_bits'}
                vegaGraph={client_bits_bar}
              />
            </div>
          </Panel>
        </div>
        <div className="col-lg-6  col-md-6 col-sm-12 col-xs-12">
          <Panel heading="Server bits per second">
            <div>
              <DrawGraph
                lte={lte}
                gte={gte}
                ntu={ntu}
                esRequest={server_bits_bar_request}
                graphDiv={'#server_bits'}
                vegaGraph={server_bits_bar}
              />
            </div>
          </Panel>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6  col-md-6 col-sm-12 col-xs-12">
          <Panel heading="Client packets per second">
            <div>
              <DrawGraph
                lte={lte}
                gte={gte}
                ntu={ntu}
                esRequest={client_packets_bar_request}
                graphDiv={'#client_packets'}
                vegaGraph={client_packets_bar}
              />
            </div>
          </Panel>
        </div>
        <div className="col-lg-6  col-md-6 col-sm-12 col-xs-12">
          <Panel heading="Server packets per second">
            <div>
              <DrawGraph
                lte={lte}
                gte={gte}
                ntu={ntu}
                esRequest={server_packets_bar_request}
                graphDiv={'#server_packets'}
                vegaGraph={server_packets_bar}
              />
            </div>
          </Panel>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6  col-md-6 col-sm-12 col-xs-12">
          <Panel
            heading="Services bits per second
"
          >
            <div>
              <DrawGraph
                lte={lte}
                gte={gte}
                ntu={ntu}
                esRequest={services_bits_bar_request}
                graphDiv={'#services_bits'}
                vegaGraph={services_bits_bar}
              />
            </div>
          </Panel>
        </div>
        <div className="col-lg-6  col-md-6 col-sm-12 col-xs-12">
          <Panel heading="Services packets per second">
            <div>
              <DrawGraph
                lte={lte}
                gte={gte}
                ntu={ntu}
                esRequest={services_packets_bar_request}
                graphDiv={'#services_packets'}
                vegaGraph={services_packets_bar}
              />
            </div>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default TrafficDetails;
