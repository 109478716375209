import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";

import { getConnection } from "../../api/peeringExchangeApi";

const initialValues = {
  provisioningStatus: "",
  status: "",
};

export const ServiceStatusDialog = ({ id, show, onHide }) => {
  const [connection, setConnection] = useState(null);

  useEffect(() => {
    const fetcher = async () => {
      try {
        const connRes = await getConnection(id).then((res) => res.json());
        setConnection(connRes);
      } catch (error) {}
    };
    if (!connection && id) {
      fetcher();
    }
  }, []);

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={connection ? connection : initialValues}
        onSubmit={(values) => {
          // saveAttribute(values);
          onHide();
        }}
      >
        {({ values }) => {
          return (
            <div
              className={`modal fade overflow-auto ${show && "show d-block"}`}
            >
              <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <div className="d-flex w-100 align-items-center justify-content-between">
                      <h1>Change Service Status</h1>
                      <button type="button" className="close" onClick={onHide}>
                        <span aria-hidden="true">
                          <i className="fal fa-times"></i>
                        </span>
                      </button>
                    </div>
                  </div>
                  <div className="modal-body">
                    <p>
                      Note: You can only update a product when its provisioning
                      status is CONFIGURED or LIVE. When you CANCEL a service,
                      you are giving 30 days notice. The service will remain
                      operational until the end of the 30th day, at which time
                      it will be decommissioned. At any time before
                      decommissioning, you may UN_CANCEL the service, and it
                      will revert to LIVE status, and it will continue as if
                      nothing has happened. When you CANCEL_NOW, the service is
                      decommissioned immediately, and cannot be recovered by an
                      UN_CANCEL.
                    </p>

                    <Form>
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Service Status
                        </label>
                        <div className="col-md-9">
                          <Field
                            readOnly
                            type="text"
                            name="provisioningStatus"
                            className="form-control m-b-5"
                          />
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Change Status
                        </label>
                        <div className="col-md-9">
                          <Field
                            as="select"
                            name="status"
                            className={`form-control m-b-5`}
                          >
                            <option value=""></option>
                            {[
                              { name: "CANCEL", value: "CANCEL" },
                              { name: "UN_CANCEL", value: "UN_CANCEL" },
                              { name: "CANCEL_NOW", value: "CANCEL_NOW" },
                            ].map((status, i) => (
                              <option key={i} value={status.value}>
                                {status.name}
                              </option>
                            ))}
                          </Field>
                        </div>
                      </div>
                    </Form>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary waves-effect waves-themed"
                      onClick={onHide}
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary waves-effect waves-themed"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
    </>
  );
};
