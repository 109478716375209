import React, { useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Panel from "../../../common/Panel/Panel";
import Table from "../../../common/Table";

import { handleDeleteAlert } from "../../../common/Alerts/DeleteAlert";
const AuthorityRoles = ({ entities }) => {
  const data = entities;

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        sortable: true,
        Cell: ({ row }) => {
          return (
            <div>
              <Link to={`${row.original.id}`}>{row.original.id}</Link>
            </div>
          );
        },
      },
      {
        Header: "Name",
        accessor: "name",
        sortable: true,
      },
      {
        Header: "Description",
        accessor: "description",
        sortable: true,
      },
      {
        Header: "Roles",
        accessor: (d) =>
          d.authorities?.map((authority, i) => (
            <button className="btn btn-light mr-1 mb-1" key={i}>
              {authority}
            </button>
          )),
        sortable: true,
      },
      {
        Header: "Priority",
        accessor: "priority",
        sortable: true,
      },
      {
        Header: "",
        accessor: "actionColumns",
        className: "align-middle",
        Cell: ({ row }) => {
          return (
            <div className="text-center d-flex">
              <Link
                className="btn btn-icon btn-circle btn-default mr-2"
                to={`${row.original.id}`}
              >
                <i className="fal fa-eye"></i>
              </Link>
              <Link
                className="btn btn-icon btn-circle btn-success mr-2"
                to={`../edit/${row.original.id}`}
              >
                <i className="fal fa-pencil-alt"></i>
              </Link>
              <button
                className="btn btn-icon btn-circle btn-danger"
                onClick={handleDeleteAlert}
              >
                <i className="fal fa-trash"></i>
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <Panel>
      <Table data={data} columns={columns} />
    </Panel>
  );
};

export default AuthorityRoles;
