import React from 'react';
import PropTypes from 'prop-types';

/**
 * @category COMMON
 * @component
 * @description Use custom button styles for actions in forms, dialogs, and more. Includes support for a handful of contextual variations, sizes, states, and more
 * @example <caption>How to use Button component</caption>
 * return (
 * <Button onClick={() => setToggleModal(true)} text="Select favicon" type="button"icon="file"/>
 * )
 */

const Button = ({
  children,
  name,
  size,
  outline,
  icon,

  type,
  disabled,
  onClick,
}) => {
  return (
    <button
      disabled={disabled}
      type={type}
      className={`btn  waves-effect waves-themed ${
        outline ? `btn-outline-${name}` : `btn-${name}`
      } ${size ? `btn-${size}` : ``}`}
      onClick={!disabled ? onClick : () => {}}
    >
      {icon && <span className={`fal mr-1 fa-${icon}`}></span>}
      {children}
    </button>
  );
};

Button.defaultProps = {
  name: 'primary',
  outline: false,
  text: 'Button',
  type: 'button',
  disabled: false,
};

Button.propTypes = {
  name: PropTypes.string,
  size: PropTypes.string,
  outline: PropTypes.bool,
  children: PropTypes.node,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  onClick: PropTypes.func,
};

export default Button;
