import React, { useState, useEffect, useRef } from 'react';

import { MegaportUserService } from './api/megaporst-user-service';
import { NtuPortService } from './api/ntu-port-service';

import SnmpLatestRequest from './queries/snmp/snmp_latest_request.json';
import SnmpLatestHCRequest from './queries/snmp/snmp_latest_hc_request.json';
import SNMPInterfaceHCStatsPortRequest from './queries/snmp/snmp_interface_hc_stats_port_request.json';
import HeartbeatRTTRequest from './queries/heartbeat_rtt_request.json';
import AlarmsSyslogRequest from './queries/alarms_syslog_request.json';
import SNMPSystemStatsRequest from './queries/snmp/snmp_system_request.json';
import SNMPSystemCPULoadLineRequest from './queries/snmp/snmp_system_cpu_load_line_request.json';
import SNMPSystemMemoryLineRequest from './queries/snmp/snmp_system_memory_line_request.json';
import SNMPSystemTemperatureLineRequest from './queries/snmp/snmp_system_temperature_line_request.json';

import { getElasticSearchData } from './api/elasticsearch';
import moment from 'moment';
import tz from 'moment-timezone';
import './overview.scss';
import Overview from './Overview';
import PortsTraffic from './PortsTraffic';

const AnalyticsDashboard = ({
  NTU,
  onNTUPortsChange,
  currentPort,
  lte,
  gte,
  getDateTime,
}) => {
  const [portUtilData, setPortUtilData] = useState({});
  const [portUtilGraph, setPortUtilGraph] = useState(false);
  const [portUtilBarPacketsData, setPortUtilBarPacketsData] = useState({});
  const [portUtilBarPacketsGraph, setPortUtilBarPacketsGraph] = useState(false);
  const portUtilBarPacketRef = useRef(null);
  const [ntuBytesPieData, setNtuBytesPieData] = useState('');
  const [ntuBytesPieGraph, setNtuBytesPieGraph] = useState(false);
  const [ntuPacketsPieData, setNtuPacketsPieData] = useState({});
  const [ntuPacketsPieGraph, setNtuPacketsPieGraph] = useState(false);
  const [portsBytesPieData, setPortsBytesPieData] = useState();
  const [portsBytesPieGraph, setPortsBytesPieGraph] = useState(false);
  const [portsPacketsPieData, setPortsPacketsPieData] = useState({});
  const [portsPacketsPieGraph, setPortsPacketsPieGraph] = useState(false);
  const [systemStats, setSystemStats] = useState({});
  const [
    interfaceStatsInputBytesLineData,
    setInterfaceStatsInputBytesLineData,
  ] = useState({});
  const [
    interfaceStatsInputBytesLineGraph,
    setInterfaceStatsInputBytesLineGraph,
  ] = useState(false);
  const [
    interfaceStatsInputPacketsLineData,
    setInterfaceStatsInputPacketsLineData,
  ] = useState({});
  const [
    interfaceStatsInputPacketsLineGraph,
    setInterfaceStatsInputPacketsLineGraph,
  ] = useState(false);
  const [snmpSystemCPULoadLineData, setSNMPSystemCPULoadLineData] = useState(
    {}
  );
  const [snmpSystemCPULoadLineGraph, setSNMPSystemCPULoadLineGraph] =
    useState(false);
  const [snmpSystemMemoryLineData, setSNMPSystemMemoryLineData] = useState({});
  const [snmpSystemMemoryLineGraph, setSNMPSystemMemoryLineGraph] =
    useState(false);
  const [snmpSystemTemperatureLineData, setSNMPSystemTemperatureLineData] =
    useState({});
  const [snmpSystemTemperatureLineGraph, setSNMPSystemTemperatureLineGraph] =
    useState(false);
  const [latencyData, setLatencyData] = useState({});
  const [latencyGraph, setLatencyGraph] = useState(false);
  const [systemCPUData, setSystemCPUData] = useState({});
  const [systemCPUGraph, setSystemCPUGraph] = useState(false);
  const [systemMemData, setSystemMemData] = useState({});
  const [systemMemGraph, setSystemMemGraph] = useState(false);
  const [autorefresh, setAutorefresh] = useState(false);
  const [ntuPortsDropdown, setNtuPortsDropdown] = useState([]);
  const [tab, setTab] = useState(false);

  const ntu = NTU;

  let Port = '';
  let has_temp = 0;
  let services = {};
  let ntuPorts = [];
  let serial;
  let model;
  let Ports;
  let PortHC;
  let PortsHC = [];
  let vrPort;

  let ignore_throttled = 1;
  let rollover_detected = false;
  let lefts = [];
  let stats;
  let statsData = [];
  let input_bytes_g;
  let input_packets_g;
  let TableAlarms;
  let totalItemsClient;
  let queryCountClient;
  /* defaults */
  var ntu_ostats = {
    inputPackets: 0,
    outputPackets: 0,
    inputBytes: 0,
    outputBytes: 0,
  };

  var port_ostats = {
    inputPackets: 0,
    outputPackets: 0,
    inputBytes: 0,
    outputBytes: 0,
  };

  let ntuStats = ntu_ostats;
  let portStats = port_ostats;
  let SystemStats = {};
  let greenconfig = {
    fade: true,
    alwaysOn: true,
    neverOn: false,
    // fill
    fill: true,
    fillColor: '01DF3A',
    fillOpacity: 0.4,
    // stroke
    stroke: false,
    strokeColor: '4d0ec0',
    strokeOpacity: 1,
    strokeWidth: 1,
    // shadow:
    shadow: true,
    shadowColor: '01DF3A',
    shadowOpacity: 0.8,
    shadowRadius: 10,
  };

  let redconfig = {
    fade: true,
    alwaysOn: true,
    neverOn: false,
    // fill
    fill: true,
    fillColor: 'ff0040',
    fillOpacity: 0.4,
    // stroke
    stroke: false,
    strokeColor: '4d0ec0',
    strokeOpacity: 1,
    strokeWidth: 1,
    // shadow:
    shadow: true,
    shadowColor: 'ff0000',
    shadowOpacity: 0.8,
    shadowRadius: 10,
  };

  function secondsToTime(inputSeconds) {
    inputSeconds = inputSeconds / 100;
    const Days = Math.floor(inputSeconds / (60 * 60 * 24));
    const Hour = Math.floor((inputSeconds % (60 * 60 * 24)) / (60 * 60));
    const Minutes = Math.floor(
      ((inputSeconds % (60 * 60 * 24)) % (60 * 60)) / 60
    );
    const Seconds = Math.floor(
      ((inputSeconds % (60 * 60 * 24)) % (60 * 60)) % 60
    );
    let ddhhmmss = '';
    if (Days > 1) {
      ddhhmmss += Days + ' Days ';
    } else if (Days == 1) {
      ddhhmmss += Days + ' Day ';
    }
    if (Hour > 1) {
      ddhhmmss += Hour + ' Hours ';
    } else if (Hour == 1) {
      ddhhmmss += Hour + ' Hour ';
    }
    if (Minutes > 1) {
      ddhhmmss += Minutes + ' Minutes ';
    } else if (Minutes == 1) {
      ddhhmmss += Minutes + ' Minute ';
    }
    if (Seconds > 1) {
      ddhhmmss += Seconds + ' Seconds ';
    } else if (Seconds == 1) {
      ddhhmmss += Seconds + ' Second ';
    }
    return ddhhmmss;
  }

  const getLTE = () => {
    if (lte == null || lte == '') {
      lte = new Date();
      lte = moment();
      lte = lte._d.getTime();
    }
    return lte;
  };
  const getGTE = () => {
    if (gte == null || gte == '') {
      gte = new Date();
      gte = moment().subtract(2, 'hours');
      gte = gte._d.getTime();
    }
    return gte;
  };

  const getPortStatsSNMP = async () => {
    //console.log('getPortStatsSNMP');
    // ports util bar
    // ports util bar packets

    var i = 0;
    var inputPackets = 0;
    var outputPackets = 0;
    var inputBytes = 0;
    var outputBytes = 0;

    if (ntuPorts.length > 0) {
      if (NTU.mode !== 'SD_WAN') {
        ntuPorts.sort(function (a, b) {
          return a.id - b.id;
        });
      } else {
        //console.log('vm.ntuPorts PRE: ', ntuPorts);
        for (var idx = 0; idx < ntuPorts.length; idx++) {
          if (
            ntuPorts[idx].results.hits.hits[0]._source.interfaces.index !==
            undefined
          ) {
            ntuPorts[idx].ifindex =
              ntuPorts[idx].results.hits.hits[0]._source.interfaces.index;
          }
        }
        ntuPorts.sort(function (a, b) {
          return a.ifindex - b.ifindex;
        });
        ////console.log('vm.ntuPorts POST: ', vm.ntuPorts);
      }
      //                 //console.log('vm.PortsHC: ', vm.PortsHC);

      //vm.ntuPorts.sort(function(a, b){return a.port_num - b.port_num});
      ////console.log('sorted ports: ', vm.ntuPorts);

      for (var idx = 0; idx < ntuPorts.length; idx++) {
        var operStatus = 'down';
        var colour = redconfig;

        ////console.log('working on ', vm.ntuPorts[idx].name);

        if (NTU.mode !== 'SD_WAN') {
          var ntuPort = ntuPorts[idx].name;
          ntuPorts[idx].key = ntuPort;
        } else {
          var ntuPort = ntuPorts[idx].key;
        }

        // find port in non-HC stats for operStatus
        for (var x = 0; x < Ports.length; x++) {
          var sPort = Ports[x];

          var key = sPort.key;
          if (key === ntuPort) {
            if (sPort.results.hits.hits.length > 0) {
              if (sPort.results.hits.hits[0]._source.interfaces !== undefined) {
                var interface_data =
                  sPort.results.hits.hits[0]._source.interfaces;
                if (interface_data.ifOperStatus == 1) {
                  operStatus = 'up';
                  colour = greenconfig;
                }
              }
            }
            break;
          }
        }
        // find port in stats

        for (var x = 0; x < PortsHC.length; x++) {
          var sPort = PortsHC[x];
          var key = sPort.key;

          if (key === ntuPort) {
            // found
            // //console.log('found port', key);
            if (
              sPort.latest_results.hits.hits.length > 0 &&
              sPort.first_results.hits.hits.length > 0
            ) {
              if (
                sPort.latest_results.hits.hits[0]._source.interfaces !==
                  undefined &&
                sPort.first_results.hits.hits[0]._source.interfaces !==
                  undefined
              ) {
                var latest_interface_data =
                  sPort.latest_results.hits.hits[0]._source.interfaces;
                var first_interface_data =
                  sPort.first_results.hits.hits[0]._source.interfaces;
                // //console.log('interface_data ', latest_interface_data);

                if (
                  latest_interface_data.ifHCInUcastPkts <
                  first_interface_data.ifHCInUcastPkts
                ) {
                  rollover_detected = true;
                }
                if (
                  latest_interface_data.ifHCInOctets <
                  first_interface_data.ifHCInOctets
                ) {
                  rollover_detected = true;
                }

                // //console.log('vm.rollover_detected = ', rollover_detected);

                if (!rollover_detected) {
                  var ifHCInUcastPkts =
                    latest_interface_data.ifHCInUcastPkts -
                    first_interface_data.ifHCInUcastPkts;
                  var ifHCOutUcastPkts =
                    latest_interface_data.ifHCOutUcastPkts -
                    first_interface_data.ifHCOutUcastPkts;
                  var ifHCInOctets =
                    latest_interface_data.ifHCInOctets -
                    first_interface_data.ifHCInOctets;
                  var ifHCOutOctets =
                    latest_interface_data.ifHCOutOctets -
                    first_interface_data.ifHCOutOctets;
                } else {
                  var ifHCInUcastPkts = latest_interface_data.ifHCInUcastPkts;
                  var ifHCOutUcastPkts = latest_interface_data.ifHCOutUcastPkts;
                  var ifHCInOctets = latest_interface_data.ifHCInOctets;
                  var ifHCOutOctets = latest_interface_data.ifHCOutOctets;
                }

                var ostats = {
                  id: x,
                  name: key,
                  ifindex: latest_interface_data.index,
                  speed: latest_interface_data.ifHighSpeed,
                  inputPackets: ifHCInUcastPkts,
                  outputPackets: ifHCOutUcastPkts,
                  inputBytes: ifHCInOctets,
                  outputBytes: ifHCOutOctets,
                  operStatus: operStatus,
                  showPopover: false,
                  colour: colour,
                };

                statsData[i] = ostats;

                lefts[latest_interface_data.index] =
                  latest_interface_data.index * 80;
                i++;

                inputBytes += ifHCInOctets;
                outputBytes += ifHCOutOctets;
                inputPackets += ifHCInUcastPkts;
                outputPackets += ifHCOutUcastPkts;

                // selected port

                if (key === Port) {
                  //console.log('check');
                  portStats.inputPackets = ifHCInUcastPkts;
                  portStats.outputPackets = ifHCOutUcastPkts;
                  portStats.inputBytes = ifHCInOctets;
                  portStats.outputBytes = ifHCOutOctets;
                }
              }
              //console.log(portStats);
            }
            break;
          }
        }
      }
    }

    console.log('vm.statsData ', statsData);
    //vm.PortsHC = vm.ntuPorts;

    if (statsData.length > 0) {
      ////console.log('vm.statsData', vm.statsData);
      /* do Port Util Graph */
      const dataToObjects = statsData;
      const newData = { table: dataToObjects };
      //console.log('newData:', newData);

      console.log('CHECK DATA: ', newData);
      setPortUtilData(newData);

      setPortUtilBarPacketsData(newData);
    }

    ntuStats.inputPackets = inputPackets;
    ntuStats.outputPackets = outputPackets;
    ntuStats.inputBytes = inputBytes;
    ntuStats.outputBytes = outputBytes;
    if (ntuStats != null) {
      console.log('vm.ntuStats', ntuStats);

      setNtuBytesPieData({ table: { ...ntuStats } });
      setNtuBytesPieGraph(true);

      setNtuPacketsPieData({ table: { ...ntuStats } });
      setNtuPacketsPieGraph(true);
    }

    if (Port != '' && portStats) {
      console.log('vm.portStats', portStats);

      setPortsBytesPieData({ table: { ...portStats } });
      setPortsBytesPieGraph(true);

      setPortsPacketsPieData({ table: { ...portStats } });
      setPortsPacketsPieGraph(true);
    }
  };

  const getPortsSNMP = async () => {
    //console.log('getPortsSNMP()');

    var gte_n = getGTE();
    var lte_n = getLTE();
    serial = NTU.serialNumber;
    if (NTU.mode == 'VIRTUAL') {
      serial = NTU.peSerialNumber;
    }
    SnmpLatestRequest.query.bool.must[1].range['@timestamp'].gte = gte_n;
    SnmpLatestRequest.query.bool.must[1].range['@timestamp'].lte = lte_n;
    SnmpLatestRequest.query.bool.must[0].query_string['query'] =
      "serial-number: '" + serial + "'";

    if (NTU.mode == 'VIRTUAL') {
      //console.log('Virtual');
      try {
        let response = await getElasticSearchData(
          'searchNoTenantIgnoreLoading',
          {
            index: 'snmp-interface-non-hc-*',
            size: 1,
            ignoreThrottled: ignore_throttled,
            body: JSON.stringify(SnmpLatestRequest),
          }
        ).then((res) => res.json());
        if (response.aggregations.ports.buckets.length > 0) {
          Ports = response.aggregations.ports.buckets;
          //console.log('Ports: ', Ports);
          // find our Port (single)
          if (ntuPorts.length == 1) {
            for (var idx = 0; idx < ntuPorts.length; idx++) {
              var ntuPort = ntuPorts[idx].key;
              ////console.log('looking for ', ntuPort);
              // find port in stats
              for (var x = 0; x < Ports.length; x++) {
                var sPort = Ports[x];
                if (sPort.key === ntuPort) {
                  //console.log('found ntuPort');
                  if (sPort.results.hits.hits.length > 0) {
                    if (
                      sPort.results.hits.hits[0]._source.interfaces !==
                      undefined
                    ) {
                      vrPort = sPort.results.hits.hits[0]._source.interfaces;
                      //console.log('vm.vrPort ', vrPort);
                    }
                  }
                  break;
                }
              }
            }
          }
        }
        await getPortsHCSNMP();
      } catch (error) {
        console.log(error);
      }
    } else {
      //console.log('not virtual');
      try {
        let response = await getElasticSearchData('searchIgnoreLoading', {
          index: 'snmp-interface-non-hc-*',
          size: 0,
          ignoreThrottled: ignore_throttled,
          body: JSON.stringify(SnmpLatestRequest),
        }).then((res) => res.json());

        if (response.aggregations.ports.buckets.length > 0) {
          Ports = response.aggregations.ports.buckets;
          //console.log('Ports: ', Ports);
          // Correct till here
          await getPortsHCSNMP();
          //                                getPortStatsSNMP();
          //getPortStats('interface_stats_port_request.json');
        }
      } catch (error) {
        console.log('vega load error: ' + error);
      }
    }
  };

  const getPortsHCSNMP = async () => {
    console.log('getPortsHCSNMP');

    var gte_n = getGTE();
    var lte_n = getLTE();
    serial = NTU.serialNumber;
    if (NTU.mode == 'VIRTUAL') {
      serial = NTU.peSerialNumber;
    }
    SnmpLatestHCRequest.query.bool.must[1].range['@timestamp'].gte = gte_n;
    SnmpLatestHCRequest.query.bool.must[1].range['@timestamp'].lte = lte_n;
    SnmpLatestHCRequest.query.bool.must[0].query_string['query'] =
      "serial-number: '" + serial + "'";

    if (NTU.mode == 'VIRTUAL') {
      try {
        let response = await getElasticSearchData(
          'searchNoTenantIgnoreLoading',
          {
            index: 'snmp-interface-hc-*',
            size: 1,
            ignoreThrottled: ignore_throttled,
            body: JSON.stringify(SnmpLatestHCRequest),
          }
        ).then((res) => res.json());
        if (response.aggregations.ports.buckets.length > 0) {
          PortsHC = response.aggregations.ports.buckets;
          //console.log('PortsHC: ', PortsHC);
          // find our Port (single)

          await getPortStatsSNMP();
          //setVegaData(newData);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      //console.log('not virtual');
      try {
        let response = await getElasticSearchData('searchIgnoreLoading', {
          index: 'snmp-interface-hc-*',
          size: 1,
          ignoreThrottled: ignore_throttled,
          body: JSON.stringify(SnmpLatestHCRequest),
        }).then((res) => res.json());
        //console.log('Ports HC SNMP Data: ', response);

        if (response.aggregations.ports.buckets.length > 0) {
          PortsHC = response.aggregations.ports.buckets;
          console.log('PortsHC: ', PortsHC);
          await getPortStatsSNMP();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const drawGraphSNMP_response = async (response, div) => {
    console.log('div::::', div);
    let dataToObjects;
    let newData;
    if (response.aggregations.results.buckets.length > 0) {
      //skip first row missing serial_diff data
      if (
        div === '#input_packets' ||
        div === '#input_bytes' ||
        div === '#latency'
      ) {
        response.aggregations.results.buckets.shift();
      }

      if (div === '#cpu_load') {
        dataToObjects = response.aggregations.results.buckets;
        newData = { table: dataToObjects };
        console.log('INTERFACCE STATS INPUT DATA: ', newData);
        setSNMPSystemCPULoadLineData(newData);
        setSNMPSystemCPULoadLineGraph(true);
      } else if (div === '#system_load') {
      } else if (div === '#system_memory') {
        dataToObjects = response.aggregations.results.buckets;
        newData = { table: dataToObjects };
        console.log('INTERFACCE STATS INPUT DATA: ', newData);
        setSNMPSystemMemoryLineData(newData);
        setSNMPSystemMemoryLineGraph(true);
      } else if (div === '#system_temperature') {
        dataToObjects = response.aggregations.results.buckets;
        newData = { table: dataToObjects };
        console.log('INTERFACCE STATS INPUT DATA: ', newData);
        setSNMPSystemTemperatureLineData(newData);
        setSNMPSystemTemperatureLineGraph(true);
      } else if (div === '#input_bytes' && Port != '') {
        //
        dataToObjects = response.aggregations.results.buckets;
        newData = { table: dataToObjects };
        console.log('INTERFACCE STATS INPUT DATA: ', newData);
        setInterfaceStatsInputBytesLineData(newData);
        setInterfaceStatsInputBytesLineGraph(true);
      } else if (div === '#input_packets' && Port != '') {
        dataToObjects = response.aggregations.results.buckets;
        newData = { table: dataToObjects };

        setInterfaceStatsInputPacketsLineData(newData);
        setInterfaceStatsInputPacketsLineGraph(true);
      } else if (div === '#latency') {
        dataToObjects = response.aggregations.results.buckets;
        newData = { table: dataToObjects };
        setLatencyData(newData);
        //console.log('Latency', newData);
        setLatencyGraph(true);
      }
    } else {
      if (div === '#input_packets') {
        if (input_packets_g != null) {
          input_packets_g.finalize();
          input_packets_g._el.innerHTML = 'No Data';
        }
      }
      if (div === '#input_bytes') {
        if (input_bytes_g != null) {
          input_bytes_g.finalize();
          input_bytes_g._el.innerHTML = 'No Data';
        }
      }
    }
  };
  const drawGraphSNMP = async (esIndex, request, div) => {
    console.log('drawGraphSNMP', div);

    var gte_n = getGTE();
    var lte_n = getLTE();

    if (ntu.mode == 'VIRTUAL') {
      serial = ntu.peSerialNumber;
    } else {
      serial = ntu.serialNumber;
    }

    if (div === '#latency') {
      request.query.bool.must[0].query_string['query'] =
        'monitor.ip: ' + ntu.ipAddress;
    } else {
      request.query.bool.must[0].query_string['query'] =
        "(serial-number: '" + serial + "')";
    }

    request.query.bool.must[1].range['@timestamp'].gte = gte_n;
    request.query.bool.must[1].range['@timestamp'].lte = lte_n;
    var port = Port;
    var vlan = 0;

    if (ntu.mode == 'VIRTUAL') {
      // find our service
      if (services.length > 0) {
        ////console.log('vm.services ', vm.services);
        for (var x = 0; x < services.length; x++) {
          if (services[x].ntuPort !== null) {
            if (services[x].ntuPort.name == Port) {
              //console.log('Found Services for Port: ', Port);
              vlan = services[x].vlanIdA;
              //console.log('found vlanIdA: ', vlan);
              port = services[x].ntuPort.name + '.' + vlan;
              break;
            }
          }
        }
      }
    }
    if (Port !== '' && (div === '#input_packets' || div === '#input_bytes')) {
      request.query.bool.must[0].query_string['query'] =
        "(serial-number: '" + serial + "')";
      request.query.bool.filter[0].exists['field'] = 'interfaces.ifName';
      request.query.bool.filter[1].match_phrase['interfaces.ifName'].query =
        '' + Port + '';
      //console.log('Data Pre2: ', JSON.stringify(request));
    }

    if (ntu.mode == 'VIRTUAL' || div === '#latency') {
      if (autorefresh == true) {
        try {
          let response = await getElasticSearchData(
            'searchNoTenantIgnoreLoading',
            {
              ignoreThrottled: ignore_throttled,
              index: esIndex,
              size: 0,

              body: JSON.stringify(request),
            }
          ).then((res) => res.json());
          //console.log(response);
          await drawGraphSNMP_response(response, div);
        } catch (error) {
          //console.log(error);
        }
      } else {
        try {
          let response = await getElasticSearchData('searchNoTenant', {
            ignoreThrottled: ignore_throttled,
            index: esIndex,
            size: 0,

            body: JSON.stringify(request),
          }).then((res) => res.json());
          //console.log(response);
          await drawGraphSNMP_response(response, div);
        } catch (error) {
          //console.log(error);
        }
      }
    } else {
      if (autorefresh == true) {
        try {
          let response = await getElasticSearchData('searchIgnoreLoading', {
            ignoreThrottled: ignore_throttled,
            index: esIndex,
            size: 0,

            body: JSON.stringify(request),
          }).then((res) => res.json());
          //console.log(response);
          drawGraphSNMP_response(response, div);
        } catch (error) {
          //console.log(error);
        }
      } else {
        try {
          let response = await getElasticSearchData('search', {
            ignoreThrottled: ignore_throttled,
            index: esIndex,
            size: 0,

            body: JSON.stringify(request),
          }).then((res) => res.json());
          console.log('Draw Graph', div, response);

          drawGraphSNMP_response(response, div);
        } catch (error) {
          //console.log(error);
        }
      }
    }
  };

  const doGraphs = async () => {
    console.log('do Graphs');
    if (Port != '') {
      drawGraphSNMP(
        'snmp-interface-hc-*',
        SNMPInterfaceHCStatsPortRequest,

        '#input_bytes'
      );
      drawGraphSNMP(
        'snmp-interface-hc-*',
        SNMPInterfaceHCStatsPortRequest,

        '#input_packets'
      );
    }
    drawGraphSNMP(
      'heartbeat-*',
      HeartbeatRTTRequest,

      '#latency'
    );
  };

  const doTables = async () => {
    AlarmsSyslogRequest.query.bool.must[0].query_string['query'] =
      "syslog_program: alarmd AND serial-number: '" + ntu.serialNumber + "'";
    AlarmsSyslogRequest.query.bool.must[1].range['@timestamp'].gte = getGTE();
    AlarmsSyslogRequest.query.bool.must[1].range['@timestamp'].lte = getLTE();

    if (autorefresh == true) {
      try {
        let response = await getElasticSearchData('searchIgnoreLoading', {
          index: 'syslog-*',
          size: 1,
          ignoreThrottled: ignore_throttled,
          body: JSON.stringify(AlarmsSyslogRequest),
        }).then((res) => res.json());
        //console.log(response);
        if (response.hits !== undefined) {
          if (response.hits.hits.length > 0) {
            TableAlarms = response.hits.hits;
            totalItemsClient = response.hits.hits.length;
            queryCountClient = response.hits.hits.length;
          }
        }
      } catch (error) {
        //console.log(error);
      }
    } else {
      try {
        let response = await getElasticSearchData('search', {
          index: 'syslog-*',
          size: 1,
          ignoreThrottled: ignore_throttled,
          body: JSON.stringify(AlarmsSyslogRequest),
        }).then((res) => res.json());
        //console.log(response);
        if (response.hits !== undefined) {
          if (response.hits.hits.length > 0) {
            TableAlarms = response.hits.hits;
            totalItemsClient = response.hits.hits.length;
            queryCountClient = response.hits.hits.length;
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const doSystemStatsSNMP = async () => {
    console.log('doSystemStatsSNMP');
    SNMPSystemStatsRequest.query.bool.must[0].query_string['query'] =
      "(serial-number: '" + ntu.serialNumber + "')";

    try {
      let response = await getElasticSearchData('searchIgnoreLoading', {
        index: 'snmp-system-*',
        size: 1,
        ignoreThrottled: ignore_throttled,
        body: JSON.stringify(SNMPSystemStatsRequest),
      }).then((res) => res.json());
      console.log('System Data: ', response);
      if (response.hits.hits.length > 0) {
        var stats = response.hits.hits[0]['_source'];
        ////console.log('stats', stats);
        //if (vm.ntu.deviceConfig !== null && vm.ntu.deviceConfig.mikrotik !== null) {
        if (stats.Load1Min !== undefined) {
          SystemStats = {
            'up-time': secondsToTime(stats['Uptime']),
            'load-average-one': stats['Load1Min'],
            'load-average-five': stats['Load5Min'],
            'load-average-fifteen': stats['Load15Min'],
            Temperature: stats['Temperature'] + 'C',
          };
        } else if (stats.Temperature !== undefined) {
          SystemStats = {
            'up-time': secondsToTime(stats['Uptime']),
            'load-average-one': stats['CpuUsage'] + '%',
            Temperature: stats['Temperature'] + 'C',
          };
          has_temp = 1;
        } else {
          SystemStats = {
            'up-time': secondsToTime(stats['Uptime']),
            'load-average-one': stats['CpuUsage'] + '%',
          };
          has_temp = 0;
        }

        console.log('vm.SystemStats: ', SystemStats);

        model = ntu.ntutypeModel;
        serial = ntu.serialNumber;

        var results = [
          {
            key: 'cpu-system',
            value: stats['CpuUsage'],
          },
          {
            key: 'idle',
            value: 100 - stats['CpuUsage'],
          },
        ];

        let dataArray = { table: results };

        setSystemCPUData(dataArray);
        setSystemCPUGraph(true);
        var memused = stats['MemoryUsage'];

        var mem = [];
        var ostats = {
          key: 'used',
          value: memused,
        };
        mem[0] = ostats;

        ostats = {
          key: 'free',
          value: 100 - memused,
        };
        mem[1] = ostats;

        setSystemMemData({ table: mem });
        setSystemMemGraph(true);
        setSystemStats(SystemStats);
        drawGraphSNMP(
          'snmp-system-*',
          SNMPSystemCPULoadLineRequest,

          '#cpu_load'
        );

        drawGraphSNMP(
          'snmp-system-*',
          SNMPSystemMemoryLineRequest,

          '#system_memory'
        );

        if (has_temp == 1) {
          drawGraphSNMP(
            'snmp-system-*',
            SNMPSystemTemperatureLineRequest,

            '#system_temperature'
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setViewSizeStats = async () => {
    //console.log('setViewSizeStats');
  };
  const doAll = async () => {
    console.log('doAll');
    await getPortsSNMP();
    await doGraphs();
    await doTables();
    await doSystemStatsSNMP();
    await setViewSizeStats();
  };

  const getHCSNMPIFnames = () => {
    //console.log('getHCNSM');
  };

  const onNTUSucess = (data) => {
    if (data.length > 0) {
      ntuPorts = data;
      console.log('ntuPorts: ', ntuPorts);
      if (!Port && NTU.mode == 'VIRTUAL') {
        Port = ntuPorts[0].name;
      }
      onNTUPortsChange(ntuPorts);
      doAll();
    }
  };
  function onNTUError(error) {
    console.log('onNTUError : ', error);
    // AlertService.error(error.data.message);
  }
  const onSuccess = async (data) => {
    services = data;

    if (ntu.mode == 'SD_WAN') {
      getHCSNMPIFnames();
    } else {
      try {
        var response = await NtuPortService(
          'get',
          '?ntuId=' + NTU.id + '&isValid=false'
        ).then((res) => res.json());
        onNTUSucess(response);
      } catch (error) {
        //console.log(error);
        onNTUError(error);
      }
    }
  };

  const portTrafficGraphs = () => {
    //  console.log('Ref width:', portUtilRef.current.clientWidth);
    //  PortsUtilBar.width = portUtilRef.current.clientWidth - 100;
    // PortsUtilBarPackets.width = portUtilBarPacketRef.current.clientWidth - 100;
    setPortUtilGraph(true);
    setPortUtilBarPacketsGraph(true);
  };
  useEffect(async () => {
    console.log('Current Port:', currentPort);
    Port = currentPort;
    if (!Port && NTU.mode != 'VIRTUAL') {
      Port = NTU.uplinkPort;
    }
    console.log('Port: ', Port);

    try {
      var response = await MegaportUserService(
        'queryAdmin',
        '?filter=' + NTU.id + '&unpaged=true'
      ).then((res) => res.json());
      //console.log('MegaportUserService response', response);
      await onSuccess(response);
    } catch (error) {
      //console.log(error);
    }
  }, [currentPort, lte, gte]);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <ul className="nav nav-pills nav-justified mb-4" f role="tablist">
            <li className="nav-item">
              <a
                className={`nav-link  py-3 px-5 ${
                  !tab ? 'active' : 'inactive'
                }`}
                data-toggle="tab"
                onClick={() => setTab(!tab)}
              >
                System Overview
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link  py-3 px-5 ${tab ? 'active' : 'inactive'}`}
                data-toggle="tab"
                onClick={() => {
                  portTrafficGraphs();
                  return setTab(!tab);
                }}
              >
                Ports & Traffic
              </a>
            </li>
          </ul>
        </div>
      </div>
      {!tab && systemCPUGraph && (
        <Overview
          NTU={NTU}
          systemStats={systemStats}
          systemCPUData={systemCPUData}
          systemMemData={systemMemData}
          snmpSystemCPULoadLineData={snmpSystemCPULoadLineData}
          snmpSystemMemoryLineData={snmpSystemMemoryLineData}
        />
      )}
      {tab && (
        <PortsTraffic
          snmpSystemTemperatureLineData={snmpSystemTemperatureLineData}
          ntuBytesPieData={ntuBytesPieData}
          ntuPacketsPieData={ntuPacketsPieData}
          portUtilData={portUtilData}
          portUtilBarPacketsData={portUtilBarPacketsData}
          portsPacketsPieData={portsPacketsPieData}
          portsBytesPieData={portsBytesPieData}
          interfaceStatsInputBytesLineData={interfaceStatsInputBytesLineData}
          interfaceStatsInputPacketsLineData={
            interfaceStatsInputPacketsLineData
          }
          latencyData={latencyData}
        />
      )}
    </>
  );
};

export default AnalyticsDashboard;
