import React, { useMemo, useState, useEffect } from 'react';
import Panel from '../../../common/Panel/Panel';
import Table from '../../../common/Table';

import { handleDeleteAlert } from '../../../common/Alerts/DeleteAlert';
const MonitorStatusVntus = ({ vntus, heading }) => {
  const data = vntus;

  console.log('all vntus:', vntus);

  const columns = useMemo(
    () => [
      {
        Header: 'Status',
        accessor: (d) => {
          if (d.portData && d.portData == '1')
            return (
              <p className="text-center p-2 bg-success text-white  rounded">
                up
              </p>
            );
          else
            return (
              <p className="text-center p-2 bg-danger text-white  rounded">
                down
              </p>
            );
        },
        sortable: true,
      },
      {
        Header: 'Device',
        accessor: (d) => {
          return <>{d.name}</>;
        },
        sortable: true,
      },
      {
        Header: 'Serial Number',
        accessor: (d) => {
          return d.serialNumber;
        },
        sortable: true,
      },
    ],
    []
  );

  return (
    <Panel heading={heading}>
      <Table data={data} columns={columns} heading="Monitor Status Devices" />
    </Panel>
  );
};

export default MonitorStatusVntus;
