export const getNTUs = async (queryType, queryFilters) => {
  const token = localStorage.getItem('token');

  const baseUrl = 'https://test.activeport.com.au/api/ntus/';
  let queryAtrr = { url: baseUrl, method: '' };
  switch (queryType) {
    case 'query':
      queryAtrr = {
        method: 'GET',
        url: 'https://test.activeport.com.au/api/ntus' + queryFilters,
      };
      break;
    case 'queryIgnoreLoading':
    case 'mikrotikStats':
    case 'mikrotikExecute':
    case 'get':
      queryAtrr = {
        method: 'GET',
        url: 'https://test.activeport.com.au/api/ntus',
      };

      break;
    case 'getByUuid':
    case 'getWithConfig':
    case 'getWithRawConfig':
    case 'update':

    case 'search':
      baseUrl = baseUrl + 'elastic/my-query';
      break;
    case 'post':
    case 'queryAdmin':
      queryAtrr = {
        method: 'GET',
        url: 'https://test.activeport.com.au/api/ntus/admin',
      };
      break;
    case 'delete':
    case 'getOrgBased':
  }

  return await fetch(queryAtrr.url, {
    method: queryAtrr.method,
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};
