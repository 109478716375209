import React, { useEffect, useState } from 'react';
import Panel from '../../../common/Panel/Panel';
import { Vega } from 'react-vega';

import DrawGraph from './DrawGraph';
import { getElasticSearchData } from './api/elasticsearch';
import moment from 'moment';
import tz from 'moment-timezone';

import applications_pie from '../../../common/graphs/vega/applications/applications_pie.json';
import applications_pie_request from '../../../common/graphs/vega/applications/applications_pie_request.json';

import application_count_pie from '../../../common/graphs/vega/applications/application_count_pie.json';
import application_count_pie_request from '../../../common/graphs/vega/applications/application_count_pie_request.json';

import application_radial_service_request from '../../../common/graphs/vega/applications/application_radial_service_request.json';

import application_sunburst from '../../../common/graphs/vega/applications/application_sunburst.json';
import applications_flare from '../../../common/graphs/vega/applications/applications_flare.json';
import ContainerDimensions from 'react-container-dimensions';

const Overview = ({ lte, gte, ntu }) => {
  var excludeUnknown = false;
  var ignore_throttled = 1;
  const [radialGraphData, setRadialGraphData] = useState({});
  var [sunburstGraphData, setSunburstGraphData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const [applications_pie_data, set_applications_pie_data] = useState({});
  const [application_count_pie_data, set_application_count_pie_data] = useState(
    {}
  );

  var radialData = [];
  var sunburstData = [];
  const getLTE = () => {
    if (lte == null || lte == '') {
      lte = new Date();
      lte = moment();
      lte = lte._d.getTime();
    }
    return lte;
  };
  const getGTE = () => {
    if (gte == null || gte == '') {
      gte = new Date();
      gte = moment().subtract(3, 'hours');
      gte = gte._d.getTime();
    }
    return gte;
  };

  const runradial = async () => {
    application_radial_service_request.query.bool.filter.push({
      term: { 'serial-number.keyword': ntu.serialNumber },
    });
    application_radial_service_request.query.bool.must_not.push({
      term: { 'application.keyword': 'ICMP' },
    });
    application_radial_service_request.query.bool.filter.push({
      term: { 'apptrack_status.keyword': 'APPTRACK_SESSION_CLOSE' },
    });

    if (excludeUnknown === true) {
      application_radial_service_request.query.bool.must_not.push({
        term: { 'nested-application.keyword': 'UNKNOWN' },
      });
      application_radial_service_request.query.bool.must_not.push({
        term: { 'application.keyword': 'UNKNOWN' },
      });
    }
    application_radial_service_request.query.bool.must[0].range[
      '@timestamp'
    ].gte = getGTE();
    application_radial_service_request.query.bool.must[0].range[
      '@timestamp'
    ].lte = getLTE();

    try {
      let response = await getElasticSearchData('search', {
        ignoreThrottled: ignore_throttled,
        index: 'apptrack-*',
        size: 0,

        body: JSON.stringify(application_radial_service_request),
      }).then((res) => res.json());
      console.log(response);

      runradial_response(response);
    } catch (error) {
      console.log(error);
    }
  };

  const runradial_response = async (response) => {
    if (response != null && response.aggregations.results.buckets.length > 0) {
      var k = 0;
      var v;

      // do radial first
      var stats = {
        id: k,
        name: 'flare',
      };
      radialData[k] = stats;
      for (var j = 0; j < response.aggregations.results.buckets.length; j++) {
        var parent0 = 0;
        var p;
        var q;

        var topLevel = response.aggregations.results.buckets[j];
        for (var x = 0; x < topLevel.app.buckets.length; x++) {
          var parent;
          var secondLevel = topLevel.app.buckets[x];
          //console.log('secondLevel: ', secondLevel);
          if (excludeUnknown === true && secondLevel['key'] !== 'UNKNOWN') {
            k++;
            parent = k;
            var xstats = {
              id: k,
              name: topLevel['key'],
              parent: 0,
              size: secondLevel['doc_count'],
            };
            radialData[k] = xstats;
          } else if (excludeUnknown === false) {
            k++;
            parent = k;
            var xstats = {
              id: k,
              name: topLevel['key'],
              parent: 0,
              size: secondLevel['doc_count'],
            };
            radialData[k] = xstats;
          }
          for (
            var y = 0;
            y < secondLevel.nestedapplication.buckets.length;
            y++
          ) {
            var parent1 = 0;
            var thirdLevel = secondLevel.nestedapplication.buckets[y];
            //console.log('thirdLevel: ', thirdLevel);
            if (excludeUnknown === true && thirdLevel['key'] !== 'UNKNOWN') {
              var sumbytes = 0;

              // loop dest
              for (var z = 0; z < thirdLevel.dest.buckets.length; z++) {
                var dest = thirdLevel.dest.buckets[z];
                //console.log('dest',dest);
                sumbytes +=
                  dest['bytesclient'].value + dest['bytesserver'].value;
              }

              //console.log('sumbytes: ', sumbytes);
              k++;
              parent1 = k;
              var ystats = {
                id: k,
                name: thirdLevel['key'],
                parent: parent,
                size: sumbytes,
              };
              radialData[k] = ystats;
            } else if (excludeUnknown === false) {
              var sumbytes = 0;

              // loop dest
              for (var z = 0; z < thirdLevel.dest.buckets.length; z++) {
                var dest = thirdLevel.dest.buckets[z];
                //console.log('dest',dest);
                sumbytes +=
                  dest['bytesclient'].value + dest['bytesserver'].value;
              }

              //console.log('sumbytes: ', sumbytes);
              k++;
              parent1 = k;
              var ystats = {
                id: k,
                name: thirdLevel['key'],
                parent: parent,
                size: sumbytes,
              };
              radialData[k] = ystats;
            }
          }
        }
      }
      // do sunburst
      k = 0;
      sunburstData = [];

      var stats = {
        id: k,
        name: 'sunburst',
      };
      sunburstData[k] = stats;
      for (var j = 0; j < response.aggregations.results.buckets.length; j++) {
        var parent0 = 0;
        var p;
        var q;
        var topLevel = response.aggregations.results.buckets[j];
        //console.log('topLevel: ', topLevel);
        //console.log('key: ', topLevel['key']);
        k++;
        parent0 = k;
        var rstats = {
          id: k,
          name: topLevel['key'],
          parent: 0,
          size: topLevel['doc_count'],
        };
        sunburstData[k] = rstats;
        for (var x = 0; x < topLevel.app.buckets.length; x++) {
          var parent;
          var secondLevel = topLevel.app.buckets[x];
          //console.log('secondLevel: ', secondLevel);
          if (excludeUnknown === true && secondLevel['key'] != 'UNKNOWN') {
            k++;
            parent = k;
            var xstats = {
              id: k,
              name: secondLevel['key'],
              parent: parent0,
              size: secondLevel['doc_count'],
            };
            sunburstData[k] = xstats;
          }
          for (
            var y = 0;
            y < secondLevel.nestedapplication.buckets.length;
            y++
          ) {
            var parent1 = 0;
            var thirdLevel = secondLevel.nestedapplication.buckets[y];
            //console.log('thirdLevel: ', thirdLevel);
            if (excludeUnknown === true && thirdLevel['key'] != 'UNKNOWN') {
              var sumbytes = 0;

              // loop dest
              for (var z = 0; z < thirdLevel.dest.buckets.length; z++) {
                var dest = thirdLevel.dest.buckets[z];
                //console.log('dest',dest);
                sumbytes +=
                  dest['bytesclient'].value + dest['bytesserver'].value;
              }

              k++;
              parent1 = k;
              var ystats = {
                id: k,
                name: thirdLevel['key'],
                parent: parent,
                size: sumbytes,
              };
              sunburstData[k] = ystats;

              if (thirdLevel && parent1 > 0) {
                var zc = 0;
                for (var z = 0; z < thirdLevel.source.buckets.length; z++) {
                  var fourthLevel = thirdLevel.source.buckets[z];
                  // console.log('fourthLevel: ', fourthLevel);
                  if (fourthLevel) {
                    zc++;
                    if (zc > 3) break;
                    k++;
                    var zstats = {
                      id: k,
                      name: fourthLevel['key'],
                      parent: parent1,
                      size:
                        fourthLevel['bytesclient']['value'] +
                        fourthLevel['bytesserver']['value'],
                    };
                    sunburstData[k] = zstats;
                  }
                }
              }
            }
          }
        }
      }
    }
    console.log('Radial Data:', radialData);
    console.log('Sunburst Data:', sunburstData);
    setRadialGraphData({ table: radialData });
    setSunburstGraphData({ table: sunburstData });
  };

  const elasticRequest = async (request, div) => {
    request.query.bool.filter.push({
      term: { 'serial-number.keyword': ntu.serialNumber },
    });
    //data.query.bool.must[0].query_string["query"] = "serial-number: " + vm.serial;
    request.query.bool.must[0].range['@timestamp'].gte = getGTE();
    request.query.bool.must[0].range['@timestamp'].lte = getLTE();

    try {
      let response = await getElasticSearchData('search', {
        ignoreThrottled: getIgnoreThrottled(),
        index: 'apptrack-*',
        size: 0,

        body: JSON.stringify(request),
      }).then((res) => res.json());
      if (div == '#applications') {
        console.log('Applications Pie Data: ', response);
        set_applications_pie_data(response);
      }
      if (div == '#application_count') {
        set_application_count_pie_data(response);
      }
    } catch (error) {
      console.log(error);
    }
  };
  function handleResize() {
    setIsLoading(true);
    setIsLoading(false);
  }

  const getIgnoreThrottled = () => {
    var now = new Date();
    //var lte_diff = moment(now).diff(moment(vm.lte), 'days')
    var gte_diff = moment(now).diff(moment(getGTE()), 'months');
    //             console.log("lte_diff = " + lte_diff);
    console.log('gte_diff = ' + gte_diff);
    if (gte_diff > 0) {
      ignore_throttled = 0; // searchable snapshot
    }
    return ignore_throttled;
  };
  useEffect(async () => {
    await runradial();
    await elasticRequest(applications_pie_request, '#applications');
    await elasticRequest(application_count_pie_request, '#application_count');
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();

    setIsLoading(false);
  }, []);
  if (isLoading)
    return (
      <div class="LoadingContainer text-center">
        <p>Loading</p>
      </div>
    );
  else
    return (
      <div className="row">
        <div className="col-md-6 col-sm-6 col-xs-12">
          <Panel heading="Applications (Protocols)">
            <DrawGraph
              esData={applications_pie_data}
              graphDiv={'#applications'}
              vegaGraph={applications_pie}
            />
          </Panel>
        </div>
        <div className="col-md-6 col-sm-6 col-xs-12">
          <Panel heading="Applications">
            <DrawGraph
              esData={application_count_pie_data}
              graphDiv={'#application_count'}
              vegaGraph={application_count_pie}
            />
          </Panel>
        </div>
        <div className="col-md-6 col-sm-6 col-xs-12">
          <Panel heading="Applications (Flare)">
            <ContainerDimensions>
              {({ width, height }) => (
                <DrawGraph
                  width={width}
                  height={height}
                  vegaGraph={applications_flare}
                  graphDiv={'#applications_flare'}
                  esData={radialGraphData}
                />
              )}
            </ContainerDimensions>
          </Panel>
        </div>
        <div className="col-md-6 col-sm-6 col-xs-12">
          <Panel heading="Applications (Sunburst)">
            <ContainerDimensions>
              {({ width, height }) => (
                <DrawGraph
                  width={width}
                  height={height}
                  vegaGraph={application_sunburst}
                  graphDiv={'#application_sunburst'}
                  esData={sunburstGraphData}
                />
              )}
            </ContainerDimensions>
          </Panel>
        </div>
      </div>
    );
};

export default Overview;
