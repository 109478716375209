export const getProviders = async () => {
  const token = localStorage.getItem("token");
  const ntuId = JSON.parse(localStorage.getItem("ntu")).id;
  return fetch(
    `https://test.activeport.com.au/api/tile-configurations/by-name/AWS?ntuId=${ntuId}&profile=`,
    {
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    }
  );
};

export const getLocations = async () => {
  const token = localStorage.getItem("token");
  return fetch(
    `https://test.activeport.com.au/api/demo-provider-ports?profile=`,
    {
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    }
  );
};
