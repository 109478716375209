import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";

import { MyTextArea } from "../../../common/Form/FormFields";
import { getNtuTypes } from "./api/templateConfigurationsApi";

export const TemplateConfigurationForm = ({
  initialData,
  submitForm,
  isNew,
  isSubmitted,
}) => {
  const [ntuTypes, setNtuTypes] = useState([]);

  useEffect(() => {
    let unmounted = false;
    const fetchNtuTypes = async () => {
      try {
        const ntuTypesRes = await getNtuTypes().then((res) => res.data);
        if (!unmounted) {
          setNtuTypes(
            ntuTypesRes.filter((type) => type.osType === initialData.osType)
          );
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchNtuTypes();
  }, [initialData]);

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialData}
        // validationSchema={schema}
        validateOnMount
        onSubmit={submitForm}
      >
        {({ errors, touched, isValid, setFieldValue, values }) => {
          return (
            <Form>
              {!isNew && (
                <div className="form-group row m-b-15">
                  <label className="col-form-label col-md-3">ID</label>
                  <div className="col-md-9">
                    <Field
                      type="text"
                      name="id"
                      label="id"
                      className="form-control m-b-5"
                      readOnly
                    />
                  </div>
                </div>
              )}

              <div className="form-group row m-b-15">
                <label className="col-form-label col-md-3">
                  Name <span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <Field
                    type="text"
                    name="name"
                    label="name"
                    className={`form-control m-b-5  ${
                      errors.name && touched.name ? "is-invalid" : ""
                    }`}
                    placeholder="Enter TemplateConfiguration Name"
                  />
                  {errors.name && touched.name ? (
                    <div className="invalid-feedback">{errors.name}</div>
                  ) : null}
                </div>
              </div>

              <div className="form-group row m-b-15">
                <label className="col-form-label col-md-3">
                  Operative System
                  <span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <Field
                    as="select"
                    name="osType"
                    className={`form-control m-b-5  ${
                      errors.osType && touched.osType ? "is-invalid" : ""
                    }`}
                  >
                    {["JUNOS", "MIKROTIK"].map((osType, i) => (
                      <option key={i} value={osType}>
                        {osType}
                      </option>
                    ))}
                  </Field>
                </div>
              </div>

              <div className="form-group row m-b-15">
                <label className="col-form-label col-md-3">
                  Description <span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <Field
                    type="text"
                    name="description"
                    label="description"
                    className={`form-control m-b-5  ${
                      errors.description && touched.description
                        ? "is-invalid"
                        : ""
                    }`}
                    placeholder="Enter Description"
                  />
                  {errors.description && touched.description ? (
                    <div className="invalid-feedback">{errors.description}</div>
                  ) : null}
                </div>
              </div>

              <div className="form-group row m-b-15">
                <label className="col-form-label col-md-3">
                  Filter Model <span className="text-danger"></span>
                </label>
                <div className="col-md-9">
                  <Field
                    as="select"
                    multiple
                    name="modelList"
                    className={`form-control m-b-5  ${
                      errors.modelList && touched.modelList ? "is-invalid" : ""
                    }`}
                  >
                    {ntuTypes.map((ntuType) => (
                      <option key={ntuType.id} value={ntuType.model}>
                        {ntuType.model}
                      </option>
                    ))}
                  </Field>
                  {errors.modelList && touched.modelList ? (
                    <div className="invalid-feedback">{errors.modelList}</div>
                  ) : null}
                </div>
              </div>

              <MyTextArea
                label="Configuration"
                name="configuration"
                rows="10"
                placeholder="Enter configuration"
              />

              <button
                type="submit"
                className="btn btn-primary btn-block btn-lg m-b-5"
                disabled={!isValid || isSubmitted}
              >
                Submit
              </button>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
