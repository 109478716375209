export const getPartnerConnects = () => {
  const token = localStorage.getItem("token");
  const ntuId = JSON.parse(localStorage.getItem("ntu")).id;
  const baseUrl = `https://test.activeport.com.au/api/_search/partner-connect?ntuId=${ntuId}&type=MCON`;
  const requestUrl = `${baseUrl}`;
  return fetch(requestUrl, {
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getPartnerConnect = (id) => {
  const token = localStorage.getItem("token");
  const baseUrl = `https://test.activeport.com.au/api/partner-connect/${id}?profile=`;

  const requestUrl = `${baseUrl}`;
  return fetch(requestUrl, {
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};
