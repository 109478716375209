import React, { useEffect, useState, Fragment } from 'react';
import { Routes, Route, useLocation, Link } from 'react-router-dom';

import ServiceCommands from './ServiceCommands';
import ServiceCommandDetail from './ServiceCommandDetail';
import ServiceCommandUpdate from './ServiceCommandUpdate';
import { getServiceCommands } from './api/ServiceCommandsApi';

const ServiceCommandsContainer = () => {
  // const match = useParams();
  const location = useLocation();
  const [entities, setEntities] = useState([]);
  const [appState, setAppState] = useState({
    loading: false,
    err: false,
  });
  const [params, setParams] = useState({
    device: '',
    os: '',
  });

  useEffect(() => {
    (async () => {
      try {
        let res = [];
        if (params.device && params.os) {
          res = await getServiceCommands(params.device, params.os).then((res) =>
            res.json()
          );
        } else {
          setAppState({ loading: true, err: false });
          res = await getServiceCommands().then((res) => res.json());
        }
        console.log(res);
        setAppState({ loading: false });
        setEntities(res);
      } catch (err) {
        setAppState({ loading: false, err: true });
      }
    })();
  }, [location.state, params]);

  return (
    <Fragment>
      <ol className="breadcrumb page-breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">ActivePort</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to="/settings">Settings</Link>
        </li>
        <li className="breadcrumb-item active">Service Commands</li>
        <li className="position-absolute pos-top pos-right d-none d-sm-block">
          <span className="js-get-date"></span>
        </li>
      </ol>
      <div className="subheader">
        <h1 className="subheader-title">
          <i className="subheader-icon fal fa-table"></i> Service Commands
          <small>Create, view, update or delete service commands</small>
        </h1>
        {!location.pathname.includes('/new') &&
          !location.pathname.includes('/edit') && (
            <Link to={`new`}>
              <button
                type="button"
                className="btn btn-success waves-effect waves-themed"
              >
                <span className="fal fa-plus mr-1"></span>
                Create
              </button>
            </Link>
          )}
      </div>
      {!appState.loading && !appState.err && (
        <Routes>
          <Route exact path={`/new`} element={<ServiceCommandUpdate />} />
          <Route exact path={`/edit/:id`} element={<ServiceCommandUpdate />} />
          <Route exact path={`:id`} element={<ServiceCommandDetail />} />
          <Route
            path="/"
            element={
              <ServiceCommands
                entities={entities}
                setParams={setParams}
                params={params}
              />
            }
          />
        </Routes>
      )}
      {appState.loading && !appState.error && (
        <div className="border p-3">
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      )}
      {!appState.loading && appState.err && (
        <div className="alert alert-danger" role="alert">
          <strong>Oh snap!</strong> We are not able to connect to the database.
          Try again in few minutes. again.
        </div>
      )}
      {/* <Route
          exact
          path={`/:id/delete`}
          element={TenantDelete}
        /> */}
    </Fragment>
  );
};

export default ServiceCommandsContainer;
