import React, { useEffect, useState, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Formik, Form, Field, yupToFormErrors } from "formik";

import * as Yup from "yup";
import Panel from "../../../common/Panel/Panel";
import Alert from "../../../common/AlertControls/Alert";
import Autocomplete from "react-google-autocomplete";
import { handleCreateNewAlert } from "../../../common/Alerts/CreateNewAlert";
import { handleUpdateAlert } from "../../../common/Alerts/EditAlert";

import {
  createCircuitConfiguration,
  getCircuitConfiguration,
  updateCircuitConfiguration,
} from "./api/circuitConfigurationsApi";

const CircuitConfigurationUpdate = ({ serviceConfigs }) => {
  const match = useParams();
  const navigate = useNavigate();
  const isNew = !match.id;

  let defaultValues = {
    id: "",
    serviceConfigurationName: "",
    zone: "",
    vlanId: "",
    serviceKey: "",
    serviceId: "",
  };
  const [initialData, setInitialData] = useState(defaultValues);
  const [appState, setAppState] = useState({
    loading: true,
  });

  const [alertShow, setAlertShow] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  // let alertShow = false;

  const schema = Yup.object().shape({
    vlanId: Yup.string()
      .min(2, "Too Short!")
      .max(250, "Too Long!")
      .required("Required"),
  });

  async function createNewCircuitConfiguration(circuitConfiguration) {
    const response = await createCircuitConfiguration(
      circuitConfiguration
    ).then((res) => res.json());
    if (response.id) {
      handleCreateNewAlert(
        'New circuitConfiguration "' +
          circuitConfiguration.name +
          '" added successfully!'
      );
      navigate("../");
    }
  }

  async function updateCircuitConfigurations(circuitConfiguration) {
    const response = await updateCircuitConfiguration(
      circuitConfiguration
    ).then((res) => res.json());

    if (response.id) {
      console.log("respnise id", response.id);
      handleUpdateAlert("circuitConfiguration", circuitConfiguration.id);
      navigate("../" + circuitConfiguration.circuitConfigurationId);
    }
  }

  function submitForm(formValues) {
    isNew
      ? createNewCircuitConfiguration(formValues)
      : updateCircuitConfigurations(formValues);
  }

  // effect runs on component mount
  useEffect(() => {
    let unmounted = false;
    // simulate async api call with set timeout

    const fetchCircuitConfiguration = async () => {
      try {
        const result = await getCircuitConfiguration(match.id).then((res) =>
          res.json()
        );
        if (!unmounted) {
          setInitialData(result);
          //reset(result);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (!isNew) fetchCircuitConfiguration();
    setAppState({ loading: false });
    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-xl-9">
          {alertShow && (
            <Alert
              alertType="alert-success"
              msg="The circuitConfiguration has been updated."
            />
          )}

          {!appState.loading && initialData && (
            <Panel heading={initialData.name}>
              <Formik
                enableReinitialize
                initialValues={initialData}
                validationSchema={schema}
                validateOnMount
                onSubmit={submitForm}
              >
                {({ errors, touched, isValid }) => {
                  return (
                    <Form>
                      {!isNew && (
                        <div className="form-group row m-b-15">
                          <label className="col-form-label col-md-3">ID</label>
                          <div className="col-md-9">
                            <Field
                              type="text"
                              name="id"
                              label="id"
                              className="form-control m-b-5"
                              readOnly
                            />
                          </div>
                        </div>
                      )}
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Service Configuration
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            as="select"
                            name="serviceConfigurationName"
                            className={`form-control m-b-5  ${
                              errors.serviceConfigurationName &&
                              touched.serviceConfigurationName
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder="Enter Service Configuration"
                          >
                            <option value="">Select an option</option>
                            {serviceConfigs?.map((serviceConfig) => (
                              <option
                                key={serviceConfig.id}
                                value={serviceConfig.name}
                              >
                                {`${serviceConfig.name} [${serviceConfig.providerName} - ${serviceConfig.providerType} - ${serviceConfig.locationCode}]`}
                              </option>
                            ))}
                          </Field>

                          {errors.serviceConfigurationName &&
                          touched.serviceConfigurationName ? (
                            <div className="invalid-feedback">
                              {errors.serviceConfigurationName}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Zone <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="zone"
                            className={`form-control m-b-5  ${
                              errors.zone && touched.zone ? "is-invalid" : ""
                            }`}
                            placeholder="Enter Zone"
                          />
                          {errors.zone && touched.zone ? (
                            <div className="invalid-feedback">
                              {errors.zone}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {!isNew && (
                        <div className="form-group row m-b-15">
                          <label className="col-form-label col-md-3">
                            Service Key <span className="text-danger">*</span>
                          </label>
                          <div className="col-md-9">
                            <Field
                              type="text"
                              name="serviceKey"
                              className={`form-control m-b-5  ${
                                errors.serviceKey && touched.serviceKey
                                  ? "is-invalid"
                                  : ""
                              }`}
                              placeholder="Enter Service Key"
                            />
                            {errors.serviceKey && touched.serviceKey ? (
                              <div className="invalid-feedback">
                                {errors.serviceKey}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      )}

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          VLAN ID
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="number"
                            name="vlanId"
                            label="vlanId"
                            className={`form-control m-b-5  ${
                              errors.vlanId && touched.vlanId
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder="Enter Vlan Id"
                          />
                          {errors.vlanId && touched.vlanId ? (
                            <div className="invalid-feedback">
                              {errors.vlanId}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {!isNew && (
                        <div className="form-group row m-b-15">
                          <label className="col-form-label col-md-3">
                            Service Id <span className="text-danger">*</span>
                          </label>
                          <div className="col-md-9">
                            <Field
                              type="number"
                              name="serviceId"
                              className={`form-control m-b-5  ${
                                errors.serviceId && touched.serviceId
                                  ? "is-invalid"
                                  : ""
                              }`}
                              placeholder="Enter Service Key"
                            />
                            {errors.serviceId && touched.serviceId ? (
                              <div className="invalid-feedback">
                                {errors.serviceId}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      )}

                      <button
                        type="submit"
                        className="btn btn-primary btn-block btn-lg m-b-5"
                        disabled={!isValid || isSubmitted}
                      >
                        Submit
                      </button>
                    </Form>
                  );
                }}
              </Formik>
            </Panel>
          )}
        </div>
      </div>
    </div>
  );
};

export default CircuitConfigurationUpdate;
