import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import Panel from "../../../common/Panel/Panel";
import { getTemplateConfiguration } from "./api/templateConfigurationsApi";

const TemplateConfigurationDetail = ({ hasAuthority }) => {
  const match = useParams();
  const navigate = useNavigate();
  const [templateConfigurationEntity, setTemplateConfigurationEntity] =
    useState(null);
  const [appState, setAppState] = useState({
    loading: false,
  });

  useEffect(() => {
    setAppState({ loading: true });
    const fetchData = async () => {
      try {
        const result = await getTemplateConfiguration(match.id).then(
          (res) => res.data
        );
        setTemplateConfigurationEntity(result);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    setAppState({ loading: false });
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-xl-10">
          {appState.loading && <p>Loading...</p>}
          {templateConfigurationEntity !== null && !appState.loading && (
            <Panel heading={templateConfigurationEntity.name}>
              <dl className="row">
                <dt className="text-inverse  col-4 ">
                  <span id="name">
                    <span>Name</span>
                  </span>
                </dt>
                <dd className="col-8">{templateConfigurationEntity.name}</dd>
                <dt className="text-inverse  col-4">
                  <span id="command">
                    <span>Description</span>
                  </span>
                </dt>
                <dd className="col-8">
                  {templateConfigurationEntity.description}{" "}
                </dd>
                <dt className="text-inverse col-4">
                  <span id="onEvent">
                    <span>Configuration</span>
                  </span>
                </dt>
                <dd className="col-8">
                  <textarea
                    readOnly
                    style={{
                      height: "30em",
                      width: "100%",
                      border: "1px solid #e4e5e7",
                      padding: "6px 1px 6px 12px",
                      overflowY: "scroll",
                      resize: false,
                    }}
                  >
                    {templateConfigurationEntity.configuration}
                  </textarea>
                </dd>
              </dl>

              <div>
                <button
                  className="btn btn-sm btn-secondary mr-3"
                  onClick={() => navigate("../")}
                >
                  <i className="fal fa-arrow-left fa-sm  mr-2"></i>
                  Back
                </button>
                {hasAuthority && (
                  <button
                    className="btn btn-sm btn-primary m-r-5"
                    onClick={() => navigate("../edit/" + match.id)}
                  >
                    <i className="fal fa-pencil-alt fa-sm  mr-2"></i>
                    Edit
                  </button>
                )}
              </div>
            </Panel>
          )}
        </div>
      </div>
    </div>
  );
};

export default TemplateConfigurationDetail;
