import React, { useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Panel from '../../../common/Panel/Panel';
import Table from '../../../common/Table';

import { handleDeleteAlert } from '../../../common/Alerts/DeleteAlert';
const EmailSetup = ({ entities }) => {
  const data = entities;

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        sortable: true,
        Cell: ({ row }) => {
          return (
            <div>
              <Link to={`/administration/email-setup/${row.original.id}`}>
                {row.original.id}
              </Link>
            </div>
          );
        },
      },
      {
        Header: 'Org',
        accessor: () => 'Active Port',
        sortable: true,
      },
      {
        Header: 'Email Setup',
        accessor: 'emailSetupName',
        sortable: true,
      },

      {
        Header: '',
        accessor: 'actionColumns',
        className: 'align-middle',
        Cell: ({ row }) => {
          return (
            <div class="text-center">
              <Link
                className="btn btn-icon btn-circle btn-default mr-2"
                to={`/administration/email-setup/${row.original.id}`}
              >
                <i className="fal fa-eye"></i>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <Panel>
      <Table data={data} columns={columns} />
    </Panel>
  );
};

export default EmailSetup;
