/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';
import Logo from '../../assets/images/activeport-icon.png';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

const AuthLayout = () => {
  const { t } = useTranslation(['global', 'login']);
  return (
    <Fragment>
      <div className="page-wrapper auth">
        <div className="page-inner bg-brand-gradient">
          <div className="page-content-wrapper bg-transparent m-0">
            <div className="height-10 w-100 shadow-lg px-4 bg-brand-gradient">
              <div className="d-flex align-items-center container p-0">
                <div className="page-logo width-mobile-auto m-0 align-items-center justify-content-center p-0 bg-transparent bg-img-none shadow-0 height-9 border-0">
                  <a
                    href=""
                    className="page-logo-link press-scale-down d-flex align-items-center"
                  >
                    <img
                      src={Logo}
                      alt="SmartAdmin WebApp"
                      aria-roledescription="logo"
                    />
                    <span className="page-logo-text mr-1">ActivePortal</span>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="flex-1"
              style={{
                background:
                  'url(img/svg/pattern-1.svg) no-repeat center bottom fixed',
                backgroundSize: 'cover',
              }}
            >
              <div className="container py-4 py-lg-5 my-lg-5 px-4 px-sm-0">
                <div className="row">
                  <div className="col col-md-6 col-lg-7 hidden-sm-down">
                    <h2 className="fs-xxl fw-500 mt-4 text-white">
                      ActivePortal:{' '}
                      {t(
                        'login:heading',
                        'Your next-generation automation SDN platform'
                      )}
                      <small className="h3 fw-300 mt-3 mb-5 text-white opacity-60">
                        {t(
                          'login:intro',
                          'Managing technology in businesses is increasingly complicated, but it doesn’t have to be this way. ActivePortal is a next-generation automation software defined networking (SDN) platform that gives you the power to monitor and control your entire technology portfolio, from one dashboard. This cuts complexity, cost and time so you can focus on what really matters.'
                        )}
                      </small>
                    </h2>
                    <a
                      href="https://activeportal.com.au/"
                      className="fs-lg fw-500 text-white opacity-70"
                    >
                      {t('login:learnmore', 'Learn more')} &gt;&gt;
                    </a>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-5 col-xl-4 ml-auto">
                    <h1 className="text-white fw-300 mb-3 d-sm-block d-md-none">
                      Secure login
                    </h1>
                    <div className="card p-4 rounded-plus bg-faded">
                      <Outlet />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AuthLayout;
