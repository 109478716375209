export const getProviderCredentials = () => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/provider-credentials/';

  return fetch(baseUrl, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getLocation = (id) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/rest/v1/circuit-vlans//';

  const requestUrl = `${baseUrl}${id}`;
  return fetch(requestUrl, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const createLocation = (tenant) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/rest/v1/circuit-vlans//';

  return fetch(baseUrl, {
    method: 'POST',
    body: JSON.stringify(tenant),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const updateLocation = (tenant) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/rest/v1/circuit-vlans//';

  return fetch(baseUrl, {
    method: 'PUT',
    body: JSON.stringify(tenant),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const deleteLocation = (id) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/rest/v1/circuit-vlans//';
  const requestUrl = `${baseUrl}${id}`;
  return fetch(requestUrl, {
    method: 'DELETE',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};
