import Swal from 'sweetalert2';

export const handleDeleteAlert = () => {
  Swal.fire({
    title: 'Deleted!',
    text: 'Record deleted',
    icon: 'error',
    width: '100%',
    background: 'rgba(0,0,0,0.8)',

    customClass: {
      container: 'modal-alert',
      header: 'modal-header',
      title: 'modal-header',
      content: 'modal-content',
      closeButton: 'btn btn-default bootbox-cancel',
      icon: 'text-danger',
      confirmButton: 'btn btn-danger shadow-0 bootbox-accept',
      denyButton: 'btn btn-default bootbox-cancel',
      cancelButton: 'btn btn-default bootbox-cancel',
    },
  });
};
