import React, { useState, useRef, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import './SettingsPanel.scss';

const SettingsPanel = ({ setTheme, removeFromTheme, toggleModal, setSkin }) => {
  const modalClose = useRef(null);
  const { t } = useTranslation(['settings']);

  const theme = JSON.parse(localStorage.getItem('themeSettings'));
  const [globalFont, setGlobalFont] = useState('');
  useEffect(() => {
    if (theme.globalFontSize) {
      setGlobalFont(theme.globalFontSize);
    } else {
      setGlobalFont('root-text');
      setTheme({ globalFontSize: 'root-text' });
    }
  }, []);
  const toggleFont = () => {};
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      console.log(ref.current);

      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          event.target.classList.remove('show');
          toggleModal('settingsPanel');
          return;
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }
  useOutsideAlerter(modalClose);
  const resetLayout = () => {
    localStorage.removeItem('themeSettings');
    let defaultThemeSettings = JSON.stringify({
      globalFontSize: 'root-text',
      themeSkin: 'skin-default',
    });
    localStorage.setItem('themeSettings', defaultThemeSettings);
    window.location.reload();
  };
  return (
    <div>
      <div
        className="modal fade js-modal-settings modal-backdrop-transparent show"
        style={{ display: 'block', paddingRight: '0' }}
        tabIndex={-1}
        role="dialog"
        aria-modal="true"
      >
        <div
          className="modal-dialog modal-dialog-right modal-md"
          ref={modalClose}
        >
          <div className="modal-content">
            <div className="dropdown-header bg-trans-gradient d-flex justify-content-center align-items-center w-100">
              <h4 className="m-0 text-center color-white">
                {t(`Layout Settings`, `Layout Settings`)}
                <small className="mb-0 opacity-80">
                  {t(`User Interface Settings`, `User Interface Settings`)}
                </small>
              </h4>
              <button
                type="button"
                className="close text-white position-absolute pos-top pos-right p-2 m-1 mr-2"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => toggleModal('settingsPanel')}
              >
                <span aria-hidden="true">
                  <i className="fal fa-times" />
                </span>
              </button>
            </div>
            <div className="modal-body p-0">
              <div className="settings-panel">
                <div className="mt-4 d-table w-100 px-5">
                  <div className="d-table-cell align-middle">
                    <h5 className="p-0"> {t(`App Layout`, 'App Layout')} </h5>
                  </div>
                </div>
                <div className="list" id="fh">
                  <a
                    className="btn btn-switch"
                    data-action="toggle"
                    data-class="header-function-fixed"
                    onClick={() => {
                      theme.headerFunctionFixed
                        ? removeFromTheme('headerFunctionFixed')
                        : setTheme({
                            headerFunctionFixed: 'header-function-fixed',
                          });
                    }}
                  />
                  <span className="onoffswitch-title">
                    {t(`Fixed Header`, 'Fixed Header')}
                  </span>
                  <span className="onoffswitch-title-desc">
                    {t(
                      'header is in a fixed at all times',
                      'header is in a fixed at all times'
                    )}
                  </span>
                </div>
                <div className="list" id="nff">
                  <a
                    className="btn btn-switch"
                    data-action="toggle"
                    data-class="nav-function-fixed"
                    onClick={() => {
                      theme.navFunctionFixed
                        ? removeFromTheme('navFunctionFixed')
                        : setTheme({
                            navFunctionFixed: 'nav-function-fixed',
                          });
                    }}
                  />
                  <span className="onoffswitch-title">
                    {t('Fixed Navigation', 'Fixed Navigation')}
                  </span>
                  <span className="onoffswitch-title-desc">
                    {t('left panel is fixed', 'left panel is fixed')}
                  </span>
                </div>
                <div className="list" id="nfm">
                  <a
                    className="btn btn-switch"
                    data-action="toggle"
                    data-class="nav-function-minify"
                    onClick={() => {
                      theme.navFunctionMinify
                        ? removeFromTheme('navFunctionMinify')
                        : setTheme({
                            navFunctionMinify: 'nav-function-minify',
                          });
                    }}
                  />
                  <span className="onoffswitch-title">
                    {' '}
                    {t('Minify Navigation', 'Minify Navigation')}
                  </span>
                  <span className="onoffswitch-title-desc">
                    {t(
                      'skew nav to maximize space',
                      'skew nav to maximize space'
                    )}
                  </span>
                </div>
                <div className="list" id="nfh">
                  <a
                    className="btn btn-switch"
                    data-action="toggle"
                    data-class="nav-function-hidden"
                    onClick={() => {
                      theme.navFunctionHidden
                        ? removeFromTheme('navFunctionHidden')
                        : setTheme({
                            navFunctionHidden: 'nav-function-hidden',
                          });
                    }}
                  />
                  <span className="onoffswitch-title">
                    {t('Hide Navigation', 'Hide Navigation')}
                  </span>
                  <span className="onoffswitch-title-desc">
                    {t(
                      'roll mouse on edge to reveal',
                      'roll mouse on edge to reveal'
                    )}
                  </span>
                </div>

                <div className="list" id="fff">
                  <a
                    className="btn btn-switch"
                    data-action="toggle"
                    data-class="footer-function-fixed"
                    onClick={() => {
                      theme.footerFunctionFixed
                        ? removeFromTheme('footerFunctionFixed')
                        : setTheme({
                            footerFunctionFixed: 'footer-function-fixed',
                          });
                    }}
                  />
                  <span className="onoffswitch-title">
                    {t('Fixed Footer', 'Fixed Footer')}
                  </span>
                  <span className="onoffswitch-title-desc">
                    {t('page footer is fixed', 'page footer is fixed')}
                  </span>
                </div>
                <div className="list" id="mmb">
                  <a
                    className="btn btn-switch"
                    data-action="toggle"
                    data-class="mod-main-boxed"
                    onClick={() => {
                      theme.modMainBoxed
                        ? removeFromTheme('modMainBoxed')
                        : setTheme({
                            modMainBoxed: 'mod-main-boxed',
                          });
                    }}
                  />
                  <span className="onoffswitch-title">
                    {t('Boxed Layout', 'Boxed Layout')}
                  </span>
                  <span className="onoffswitch-title-desc">
                    {t(
                      'encapsulates to a container',
                      'encapsulates to a container'
                    )}
                  </span>
                </div>
                <div className="expanded">
                  <ul className="mb-3 mt-1">
                    <li>
                      <div
                        className="bg-fusion-50"
                        data-action="toggle"
                        data-class="mod-bg-1"
                        onClick={() => {
                          theme.mobileNavOn
                            ? removeFromTheme('mobileNavOn')
                            : setTheme({
                                mobileNavOn: 'mobile-nav-on',
                              });
                        }}
                      />
                    </li>
                    <li>
                      <div
                        className="bg-warning-200"
                        data-action="toggle"
                        data-class="mod-bg-2"
                        onClick={() => {
                          theme.modMainBoxedbg
                            ? removeFromTheme('modMainBoxedbg')
                            : setTheme({
                                modMainBoxedbg: 'mod-bg-2',
                              });
                        }}
                      />
                    </li>
                    <li>
                      <div
                        className="bg-primary-200"
                        data-action="toggle"
                        data-class="mod-bg-3"
                        onClick={() => {
                          theme.modMainBoxedbg
                            ? removeFromTheme('modMainBoxedbg')
                            : setTheme({
                                modMainBoxedbg: 'mod-bg-3',
                              });
                        }}
                      />
                    </li>
                    <li>
                      <div
                        className="bg-success-300"
                        data-action="toggle"
                        data-class="mod-bg-4"
                        onClick={() => {
                          theme.modMainBoxedbg
                            ? removeFromTheme('modMainBoxedbg')
                            : setTheme({
                                modMainBoxedbg: 'mod-bg-4',
                              });
                        }}
                      />
                    </li>
                    <li>
                      <div
                        className="bg-white border"
                        data-action="toggle"
                        data-class="mod-bg-none"
                        onClick={() => {
                          theme.modMainBoxedbg
                            ? removeFromTheme('modMainBoxedbg')
                            : setTheme({
                                modMainBoxedbg: 'mod-bg-none',
                              });
                        }}
                      />
                    </li>
                  </ul>
                  <div className="list" id="mbgf">
                    <a
                      className="btn btn-switch"
                      data-action="toggle"
                      data-class="mod-fixed-bg"
                      onClick={() => {
                        theme.mdFixedBg
                          ? removeFromTheme('mdFixedBg')
                          : setTheme({
                              mdFixedBg: 'mod-fixed-bg',
                            });
                      }}
                    />
                    <span className="onoffswitch-title">
                      {t('Fixed Background', 'Fixed Background')}
                    </span>
                  </div>
                </div>
                <div className="mt-4 d-table w-100 px-5">
                  <div className="d-table-cell align-middle">
                    <h5 className="p-0">{t('Mobile Menu', 'Mobile Menu')}</h5>
                  </div>
                </div>
                <div className="list" id="nmp">
                  <a
                    className="btn btn-switch"
                    data-action="toggle"
                    data-class="nav-mobile-push"
                    onClick={() => {
                      theme.navMobilePush
                        ? removeFromTheme('navMobilePush')
                        : setTheme({
                            navMobilePush: 'nav-mobile-push',
                          });
                    }}
                  />
                  <span className="onoffswitch-title">
                    {t('Push Content', 'Push Content')}
                  </span>
                  <span className="onoffswitch-title-desc">
                    {' '}
                    {t(
                      'content pushed on menu reveal',
                      'content pushed on menu reveal'
                    )}
                  </span>
                </div>

                <div className="mt-4 d-table w-100 px-5">
                  <div className="d-table-cell align-middle">
                    <h5 className="p-0">
                      {t('Accessibility', 'Accessibility')}
                    </h5>
                  </div>
                </div>
                <div className="list" id="mbf">
                  <a
                    className="btn btn-switch"
                    data-action="toggle"
                    data-class="mod-bigger-font"
                    onClick={() => {
                      theme.modBiggerFont
                        ? removeFromTheme('modBiggerFont')
                        : setTheme({
                            modBiggerFont: 'mod-bigger-font',
                          });
                    }}
                  />
                  <span className="onoffswitch-title">
                    {t('Bigger Content Font', 'Bigger Content Font')}
                  </span>
                  <span className="onoffswitch-title-desc">
                    {' '}
                    {t(
                      'content fonts are bigger for readability',
                      'content fonts are bigger for readability'
                    )}
                  </span>
                </div>
                <div className="list" id="mhc">
                  <a
                    className="btn btn-switch"
                    data-action="toggle"
                    data-class="mod-high-contrast"
                    onClick={() => {
                      theme.modHighContrast
                        ? removeFromTheme('modHighContrast')
                        : setTheme({
                            modHighContrast: 'mod-high-contrast',
                          });
                    }}
                  />
                  <span className="onoffswitch-title">
                    {' '}
                    {t(
                      'High Contrast Text (WCAG 2 AA)',
                      'High Contrast Text (WCAG 2 AA)'
                    )}
                  </span>
                  <span className="onoffswitch-title-desc">
                    {' '}
                    {t(
                      '4.5:1 text contrast ratio',
                      '4.5:1 text contrast ratio'
                    )}
                  </span>
                </div>

                <div className="list" id="mpc">
                  <a
                    className="btn btn-switch"
                    data-action="toggle"
                    data-class="mod-pace-custom"
                    onClick={() => {
                      theme.modPaceCustom
                        ? removeFromTheme('modPaceCustom')
                        : setTheme({
                            modPaceCustom: 'mod-pace-custom',
                          });
                    }}
                  />
                  <span className="onoffswitch-title">
                    {t('Preloader Inside', 'Preloader Inside')}
                  </span>
                  <span className="onoffswitch-title-desc">
                    {t(
                      'preloader will be inside content',
                      'preloader will be inside content'
                    )}
                  </span>
                </div>
                <div className="list" id="mpi">
                  <a
                    className="btn btn-switch"
                    data-action="toggle"
                    data-class="mod-panel-icon"
                    onClick={() => {
                      theme.modPanelIcon
                        ? removeFromTheme('modPanelIcon')
                        : setTheme({
                            modPanelIcon: 'mod-panel-icon',
                          });
                    }}
                  />
                  <span className="onoffswitch-title">
                    {t(
                      'SmartPanel Icons (not Panels)',
                      'SmartPanel Icons (not Panels)'
                    )}
                  </span>
                  <span className="onoffswitch-title-desc">
                    {t(
                      'smartpanel buttons will appear as icons',
                      'smartpanel buttons will appear as icons'
                    )}
                  </span>
                </div>
                <div className="mt-4 d-table w-100 px-5">
                  <div className="d-table-cell align-middle">
                    <h5 className="p-0">
                      {t('Global Modifications', 'Global Modifications')}
                    </h5>
                  </div>
                </div>
                <div className="list" id="mcbg">
                  <a
                    className="btn btn-switch"
                    data-action="toggle"
                    data-class="mod-clean-page-bg"
                    onClick={() => {
                      theme.modCleanPageBg
                        ? removeFromTheme('modCleanPageBg')
                        : setTheme({
                            modCleanPageBg: 'mod-clean-page-bg',
                          });
                    }}
                  />
                  <span className="onoffswitch-title">
                    {t('Clean Page Background', 'Clean Page Background')}
                  </span>
                  <span className="onoffswitch-title-desc">
                    {t('adds more whitespace', 'adds more whitespace')}
                  </span>
                </div>
                <div className="list" id="mhni">
                  <a
                    className="btn btn-switch"
                    data-action="toggle"
                    data-class="mod-hide-nav-icons"
                    onClick={() => {
                      theme.modHideNavIcons
                        ? removeFromTheme('modHideNavIcons')
                        : setTheme({
                            modHideNavIcons: 'mod-hide-nav-icons',
                          });
                    }}
                  />
                  <span className="onoffswitch-title">
                    {t('Hide Navigation Icons', 'Hide Navigation Icons')}
                  </span>
                  <span className="onoffswitch-title-desc">
                    {' '}
                    {t(
                      'invisible navigation icons',
                      'invisible navigation icons'
                    )}
                  </span>
                </div>
                <div className="list" id="dan">
                  <a
                    className="btn btn-switch"
                    data-action="toggle"
                    data-class="mod-disable-animation"
                    onClick={() => {
                      theme.modDisableAnimation
                        ? removeFromTheme('modDisableAnimation')
                        : setTheme({
                            modDisableAnimation: 'mod-disable-animation',
                          });
                    }}
                  />
                  <span className="onoffswitch-title">
                    {t('Disable CSS Animation', 'Disable CSS Animation')}
                  </span>
                  <span className="onoffswitch-title-desc">
                    {t(
                      'disables css based animations',
                      ' disables css based animations'
                    )}
                  </span>
                </div>
                <div className="list" id="mhic">
                  <a
                    className="btn btn-switch"
                    data-action="toggle"
                    data-class="mod-hide-info-card"
                    onClick={() => {
                      theme.modHideInfoCard
                        ? removeFromTheme('modHideInfoCard')
                        : setTheme({
                            modHideInfoCard: 'mod-hide-info-card',
                          });
                    }}
                  />
                  <span className="onoffswitch-title">
                    {t('Hide Info Card', 'Hide Info Card')}
                  </span>
                  <span className="onoffswitch-title-desc">
                    {t(
                      'hides info card from left panel',
                      'hides info card from left panel'
                    )}
                  </span>
                </div>
                <div className="list" id="mlph">
                  <a
                    className="btn btn-switch"
                    data-action="toggle"
                    data-class="mod-lean-subheader"
                    onClick={() => {
                      theme.modLeanSubheader
                        ? removeFromTheme('modLeanSubheader')
                        : setTheme({
                            modLeanSubheader: 'mod-lean-subheader',
                          });
                    }}
                  />
                  <span className="onoffswitch-title">
                    {t('Lean Subheader', 'Lean Subheader')}
                  </span>
                  <span className="onoffswitch-title-desc">
                    {t(
                      'distinguished page header',
                      'distinguished page header'
                    )}
                  </span>
                </div>
                <div className="list" id="mnl">
                  <a
                    className="btn btn-switch"
                    data-action="toggle"
                    data-class="mod-nav-link"
                    onClick={() => {
                      theme.modNavLink
                        ? removeFromTheme('modNavLink')
                        : setTheme({
                            modNavLink: 'mod-nav-link',
                          });
                    }}
                  />
                  <span className="onoffswitch-title">
                    {t('Hierarchical Navigation', 'Hierarchical Navigation')}
                  </span>
                  <span className="onoffswitch-title-desc">
                    {' '}
                    {t(
                      'clear breakdown of nav links',
                      'clear breakdown of nav links'
                    )}
                  </span>
                </div>
                <div className="list" id="mdn">
                  <a
                    className="btn btn-switch"
                    data-action="toggle"
                    data-class="mod-nav-dark"
                    onClick={() => {
                      theme.modNavDark
                        ? removeFromTheme('modNavDark')
                        : setTheme({
                            modNavDark: 'mod-nav-dark',
                          });
                    }}
                  />
                  <span className="onoffswitch-title">
                    {t('Dark Navigation', 'Dark Navigation')}
                  </span>
                  <span className="onoffswitch-title-desc">
                    {t(
                      'navigation background is darkend',
                      'navigation background is darkend'
                    )}
                  </span>
                </div>
                <hr className="mb-0 mt-4" />
                <div className="mt-4 d-table w-100 pl-5 pr-3">
                  <div className="d-table-cell align-middle">
                    <h5 className="p-0">
                      {t('Global Font Size', 'Global Font Size')}
                    </h5>
                  </div>
                </div>
                <div className="list mt-1">
                  <div
                    className="btn-group btn-group-sm btn-group-toggle my-2"
                    data-toggle="buttons"
                  >
                    <label
                      className={`btn btn-default btn-sm waves-effect waves-themed ${
                        theme.globalFontSize == 'root-text-sm' ? 'active' : ''
                      }`}
                      data-action="toggle-swap"
                      data-class="root-text-sm"
                      data-target="html"
                      onClick={() =>
                        setTheme({
                          globalFontSize: 'root-text-sm',
                        })
                      }
                    >
                      <input type="radio" name="changeFrontSize" /> SM
                    </label>
                    <label
                      className={`btn btn-default btn-sm waves-effect waves-themed ${
                        theme.globalFontSize == 'root-text' ? 'active' : ''
                      }`}
                      data-action="toggle-swap"
                      data-class="root-text"
                      data-target="html"
                      onClick={() =>
                        setTheme({
                          globalFontSize: 'root-text',
                        })
                      }
                    >
                      <input
                        type="radio"
                        name="changeFrontSize"
                        defaultChecked={`${
                          globalFont == 'root-text' ? 'true' : 'false'
                        }`}
                      />{' '}
                      MD
                    </label>
                    <label
                      className={`btn btn-default btn-sm waves-effect waves-themed ${
                        theme.globalFontSize == 'root-text-lg' ? 'active' : ''
                      }`}
                      data-action="toggle-swap"
                      data-class="root-text-lg"
                      data-target="html"
                      onClick={() =>
                        setTheme({
                          globalFontSize: 'root-text-lg',
                        })
                      }
                    >
                      <input type="radio" name="changeFrontSize" /> LG
                    </label>
                    <label
                      className={`btn btn-default btn-sm waves-effect waves-themed ${
                        theme.globalFontSize == 'root-text-xl' ? 'active' : ''
                      }`}
                      data-action="toggle-swap"
                      data-class="root-text-xl"
                      data-target="html"
                      onClick={() =>
                        setTheme({
                          globalFontSize: 'root-text-xl',
                        })
                      }
                    >
                      <input type="radio" name="changeFrontSize" />
                      XL
                    </label>
                  </div>
                  <span className="onoffswitch-title-desc d-block mb-0">
                    {t(
                      'change root font size to effect rem values (resets on page refresh)',
                      'change root font size to effect rem values (resets on page refresh)'
                    )}
                  </span>
                </div>
                <hr className="mb-0 mt-4" />
                <div className="mt-4 d-table w-100 pl-5 pr-3">
                  <div className="d-table-cell align-middle">
                    <h5 className="p-0 pr-2 d-flex">
                      Theme colors {t('Theme colors', 'Theme colors')}
                    </h5>
                  </div>
                </div>
                <div className="expanded theme-colors pl-5 pr-3">
                  <ul className="m-0">
                    <li>
                      <a
                        id="myapp-0"
                        data-action="theme-update"
                        data-themesave
                        data-theme=""
                        data-placement="top"
                        title="Wisteria (base css)"
                        data-original-title="Wisteria (base css)"
                        onClick={() =>
                          setTheme({
                            themeURL: '',
                          })
                        }
                      ></a>
                    </li>
                    <li>
                      <a
                        id="myapp-1"
                        data-action="theme-update"
                        data-themesave
                        data-theme="css/themes/cust-theme-1.css"
                        data-placement="top"
                        title="Tapestry"
                        data-original-title="Tapestry"
                        onClick={() =>
                          setTheme({
                            themeURL: 'css/themes/cust-theme-1.css',
                          })
                        }
                      ></a>
                    </li>
                    <li>
                      <a
                        id="myapp-2"
                        data-action="theme-update"
                        data-themesave
                        data-theme="css/themes/cust-theme-2.css"
                        data-placement="top"
                        title="Atlantis"
                        data-original-title="Atlantis"
                        onClick={() =>
                          setTheme({
                            themeURL: 'css/themes/cust-theme-2.css',
                          })
                        }
                      ></a>
                    </li>
                    <li>
                      <a
                        id="myapp-3"
                        data-action="theme-update"
                        data-themesave
                        data-theme="css/themes/cust-theme-3.css"
                        data-placement="top"
                        title="Indigo"
                        data-original-title="Indigo"
                        onClick={() =>
                          setTheme({
                            themeURL: 'css/themes/cust-theme-3.css',
                          })
                        }
                      ></a>
                    </li>
                    <li>
                      <a
                        id="myapp-4"
                        data-action="theme-update"
                        data-themesave
                        data-theme="css/themes/cust-theme-4.css"
                        data-placement="top"
                        title="Dodger Blue"
                        data-original-title="Dodger Blue"
                        onClick={() =>
                          setTheme({
                            themeURL: 'css/themes/cust-theme-4.css',
                          })
                        }
                      ></a>
                    </li>
                    <li>
                      <a
                        id="myapp-5"
                        data-action="theme-update"
                        data-themesave
                        data-theme="css/themes/cust-theme-5.css"
                        data-placement="top"
                        title="Tradewind"
                        data-original-title="Tradewind"
                        onClick={() =>
                          setTheme({
                            themeURL: 'css/themes/cust-theme-5.css',
                          })
                        }
                      ></a>
                    </li>
                    <li>
                      <a
                        id="myapp-6"
                        data-action="theme-update"
                        data-themesave
                        data-theme="css/themes/cust-theme-6.css"
                        data-placement="top"
                        title="Cranberry"
                        data-original-title="Cranberry"
                        onClick={() =>
                          setTheme({
                            themeURL: 'css/themes/cust-theme-6.css',
                          })
                        }
                      ></a>
                    </li>
                    <li>
                      <a
                        id="myapp-7"
                        data-action="theme-update"
                        data-themesave
                        data-theme="css/themes/cust-theme-7.css"
                        data-placement="top"
                        title="Oslo Gray"
                        data-original-title="Oslo Gray"
                        onClick={() =>
                          setTheme({
                            themeURL: 'css/themes/cust-theme-7.css',
                          })
                        }
                      ></a>
                    </li>
                    <li>
                      <a
                        id="myapp-8"
                        data-action="theme-update"
                        data-themesave
                        data-theme="css/themes/cust-theme-8.css"
                        data-placement="top"
                        title="Chetwode Blue"
                        data-original-title="Chetwode Blue"
                        onClick={() =>
                          setTheme({
                            themeURL: 'css/themes/cust-theme-8.css',
                          })
                        }
                      ></a>
                    </li>
                    <li>
                      <a
                        id="myapp-9"
                        data-action="theme-update"
                        data-themesave
                        data-theme="css/themes/cust-theme-9.css"
                        data-placement="top"
                        title="Apricot"
                        data-original-title="Apricot"
                        onClick={() =>
                          setTheme({
                            themeURL: 'css/themes/cust-theme-9.css',
                          })
                        }
                      ></a>
                    </li>
                    <li>
                      <a
                        id="myapp-10"
                        data-action="theme-update"
                        data-themesave
                        data-theme="css/themes/cust-theme-10.css"
                        data-placement="top"
                        title="Blue Smoke"
                        data-original-title="Blue Smoke"
                        onClick={() =>
                          setTheme({
                            themeURL: 'css/themes/cust-theme-10.css',
                          })
                        }
                      ></a>
                    </li>
                    <li>
                      <a
                        id="myapp-11"
                        data-action="theme-update"
                        data-themesave
                        data-theme="css/themes/cust-theme-11.css"
                        data-placement="top"
                        title="Green Smoke"
                        data-original-title="Green Smoke"
                        onClick={() =>
                          setTheme({
                            themeURL: 'css/themes/cust-theme-11.css',
                          })
                        }
                      ></a>
                    </li>
                    <li>
                      <a
                        id="myapp-12"
                        data-action="theme-update"
                        data-themesave
                        data-theme="css/themes/cust-theme-12.css"
                        data-placement="top"
                        title="Wild Blue Yonder"
                        data-original-title="Wild Blue Yonder"
                        onClick={() =>
                          setTheme({
                            themeURL: 'css/themes/cust-theme-12.css',
                          })
                        }
                      ></a>
                    </li>
                    <li>
                      <a
                        id="myapp-13"
                        data-action="theme-update"
                        data-themesave
                        data-theme="css/themes/cust-theme-13.css"
                        data-placement="top"
                        title="Emerald"
                        data-original-title="Emerald"
                        onClick={() =>
                          setTheme({
                            themeURL: 'css/themes/cust-theme-13.css',
                          })
                        }
                      ></a>
                    </li>
                    <li>
                      <a
                        id="myapp-14"
                        data-action="theme-update"
                        data-themesave
                        data-theme="css/themes/cust-theme-14.css"
                        data-placement="top"
                        title="Supernova"
                        data-original-title="Supernova"
                        onClick={() =>
                          setTheme({
                            themeURL: 'css/themes/cust-theme-14.css',
                          })
                        }
                      ></a>
                    </li>
                    <li>
                      <a
                        id="myapp-15"
                        data-action="theme-update"
                        data-themesave
                        data-theme="css/themes/cust-theme-15.css"
                        data-placement="top"
                        title="Hoki"
                        data-original-title="Hoki"
                        onClick={() =>
                          setTheme({
                            themeURL: 'css/themes/cust-theme-15.css',
                          })
                        }
                      ></a>
                    </li>
                  </ul>
                </div>
                <hr className="mb-0 mt-4" />
                <div className="mt-4 d-table w-100 pl-5 pr-3">
                  <div className="d-table-cell align-middle">
                    <h5 className="p-0 pr-2 d-flex">
                      {t('Theme Modes (beta)', 'Theme Modes (beta)')}
                    </h5>
                  </div>
                </div>
                <div className="pl-5 pr-3 py-3">
                  <div className="row no-gutters">
                    <div className="col-4 pr-2 text-center">
                      <div
                        id="skin-default"
                        data-action="toggle-replace"
                        data-replaceclass="mod-skin-light mod-skin-dark"
                        data-class
                        data-toggle="tooltip"
                        data-placement="top"
                        className="d-flex bg-white border border-primary rounded overflow-hidden text-success js-waves-on waves-effect waves-themed"
                        data-original-title="Default Mode"
                        style={{ height: 80 }}
                        onClick={() => {
                          setTheme({ themeSkin: 'skin-default' });
                        }}
                      >
                        <div className="bg-primary-600 bg-primary-gradient px-2 pt-0 border-right border-primary" />
                        <div className="d-flex flex-column flex-1">
                          <div className="bg-white border-bottom border-primary py-1" />
                          <div className="bg-faded flex-1 pt-3 pb-3 px-2">
                            <div
                              className="py-3"
                              style={{
                                background:
                                  'url("img/demo/s-1.png") top left no-repeat',
                                backgroundSize: '100%',
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      Default
                    </div>
                    <div className="col-4 px-1 text-center">
                      <div
                        id="skin-light"
                        data-action="toggle-replace"
                        data-replaceclass="mod-skin-dark"
                        data-class="mod-skin-light"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="true"
                        className="d-flex bg-white border border-secondary rounded overflow-hidden text-success js-waves-on waves-effect waves-themed"
                        data-original-title="Light Mode"
                        style={{ height: 80 }}
                        onClick={() => {
                          setTheme({ themeSkin: 'mod-skin-light' });
                        }}
                      >
                        <div className="bg-white px-2 pt-0 border-right border-" />
                        <div className="d-flex flex-column flex-1">
                          <div className="bg-white border-bottom border- py-1" />
                          <div className="bg-white flex-1 pt-3 pb-3 px-2">
                            <div
                              className="py-3"
                              style={{
                                background:
                                  'url("img/demo/s-1.png") top left no-repeat',
                                backgroundSize: '100%',
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      Light
                    </div>
                    <div className="col-4 pl-2 text-center">
                      <div
                        id="skin-dark"
                        data-action="toggle-replace"
                        data-replaceclass="mod-skin-light"
                        data-class="mod-skin-dark"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="true"
                        className="d-flex bg-white border border-dark rounded overflow-hidden text-success js-waves-on waves-effect waves-themed"
                        data-original-title="Dark Mode"
                        style={{ height: 80 }}
                        onClick={() => {
                          setTheme({ themeSkin: 'mod-skin-dark' });
                        }}
                      >
                        <div className="bg-fusion-500 px-2 pt-0 border-right" />
                        <div className="d-flex flex-column flex-1">
                          <div className="bg-fusion-600 border-bottom py-1" />
                          <div className="bg-fusion-300 flex-1 pt-3 pb-3 px-2">
                            <div
                              className="py-3 opacity-30"
                              style={{
                                background:
                                  'url("img/demo/s-1.png") top left no-repeat',
                                backgroundSize: '100%',
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      Dark
                    </div>
                  </div>
                </div>
                <hr className="mb-0 mt-4" />
                <div className="pl-5 pr-3 py-3 bg-faded">
                  <div className="row no-gutters">
                    <div className="col-12 pr-1">
                      <a
                        className="btn btn-outline-danger fw-500 btn-block waves-effect waves-themed"
                        data-action="app-reset"
                        onClick={() => resetLayout()}
                      >
                        {t('Reset Settings', 'Reset Settings')}
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <span id="saving" />
            </div>
          </div>
        </div>
      </div>
      {/* END Page Settings */}
    </div>
  );
};

export default SettingsPanel;
