import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { isObject } from "lodash";

import Panel from "../../../common/Panel/Panel";
import Table from "../../../common/Table";

const EntityAudit = ({ entities }) => {
  const data = entities;

  const columns = useMemo(
    () => [
      {
        Header: "Entity Id",
        accessor: "entityId",
        sortable: true,
        Cell: ({ row }) => {
          return (
            <div>
              <Link
                to={`/administration/help-sections/${row.original.entityId}`}
              >
                {row.original.entityId}
              </Link>
            </div>
          );
        },
      },
      {
        Header: "Action",
        accessor: "action",
      },
      {
        Header: "Version",
        accessor: "commitVersion",
      },
      {
        Header: "Value",
        accessor: ({ entityValue }) => {
          const values = JSON.parse(entityValue);
          return (
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Field</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(values)
                  .filter((key) => !isObject(values[key]))
                  .map((valKey, i) => (
                    <tr key={i}>
                      <td>{valKey}</td>
                      <td>{values[valKey]}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          );
        },
      },
      {
        Header: "Date Modified",
        accessor: ({ modifiedDate }) =>
          moment(modifiedDate).format("MMM DD,  YYYY h:mm:ss A"),
      },
      { Header: "Modified By", accessor: "modifiedBy" },
      {
        Header: "",
        accessor: "actionColumns",
        className: "align-middle",
        Cell: ({ row }) => {
          return (
            <div class="text-center d-flex">
              <Link
                className="btn btn-icon btn-circle btn-default mr-2"
                to={`/system/help-section/${row.original.id}`}
              >
                <i className="fal fa-eye"></i>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <Panel>
      <Table data={data} columns={columns} />
    </Panel>
  );
};

export default EntityAudit;
