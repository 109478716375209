import React from "react";

import "./help-text.scss";

const HelpText = ({ text }) => {
  return (
    <div className="help-text_warper">
      <p>{text}</p>
    </div>
  );
};

export default HelpText;
