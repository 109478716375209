import React, { useEffect, useState } from 'react';
import { Vega } from 'react-vega';

const DrawGraph = ({ esData, vegaGraph, graphDiv, width, height }) => {
  const [graphData, setGraphData] = useState({});
  const [showGraph, setShowGraph] = useState(false);
  const [graph, setGraph] = useState(vegaGraph);
  const drawGraph_response = async (response, div) => {
    console.log('Response', response.aggregations.results.buckets);
    var sum_rep_tags = 0;
    if (response != null) {
      if (div === '#reputation') {
        if (response.aggregations.results.buckets.length > 0) {
          for (
            var x = 0;
            x < response.aggregations.results.buckets.length;
            x++
          ) {
            if (response.aggregations.results.buckets[x].ip_rep !== undefined) {
              if (
                response.aggregations.results.buckets[x].ip_rep.buckets.length >
                0
              ) {
                sum_rep_tags +=
                  response.aggregations.results.buckets[x].ip_rep.buckets
                    .length;
              }
            }
          }
        }
      }
      if (response.aggregations.results.buckets.length == 0) {
      } else if (response.aggregations.results.buckets.length > 0) {
        if (div === '#reputation') {
          if (sum_rep_tags <= 0) {
            setGraphData(null);
            return;
          }
        }
        setGraphData({ table: response.aggregations.results.buckets });
        //    console.log('result gtom graph', div);
        //    console.log(response.aggregations.results.buckets);
        setShowGraph(true);
      }
    }
  };

  const drawGraph_data = async (div) => {
    setGraphData(esData);
    setShowGraph(true);
  };
  useEffect(async () => {
    //  console.log('GRAPH: ', graphDiv);
    if (
      graphDiv == '#applications_top_traffic_bar' ||
      graphDiv == '#applications_top_packets_bar' ||
      graphDiv == '#applications_top_sessions_bar'
    ) {
      vegaGraph.width = width - 100;
      vegaGraph.height = width / 2.5;
      if (height < 300) {
        vegaGraph.height = width / 2.5;
      }
    }

    if (graphDiv == '#applications_bits_bar') {
      vegaGraph.width = width - 100;
      vegaGraph.height = width / 3;
      if (width < 400) {
        vegaGraph.width = 290;
        vegaGraph.height = 140;
      }
    }
    if (graphDiv == '#application_sankey_bytes') {
      vegaGraph.width = width - 100;
      vegaGraph.height = width / 2;
      if (height < 300) {
        vegaGraph.height = width / 1.5;
      }
    }

    if (graphDiv == '#applications_flare') {
      vegaGraph.width = width - 50;
      if (vegaGraph.width > 400) vegaGraph.width = 350;
    }

    if (graphDiv == '#application_sunburst') {
      vegaGraph.width = width - 50;
      if (vegaGraph.width > 400) vegaGraph.width = 350;
    }
    //  console.log(vegaGraph);
    if (
      graphDiv == '#applications_flare' ||
      graphDiv == '#application_sunburst'
    ) {
      drawGraph_data(graphDiv);
    } else drawGraph_response(esData, graphDiv);
  }, []);
  if (showGraph) {
    return (
      <div>
        <Vega spec={vegaGraph} data={graphData} />
      </div>
    );
  } else {
    if (graphData == {} || graphData == null) {
      return <p>No Data</p>;
    } else return <p>Loading...</p>;
  }
};

export default DrawGraph;
