export const getNTUs = async () => {
  const token = localStorage.getItem('token');

  const baseUrl = 'https://test.activeport.com.au/api/ntus/admin';

  return fetch(baseUrl, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getNTU = (id) => {
  const token = localStorage.getItem('token');

  const baseUrl = 'https://test.activeport.com.au/api/ntus/';

  const requestUrl = `${baseUrl}${id}`;
  return fetch(requestUrl, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getTimeZones = async () => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/ntus/timezones';

  return fetch(baseUrl, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getTemplateConfigurations = async () => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/template-configurations';

  return fetch(baseUrl, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getSubnetAllocation = (id, key) => {
  const token = localStorage.getItem('token');

  const baseUrl = `https://test.activeport.com.au/api/subnet-allocations/${id}?key=${key}`;

  const requestUrl = `${baseUrl}`;
  return fetch(requestUrl, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getNtuPorts = async () => {
  const token = localStorage.getItem('token');
  const ntuId = JSON.parse(localStorage.getItem('ntu')).id;
  const baseUrl = `https://test.activeport.com.au/api/ntu-ports?isValid=false&ntuId=${ntuId}`;

  return fetch(baseUrl, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const checkConnectionStatus = (ntu) => {
  const token = localStorage.getItem('token');
  const baseUrl =
    'https://test.activeport.com.au/api/ntu-test?cacheBuster=1655889286546&profile=';
  return fetch(baseUrl, {
    method: 'POST',
    body: JSON.stringify(ntu),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};
