import React, { useEffect, useState, Fragment } from 'react';
import {
  Routes,
  Route,
  useLocation,
  Link,
  useNavigate,
} from 'react-router-dom';
import { deleteMediaQuery, getMedia } from './api/mediaApi';
import MediaFiles from './MediaFiles';
import MediaUploader from './MediaUploader';
import { handleDeleteAlert } from '../../../common/Alerts/DeleteAlert';

const MediaContainer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [entities, setEntities] = useState([]);
  const [deleteState, setDeleteState] = useState(false);
  const [appState, setAppState] = useState({
    loading: false,
    err: false,
  });

  const deleteMedia = async (id) => {
    await deleteMediaQuery(id)
      .then((res) => res.json())
      .catch((error) => error);

    handleDeleteAlert();
    setDeleteState(!deleteState);
  };
  useEffect(() => {
    (async () => {
      try {
        setAppState({ loading: true, err: false });
        const res = await getMedia().then((res) => res.json());

        setEntities(res);
      } catch (err) {
        setAppState({ loading: false, err: true });
      }
    })();
    setAppState({ loading: false });
  }, [location, deleteState]);

  return (
    <Fragment>
      <ol className="breadcrumb page-breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">Media</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to="/administration">System</Link>
        </li>
        <li className="breadcrumb-item active">Media Files</li>
        <li className="position-absolute pos-top pos-right d-none d-sm-block">
          <span className="js-get-date"></span>
        </li>
      </ol>
      <div className="subheader">
        <h1 className="subheader-title">
          <i className="subheader-icon fal fa-table"></i> Media Files
        </h1>

        {!location.pathname.includes('/new') &&
          !location.pathname.includes('/edit') && (
            <Link to={`new`}>
              <button
                type="button"
                className="btn btn-success waves-effect waves-themed"
              >
                <span className="fal fa-plus mr-1"></span>
                Add Media
              </button>
            </Link>
          )}
      </div>
      {!appState.loading && !appState.err && (
        <Routes>
          <Route
            exact
            path={`/new`}
            element={<MediaUploader entities={entities} />}
          />
          <Route
            path={`/`}
            element={
              <MediaFiles entities={entities} deleteMedia={deleteMedia} />
            }
          />
        </Routes>
      )}
      {appState.loading && !appState.error && (
        <div className="border p-3">
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      )}
      {!appState.loading && appState.err && (
        <div className="alert alert-danger" role="alert">
          <strong>Oh snap!</strong> We are not able to connect to the database.
          Try again in few minutes. again.
        </div>
      )}
      {/* <Route
          exact
          path={`/:id/delete`}
          element={TenantDelete}
        /> */}
    </Fragment>
  );
};

export default MediaContainer;
