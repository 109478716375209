import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import Panel from "../../../common/Panel/Panel";
import { getRegion } from "./api/regionsApi";
import { convertCamelCaseToCapitalize } from "../../../utils/string-transformers";

const RegionDetail = () => {
  const match = useParams();

  const navigate = useNavigate();
  const [regionEntity, setRegionEntity] = useState({
    availabilityZone: "ap-southeast-2",
    description: "Perth, Australia",
    id: 1,
    name: "Perth",
    title: "acp-au-perth",
    uid: "14e9dc88-a2fd-442c-a9a0-db7c36d2c4d0",
  });
  const [appState, setAppState] = useState({
    loading: false,
  });

  useEffect(() => {
    setAppState({ loading: true });
    const fetchData = async () => {
      try {
        const result = await getRegion(match.id).then((res) => res.json());
        setRegionEntity(result);
        console.log("Region Detail: ", result);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    setAppState({ loading: false });
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-xl-12">
          {appState.loading && <p>Loading...</p>}
          {regionEntity !== null && !appState.loading && (
            <Panel>
              <dl className="row">
                {Object.keys(regionEntity).map((entityKey) => (
                  <>
                    <dt className="text-inverse  col-4 ">
                      <span id="command">
                        <span>{convertCamelCaseToCapitalize(entityKey)}</span>
                      </span>
                    </dt>
                    <dd className="col-8 ">{regionEntity[entityKey]}</dd>
                  </>
                ))}
              </dl>

              <div>
                <button
                  className="btn btn-sm btn-secondary mr-3"
                  onClick={() => navigate("../")}
                >
                  <i className="fal fa-arrow-left fa-sm  mr-2"></i>
                  Back
                </button>
                <button
                  className="btn btn-sm btn-primary m-r-5"
                  onClick={() => navigate("../edit/" + match.id)}
                >
                  <i className="fal fa-pencil-alt fa-sm  mr-2"></i>
                  Edit
                </button>
              </div>
            </Panel>
          )}
        </div>
      </div>
    </div>
  );
};

export default RegionDetail;
