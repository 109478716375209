import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';
import Panel from '../../../common/Panel/Panel';
import DrawGraph from './DrawGraph';
import { getElasticSearchData } from './api/elasticsearch';
import moment from 'moment';
import tz from 'moment-timezone';
import ContainerDimensions from 'react-container-dimensions';

import applications_flow_source_pie_request from '../../../common/graphs/vega/applications/applications_flow_source_pie_request.json';
import applications_flow_source_pie from '../../../common/graphs/vega/applications/applications_flow_source_pie.json';

import applications_flow_dest_pie_request from '../../../common/graphs/vega/applications/applications_flow_dest_pie_request.json';
import applications_flow_dest_pie from '../../../common/graphs/vega/applications/applications_flow_dest_pie.json';

import applications_flow_app_pie_request from '../../../common/graphs/vega/applications/applications_flow_app_pie_request.json';
import applications_flow_app_pie from '../../../common/graphs/vega/applications/applications_flow_app_pie.json';

import application_sankey_bytes_pie_request from '../../../common/graphs/vega/applications/application_sankey_bytes_pie_request.json';
import application_sankey_bytes_pie from '../../../common/graphs/vega/applications/application_sankey_bytes_pie.json';

const Sankey = ({ gte, lte, ntu }) => {
  var ignore_throttled = 1;
  const [isLoading, setIsLoading] = useState(true);
  const [
    application_sankey_bytes_pie_data,
    set_application_sankey_bytes_pie_data,
  ] = useState({});
  const [applications_flow_dest_pie_data, set_applications_flow_dest_pie_data] =
    useState({});
  const [applications_flow_app_pie_data, set_applications_flow_app_pie_data] =
    useState({});
  const [
    applications_flow_source_pie_data,
    set_applications_flow_source_pie_data,
  ] = useState({});

  const [sankeyGraph, setSankeyGraph] = useState(application_sankey_bytes_pie);

  const getLTE = () => {
    if (lte == null || lte == '') {
      lte = new Date();
      lte = moment();
      lte = lte._d.getTime();
    }
    return lte;
  };
  const getGTE = () => {
    if (gte == null || gte == '') {
      gte = new Date();
      gte = moment().subtract(3, 'hours');
      gte = gte._d.getTime();
    }
    return gte;
  };

  const getIgnoreThrottled = () => {
    var now = new Date();
    //var lte_diff = moment(now).diff(moment(vm.lte), 'days')
    var gte_diff = moment(now).diff(moment(getGTE()), 'months');
    //             console.log("lte_diff = " + lte_diff);
    console.log('gte_diff = ' + gte_diff);
    if (gte_diff > 0) {
      ignore_throttled = 0; // searchable snapshot
    }
    return ignore_throttled;
  };

  const elasticRequest = async (request, div) => {
    request.query.bool.filter.push({
      term: { 'serial-number.keyword': ntu.serialNumber },
    });
    //data.query.bool.must[0].query_string["query"] = "serial-number: " + vm.serial;
    request.query.bool.must[0].range['@timestamp'].gte = getGTE();
    request.query.bool.must[0].range['@timestamp'].lte = getLTE();

    try {
      let response = await getElasticSearchData('search', {
        ignoreThrottled: getIgnoreThrottled(),
        index: 'apptrack-*',
        size: 0,

        body: JSON.stringify(request),
      }).then((res) => res.json());
      console.log('SANKEY RESPONSE:', response);
      if (div == '#applications_flow_app_pie') {
        set_applications_flow_app_pie_data(response);
      }
      if (div == '#applications_flow_source_pie') {
        set_applications_flow_source_pie_data(response);
      }
      if (div == '#applications_flow_dest_pie') {
        set_applications_flow_dest_pie_data(response);
      }
      if (div == '#application_sankey_bytes') {
        set_application_sankey_bytes_pie_data(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateVegaSize = () => {
    setIsLoading(true);
    setIsLoading(false);
  };

  function handleResize() {
    // Set window width/height to state

    updateVegaSize();
  }

  useEffect(async () => {
    await elasticRequest(
      applications_flow_app_pie_request,
      '#applications_flow_app_pie'
    );

    await elasticRequest(
      applications_flow_source_pie_request,
      '#applications_flow_source_pie'
    );
    await elasticRequest(
      applications_flow_dest_pie_request,
      '#applications_flow_dest_pie'
    );
    await elasticRequest(
      application_sankey_bytes_pie_request,
      '#application_sankey_bytes'
    );

    setIsLoading(false);

    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, [lte, gte]);

  if (isLoading)
    return (
      <div class="LoadingContainer text-center">
        <p>Loading</p>
      </div>
    );
  else
    return (
      <div>
        <div className="row">
          <div className="col-md-4 col-sm-12 col-xs-12">
            <div className="row">
              <div className="col-md-12 col-sm-6 col-xs-12">
                <ContainerDimensions>
                  {({ width, height }) => (
                    <Panel heading="Clients (flow records)">
                      <div>
                        <DrawGraph
                          width={width}
                          height={height}
                          esData={applications_flow_source_pie_data}
                          graphDiv={'#applications_flow_source_pie'}
                          vegaGraph={applications_flow_source_pie}
                        />
                      </div>
                    </Panel>
                  )}
                </ContainerDimensions>
              </div>
              <div className="col-md-12 col-sm-6 col-xs-12">
                <ContainerDimensions>
                  {({ width, height }) => (
                    <Panel heading="Servers (flow records)">
                      <div>
                        <DrawGraph
                          width={width}
                          height={height}
                          esData={applications_flow_dest_pie_data}
                          graphDiv={'#applications_flow_dest_pie'}
                          vegaGraph={applications_flow_dest_pie}
                        />
                      </div>
                    </Panel>
                  )}
                </ContainerDimensions>
              </div>

              <div className="col-md-12 col-sm-6 col-xs-12">
                <ContainerDimensions>
                  {({ width, height }) => (
                    <Panel heading="Services (flow records)">
                      <div>
                        <DrawGraph
                          width={width}
                          height={height}
                          esData={applications_flow_app_pie_data}
                          graphDiv={'#applications_flow_app_pie'}
                          vegaGraph={applications_flow_app_pie}
                        />
                      </div>
                    </Panel>
                  )}
                </ContainerDimensions>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
            <ContainerDimensions>
              {({ width, height }) => (
                <Panel heading="Sankey (bytes)">
                  <div>
                    <DrawGraph
                      esData={application_sankey_bytes_pie_data}
                      graphDiv={'#application_sankey_bytes'}
                      vegaGraph={sankeyGraph}
                      width={width}
                      height={height}
                    />
                  </div>
                </Panel>
              )}
            </ContainerDimensions>
          </div>
        </div>
      </div>
    );
};

export default Sankey;
