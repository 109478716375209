import React, { useEffect, useState, Fragment } from "react";
import { Routes, Route, useLocation, Link } from "react-router-dom";

import InstanceTypes from "./InstanceTypes";
import InstanceTypeDetail from "./InstanceTypeDetail";
import InstanceTypeUpdate from "./InstanceTypeUpdate";
// import InstanceTypeDelete from "./InstanceTypeDeleteDialog";
import { getInstanceTypes } from "./api/instanceTypesApi";

const InstanceTypesContainer = () => {
  const location = useLocation();

  const [entities, setEntities] = useState([
    {
      bandwidth: 100,
      cores: 2,
      gpu: 1,
      id: 1,
      maxMemory: 15258,
      memory: 8192,
      name: "acp.large",
      threads: 2,
      unit: "MiB",
      vcpu: 4,
    },
  ]);
  const [appState, setAppState] = useState({
    loading: false,
    err: false,
  });

  useEffect(() => {
    (async () => {
      try {
        setAppState({ loading: true, err: false });
        const res = await getInstanceTypes().then((res) => res.json());
        setAppState({ loading: false });
        setEntities(res);
      } catch (err) {
        setAppState({ loading: false, err: true });
      }
    })();
  }, [location.key]);

  return (
    <Fragment>
      <ol className="breadcrumb page-breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">ActivePort</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to="/administration">Administration</Link>
        </li>
        <li className="breadcrumb-item active">InstanceTypes</li>
        <li className="position-absolute pos-top pos-right d-none d-sm-block">
          <span className="js-get-date"></span>
        </li>
      </ol>
      <div className="subheader">
        <h1 className="subheader-title">
          <i className="subheader-icon fal fa-table"></i> Instance Types
        </h1>

        {!location.pathname.includes("/new") &&
          !location.pathname.includes("/edit") && (
            <Link to={`new`}>
              <button
                type="button"
                className="btn btn-success waves-effect waves-themed"
              >
                <span className="fal fa-plus mr-1"></span>
                Create
              </button>
            </Link>
          )}
      </div>
      {!appState.loading && (
        // && !appState.err
        <Routes>
          <Route exact path={`/new`} element={<InstanceTypeUpdate />} />
          <Route exact path={`/edit/:id`} element={<InstanceTypeUpdate />} />
          <Route exact path={`:id`} element={<InstanceTypeDetail />} />

          <Route path={`/`} element={<InstanceTypes entities={entities} />} />
        </Routes>
      )}
      {appState.loading && !appState.error && (
        <div className="border p-3">
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      )}
      {!appState.loading && appState.err && (
        <div className="alert alert-danger" role="alert">
          <strong>Oh snap!</strong> We are not able to connect to the database.
          Try again in few minutes. again.
        </div>
      )}
      {/* <Route
          exact
          path={`/:id/delete`}
          element={InstanceTypeDelete}
        /> */}
    </Fragment>
  );
};

export default InstanceTypesContainer;
