import React, { useMemo, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import Panel from '../../../common/Panel/Panel';
import Table from '../../../common/Table';
import { CIRCUIT_TYPES } from '../../../constants/circuitTypeEnum';
import AuthContext from '../../../store/auth-context';

import { handleDeleteAlert } from '../../../common/Alerts/DeleteAlert';
const ServiceConfig = ({ entities }) => {
  const data = entities;

  const AuthCtx = useContext(AuthContext);

  console.log('auth', AuthCtx);

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'row_id',
        sortable: true,
        Cell: ({ row }) => {
          return (
            <div>
              <Link to={`${row.original.tenantId}`}>{row.original.id}</Link>
            </div>
          );
        },
      },
      {
        Header: 'Organisation',
        accessor: (d) => {
          return <p>ActivePort</p>;
        },
        sortable: true,
      },
      {
        Header: 'Name',
        accessor: 'serviceCodeName',
        sortable: true,
      },
      {
        Header: 'State',
        accessor: 'state',
        sortable: true,
      },

      {
        Header: 'Service Type',
        accessor: 'serviceTypeCode',
        sortable: true,
      },
      {
        Header: 'Provider Configuration',
        accessor: (d) => {
          return (
            <p>
              {d.providerName} {d.providerPortName}
            </p>
          );
        },
        sortable: true,
      },

      {
        Header: 'Item Inventory',
        accessor: 'priceCodeName',
        sortable: true,
      },
      {
        Header: 'PE Hosted Types',
        accessor: (d) =>
          d.hostedTypes.map((item) => {
            return <button class="btn btn-light mr-1 mb-1">{item}</button>;
          }),

        sortable: true,
      },
      {
        Header: 'Port Location',
        accessor: 'locationCode',
        sortable: true,
      },
      {
        Header: '',
        accessor: 'actionColumns',
        className: 'align-middle',
        Cell: ({ row }) => {
          return (
            <div className="text-center d-flex">
              <Link
                className="btn btn-icon btn-circle btn-default mr-2"
                to={`${row.original.id}`}
              >
                <i className="fal fa-eye"></i>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <Panel>
      <Table data={data} columns={columns} />
    </Panel>
  );
};

export default ServiceConfig;
