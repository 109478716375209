import React, { useMemo } from "react";
import { Link } from "react-router-dom";

import Panel from "../../../common/Panel/Panel";
import Table from "../../../common/Table";
import { handleDeleteAlert } from "../../../common/Alerts/DeleteAlert";

const organizations = JSON.parse(localStorage.getItem("accountInfo"))?.filter
  .filters;

const TemplateConfigurations = ({ entities, hasAuthority }) => {
  const data = entities;

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "row_id",
        sortable: true,
        Cell: ({ row }) => {
          return (
            <div>
              <Link to={`${row.original.id}`}>{row.original.id}</Link>
            </div>
          );
        },
      },
      {
        Header: "Organization",
        accessor: (item) => {
          const { orgName } = organizations.find(
            (org) => org.orgId === item.orgId
          );
          return orgName;
        },
        sortable: true,
      },
      {
        Header: "Name",
        accessor: "name",
        sortable: true,
      },

      {
        Header: "NTU OS",
        accessor: "osType",
        sortable: true,
      },
      {
        Header: "Model List",
        accessor: (d) =>
          d.modelList?.map((listItem, i) => (
            <button className="btn btn-light mr-1 mb-1" key={i}>
              {listItem}
            </button>
          )),
        sortable: true,
      },
      {
        Header: "Configuration",
        accessor: (d) => (
          <textarea
            readOnly
            style={{
              height: "30em",
              width: "62em",
              border: "1px solid #e4e5e7",
              padding: "6px 1px 6px 12px",
              overflowY: "scroll",
              resize: false,
            }}
          >
            {d.configuration}
          </textarea>
        ),
        sortable: true,
      },
      {
        Header: "",
        accessor: "actionColumns",
        className: "align-middle",
        Cell: ({ row }) => {
          return (
            <div className="text-center d-flex">
              <Link
                className="btn btn-icon btn-circle btn-default mr-2"
                to={`${row.original.id}`}
              >
                <i className="fal fa-eye"></i>
              </Link>
              {hasAuthority && (
                <>
                  <Link
                    className="btn btn-icon btn-circle btn-success mr-2"
                    to={`../edit/${row.original.id}`}
                  >
                    <i className="fal fa-pencil-alt"></i>
                  </Link>
                  <button
                    className="btn btn-icon btn-circle btn-danger"
                    onClick={handleDeleteAlert}
                  >
                    <i className="fal fa-trash"></i>
                  </button>
                </>
              )}
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <Panel>
      <Table data={data} columns={columns} />
    </Panel>
  );
};

export default TemplateConfigurations;
