import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import Panel from "../../../common/Panel/Panel";
import Table from "../../../common/Table";

const CircuitConfigurations = ({ entities }) => {
  const ntu = localStorage.getItem("ntu");
  const data = entities;
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "row_id",
        sortable: true,
        Cell: ({ row }) => {
          return (
            <div>
              <Link to={`${row.original.id}`}>{row.original.id}</Link>
            </div>
          );
        },
      },
      {
        Header: "Organization",
        accessor: "orgId",
        sortable: true,
      },
      {
        Header: "Zone",
        accessor: "zone",
        sortable: true,
      },
      {
        Header: "Service Key",
        accessor: "serviceKey",
        sortable: true,
      },
      {
        Header: "Description",
        accessor: "description",
        sortable: true,
      },
      {
        Header: "VLAN ID",
        accessor: "vlanId",
        sortable: true,
      },
      {
        Header: "Service Type",
        accessor: "type",
        sortable: true,
      },
      {
        Header: "Service ID",
        accessor: "serviceId",
        sortable: true,
      },
      {
        Header: "Service Configuration",
        accessor: "serviceConfigurationName",
        sortable: true,
      },
      {
        Header: "",
        accessor: "actionColumns",
        className: "align-middle",
        Cell: ({ row }) => {
          return (
            <div className="text-center d-flex">
              <Link
                className="btn btn-icon btn-circle btn-default mr-2"
                to={`${row.original.id}`}
              >
                <i className="fal fa-eye"></i>
              </Link>
              {ntu && (
                <>
                  <Link
                    className="btn btn-icon btn-circle btn-success mr-2"
                    to={`../edit/${row.original.id}`}
                  >
                    <i className="fal fa-pencil-alt"></i>
                  </Link>
                  <button
                    className="btn btn-icon btn-circle btn-danger"
                    // onClick={handleDeleteAlert}
                  >
                    <i className="fal fa-trash"></i>
                  </button>
                </>
              )}
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <Panel>
      <Table data={data} columns={columns} />
    </Panel>
  );
};

export default CircuitConfigurations;
