import React, { useState } from "react";

import { ServicesBP1 } from "../ServicesBP1";
import { AddAlibabaService } from "./AddAlibabaService";

const Oracle = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <ServicesBP1
        title="ALIBABA"
        subTitle="Configure the ALIBABA Service"
        addBtnText="Add ALIBABA Service"
        showModal={showModal}
        setShowModal={setShowModal}
        serviceLogoUrl="/assets/img/ntu/ALIBABA-TILE.png"
      />
      <AddAlibabaService
        show={showModal}
        setShow={setShowModal}
        //   onSave={onSave}
      />
    </>
  );
};

export default Oracle;
