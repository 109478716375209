import React, { useMemo } from "react";
import moment from "moment";

import Panel from "../../../common/Panel/Panel";
import Table from "../../../common/Table";

const LoginAudits = ({ entities }) => {
  const data = entities;

  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: ({ timestamp }) =>
          moment(timestamp).format("MMM DD, YYYY h:mm:ss A"),
        sortable: true,
      },
      {
        Header: "User",
        accessor: "principal",
        sortable: true,
      },
      {
        Header: "State",
        accessor: "type",
      },
      { Header: "Extra Data", accessor: "extraData" },
    ],
    []
  );

  return (
    <Panel>
      <Table data={data} columns={columns} />
    </Panel>
  );
};

export default LoginAudits;
