import Provisioning from '../components/Dashboards/Provisioning';
import Zettanet from '../components/Dashboards/Provisioning/Zettanet';
import Zettagrid from '../components/Dashboards/Provisioning/Zettagrid';
import Probax from '../components/Dashboards/Provisioning/Probax';
import LocalNet from '../components/Dashboards/Provisioning/LocalNet';
import NetworkConnect from '../components/Dashboards/Provisioning/NetworkConnect';
import XCNetworkConnect from '../components/Dashboards/Provisioning/XC';
import VXCNetworkConnect from '../components/Dashboards/Provisioning/VXC';
import ActiveportInternet from '../components/Dashboards/Provisioning/ActiviportInternet';
import Oracle from '../components/Dashboards/Provisioning/Oracle';
import AlibabaCloud from '../components/Dashboards/Provisioning/AlibabaCloud';
import GoogleCloud from '../components/Dashboards/Provisioning/GoogleCloud';
import IBMCloud from '../components/Dashboards/Provisioning/IBMCloud';
import PeeringExchange from '../components/Dashboards/Provisioning/PeeringExchange';
import PartnerConnect from '../components/Dashboards/Provisioning/PartnerConnect';
import AWSCloud from '../components/Dashboards/Provisioning/AwsCloud';
import Azure from '../components/Dashboards/Provisioning/Azure';
import PolicerSchedule from '../components/Dashboards/Provisioning/PolicerSchedule';

import Administration from '../components/Dashboards/Administration';
import Uptime from '../components/Dashboards/Uptime';
import Internet from '../components/Dashboards/Internet';
import Applications from '../components/Dashboards/Applications';
import Analytics from '../components/Dashboards/Analytics';
import Syslog from '../components/Dashboards/Syslog';

export const DashboardRoutes = [
  {
    element: <Applications />,
    path: '/dashboard/applications',
  },
  {
    element: <Internet />,
    path: '/dashboard/internet',
  },
  {
    element: <Administration />,
    path: '/dashboard/administration',
  },
  {
    element: <Provisioning />,
    path: '/dashboard/provisioning',
  },
  {
    element: <Analytics />,
    path: '/dashboard/analytics',
  },
  {
    element: <Uptime />,
    path: '/dashboard/uptime',
  },
  {
    element: <Syslog />,
    path: '/dashboard/syslogs',
  },
];

export const ProvisioningRoutes = [
  {
    element: <PolicerSchedule />,
    path: '/dashboard/provisioning/policer-schedule',
  },
  {
    element: <Zettanet />,
    path: '/dashboard/provisioning/zetta-internet',
  },
  {
    element: <PeeringExchange />,
    path: '/dashboard/provisioning/peering-exchange/*',
  },
  {
    element: <PartnerConnect />,
    path: '/dashboard/provisioning/partner-connect/*',
  },
  {
    element: <Zettagrid />,
    path: '/dashboard/provisioning/zetta-service',
  },
  {
    element: <Probax />,
    path: '/dashboard/provisioning/probax-service',
  },
  {
    element: <NetworkConnect />,
    path: '/dashboard/provisioning/network-connect',
  },
  {
    element: <XCNetworkConnect />,
    path: '/dashboard/provisioning/network-connect---xc',
  },
  {
    element: <VXCNetworkConnect />,
    path: '/dashboard/provisioning/network-connect---vxc',
  },
  {
    element: <XCNetworkConnect />,
    path: '/dashboard/provisioning/network-connect---vxc',
  },
  {
    element: <LocalNet />,
    path: '/dashboard/provisioning/local-internet',
  },
  {
    element: <ActiveportInternet />,
    path: '/dashboard/provisioning/activeport-internet',
  },
  {
    element: <Oracle />,
    path: '/dashboard/provisioning/oracle',
  },
  {
    element: <AlibabaCloud />,
    path: '/dashboard/provisioning/alibaba-cloud',
  },
  {
    element: <IBMCloud />,
    path: '/dashboard/provisioning/ibm',
  },
  {
    element: <Azure />,
    path: '/dashboard/provisioning/azure',
  },
  {
    element: <GoogleCloud />,
    path: '/dashboard/provisioning/google-cloud',
  },
  {
    element: <AWSCloud />,
    path: '/dashboard/provisioning/aws',
  },
];
