import React, { Fragment } from 'react';
import { useContext, useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { getUserDetails } from './api/accountapi';
import { Outlet, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

import AuthContext from '../../store/auth-context';
import SettingsContext from '../../store/settings-context';
import { Vega } from 'react-vega';
import { Handler } from 'vega-tooltip';
import moment from 'moment';

import Header from './Header';
import Footer from './Footer';
import MainNavigation from './MainNavigation';
import SettingsPanel from './SettingsPanel';

import AuthLayout from './AuthLayout';
import Messenger from './Messenger';
import FooterModal from './FooterModal';
import { getDeviceConfig, getServiceList } from './api/ntuapi';
import { getElasticSearchData } from '../Layout/api/elasticsearch';
import AppTrackGraph from '../../graphs/vega/applications/apptrack_check_request.json';
import NetFlowGraph from '../../graphs/vega/applications/netflow_check_request.json';

const Layout = (props) => {
  const navigate = useNavigate();
  const cookies = new Cookies();

  const authCtx = useContext(AuthContext);
  const settingsCtx = useContext(SettingsContext);

  const localThemeSettings = JSON.parse(localStorage.getItem('themeSettings'));

  const [ntu, setNtu] = useState('');
  const [updateNTU, setUpdateNTU] = useState(false);
  const [workingOrg, setWorkingOrg] = useState('');

  const [themeId, setThemeId] = useState('');
  const [settingsPanel, setSettingsPanel] = useState(false);
  const [mobileSidebarToggle, setMobileSideBarToggle] = useState(false);
  const mobileSidebarClose = useRef(null);
  const [ntuList, setNtuList] = useState([]);
  const [themeSettings, setThemeSettings] = useState({
    globalFontSize: 'root-text',
    themeSkin: 'skin-default',
  });

  let lte = '';
  let gte = '';
  let ignore_throttled = 1;

  if (lte == '') {
    lte = new Date();
    lte = moment();
  }
  if (gte == '') {
    gte = new Date();
    gte = moment().subtract(180, 'minutes');
  }

  const appTrackCheck = async (NTU) => {
    if (lte == '') {
      lte = new Date();
      lte = moment();
    }
    if (gte == '') {
      gte = new Date();
      gte = moment().subtract(180, 'minutes');
    }
    let getApptrack = false;

    var gte_n = gte._d.getTime();
    var lte_n = lte._d.getTime();
    var now = new Date();
    var gte_diff = moment(now).diff(moment(gte), 'months');
    if (gte_diff > 0) {
      ignore_throttled = 0; // searchable snapshot
    }

    var now = new Date();
    AppTrackGraph.query.bool.must[0].query_string['query'] =
      'serial-number: ' + NTU.serialNumber;
    AppTrackGraph.query.bool.must[2].range['@timestamp'].gte = gte._d.getTime();
    AppTrackGraph.query.bool.must[2].range['@timestamp'].lte = lte._d.getTime();
    try {
      let elasticData = await getElasticSearchData(
        'apptrack-*',
        ignore_throttled,
        JSON.stringify(AppTrackGraph),
        'search'
      ).then((res) => res.json());
      //  console.log(elasticData);

      if (elasticData.hits.total.value > 0 || elasticData.hits.total > 0) {
        getApptrack = true;
      } else {
        getApptrack = false;
      }
    } catch (error) {
      console.log(error);
    }

    return getApptrack;
  };

  const netflowCheck = async (NTU) => {
    var gte_n = gte._d.getTime();
    var lte_n = lte._d.getTime();
    let returnNetflow = false;
    var now = new Date();
    var gte_diff = moment(now).diff(moment(gte), 'months');
    console.log(gte_diff);
    if (gte_diff > 0) {
      ignore_throttled = 0; // searchable snapshot
    }

    var now = new Date();
    let serial = NTU.serialNumber;
    if (NTU.mode == 'VIRTUAL') {
      serial = NTU.peSerialNumber;
    }
    NetFlowGraph.query.bool.must[0].query_string['query'] =
      'serial-number: ' + serial;
    NetFlowGraph.query.bool.must[2].range['@timestamp'].gte = gte._d.getTime();
    NetFlowGraph.query.bool.must[2].range['@timestamp'].lte = lte._d.getTime();

    if (NTU.mode == 'VIRTUAL') {
      try {
        let elasticData = await getElasticSearchData(
          'elastiflow-3.5.1-*',
          ignore_throttled,
          JSON.stringify(NetFlowGraph),
          'searchNoTenant'
        ).then((res) => res.json());

        if (elasticData.hits.total.value > 0 || elasticData.hits.total > 0) {
          returnNetflow = true;
        } else {
          returnNetflow = false;
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      // index: 'elastiflow-3.5.1-*',
      //size: 0,
      try {
        let elasticData = await getElasticSearchData(
          'elastiflow-3.5.1-*',
          ignore_throttled,
          JSON.stringify(NetFlowGraph),
          'search'
        ).then((res) => res.json());

        if (elasticData.hits.total.value > 0 || elasticData.hits.total > 0) {
          returnNetflow = true;
        } else {
          returnNetflow = false;
        }
      } catch (error) {
        console.log(error);
      }
    }
    return returnNetflow;
  };

  const handleClickOutsideSideNav = (event) => {
    const localStTheme = JSON.parse(localStorage.getItem('themeSettings'));
    if (localStTheme != null) {
      if (localStTheme.mobileNavOn) {
        if (
          mobileSidebarClose.current &&
          !mobileSidebarClose.current.contains(event.target)
        ) {
          setMobileSideBarToggle(false);
          localStTheme.mobileNavOn
            ? removeFromTheme('mobileNavOn')
            : setTheme({
                mobileNavOn: 'mobile-nav-on',
              });
        }
      }
    }
  };

  // ******************
  // Get Device Config
  // ******************
  const deviceConfig = async (ntuID) => {
    try {
      const getNtuConfig = await getDeviceConfig(ntuID).then((res) =>
        res.json()
      );
      localStorage.setItem('ntu', JSON.stringify(getNtuConfig));
      settingsCtx.setNTU(getNtuConfig);
      return getNtuConfig;
    } catch (error) {
      console.log(error);
    }
  };
  // ******************
  // Get Service List
  // ******************

  const getServices = async (mode) => {
    let serviceList = await getServiceList(mode);
    settingsCtx.setServiceList(serviceList);
    return serviceList;
  };

  // ******************
  // On NTU Change
  // ******************
  const selectNTU = async (selectedNTU) => {
    //localStorage.setItem('ntu', JSON.stringify(selectedNTU));
    //get NTU Config

    let serviceList = await getServices(selectedNTU.mode);
    let ntuConfig = await deviceConfig(selectedNTU.id);
    let getnetflow = await netflowCheck(selectedNTU);
    let getapptrack = await appTrackCheck(selectedNTU);
    ntuConfig.has_netflow = getnetflow;
    ntuConfig.has_apptrack = getapptrack;

    localStorage.setItem('ntu', JSON.stringify(ntuConfig));
    localStorage.setItem('menuItem', 'Dashboards');
    settingsCtx.setNTU(ntuConfig);

    cookies.set('ntu', ntuConfig, { path: '/' });
    cookies.set('serviceList', serviceList, { path: '/' });
    localStorage.setItem('serviceList', JSON.stringify(serviceList));
    setNtu(ntuConfig);
    navigate('/dashboard/provisioning');
  };

  // ******************
  // On Org Change
  // ******************
  const selectWorkingOrg = (selectedWorkingOrg) => {
    localStorage.setItem('workingOrg', JSON.stringify(selectedWorkingOrg));
    settingsCtx.setWorkingOrg(selectedWorkingOrg);

    setWorkingOrg(selectedWorkingOrg);
    cookies.set('workingOrg', selectedWorkingOrg, { path: '/' });
    settingsCtx.setNTU('');
    setNtu('');
    setUpdateNTU(!updateNTU);
    navigate('/');
  };

  // ******************
  // Theme Change
  // ******************

  const setTheme = (settings) => {
    let newThemeSettings = themeSettings;
    setThemeId(settings.themeURL);
    newThemeSettings = { ...themeSettings, ...settings };

    setThemeSettings({ ...newThemeSettings });
    localStorage.setItem('themeSettings', JSON.stringify(newThemeSettings));

    updateTheme();
  };

  const removeFromTheme = (settingName) => {
    let newThemeSettings = JSON.parse(localStorage.getItem('themeSettings'));

    delete newThemeSettings[settingName];

    setThemeSettings({ ...newThemeSettings });
    localStorage.setItem('themeSettings', JSON.stringify(newThemeSettings));
    updateTheme();
  };

  const updateTheme = () => {
    const localStTheme = JSON.parse(localStorage.getItem('themeSettings'));

    document.body.classList.remove(
      'mod-skin-light',
      'mod-skin-dark',
      'mod-skin-default'
    );
    document.body.className = '';
    Object.values(localStTheme).map(function (value, key) {
      if (value != '') {
        document.body.classList.add(value);
      }
    });

    if (localStTheme.globalFontSize != null) {
      var htmlElement = document.getElementsByTagName('html')[0];

      htmlElement.className = '';
      htmlElement.classList.add(localStTheme.globalFontSize);
    }
  };

  // ******************
  // On Toggle Modal
  // ******************

  const toggleModal = (modalName) => {
    switch (modalName) {
      case 'settingsPanel':
        settingsPanel
          ? removeFromTheme('settingspanel')
          : setTheme({ settingspanel: 'modal-open' });
        setSettingsPanel(!settingsPanel);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideSideNav, true);
    if (localStorage.getItem('ntu')) {
      setNtu(JSON.parse(localStorage.getItem('ntu')));
    } else localStorage.setItem('ntu', '');
    if (localStorage.getItem('themeSettings')) {
      setThemeSettings(localThemeSettings);
      updateTheme();
    } else localStorage.setItem('themeSettings', JSON.stringify(themeSettings));
  }, []);

  useEffect(() => {
    if (localStorage.getItem('ntu') != '') {
      settingsCtx.setNTU(JSON.parse(localStorage.getItem('ntu')));
    }
    if (localStorage.getItem('serviceList')) {
      settingsCtx.setServiceList(
        JSON.parse(localStorage.getItem('serviceList'))
      );
    }
  }, []);

  if (
    authCtx.token === '' ||
    authCtx.token === 'undefined' ||
    authCtx.token == null
  ) {
    return (
      <Fragment>
        <Helmet>
          <link
            rel="stylesheet"
            type="text/css"
            id="customeTheme"
            href={
              themeSettings.themeURLthemeURL
                ? `/${themeSettings.themeURLthemeURL}`
                : ``
            }
          />
        </Helmet>
        <AuthLayout>
          <main>{props.children}</main>
        </AuthLayout>
      </Fragment>
    );
  }
  return (
    <Fragment>
      {themeSettings.themeURL && (
        <Helmet>
          <link
            rel="stylesheet"
            type="text/css"
            id="customeTheme2"
            href={`/${themeSettings.themeURL}`}
          />
        </Helmet>
      )}

      <div className="mod-nav-link">
        <div className="page-wrapper">
          <div className="page-inner">
            <div ref={mobileSidebarClose}>
              <MainNavigation
                setTheme={setTheme}
                removeFromTheme={removeFromTheme}
                ntu={ntu}
                updateNTU={updateNTU}
              />
            </div>
            <div className="page-content-wrapper">
              <Header
                toggleModal={toggleModal}
                setTheme={setTheme}
                removeFromTheme={removeFromTheme}
                themeSettings={themeSettings}
                ntuList={ntuList}
                selectNTU={selectNTU}
                selectWorkingOrg={selectWorkingOrg}
                ntu={ntu}
              />
              <main id="js-page-content" role="main" className="page-content">
                <Outlet />
              </main>

              <Messenger />
              {settingsPanel && (
                <SettingsPanel
                  setTheme={setTheme}
                  removeFromTheme={removeFromTheme}
                  openModal={true}
                  toggleModal={toggleModal}
                />
              )}
              <Footer />
              <FooterModal />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Layout;
