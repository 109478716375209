export const getUsers = () => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/users';
  return fetch(baseUrl, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getUser = (login) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/users/';

  const requestUrl = `${baseUrl}${login}`;
  return fetch(requestUrl, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getUserProfiles = () => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/user-profiles';

  return fetch(baseUrl, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const createUser = (data) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/users';

  return fetch(baseUrl, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const updateUser = (data) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/users';

  return fetch(baseUrl, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};
