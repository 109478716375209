export const getElasticSearchData = async (
  indexName,
  ignore_throttled,
  elasticQuery,
  queryType
) => {
  const token = localStorage.getItem('token');
  let baseUrl = 'https://test.activeport.com.au/api/';
  switch (queryType) {
    case 'getRouteEngine':

    case 'getInterfaces':
    case 'getFormQuery':
    case 'getGeneric':
    case 'getGenericIgnoreLoading':
    case 'getGenericArray':
    case 'search':
      baseUrl = baseUrl + 'elastic/my-query';
      break;
    case 'post':
    case 'searchNoTenant':
      baseUrl = baseUrl + 'elastic/my-query3';
      break;
    case 'delete':
    case 'getOrgBased':
  }

  return await fetch(baseUrl, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
    method: 'POST',
    index: indexName,
    ignoreThrottled: ignore_throttled,
    body: JSON.stringify({
      index: indexName,
      ignore_throttled: ignore_throttled,
      body: elasticQuery,
    }),
  });
};
