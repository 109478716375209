import React, { useEffect, useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import Panel from '../../../common/Panel/Panel';
import { getServiceCommand } from './api/ServiceCommandsApi';

const ServiceCommandDetail = () => {
  const match = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [serviceCommandEntity, setServiceCommandEntity] = useState(null);
  const [appState, setAppState] = useState({
    loading: false,
  });

  useEffect(() => {
    setAppState({ loading: true });
    const fetchData = async () => {
      try {
        const result = await getServiceCommand(match.id).then((res) =>
          res.json()
        );
        setServiceCommandEntity(result);
        console.log('ServiceCommand Detail: ', result);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    setAppState({ loading: false });
  }, [location]);

  return (
    <div>
      <div className="row">
        <div className="col-xl-12">
          {appState.loading && <p>Loading...</p>}
          {serviceCommandEntity !== null && !appState.loading && (
            <Panel>
              <dl className="row">
                <dt className="text-inverse  col-4 ">
                  <span id="command">
                    <span>Command</span>
                  </span>
                </dt>
                <dd className="col-8">
                  <textarea
                    className="form-control"
                    readOnly
                    disabled
                    rows={10}
                  >
                    {serviceCommandEntity.command}
                  </textarea>
                </dd>
                <dt className="text-inverse  col-4 ">
                  <span id="command">
                    <span>On Event</span>
                  </span>
                </dt>
                <dd className="col-8 ">{serviceCommandEntity.onEvent} </dd>
                <dt className="text-inverse  col-4 ">
                  <span id="onEvent">
                    <span>Entry Type</span>
                  </span>
                </dt>
                <dd className="col-8 ">{serviceCommandEntity.entryType}</dd>
                <dt className="text-inverse  col-4 ">
                  <span id="command">
                    <span>Device Type</span>
                  </span>
                </dt>
                <dd className="col-8 ">{serviceCommandEntity.deviceType}</dd>
                <dt className="text-inverse  col-4 ">
                  <span id="command">
                    <span>OS Type</span>
                  </span>
                </dt>
                <dd className="col-8 ">{serviceCommandEntity.osType}</dd>
                <dt className="text-inverse  col-4 ">
                  <span id="command">
                    <span>Circuit Type</span>
                  </span>
                </dt>
                <dd className="col-8 ">
                  {serviceCommandEntity.circuitTypes.map((circuitType, i) => {
                    return (
                      <div
                        key={i}
                        class="btn bg-faded cursor-e-resize waves-effect waves-themed"
                      >
                        {circuitType}
                      </div>
                    );
                  })}
                </dd>
              </dl>

              <div>
                <button
                  className="btn btn-sm btn-secondary mr-3"
                  onClick={() => navigate('../')}
                >
                  <i className="fal fa-arrow-left fa-sm  mr-2"></i>
                  Back
                </button>
                <button
                  className="btn btn-sm btn-primary m-r-5"
                  onClick={() => navigate('../edit/' + match.id)}
                >
                  <i className="fal fa-pencil-alt fa-sm  mr-2"></i>
                  Edit
                </button>
              </div>
            </Panel>
          )}
        </div>
      </div>
    </div>
  );
};

export default ServiceCommandDetail;
