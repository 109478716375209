export const searchNtu = async (uuid) => {
  const token = localStorage.getItem("token");
  return fetch(
    `https://test.activeport.com.au/api/ntus/user-uuid/${uuid}?cacheBuster=1651833213795&profile=`,
    {
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    }
  );
};
