import React, { useMemo, useState, useEffect } from 'react';
import Table from '../../../common/Table';

import { handleDeleteAlert } from '../../../common/Alerts/DeleteAlert';
const TopNATClientsTable = ({ allClients, heading }) => {
  const data = allClients;
  console.log('all devices:', allClients);

  const columns = useMemo(
    () => [
      {
        Header: 'Client',
        accessor: (d) => {
          return d.key;
        },
        sortable: true,
      },
      {
        Header: 'Bytes',
        accessor: (d) => {
          return d.bytes.value;
        },
        sortable: true,
      },
      {
        Header: 'Packets',
        accessor: (d) => {
          return d.packets.value;
        },
        sortable: true,
      },
      {
        Header: 'Flow Records',
        accessor: (d) => {
          return d.doc_count;
        },
        sortable: true,
      },
    ],
    []
  );
  useEffect(() => {}, []);

  return <Table data={data} columns={columns} heading="Top Clients" />;
};

export default TopNATClientsTable;
