import React, { useMemo } from "react";
import { Link } from "react-router-dom";

import Panel from "../../../common/Panel/Panel";
import Table from "../../../common/Table";

import { handleDeleteAlert } from "../../../common/Alerts/DeleteAlert";
const GpuServers = ({ entities }) => {
  const data = entities;

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        sortable: true,
        Cell: ({ row }) => {
          return (
            <div>
              <Link to={`${row.original.id}`}>{row.original.id}</Link>
            </div>
          );
        },
      },
      {
        Header: "Name",
        accessor: "displayName",
        sortable: true,
      },
      {
        Header: "Hostname/ID",
        accessor: "name",
        sortable: true,
      },
      {
        Header: "IP Address",
        accessor: "ipAddress",
        sortable: true,
      },
      {
        Header: "UID",
        accessor: "uid",
        sortable: true,
      },
      {
        Header: "Type",
        accessor: "type",
        sortable: true,
      },
      {
        Header: "VMs Public IP",
        accessor: "publicIp",
        sortable: true,
      },
      {
        Header: "Max VCPU",
        accessor: "maxCpus",
        sortable: true,
      },
      {
        Header: "Max Memory",
        accessor: "maxMemory",
        sortable: true,
      },
      {
        Header: "Iface Out",
        accessor: "ifaceOut",
        sortable: true,
      },
      {
        Header: "Region",
        accessor: "regionName",
        sortable: true,
      },
      {
        Header: "",
        accessor: "actionColumns",
        className: "align-middle",
        Cell: ({ row }) => {
          return (
            <div className="text-center d-flex">
              <Link
                className="btn btn-icon btn-circle btn-default mr-2"
                to={`${row.original.id}`}
              >
                <i className="fal fa-eye"></i>
              </Link>
              <Link
                className="btn btn-icon btn-circle btn-success mr-2"
                to={`../edit/${row.original.id}`}
              >
                <i className="fal fa-pencil-alt"></i>
              </Link>
              <button
                className="btn btn-icon btn-circle btn-danger"
                onClick={handleDeleteAlert}
              >
                <i className="fal fa-trash"></i>
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <Panel>
      <Table data={data} columns={columns} />
    </Panel>
  );
};

export default GpuServers;
