import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import Panel from '../../../common/Panel/Panel';
import Table from '../../../common/Table';

import { handleDeleteAlert } from '../../../common/Alerts/DeleteAlert';
const ProviderCredentials = ({ entities }) => {
  const data = entities;

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'row_id',
        sortable: true,
        Cell: ({ row }) => {
          return (
            <div>
              <Link to={`/${row.original.tenantId}`}>{row.original.id}</Link>
            </div>
          );
        },
      },
      {
        Header: 'Name',
        accessor: 'name',
        sortable: true,
      },
      {
        Header: 'Description',
        accessor: 'description',
        sortable: true,
      },
      {
        Header: 'API',
        accessor: 'api',
        sortable: true,
      },
      {
        Header: 'Stage',
        accessor: 'stage',
        sortable: true,
      },
      {
        Header: 'Username',
        accessor: 'username',
        sortable: true,
      },
      {
        Header: 'Billing Uid',
        accessor: 'billingUid',
        sortable: true,
      },

      {
        Header: 'Company Id',
        accessor: 'productUid',
        sortable: true,
      },

      {
        Header: 'Endpoint',
        accessor: 'endpoint',
        sortable: true,
      },

      {
        Header: '',
        accessor: 'actionColumns',
        className: 'align-middle',
        Cell: ({ row }) => {
          return (
            <div className="text-center d-flex">
              <Link
                className="btn btn-icon btn-circle btn-default mr-2"
                to={`/settings/locations/${row.original.id}`}
              >
                <i className="fal fa-eye"></i>
              </Link>
              <Link
                className="btn btn-icon btn-circle btn-success mr-2"
                to={`/settings/locations/${row.original.id}/edit`}
              >
                <i className="fal fa-pencil-alt"></i>
              </Link>
              <button
                className="btn btn-icon btn-circle btn-danger"
                onClick={handleDeleteAlert}
              >
                <i className="fal fa-trash"></i>
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <Panel>
      <Table data={data} columns={columns} />
    </Panel>
  );
};

export default ProviderCredentials;
