import React, { useEffect, useState } from 'react';
import Panel from '../../../common/Panel/Panel';
import DrawGraph from './DrawGraph';

import autonomous_systems_source_bits_bar from '../../../common/graphs/vega/autonomous_systems_source_bits_bar.json';
import autonomous_systems_dest_bits_bar from '../../../common/graphs/vega/autonomous_systems_dest_bits_bar.json';
import autonomous_systems_source_packets_bar from '../../../common/graphs/vega/autonomous_systems_source_packets_bar.json';
import autonomous_systems_dest_packets_bar from '../../../common/graphs/vega/autonomous_systems_dest_packets_bar.json';

import autonomous_systems_source_bits_bar_request from '../../../common/graphs/vega/autonomous_systems_source_bits_bar_request.json';
import autonomous_systems_dest_bits_bar_request from '../../../common/graphs/vega/autonomous_systems_dest_bits_bar_request.json';
import autonomous_systems_source_packets_bar_request from '../../../common/graphs/vega/autonomous_systems_source_packets_bar_request.json';
import autonomous_systems_dest_packets_bar_request from '../../../common/graphs/vega/autonomous_systems_dest_packets_bar_request.json';

const ASTraffic = ({ lte, gte, ntu }) => {
  return (
    <div className="row">
      <div className="col-lg-6  col-md-6 col-sm-12 col-xs-12">
        <Panel heading="Source Autonomous Systems (bits/s)">
          <div>
            <DrawGraph
              lte={lte}
              gte={gte}
              ntu={ntu}
              esRequest={autonomous_systems_source_bits_bar_request}
              graphDiv={'#source_autonomous_bits'}
              vegaGraph={autonomous_systems_source_bits_bar}
            />
          </div>
        </Panel>
      </div>
      <div className="col-lg-6  col-md-6 col-sm-12 col-xs-12">
        <Panel heading="Destination Autonomous Systems (bits/s)">
          <div>
            <DrawGraph
              lte={lte}
              gte={gte}
              ntu={ntu}
              esRequest={autonomous_systems_dest_bits_bar_request}
              graphDiv={'#destination_autonomous_bits'}
              vegaGraph={autonomous_systems_dest_bits_bar}
            />
          </div>
        </Panel>
      </div>
      <div className="col-lg-6  col-md-6 col-sm-12 col-xs-12">
        <Panel heading="Source Autonomous Systems (packets/s)">
          <div>
            <DrawGraph
              lte={lte}
              gte={gte}
              ntu={ntu}
              esRequest={autonomous_systems_source_packets_bar_request}
              graphDiv={'#source_autonomous_packcets'}
              vegaGraph={autonomous_systems_source_packets_bar}
            />
          </div>
        </Panel>
      </div>
      <div className="col-lg-6  col-md-6 col-sm-12 col-xs-12">
        <Panel heading="Destination Autonomous Systems (packets/s)">
          <div>
            <DrawGraph
              lte={lte}
              gte={gte}
              ntu={ntu}
              esRequest={autonomous_systems_dest_packets_bar_request}
              graphDiv={'#destination_autonomous_packets'}
              vegaGraph={autonomous_systems_dest_packets_bar}
            />
          </div>
        </Panel>
      </div>
    </div>
  );
};

export default ASTraffic;
