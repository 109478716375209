import React, { useMemo, useState, useEffect } from 'react';
import Panel from '../../../common/Panel/Panel';
import Table from '../../../common/Table';

import { handleDeleteAlert } from '../../../common/Alerts/DeleteAlert';
const MonitorStatusDevices = ({ allDevices, hostnames, heading }) => {
  const data = allDevices;
  console.log('all devices:', allDevices);
  console.log('Hostnames: ', hostnames);
  const columns = useMemo(
    () => [
      {
        Header: 'Status',
        accessor: (d) => {
          return (
            <>
              <p
                className={`text-center p-2 bg-${
                  d.latest.hits.hits[0]._source.monitor.status == 'up'
                    ? 'success'
                    : 'danger'
                } text-white  rounded`}
              >
                {d.latest.hits.hits[0]._source.monitor.status}
              </p>
            </>
          );
        },
        sortable: true,
      },
      {
        Header: 'Device',
        accessor: (d) => {
          return <>{d.key}</>;
        },
        sortable: true,
      },
      {
        Header: 'Hostname',
        accessor: (d) => {
          return d.hostname;
        },
        sortable: true,
      },
      {
        Header: 'Error',
        accessor: (d) => {
          if (d.latest.hits.hits[0]._source.error != null)
            return d.latest.hits.hits[0]._source.error.message;
          else return '';
        },
        sortable: true,
      },
    ],
    []
  );
  useEffect(() => {}, [hostnames]);

  return (
    <Panel heading={heading}>
      <Table data={data} columns={columns} heading="Monitor Status Devices" />
    </Panel>
  );
};

export default MonitorStatusDevices;
