import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import Panel from "../../../common/Panel/Panel";
import Table from "../../../common/Table";
import { handleDeleteAlert } from "../../../common/Alerts/DeleteAlert";

const Tenants = ({ entities }) => {
  const data = entities;

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "row_id",
        sortable: true,
        Cell: ({ row }) => {
          return (
            <div>
              <Link to={`${row.original.id}`}>{row.original.id}</Link>
            </div>
          );
        },
      },
      {
        Header: "Organization",
        accessor: "orgId",
        sortable: true,
      },
      {
        Header: "Name",
        accessor: "name",
        sortable: true,
      },
      {
        Header: "Location",
        accessor: "locationId",
        sortable: true,
      },
      {
        Header: "Device ID",
        accessor: "aggrId",
        sortable: true,
      },
      {
        Header: "Deployment",
        accessor: "type",
        sortable: true,
      },
      {
        Header: "Type",
        accessor: "serviceType",
        sortable: true,
      },
      {
        Header: "Rank",
        accessor: "priority",
        sortable: true,
      },
      {
        Header: "State",
        accessor: "state",
        sortable: true,
      },
      {
        Header: "Management IP",
        accessor: "managementIp",
        sortable: true,
      },
      {
        Header: "Space Name",
        accessor: "spaceName",
        sortable: true,
      },

      {
        Header: "PE",
        accessor: "peName",
        sortable: true,
      },
      {
        Header: "PE Port",
        accessor: "pePort",
        sortable: true,
      },

      {
        Header: "Interfaces",
        accessor: (d) =>
          d.ifaces?.map((iface) => (
            <button className="btn btn-light mr-1 mb-1" key={iface.id}>
              {iface.ifname}
            </button>
          )),
        sortable: true,
      },
      {
        Header: "",
        accessor: "actionColumns",
        className: "align-middle",
        Cell: ({ row }) => {
          return (
            <div className="text-center d-flex">
              <Link
                className="btn btn-icon btn-circle btn-default mr-2"
                to={`${row.original.tenantId}`}
              >
                <i className="fal fa-eye"></i>
              </Link>
              <Link
                className="btn btn-icon btn-circle btn-success mr-2"
                to={`../edit/${row.original.tenantId}`}
              >
                <i className="fal fa-pencil-alt"></i>
              </Link>
              <button
                className="btn btn-icon btn-circle btn-danger"
                onClick={handleDeleteAlert}
              >
                <i className="fal fa-trash"></i>
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <Panel>
      <Table data={data} columns={columns} />
    </Panel>
  );
};

export default Tenants;
