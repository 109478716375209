import React, { useEffect, useState } from 'react';
import Panel from '../../../common/Panel/Panel';
import DrawGraph from './DrawGraph';

import sankey_bytes_pie from '../../../common/graphs/vega/sankey_bytes_pie.json';
import autonomous_systems_flow_services_pie from '../../../common/graphs/vega/autonomous_systems_flow_services_pie.json';
import autonomous_systems_flow_dest_pie from '../../../common/graphs/vega/autonomous_systems_flow_dest_pie.json';
import autonomous_systems_flow_pie from '../../../common/graphs/vega/autonomous_systems_flow_pie.json';
import sankey_bytes_pie_request from '../../../common/graphs/vega/sankey_bytes_pie_request.json';
import autonomous_systems_flow_pie_request from '../../../common/graphs/vega/autonomous_systems_flow_pie_request.json';
import autonomous_systems_flow_dest_pie_request from '../../../common/graphs/vega/autonomous_systems_flow_dest_pie_request.json';
import autonomous_systems_flow_services_pie_request from '../../../common/graphs/vega/autonomous_systems_flow_services_pie_request.json';

const Sankey = ({ gte, lte, ntu }) => {
  return (
    <div>
      <div className="row">
        <div className="col-md-4 col-sm-12 col-xs-12">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <Panel heading="Clients (flow records)">
                <div>
                  <DrawGraph
                    lte={lte}
                    gte={gte}
                    ntu={ntu}
                    esRequest={autonomous_systems_flow_pie_request}
                    graphDiv={'#autonomous_systems_flow'}
                    vegaGraph={autonomous_systems_flow_pie}
                  />
                </div>
              </Panel>
            </div>
            <div className="col-md-12 col-sm-12 col-xs-12">
              <Panel heading="Servers (flow records)">
                <div>
                  <DrawGraph
                    lte={lte}
                    gte={gte}
                    ntu={ntu}
                    esRequest={autonomous_systems_flow_dest_pie_request}
                    graphDiv={'#autonomous_systems_dest_flow'}
                    vegaGraph={autonomous_systems_flow_dest_pie}
                  />
                </div>
              </Panel>
            </div>

            <div className="col-md-12 col-sm-12 col-xs-12">
              <Panel heading="Services (flow records)">
                <div>
                  <DrawGraph
                    lte={lte}
                    gte={gte}
                    ntu={ntu}
                    esRequest={autonomous_systems_flow_services_pie_request}
                    graphDiv={'#autonomous_systems_services_flow'}
                    vegaGraph={autonomous_systems_flow_services_pie}
                  />
                </div>
              </Panel>
            </div>
          </div>
        </div>
        <div className="col-md-8 col-sm-12 col-xs-12">
          <Panel heading="Sankey (bytes)">
            <div>
              <DrawGraph
                lte={lte}
                gte={gte}
                ntu={ntu}
                esRequest={sankey_bytes_pie_request}
                graphDiv={'#sankey_bytes'}
                vegaGraph={sankey_bytes_pie}
              />
            </div>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default Sankey;
