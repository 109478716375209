import React, { useMemo, useState, useEffect } from 'react';
import Table from '../../../common/Table';

import { handleDeleteAlert } from '../../../common/Alerts/DeleteAlert';
const FirewallTable = ({ allData, heading }) => {
  const data = allData;
  console.log('all devices:', allData);

  const levelOneColumns = [
    {
      Header: 'Zone',
      sortable: true,
      Cell: ({ row }) => (
        // Use Cell to render an expander for each row.
        // We can use the getToggleRowExpandedProps prop-getter
        // to build the expander.
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? '-' : '+'} {row.original.key}
        </span>
      ),
    },
    {
      Header: 'Bytes',
      accessor: 'bytesclient',
    },
    {
      Header: 'Packets',
      accessor: 'packetsclient',
    },
    {
      Header: 'Zone Records',
      accessor: 'doc_count',
      sortable: true,
    },
  ];

  const levelTwoColumns = [
    {
      Header: 'Application',
      sortable: true,
      Cell: ({ row }) => (
        // Use Cell to render an expander for each row.
        // We can use the getToggleRowExpandedProps prop-getter
        // to build the expander.
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? '-' : '+'} {row.original.key}
        </span>
      ),
    },
    {
      Header: 'Records',
      accessor: 'doc_count',
      sortable: true,
    },
  ];

  const levelThreeColumns = [
    {
      Header: 'Source',
      accessor: 'key',
    },
    {
      Header: 'Dest',
      accessor: '',
    },
    {
      Header: 'Bytes',
      accessor: 'bytesserver.value',
    },
    {
      Header: 'Packets',
      accessor: 'packetsserver.value',
    },
    {
      Header: 'Service',
      accessor: '',
    },
  ];

  useEffect(() => {}, []);

  const renderLevelTwoRowSubComponent = React.useCallback(
    ({ row }) => (
      <Table
        columns={levelTwoColumns}
        data={row.original.application.buckets}
        // We added this as a prop for our table component
        // Remember, this is not part of the React Table API,
        // it's merely a rendering option we created for
        // ourselves
        renderRowSubComponent={renderLevelThreeRowSubComponent}
        showPagination={false}
      />
    ),
    []
  );

  const renderLevelThreeRowSubComponent = React.useCallback(
    ({ row }) => (
      <Table
        columns={levelThreeColumns}
        data={row.original.source.buckets}
        // We added this as a prop for our table component
        // Remember, this is not part of the React Table API,
        // it's merely a rendering option we created for
        // ourselves
        renderRowSubComponent={renderLevelThreeRowSubComponent}
        showPagination={false}
      />
    ),
    []
  );

  return (
    <Table
      data={data}
      columns={levelOneColumns}
      heading="Top Applications by Zone"
      renderRowSubComponent={renderLevelTwoRowSubComponent}
      showPagination={true}
    />
  );
};

export default FirewallTable;
