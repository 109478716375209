import React from "react";
import { Formik, Field, Form } from "formik";

import HelpText from "../../../../common/Form/HelpText/HelpText";

export const ServiceSettingsFrom = ({ partnerConnect }) => {
  return (
    <>
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <p className="nav-link active">Service Information</p>
        </li>
      </ul>

      <div className="border border-top-0 px-3 py-3">
        <Formik
          enableReinitialize
          initialValues={partnerConnect}
          // validationSchema={schema}
          validateOnMount
          // onSubmit={submitForm}
        >
          {() => {
            return (
              <Form>
                <div className="form-group row m-b-15">
                  <label className="col-form-label col-md-3">
                    Service Name
                  </label>
                  <div className="col-md-9">
                    <Field
                      type="text"
                      name="name"
                      className={`form-control m-b-5`}
                      placeholder=""
                      readOnly
                      disabled
                    />
                  </div>
                </div>
                <div className="form-group row m-b-15">
                  <label className="col-form-label col-md-3">Space Type</label>
                  <div className="col-md-9">
                    <Field
                      as="select"
                      name="circuitType"
                      className={`form-control m-b-5`}
                      readOnly
                      disabled
                    >
                      {[
                        { label: "VLAN", value: "VLAN" },
                        { label: "Circuit", value: "CIRCUIT" },
                        { label: "Route", value: "ROUTING_ROUTE" },
                        { label: "NAT", value: "ROUTING_NAT" },
                      ].map((type, i) => (
                        <option key={i} value={type.value}>
                          {type.label}
                        </option>
                      ))}
                    </Field>
                  </div>
                </div>
                <div className="form-group row m-b-15">
                  <label className="col-form-label col-md-3">Provider</label>
                  <div className="col-md-9">
                    <Field
                      type="text"
                      name="partnerName"
                      className={`form-control m-b-5`}
                      placeholder=""
                      readOnly
                      disabled
                    />
                  </div>
                </div>
                <div className="form-group row m-b-15">
                  <label className="col-form-label col-md-3">
                    Bandwidth (Mbps)
                  </label>
                  <div className="col-md-9">
                    <Field
                      type="number"
                      name="rateLimit"
                      className={`form-control m-b-5`}
                      placeholder=""
                      readOnly
                      disabled
                    />
                    <HelpText text="Committed Rate Limit Mbit/s" />
                  </div>
                </div>
                <div className="form-group row m-b-15">
                  <label className="col-form-label col-md-3">VLAN</label>
                  <div className="col-md-9">
                    <Field
                      type="text"
                      name="vlanIdA"
                      className={`form-control m-b-5`}
                      placeholder=""
                      readOnly
                    />
                    <HelpText text="Assigned VLAN" />
                  </div>
                </div>
                <div className="form-group row m-b-15">
                  <label className="col-form-label col-md-3">Price</label>
                  <div className="col-md-9">
                    <Field
                      type="text"
                      name="priceDisplay"
                      className={`form-control m-b-5`}
                      readOnly
                      placeholder=""
                    />
                    <HelpText text="Service Price" />
                  </div>
                </div>
                <div className="form-group row m-b-15">
                  <label className="col-form-label col-md-3">Price</label>
                  <div className="col-md-9">
                    <Field
                      type="text"
                      name="priceDisplay"
                      className={`form-control m-b-5`}
                      readOnly
                      placeholder=""
                    />
                    <HelpText text="Service Price" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-10" />
                  <div className="col-2 pr-2">
                    <button
                      type="submit"
                      className="btn btn-primary btn-block btn-lg m-b-5"
                      disabled={true}
                    >
                      Edit
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};
