export const getServiceTypes = () => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/service-types';
  return fetch(baseUrl, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getServiceType = (id) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/service-types';
  const requestUrl = `${baseUrl}/${id}`;
  return fetch(requestUrl, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const createServiceType = (serviceType) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/service-types';
  return fetch(baseUrl, {
    method: 'POST',
    body: JSON.stringify(serviceType),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const updateServiceType = (serviceType) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/service-types';
  return fetch(baseUrl, {
    method: 'PUT',
    body: JSON.stringify(serviceType),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const deleteServiceType = (id) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/service-types';
  const requestUrl = `${baseUrl}/${id}`;
  return fetch(requestUrl, {
    method: 'DELETE',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};
