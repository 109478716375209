import React, { useState, useEffect } from "react";
import { Field } from "formik";

import { getNtuPorts, checkConnectionStatus, getNTU } from "../../api/NTUApi";
import { LabelButtonTable } from "./LabelButtonTable";

export const Services = ({ id }) => {
  const [labelButtonTableData, setLabelButtonTableData] = useState([
    {
      title: "Syslog Hosts",
      tableHeads: ["Host", "Facility", "Severity", "Enabled", ""],
      tableData: [],
      initialValues: { host: "", facility: "", severity: "", enabled: false },
      fieldsData: [
        { name: "host", label: "Host" },
        { name: "facility", label: "Syslog Facility" },
        { name: "severity", label: "Syslog Severity" },
        { name: "enabled", label: "Enabled", type: "checkbox" },
      ],
    },
    {
      title: "Netflow Hosts",
      tableHeads: ["Host", "Enabled", "Port", "Interface", "Version", ""],
      tableData: [],
      initialValues: {
        host: "",
        enabled: false,
        port: "",
        interfaceName: "",
        version: "",
      },
      fieldsData: [
        { name: "host", label: "Host" },
        { name: "enabled", label: "Enabled", type: "checkbox" },
        { name: "port", label: "Port", type: "number" },
        {
          name: "interfaceName",
          label: "Interface",
          as: "select",
          options: [],
        },
        {
          name: "version",
          label: "Netflow Version",
          as: "select",
          options: [
            {
              label: "5",
              value: "5",
            },
            {
              label: "9",
              value: "9",
            },
          ],
        },
      ],
    },
    {
      title: "Apptrack",
      tableHeads: ["Enabled", "Security Zone", ""],
      tableData: [],
      initialValues: { enabled: false, securityZone: "" },
      fieldsData: [
        { name: "enabled", label: "Enabled", type: "checkbox" },
        {
          name: "securityZone",
          label: "Security Zone",
          as: "Select",
          options: [
            { label: "", value: "" },
            { label: "INTERNET", value: "INTERNET" },
          ],
        },
      ],
    },
  ]);

  const addNewRowHandler = (title, newRowData, index) => {
    const updatedTableDataObj = labelButtonTableData.find(
      (obj) => obj.title === title
    );
    if (index >= 0) {
      const { id, ...rest } = newRowData;
      updatedTableDataObj.tableData.splice(index, 1, rest);
    } else {
      updatedTableDataObj.tableData.push(newRowData);
    }
    const updatedTableData = labelButtonTableData.map((obj) => {
      if (obj.title === title) {
        return updatedTableDataObj;
      }
      return obj;
    });
    setLabelButtonTableData(updatedTableData);
  };

  const deleteRowHandler = (title, index) => {
    const updatedTableDataObj = labelButtonTableData.find(
      (obj) => obj.title === title
    );
    updatedTableDataObj.tableData.splice(index, 1);
    const updatedTableData = labelButtonTableData.map((obj) => {
      if (obj.title === title) {
        return updatedTableDataObj;
      }
      return obj;
    });
    setLabelButtonTableData(updatedTableData);
  };

  const onTestConnectionBtnClick = async () => {
    try {
      const ntu = await getNTU(id).then((res) => res.json());
      if (ntu) {
        const status = await checkConnectionStatus(ntu).then((res) =>
          res.json()
        );
        alert(`Connection ${status.responseString}`);
      }
    } catch (error) {
      console.log("error in checking connection = ", error);
    }
  };

  useEffect(() => {
    const fetcher = async () => {
      try {
        const res = await getNtuPorts().then((res) => res.json());
        const updatedTableDataObj = labelButtonTableData[1];
        updatedTableDataObj.fieldsData[3].options = res.map((port) => ({
          label: port.name,
          value: port.name,
        }));
        const updatedTableData = labelButtonTableData.map((obj, i) => {
          if (i === 1) {
            return updatedTableDataObj;
          }
          return obj;
        });
        setLabelButtonTableData(updatedTableData);
      } catch (error) {
        console.log(error);
      }
    };

    fetcher();
  }, []);

  return (
    <>
      <div className="form-group row m-b-15">
        <label className="col-form-label col-md-3">Rest Endpoint</label>
        <div className="col-md-9">
          <Field name="endpoint" className="form-control m-b-5" />
        </div>
      </div>
      <div className="form-group row m-b-15">
        <label className="col-form-label col-md-3">Rest Username</label>
        <div className="col-md-9">
          <Field name="restUsername" className="form-control m-b-5" />
        </div>
      </div>
      <div className="form-group row m-b-15">
        <label className="col-form-label col-md-3">Rest Password</label>
        <div className="col-md-9">
          <Field
            name="restPassword"
            type="password"
            className="form-control m-b-5"
          />
        </div>
      </div>
      <div className="d-flex align-items-center">
        <p className="font-weight-bold">Check NTU Connection</p>
        <button
          className="btn btn-primary ml-2 mb-3"
          onClick={onTestConnectionBtnClick}
        >
          Test
        </button>
      </div>
      {labelButtonTableData.map((item, i) => (
        <LabelButtonTable
          key={i}
          {...item}
          addNewRowHandler={addNewRowHandler}
          deleteRowHandler={deleteRowHandler}
        />
      ))}
    </>
  );
};
