export const getEmailTemplates = () => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/email-template';
  return fetch(baseUrl, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getEmailTemplate = (id) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/email-template';
  const requestUrl = `${baseUrl}/${id}`;
  return fetch(requestUrl, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getEmailSetups = () => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/email-template';
  return fetch('https://test.activeport.com.au/api/email-setup', {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const createEmailTemplate = (emailTemplate) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/email-template';
  return fetch(baseUrl, {
    method: 'POST',
    body: JSON.stringify(emailTemplate),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const updateEmailTemplate = (emailTemplate) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/email-template';
  return fetch(baseUrl, {
    method: 'PUT',
    body: JSON.stringify(emailTemplate),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const deleteEmailTemplate = (id) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/email-template';
  const requestUrl = `${baseUrl}/${id}`;
  return fetch(requestUrl, {
    method: 'DELETE',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};
