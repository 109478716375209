export let defaultValues = {
  attributes: {
    brand: {
      entityType: null,
      value: '',
      modifiedBy: null,
      description: null,
      fieldType: 'BLOB',
      fieldMinLength: null,
      fieldMaxLength: null,
      label: 'Brand JSON',
      visible: true,
      required: null,
      regex: null,
    },
    chat_html: {
      entityType: null,
      value: '',
      modifiedBy: null,
      description: 'chat script without the <script> tags',
      fieldType: 'STRING',
      fieldMinLength: null,
      fieldMaxLength: null,
      label: 'chat_html',
      visible: true,
      required: null,
      regex: null,
    },
    favicon: {
      entityType: null,
      value: '',
      modifiedBy: null,
      description: null,
      fieldType: 'MEDIA',
      fieldMinLength: null,
      fieldMaxLength: null,
      label: 'Favicon filename',
      visible: true,
      required: null,
      regex: null,
    },
    logo: {
      entityType: null,
      value: '',
      modifiedBy: null,
      description: null,
      fieldType: 'MEDIA',
      fieldMinLength: null,
      fieldMaxLength: null,
      label: 'Logo Override',
      visible: true,
      required: null,
      regex: null,
    },
    portal_tagline: {
      entityType: null,
      value: '',
      modifiedBy: null,
      description: null,
      fieldType: 'STRING',
      fieldMinLength: null,
      fieldMaxLength: null,
      label: 'Portal Tagline',
      visible: true,
      required: null,
      regex: null,
    },
    portal_title: {
      entityType: null,
      value: '',
      modifiedBy: null,
      description: null,
      fieldType: 'STRING',
      fieldMinLength: null,
      fieldMaxLength: null,
      label: 'Portal Title',
      visible: true,
      required: null,
      regex: null,
    },
    css_file: {
      entityType: null,
      value: '',
      modifiedBy: null,
      description: null,
      fieldType: 'MEDIA',
      fieldMinLength: null,
      fieldMaxLength: null,
      label: 'CSS Override file name',
      visible: true,
      required: null,
      regex: null,
    },
    server_name: {
      entityType: null,
      value: '',
      modifiedBy: null,
      description: null,
      fieldType: 'STRING',
      fieldMinLength: null,
      fieldMaxLength: null,
      label: 'Portal Title',
      visible: true,
      required: null,
      regex: null,
    },
  },
  billing: false,
  code: '',
  description: null,
  id: null,
  ilmDays: 90,
  name: '',
  sdWan: false,
  sdWanServerId: '',
  slmDays: 365,
  type: '',
};
