import React, { useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Panel from '../../../common/Panel/Panel';
import Table from '../../../common/Table';

import { handleDeleteAlert } from '../../../common/Alerts/DeleteAlert';
const NtuTypes = ({ entities }) => {
  const data = entities;

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        sortable: true,
        Cell: ({ row }) => {
          return (
            <div>
              <Link to={`${row.original.id}`}>{row.original.id}</Link>
            </div>
          );
        },
      },
      {
        Header: 'Model',
        accessor: 'model',
        sortable: true,
      },
      {
        Header: 'Ethernet Ports',
        accessor: 'etherPrefix',
        sortable: true,
      },
      {
        Header: 'Ethernet Prefix',
        accessor: 'ethernetPorts',
        sortable: true,
      },
      {
        Header: 'SFP Ports',
        accessor: 'sfpPorts',
        sortable: true,
      },
      {
        Header: 'SFP Prefix',
        accessor: 'sfpPrefix',
        sortable: true,
      },
      {
        Header: 'Description',
        accessor: 'description',
        sortable: true,
      },
      {
        Header: 'Port Service Type',
        accessor: 'portServiceType',
        sortable: true,
      },
      {
        Header: 'OS Type',
        accessor: 'osType',
        sortable: true,
      },
      {
        Header: 'Attributes',
        accessor: (d) =>
          Object.values(d.attributes).map((attribute) => (
            <button
              className="btn btn-light mr-1 mb-1"
              key={attribute.id}
              style={{ width: '180px', fontSize: '12px' }}
            >
              {`${attribute.label}: ${attribute.value}`}
            </button>
          )),
        sortable: true,
      },
      {
        Header: '',
        accessor: 'actionColumns',
        className: 'align-middle',
        Cell: ({ row }) => {
          return (
            <div className="text-center d-flex">
              <Link
                className="btn btn-icon btn-circle btn-default mr-2"
                to={`${row.original.id}`}
              >
                <i className="fal fa-eye"></i>
              </Link>
              <Link
                className="btn btn-icon btn-circle btn-success mr-2"
                to={`../edit/${row.original.id}`}
              >
                <i className="fal fa-pencil-alt"></i>
              </Link>
              <button
                className="btn btn-icon btn-circle btn-danger"
                onClick={handleDeleteAlert}
              >
                <i className="fal fa-trash"></i>
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <Panel>
      <Table data={data} columns={columns} />
    </Panel>
  );
};

export default NtuTypes;
