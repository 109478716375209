import React, { useEffect, useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import Panel from '../../../common/Panel/Panel';
import { getNTU } from './api/NTUApi';

const NTUDetail = () => {
  const match = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [ntuEntity, setNTUEntity] = useState(null);
  const [appState, setAppState] = useState({
    loading: false,
  });

  useEffect(() => {
    console.log(location);

    setAppState({ loading: true });
    const fetchData = async () => {
      try {
        const result = await getNTU(match.id).then((res) => res.json());
        setNTUEntity(result);
        console.log('NTU Detail: ', result);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    setAppState({ loading: false });
  }, [location]);

  return (
    <div>
      <div className="row">
        <div className="col-xl-10">
          {appState.loading && <p>Loading...</p>}
          {ntuEntity !== null && !appState.loading && (
            <Panel heading={ntuEntity.name}>
              <dl className="row">
                <dt className="text-inverse  col-4 ">
                  <span id="name">
                    <span>Name</span>
                  </span>
                </dt>
                <dd className="col-8 ">{ntuEntity.name}</dd>

                <dt className="text-inverse  col-4 ">
                  <span id="name">
                    <span>UID</span>
                  </span>
                </dt>
                <dd className="col-8 ">{ntuEntity.uid}</dd>

                <dt className="text-inverse  col-4 ">
                  <span id="name">
                    <span>Description</span>
                  </span>
                </dt>
                <dd className="col-8 ">{ntuEntity.description}</dd>

                <dt className="text-inverse  col-4 ">
                  <span id="name">
                    <span>Serial Number</span>
                  </span>
                </dt>
                <dd className="col-8 ">{ntuEntity.serialNumber}</dd>

                <dt className="text-inverse  col-4 ">
                  <span id="name">
                    <span>NTU Loopback</span>
                  </span>
                </dt>
                <dd className="col-8 ">{ntuEntity.serialNumber}</dd>

                <dt className="text-inverse  col-4 ">
                  <span id="name">
                    <span>PE Loopback</span>
                  </span>
                </dt>
                <dd className="col-8 ">{ntuEntity.peLoopbackIP}</dd>

                <dt className="text-inverse  col-4 ">
                  <span id="name">
                    <span>Config Backup</span>
                  </span>
                </dt>
                <dd className="col-8 ">
                  {JSON.stringify(ntuEntity.configBackup)}
                </dd>

                <dt className="text-inverse  col-4 ">
                  <span id="name">
                    <span>Update Oneconfig</span>
                  </span>
                </dt>
                <dd className="col-8 "> </dd>

                <dt className="text-inverse  col-4 ">
                  <span id="name">
                    <span>Firmware Version</span>
                  </span>
                </dt>
                <dd className="col-8 ">{ntuEntity.firmwareVersion}</dd>

                <dt className="text-inverse  col-4 ">
                  <span id="name">
                    <span>Running Config</span>
                  </span>
                </dt>
                <dd className="col-8 "></dd>

                <dt className="text-inverse  col-4 ">
                  <span id="name">
                    <span>Config Id</span>
                  </span>
                </dt>
                <dd className="col-8 "></dd>

                <dt className="text-inverse  col-4 ">
                  <span id="name">
                    <span>Rest Endpoint</span>
                  </span>
                </dt>
                <dd className="col-8 ">{ntuEntity.endpoint}</dd>

                <dt className="text-inverse  col-4 ">
                  <span id="name">
                    <span>Rest Username</span>
                  </span>
                </dt>
                <dd className="col-8 ">{ntuEntity.restUsername}</dd>

                <dt className="text-inverse  col-4 ">
                  <span id="name">
                    <span>Rest Enabled</span>
                  </span>
                </dt>
                <dd className="col-8 ">
                  {JSON.stringify(ntuEntity.restEnabled)}
                </dd>

                <dt className="text-inverse  col-4 ">
                  <span id="name">
                    <span>Auto Rollback</span>
                  </span>
                </dt>
                <dd className="col-8 ">
                  {JSON.stringify(ntuEntity.autoRollback)}
                </dd>

                <dt className="text-inverse  col-4 ">
                  <span id="name">
                    <span>Mode</span>
                  </span>
                </dt>
                <dd className="col-8 ">{ntuEntity.mode}</dd>

                <dt className="text-inverse  col-4 ">
                  <span id="name">
                    <span>Time Zone</span>
                  </span>
                </dt>
                <dd className="col-8 ">{ntuEntity.timeZone}</dd>

                <dt className="text-inverse  col-4 ">
                  <span id="name">
                    <span> Min Rate</span>
                  </span>
                </dt>
                <dd className="col-8 ">{ntuEntity.minRate}</dd>

                <dt className="text-inverse  col-4 ">
                  <span id="name">
                    <span> Max Rate</span>
                  </span>
                </dt>
                <dd className="col-8 ">{ntuEntity.maxRate}</dd>

                <dt className="text-inverse  col-4 ">
                  <span id="name">
                    <span> Default Rate</span>
                  </span>
                </dt>
                <dd className="col-8 ">{ntuEntity.defaultRate}</dd>

                <dt className="text-inverse  col-4 ">
                  <span id="name">
                    <span>Enable Bod</span>
                  </span>
                </dt>
                <dd className="col-8 ">
                  {JSON.stringify(ntuEntity.enableBod)}
                </dd>

                <dt className="text-inverse  col-4 ">
                  <span id="name">
                    <span>Burst Time</span>
                  </span>
                </dt>
                <dd className="col-8 ">{ntuEntity.burstTime}</dd>

                <dt className="text-inverse  col-4 ">
                  <span id="name">
                    <span>Secondary Uplink Port</span>
                  </span>
                </dt>
                <dd className="col-8 ">{ntuEntity.uplinkPort}</dd>

                <dt className="text-inverse  col-4 ">
                  <span id="name">
                    <span>Service Configuration</span>
                  </span>
                </dt>
                <dd className="col-8 ">{ntuEntity.serviceConfigurationName}</dd>

                <dt className="text-inverse  col-4 ">
                  <span id="name">
                    <span>Port Location</span>
                  </span>
                </dt>
                <dd className="col-8 ">{ntuEntity.locationCode}</dd>

                <dt className="text-inverse  col-4 ">
                  <span id="name">
                    <span>Device Type</span>
                  </span>
                </dt>
                <dd className="col-8 ">{ntuEntity.ntutypeModel}</dd>
              </dl>

              <div>
                <button
                  className="btn btn-sm btn-secondary mr-3"
                  onClick={() => navigate('../')}
                >
                  <i className="fal fa-arrow-left fa-sm  mr-2"></i>
                  Back
                </button>
                <button
                  className="btn btn-sm btn-primary m-r-5"
                  onClick={() => navigate('../edit/' + match.id)}
                >
                  <i className="fal fa-pencil-alt fa-sm  mr-2"></i>
                  Edit
                </button>
              </div>
            </Panel>
          )}
        </div>
      </div>
    </div>
  );
};

export default NTUDetail;
