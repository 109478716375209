import React, { useMemo } from "react";
import { Link } from "react-router-dom";

import Panel from "../../../common/Panel/Panel";
import Table from "../../../common/Table";

const organizations = JSON.parse(localStorage.getItem("accountInfo"))?.filter
  .filters;

const ApiTemplates = ({ entities }) => {
  const data = entities;

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        sortable: true,
        Cell: ({ row }) => {
          return (
            <div>
              <Link to={`${row.original.id}`}>{row.original.id}</Link>
            </div>
          );
        },
      },
      {
        Header: "Organization",
        accessor: (item) => {
          const org = organizations?.find((org) => org.orgId === item.orgId);
          return org.orgName;
        },
        sortable: true,
      },
      {
        Header: "Name",
        accessor: "name",
        sortable: true,
      },
      {
        Header: "Type",
        accessor: "type",
        sortable: true,
      },
    ],
    []
  );

  return (
    <Panel>
      <Table data={data} columns={columns} />
    </Panel>
  );
};

export default ApiTemplates;
