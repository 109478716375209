import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import Panel from "../../../common/Panel/Panel";
import Alert from "../../../common/AlertControls/Alert";
import { handleCreateNewAlert } from "../../../common/Alerts/CreateNewAlert";
import { handleUpdateAlert } from "../../../common/Alerts/EditAlert";
import {
  createEntityManager,
  getEntityManager,
  updateEntityManager,
} from "./api/entityManagersApi";
import { EntityManagerAttributeModal } from "./EntityManagerAttributeModal";

const EntityManagerUpdate = () => {
  const match = useParams();
  const navigate = useNavigate();

  let defaultValues = {
    id: "",
    entityType: "",
    description: "",
    attributes: {},
  };

  const [initialData, setInitialData] = useState(defaultValues);
  const [appState, setAppState] = useState({
    loading: true,
  });

  const isNew = !match.id;
  const [alertShow, setAlertShow] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [attributes, setAttributes] = useState(null);
  const [activeAttribute, setActiveAttribute] = useState(null);
  const [showAttributeModal, setShowAttributeModal] = useState(false);

  const schema = Yup.object().shape({});

  async function createNewEntityManager(entityManager) {
    const response = await createEntityManager(entityManager).then((res) =>
      res.json()
    );
    if (response.id) {
      handleCreateNewAlert(
        'New entityManager "' + entityManager.name + '" added successfully!'
      );
      navigate("../");
    }
  }

  async function updateEntityManagers(entityManager) {
    const response = await updateEntityManager(entityManager).then((res) =>
      res.json()
    );

    if (response.id) {
      handleUpdateAlert("entityManager", entityManager.id);
      navigate("../" + entityManager.entityManagerId);
    }
  }

  function submitForm(formValues) {
    isNew
      ? createNewEntityManager(formValues)
      : updateEntityManagers(formValues);
  }

  // effect runs on component mount
  useEffect(() => {
    let unmounted = false;
    const fetchEntityManager = async () => {
      try {
        const result = await getEntityManager(match.id).then((res) =>
          res.json()
        );
        if (!unmounted) {
          if (result.description == null) result.description = "";
          setAttributes(result.attributes);
          setInitialData(result);
          //reset(result);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (!isNew) fetchEntityManager();
    setAppState({ loading: false });
    return () => {
      unmounted = true;
    };
  }, []);

  const onSaveAttribute = (newAttribute) => {
    const updatedAttributes = { ...attributes };
    updatedAttributes[newAttribute.field] = newAttribute;
    setAttributes(updatedAttributes);
  };

  return (
    <div>
      <div className="row">
        <div className="col-xl-9">
          {alertShow && (
            <Alert
              alertType="alert-success"
              msg="The entityManager has been updated."
            />
          )}

          {!appState.loading && initialData && (
            <Panel heading={initialData.name}>
              <Formik
                enableReinitialize
                initialValues={initialData}
                validationSchema={schema}
                validateOnMount
                onSubmit={submitForm}
              >
                {({ errors, touched, isValid, setFieldValue, values }) => {
                  return (
                    <Form>
                      {!isNew && (
                        <div className="form-group row m-b-15">
                          <label className="col-form-label col-md-3">ID</label>
                          <div className="col-md-9">
                            <Field
                              type="text"
                              name="id"
                              label="id"
                              className="form-control m-b-5"
                              readOnly
                            />
                          </div>
                        </div>
                      )}

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Entity Type <span className="text-danger"></span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            as="select"
                            name="entityType"
                            className={`form-control m-b-5  ${
                              errors.entityType && touched.entityType
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            <option value=""></option>
                            {["Organization", "Location", "NTU Type"].map(
                              (type, i) => (
                                <option key={i} value={type}>
                                  {type}
                                </option>
                              )
                            )}
                          </Field>
                          {errors.entityType && touched.entityType ? (
                            <div className="invalid-feedback">
                              {errors.entityType}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Description
                        </label>
                        <div className="col-md-9">
                          <Field
                            name="description"
                            label="description"
                            className={`form-control m-b-5  ${
                              errors.description && touched.description
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder="Enter Description"
                          />
                          {errors.description && touched.description ? (
                            <div className="invalid-feedback">
                              {errors.description}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div
                        className="btn btn-icon btn-circle btn-default mb-2"
                        onClick={() => {
                          setActiveAttribute(null);
                          setShowAttributeModal(true);
                        }}
                      >
                        <i className="fas fa-plus"></i>
                      </div>

                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Field</th>
                            <th>Field Label</th>
                            <th>Field Type</th>
                            <th>Description</th>
                            <th width="100px" />
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(attributes || {}).map(
                            (attributeKey, i) => {
                              const obj = attributes[attributeKey];
                              return (
                                <tr key={i}>
                                  <td>{attributeKey}</td>
                                  <td>{obj?.label}</td>
                                  <td>{obj?.fieldType}</td>
                                  <td>{obj?.description}</td>
                                  <td className="d-flex">
                                    <div
                                      className="btn btn-icon btn-circle btn-success mr-2"
                                      onClick={() => {
                                        setActiveAttribute({
                                          ...obj,
                                          field: attributeKey,
                                        });
                                        setShowAttributeModal(true);
                                      }}
                                    >
                                      <i className="fal fa-pencil-alt" />
                                    </div>
                                    <div
                                      className="btn btn-icon btn-circle btn-danger"
                                      onClick={() => {
                                        const newAttributes = {
                                          ...attributes,
                                        };
                                        delete newAttributes[attributeKey];
                                        setAttributes(newAttributes);
                                      }}
                                    >
                                      <i className="fal fa-trash" />
                                    </div>
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>

                      <button
                        type="submit"
                        className="btn btn-primary btn-block btn-lg m-b-5"
                        disabled={!isValid || isSubmitted}
                      >
                        Submit
                      </button>
                    </Form>
                  );
                }}
              </Formik>
              <EntityManagerAttributeModal
                attribute={activeAttribute}
                show={showAttributeModal}
                setShow={setShowAttributeModal}
                onSaveAttribute={onSaveAttribute}
              />
            </Panel>
          )}
        </div>
      </div>
    </div>
  );
};

export default EntityManagerUpdate;
