import React, { useEffect, useState, Fragment } from "react";
import { Routes, Route, useLocation, Link } from "react-router-dom";

import SubnetConfigurations from "./subnetConfigurations";
import SubnetConfigurationDetail from "./SubnetConfigurationDetail";
import SubnetConfigurationUpdate from "./SubnetConfigurationUpdate";
// import SubnetConfigurationDelete from "./SubnetConfigurationDeleteDialog";
import { getSubnetConfigurations } from "./api/subnetConfigurationsApi";
import { getLocations } from "../Locations/api/LocationsApi";

const SubnetConfigurationsContainer = () => {
  const ntu = localStorage.getItem("ntu");

  const location = useLocation();

  const [entities, setEntities] = useState([]);
  const [appState, setAppState] = useState({
    loading: false,
    err: false,
  });
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        setAppState({ loading: true, err: false });
        const res = await getSubnetConfigurations().then((res) => res.json());
        const locationsRes = await getLocations().then((res) => res.json());
        setAppState({ loading: false });
        setEntities(res);
        setLocations(locationsRes);
      } catch (err) {
        setAppState({ loading: false, err: true });
      }
    })();
  }, []);

  return (
    <Fragment>
      <ol className="breadcrumb page-breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">ActivePort</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to="/administration">Settings</Link>
        </li>
        <li className="breadcrumb-item active">Subnet Configurations</li>
        <li className="position-absolute pos-top pos-right d-none d-sm-block">
          <span className="js-get-date"></span>
        </li>
      </ol>
      <div className="subheader">
        <h1 className="subheader-title">
          <i className="subheader-icon fal fa-table"></i> Subnet Configurations
        </h1>

        {ntu &&
          !location.pathname.includes("/new") &&
          !location.pathname.includes("/edit") && (
            <Link to={`new`}>
              <button
                type="button"
                className="btn btn-success waves-effect waves-themed"
              >
                <span className="fal fa-plus mr-1"></span>
                Create
              </button>
            </Link>
          )}
      </div>
      {!appState.loading && !appState.err && (
        <Routes>
          {ntu && (
            <>
              <Route
                exact
                path={`/new`}
                element={<SubnetConfigurationUpdate locations={locations} />}
              />
              <Route
                exact
                path={`/edit/:id`}
                element={<SubnetConfigurationUpdate locations={locations} />}
              />
              <Route
                exact
                path={`:id`}
                element={<SubnetConfigurationDetail />}
              />
            </>
          )}

          <Route
            path={`/`}
            element={<SubnetConfigurations entities={entities} />}
          />
        </Routes>
      )}
      {appState.loading && !appState.error && (
        <div className="border p-3">
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      )}
      {!appState.loading && appState.err && (
        <div className="alert alert-danger" role="alert">
          <strong>Oh snap!</strong> We are not able to connect to the database.
          Try again in few minutes. again.
        </div>
      )}
      {/* <Route
          exact
          path={`/:id/delete`}
          element={SubnetConfigurationDelete}
        /> */}
    </Fragment>
  );
};

export default SubnetConfigurationsContainer;
