import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import Panel from '../../../common/Panel/Panel';
import Table from '../../../common/Table';
import { handleDeleteAlert } from '../../../common/Alerts/DeleteAlert';
import { offset } from '@popperjs/core';
const ServiceDefinition = ({ entities }) => {
  const data = entities;
  var commands2 = [];

  for (var i = 0; i < data.length; i++) {
    let newarray = [];
    for (var j = 0; j < data[i].commands.length; j++) {
      let command = data[i].commands[j];
      var exists = false;

      if (newarray.length != 0) {
        for (var k = 0; k < newarray.length; k++) {
          if (
            newarray[k].osType == command.osType &&
            newarray[k].deviceType == command.deviceType
          )
            exists = true;
        }
      }
      if (!exists) {
        newarray.push(command);
      }
    }
    commands2.push(newarray);
  }
  for (var i = 0; i < data.length; i++) {
    data[i].commands2 = commands2[i];
  }

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'row_id',
        sortable: true,
        Cell: ({ row }) => {
          return (
            <div>
              <Link to={`${row.original.id}`}>{row.original.id}</Link>
            </div>
          );
        },
      },
      {
        Header: 'Name',
        accessor: 'name',
        sortable: true,
      },
      {
        Header: 'Service Type',
        accessor: 'serviceTypeCode',
        sortable: true,
      },
      {
        Header: 'Creation Type',
        accessor: 'creationType',
        sortable: true,
      },
      {
        Header: 'Provider Type',
        accessor: 'providerTypeName',
        sortable: true,
      },
      {
        Header: 'Remote Service Code',
        accessor: 'tag',
        sortable: true,
      },
      {
        Header: 'Devices',
        accessor: (d) =>
          d.commands2?.map((command) => (
            <button className="btn btn-light mr-1 mb-1" key={command.id}>
              {`${command.deviceType} > ${command.osType}`}
            </button>
          )),

        sortable: true,
      },
      {
        Header: 'Events',
        accessor: (d) =>
          d.commands2?.map((command) => (
            <button className="btn btn-light mr-1 mb-1" key={command.id}>
              {`${command.onEvent}`}
            </button>
          )),

        sortable: true,
      },
      {
        Header: '',
        accessor: 'actionColumns',
        className: 'align-middle',
        Cell: ({ row }) => {
          return (
            <div className="text-center d-flex">
              <Link
                className="btn btn-icon btn-circle btn-default mr-2"
                to={`${row.original.id}`}
              >
                <i className="fal fa-eye"></i>
              </Link>
              <Link
                className="btn btn-icon btn-circle btn-success mr-2"
                to={`../edit/${row.original.id}`}
              >
                <i className="fal fa-pencil-alt"></i>
              </Link>
              <button
                className="btn btn-icon btn-circle btn-danger"
                onClick={handleDeleteAlert}
              >
                <i className="fal fa-trash"></i>
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <Panel>
      <Table data={data} columns={columns} />
    </Panel>
  );
};

export default ServiceDefinition;
