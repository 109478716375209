import React from "react";
import { Formik, Form, Field } from "formik";

import HelpText from "../../../../common/Form/HelpText/HelpText";
import { searchNtu } from "../api/networkConnectApi";

const circuitTypes = [
  {
    label: "VLAN",
    value: "VLAN",
  },
  {
    label: "Circuit",
    value: "CIRCUIT",
  },
  {
    label: "Route",
    value: "ROUTING_ROUTE",
  },
  {
    label: "NAT   ",
    value: "ROUTING_NAT",
  },
];

export const CreateNetworkConnectForm = ({
  show,
  setShow,
  onSaveAttribute,
}) => {
  const ntu = JSON.parse(localStorage.getItem("ntu"));

  const initialValues = {
    name: "",
    circuitType: ntu.mode === "VIRTUAL" ? "VLAN" : "CIRCUIT",
    ntuName: `${ntu.name}-${ntu.locationCode}`,
    ntuUuid: "",
  };

  const onHide = (e) => {
    e.preventDefault();
    setShow(!show);
  };

  const saveAttribute = (values) => {
    onSaveAttribute(values);
    setShow(false);
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) => {
          saveAttribute(values);
          resetForm();
        }}
      >
        {({ values }) => {
          return (
            <div
              className={`modal fade overflow-auto ${show && "show d-block"}`}
            >
              <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <div className="d-flex w-100 align-items-center justify-content-between">
                      <img src="/assets/img/ntu/ACTIVEPORT-TILE.png" alt="" />
                      <button type="button" className="close" onClick={onHide}>
                        <span aria-hidden="true">
                          <i className="fal fa-times"></i>
                        </span>
                      </button>
                    </div>
                  </div>
                  <div className="modal-body">
                    <Form>
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Service Name
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="name"
                            className="form-control m-b-5"
                          />
                        </div>
                      </div>
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Space Type
                        </label>
                        <div className="col-md-9">
                          <Field
                            readOnly
                            disabled
                            as="select"
                            name="circuitType"
                            className={`form-control m-b-5`}
                          >
                            {circuitTypes.map(({ label, value }, i) => (
                              <option key={i} value={value}>
                                {label}
                              </option>
                            ))}
                          </Field>
                        </div>
                      </div>
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Source Ntu
                        </label>
                        <div className="col-md-9">
                          <Field
                            readOnly
                            type="text"
                            name="ntuName"
                            className="form-control m-b-5"
                          />
                          <HelpText text="Source NTU" />
                        </div>
                      </div>
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          DST NTU UUID
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="ntuUuid"
                            className="form-control m-b-5"
                          />
                          <button
                            className="btn btn-light mt-2"
                            type="button"
                            onClick={() => searchNtu(values.ntuUuid)}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary waves-effect waves-themed"
                          onClick={onHide}
                        >
                          Close
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary waves-effect waves-themed"
                        >
                          Validate
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
    </>
  );
};
