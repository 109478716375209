import { Formik, Form, Field, useField } from 'formik';

const MyTextInput = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <>
      <div className="form-group row m-b-15">
        <label
          className="col-form-label col-md-3"
          htmlFor={props.id || props.name}
        >
          {label}
        </label>
        <div className="col-md-9">
          <input
            className={`form-control m-b-5  ${
              meta.touched && meta.error ? 'is-invalid' : ''
            }`}
            {...field}
            {...props}
          />
        </div>
      </div>

      {meta.touched && meta.error ? (
        <div className="invalid-feedback">{meta.error}</div>
      ) : null}
    </>
  );
};

export const MyTextArea = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <>
      <div className="form-group row m-b-15">
        <label
          className="col-form-label col-md-3"
          htmlFor={props.id || props.name}
        >
          {label}
        </label>
        <div className="col-md-9">
          <textarea
            className={`form-control m-b-5  ${
              meta.touched && meta.error ? 'is-invalid' : ''
            }`}
            {...field}
            {...props}
          />
        </div>
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </div>
    </>
  );
};

export const MyCheckbox = ({ children, ...props }) => {
  const [field, meta] = useField({ ...props, type: 'checkbox' });
  return (
    <>
      <label className="checkbox">
        <input {...field} {...props} type="checkbox" />
        {/* {children} */}
      </label>
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
};

const MySelect = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return <></>;
};

export const MyCheckbox1 = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  classes = '',
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = 'text',
  ...props
}) => {
  return (
    <>
      <label className={`checkbox ${classes}`}>
        <input type="checkbox" {...field} {...props} />
      </label>
    </>
  );
};
