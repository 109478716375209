import Swal from 'sweetalert2';

export const handleCreateNewAlert = (text) => {
  Swal.fire({
    title: 'Success!',
    text: text,
    icon: 'success',
    width: '100%',
    background: 'rgba(0,0,0,0.8)',

    customClass: {
      container: 'modal-alert',
      header: 'modal-header',
      title: 'modal-header',
      content: 'modal-content',
      closeButton: 'btn btn-default bootbox-cancel',
      icon: 'text-danger',
      confirmButton: 'btn btn-success shadow-0 bootbox-accept',
      denyButton: 'btn btn-default bootbox-cancel',
      cancelButton: 'btn btn-default bootbox-cancel',
    },
  });
};
