export const tileNames = {
  AZURE: 'Azure',
  AZURE_PRIVATE: 'Azure',
  AZURE_MICROSOFT: 'Azure',
  VXC: 'Network Connect - VXC',
  XC: 'Network Connect - XC',
  IX: 'Peering Exchange',
  GOOGLE: 'Google Cloud',
  INTERNET_BREAKOUT: 'Local Internet',
  AWS: 'AWS',
  ALIBABA: 'Alibaba Cloud',
  ACURIX_INTERNET: 'ActivePort Internet',
  BOD: 'BOD Service',
  ORACLE: 'Oracle',
  IBM: 'IBM',
  ZETTA_SERVICE: 'Zetta Service',
  ZETTA_INTERNET: 'Zetta Internet',
  PROBAX_SERVICE: 'Probax Service',
  MCON: 'Partner Connect',
};

export const tileImage = {
  AZURE: 'AZURE-TILE.png',
  Azure: 'AZURE-TILE.png',
  AZURE_PRIVATE: 'AZURE-TILE.png',
  AZURE_MICROSOFT: 'AZURE-TILE.png',
  VXC: 'ACTIVEPORT-TILE.png',
  XC: 'ACTIVEPORT-TILE.png',
  IX: 'PEERING-EXCHANGE-TILE.png',
  GOOGLE: 'GOOGLE-TILE.png',
  INTERNET_BREAKOUT: 'ACTIVEPORT-TILE.png',
  AWS: 'AWS-TILE.png',
  ALIBABA: 'ALIBABA-TILE.png',
  ACURIX_INTERNET: 'ACTIVEPORT-TILE.png',
  BOD: 'BOD-SERVICE.png',
  ORACLE: 'ORACLE-TILE.png',
  IBM: 'IBM-TILE.png',
  ZETTA_SERVICE: 'ZETTAGRID-TILE.png',
  ZETTA_INTERNET: 'ZETTANET-TILE.png',
  PROBAX_SERVICE: 'PROBAX-TILE.png',
  MCON: 'ACTIVEPORT-TILE.png',
};
