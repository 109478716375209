import React, { useEffect, useState, Fragment } from 'react';
import { Routes, Route, useLocation, Link } from 'react-router-dom';
import './organisation.scss';
import Organisations from './Organisations';
import OrganisationDetail from './OrganisationDetail';
import OrganisationUpdate from './OrganisationUpdate';
// import TenantDelete from "./TenantDeleteDialog";
import {
  getOrganisations,
  getSdWanServers,
  getSlavesOrg,
} from './api/organisationApi';
import OrganisationCreate from './OrganisationCreate';
import ImageSelector from '../../../common/ImageSelector';

const OrganisationContainer = () => {
  const location = useLocation();

  const [entities, setEntities] = useState([]);
  const [sdWanServers, setSdWanServers] = useState([]);
  const [slaveOrgs, setSlaveOrgs] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const [appState, setAppState] = useState({
    loading: false,
    err: false,
  });

  useEffect(() => {
    (async () => {
      try {
        setAppState({ loading: true, err: false });
        const res = await getOrganisations().then((res) => res.json());
        const sdwanservers = await getSdWanServers().then((res) => res.json());
        const slaveorgs = await getSlavesOrg().then((res) => res.json());
        console.log(slaveorgs);
        setEntities(res);
        setSdWanServers(sdwanservers);
        setSlaveOrgs(slaveorgs);
      } catch (err) {
        setAppState({ loading: false, err: true });
      }
    })();
    setAppState({ loading: false });
  }, []);

  return (
    <Fragment>
      <ol className="breadcrumb page-breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">ActivePort</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to="/administration">System</Link>
        </li>
        <li className="breadcrumb-item active">Organizations</li>
        <li className="position-absolute pos-top pos-right d-none d-sm-block">
          <span className="js-get-date"></span>
        </li>
      </ol>
      <div className="subheader">
        <h1 className="subheader-title">
          <i className="subheader-icon fal fa-table"></i> Organizations
        </h1>

        {!location.pathname.includes('/new') &&
          !location.pathname.includes('/edit') && (
            <Link to={`new`}>
              <button
                type="button"
                className="btn btn-success waves-effect waves-themed"
              >
                <span className="fal fa-plus mr-1"></span>
                Create
              </button>
            </Link>
          )}
      </div>
      {!appState.loading && !appState.err && (
        <Routes>
          <Route
            exact
            path={`/new`}
            element={
              <OrganisationCreate
                entities={entities}
                sdWanServers={sdWanServers}
                slaveOrgs={slaveOrgs}
              />
            }
          />
          <Route exact path={`/edit/:id`} element={<OrganisationUpdate />} />
          <Route exact path={`:id`} element={<OrganisationDetail />} />

          <Route path={`/`} element={<Organisations entities={entities} />} />
        </Routes>
      )}
      {appState.loading && !appState.error && (
        <div className="border p-3">
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      )}
      {!appState.loading && appState.err && (
        <div className="alert alert-danger" role="alert">
          <strong>Oh snap!</strong> We are not able to connect to the database.
          Try again in few minutes. again.
        </div>
      )}
      {/* <Route
          exact
          path={`/:id/delete`}
          element={TenantDelete}
        /> */}
    </Fragment>
  );
};

export default OrganisationContainer;
