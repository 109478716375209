import React, { useState } from 'react';
import PropTypes from 'prop-types';

/**
 * Panels can be used in almost any situation, helping wrap everything in a slick & lightweight container
 * <a href="https://www.gotbootstrap.com/themes/smartadmin/4.5.1/ui_panels.html">Click here to view smart admin UI Panels</a>
 * @component
 * @example <caption>How to use Panel component</caption>
 * const heading = 'Heading'
 * const children = '<div>Some text here</div>'
 * return (
 * <Panel heading={heading}>{children}</Panel>
 * )
 */

const Panel = ({ children, heading }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isHiddenDom, setIsHiddenDom] = useState(false);

  /** function to collapse the panel. */
  const handleFilterOpening = () => {
    setIsOpen((prev) => !prev);
  };

  /** function to make the panel full screen. */
  const handleFilterFullscreen = () => {
    setIsFullscreen((prev) => !prev);
  };

  /** function to hide the panel. */
  const handleFilterHiddenDom = () => {
    setIsHiddenDom((prev) => !prev);
  };

  return (
    <div>
      <div
        id="panel-general"
        className={
          'panel ' +
          (isHiddenDom ? ' invisible' : '') +
          (isFullscreen ? ' panel-fullscreen' : '')
        }
      >
        <div className="panel-hdr text-primary">
          <h2>
            <span className="fw-300">{heading}</span>
          </h2>
          <div className="panel-toolbar">
            <button
              className="btn btn-panel bg-transparent fs-xl w-auto h-auto rounded-0"
              data-action="panel-collapse"
              data-toggle="tooltip"
              data-offset="0,10"
              data-original-title="Collapse"
              onClick={handleFilterOpening}
            >
              <i className="fal fa-window-minimize"></i>
            </button>
            <button
              className="btn btn-panel bg-transparent fs-xl w-auto h-auto rounded-0"
              data-action="panel-fullscreen"
              data-toggle="tooltip"
              data-offset="0,10"
              data-original-title="Fullscreen"
              onClick={handleFilterFullscreen}
            >
              <i className="fal fa-expand"></i>
            </button>
            <button
              className="btn btn-panel bg-transparent fs-xl w-auto h-auto rounded-0"
              data-action="panel-close"
              data-toggle="tooltip"
              data-offset="0,10"
              data-original-title="Close"
              onClick={handleFilterHiddenDom}
            >
              <i className="fal fa-times"></i>
            </button>
          </div>
        </div>
        <div className={'panel-container collapse' + (isOpen ? ' show' : '')}>
          <div className="panel-content">{children}</div>
        </div>
      </div>
    </div>
  );
};

Panel.propTypes = {
  /**
   * Content to show inside the panel
   */
  children: PropTypes.node.isRequired,
  /**
   * Panel Heading
   */
  heading: PropTypes.string,
};

export default Panel;
