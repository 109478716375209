import React, { useState, useEffect, useRef } from 'react';
import { getElasticSearchData } from './api/elasticsearch';
import Overview from './Overview';
import TopN from './TopN';
import Sankey from './Sankey';
import Threats from './Threats';
import Exporters from './Exporters';
import moment from 'moment';
import tz from 'moment-timezone';

// Import All Graphs
import ServerBytesPieRequest from '../../../common/graphs/vega/server_bytes_pie_request.json';
import ServicesBytesPieRequest from '../../../common/graphs/vega/services_bytes_pie_request.json';
import AutonomousSystemsBytesRequest from '../../../common/graphs/vega/autonomous_systems_bytes_pie_request.json';
import ip_versions_bytes_pie_request from '../../../common/graphs/vega/ip_versions_bytes_pie_request.json';

import GeoIP from './GeoIP';
import ASTraffic from './ASTraffic';
import TrafficDetails from './TrafficDetails';

const InternetDashbaord = ({ NTU, NTUPorts, lte, gte }) => {
  const ntu = NTU;
  const [showGraphs, setShowGraphs] = useState(false);

  //Overview
  const [serverBytesData, setServerBytesData] = useState({});
  const [servicesBytesData, setServicesBytesData] = useState({});
  const [autonomousSystemsBytesData, setAutonomousSystemsBytesData] = useState(
    {}
  );
  const [iPVersionsBytesData, setIPVersionsBytesData] = useState({});

  //Sankey
  const [sankeyBytesData, setSankeyBytesData] = useState({});
  const [autonomousSystemFlowData, setAutonomousSystemFlowData] = useState({});

  const [
    autonomousSystemFlowServicesData,
    setAutonomousSystemFlowServicesData,
  ] = useState({});
  const [autonomousSystemFlowDestData, setAutonomousSystemFlowDestData] =
    useState({});

  const [serviceBitsData, setServiceBitsData] = useState({});
  const [sourceAutoBitsData, setSourceAutoBitsData] = useState({});
  const [destinationAutoBitsData, setDestinationAutoBitsData] = useState({});
  const [sourceAutoPacketsData, setSourceAutoPacketsData] = useState({});
  const [destinationAutoPacketsData, setDestinationAutoPacketsData] = useState(
    {}
  );

  const [clientBitsData, setClientBitsData] = useState({});
  const [serverBitsData, setServerBitsData] = useState({});
  const [clientPacketsData, setClientPacketsData] = useState({});
  const [serverPacketsData, setServerPacketsData] = useState({});

  const [servicesBitsData, setServicesBitsData] = useState({});
  const [servicesPacketsData, setServicesPacketsData] = useState({});
  const [autonomousSystemFlow1Data, setAutonomousSystemFlow1Data] = useState(
    {}
  );

  const [ingressInterfaceBitsData, setIngressInterfaceBitsData] = useState({});
  const [egressInterfaceBitsData, setEgressInterfaceBitsData] = useState({});

  const [ingressInterfacePacketsData, setIngressInterfacePacketsData] =
    useState({});
  const [egressInterfacePacketsData, setEgressInterfacePacketsData] = useState(
    {}
  );

  const [countriesSourceData, setCountriesSourceData] = useState({});
  const [countriesDestData, setCountriesDestData] = useState({});
  const [citiesSourceData, setCitiesSourceData] = useState({});
  const [citiesDestData, setCitiesDestData] = useState({});
  const [internetGeoIPSrcData, setInternetGeoIPSrcData] = useState({});

  const [autonomousSystemsFlow1Data, setAutonomousSystemsFlow1Data] = useState(
    {}
  );
  const [autonomusSystemsDestFlowData, setAutonomousSystemsFlowDestData] =
    useState({});

  const [tab, setTab] = useState('overview');
  var serial;
  var ignore_throttled = 1;

  const getLTE = () => {
    if (lte == null || lte == '') {
      lte = new Date();
      lte = moment();
      lte = lte._d.getTime();
    }
    return lte;
  };
  const getGTE = () => {
    if (gte == null || gte == '') {
      gte = new Date();
      gte = moment().subtract(3, 'hours');
      gte = gte._d.getTime();
    }
    return gte;
  };

  const drawGraph_response = async (response, div) => {
    var gte_n = getGTE();
    var lte_n = getLTE();
    var sum_rep_tags = 0;

    if (response != null && response.aggregations.results.buckets.length > 0) {
      if (div === '#reputation') {
        for (var x = 0; x < response.aggregations.results.buckets.length; x++) {
          if (response.aggregations.results.buckets[x].ip_rep !== undefined) {
            if (
              response.aggregations.results.buckets[x].ip_rep.buckets.length > 0
            ) {
              sum_rep_tags +=
                response.aggregations.results.buckets[x].ip_rep.buckets.length;
            }
          }
        }
        //console.log('sum_rep_tags: ', sum_rep_tags);
      }

      if (div === '#internet_geoip_src') {
        //console.log(JSON.stringify(response.aggregations));
        if (
          response.aggregations.results.buckets[0].results_dst.buckets.length ==
          0
        ) {
          console.log('NO DST GEO DATA!');
          // HACK ALERT - assume 1st entry is "server" lol
          response.aggregations.results.buckets[0].results_dst.buckets =
            response.aggregations.results_client.buckets;
        }
      }
      let result = response.aggregations.results.buckets;
      console.log('result::', div);
      if (div === '#server_bytes') {
        let dataArray = { table: response.aggregations.results.buckets };

        //  console.log(server_bytes_data);
        setServerBytesData(dataArray);
      } else if (div === '#services_bytes') {
        let dataArray = { table: response.aggregations.results.buckets };
        setServicesBytesData(dataArray);
      } else if (div === '#autonomous_systems_bytes') {
        let dataArray = { table: response.aggregations.results.buckets };
        setAutonomousSystemsBytesData(dataArray);
      } else if (div === '#ip_versions_bytes') {
        let dataArray = { table: response.aggregations.results.buckets };
        setIPVersionsBytesData(dataArray);
      } else if (div === '#services_bits_lg') {
        console.log('SERVICES BITS: OK');
        let dataArray = { table: response.aggregations.results.buckets };

        setServicesBitsData(dataArray);
      }
    }
  };
  const drawGraph = async (request, div) => {
    var gte_n = getGTE();
    var lte_n = getLTE();

    serial = ntu.serialNumber;
    if (ntu.mode == 'VIRTUAL') {
      serial = ntu.peSerialNumber;
    }
    request.query.bool.filter.push({ term: { 'serial-number': serial } });
    //data.query.bool.must[0].query_string["query"] = "serial-number: " + vm.serial;
    request.query.bool.must[0].range['@timestamp'].gte = gte_n;
    request.query.bool.must[0].range['@timestamp'].lte = lte_n;

    try {
      let response = await getElasticSearchData('search', {
        ignoreThrottled: ignore_throttled,
        index: 'elastiflow-3.5.1-*',
        size: 0,

        body: JSON.stringify(request),
      }).then((res) => res.json());
      console.log(response);
      drawGraph_response(response, div);
    } catch (error) {
      console.log(error);
    }
  };
  const loadAll = async () => {
    await drawGraph(ServerBytesPieRequest, '#server_bytes');
    await drawGraph(ServicesBytesPieRequest, '#services_bytes');
    await drawGraph(AutonomousSystemsBytesRequest, '#autonomous_systems_bytes');
    await drawGraph(ip_versions_bytes_pie_request, '#ip_versions_bytes');

    setShowGraphs(true);
  };
  useEffect(async () => {
    loadAll();
  }, [lte, gte]);
  return (
    <>
      <div className="row">
        <div className="col-12">
          <ul className="nav nav-pills nav-justified mb-4" f role="tablist">
            <li className="nav-item">
              <a
                className={`nav-link  py-3 px-5 ${
                  tab == 'overview' ? 'active' : 'inactive'
                }`}
                data-toggle="tab"
                onClick={() => setTab('overview')}
              >
                Overview
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link  py-3 px-5 ${
                  tab == 'top-n' ? 'active' : 'inactive'
                }`}
                data-toggle="tab"
                onClick={() => {
                  return setTab('top-n');
                }}
              >
                Top-N
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link  py-3 px-5 ${
                  tab == 'threats' ? 'active' : 'inactive'
                }`}
                data-toggle="tab"
                onClick={() => {
                  return setTab('threats');
                }}
              >
                Threats
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link  py-3 px-5 ${
                  tab == 'sankey' ? 'active' : 'inactive'
                }`}
                data-toggle="tab"
                onClick={() => {
                  return setTab('sankey');
                }}
              >
                Sankey
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link  py-3 px-5 ${
                  tab == 'geo-ip' ? 'active' : 'inactive'
                }`}
                data-toggle="tab"
                onClick={() => {
                  return setTab('geo-ip');
                }}
              >
                Geo IP
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link  py-3 px-5 ${
                  tab == 'as-traffic' ? 'active' : 'inactive'
                }`}
                data-toggle="tab"
                onClick={() => {
                  return setTab('as-traffic');
                }}
              >
                AS Traffic
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link  py-3 px-5 ${
                  tab == 'exporters' ? 'active' : 'inactive'
                }`}
                data-toggle="tab"
                onClick={() => {
                  return setTab('exporters');
                }}
              >
                Exporters
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link  py-3 px-5 ${
                  tab == 'traffic-details' ? 'active' : 'inactive'
                }`}
                data-toggle="tab"
                onClick={() => {
                  return setTab('traffic-details');
                }}
              >
                Traffic Details
              </a>
            </li>
          </ul>
        </div>
      </div>
      {showGraphs && tab == 'overview' && (
        <Overview
          ServerBytesData={serverBytesData}
          ServicesBytesData={servicesBytesData}
          AutonomousSystemsBytesData={autonomousSystemsBytesData}
          IPVersionsBytesData={iPVersionsBytesData}
        />
      )}
      {showGraphs && tab == 'top-n' && <TopN lte={lte} gte={gte} ntu={NTU} />}
      {showGraphs && tab == 'threats' && (
        <Threats lte={lte} gte={gte} ntu={NTU} />
      )}
      {showGraphs && tab == 'sankey' && (
        <Sankey lte={lte} gte={gte} ntu={NTU} />
      )}

      {showGraphs && tab == 'geo-ip' && <GeoIP lte={lte} gte={gte} ntu={NTU} />}
      {showGraphs && tab == 'as-traffic' && (
        <ASTraffic lte={lte} gte={gte} ntu={NTU} />
      )}
      {showGraphs && tab == 'exporters' && (
        <Exporters lte={lte} gte={gte} ntu={NTU} />
      )}
      {showGraphs && tab == 'traffic-details' && (
        <TrafficDetails lte={lte} gte={gte} ntu={NTU} />
      )}
    </>
  );
};

export default InternetDashbaord;
