import React, { useState, useRef, useEffect } from 'react';

const MonitorRTTS = ({ devices, drawDeviceGraph }) => {
  let options = [];
  const [selected, setSelected] = useState('');
  console.log('devices length:', devices.allDevices.length);
  for (var idx = 0; idx < devices.allDevices.length; idx++) {
    var updown =
      devices.allDevices[idx].latest.hits.hits[0]._source.monitor.status;
    //var updown = vm.AllDevices[idx]["updown"]["buckets"][0]["key"];

    if (updown == 'up') {
      options.push(devices.allDevices[idx]);
    }
  }

  return (
    <div>
      <div class="form-group">
        <label class="form-label" for="example-select">
          Select Device
        </label>

        <select
          class="form-control"
          id="example-select"
          value={selected}
          onChange={(e) => {
            drawDeviceGraph(e.target.value);
            setSelected(e.target.value);
          }}
        >
          <option>Select a device</option>
          {options.map((item, key) => {
            return (
              <option
                selected={`${selected == key ? selected : ''}`}
                value={key}
              >
                {item.key}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default MonitorRTTS;
