import React, { useState } from "react";
import { NtuSettingsDialog } from "./NtuSettingsDialog";

export const NtuSettings = ({ partnerConnects }) => {
  const [showModal, setShowModal] = useState(false);
  const [id, setId] = useState("");

  const onEditClick = (id) => {
    setId(id);
    setShowModal(true);
  };

  return (
    <>
      <div className="border border-1">
        <div className="d-flex justify-content-between p-2">
          <p className="font-weight-bold">NTU Settings</p>
        </div>

        <div className="p-2">
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                <th>State</th>
                <th>Routing</th>
                <th>Interface</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {(partnerConnects || []).map((connection) => (
                <tr key={connection.id}>
                  <td>
                    <p className="p-2 badge badge-success">
                      {connection.state}
                    </p>
                  </td>
                  <td>{connection.routingType}</td>
                  <td></td>
                  <td width="100px">
                    <div
                      className="btn btn-icon btn-circle btn-primary mr-2"
                      onClick={() => onEditClick(connection.id)}
                    >
                      <i className="fal fa-pencil-alt"></i>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {id && (
        <NtuSettingsDialog
          show={showModal}
          id={id}
          onHide={() => setShowModal(false)}
        />
      )}
    </>
  );
};
