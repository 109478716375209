import Tenant from '../components/Admin/Tenants';
import Administration from '../components/Admin/Administration';
import UserProfiles from '../components/Admin/UserProfiles';
import BillingSystem from '../components/Admin/BillingSystem';
import BillingAccounts from '../components/Admin/BillingAccounts';
import InventoryItems from '../components/Admin/InventoryItems';
import UserManagement from '../components/Admin/UserManagement/';
import EmailSetup from '../components/Admin/EmailSetup/';
import EmailAddresses from '../components/Admin/EmailAddresses';
import EmailTemplates from '../components/Admin/EmailTemplates';
import NotificationEmails from '../components/Admin/NotificationEmails';

export const AdministrationRoutes = [
  {
    element: <NotificationEmails />,
    path: '/administration/notification-emails/*',
    roleNames: '',
  },
  {
    element: <EmailTemplates />,
    path: '/administration/email-templates/*',
    roleNames: '',
  },
  {
    element: <EmailAddresses />,
    path: '/administration/email-addresses/*',
    roleNames: '',
  },
  {
    element: <EmailSetup />,
    path: '/administration/email-setup/*',
    roleNames: '',
  },
  {
    element: <UserManagement />,
    path: '/administration/user-management/*',
    roleNames: '',
  },
  {
    element: <InventoryItems />,
    path: '/administration/inventory-items/*',
    roleNames: '',
  },
  {
    element: <BillingAccounts />,
    path: '/administration/billing-accounts/*',
    roleNames: '',
  },
  {
    element: <BillingSystem />,
    path: '/administration/billing-system/*',
    roleNames: '',
  },
  {
    element: <UserProfiles />,
    path: '/administration/user-profiles/*',
    roleNames: '',
  },
  {
    element: <Tenant />,
    path: '/administration/tenants/*',
    roleNames: ['ROLE_TENANT_ADMIN'],
  },
];
