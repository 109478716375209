import React, { useEffect, useState } from 'react';
import Panel from '../../../common/Panel/Panel';
import DrawGraph from './DrawGraph';

import ingress_bits_bar from '../../../common/graphs/vega/ingress_bits_bar.json';
import egress_bits_bar from '../../../common/graphs/vega/egress_bits_bar.json';
import ingress_packets_bar from '../../../common/graphs/vega/ingress_packets_bar.json';
import egress_packets_bar from '../../../common/graphs/vega/egress_packets_bar.json';
import ingress_bits_bar_request from '../../../common/graphs/vega/ingress_bits_bar_request.json';
import egress_bits_bar_request from '../../../common/graphs/vega/egress_bits_bar_request.json';
import ingress_packets_bar_request from '../../../common/graphs/vega/ingress_packets_bar_request.json';
import egress_packets_bar_request from '../../../common/graphs/vega/egress_packets_bar_request.json';

const ASTraffic = ({ lte, gte, ntu }) => {
  return (
    <div>
      <div className="row">
        <div className="col-lg-6  col-md-6 col-sm-12 col-xs-12">
          <Panel heading="Source Autonomous Systems (bits/s)">
            <div>
              <DrawGraph
                lte={lte}
                gte={gte}
                ntu={ntu}
                esRequest={ingress_bits_bar_request}
                graphDiv={'#ingress_interface_bits'}
                vegaGraph={ingress_bits_bar}
              />
            </div>
          </Panel>
        </div>
        <div className="col-lg-6  col-md-6 col-sm-12 col-xs-12">
          <Panel heading="Source Autonomous Systems (bits/s)">
            <div>
              <DrawGraph
                lte={lte}
                gte={gte}
                ntu={ntu}
                esRequest={egress_bits_bar_request}
                graphDiv={'#egress_interface_bits'}
                vegaGraph={egress_bits_bar}
              />
            </div>
          </Panel>
        </div>
        <div className="col-lg-6  col-md-6 col-sm-12 col-xs-12">
          <Panel heading="Source Autonomous Systems (bits/s)">
            <div>
              <DrawGraph
                lte={lte}
                gte={gte}
                ntu={ntu}
                esRequest={ingress_packets_bar_request}
                graphDiv={'#autonomous_systems_flow'}
                vegaGraph={ingress_packets_bar}
              />
              v
            </div>
          </Panel>
        </div>
        <div className="col-lg-6  col-md-6 col-sm-12 col-xs-12">
          <Panel heading="Source Autonomous Systems (bits/s)">
            <div>
              <DrawGraph
                lte={lte}
                gte={gte}
                ntu={ntu}
                esRequest={egress_packets_bar_request}
                graphDiv={'#autonomous_systems_flow'}
                vegaGraph={egress_packets_bar}
              />
            </div>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default ASTraffic;
