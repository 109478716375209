import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';

import * as Yup from 'yup';
import Panel from '../../../common/Panel/Panel';
import Alert from '../../../common/AlertControls/Alert';
import { handleCreateNewAlert } from '../../../common/Alerts/CreateNewAlert';
import { handleUpdateAlert } from '../../../common/Alerts/EditAlert';

import {
  createNFVBucket,
  getNFVBucket,
  updateNFVBucket,
} from './api/NFVBucketsApi';

const NFVBucketUpdate = () => {
  const match = useParams();
  const navigate = useNavigate();
  let defaultValues = {
    id: '',
    name: '',
    region: '',
    description: '',
  };
  const [initialData, setInitialData] = useState(defaultValues);
  const [appState, setAppState] = useState({
    loading: true,
  });

  const isNew = !match.id;
  const [alertShow, setAlertShow] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  // let alertShow = false;

  const schema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Too Short!')
      .max(250, 'Too Long!')
      .required('Required'),
    region: Yup.string()
      .min(2, 'Too Short!')
      .max(250, 'Too Long!')
      .required('Required'),
  });

  async function createNewNFVBucket(nfvBucket) {
    const response = await createNFVBucket(nfvBucket).then((res) => res.json());
    if (response.id) {
      handleCreateNewAlert(
        'New nfvBucket "' + nfvBucket.name + '" added successfully!'
      );
      navigate('../');
    }
  }

  async function updateNFVBuckets(nfvBucket) {
    const response = await updateNFVBucket(nfvBucket).then((res) => res.json());

    if (response.id) {
      console.log('respnise id', response.id);
      handleUpdateAlert('nfvBucket', nfvBucket.id);
      navigate('../' + nfvBucket.nfvBucketId);
    }
  }

  function submitForm(formValues) {
    isNew ? createNewNFVBucket(formValues) : updateNFVBuckets(formValues);
  }

  // effect runs on component mount
  useEffect(() => {
    let unmounted = false;
    // simulate async api call with set timeout
    const fetchNFVBucket = async () => {
      try {
        const result = await getNFVBucket(match.id).then((res) => res.json());
        if (!unmounted) {
          if (result.description == null) result.description = '';
          setInitialData(result);
          //reset(result);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (!isNew) fetchNFVBucket();
    setAppState({ loading: false });
    return () => {
      unmounted = true;
    };
  }, []);

  const onSubmit = (data) => {
    alert(JSON.stringify(data));
  };

  return (
    <div>
      <div className="row">
        <div className="col-xl-9">
          {alertShow && (
            <Alert
              alertType="alert-success"
              msg="The nfvBucket has been updated."
            />
          )}

          {!appState.loading && initialData && (
            <Panel heading={initialData.name}>
              <Formik
                enableReinitialize
                initialValues={initialData}
                validationSchema={schema}
                validateOnMount
                onSubmit={submitForm}
              >
                {({ errors, touched, isValid, setFieldValue, values }) => {
                  return (
                    <Form>
                      {!isNew && (
                        <div className="form-group row m-b-15">
                          <label className="col-form-label col-md-3">ID</label>
                          <div className="col-md-9">
                            <Field
                              type="text"
                              name="id"
                              label="id"
                              className="form-control m-b-5"
                              readOnly
                            />
                          </div>
                        </div>
                      )}

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Name <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="name"
                            label="name"
                            className={`form-control m-b-5  ${
                              errors.name && touched.name ? 'is-invalid' : ''
                            }`}
                            placeholder="Enter NFVBucket Name"
                          />
                          {errors.name && touched.name ? (
                            <div className="invalid-feedback">
                              {errors.name}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Region <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="region"
                            label="region"
                            className={`form-control m-b-5  ${
                              errors.region && touched.region
                                ? 'is-invalid'
                                : ''
                            }`}
                            placeholder="Enter NFVBucket Name"
                          />
                          {errors.region && touched.region ? (
                            <div className="invalid-feedback">
                              {errors.region}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Description <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="textarea"
                            as="textarea"
                            name="description"
                            label="description"
                            className={`form-control m-b-5  ${
                              errors.description && touched.description
                                ? 'is-invalid'
                                : ''
                            }`}
                            placeholder="Enter Description"
                          />
                          {errors.description && touched.description ? (
                            <div className="invalid-feedback">
                              {errors.description}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <button
                        type="submit"
                        className="btn btn-primary btn-block btn-lg m-b-5"
                        disabled={!isValid || isSubmitted}
                      >
                        Submit
                      </button>
                    </Form>
                  );
                }}
              </Formik>
            </Panel>
          )}
        </div>
      </div>
    </div>
  );
};

export default NFVBucketUpdate;
