import React, { useEffect, useState, useMemo } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import './servicedefinition.scss';
import * as Yup from 'yup';
import Panel from '../../../common/Panel/Panel';
import Alert from '../../../common/AlertControls/Alert';
import TableWithRowSelect from '../../../common/Table/TableWithRowSelect';
import './servicedefinition.scss';
import { handleCreateNewAlert } from '../../../common/Alerts/CreateNewAlert';
import { handleUpdateAlert } from '../../../common/Alerts/EditAlert';
import { defaultValues } from './defaults';
import ProgressBar from '../../../common/ProgressBar';
import {
  createServiceDefinition,
  getServiceDefinition,
  updateServiceDefinition,
  getServiceTypes,
} from './api/serviceDefinitionApi';
import FormSteps from '../../../common/FormSteps';

const ServiceDefinitionUpdate = (props) => {
  const { providerConfigurationsData, serviceCommandsListData } = props;
  let commandList = [];
  const [selectedRows, setSelectedRows] = useState([]);
  const dosomething = () => {
    console.log('click');
  };
  const filterPEJUNOS = React.useMemo(
    () =>
      serviceCommandsListData.reduce(function (filtered, option) {
        if (option.osType == 'JUNOS' && option.deviceType == 'PE') {
          filtered.push(option);
        }
        return filtered;
      }, []),
    []
  );

  const filterNTUMIKROTIK = React.useMemo(
    () =>
      serviceCommandsListData.reduce(function (filtered, option) {
        if (option.osType == 'MIKROTIK' && option.deviceType == 'NTU') {
          filtered.push(option);
        }
        return filtered;
      }, []),
    []
  );
  const filterNTUJUNOS = React.useMemo(
    () =>
      serviceCommandsListData.reduce(function (filtered, option) {
        if (option.osType == 'JUNOS' && option.deviceType == 'NTU') {
          filtered.push(option);
        }
        return filtered;
      }, []),
    []
  );

  const filterSDWAN = React.useMemo(
    () =>
      serviceCommandsListData.reduce(
        function (filtered, option) {
          if (option.deviceType == 'SD_WAN') {
            filtered.push(option);
          }
          return filtered;
        },

        []
      ),
    []
  );

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'row_id',
        sortable: true,
        Cell: ({ row }) => {
          return (
            <div>
              <Link to={`${row.original.id}`}>{row.original.id}</Link>
            </div>
          );
        },
      },
      {
        Header: 'Name',
        accessor: 'name',
        sortable: true,
      },
      {
        Header: 'On Event',
        accessor: 'onEvent',
        sortable: true,
      },
      {
        Header: 'Routing Type',
        accessor: 'routingType',
        sortable: true,
      },
      {
        Header: 'Devices',
        accessor: (d) =>
          d.circuitTypes?.map((circuit, i) => (
            <button key={i} className="btn btn-light mr-1 mb-1">
              {circuit}
            </button>
          )),

        sortable: true,
      },

      {
        Header: 'Actions',
        accessor: 'actionColumns',
        className: 'align-middle',
        Cell: ({ row }) => {
          return (
            <div className="text-center d-flex">
              <Link
                className="btn btn-icon btn-circle btn-default mr-2"
                to={`${row.original.id}`}
              >
                <i className="fal fa-eye"></i>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  const match = useParams();
  const navigate = useNavigate();
  const [formSteps, setFormSteps] = useState(1);

  const [services, setServices] = useState(null);
  const [initialData, setInitialData] = useState(defaultValues);
  const [appState, setAppState] = useState({
    loading: true,
  });
  const steps = [
    {
      id: 1,
      name: 'Details',
    },
    {
      id: 2,
      name: 'PE [JUNOS]',
    },
    {
      id: 3,
      name: 'NTU [MIKROTIK]',
    },
    {
      id: 4,
      name: 'NTU [JUNOS]',
    },
    {
      id: 5,
      name: 'SD-WAN',
    },
  ];
  const isNew = !match.id;
  const [alertShow, setAlertShow] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  // let alertShow = false;

  async function createNewServiceDefinition(ServiceDefinition) {
    const response = await createServiceDefinition(ServiceDefinition).then(
      (res) => res.json()
    );
    if (response.id) {
      handleCreateNewAlert(
        'New provider definition "' +
          ServiceDefinition.name +
          '" added successfully!'
      );
      navigate('../');
    }
  }

  async function updateServiceDefinitions(ServiceDefinition) {
    const response = await updateServiceDefinition(ServiceDefinition).then(
      (res) => res.json()
    );

    if (response.id) {
      console.log('respnise id', response.id);
      handleUpdateAlert('ServiceDefinition', response.id);
      navigate('../' + response.id);
    }
  }

  function submitForm(formValues) {
    if (formValues.services && formValues.services.length > 0) {
      for (let i = 0; i < formValues.services.length; i++) {
        services.forEach((service) => {
          if (formValues.services[i] == service.code) {
            formValues.services[i] = service;
          }
        });
      }
    }
    isNew
      ? createNewServiceDefinition(formValues)
      : updateServiceDefinitions(formValues);
  }

  const getCommandList = (row) => {
    commandList = row;
    console.log(commandList);
  };
  // effect runs on component mount
  useEffect(() => {
    let unmounted = false;
    const fetchServiceDefinition = async () => {
      try {
        const result = await getServiceDefinition(match.id).then((res) =>
          res.json()
        );
        if (!unmounted) {
          if (result.description == null) result.description = '';
          setInitialData(result);
          //reset(result);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (!isNew) fetchServiceDefinition();
    setAppState({ loading: false });
    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <div>
      {alertShow && (
        <Alert
          alertType="alert-success"
          msg="The ServiceDefinition has been updated."
        />
      )}

      {!appState.loading && initialData && (
        <Panel heading={initialData.name}>
          <Formik
            enableReinitialize
            initialValues={initialData}
            validateOnMount
            onSubmit={submitForm}
          >
            {({ errors, touched, isValid, setFieldValue, values }) => {
              return (
                <Form>
                  <div className="row">
                    <div className="col-md-12">
                      {' '}
                      <ProgressBar steps={steps} activeStep={formSteps} />
                    </div>
                  </div>
                  {formSteps == 1 && (
                    <>
                      <div className="row">
                        <div className="col-md-10 offset-1">
                          {!isNew && (
                            <div className="form-group row m-b-15">
                              <label className="col-form-label col-md-3">
                                ID
                              </label>
                              <div className="col-md-9">
                                <Field
                                  type="text"
                                  name="id"
                                  label="id"
                                  className="form-control m-b-5"
                                  readOnly
                                />
                              </div>
                            </div>
                          )}

                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Name <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                type="text"
                                name="name"
                                label="name"
                                className={`form-control m-b-5  ${
                                  errors.name && touched.name
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                placeholder="Enter Service Name"
                              />
                              {errors.name && touched.name ? (
                                <div className="invalid-feedback">
                                  {errors.name}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Provider Type{' '}
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                as="select"
                                name="providerTypeName"
                                className={`form-control m-b-5  ${
                                  errors.tiles && touched.tiles
                                    ? 'is-invalid'
                                    : ''
                                }`}
                              >
                                {providerConfigurationsData?.map(
                                  (providerType) => (
                                    <option
                                      key={providerType.id}
                                      value={providerType.name}
                                    >
                                      {providerType.name}
                                    </option>
                                  )
                                )}
                              </Field>
                              {errors.tiles && touched.tiles ? (
                                <div className="invalid-feedback">
                                  {errors.tiles}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Service Type{' '}
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                as="select"
                                name="serviceTypeName"
                                className={`form-control m-b-5  ${
                                  errors.tiles && touched.tiles
                                    ? 'is-invalid'
                                    : ''
                                }`}
                              >
                                {['AWS'].map((service, i) => (
                                  <option key={i} value={service}>
                                    {service}
                                  </option>
                                ))}
                              </Field>
                              {errors.tiles && touched.tiles ? (
                                <div className="invalid-feedback">
                                  {errors.tiles}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Creation Type{' '}
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                as="select"
                                name="creationType"
                                className={`form-control m-b-5  ${
                                  errors.tiles && touched.tiles
                                    ? 'is-invalid'
                                    : ''
                                }`}
                              >
                                {['CIRCUITVLAN', 'AUTOGENVLAN'].map(
                                  (ctype, i) => (
                                    <option key={i} value={ctype}>
                                      {ctype}
                                    </option>
                                  )
                                )}
                              </Field>
                              {errors.tiles && touched.tiles ? (
                                <div className="invalid-feedback">
                                  {errors.tiles}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Remote Service Code
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                type="text"
                                name="name"
                                label="name"
                                className={`form-control m-b-5  ${
                                  errors.name && touched.name
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                placeholder="Enter Remote Service Code"
                              />
                              {errors.name && touched.name ? (
                                <div className="invalid-feedback">
                                  {errors.name}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  <div className={`${formSteps == 2 ? '' : 'd-none'}`}>
                    <TableWithRowSelect
                      data={filterPEJUNOS}
                      columns={columns}
                      onRowSelect={getCommandList}
                    />
                  </div>

                  <div className={`${formSteps == 3 ? '' : 'd-none'}`}>
                    <TableWithRowSelect
                      data={filterNTUMIKROTIK}
                      columns={columns}
                      onRowSelect={getCommandList}
                    />
                  </div>

                  <div className={`${formSteps == 4 ? '' : 'd-none'}`}>
                    <TableWithRowSelect
                      data={filterNTUJUNOS}
                      columns={columns}
                      onRowSelect={getCommandList}
                    />
                  </div>

                  <div className={`${formSteps == 5 ? '' : 'd-none'}`}>
                    <TableWithRowSelect
                      data={filterSDWAN}
                      columns={columns}
                      onRowSelect={getCommandList}
                    />
                  </div>

                  <div className=" row ">
                    <div className="col-md-10 offset-1 my-5 text-right">
                      <FormSteps
                        currentStep={formSteps}
                        totalSteps={steps.length}
                        setFormSteps={setFormSteps}
                        isValid={isValid}
                        isSubmitted={isSubmitted}
                      />
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Panel>
      )}
    </div>
  );
};

export default ServiceDefinitionUpdate;
