import React, { useMemo, useState, useEffect } from 'react';
import Table from '../../../common/Table';

import { handleDeleteAlert } from '../../../common/Alerts/DeleteAlert';
const TopApplicationsTable = ({ allApplications, heading }) => {
  const data = allApplications;
  console.log('all devices:', allApplications);

  const columns = useMemo(
    () => [
      {
        Header: 'Application',
        accessor: (d) => {
          return d.key;
        },
        sortable: true,
      },
      {
        Header: 'Total Bytes',
        accessor: (d) => {
          return d.bytesclient.value + d.bytesserver.value;
        },
        sortable: true,
      },
      {
        Header: 'Total Packets',
        accessor: (d) => {
          return d.packetsclient.value + d.packetsserver.value;
        },
        sortable: true,
      },
      {
        Header: 'Bytes Client',
        accessor: (d) => {
          return d.bytesclient.value;
        },
        sortable: true,
      },
      {
        Header: 'Packets Client',
        accessor: (d) => {
          return d.packetsclient.value;
        },
        sortable: true,
      },
      {
        Header: 'Bytes Server',
        accessor: (d) => {
          return d.bytesserver.value;
        },
        sortable: true,
      },
      {
        Header: 'Packets Server',
        accessor: (d) => {
          return d.packetsserver.value;
        },
        sortable: true,
      },
      {
        Header: 'Flow Records',
        accessor: (d) => {
          return d.doc_count;
        },
        sortable: true,
      },
    ],
    []
  );
  useEffect(() => {}, []);

  return <Table data={data} columns={columns} heading="Top Clients" />;
};

export default TopApplicationsTable;
