import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Panel from '../../../common/Panel/Panel';
import { getEmailTemplate } from './api/emailTemplatesApi';
const EmailTemplateDetail = () => {
  const match = useParams();
  const [emailTemplateEntity, setEmailTemplateEntity] = useState(null);
  const [appState, setAppState] = useState({
    loading: false,
  });

  useEffect(() => {
    setAppState({ loading: true });
    const fetchData = async () => {
      try {
        const result = await getEmailTemplate(match.id).then((res) =>
          res.json()
        );
        setEmailTemplateEntity(result);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    setAppState({ loading: false });
  }, []);
  return (
    <div>
      <div className="row">
        <div className="col-xl-6">
          {appState.loading && <p>Loading...</p>}
          {emailTemplateEntity !== null && !appState.loading && (
            <>
              <span>Email Template</span> [
              <b>{emailTemplateEntity.emailTemplateName}</b>]
              <dl className="row">
                <dt class="text-inverse  col-4 text-truncate">
                  <span id="name">
                    <span>Template Name</span>
                  </span>
                </dt>
                <dd class="col-8 text-truncate">
                  {emailTemplateEntity.templateName}
                </dd>
              </dl>
              <Link
                className="btn btn-sm btn-default m-r-5"
                to="/administration/email-templates"
              >
                <i className="fal fa-arrow-left fa-sm  mr-2"></i>
                Back
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmailTemplateDetail;
