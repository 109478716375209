export const getServiceConfigs = () => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/service-configurations/';

  return fetch(baseUrl, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getServiceConfig = (id) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/service-configurations/';

  const requestUrl = `${baseUrl}${id}`;
  return fetch(requestUrl, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const createServiceConfig = (tenant) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/service-configurations/';

  return fetch(baseUrl, {
    method: 'POST',
    body: JSON.stringify(tenant),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const updateServiceConfig = (tenant) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/service-configurations/';

  return fetch(baseUrl, {
    method: 'PUT',
    body: JSON.stringify(tenant),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const deleteServiceConfig = (id) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/service-configurations/';
  const requestUrl = `${baseUrl}${id}`;
  return fetch(requestUrl, {
    method: 'DELETE',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};
