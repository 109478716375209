import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import Panel from '../../../common/Panel/Panel';
import Table from '../../../common/Table';
import { handleDeleteAlert } from '../../../common/Alerts/DeleteAlert';

const organizations = JSON.parse(localStorage.getItem('accountInfo'))?.filter
  .filters;

const NFVBuckets = ({ entities }) => {
  const data = entities;
  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'row_id',
        sortable: true,
        Cell: ({ row }) => {
          return (
            <div>
              <Link to={`${row.original.id}`}>{row.original.id}</Link>
            </div>
          );
        },
      },
      {
        Header: 'Organization',
        accessor: (item) => {
          const { orgName } = organizations.find(
            (org) => org.orgId === item.orgId
          );
          return orgName;
        },
        sortable: true,
      },
      {
        Header: 'Tenant',
        accessor: 'tenantId',
        sortable: true,
      },
      {
        Header: 'Bucket Name',
        accessor: 'name',
        sortable: true,
      },
      {
        Header: 'Region',
        accessor: 'region',
        sortable: true,
      },
      {
        Header: 'Description',
        accessor: 'description',
        sortable: true,
      },

      {
        Header: 'Access Key',
        accessor: 'accessKey',
        sortable: true,
      },

      {
        Header: 'Secret Key',
        accessor: 'secretKey',
        sortable: true,
      },

      {
        Header: '',
        accessor: 'actionColumns',
        className: 'align-middle',
        Cell: ({ row }) => {
          return (
            <div className="text-center d-flex">
              <Link
                className="btn btn-icon btn-circle btn-default mr-2"
                to={`${row.original.id}`}
              >
                <i className="fal fa-eye"></i>
              </Link>
              <Link
                className="btn btn-icon btn-circle btn-success mr-2"
                to={`../edit/${row.original.id}`}
              >
                <i className="fal fa-pencil-alt"></i>
              </Link>
              <button
                className="btn btn-icon btn-circle btn-danger"
                onClick={handleDeleteAlert}
              >
                <i className="fal fa-trash"></i>
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <Panel>
      <Table data={data} columns={columns} />
    </Panel>
  );
};

export default NFVBuckets;
