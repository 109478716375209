import React, { useMemo } from "react";
import { Link } from "react-router-dom";

import Panel from "../../../common/Panel/Panel";
import Table from "../../../common/Table";

import { handleDeleteAlert } from "../../../common/Alerts/DeleteAlert";

const EntityManagers = ({ entities }) => {
  const data = entities;

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "row_id",
        sortable: true,
        Cell: ({ row }) => {
          return (
            <div>
              <Link to={`${row.original.id}`}>{row.original.id}</Link>
            </div>
          );
        },
      },
      {
        Header: "Entity Type",
        accessor: "entityType",
        sortable: true,
      },
      {
        Header: "Description",
        accessor: "description",
        sortable: true,
      },
      {
        Header: "Attributes",
        accessor: (d) =>
          Object.keys(d.attributes)?.map((attributeKey, i) => (
            <button className="btn btn-light mr-1 mb-1" key={i}>
              {`${attributeKey}: ${d.attributes[attributeKey].fieldType}`}
            </button>
          )),
        sortable: true,
      },
      {
        Header: "",
        accessor: "actionColumns",
        className: "align-middle",
        Cell: ({ row }) => {
          return (
            <div className="text-center d-flex">
              <Link
                className="btn btn-icon btn-circle btn-default mr-2"
                to={`${row.original.id}`}
              >
                <i className="fal fa-eye"></i>
              </Link>
              <Link
                className="btn btn-icon btn-circle btn-success mr-2"
                to={`../edit/${row.original.id}`}
              >
                <i className="fal fa-pencil-alt"></i>
              </Link>
              <button
                className="btn btn-icon btn-circle btn-danger"
                onClick={handleDeleteAlert}
              >
                <i className="fal fa-trash"></i>
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <Panel>
      <Table data={data} columns={columns} />
    </Panel>
  );
};

export default EntityManagers;
