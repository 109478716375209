import React, { useMemo, useState, useEffect } from 'react';
import Table from '../../../common/Table';

const SyslogTable = ({ syslog }) => {
  console.log('table: ', syslog);
  const options = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Time',
        accessor: (d) => {
          var syslogDate = new Date(d['_source']['@timestamp']);
          return (
            syslogDate.toLocaleDateString(undefined, options) +
            ' ' +
            syslogDate.toLocaleTimeString([], { hour12: true })
          );
        },
        sortable: true,
      },
      {
        Header: 'Facility',
        accessor: (d) => {
          return d['_source']['syslog_facility'];
        },
        sortable: true,
      },
      {
        Header: 'Severity',
        accessor: (d) => {
          return d['_source']['syslog_severity'];
        },
        sortable: true,
      },
      {
        Header: 'Program',
        accessor: (d) => {
          if (d['_source']['syslog_program'] == 'null') return '-';
          else return d['_source']['syslog_program'];
        },
        sortable: true,
      },
      {
        Header: 'Message',
        accessor: (d) => {
          return d['_source']['syslog_message'];
        },
        sortable: true,
      },
    ],
    []
  );
  if (syslog.length == 0) return <p>No Data</p>;
  else return <Table data={syslog} columns={columns} heading="" />;
};

export default SyslogTable;
