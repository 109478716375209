import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";

import HelpText from "../../../../common/Form/HelpText/HelpText";
import { getProviders } from "../api/peeringExchangeApi";

const initialValues = {
  name: "",
  providerGroup: "MEGAPORT-PERTH",
  providerPortName: "ActivePort - NextDC P1 - DEMO - AU",
  asn: "",
  mac: "",
  rateLimit: 10,
};

export const AddPeeringExchangeServiceForm = ({ show, setShow }) => {
  const [providers, setProviders] = useState([]);

  useEffect(() => {
    const fetcher = async () => {
      try {
        const res = await getProviders().then((res) => res.json());
        setProviders(res.services);
      } catch (error) {
        console.log(error);
      }
    };
    fetcher();
  }, []);

  const onHide = (e) => {
    e.preventDefault();
    setShow(!show);
  };

  const saveAttribute = (values) => {};

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) => {
          saveAttribute(values);
          resetForm();
        }}
      >
        {({ values }) => {
          return (
            <div
              className={`modal fade overflow-auto ${show && "show d-block"}`}
            >
              <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <div className="d-flex w-100 align-items-center justify-content-between">
                      <img
                        src="/assets/img/ntu/PEERING-EXCHANGE-TILE.png"
                        alt=""
                      />
                      <button type="button" className="close" onClick={onHide}>
                        <span aria-hidden="true">
                          <i className="fal fa-times"></i>
                        </span>
                      </button>
                    </div>
                  </div>
                  <div className="modal-body">
                    <Form>
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Service name
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="name"
                            className="form-control m-b-5"
                          />
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Provider
                        </label>
                        <div className="col-md-9">
                          <Field
                            as="select"
                            name="providerGroup"
                            className={`form-control m-b-5`}
                            readOnly
                            disabled
                          >
                            <option value=""></option>
                            {providers.map((provider) => (
                              <option
                                key={provider.id}
                                value={provider.providerName}
                              >
                                {provider.providerName}
                              </option>
                            ))}
                          </Field>
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">Port</label>
                        <div className="col-md-9">
                          <Field
                            readOnly
                            type="text"
                            name="providerPortName"
                            className="form-control m-b-5"
                          />
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">ASN</label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="asn"
                            className="form-control m-b-5"
                          />
                          <HelpText text="ASN cannot be modified for IX services after deployment" />
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">MAC</label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="asn"
                            className="form-control m-b-5"
                          />
                        </div>
                      </div>
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Bandwidth (Mbps)
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="number"
                            name="rateLimit"
                            className="form-control m-b-5"
                          />
                          <HelpText text="Committed Rate Limit Mbit/s" />
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary waves-effect waves-themed"
                          onClick={onHide}
                        >
                          Close
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary waves-effect waves-themed"
                        >
                          Next
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
    </>
  );
};
