import React, { useRef, useLayoutEffect, useEffect, useState } from 'react';
import Panel from '../../../common/Panel/Panel';
import { Vega } from 'react-vega';
import PortsUtilBar from './graphs/ports_util_bar.json';
import PortsUtilBarPackets from './graphs/ports_util_bar_packets.json';

import NTUBytesPie from './graphs/ntu_bytes_pie.json';
import NTUPacketsPie from './graphs/ntu_packets_pie.json';

import PortsBytePie from './graphs/ports_bytes_pie.json';
import PortsPacketsPie from './graphs/ports_packets_pie.json';
import SNMPSystemTemperatureLine from './graphs/snmp/snmp_system_temperature_line.json';
import HeartbeatRTTLine from './graphs/heartbeat_rtt_line.json';
import InterfaceStatsInputBytesLine from './graphs/interface_stats_input_bytes_line.json';
import InterfaceStatsInputPacketsLine from './graphs/interface_stats_input_packets_line.json';

const PortsTraffic = ({
  snmpSystemTemperatureLineData,
  ntuBytesPieData,
  ntuPacketsPieData,
  portUtilData,
  portUtilBarPacketsData,
  portsBytesPieData,
  portsPacketsPieData,
  latencyData,
  interfaceStatsInputBytesLineData,
  interfaceStatsInputPacketsLineData,
}) => {
  const portUtilRef = useRef(null);
  const portUtilBarPacketRef = useRef(null);
  const interfacebytesRef = useRef(null);
  const interfacepacketsRef = useRef(null);
  const latencyRef = useRef(null);

  const [showGraphs, setShowGraphs] = useState(false);
  console.log('LINE DATA TEMP: ', ntuBytesPieData);
  useEffect(() => {
    PortsUtilBar.width = portUtilRef.current.clientWidth - 100;
    PortsUtilBarPackets.width = portUtilBarPacketRef.current.clientWidth - 100;
    InterfaceStatsInputBytesLine.width =
      interfacebytesRef.current.clientWidth - 50;
    InterfaceStatsInputPacketsLine.width =
      interfacepacketsRef.current.clientWidth - 50;
    HeartbeatRTTLine.width = latencyRef.current.clientWidth - 50;
    setShowGraphs(true);
  });

  return (
    <div className="row">
      {snmpSystemTemperatureLineData.table && (
        <div className="col-12">
          <Panel heading="SNMP System Temperature Line">
            <div>
              <Vega
                spec={SNMPSystemTemperatureLine}
                data={snmpSystemTemperatureLineData}
              />
            </div>
          </Panel>
        </div>
      )}
      {ntuBytesPieData.table && (
        <div className="col-sm-6 col-xs-12">
          <Panel heading="NTU Bytes">
            <div>
              <Vega spec={NTUBytesPie} data={ntuBytesPieData} />
            </div>
          </Panel>
        </div>
      )}
      {ntuPacketsPieData.table && (
        <div className="col-sm-6 col-xs-12 ">
          <Panel heading="NTU Packets">
            <div>
              <Vega spec={NTUPacketsPie} data={ntuPacketsPieData} />
            </div>
          </Panel>
        </div>
      )}
      <div className="col-md-6 col-xs-12 col-sm-12" ref={portUtilRef}>
        <Panel
          heading="Port Utilisation - Bytes
"
        >
          <div>
            {showGraphs && <Vega spec={PortsUtilBar} data={portUtilData} />}
          </div>
        </Panel>
      </div>
      <div className="col-md-6 col-xs-12 col-sm-12" ref={portUtilBarPacketRef}>
        <Panel heading="Port Utilisation - Packets">
          <div>
            {showGraphs && (
              <Vega spec={PortsUtilBarPackets} data={portUtilBarPacketsData} />
            )}
          </div>
        </Panel>
      </div>
      <div className="col-md-6 col-sm-6 col-xs-12">
        <Panel heading="Ports Bytes Pie Graph">
          <div>
            {showGraphs && (
              <Vega spec={PortsBytePie} data={portsBytesPieData} />
            )}
          </div>
        </Panel>
      </div>
      <div className="col-md-6 col-sm-6 col-xs-12">
        <Panel heading="Ports Packets Pie Graph">
          <div>
            {showGraphs && (
              <Vega spec={PortsPacketsPie} data={portsPacketsPieData} />
            )}
          </div>
        </Panel>
      </div>
      <div className="col-12" ref={latencyRef}>
        <Panel heading="Management Latency">
          <div>
            {showGraphs && <Vega spec={HeartbeatRTTLine} data={latencyData} />}
          </div>
        </Panel>
      </div>

      <div className="col-md-6 col-sm-12" ref={interfacebytesRef}>
        <Panel heading="Interface Stats Input Bytes Line">
          <div>
            {showGraphs && (
              <Vega
                spec={InterfaceStatsInputBytesLine}
                data={interfaceStatsInputBytesLineData}
              />
            )}
          </div>
        </Panel>
      </div>
      <div className="col-md-6 col-sm-12" ref={interfacepacketsRef}>
        <Panel heading="Interface Stats Input Packets Line">
          <div>
            {showGraphs && (
              <Vega
                spec={InterfaceStatsInputPacketsLine}
                data={interfaceStatsInputPacketsLineData}
              />
            )}
          </div>
        </Panel>
      </div>
    </div>
  );
};

export default PortsTraffic;
