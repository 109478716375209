import React, { useState, useEffect } from 'react';
import Panel from '../../../common/Panel/Panel';
import ContainerDimensions from 'react-container-dimensions';

import applications_top_traffic_bar from '../../../common/graphs/vega/applications/applications_top_traffic_bar.json';
import applications_top_sessions_bar from '../../../common/graphs/vega/applications/applications_top_sessions_bar.json';

import applications_top_packets_bar from '../../../common/graphs/vega/applications/applications_top_packets_bar.json';

import applications_top_traffic_request from '../../../common/graphs/vega/applications/applications_top_traffic_request.json';
import applications_bits_bar from '../../../common/graphs/vega/applications/applications_bits_bar.json';
import applications_bits_bar_request from '../../../common/graphs/vega/applications/applications_bits_bar_request.json';

import application_table_request from '../../../common/graphs/vega/applications/application_table_request.json';
import application_server_packets_bytes_table_request from '../../../common/graphs/vega/applications/application_server_packets_bytes_table_request.json';

import application_client_packets_bytes_table_request from '../../../common/graphs/vega/applications/application_client_packets_bytes_table_request.json';

import DrawGraph from './DrawGraph';
import { getElasticSearchData } from './api/elasticsearch';
import moment from 'moment';
import tz from 'moment-timezone';
import TopNATClientsTable from './TopNATClientsTable';
import TopNATServersTable from './TopNATServersTable';
import TopApplicationsTable from './TopApplicationsTable';
const TopN = ({ lte, gte, ntu }) => {
  //applications_top_traffic_bar, applications_top_packets_bar_c , applications_top_sessions_bar_c,applications_bits_lg_c
  var serial;
  var excludeUnknown = false;
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading1, setIsLoading1] = useState(false);

  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const [
    applications_top_traffic_bar_data,
    set_applications_top_traffic_bar_data,
  ] = useState({});
  const [applications_bits_bar_data, set_applications_bits_bar_data] = useState(
    {}
  );
  const [applicationPacketsBytes, setApplicationPacketsBytes] = useState({});
  const [serverPacketsBytes, setServerPacketsBytes] = useState({});
  const [clientPacketsBytes, setClientPacketsBytes] = useState({});
  var ignore_throttled = 1;
  const getLTE = () => {
    if (lte == null || lte == '') {
      lte = new Date();
      lte = moment();
      lte = lte._d.getTime();
    }
    return lte;
  };
  const getGTE = () => {
    if (gte == null || gte == '') {
      gte = new Date();
      gte = moment().subtract(3, 'hours');
      gte = gte._d.getTime();
    }
    return gte;
  };

  const getIgnoreThrottled = () => {
    var now = new Date();
    //var lte_diff = moment(now).diff(moment(vm.lte), 'days')
    var gte_diff = moment(now).diff(moment(getGTE()), 'months');
    //             console.log("lte_diff = " + lte_diff);
    console.log('gte_diff = ' + gte_diff);
    if (gte_diff > 0) {
      ignore_throttled = 0; // searchable snapshot
    }
    return ignore_throttled;
  };
  const elasticRequest = async (request, div) => {
    request.query.bool.filter.push({
      term: { 'serial-number.keyword': ntu.serialNumber },
    });
    //data.query.bool.must[0].query_string["query"] = "serial-number: " + vm.serial;
    request.query.bool.must[0].range['@timestamp'].gte = getGTE();
    request.query.bool.must[0].range['@timestamp'].lte = getLTE();

    try {
      let response = await getElasticSearchData('search', {
        ignoreThrottled: getIgnoreThrottled(),
        index: 'apptrack-*',
        size: 0,

        body: JSON.stringify(request),
      }).then((res) => res.json());

      if (div == '#applications_top_traffic_bar') {
        set_applications_top_traffic_bar_data(response);
      }
      if (div == '#applications_bits_bar') {
        set_applications_bits_bar_data(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const TableApplications = async () => {
    application_table_request.query.bool.filter.push({
      term: { 'serial-number.keyword': ntu.serialNumber },
    });
    application_table_request.query.bool.filter.push({
      term: { 'apptrack_status.keyword': 'APPTRACK_SESSION_CLOSE' },
    });

    if (excludeUnknown === true) {
      application_table_request.query.bool.must_not.push({
        term: { 'nested-application.keyword': 'UNKNOWN' },
      });
      application_table_request.query.bool.must_not.push({
        term: { 'application.keyword': 'UNKNOWN' },
      });
    }

    application_table_request.query.bool.must[0].range['@timestamp'].gte =
      getGTE();
    application_table_request.query.bool.must[0].range['@timestamp'].lte =
      getLTE();

    try {
      let response = await getElasticSearchData('search', {
        ignoreThrottled: 1,
        index: 'apptrack-*',
        size: 0,

        body: JSON.stringify(application_table_request),
      }).then((res) => res.json());
      console.log(response);

      setApplicationPacketsBytes(response.aggregations.results.buckets);

      setIsLoading1(true);
    } catch (error) {
      console.log(error);
    }
  };

  const TableServerPacketsBytes = async () => {
    application_server_packets_bytes_table_request.query.bool.filter.push({
      term: { 'serial-number.keyword': ntu.serialNumber },
    });
    application_server_packets_bytes_table_request.query.bool.filter.push({
      term: { 'apptrack_status.keyword': 'APPTRACK_SESSION_CLOSE' },
    });

    if (excludeUnknown === true) {
      application_server_packets_bytes_table_request.query.bool.must_not.push({
        term: { 'nested-application.keyword': 'UNKNOWN' },
      });
      application_server_packets_bytes_table_request.query.bool.must_not.push({
        term: { 'application.keyword': 'UNKNOWN' },
      });
    }

    application_server_packets_bytes_table_request.query.bool.must[0].range[
      '@timestamp'
    ].gte = getGTE();
    application_server_packets_bytes_table_request.query.bool.must[0].range[
      '@timestamp'
    ].lte = getLTE();

    try {
      let response = await getElasticSearchData('search', {
        ignoreThrottled: 1,
        index: 'apptrack-*',
        size: 0,

        body: JSON.stringify(application_server_packets_bytes_table_request),
      }).then((res) => res.json());
      console.log(response);

      setServerPacketsBytes(response.aggregations.results.buckets);

      setIsLoading2(true);
    } catch (error) {
      console.log(error);
    }
  };

  const TableClientPacketsBytes = async () => {
    application_client_packets_bytes_table_request.query.bool.filter.push({
      term: { 'serial-number.keyword': ntu.serialNumber },
    });
    application_client_packets_bytes_table_request.query.bool.filter.push({
      term: { 'apptrack_status.keyword': 'APPTRACK_SESSION_CLOSE' },
    });

    if (excludeUnknown === true) {
      application_client_packets_bytes_table_request.query.bool.must_not.push({
        term: { 'nested-application.keyword': 'UNKNOWN' },
      });
      application_client_packets_bytes_table_request.query.bool.must_not.push({
        term: { 'application.keyword': 'UNKNOWN' },
      });
    }

    application_client_packets_bytes_table_request.query.bool.must[0].range[
      '@timestamp'
    ].gte = getGTE();
    application_client_packets_bytes_table_request.query.bool.must[0].range[
      '@timestamp'
    ].lte = getLTE();

    try {
      let response = await getElasticSearchData('search', {
        ignoreThrottled: 1,
        index: 'apptrack-*',
        size: 0,

        body: JSON.stringify(application_client_packets_bytes_table_request),
      }).then((res) => res.json());

      setClientPacketsBytes(response.aggregations.results.buckets);
      setIsLoading3(true);
    } catch (error) {
      console.log(error);
    }
  };

  function handleResize() {
    setIsLoading(true);
    setIsLoading(false);
  }

  useEffect(async () => {
    await elasticRequest(
      applications_top_traffic_request,
      '#applications_top_traffic_bar'
    );

    await elasticRequest(
      applications_bits_bar_request,
      '#applications_bits_bar'
    );

    await TableApplications();
    await TableClientPacketsBytes();
    await TableServerPacketsBytes();
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    setIsLoading(false);
  }, []);
  if (isLoading)
    return (
      <div class="LoadingContainer text-center">
        <p>Loading</p>
      </div>
    );
  else
    return (
      <div className="row">
        <div className="col-md-6 col-sm-12 col-xs-12">
          <Panel
            heading="Top 10 Applications by Bytes
"
          >
            <ContainerDimensions>
              {({ width, height }) => (
                <div>
                  <DrawGraph
                    width={width}
                    height={height}
                    esData={applications_top_traffic_bar_data}
                    graphDiv={'#applications_top_traffic_bar'}
                    vegaGraph={applications_top_traffic_bar}
                  />
                </div>
              )}
            </ContainerDimensions>
          </Panel>
        </div>
        <div className="col-md-6 col-sm-6 col-xs-12">
          <Panel
            heading="Top 10 Applications by Packets
"
          >
            {' '}
            <ContainerDimensions>
              {({ width, height }) => (
                <div>
                  <DrawGraph
                    width={width}
                    height={height}
                    esData={applications_top_traffic_bar_data}
                    graphDiv={'#applications_top_packets_bar'}
                    vegaGraph={applications_top_packets_bar}
                  />
                </div>
              )}
            </ContainerDimensions>
          </Panel>
        </div>
        <div className="col-md-6 col-sm-6 col-xs-12">
          <Panel
            heading="Top 10 Applications by Sessions
"
          >
            <ContainerDimensions>
              {({ width, height }) => (
                <div>
                  <DrawGraph
                    width={width}
                    height={height}
                    esData={applications_top_traffic_bar_data}
                    graphDiv={'#applications_top_sessions_bar'}
                    vegaGraph={applications_top_sessions_bar}
                  />
                </div>
              )}
            </ContainerDimensions>
          </Panel>
        </div>
        <div className="col-md-12 col-sm-12 col-xs-12">
          <Panel heading="Applications bits per second">
            <ContainerDimensions>
              {({ width, height }) => (
                <div>
                  <DrawGraph
                    width={width}
                    height={height}
                    esData={applications_bits_bar_data}
                    graphDiv={'#applications_bits_bar'}
                    vegaGraph={applications_bits_bar}
                  />
                </div>
              )}
            </ContainerDimensions>
          </Panel>
        </div>
        <div className="col-md-12 col-sm-12 col-xs-12">
          <Panel heading="Top Applications">
            {isLoading1 && (
              <TopApplicationsTable allApplications={applicationPacketsBytes} />
            )}
          </Panel>
        </div>
        <div className="col-md-6 col-sm-12 col-xs-12">
          <Panel heading="Top NAT Clients">
            {isLoading3 && (
              <TopNATClientsTable allClients={clientPacketsBytes} />
            )}
          </Panel>
        </div>
        <div className="col-md-6 col-sm-12 col-xs-12">
          <Panel heading="Top NAT Servers">
            {isLoading2 && (
              <TopNATServersTable allServers={serverPacketsBytes} />
            )}
          </Panel>
        </div>
      </div>
    );
};

export default TopN;
