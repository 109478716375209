export const getService = async () => {
  const token = localStorage.getItem("token");
  return fetch(
    `https://test.activeport.com.au/api/tile-configurations/by-name/INTERNET_BREAKOUT?ntuId=18`,
    {
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    }
  );
};

export const getNtuPorts = async () => {
  const token = localStorage.getItem("token");
  return fetch(
    `https://test.activeport.com.au/api/ntu-ports?cacheBuster=1651184598432&isValid=true&ntuId=18&profile=`,
    {
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    }
  );
};
