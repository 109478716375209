import React, { useEffect, useState, useContext } from 'react';
import SettingsContext from '../../../store/settings-context';
import DateTimePicker from './DateTimePicker';

import { Link } from 'react-router-dom';
import { useNavigate, Navigate } from 'react-router-dom';
import Panel from '../../../common/Panel/Panel';
import SyslogTable from './SyslogTable';
import { Vega } from 'react-vega';
import DrawGraph from './DrawGraph';
import { getElasticSearchData } from '../../../common/api/elasticsearch';
import moment from 'moment';
import tz from 'moment-timezone';
import syslogs_severity_request from '../../../common/graphs/vega/syslogs_severity_request.json';
import syslogs_facility_request from '../../../common/graphs/vega/syslogs_facility_request.json';
import syslogs_severity_pie from '../../../common/graphs/vega/syslogs_severity_pie.json';
import syslogs_facility_pie from '../../../common/graphs/vega/syslogs_facility_pie.json';
import syslogs_request from '../../../common/graphs/vega/syslogs_request.json';

const SyslogContainer = () => {
  const settingsCtx = useContext(SettingsContext);

  const nav = useNavigate();
  const [lte, setLte] = useState(null);
  const [gte, setGte] = useState(null);
  const [Severity, setSeverity] = useState('all');
  const [Facility, setFacility] = useState('all');
  const [isTableLoading, setIsTableLoading] = useState('true');
  const [isGraphLoading, setIsGraphLoading] = useState('true');
  const [syslog_search, setSyslog_search] = useState('');
  const [Syslog, setSyslog] = useState([]);
  var Syslogs;
  var totalItemsClient;
  var queryCountClient;
  let NTU = settingsCtx.NTU;
  if (NTU == '' || NTU == null) {
    if (localStorage.getItem('ntu') != '' || localStorage.getItem('ntu')) {
      settingsCtx.NTU = JSON.parse(localStorage.getItem('ntu'));
      NTU = settingsCtx.NTU;
    }
  }

  var excludeUnknown = false;
  var ignore_throttled = 1;
  const [syslogs_severity_pie_data, set_syslogs_severity_pie_data] = useState(
    {}
  );
  var [syslogs_facility_pie_data, set_syslogs_facility_pie_data] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const getDateTime = (gte, lte) => {
    console.log('update DATE TIME');
    setGte(gte._d.getTime());
    setLte(lte._d.getTime());
  };

  const onFacilityChange = (facility) => {
    setFacility(facility);
  };

  const onSeverityChange = (severity) => {
    setSeverity(severity);
  };
  const getLTE = () => {
    if (lte == null || lte == '') {
      var lte_n = new Date();
      lte_n = moment();
      lte_n = lte_n._d.getTime();
    }
    return lte_n;
  };
  const getGTE = () => {
    if (gte == null || gte == '') {
      var gte_n = new Date();
      gte_n = moment().subtract(1, 'hours');
      gte_n = gte_n._d.getTime();
    }
    return gte_n;
  };

  const elasticRequest = async (request, div) => {
    request.query.bool.must[0].query_string['query'] =
      "serial-number: '" + NTU.serialNumber + "'";
    request.query.bool.must[1].range['@timestamp'].gte = getGTE();
    request.query.bool.must[1].range['@timestamp'].lte = getLTE();

    // console.log(request);
    console.log('div:: ', div, request);

    try {
      let response = await getElasticSearchData('search', {
        ignoreThrottled: getIgnoreThrottled(),
        index: 'syslog-*',
        size: 0,

        body: JSON.stringify(request),
      }).then((res) => res.json());
      console.log('Response:', response);
      if (div == '#severity') {
        set_syslogs_severity_pie_data(response);
      }
      if (div == '#facility') {
        set_syslogs_facility_pie_data(response);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getIgnoreThrottled = () => {
    var now = new Date();
    //var lte_diff = moment(now).diff(moment(vm.lte), 'days')
    var gte_diff = moment(now).diff(moment(getGTE()), 'months');
    //             console.log("lte_diff = " + lte_diff);
    console.log('gte_diff = ' + gte_diff);
    if (gte_diff > 0) {
      ignore_throttled = 0; // searchable snapshot
    }
    return ignore_throttled;
  };

  const getSyslogs = async () => {
    var sever = '';
    var facil = '';
    var search = '';
    console.log('S , F', Severity, Facility);
    if (Severity !== '' && Severity !== 'all') {
      sever = " AND syslog_severity: '" + Severity + "'";
    }
    if (Facility !== '' && Facility !== 'all') {
      facil = " AND syslog_facility: '" + Facility + "'";
    }
    if (syslog_search !== '' && syslog_search !== undefined) {
      search = ' AND syslog_message:' + syslog_search + '';
    }
    syslogs_request.query.bool.must[0].query_string['query'] =
      "serial-number: '" + NTU.serialNumber + "'" + facil + sever + search;
    syslogs_request.query.bool.must[1].range['@timestamp'].gte = getGTE();
    syslogs_request.query.bool.must[1].range['@timestamp'].lte = getLTE();

    try {
      let response = await getElasticSearchData('search', {
        ignoreThrottled: getIgnoreThrottled(),
        index: 'syslog-*',
        size: 0,

        body: JSON.stringify(syslogs_request),
      }).then((res) => res.json());
      console.log('Response:', response);
      if (response.hits != undefined) {
        setSyslog(response.hits.hits);
        setIsTableLoading(false);
        //                             console.log('Syslogs: ', vm.Syslogs);
        //  totalItemsClient = response.hits.hits.length;
        // queryCountClient = totalItemsClient;
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(async () => {
    await elasticRequest(syslogs_severity_request, '#severity');
    await elasticRequest(syslogs_facility_request, '#facility');
    await getSyslogs();
    setIsLoading(false);
  }, [Severity, Facility]);
  if (localStorage.getItem('ntu') == '' || !localStorage.getItem('ntu')) {
    return <Navigate to="/" />;
  } else
    return (
      <>
        <ol className="breadcrumb page-breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">ActivePort</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="../dashboard/administration">Dashboard</Link>
          </li>
          <li className="breadcrumb-item">Syslogs</li>
          <li className="position-absolute pos-top pos-right d-none d-sm-block">
            <span className="js-get-date">
              {moment().format('dddd, D MMMM, YYYY')}
            </span>
          </li>
        </ol>
        <div className="subheader">
          <h1 className="subheader-title">
            <i className="subheader-icon fal fa-chart-area" />
            &nbsp;Syslogs
            <small>The Syslogs Dashboard shows Logs from your NTU.</small>
          </h1>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card mb-g">
              <div className="card-body">
                <DateTimePicker
                  getDateTime={getDateTime}
                  facilityChange={onFacilityChange}
                  severityChange={onSeverityChange}
                  Facility={Facility}
                  Severity={Severity}
                />
              </div>
            </div>
          </div>

          <div className="col-lg-12">
            <div>
              <div className="">
                <Panel heading="Syslog Facilities and Severities">
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-xs-12">
                      <div>
                        {isLoading ? (
                          <div>Loading...</div>
                        ) : (
                          !isLoading && (
                            <DrawGraph
                              esData={syslogs_severity_pie_data}
                              graphDiv={'#severity'}
                              vegaGraph={syslogs_severity_pie}
                            />
                          )
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12">
                      <div>
                        {!isLoading && (
                          <DrawGraph
                            esData={syslogs_facility_pie_data}
                            graphDiv={'#facility'}
                            vegaGraph={syslogs_facility_pie}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Panel>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <Panel heading="Syslogs">
              {isTableLoading ? (
                <div>Loading...</div>
              ) : (
                Syslog != [] && <SyslogTable syslog={Syslog} heading="Syslog" />
              )}
            </Panel>
          </div>
        </div>
      </>
    );
};

export default SyslogContainer;
