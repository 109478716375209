import React, { useEffect, useState } from 'react';
import { Vega } from 'react-vega';
import { getElasticSearchData } from './api/elasticsearch';
import moment from 'moment';
import tz from 'moment-timezone';

const DrawGraph = ({ lte, gte, ntu, esRequest, vegaGraph, graphDiv }) => {
  var serial;
  const getLTE = () => {
    if (lte == null || lte == '') {
      lte = new Date();
      lte = moment();
      lte = lte._d.getTime();
    }
    return lte;
  };
  const getGTE = () => {
    if (gte == null || gte == '') {
      gte = new Date();
      gte = moment().subtract(3, 'hours');
      gte = gte._d.getTime();
    }
    return gte;
  };
  const [graphData, setGraphData] = useState({});
  const [showGraph, setShowGraph] = useState(false);
  var ignore_throttled = 1;

  const drawGraph_response = async (response, div) => {
    var sum_rep_tags = 0;

    if (response != null && response.aggregations.results.buckets.length > 0) {
      if (div === '#reputation') {
        for (var x = 0; x < response.aggregations.results.buckets.length; x++) {
          if (response.aggregations.results.buckets[x].ip_rep !== undefined) {
            if (
              response.aggregations.results.buckets[x].ip_rep.buckets.length > 0
            ) {
              sum_rep_tags +=
                response.aggregations.results.buckets[x].ip_rep.buckets.length;
            }
            console.log('sum_rep_tags: ', sum_rep_tags);
          }
        }

        if (
          response.aggregations.results.buckets[0].ip_rep.buckets.length == 0
        ) {
          setGraphData({});
          setShowGraph(false);
        }
      } else if (div === '#internet_geoip_src') {
        //console.log(JSON.stringify(response.aggregations));
        if (
          response.aggregations.results.buckets[0].results_dst.buckets.length ==
          0
        ) {
          console.log('NO DST GEO DATA!');
          // HACK ALERT - assume 1st entry is "server" lol
          response.aggregations.results.buckets[0].results_dst.buckets =
            response.aggregations.results_client.buckets;
        }
        setGraphData({ table: response.aggregations.results.buckets });
        console.log('result gtom graph', div);
        setShowGraph(true);
      } else {
        let result = response.aggregations.results.buckets;

        setGraphData({ table: response.aggregations.results.buckets });
        console.log('result gtom graph', div);
        setShowGraph(true);
      }
    }
  };

  const drawGraph = async (request, div) => {
    serial = ntu.serialNumber;
    if (ntu.mode == 'VIRTUAL') {
      serial = ntu.peSerialNumber;
    }
    request.query.bool.filter.push({ term: { 'serial-number': serial } });
    //data.query.bool.must[0].query_string["query"] = "serial-number: " + vm.serial;
    request.query.bool.must[0].range['@timestamp'].gte = getGTE();
    request.query.bool.must[0].range['@timestamp'].lte = getLTE();

    try {
      let response = await getElasticSearchData('search', {
        ignoreThrottled: ignore_throttled,
        index: 'elastiflow-3.5.1-*',
        size: 0,

        body: JSON.stringify(request),
      }).then((res) => res.json());
      console.log(response);

      drawGraph_response(response, div);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    drawGraph(esRequest, graphDiv);
  }, []);
  if (showGraph) {
    return <Vega spec={vegaGraph} data={graphData} />;
  } else {
    if (graphData != {}) {
      return <p>No Data</p>;
    } else return <p>Loading...</p>;
  }
};

export default DrawGraph;
