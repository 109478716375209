import React, { useState, useEffect } from 'react';
import Panel from '../../../common/Panel/Panel';
import DrawGraph from './DrawGraph';
import { getElasticSearchData } from './api/elasticsearch';
import moment from 'moment';
import tz from 'moment-timezone';
import application_zones_traffic_request from '../../../common/graphs/vega/applications/application_zones_traffic_request.json';
import application_zone_bytes_pie from '../../../common/graphs/vega/applications/application_zone_bytes_pie.json';
import application_zone_packets_pie from '../../../common/graphs/vega/applications/application_zone_packets_pie.json';
import application_zones_table_request from '../../../common/graphs/vega/applications/application_zones_table_request.json';
import ZonesTable from './ZonesTable';

const Zones = ({ lte, gte, ntu }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(false);
  const excludeUnknown = false;
  const [application_zone_bytes_data, set_application_zone_bytes_data] =
    useState({});
  const [zone_packets_bytes_data, set_zone_packets_bytes_data] = useState({});
  var ignore_throttled = 1;
  const getLTE = () => {
    if (lte == null || lte == '') {
      lte = new Date();
      lte = moment();
      lte = lte._d.getTime();
    }
    return lte;
  };
  const getGTE = () => {
    if (gte == null || gte == '') {
      gte = new Date();
      gte = moment().subtract(3, 'hours');
      gte = gte._d.getTime();
    }
    return gte;
  };

  const getIgnoreThrottled = () => {
    var now = new Date();
    //var lte_diff = moment(now).diff(moment(vm.lte), 'days')
    var gte_diff = moment(now).diff(moment(getGTE()), 'months');
    //             console.log("lte_diff = " + lte_diff);
    console.log('gte_diff = ' + gte_diff);
    if (gte_diff > 0) {
      ignore_throttled = 0; // searchable snapshot
    }
    return ignore_throttled;
  };

  const elasticRequest = async (request, div) => {
    request.query.bool.filter.push({
      term: { 'serial-number.keyword': ntu.serialNumber },
    });
    //data.query.bool.must[0].query_string["query"] = "serial-number: " + vm.serial;
    request.query.bool.must[0].range['@timestamp'].gte = getGTE();
    request.query.bool.must[0].range['@timestamp'].lte = getLTE();

    try {
      let response = await getElasticSearchData('search', {
        ignoreThrottled: getIgnoreThrottled(),
        index: 'apptrack-*',
        size: 0,

        body: JSON.stringify(request),
      }).then((res) => res.json());

      if (div == '#application_zone_bytes') {
        set_application_zone_bytes_data(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function handleResize() {
    setIsLoading(true);
    setIsLoading(false);
  }

  const TableZones = async () => {
    application_zones_table_request.query.bool.filter.push({
      term: { 'serial-number.keyword': ntu.serialNumber },
    });
    application_zones_table_request.query.bool.filter.push({
      term: { 'apptrack_status.keyword': 'APPTRACK_SESSION_CLOSE' },
    });

    if (excludeUnknown === true) {
      application_zones_table_request.query.bool.must_not.push({
        term: { 'nested-application.keyword': 'UNKNOWN' },
      });
      application_zones_table_request.query.bool.must_not.push({
        term: { 'application.keyword': 'UNKNOWN' },
      });
    }

    application_zones_table_request.query.bool.must[0].range['@timestamp'].gte =
      getGTE();
    application_zones_table_request.query.bool.must[0].range['@timestamp'].lte =
      getLTE();

    try {
      let response = await getElasticSearchData('search', {
        ignoreThrottled: getIgnoreThrottled(),
        index: 'apptrack-*',
        size: 0,
        body: JSON.stringify(application_zones_table_request),
      }).then((res) => res.json());
      console.log('zone table');
      set_zone_packets_bytes_data(response.aggregations.results.buckets);
      setIsLoading2(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(async () => {
    // application_zone_packets also for this

    await elasticRequest(
      application_zones_traffic_request,
      '#application_zone_bytes'
    );

    await TableZones();

    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    setIsLoading(false);
  }, []);

  if (isLoading)
    return (
      <div class="LoadingContainer text-center">
        <p>Loading</p>
      </div>
    );
  else
    return (
      <div className="row">
        <div className="col-md-6 col-sm-12 col-xs-12">
          <Panel heading="Top Zones Bytes">
            <div>
              <DrawGraph
                esData={application_zone_bytes_data}
                graphDiv={'#application_zone_bytes'}
                vegaGraph={application_zone_bytes_pie}
              />
            </div>
          </Panel>
        </div>

        <div className="col-md-6 col-sm-12 col-xs-12">
          <Panel heading="Top Zones Bytes">
            <div>
              <DrawGraph
                esData={application_zone_bytes_data}
                graphDiv={'#application_zone_bytes'}
                vegaGraph={application_zone_packets_pie}
              />
            </div>
          </Panel>
        </div>
        <div className="col-md-6 col-sm-12 col-xs-12">
          <Panel heading="Top Applications by Zones">
            {isLoading2 && <ZonesTable allData={zone_packets_bytes_data} />}
          </Panel>
        </div>
      </div>
    );
};

export default Zones;
