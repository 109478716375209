import React from 'react';

const FooterModal = () => {
  return (
    <div>
      <div
        className="modal fade modal-backdrop-transparent"
        id="modal-shortcut"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="modal-shortcut"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-top modal-transparent"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-body">
              <ul className="app-list w-auto h-auto p-0 text-left">
                <li>
                  <a
                    href="intel_introduction.html"
                    className="app-list-item text-white border-0 m-0"
                  >
                    <div className="icon-stack">
                      <i className="base base-7 icon-stack-3x opacity-100 color-primary-500 " />
                      <i className="base base-7 icon-stack-2x opacity-100 color-primary-300 " />
                      <i className="fal fa-home icon-stack-1x opacity-100 color-white" />
                    </div>
                    <span className="app-list-name">Home</span>
                  </a>
                </li>
                <li>
                  <a
                    href="page_inbox_general.html"
                    className="app-list-item text-white border-0 m-0"
                  >
                    <div className="icon-stack">
                      <i className="base base-7 icon-stack-3x opacity-100 color-success-500 " />
                      <i className="base base-7 icon-stack-2x opacity-100 color-success-300 " />
                      <i className="ni ni-envelope icon-stack-1x text-white" />
                    </div>
                    <span className="app-list-name">Inbox</span>
                  </a>
                </li>
                <li>
                  <a
                    href="intel_introduction.html"
                    className="app-list-item text-white border-0 m-0"
                  >
                    <div className="icon-stack">
                      <i className="base base-7 icon-stack-2x opacity-100 color-primary-300 " />
                      <i className="fal fa-plus icon-stack-1x opacity-100 color-white" />
                    </div>
                    <span className="app-list-name">Add More</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <p id="js-color-profile" className="d-none">
          <span className="color-primary-50" />
          <span className="color-primary-100" />
          <span className="color-primary-200" />
          <span className="color-primary-300" />
          <span className="color-primary-400" />
          <span className="color-primary-500" />
          <span className="color-primary-600" />
          <span className="color-primary-700" />
          <span className="color-primary-800" />
          <span className="color-primary-900" />
          <span className="color-info-50" />
          <span className="color-info-100" />
          <span className="color-info-200" />
          <span className="color-info-300" />
          <span className="color-info-400" />
          <span className="color-info-500" />
          <span className="color-info-600" />
          <span className="color-info-700" />
          <span className="color-info-800" />
          <span className="color-info-900" />
          <span className="color-danger-50" />
          <span className="color-danger-100" />
          <span className="color-danger-200" />
          <span className="color-danger-300" />
          <span className="color-danger-400" />
          <span className="color-danger-500" />
          <span className="color-danger-600" />
          <span className="color-danger-700" />
          <span className="color-danger-800" />
          <span className="color-danger-900" />
          <span className="color-warning-50" />
          <span className="color-warning-100" />
          <span className="color-warning-200" />
          <span className="color-warning-300" />
          <span className="color-warning-400" />
          <span className="color-warning-500" />
          <span className="color-warning-600" />
          <span className="color-warning-700" />
          <span className="color-warning-800" />
          <span className="color-warning-900" />
          <span className="color-success-50" />
          <span className="color-success-100" />
          <span className="color-success-200" />
          <span className="color-success-300" />
          <span className="color-success-400" />
          <span className="color-success-500" />
          <span className="color-success-600" />
          <span className="color-success-700" />
          <span className="color-success-800" />
          <span className="color-success-900" />
          <span className="color-fusion-50" />
          <span className="color-fusion-100" />
          <span className="color-fusion-200" />
          <span className="color-fusion-300" />
          <span className="color-fusion-400" />
          <span className="color-fusion-500" />
          <span className="color-fusion-600" />
          <span className="color-fusion-700" />
          <span className="color-fusion-800" />
          <span className="color-fusion-900" />
        </p>
      </div>
    </div>
  );
};

export default FooterModal;
