import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';

import * as Yup from 'yup';
import Panel from '../../../common/Panel/Panel';
import Alert from '../../../common/AlertControls/Alert';
import { handleCreateNewAlert } from '../../../common/Alerts/CreateNewAlert';
import { handleUpdateAlert } from '../../../common/Alerts/EditAlert';
import { MyTextArea } from '../../../common/Form/FormFields';

import {
  createServiceCommand,
  getServiceCommand,
  updateServiceCommand,
} from './api/ServiceCommandsApi';

const ServiceCommandUpdate = () => {
  const match = useParams();
  const navigate = useNavigate();
  let defaultValues = {
    id: '',
    name: '',
    command: '',
    onEvent: '',
    circuitTypes: [],
    deviceType: '',
    osType: '',
    routingType: '',
    tag: '',
  };
  const [initialData, setInitialData] = useState(defaultValues);
  const [appState, setAppState] = useState({
    loading: true,
  });

  const isNew = !match.id;
  const [alertShow, setAlertShow] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  // let alertShow = false;

  const schema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Too Short!')
      .max(250, 'Too Long!')
      .required('Required'),
    command: Yup.string().required('Required'),
    onEvent: Yup.string()
      .min(1, 'Too Short!')
      .max(250, 'Too Long!')
      .required('Required'),
    circuitTypes: Yup.array(),
    deviceType: Yup.string()
      .min(1, 'Too Short!')
      .max(250, 'Too Long!')
      .required('Required'),
    osType: Yup.string()
      .min(1, 'Too Short!')
      .max(250, 'Too Long!')
      .required('Required'),
    routingType: Yup.string()
      .min(1, 'Too Short!')
      .max(250, 'Too Long!')
      .required('Required'),
  });

  async function createNewServiceCommand(serviceCommand) {
    const response = await createServiceCommand(serviceCommand).then((res) =>
      res.json()
    );
    if (response.id) {
      handleCreateNewAlert(
        'New serviceCommand "' + serviceCommand.name + '" added successfully!'
      );
      navigate('../');
    }
  }

  async function updateServiceCommands(serviceCommand) {
    const response = await updateServiceCommand(serviceCommand).then((res) =>
      res.json()
    );
    if (response.id) {
      handleUpdateAlert('serviceCommand', response.id);
      navigate('../' + response.id);
    }
  }

  function submitForm(formValues) {
    isNew
      ? createNewServiceCommand(formValues)
      : updateServiceCommands(formValues);
  }

  // effect runs on component mount
  useEffect(() => {
    let unmounted = false;
    // simulate async api call with set timeout

    const fetchServiceCommand = async () => {
      try {
        const result = await getServiceCommand(match.id).then((res) =>
          res.json()
        );
        if (!unmounted) {
          console.log('result: ', result);
          if (result.description == null) result.description = '';
          setInitialData(result);
          //reset(result);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (!isNew) fetchServiceCommand();
    setAppState({ loading: false });
    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-xl-12">
          {alertShow && (
            <Alert
              alertType="alert-success"
              msg="The serviceCommand has been updated."
            />
          )}

          {!appState.loading && initialData && (
            <Panel heading={initialData.name}>
              <Formik
                enableReinitialize
                initialValues={initialData}
                validationSchema={schema}
                validateOnMount
                onSubmit={submitForm}
              >
                {({ errors, touched, isValid, setFieldValue, values }) => {
                  console.log('dd', values);
                  return (
                    <Form>
                      {!isNew && (
                        <div className="form-group row m-b-15">
                          <label className="col-form-label col-md-3">ID</label>
                          <div className="col-md-9">
                            <Field
                              type="text"
                              name="id"
                              label="id"
                              className="form-control m-b-5"
                              readOnly
                            />
                          </div>
                        </div>
                      )}
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Name <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="name"
                            label="name"
                            className={`form-control m-b-5  ${
                              errors.name && touched.name ? 'is-invalid' : ''
                            }`}
                            placeholder="Enter Service Command Name"
                          />
                          {errors.name && touched.name ? (
                            <div className="invalid-feedback">
                              {errors.name}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Command <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="command"
                            label="command"
                            className={`form-control m-b-5  ${
                              errors.command && touched.command
                                ? 'is-invalid'
                                : ''
                            }`}
                            placeholder="Enter Service Command Command"
                          />
                          {errors.command && touched.command ? (
                            <div className="invalid-feedback">
                              {errors.command}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <MyTextArea
                        label="Command"
                        name="command"
                        rows="10"
                        placeholder="Enter your command"
                      />

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          On Event
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            as="select"
                            name="onEvent"
                            className={`form-control m-b-5  ${
                              errors.onEvent && touched.onEvent
                                ? 'is-invalid'
                                : ''
                            }`}
                          >
                            {[
                              'CREATE_SERVICE',
                              'DISABLE_SERVICE_FROM_NTU',
                              'DELETE_SERVICE',
                              'UPDATE_POLICER',
                              'UPDATE_PORT',
                              'UPDATE_SCHEDULE_POLICER',
                            ].map((event, i) => (
                              <option key={i} value={event}>
                                {event}
                              </option>
                            ))}
                          </Field>
                          {errors.onEvent && touched.onEvent ? (
                            <div className="invalid-feedback">
                              {errors.onEvent}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Types
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            as="select"
                            multiple
                            name="onEvent"
                            className={`form-control m-b-5  ${
                              errors.circuitTypes && touched.circuitTypes
                                ? 'is-invalid'
                                : ''
                            }`}
                          >
                            {[
                              'CIRCUIT',
                              'ROUTING_ROUTE',
                              'ROUTING_NAT',
                              'VLAN',
                            ].map((event, i) => (
                              <option key={i} value={event}>
                                {event}
                              </option>
                            ))}
                          </Field>
                          {errors.circuitTypes && touched.circuitTypes ? (
                            <div className="invalid-feedback">
                              {errors.circuitTypes}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Device Type
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="deviceType"
                            label="deviceType"
                            className={`form-control m-b-5  ${
                              errors.deviceType && touched.deviceType
                                ? 'is-invalid'
                                : ''
                            }`}
                            placeholder="Enter Service Command Device Type"
                          />
                          {errors.deviceType && touched.deviceType ? (
                            <div className="invalid-feedback">
                              {errors.deviceType}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          OS Type
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="osType"
                            label="osType"
                            className={`form-control m-b-5  ${
                              errors.osType && touched.osType
                                ? 'is-invalid'
                                : ''
                            }`}
                            placeholder="Enter Service Command Device Type"
                          />
                          {errors.osType && touched.osType ? (
                            <div className="invalid-feedback">
                              {errors.osType}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Routing Type
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="routingType"
                            label="routingType"
                            className={`form-control m-b-5  ${
                              errors.routingType && touched.routingType
                                ? 'is-invalid'
                                : ''
                            }`}
                            placeholder="Enter Service Command Routing Type"
                          />
                          {errors.routingType && touched.routingType ? (
                            <div className="invalid-feedback">
                              {errors.routingType}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Tag
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="tag"
                            label="tag"
                            className={`form-control m-b-5  ${
                              errors.tag && touched.tag ? 'is-invalid' : ''
                            }`}
                            placeholder="Enter Service Command Routing Type"
                          />
                          {errors.tag && touched.tag ? (
                            <div className="invalid-feedback">{errors.tag}</div>
                          ) : null}
                        </div>
                      </div>

                      <button
                        type="submit"
                        className="btn btn-primary btn-block btn-lg m-b-5"
                        disabled={!isValid || isSubmitted}
                      >
                        Submit
                      </button>
                    </Form>
                  );
                }}
              </Formik>
            </Panel>
          )}
        </div>
      </div>
    </div>
  );
};

export default ServiceCommandUpdate;
