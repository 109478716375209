import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import Panel from '../../../common/Panel/Panel';
import Table from '../../../common/Table';
import { handleDeleteAlert } from '../../../common/Alerts/DeleteAlert';

const NFVConfigs = ({ entities }) => {
  const data = entities;

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'row_id',
        sortable: true,
        Cell: ({ row }) => {
          return (
            <div>
              <Link to={`${row.original.id}`}>{row.original.id}</Link>
            </div>
          );
        },
      },
      {
        Header: 'Name',
        accessor: 'name',
        sortable: true,
      },
      {
        Header: 'Version',
        accessor: 'version',
        sortable: true,
      },
      {
        Header: 'Build',
        accessor: 'buildVersion',
        sortable: true,
      },
      {
        Header: 'Storage Path',
        accessor: 'storage_pool',
        sortable: true,
      },
      {
        Header: 'Agent Source',
        accessor: 'agentUrl',
        sortable: true,
      },
      {
        Header: 'Proxy Tunnel',
        accessor: 'proxyIp',
        sortable: true,
      },
      {
        Header: '',
        accessor: 'actionColumns',
        className: 'align-middle',
        Cell: ({ row }) => {
          return (
            <div className="text-center d-flex">
              <Link
                className="btn btn-icon btn-circle btn-success mr-2"
                to={`../edit/${row.original.id}`}
              >
                <i className="fal fa-pencil-alt"></i>
              </Link>
              <button
                className="btn btn-icon btn-circle btn-danger"
                onClick={handleDeleteAlert}
              >
                <i className="fal fa-trash"></i>
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <Panel>
      <Table data={data} columns={columns} />
    </Panel>
  );
};

export default NFVConfigs;
