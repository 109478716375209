/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext } from 'react';
import MenuItem from './MenuItem';

import AuthContext from '../../store/auth-context';

const SidebarMenu = (props) => {
  const account = JSON.parse(localStorage.getItem('accountInfo'));

  let ntu = localStorage.getItem('ntu') || '';
  if (ntu != '') ntu = JSON.parse(ntu);
  const authCtx = useContext(AuthContext);
  const [clickedItem, setClickedItem] = useState(
    localStorage.getItem('menuItem')
  );

  const [menu, setMenu] = useState('analytics');

  const setActiveItem = (itemClicked) => {
    localStorage.setItem('menuItem', itemClicked);
    localStorage.removeItem('subMenuItem');
    setClickedItem(itemClicked);
  };

  const AnalyticsMenuList = [
    {
      id: 1,
      title: 'Dashboards',
      selected: false,
      key: 'dashboards',
      link: '/dashboard/provisioning',
      icon: 'fa-tachometer-fast',
      hasAuth: ['ROLE_USER'],
      conditions: () => {
        return true;
      },
      subMenu: [
        {
          id: 1.1,
          title: 'Provisioning',
          selected: false,
          link: '/dashboard/provisioning',
          key: 'provisioning',
          hasAuth: [''],
          conditions: () => {
            if (ntu == '') return false;
            return ntu != '';
          },
        },
        {
          id: 1.2,
          title: 'Administration',
          selected: false,
          link: '/dashboard/administration',
          key: 'administration',
          hasAuth: ['ROLE_TENANT_ADMIN', 'ROLE_ORG_ADMIN', 'ROLE_DEVELOPER'],
          conditions: () => {
            return true;
          },
        },
        {
          id: 1.3,
          title: 'Elastic',
          selected: false,
          link: '/dashboard/elastic',
          key: 'elastic',
          hasAuth: ['ROLE_ADMIN'],
          conditions: () => {
            return true;
          },
        },
        {
          id: 1.4,
          title: 'Analytics',
          selected: false,
          link: '/dashboard/analytics',
          key: 'analytics',
          hasAuth: ['ROLE_USER'],
          conditions: () => {
            if (ntu == '') return false;
            return ntu != '' && ntu.mode != 'SD_WAN';
          },
        },
        {
          id: 1.5,
          title: 'Analytics',
          selected: false,
          link: '/dashboard/sdwan',
          key: 'analytics-sdwan',
          hasAuth: ['ROLE_ORG_ADMIN'],
          conditions: () => {
            if (ntu == '') return false;
            return ntu != '' && ntu.mode == 'SD_WAN' && account.org.hasSdWan;
          },
        },
        {
          id: 1.6,
          title: 'Internet',
          selected: false,
          link: '/dashboard/internet',
          key: 'Internet',
          hasAuth: ['ROLE_USER'],
          conditions: () => {
            if (ntu == '') return false;
            return ntu.has_netflow != null && ntu.has_netflow == true;
          },
        },
        {
          id: 1.7,
          title: 'Applications',
          selected: false,
          link: '/dashboard/applications',
          key: 'Applications',
          hasAuth: ['ROLE_USER'],
          conditions: () => {
            if (ntu == '') return false;
            return ntu.has_apptrack != null && ntu.has_apptrack == true;
          },
        },
        {
          id: 1.8,
          title: 'Syslogs',
          selected: false,
          link: '/dashboard/syslogs',
          key: 'Syslogs',
          hasAuth: ['ROLE_USER'],
          conditions: () => {
            if (ntu == '') return false;
            return ntu != '' && ntu.mode != 'VIRTUAL' && ntu.mode != 'SD_WAN';
          },
        },
        {
          id: 1.9,
          title: 'Uptime',
          selected: false,
          link: '/dashboard/uptime',
          key: 'uptime',
          hasAuth: ['ROLE_ORG_ADMIN'],
          conditions: () => {
            return true;
          },
        },
      ],
    },
    {
      id: 2,
      title: 'Invoice',
      selected: false,
      key: 'invoice',
      link: 'invoice',
      icon: 'fa-file-invoice',
      hasAuth: ['ROLE_USER'],
      conditions: () => {
        return true;
      },
    },
    {
      id: 3,
      title: 'Developer',
      selected: false,
      key: 'Developer',
      link: '/docs',
      icon: 'fa-brackets-curly',
      hasAuth: ['ROLE_DEVELOPER'],
      conditions: () => {
        return true;
      },
      subMenu: [
        {
          id: 3.1,
          title: 'API Docs',
          selected: false,
          link: '/docs',
          key: 'API Docs',
          hasAuth: ['ROLE_DEVELOPER'],
          conditions: () => {
            return true;
          },
        },
      ],
    },
    {
      id: 4,
      title: 'Reporting',
      selected: false,
      key: 'Reporting',
      link: '/system',
      icon: 'fa-cogs',
      hasAuth: ['ROLE_ADMIN'],
      conditions: () => {
        return true;
      },
      subMenu: [
        {
          id: 4.1,
          title: 'Subnet Allocation',
          selected: false,
          key: 'Subnet Allocation',
          link: '/settings/subnet-allocations',
          hasAuth: ['ROLE_ADMIN'],
          conditions: () => {
            return true;
          },
        },
        {
          id: 4.2,
          title: 'Logs',
          selected: false,
          key: 'Logs',
          link: '/settings/logs',
          hasAuth: ['ROLE_ADMIN'],
          conditions: () => {
            return true;
          },
        },
        {
          id: 4.3,
          title: 'Audits',
          selected: false,
          key: 'Audits',
          link: '/settings/entity-audits',
          hasAuth: ['ROLE_ADMIN'],
          conditions: () => {
            return true;
          },
        },
        {
          id: 4.4,
          title: 'Notification Event',
          selected: false,
          key: 'Notification Event',
          link: '/settings/notification-events',
          hasAuth: ['ROLE_USER'],
          conditions: () => {
            return true;
          },
        },
        {
          id: 4.5,
          title: 'Jobs',
          selected: false,
          key: 'Jobs',
          link: '/settings/config-jobs',
          hasAuth: ['ROLE_ORG_ADMIN'],
          conditions: () => {
            return true;
          },
        },
        {
          id: 4.6,
          title: 'Health',
          selected: false,
          key: 'Health',
          link: '/settings/health',
          hasAuth: ['ROLE_ADMIN'],
          conditions: () => {
            return true;
          },
        },
        {
          id: 4.7,
          title: 'Service Report',
          selected: false,
          key: 'Service Report',
          link: '/settings/service-reports',
          hasAuth: ['ROLE_ORG_ADMIN,ROLE_BILLING'],
          conditions: () => {
            return true;
          },
        },
        {
          id: 4.8,
          title: 'NTU Ports',
          selected: false,
          key: 'NTU Ports',
          link: '/settings/ntu-ports',
          hasAuth: ['ROLE_ORG_ADMIN'],
          conditions: () => {
            return true;
          },
        },
      ],
    },
    {
      id: 5,
      title: 'Billing',
      selected: false,
      key: 'Billing',
      link: '/system/billing-systems',
      icon: 'fa-cogs',
      hasAuth: ['ROLE_ORG_ADMIN'],
      conditions: () => {
        return true;
      },
      subMenu: [
        {
          id: 5.1,
          title: 'Billing System',
          selected: false,
          key: 'Billing System',
          link: '/system/billing-systems',
          hasAuth: ['ROLE_ORG_ADMIN'],
          conditions: () => {
            return true;
          },
        },
        {
          id: 5.2,
          title: 'Xero Accounts',
          selected: false,
          key: 'Xero Accounts',
          link: '/system/tenant-billings',
          hasAuth: ['ROLE_ADMIN'],
          conditions: () => {
            return true;
          },
        },
        {
          id: 5.3,
          title: 'Stripe Setup',
          selected: false,
          key: 'Stripe Setup',
          link: '/system/tenant-billings',
          hasAuth: ['ROLE_ADMIN'],
          conditions: () => {
            return true;
          },
        },
        {
          id: 5.4,
          title: 'CSV Account',
          selected: false,
          key: 'CSV Account',
          link: '/system/csv-accounts',
          hasAuth: ['ROLE_ADMIN'],
          conditions: () => {
            return true;
          },
        },
        {
          id: 5.5,
          title: 'Inventory Items',
          selected: false,
          link: '/administration/inventory-items',
          key: 'inventory items',
          hasAuth: ['ROLE_ORG_ADMIN'],
          conditions: () => {
            return true;
          },
        },
        {
          id: 5.6,
          title: 'Billing Check',
          selected: false,
          link: '/administration/billing',
          key: 'billing check',
          hasAuth: ['ROLE_ORG_ADMIN'],
          conditions: () => {
            return true;
          },
        },
      ],
    },
  ];

  const NetworkingMenuList = [
    {
      id: 1,
      title: 'Provider',
      selected: false,
      key: 'provider',
      link: '/settings/provider-config',
      icon: 'fa-tachometer-fast',
      hasAuth: ['ROLE_ORG_ADMIN'],
      conditions: () => {
        return true;
      },
      subMenu: [
        {
          id: 1.1,
          title: 'Provider Configuration',
          selected: false,
          link: '/settings/provider-config',
          key: 'provider-config',
          hasAuth: ['ROLE_ORG_ADMIN'],
          conditions: () => {
            return true;
          },
        },
        {
          id: 1.1,
          title: 'Provider Definition',
          selected: false,
          link: '/system/provider-configurations',
          key: 'provider-def',
          hasAuth: ['ROLE_ADMIN'],
          conditions: () => {
            return true;
          },
        },
        {
          id: 1.2,
          title: 'Provider Ports',
          selected: false,
          link: '/settings/provider-ports',
          key: 'provider-ports',
          hasAuth: ['ROLE_ORG_ADMIN'],
          conditions: () => {
            return true;
          },
        },
        {
          id: 1.3,
          title: 'Provider Credentials',
          link: '/settings/provider-credentials',
          selected: false,
          key: 'provider credentials',
          hasAuth: ['ROLE_ORG_ADMIN'],
          conditions: () => {
            return true;
          },
        },
      ],
    },
    {
      id: 2,
      title: 'Service',
      selected: false,
      key: 'service',
      link: '/system/service-code',
      icon: 'fa-tachometer-fast',
      hasAuth: ['ROLE_ORG_ADMIN'],
      conditions: () => {
        return true;
      },
      subMenu: [
        {
          id: 2.1,
          title: 'Service Definition',
          selected: false,
          key: 'service definition',
          link: '/system/service-code',
          hasAuth: ['ROLE_ADMIN'],
          conditions: () => {
            return true;
          },
        },
        {
          id: 2.1,
          title: 'Service Types',
          selected: false,
          key: 'service types',
          link: '/system/service-types',
          hasAuth: ['ROLE_ADMIN'],
          conditions: () => {
            return true;
          },
        },
        {
          id: 2.2,
          title: 'Service Commands',
          selected: false,
          key: 'service commands',
          link: '/settings/service-commands',
          hasAuth: ['ROLE_ORG_ADMIN'],
          conditions: () => {
            return true;
          },
        },
        {
          id: 2.3,
          title: 'Service Configuration',
          selected: false,
          key: 'service configuration',
          link: '/settings/service-config',
          hasAuth: ['ROLE_ORG_ADMIN'],
          conditions: () => {
            return true;
          },
        },
      ],
    },
    {
      id: 3,
      title: 'NTU',
      selected: false,
      key: 'ntu',
      link: '/system/ntu-types',
      icon: 'fa-tachometer-fast',
      hasAuth: ['ROLE_ORG_ADMIN'],
      conditions: () => {
        return true;
      },
      subMenu: [
        {
          id: 3.1,
          title: 'NTU Types',
          selected: false,
          link: '/system/ntu-types',
          key: 'NTU Types',
          hasAuth: ['ROLE_ADMIN'],
          conditions: () => {
            return true;
          },
        },
        {
          id: 3.2,
          title: 'NTUs',
          selected: false,
          link: '/settings/ntu',
          key: 'NTUs',
          hasAuth: ['ROLE_ORG_ADMIN'],
          conditions: () => {
            return true;
          },
        },
        {
          id: 3.3,
          title: 'PE Downlink Ports',
          selected: false,
          link: '/settings/pe-downlink-ports',
          key: 'PE downlink ports',
          hasAuth: ['ROLE_ORG_ADMIN'],
          conditions: () => {
            return true;
          },
        },
        {
          id: 3.4,
          title: 'PEs',
          selected: false,
          key: 'pes',
          link: '/settings/pe',
          hasAuth: ['ROLE_ORG_ADMIN'],
          conditions: () => {
            return true;
          },
        },
      ],
    },
    {
      id: 4,
      title: 'SD-WAN',
      selected: false,
      key: 'sd-wan',
      link: '/settings/sd-wan-servers',
      icon: 'fa-tachometer-fast',
      hasAuth: ['ROLE_ORG_ADMIN'],

      conditions: () => {
        return true;
      },
      subMenu: [
        {
          id: 4.1,
          title: 'SD-WAN Servers',
          selected: false,
          link: '/settings/sd-wan-servers',
          key: 'sd-wan',
          hasAuth: ['ROLE_ORG_ADMIN'],
          conditions: () => {
            return true;
          },
        },
        {
          id: 4.2,
          title: 'SD-WAN Aggregators',
          selected: false,
          link: '/settings/sd-wan-aggregators',
          key: 'sd-wan',
          hasAuth: ['ROLE_ORG_ADMIN'],
          conditions: () => {
            return true;
          },
        },
      ],
    },
    {
      id: 5,
      title: 'NFV',
      selected: false,
      key: 'nfv',
      link: '/settings/nfv-config',
      icon: 'fa-tachometer-fast',
      hasAuth: ['ROLE_ORG_ADMIN'],

      conditions: () => {
        return true;
      },
      subMenu: [
        {
          id: 5.1,
          title: 'NFV Configuration',
          selected: false,
          link: '/settings/nfv-config',
          key: 'nfv-config',
          hasAuth: ['ROLE_ADMIN'],
          conditions: () => {
            return true;
          },
        },
        {
          id: 5.2,
          title: 'NFV Profile',
          selected: false,
          link: '/settings/nfv-os',
          key: 'nfv-os',
          hasAuth: ['ROLE_ORG_ADMIN'],
          conditions: () => {
            return true;
          },
        },
        {
          id: 5.3,
          title: 'NFV Bucket',
          selected: false,
          link: '/settings/nfv-bucket',
          key: 'nfv-bucket',
          hasAuth: ['ROLE_ORG_ADMIN'],
          conditions: () => {
            return true;
          },
        },
      ],
    },
    {
      id: 6,
      title: 'IPAM',
      selected: false,
      key: 'nfv',
      link: '/settings/realm-ip',
      icon: 'fa-tachometer-fast',
      hasAuth: ['ROLE_ORG_ADMIN'],

      conditions: () => {
        return true;
      },
      subMenu: [
        {
          id: 6.1,
          title: 'Subnet Configuration',
          selected: false,
          link: '/settings/realm-ip',
          key: 'realm-ip',
          hasAuth: ['ROLE_ADMIN'],
          conditions: () => {
            return true;
          },
        },
        {
          id: 6.2,
          title: 'Circuit Configuration',
          selected: false,
          link: '/settings/circuit-vlan',
          key: 'circuit-vlan',
          hasAuth: ['ROLE_ORG_ADMIN'],
          conditions: () => {
            return true;
          },
        },
      ],
    },
  ];

  const AdministrationMenuList = [
    {
      id: 1,
      title: 'System Admin',
      selected: false,
      key: 'system admin',
      link: '/system/entity-manager',
      icon: 'fa-tachometer-fast',
      hasAuth: ['ROLE_ADMIN'],
      conditions: () => {
        return true;
      },
      subMenu: [
        {
          id: 1.1,
          title: 'Entity Manager',
          selected: false,
          link: '/system/entity-manager',
          key: 'entity manager',
          hasAuth: ['ROLE_ORG_ADMIN'],
          conditions: () => {
            return true;
          },
        },
        {
          id: 1.2,
          title: 'Templates',
          selected: false,
          link: '/system/template-configuration',
          key: 'template',
          hasAuth: ['ROLE_ORG_ADMIN'],
          conditions: () => {
            return true;
          },
        },
        {
          id: 1.3,
          title: 'Organizations',
          selected: false,
          link: '/system/organisations',
          key: 'organization',
          hasAuth: ['ROLE_ORG_ADMIN'],
          conditions: () => {
            return true;
          },
        },
        {
          id: 1.4,
          title: 'Authority Roles',
          selected: false,
          link: '/system/user-roles',
          key: 'user-role',
          hasAuth: ['ROLE_ORG_ADMIN'],
          conditions: () => {
            return true;
          },
        },
        {
          id: 1.5,
          title: 'Entity Audits',
          selected: false,
          link: '/system/entity-audits',
          key: 'entity-audit',
          hasAuth: ['ROLE_ORG_ADMIN'],
          conditions: () => {
            return true;
          },
        },
        {
          id: 1.5,
          title: 'Login Audits',
          selected: false,
          link: '/system/audits',
          key: 'login-audit',
          hasAuth: ['ROLE_ORG_ADMIN'],
          conditions: () => {
            return true;
          },
        },
        {
          id: 1.6,
          title: 'Media Files',
          selected: false,
          link: '/system/media',
          key: 'media',
          hasAuth: ['ROLE_ORG_ADMIN'],
          conditions: () => {
            return true;
          },
        },
        {
          id: 1.7,
          title: 'Help Sections',
          selected: false,
          link: '/system/help-section/',
          key: 'help-section',
          hasAuth: ['ROLE_ORG_ADMIN'],
          conditions: () => {
            return true;
          },
        },
        {
          id: 1.8,
          title: 'Help Content',
          selected: false,
          link: '/system/help-content',
          key: 'help-content',
          hasAuth: ['ROLE_ORG_ADMIN'],
          conditions: () => {
            return true;
          },
        },
      ],
    },
    {
      id: 2,
      title: 'Email Admin',
      selected: false,
      key: 'email admin',
      link: '/administration/email-setup',
      icon: 'fa-tachometer-fast',
      hasAuth: ['ROLE_ORG_ADMIN'],
      conditions: () => {
        return true;
      },
      subMenu: [
        {
          id: 2.1,
          title: 'Entity Setup',
          selected: false,
          link: '/administration/email-setup',
          key: 'email setup',
          hasAuth: ['ROLE_ORG_ADMIN'],
          conditions: () => {
            return true;
          },
        },
        {
          id: 2.2,
          title: 'Entity Addresses',
          selected: false,
          link: '/administration/email-addresses',
          key: 'email address',
          hasAuth: ['ROLE_ORG_ADMIN'],
          conditions: () => {
            return true;
          },
        },
        {
          id: 2.3,
          title: 'Email Templates',
          selected: false,
          link: '/administration/email-templates',
          key: 'email template',
          hasAuth: ['ROLE_ORG_ADMIN'],
          conditions: () => {
            return true;
          },
        },
        {
          id: 2.4,
          title: 'Notification Emails',
          selected: false,
          link: '/administration/notification-emails',
          key: 'notification email',
          hasAuth: ['ROLE_ORG_ADMIN'],
          conditions: () => {
            return true;
          },
        },
      ],
    },
    {
      id: 3,
      title: 'User Admin',
      selected: false,
      key: 'user admin',
      link: '/administration/user-profiles',
      icon: 'fa-tachometer-fast',
      hasAuth: ['ROLE_ORG_ADMIN'],
      conditions: () => {
        return true;
      },
      subMenu: [
        {
          id: 3.1,
          title: 'User Profiles',
          selected: false,
          link: '/administration/user-profiles',
          key: 'user profile',
          hasAuth: ['ROLE_ORG_ADMIN'],
          conditions: () => {
            return true;
          },
        },
        {
          id: 3.2,
          title: 'User Management',
          selected: false,
          link: '/administration/user-management',
          key: 'user management',
          hasAuth: ['ROLE_ORG_ADMIN'],
          conditions: () => {
            return true;
          },
        },
      ],
    },
    {
      id: 4,
      title: 'Tenant Admin',
      selected: false,
      key: 'tenant admin',
      link: '/administration/tile-configurations',
      icon: 'fa-tachometer-fast',
      hasAuth: ['ROLE_ORG_ADMIN'],
      conditions: () => {
        return true;
      },
      subMenu: [
        {
          id: 4.1,
          title: 'Tile Configuration',
          selected: false,
          link: '/settings/tiles-config/',
          key: 'tile configuration',
          hasAuth: ['ROLE_ORG_ADMIN'],
          conditions: () => {
            return true;
          },
        },
        {
          id: 4.2,
          title: 'Tenants',
          selected: false,
          link: '/administration/tenants',
          key: 'tenant',
          hasAuth: ['ROLE_ORG_ADMIN'],
          conditions: () => {
            return true;
          },
        },
      ],
    },
    {
      id: 5,
      title: 'Locations',
      selected: false,
      key: 'location',
      link: '/settings/locations',
      icon: 'fa-tachometer-fast',
      hasAuth: ['ROLE_ORG_ADMIN'],
      conditions: () => {
        return true;
      },
    },
  ];

  const ComputeMenuList = [
    {
      id: 1,
      title: 'Compute Admin',
      selected: false,
      key: 'compute admin',
      link: '/',
      icon: 'fa-tachometer-fast',
      hasAuth: ['ROLE_ADMIN'],
      conditions: () => {
        return true;
      },
      subMenu: [
        {
          id: 1.1,
          title: 'GPU Server Connect',
          selected: false,
          link: '/compute/gpu-server',
          key: 'gpu server connect',
          hasAuth: ['ROLE_ORG_ADMIN'],
          conditions: () => {
            return true;
          },
        },
        {
          id: 1.2,
          title: 'Instance Type',
          selected: false,
          link: '/compute/instance-type',
          key: 'instance type',
          hasAuth: ['ROLE_ORG_ADMIN'],
          conditions: () => {
            return true;
          },
        },

        {
          id: 1.3,
          title: 'Regions',
          selected: false,
          link: '/compute/regions',
          key: 'regions',
          hasAuth: ['ROLE_ORG_ADMIN'],
          conditions: () => {
            return true;
          },
        },
        {
          id: 1.4,
          title: 'GPU Servers',
          selected: false,
          link: '/compute/gpu-server',
          key: 'gpu server',
          hasAuth: ['ROLE_ORG_ADMIN'],
          conditions: () => {
            return true;
          },
        },
        {
          id: 1.5,
          title: 'Compute API Templates',
          selected: false,
          link: '/compute/vm-command',
          key: 'compute api templates',
          hasAuth: ['ROLE_ORG_ADMIN'],
          conditions: () => {
            return true;
          },
        },
        {
          id: 1.6,
          title: 'Region Configuration',
          selected: false,
          link: '/',
          key: 'region configuration',
          hasAuth: ['ROLE_ORG_ADMIN'],
          conditions: () => {
            return true;
          },
        },
        {
          id: 1.7,
          title: 'GPU Server Allocation',
          selected: false,
          link: '/',
          key: 'gpu server allocation',
          hasAuth: ['ROLE_ORG_ADMIN'],
          conditions: () => {
            return true;
          },
        },
        {
          id: 1.8,
          title: 'Storage Pools',
          selected: false,
          link: '/',
          key: 'storage pools',
          hasAuth: ['ROLE_ORG_ADMIN'],
          conditions: () => {
            return true;
          },
        },
        {
          id: 1.9,
          title: 'Security Groups',
          selected: false,
          link: '/',
          key: 'security groups',
          hasAuth: ['ROLE_ORG_ADMIN'],
          conditions: () => {
            return true;
          },
        },
      ],
    },
    {
      id: 2,
      title: 'S3 Buckets',
      selected: false,
      key: 's3 busket',
      link: '/',
      icon: 'fa-tachometer-fast',
      hasAuth: ['ROLE_ADMIN'],
      conditions: () => {
        return true;
      },
      subMenu: [],
    },
    {
      id: 3,
      title: 'Inbound Rules',
      selected: false,
      key: 'inbound rule',
      link: '/',
      icon: 'fa-tachometer-fast',
      hasAuth: ['ROLE_ADMIN'],
      conditions: () => {
        return true;
      },
      subMenu: [],
    },
    {
      id: 4,
      title: 'API Keys',
      selected: false,
      key: 'api key',
      link: '/',
      icon: 'fa-tachometer-fast',
      hasAuth: ['ROLE_ADMIN'],
      conditions: () => {
        return true;
      },
      subMenu: [],
    },
    {
      id: 5,
      title: 'Volumes',
      selected: false,
      key: 'volume',
      link: '/',
      icon: 'fa-tachometer-fast',
      hasAuth: ['ROLE_ADMIN'],
      conditions: () => {
        return true;
      },
      subMenu: [],
    },
    {
      id: 6,
      title: 'Guests',
      selected: false,
      key: 'guest',
      link: '/',
      icon: 'fa-tachometer-fast',
      hasAuth: ['ROLE_ADMIN'],
      conditions: () => {
        return true;
      },
      subMenu: [],
    },
    {
      id: 7,
      title: 'Images',
      selected: false,
      key: 'image',
      link: '/',
      icon: 'fa-tachometer-fast',
      hasAuth: ['ROLE_ADMIN'],
      conditions: () => {
        return true;
      },
      subMenu: [],
    },
    {
      id: 8,
      title: 'Snapshots',
      selected: false,
      key: 'snapshot',
      link: '/',
      icon: 'fa-tachometer-fast',
      hasAuth: ['ROLE_ADMIN'],
      conditions: () => {
        return true;
      },
      subMenu: [],
    },
  ];

  let activeMenu = [];
  switch (menu) {
    case 'analytics':
      activeMenu = AnalyticsMenuList;
      break;
    case 'networking':
      activeMenu = NetworkingMenuList;
      break;
    case 'administration':
      activeMenu = AdministrationMenuList;
      break;
    case 'compute':
      activeMenu = ComputeMenuList;
      break;
    default:
      break;
  }

  return (
    <>
      <nav className="nav nav-pills d-flex mt-1 mx-1">
        <a
          className={`nav-item nav-link 
          nav-link ${menu === 'analytics' && 'active'}
          btn-lg`}
          dataToggle="pill"
          onClick={() => setMenu('analytics')}
        >
          <i class="fal fa-home mr-1"></i>
        </a>
        <a
          className={`nav-item nav-link 
          ${menu === 'networking' && 'active'}
          btn-lg`}
          dataToggle="pill"
          onClick={() => setMenu('networking')}
        >
          <i class="fal fa-cloud mr-1"></i>
        </a>
        {authCtx.hasAuth(['ROLE_ADMIN']) && (
          <a
            className={`nav-item nav-link 
           ${menu === 'compute' && 'active'}
        btn-lg`}
            dataToggle="pill"
            onClick={() => setMenu('compute')}
          >
            <i class="fal fa-cog mr-1"></i>
          </a>
        )}

        <a
          className={`nav-item nav-link 
          ${menu === 'administration' && 'active'}
          btn-lg`}
          dataToggle="pill"
          onClick={() => setMenu('administration')}
        >
          <i class="fal fa-user mr-1"></i>
        </a>
      </nav>

      <ul id="js-nav-menu" className="nav-menu js-nav-built">
        {activeMenu.map((item, key) => (
          <li
            key={key}
            className={`${clickedItem == item.title ? 'active' : ''} ${
              authCtx.hasAuth(item.hasAuth) ? '' : 'd-none'
            } ${item.conditions() ? '' : 'd-none'}`}
          >
            <MenuItem
              item={item}
              key={item.key}
              itemActive={clickedItem == item.title ? true : false}
              setActiveItem={setActiveItem}
            />
          </li>
        ))}
      </ul>
    </>
  );
};

export default SidebarMenu;
