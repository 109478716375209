import React, { useState, useEffect } from 'react';
import { i18n } from '../../utils/i18n';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';

const Language = () => {
  const [locale, setLocale] = useState('en');
  const { t } = useTranslation(['settings']);
  const cookies = new Cookies();

  const [currentLocale, setCurrentLocale] = useState(
    localStorage.getItem('i18nextLng')
  );
  const handleOnclick = (lang) => {
    setCurrentLocale(lang);
    i18n.changeLanguage(lang);
    cookies.set('i18next', lang, { path: '/' });
  };

  useEffect(() => {
    if (cookies.get('i18next')) {
      console.log(cookies.get('i18next'));

      setCurrentLocale(cookies.get('i18next'));
      // setLocale({ flag: currentLocale, language: currentLocale });
    }

    return () => {};
  }, []);
  return (
    <div className="dropdown-multilevel dropdown-multilevel-left">
      <div className="dropdown-item">{t(`Language`, `Language`)}</div>
      <div className="dropdown-menu">
        <a
          className={`dropdown-item ${currentLocale == 'fr' ? 'active' : ''}`}
          data-action="lang"
          data-lang="fr"
          onClick={() => handleOnclick('fr')}
        >
          {t(`French`, `French`)}
        </a>
        <a
          className={`dropdown-item ${currentLocale == 'en' ? 'active' : ''}`}
          data-action="lang"
          data-lang="en"
          onClick={() => handleOnclick('en')}
        >
          {t(`English`, `English`)}
        </a>
        <a
          className={`dropdown-item ${currentLocale == 'es' ? 'active' : ''}`}
          data-action="lang"
          data-lang="es"
          onClick={() => handleOnclick('es')}
        >
          {t(`Spanish`, `Spanish`)}
        </a>

        <a
          onClick={() => handleOnclick('ja')}
          className={`dropdown-item ${currentLocale == 'ja' ? 'active' : ''}`}
          data-action="lang"
          data-lang="ja"
        >
          {t(`Japanese`, `Japanese`)}
        </a>
      </div>
    </div>
  );
};

export default Language;
