import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import Panel from "../../../common/Panel/Panel";
import { getEntityManager } from "./api/entityManagersApi";

const EntityManagerDetail = () => {
  const match = useParams();
  const navigate = useNavigate();
  const [entityManagerEntity, setEntityManagerEntity] = useState(null);
  const [appState, setAppState] = useState({
    loading: false,
  });

  useEffect(() => {
    setAppState({ loading: true });
    const fetchData = async () => {
      try {
        const result = await getEntityManager(match.id).then((res) =>
          res.json()
        );
        setEntityManagerEntity(result);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    setAppState({ loading: false });
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-xl-10">
          {appState.loading && <p>Loading...</p>}
          {entityManagerEntity !== null && !appState.loading && (
            <Panel heading={entityManagerEntity.name}>
              <dl className="row">
                <dt className="text-inverse  col-4 ">
                  <span id="name">
                    <span>Name</span>
                  </span>
                </dt>
                <dd className="col-8 ">{entityManagerEntity.name}</dd>
                <dt className="text-inverse  col-4 ">
                  <span id="command">
                    <span>Description</span>
                  </span>
                </dt>
                <dd className="col-8 ">{entityManagerEntity.description} </dd>
                <dt className="text-inverse  col-4 ">
                  <span id="command">
                    <span>Uid</span>
                  </span>
                </dt>
                <dd className="col-8 ">{entityManagerEntity.uid} </dd>
              </dl>

              <div>
                <button
                  className="btn btn-sm btn-secondary mr-3"
                  onClick={() => navigate("../")}
                >
                  <i className="fal fa-arrow-left fa-sm  mr-2"></i>
                  Back
                </button>

                <button
                  className="btn btn-sm btn-primary m-r-5"
                  onClick={() => navigate("../edit/" + match.id)}
                >
                  <i className="fal fa-pencil-alt fa-sm  mr-2"></i>
                  Edit
                </button>
              </div>
            </Panel>
          )}
        </div>
      </div>
    </div>
  );
};

export default EntityManagerDetail;
