import React, { useEffect, useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import Panel from '../../../common/Panel/Panel';
import { getServiceDefinition } from './api/serviceDefinitionApi';

const ServiceDefinitionDetail = () => {
  const match = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [ServiceDefinitionEntity, setServiceDefinitionEntity] = useState(null);
  const [appState, setAppState] = useState({
    loading: false,
  });

  useEffect(() => {
    setAppState({ loading: true });
    const fetchData = async () => {
      try {
        const result = await getServiceDefinition(match.id).then((res) =>
          res.json()
        );
        setServiceDefinitionEntity(result);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    setAppState({ loading: false });
  }, [location]);

  return (
    <div>
      <div className="row">
        <div className="col-xl-10">
          {appState.loading && <p>Loading...</p>}
          {ServiceDefinitionEntity !== null && !appState.loading && (
            <Panel heading={ServiceDefinitionEntity.name}>
              <dl className="row">
                <dt className="text-inverse  col-4 ">
                  <span id="command">
                    <span>Service Type</span>
                  </span>
                </dt>
                <dd className="col-8 ">
                  {ServiceDefinitionEntity.serviceTypeCode}
                </dd>
                <dt className="text-inverse col-4 ">
                  <span id="command">
                    <span>Service Url</span>
                  </span>
                </dt>
                <dd className="col-8 ">
                  {ServiceDefinitionEntity.serviceUrl}{' '}
                </dd>
                <dt className="text-inverse  col-4 ">
                  <span id="command">
                    <span>Creation Type</span>
                  </span>
                </dt>
                <dd className="col-8 ">
                  {ServiceDefinitionEntity.creationType}
                </dd>

                <dt className="text-inverse  col-4 ">
                  <span id="command">
                    <span>Dto Class</span>
                  </span>
                </dt>
                <dd className="col-8 ">{ServiceDefinitionEntity.dtoClass}</dd>

                <dt className="text-inverse  col-4 ">
                  <span id="command">
                    <span>Provider Type</span>
                  </span>
                </dt>
                <dd className="col-8 ">
                  {ServiceDefinitionEntity.partnerType}
                </dd>

                <dt className="text-inverse  col-4 ">
                  <span id="command">
                    <span>Commands </span>
                  </span>
                </dt>
                <dd className="col-8  tenant-tiles-list">
                  {ServiceDefinitionEntity.commands.map((command) => {
                    return (
                      <div
                        key={command.id}
                        className="btn bg-faded cursor-e-resize waves-effect waves-themed"
                      >
                        {command.name}
                      </div>
                    );
                  })}
                </dd>
              </dl>

              <div>
                <button
                  className="btn btn-sm btn-secondary mr-3"
                  onClick={() => navigate('../')}
                >
                  <i className="fal fa-arrow-left fa-sm  mr-2"></i>
                  Back
                </button>
                <button
                  className="btn btn-sm btn-primary m-r-5"
                  onClick={() => navigate('../edit/' + match.id)}
                >
                  <i className="fal fa-pencil-alt fa-sm  mr-2"></i>
                  Edit
                </button>
              </div>
            </Panel>
          )}
        </div>
      </div>
    </div>
  );
};

export default ServiceDefinitionDetail;
