import React, { useState, useEffect } from "react";
import { getPolicersSchedules } from "../api/policerScheduleApi";
import { AddPolicerForm } from "./AddPolicerForm";

const PolicerScheduleContainer = () => {
  const [policersSchedules, setPolicersSchedules] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetcher = async () => {
      try {
        const res = await getPolicersSchedules().then((res) => res.json());
        setPolicersSchedules(res);
      } catch (error) {
        console.log(error);
      }
    };
    fetcher();
  }, []);

  return (
    <>
      <div className="bg-light border border-1 pt-2 pb-5 pl-2">
        <h2>Bandwidth on Demand</h2>
      </div>

      <div className="bg-light border border-1 pt-2 pl-2 mt-2">
        <p className="font-weight-bold">
          Configured Services [NTU Time: 2022-06-02T19:16:43.9396+08:00]
        </p>
      </div>
      <div className="bg-light border border-1 p-2 pb-5 border-top-0">
        <div className="bg-light border border-1 pt-2 pl-2 d-flex justify-content-between">
          <p className="font-weight-bold">Time Based Policers</p>
          <button
            className="btn btn-light mr-1 mb-2 py-0"
            onClick={() => setShowModal((prev) => !prev)}
          >
            Add Policer
          </button>
        </div>
        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              {[
                "",
                "State",
                "Name",
                "Bandwidth",
                "Type",
                "Start Policer",
                "End Policer",
                "Days",
                "Monthly Price",
                "Monthly Hrs Active",
              ].map((item, i) => (
                <th key={i}>{item}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {policersSchedules?.ranges?.map((range) => (
              <tr key={range.id}>
                <th></th>
                <th>
                  {range.active ? (
                    <span className="text-success">ACTIVE</span>
                  ) : (
                    <span className="text-danger">DISABLED</span>
                  )}
                </th>
                <th>{range.name}</th>
                <th>{range.bandwidth}</th>
                <th>{range.rangeType}</th>
                <th>{range.startTime}</th>
                <th>{range.stopTime}</th>
                <th>
                  {range.onDays?.map((day, i) => (
                    <button className="btn btn-light mr-1 mb-1" key={i}>
                      {day}
                    </button>
                  ))}
                </th>
                <th>${range.estimatedPrice}</th>
                <th>{range.totalDuration}</th>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="bg-light border border-1 pt-2 pl-2">
          <p className="font-weight-bold">Default Policers</p>
        </div>
        <div className="border border-1 p-2 border-top-0">
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                {[
                  "State",
                  "Bandwidth",
                  "Monthly Price",
                  "Monthly Hrs Active",
                ].map((item, i) => (
                  <th key={i}>{item}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span className="text-success">ACTIVE</span>
                </td>
                <td>100</td>
                <td>$259.68</td>
                <td>644.00</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="bg-light border border-1 mt-3 pt-2 pl-2">
          <p className="font-weight-bold">Service Monthly Estimated Price</p>
        </div>
        <div className="border border-1 p-2 border-top-0">
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th>Monthly Price (Estimated)</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td width="70%">$559.68</td>
                <td className="d-flex justify-content-end">
                  <button className="btn btn-icon btn-circle btn-danger">
                    <i className="fal fa-trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <AddPolicerForm setShow={setShowModal} show={showModal} />
    </>
  );
};

export default PolicerScheduleContainer;
