import React from 'react';
import Profile from './Profile';
const ProfileContainer = () => {
  return (
    <div>
      <div>
        <div className="subheader">
          <h1 className="subheader-title">
            <i className="subheader-icon fal fa-plus-circle" /> Profile
            <small>Profile layout</small>
          </h1>
        </div>
        <div className="row">
          <div className="col-lg-12 col-xl-8 order-lg-3 order-xl-1">
            <div className="card border">
              <div className="card-header">
                <ul className="nav nav-pills card-header-pills">
                  <li className="nav-item">
                    <a
                      className="nav-link fs-xl active"
                      data-toggle="tab"
                      href="#js_pill_border_icon-1"
                    >
                      <i className="fal fa-home mr-1"></i>Profile
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link fs-xl " href="#">
                      Notifications
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link fs-xl " href="#" tabIndex={-1}>
                      Account & Privacy
                    </a>
                  </li>
                </ul>
              </div>
              <div className="card-body py-3">
                <h3 class="fw-600 border-bottom py-3">Basic Info</h3>
                <div className="card-text">
                  <form>
                    <div className="row">
                      <div className="col-xl-4 py-3">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="example-select"
                          >
                            Title
                          </label>
                          <select className="form-control" id="example-select">
                            <option>Mr</option>
                            <option>Mrs</option>
                            <option>Ms</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-6 py-3">
                        {' '}
                        <div className="form-group">
                          <label className="form-label" htmlFor="simpleinput">
                            First Name
                          </label>
                          <input
                            type="text"
                            id="simpleinput"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-xl-6 py-3">
                        {' '}
                        <div className="form-group">
                          <label className="form-label" htmlFor="simpleinput">
                            Last Name
                          </label>
                          <input
                            type="text"
                            id="simpleinput"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-6 py-3">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="example-email-2"
                          >
                            Email
                          </label>
                          <input
                            type="email"
                            id="example-email-2"
                            name="example-email-2"
                            className="form-control"
                            placeholder="Email"
                          />
                        </div>
                      </div>
                    </div>

                    <h3 class="fw-600 border-bottom pt-5 pb-3">About Me</h3>
                    <div className="row">
                      <div className="col-xl-6 py-3">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="example-textarea"
                          >
                            Biography
                          </label>
                          <textarea
                            className="form-control"
                            id="example-textarea"
                            rows={5}
                            defaultValue={''}
                          />
                        </div>
                      </div>
                    </div>

                    <h3 class="fw-600 border-bottom pt-5 pb-3">
                      External Links
                    </h3>
                    <div className="row">
                      <div className="col-xl-12 py-3">
                        {' '}
                        <div className="form-group">
                          <label className="form-label" htmlFor="simpleinput">
                            Facebook URL
                          </label>
                          <input
                            type="text"
                            id="simpleinput"
                            className="form-control"
                            placeholder="Paste your link here"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-12 py-3">
                        {' '}
                        <div className="form-group">
                          <label className="form-label" htmlFor="simpleinput">
                            Twitter URL
                          </label>
                          <input
                            type="text"
                            id="simpleinput"
                            className="form-control"
                            placeholder="Paste your link here"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-12 py-3">
                        {' '}
                        <div className="form-group">
                          <label className="form-label" htmlFor="simpleinput">
                            LinkedIn URL
                          </label>
                          <input
                            type="text"
                            id="simpleinput"
                            className="form-control"
                            placeholder="Paste your link here"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xl-12 py-3 text-right">
                        <button
                          type="button"
                          class="btn  btn-primary waves-effect waves-themed mr-3"
                        >
                          <span class="fal fa-check mr-1"></span>
                          Submit
                        </button>
                        <button
                          type="button"
                          class="btn  btn-outline-primary waves-effect waves-themed"
                        >
                          <span class="fal fa-times mr-1"></span>
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-xl-4 order-lg-1 order-xl-2">
            <div className="card mb-g rounded-top">
              <div className="row no-gutters row-grid">
                <div className="col-12">
                  <div className="d-flex flex-column align-items-center justify-content-center p-4">
                    <img
                      src="img/demo/avatars/avatar-admin-lg.png"
                      className="rounded-circle shadow-2 img-thumbnail"
                      alt
                    />
                    <h5 className="mb-0 fw-700 text-center mt-3">
                      Dr. Codex Lantern
                      <small className="text-muted mb-0">Toronto, Canada</small>
                    </h5>
                    <div className="mt-4 text-center demo">
                      <a
                        href="javascript:void(0);"
                        className="fs-xl"
                        style={{ color: '#3b5998' }}
                      >
                        <i className="fab fa-facebook" />
                      </a>
                      <a
                        href="javascript:void(0);"
                        className="fs-xl"
                        style={{ color: '#38A1F3' }}
                      >
                        <i className="fab fa-twitter" />
                      </a>
                      <a
                        href="javascript:void(0);"
                        className="fs-xl"
                        style={{ color: '#db3236' }}
                      >
                        <i className="fab fa-google-plus" />
                      </a>
                      <a
                        href="javascript:void(0);"
                        className="fs-xl"
                        style={{ color: '#0077B5' }}
                      >
                        <i className="fab fa-linkedin-in" />
                      </a>
                      <a
                        href="javascript:void(0);"
                        className="fs-xl"
                        style={{ color: '#000000' }}
                      >
                        <i className="fab fa-reddit-alien" />
                      </a>
                      <a
                        href="javascript:void(0);"
                        className="fs-xl"
                        style={{ color: '#00AFF0' }}
                      >
                        <i className="fab fa-skype" />
                      </a>
                      <a
                        href="javascript:void(0);"
                        className="fs-xl"
                        style={{ color: '#0063DC' }}
                      >
                        <i className="fab fa-flickr" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="text-center py-3">
                    <h5 className="mb-0 fw-700">
                      764
                      <small className="text-muted mb-0">Connections</small>
                    </h5>
                  </div>
                </div>
                <div className="col-6">
                  <div className="text-center py-3">
                    <h5 className="mb-0 fw-700">
                      1,673
                      <small className="text-muted mb-0">Followers</small>
                    </h5>
                  </div>
                </div>
                <div className="col-12">
                  <div className="p-3 text-center">
                    <a
                      href="javascript:void(0);"
                      className="btn-link font-weight-bold"
                    >
                      Follow
                    </a>{' '}
                    <span className="text-primary d-inline-block mx-3">●</span>
                    <a
                      href="javascript:void(0);"
                      className="btn-link font-weight-bold"
                    >
                      Message
                    </a>{' '}
                    <span className="text-primary d-inline-block mx-3">●</span>
                    <a
                      href="javascript:void(0);"
                      className="btn-link font-weight-bold"
                    >
                      Connect
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileContainer;
