export const getNtuTypes = () => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/ntu-types?size=99999999';

  return fetch(baseUrl, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getNtuType = (id) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/ntu-types/';

  const requestUrl = `${baseUrl}${id}`;
  return fetch(requestUrl, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getNtuTypeAttributes = () => {
  const token = localStorage.getItem('token');
  const baseUrl =
    'https://test.activeport.com.au/api/entity-managers/get-entity-type/com.activeport.web.domain.NtuType';

  const requestUrl = `${baseUrl}`;
  return fetch(requestUrl, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const createNtuType = (ntuType) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/ntu-types';

  return fetch(baseUrl, {
    method: 'POST',
    body: JSON.stringify(ntuType),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const updateNtuType = (ntuType) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/ntu-types/';

  return fetch(baseUrl, {
    method: 'PUT',
    body: JSON.stringify(ntuType),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const deleteNtuType = (id) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/ntu-types/';
  const requestUrl = `${baseUrl}${id}`;
  return fetch(requestUrl, {
    method: 'DELETE',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};
