import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import Panel from "../../../common/Panel/Panel";
import Table from "../../../common/Table";

const HelpContents = ({ entities }) => {
  const data = entities;

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        sortable: true,
        Cell: ({ row }) => {
          return (
            <div>
              <Link to={`/administration/help-Contents/${row.original.id}`}>
                {row.original.id}
              </Link>
            </div>
          );
        },
      },
      {
        Header: "Help Section",
        accessor: "helpSection",
        sortable: true,
      },
      {
        Header: "Name",
        accessor: "name",
        sortable: true,
      },
      {
        Header: "Description",
        accessor: "description",
        sortable: true,
      },
      {
        Header: "",
        accessor: "actionColumns",
        className: "align-middle",
        Cell: ({ row }) => {
          return (
            <div class="text-center d-flex">
              <Link
                className="btn btn-icon btn-circle btn-default mr-2"
                to={`/system/help-Content/${row.original.id}`}
              >
                <i className="fal fa-eye"></i>
              </Link>
              <Link
                className="btn btn-icon btn-circle btn-success mr-2"
                to={`/system/help-Content/edit/${row.original.id}`}
              >
                <i className="fal fa-pencil-alt"></i>
              </Link>
              <Link
                className="btn btn-icon btn-circle btn-danger"
                to={`/system/help-Content/${row.original.id}/delete`}
              >
                <i className="fal fa-trash"></i>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <Panel>
      <Table data={data} columns={columns} />
    </Panel>
  );
};

export default HelpContents;
