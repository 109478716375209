export const getUserDetails = async () => {
  const token = localStorage.getItem('token');
  const url = 'https://test.activeport.com.au/api/account';

  const data = await fetch(url, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
  if (!data.ok) {
    const message = `An error has occured: ${data.status}`;
    throw new Error(message);
  }
  const response = await data.json();

  return response;
};
