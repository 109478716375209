import React, { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";

import Panel from "../../../common/Panel/Panel";
import { getInstanceType } from "./api/instanceTypesApi";
import { convertCamelCaseToCapitalize } from "../../../utils/string-transformers";

const InstanceTypeDetail = () => {
  const match = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [instanceTypeEntity, setInstanceTypeEntity] = useState({
    bandwidth: 100,
    cores: 2,
    gpu: 1,
    id: 1,
    maxMemory: 15258,
    memory: 8192,
    name: "acp.large",
    threads: 2,
    unit: "MiB",
    vcpu: 4,
  });
  const [appState, setAppState] = useState({
    loading: false,
  });

  useEffect(() => {
    setAppState({ loading: true });
    const fetchData = async () => {
      try {
        const result = await getInstanceType(match.id).then((res) =>
          res.json()
        );
        setInstanceTypeEntity(result);
        console.log("InstanceType Detail: ", result);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    setAppState({ loading: false });
  }, [location]);

  return (
    <div>
      <div className="row">
        <div className="col-xl-12">
          {appState.loading && <p>Loading...</p>}
          {instanceTypeEntity !== null && !appState.loading && (
            <Panel>
              <dl className="row">
                {Object.keys(instanceTypeEntity).map((entityKey) => (
                  <>
                    <dt className="text-inverse  col-4 ">
                      <span id="command">
                        <span>{convertCamelCaseToCapitalize(entityKey)}</span>
                      </span>
                    </dt>
                    <dd className="col-8 ">{instanceTypeEntity[entityKey]}</dd>
                  </>
                ))}
              </dl>

              <div>
                <button
                  className="btn btn-sm btn-secondary mr-3"
                  onClick={() => navigate("../")}
                >
                  <i className="fal fa-arrow-left fa-sm  mr-2"></i>
                  Back
                </button>
                <button
                  className="btn btn-sm btn-primary m-r-5"
                  onClick={() => navigate("../edit/" + match.id)}
                >
                  <i className="fal fa-pencil-alt fa-sm  mr-2"></i>
                  Edit
                </button>
              </div>
            </Panel>
          )}
        </div>
      </div>
    </div>
  );
};

export default InstanceTypeDetail;
