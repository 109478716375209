import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Panel from '../../../common/Panel/Panel';
import { getUserProfile } from './api/userProfilesApi';

const TenantDetail = () => {
  const match = useParams();
  const [userProfileEntity, setUserProfileEntity] = useState(null);
  const [appState, setAppState] = useState({
    loading: false,
  });

  useEffect(() => {
    setAppState({ loading: true });
    try {
      const fetchData = async () => {
        const result = await getUserProfile(match.id).then((res) => res.json());
        setUserProfileEntity(result);
      };
      fetchData();
      setAppState({ loading: false });
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-xl-10">
          {appState.loading && <p>Loading...</p>}
          {userProfileEntity !== null && !appState.loading && (
            <Panel heading={userProfileEntity.name}>
              <span>User Profile</span> [<b>{userProfileEntity.id}</b>]
              <dl className="row">
                <dt class="text-inverse  col-4 text-truncate">
                  <span id="name">
                    <span>Name</span>
                  </span>
                </dt>
                <dd class="col-8 text-truncate">{userProfileEntity.name}</dd>
                <dt class="text-inverse  col-4 text-truncate">
                  <span id="command">
                    <span>Description</span>
                  </span>
                </dt>
                <dd class="col-8 text-truncate">
                  {userProfileEntity.description}
                </dd>
                <dt class="text-inverse  col-4 text-truncate">
                  <span id="onEvent">
                    <span>Privileges</span>
                  </span>
                </dt>
                <dd class="col-8 text-truncate">
                  {/* {userProfileEntity.privileges.ma} */}
                </dd>
              </dl>
              <div>
                <Link
                  className="btn btn-sm btn-default mr-3"
                  to="/administration/user-profiles"
                >
                  <i className="fal fa-arrow-left fa-sm  mr-2"></i>
                  Back
                </Link>
                <Link
                  className="btn btn-sm btn-primary m-r-5"
                  to={`/administration/user-profiles/${userProfileEntity.id}/edit`}
                >
                  <i className="fal fa-pencil-alt fa-sm  mr-2"></i>
                  Edit
                </Link>
              </div>
            </Panel>
          )}
        </div>
      </div>
    </div>
  );
};

export default TenantDetail;
