export const getOrganisations = () => {
  const token = localStorage.getItem('token');
  const baseUrl =
    'https://test.activeport.com.au/api/organizations?size=99999999';

  return fetch(baseUrl, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getSdWanServers = () => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/sd-wan-servers';

  return fetch(baseUrl, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getSlavesOrg = () => {
  const token = localStorage.getItem('token');
  const baseUrl =
    'https://test.activeport.com.au/api/organizations/my-slaves-org';

  return fetch(baseUrl, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getOrganization = (id) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/organizations/';

  const requestUrl = `${baseUrl}${id}`;
  return fetch(requestUrl, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const updateOrganization = (organization) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/organizations/';

  return fetch(baseUrl, {
    method: 'PUT',
    body: JSON.stringify(organization),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const deleteOrganization = (id) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/organizations/';
  const requestUrl = `${baseUrl}${id}`;
  return fetch(requestUrl, {
    method: 'DELETE',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};
