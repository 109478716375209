import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';

import * as Yup from 'yup';
import Panel from '../../../common/Panel/Panel';
import Alert from '../../../common/AlertControls/Alert';
import Autocomplete from 'react-google-autocomplete';
import { handleCreateNewAlert } from '../../../common/Alerts/CreateNewAlert';
import { handleUpdateAlert } from '../../../common/Alerts/EditAlert';

import {
  createProviderDefinition,
  getProviderDefinition,
  updateProviderDefinition,
  getServiceTypes,
} from './api/providerDefinitionsApi';

const ProviderDefinitionUpdate = () => {
  const match = useParams();
  let t = [];
  const navigate = useNavigate();
  let defaultValues = {
    id: '',
    name: '',
    type: '',
    apiType: '',
    hasPortId: false,
    services: [],
  };
  const [services, setServices] = useState(null);
  const [initialData, setInitialData] = useState(defaultValues);
  const [appState, setAppState] = useState({
    loading: true,
  });

  const isNew = !match.id;
  const [alertShow, setAlertShow] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  // let alertShow = false;

  const schema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Too Short!')
      .max(250, 'Too Long!')
      .required('Required'),
    type: Yup.string()
      .min(2, 'Too Short!')
      .max(250, 'Too Long!')
      .required('Required'),
    apiType: Yup.string()
      .min(2, 'Too Short!')
      .max(250, 'Too Long!')
      .required('Required'),
  });

  async function createNewProviderDefinition(providerDefinition) {
    const response = await createProviderDefinition(providerDefinition).then(
      (res) => res.json()
    );
    if (response.id) {
      handleCreateNewAlert(
        'New provider definition "' +
          providerDefinition.name +
          '" added successfully!'
      );
      navigate('../');
    }
  }

  async function updateProviderDefinitions(providerDefinition) {
    const response = await updateProviderDefinition(providerDefinition).then(
      (res) => res.json()
    );

    if (response.id) {
      console.log('respnise id', response.id);
      handleUpdateAlert('providerDefinition', response.id);
      navigate('../' + response.id);
    }
  }

  function submitForm(formValues) {
    if (formValues.services && formValues.services.length > 0) {
      for (let i = 0; i < formValues.services.length; i++) {
        services.forEach((service) => {
          if (formValues.services[i] == service.code) {
            formValues.services[i] = service;
          }
        });
      }
    }
    isNew
      ? createNewProviderDefinition(formValues)
      : updateProviderDefinitions(formValues);
  }

  // effect runs on component mount
  useEffect(() => {
    let unmounted = false;
    // simulate async api call with set timeout
    let providerDefinitionServices;
    const fetcher = async () => {
      try {
        providerDefinitionServices = await getServiceTypes().then((res) =>
          res.json()
        );
        if (!unmounted) {
          setServices(providerDefinitionServices);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetcher();

    const fetchProviderDefinition = async () => {
      try {
        const result = await getProviderDefinition(match.id).then((res) =>
          res.json()
        );
        const services = [];
        if (!unmounted) {
          result.services.map((service) => {
            services.push(service.code);
          });
          result.services = services;
          console.log('result: ', result);
          if (result.description == null) result.description = '';
          setInitialData(result);
          //reset(result);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (!isNew) fetchProviderDefinition();
    setAppState({ loading: false });
    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-xl-9">
          {alertShow && (
            <Alert
              alertType="alert-success"
              msg="The providerDefinition has been updated."
            />
          )}

          {!appState.loading && initialData && (
            <Panel heading={initialData.name}>
              <Formik
                enableReinitialize
                initialValues={initialData}
                validationSchema={schema}
                validateOnMount
                onSubmit={submitForm}
              >
                {({ errors, touched, isValid, setFieldValue, values }) => {
                  return (
                    <Form>
                      {!isNew && (
                        <div className="form-group row m-b-15">
                          <label className="col-form-label col-md-3">ID</label>
                          <div className="col-md-9">
                            <Field
                              type="text"
                              name="id"
                              label="id"
                              className="form-control m-b-5"
                              readOnly
                            />
                          </div>
                        </div>
                      )}

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Name <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="name"
                            label="name"
                            className={`form-control m-b-5  ${
                              errors.name && touched.name ? 'is-invalid' : ''
                            }`}
                            placeholder="Enter ProviderDefinition Name"
                          />
                          {errors.name && touched.name ? (
                            <div className="invalid-feedback">
                              {errors.name}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Type <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="type"
                            label="type"
                            className={`form-control m-b-5  ${
                              errors.type && touched.type ? 'is-invalid' : ''
                            }`}
                            placeholder="Enter Type"
                          />
                          {errors.type && touched.type ? (
                            <div className="invalid-feedback">
                              {errors.type}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          API Type <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="apiType"
                            label="apiType"
                            className={`form-control m-b-5  ${
                              errors.apiType && touched.apiType
                                ? 'is-invalid'
                                : ''
                            }`}
                            placeholder="Enter API Type"
                          />
                          {errors.apiType && touched.apiType ? (
                            <div className="invalid-feedback">
                              {errors.apiType}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Services <span className="text-danger"></span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            as="select"
                            multiple
                            name="services"
                            className={`form-control m-b-5  ${
                              errors.tiles && touched.tiles ? 'is-invalid' : ''
                            }`}
                          >
                            {services?.map((service) => (
                              <option key={service.id} value={service.code}>
                                {service.code}
                              </option>
                            ))}
                          </Field>
                          {errors.tiles && touched.tiles ? (
                            <div className="invalid-feedback">
                              {errors.tiles}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <button
                        type="submit"
                        className="btn btn-primary btn-block btn-lg m-b-5"
                        disabled={!isValid || isSubmitted}
                      >
                        Submit
                      </button>
                    </Form>
                  );
                }}
              </Formik>
            </Panel>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProviderDefinitionUpdate;
