export const getNFVProfiles = () => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/api/nfv-os?size=99999999";

  return fetch(baseUrl, {
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getNFVProfile = (id) => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/api/nfv-os/";

  const requestUrl = `${baseUrl}${id}`;
  return fetch(requestUrl, {
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const createNFVProfile = (nfvProfile) => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/api/nfv-os";

  return fetch(baseUrl, {
    method: "POST",
    body: JSON.stringify(nfvProfile),
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const updateNFVProfile = (nfvProfile) => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/api/nfv-os/";

  return fetch(baseUrl, {
    method: "PUT",
    body: JSON.stringify(nfvProfile),
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const deleteNFVProfile = (id) => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/api/nfv-os/";
  const requestUrl = `${baseUrl}${id}`;
  return fetch(requestUrl, {
    method: "DELETE",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};
