export const getProviderDefinitions = () => {
  const token = localStorage.getItem('token');
  const baseUrl =
    'https://test.activeport.com.au/api/provider-configurations?size=99999999';

  return fetch(baseUrl, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getProviderDefinition = (id) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/provider-configurations';

  const requestUrl = `${baseUrl}/${id}`;
  return fetch(requestUrl, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getServiceTypes = (id) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/service-types';

  const requestUrl = `${baseUrl}`;
  return fetch(requestUrl, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const createProviderDefinition = (providerDefinition) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api';

  return fetch(baseUrl, {
    method: 'POST',
    body: JSON.stringify(providerDefinition),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const updateProviderDefinition = (providerDefinition) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/provider-configurations';

  return fetch(baseUrl, {
    method: 'PUT',
    body: JSON.stringify(providerDefinition),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const deleteProviderDefinition = (id) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/provider-configurations';
  const requestUrl = `${baseUrl}/${id}`;
  return fetch(requestUrl, {
    method: 'DELETE',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};
