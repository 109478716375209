export const getAuthorityRoles = () => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/api/user-roles?size=99999999";

  return fetch(baseUrl, {
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getAuthorityRole = (id) => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/api/user-roles/";

  const requestUrl = `${baseUrl}${id}`;
  return fetch(requestUrl, {
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const createAuthorityRole = (authorityRole) => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/api/user-roles";

  return fetch(baseUrl, {
    method: "POST",
    body: JSON.stringify(authorityRole),
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const updateAuthorityRole = (authorityRole) => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/api/user-roles/";

  return fetch(baseUrl, {
    method: "PUT",
    body: JSON.stringify(authorityRole),
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const deleteAuthorityRole = (id) => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/api/user-roles/";
  const requestUrl = `${baseUrl}${id}`;
  return fetch(requestUrl, {
    method: "DELETE",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};
