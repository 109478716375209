import React from "react";
import { SubnetsTable } from "./SubnetTable";

export const Subnets = ({ subnets, id }) => {
  return (
    <>
      {Object.keys(subnets).map((subnetKey, i) => (
        <SubnetsTable key={i} obj={subnets[subnetKey]} th2={subnetKey} />
      ))}
    </>
  );
};
