import React, { useState } from "react";

import { ServicesBP1 } from "../ServicesBP1";
import { AddAzureServiceForm } from "./AddAzureServiceForm";

const ZettanetContainer = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <ServicesBP1
        title="AZURE"
        subTitle="Configure the AZURE Service"
        addBtnText="Add AZURE Service"
        showModal={showModal}
        setShowModal={setShowModal}
        serviceLogoUrl="/assets/img/ntu/AZURE-TILE.png"
      />
      <AddAzureServiceForm
        show={showModal}
        setShow={setShowModal}
        //   onSave={onSave}
      />
    </>
  );
};

export default ZettanetContainer;
