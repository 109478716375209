import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";

import { MyCheckbox1 } from "../../../common/Form/FormFields";

const initialValues = {
  field: "",
  label: "",
  fieldType: "",
  description: "",
  visible: false,
  required: false,
  fieldMaxLength: "",
  fieldMinLength: "",
  regex: "",
};

export const EntityManagerAttributeModal = ({
  attribute,
  show,
  setShow,
  onSaveAttribute,
}) => {
  const [data, setData] = useState(initialValues);

  useEffect(() => {
    if (attribute) {
      setData(attribute);
    } else {
      setData(initialValues);
    }
  }, [attribute]);

  const onHide = (e) => {
    e.preventDefault();
    setShow(!show);
  };

  const saveAttribute = (values) => {
    onSaveAttribute(values);
    setShow(false);
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={data}
        onSubmit={(values, { resetForm }) => {
          saveAttribute(values);
          resetForm();
        }}
      >
        {({ values }) => {
          return (
            <div
              className={`modal fade overflow-auto ${show && "show d-block"}`}
            >
              <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Create or edit an attribute</h5>
                    <button type="button" className="close" onClick={onHide}>
                      <span aria-hidden="true">
                        <i className="fal fa-times"></i>
                      </span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <Form>
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">Field</label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="field"
                            className="form-control m-b-5"
                          />
                        </div>
                      </div>
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Field Label
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="label"
                            className="form-control m-b-5"
                          />
                        </div>
                      </div>
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Field Label <span className="text-danger"></span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            as="select"
                            name="fieldType"
                            className={`form-control m-b-5`}
                          >
                            <option value=""></option>
                            {[
                              "STRING",
                              "INTEGER",
                              "BOOLEAN",
                              "BLOB",
                              "MEDIA",
                              "TIMESTAMP",
                            ].map((type, i) => (
                              <option key={i} value={type}>
                                {type}
                              </option>
                            ))}
                          </Field>
                        </div>
                      </div>
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Description
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="description"
                            className="form-control m-b-5"
                          />
                        </div>
                      </div>
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Visible
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="checkbox"
                            component={MyCheckbox1}
                            name="visible"
                            checked={values.visible}
                            className={`form-control m-b-5`}
                          />
                        </div>
                      </div>
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Required
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="checkbox"
                            component={MyCheckbox1}
                            name="required"
                            checked={values.required}
                            className={`form-control m-b-5`}
                          />
                        </div>
                      </div>
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">Min</label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="fieldMinLength"
                            className="form-control m-b-5"
                          />
                        </div>
                      </div>
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">Max</label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="fieldMaxLength"
                            className="form-control m-b-5"
                          />
                        </div>
                      </div>
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">Regex</label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="regex"
                            className="form-control m-b-5"
                          />
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary waves-effect waves-themed"
                          onClick={onHide}
                        >
                          Close
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary waves-effect waves-themed"
                        >
                          Save changes
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
    </>
  );
};
