import React from "react";
import { Formik, Form, Field } from "formik";

export const Modal = ({
  title,
  initialValues,
  show,
  onHide,
  fieldsData,
  addNewRowHandler,
}) => {
  return (
    <>
      <div className={`modal fade overflow-auto ${show && "show d-block"}`}>
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <div className="d-flex w-100 align-items-center justify-content-between">
                <h2 className="font-weight-bold">Create or edit a {title}</h2>
                <button type="button" className="close" onClick={onHide}>
                  <span aria-hidden="true">
                    <i className="fal fa-times"></i>
                  </span>
                </button>
              </div>
            </div>
            <div className="modal-body">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={(values, { resetForm }) => {
                  if (initialValues.id >= 0) {
                    addNewRowHandler(title, values, initialValues.id);
                  } else {
                    addNewRowHandler(title, values);
                  }
                  resetForm();
                }}
              >
                {({ handleSubmit }) => {
                  return (
                    <Form>
                      {fieldsData.map((field, i) => (
                        <div key={i} className="form-group row m-b-15">
                          <label className="col-form-label col-md-3">
                            {field.label}
                          </label>
                          <div
                            className={`col-md-9 ${
                              field.type === "checkbox" && "mt-2"
                            }`}
                          >
                            <Field {...field} className="form-control m-b-5">
                              {field.options && (
                                <>
                                  <option value=""></option>
                                  {field.options.map((opt, i) => (
                                    <option key={i} value={opt.value}>
                                      {opt.label}
                                    </option>
                                  ))}
                                </>
                              )}
                            </Field>
                          </div>
                        </div>
                      ))}
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary waves-effect waves-themed"
                          onClick={onHide}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary waves-effect waves-themed"
                          onClick={(e) => {
                            e.preventDefault();
                            handleSubmit();
                            onHide(e);
                          }}
                        >
                          Update
                        </button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
