import React, { useMemo, useState, useEffect } from 'react';
import Table from '../../../common/Table';

import { handleDeleteAlert } from '../../../common/Alerts/DeleteAlert';
const ZonesTable = ({ allData, heading }) => {
  const data = allData;
  console.log('all devices:', allData);

  const columns = useMemo(
    () => [
      {
        Header: 'Zone',
        accessor: (d) => {
          return d.key;
        },
        sortable: true,
      },

      {
        Header: 'Zone Records',
        accessor: (d) => {
          return d.doc_count;
        },
        sortable: true,
      },
    ],
    []
  );
  useEffect(() => {}, []);

  return (
    <Table data={data} columns={columns} heading="Top Applications by Zone" />
  );
};

export default ZonesTable;
