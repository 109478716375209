import React from 'react';
import MediaUpload from '../../../common/MediaUpload';

const MediaUploader = () => {
  const handleSubmit = () => {};
  return (
    <div>
      <MediaUpload onSubmit={handleSubmit} />
    </div>
  );
};

export default MediaUploader;
