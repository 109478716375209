import React from "react";

export const ServicesBP1 = ({
  title,
  subTitle,
  addBtnText,
  addBtnText2,
  setShowModal,
  setShowModal2,
  serviceLogoUrl,
  children,
}) => {
  return (
    <>
      <div className="bg-white pl-2 pt-2 mb-5">
        <h2>{title}</h2>
        <p>{subTitle}</p>
      </div>

      <div className="row" style={{ minHeight: "130px" }}>
        <div className="col-lg-3 bg-white d-flex align-items-center justify-content-center">
          <img src={serviceLogoUrl} alt="" />
        </div>
        <div className="col-lg-8 offset-lg-1 mt-md-3 mt-lg-0 bg-white p-0">
          <div className="border-bottom p-2">
            <h6 className="m-0">Service Types</h6>
          </div>
          <button
            type="button"
            className="btn my-4 btn-primary ml-5"
            onClick={setShowModal}
          >
            {addBtnText}
          </button>
          {addBtnText2 && (
            <button
              type="button"
              className="btn my-4 btn-primary ml-5"
              onClick={setShowModal2}
            >
              {addBtnText2}
            </button>
          )}
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-3" />
        <div className="col-lg-8 offset-lg-1 bg-white p-0">
          {children ? (
            <>{children}</>
          ) : (
            <>
              <div className="border-bottom p-2">
                <h6 className="m-0">Configured Services</h6>
              </div>
              <div className="p-2">
                <div className="border p-2 text-center">
                  No Services Configured
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
