export const getElasticSearchData = async (queryType, queryFilters) => {
  const token = localStorage.getItem('token');
  let baseUrl = 'https://test.activeport.com.au/api/';
  switch (queryType) {
    case 'getRouteEngine':

    case 'getInterfaces':
    case 'getFormQuery':
    case 'getGeneric':
    case 'getGenericIgnoreLoading':
    case 'getGenericArray':
    case 'searchIgnoreLoading':
      queryFilters.ignoreLoadingBar = true;
      console.log('Query Filters: ', queryFilters);
      baseUrl = baseUrl + 'elastic/my-query';
      break;
    case 'searchNoTenantIgnoreLoading':
      queryFilters.ignoreLoadingBar = true;

      baseUrl = baseUrl + 'elastic/my-query3';
      break;
    case 'search':
      baseUrl = baseUrl + 'elastic/my-query';
      break;
    case 'post':
    case 'searchNoTenant':
      baseUrl = baseUrl + 'elastic/my-query3';
      break;
    case 'delete':
    case 'getOrgBased':
  }

  return await fetch(baseUrl, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
    method: 'POST',
    body: JSON.stringify(queryFilters),
  });
};
