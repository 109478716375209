import React, { useEffect, useContext, Fragment } from 'react';
import Administration from './Administration';
import SettingsContext from '../../store/settings-context';

const DashboardsContainer = () => {
  return (
    <Fragment>
      <Administration />
    </Fragment>
  );
};

export default DashboardsContainer;
