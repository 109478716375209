export const getInstanceTypes = () => {
  const token = localStorage.getItem("token");
  const baseUrl =
    "https://test.activeport.com.au/gaming-api/instance-types?size=99999999";

  return fetch(baseUrl, {
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getInstanceType = (id) => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/gaming-api/instance-types/";

  const requestUrl = `${baseUrl}${id}`;
  return fetch(requestUrl, {
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const createInstanceType = (instanceType) => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/gaming-api/instance-types";

  return fetch(baseUrl, {
    method: "POST",
    body: JSON.stringify(instanceType),
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const updateInstanceType = (instanceType) => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/gaming-api/instance-types/";

  return fetch(baseUrl, {
    method: "PUT",
    body: JSON.stringify(instanceType),
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const deleteInstanceType = (id) => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/gaming-api/instance-types/";
  const requestUrl = `${baseUrl}${id}`;
  return fetch(requestUrl, {
    method: "DELETE",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};
