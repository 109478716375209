import React, { Fragment, useState, useContext, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import HeaderAvatar from '../../../assets/images/demo/avatars/avatar-admin.png';
import Language from '../Language';
import AuthContext from '../../../store/auth-context';

const ProfilePanel = ({ toggleModal }) => {
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const { t } = useTranslation(['settings']);

  useEffect(() => {
    const accountInfo = JSON.parse(localStorage.getItem('accountInfo'));
    setUserName(accountInfo.firstName + ' ' + accountInfo.lastName);
    return () => {};
  }, []);

  const logoutHandler = () => {
    authCtx.logout();
    navigate('/');
    // optional: redirect the user
  };

  const resetLayout = () => {
    localStorage.removeItem('themeSettings');
    let defaultThemeSettings = JSON.stringify({
      globalFontSize: 'root-text',
      themeSkin: 'skin-default',
    });
    localStorage.setItem('themeSettings', defaultThemeSettings);
    window.location.reload();
  };

  const fullScreenToggle = () => {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        /* Standard browsers */
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        /* Internet Explorer */
        document.documentElement.msRequestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        /* Firefox */
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        /* Chrome */
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  };
  return (
    <Fragment>
      <div className="dropdown-menu dropdown-menu-animated dropdown-lg show">
        <div className="dropdown-header bg-trans-gradient d-flex flex-row py-4 rounded-top">
          <div className="d-flex flex-row align-items-center mt-1 mb-1 color-white">
            <span className="mr-2">
              <img
                src={HeaderAvatar}
                className="rounded-circle profile-image"
                alt="Dr. Codex Lantern"
              />
            </span>
            <div className="info-card-text">
              <div className="fs-lg text-truncate text-truncate-lg">
                {userName}
              </div>
              <span className="text-truncate text-truncate-md opacity-80">
                {userEmail}
              </span>
            </div>
          </div>
        </div>
        <div className="dropdown-divider m-0" />

        <Link
          to="/user/profile"
          className="dropdown-item"
          data-toggle="modal"
          data-target=".js-modal-settings"
        >
          <span data-i18n="drpdwn.settings">
            {t(`Profile Settings`, `Profile Settings`)}
          </span>
        </Link>
        <a
          onClick={() => toggleModal('settingsPanel')}
          className="dropdown-item"
          data-action="app-reset"
        >
          <span data-i18n="drpdwn.settings">
            {t(`Layout Settings`, `Layout Settings`)}
          </span>
        </a>
        <div className="dropdown-divider m-0" />
        <a
          className="dropdown-item"
          data-action="app-fullscreen"
          onClick={() => fullScreenToggle()}
        >
          <span data-i18n="drpdwn.fullscreen">
            {t(`Fullscreen`, `Fullscreen`)}
          </span>
          <i className="float-right text-muted fw-n">F11</i>
        </a>
        <a className="dropdown-item" data-action="app-print">
          <span data-i18n="drpdwn.print"> {t(`Print`, `Print`)}</span>
          <i className="float-right text-muted fw-n">Ctrl + P</i>
        </a>
        <Language />
        <a
          onClick={() => resetLayout()}
          className="dropdown-item"
          data-action="app-reset"
        >
          <span data-i18n="drpdwn.reset_layout">
            {t(`Reset Layout`, `Reset Layout`)}
          </span>
        </a>
        <div className="dropdown-divider m-0" />
        <a
          className="dropdown-item fw-500 pt-3 pb-3"
          onClick={() => logoutHandler()}
        >
          <span data-i18n="drpdwn.page-logout">{t(`Logout`, `Logout`)}</span>
          <span className="float-right fw-n"></span>
        </a>
      </div>
    </Fragment>
  );
};

export default ProfilePanel;
