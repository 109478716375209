import React, { Fragment, useState, useEffect } from 'react';
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker';
import moment from 'moment';
import PropTypes from 'prop-types';

/**
 * Date Range Picker Common Component to display a date range picker
 * <br/><a href="https://www.npmjs.com/package/react-advanced-datetimerange-picker">Click here to view npm package</a>
 * @component
 */

const DateRangePicker = (props) => {
  let now = new Date();
  let start = moment(
    new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
  );
  let end = moment(start).add(1, 'days').subtract(1, 'seconds');

  const [startDate, setStartDate] = useState(
    moment(
      new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
    )
  );
  const [endDate, setEndDate] = useState(
    moment(startDate).add(1, 'days').subtract(1, 'seconds')
  );
  const [local, setLocal] = useState({
    format: 'DD-MM-YYYY HH:mm',
    sundayFirst: false,
  });
  const [maxDate, setMaxDate] = useState(moment(startDate).add(24, 'hour'));
  let dateValue = `${startDate.format(
    'MMMM DD, YYYY (HH:mm:s)'
  )} - ${endDate.format('MMMM DD, YYYY (HH:mm:s)')}`;

  const ranges = {
    Today: [moment().startOf('day'), moment()],
    'Last 15 Minutes': [moment().subtract(15, 'minutes'), moment()],
    'Last 30 Minutes': [moment().subtract(30, 'minutes'), moment()],
    'Last 1 Hour': [moment().subtract(1, 'hour'), moment()],
    'Last 3 Hours': [moment().subtract(3, 'hour'), moment()],
    'Last 6 Hours': [moment().subtract(6, 'hour'), moment()],
    'Last 12 Hours': [moment().subtract(12, 'hour'), moment()],
    'Last 24 Hours': [moment().subtract(24, 'hour'), moment()],
    Yesterday: [
      moment(start).subtract(1, 'days'),
      moment(end).subtract(1, 'days'),
    ],
    'Last 7 Days': [moment(start).subtract(6, 'days'), moment(end)],
    'Last 30 Days': [moment(start).subtract(29, 'days'), moment(end)],
    'This Month': [moment(start).startOf('month'), moment(end)],
    'Last Month': [
      moment().subtract(1, 'months').date(1),
      moment(start).subtract(1, 'months').endOf('month'),
    ],

    '1 Year': [moment(start).subtract(1, 'years'), moment(end)],
  };

  /** callback function when date is changed */
  const applyCallback = (stDate, enDate) => {
    // console.log('Start Date: ', stDate);
    //  console.log('End Date', enDate);
    setStartDate(stDate);
    setEndDate(enDate);
    if (props.createGraph) {
      props.createGraph(props.request, stDate, enDate);
    }
    if (props.getDateTime) {
      console.log('props get date time');
      props.getDateTime(stDate, enDate);
    }
  };

  moment.locale(localStorage.getItem('i18nextLng'));
  useEffect(() => {
    if (props.startDate != null) {
      setStartDate(props.startDate);
    }
    if (props.endDate != null) {
      setEndDate(props.endDate);
    }
  }, []);
  return (
    <Fragment>
      <DateTimeRangeContainer
        ranges={ranges}
        start={startDate}
        end={endDate}
        local={local}
        maxDate={maxDate}
        applyCallback={applyCallback}
      >
        <input
          id="formControlsTextB"
          type="text"
          label="Text"
          className="form-control m-b-5 "
          value={dateValue}
          onChange={() => {}}
        />
      </DateTimeRangeContainer>
    </Fragment>
  );
};

DateRangePicker.propTypes = {
  /**
   * Start Date & Time
   */
  startDate: PropTypes.instanceOf(moment).isRequired,
  /**
   * End Date & Time
   */
  endDate: PropTypes.instanceOf(moment).isRequired,
  /**
   * Update date and type in parent component
   */
  getDateTime: PropTypes.func,
};
export default DateRangePicker;
