import React, { useState } from "react";

import { ServicesBP1 } from "../ServicesBP1";
import { AddGooglePortForm } from "./AddGooglePortForm";

const ZettanetContainer = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <ServicesBP1
        title="Google Cloud"
        subTitle="Configure an Google Cloud Service"
        addBtnText="Add Google Port"
        showModal={showModal}
        setShowModal={setShowModal}
        serviceLogoUrl="/assets/img/ntu/GOOGLE-TILE.png"
      />
      <AddGooglePortForm
        show={showModal}
        setShow={setShowModal}
        //   onSave={onSave}
      />
    </>
  );
};

export default ZettanetContainer;
