import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Panel from '../../../common/Panel/Panel';
import { getEmailSetup } from './api/emailSetupApi';

const TenantDetail = () => {
  const match = useParams();
  const [emailSetupEntity, setEmailSetupEntity] = useState(null);
  const [appState, setAppState] = useState({
    loading: false,
  });

  useEffect(() => {
    setAppState({ loading: true });
    const fetchData = async () => {
      const result = await getEmailSetup(match.id).then((res) => res.json());
      setEmailSetupEntity(result);
    };
    fetchData();
    setAppState({ loading: false });
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-xl-6">
          {appState.loading && <p>Loading...</p>}
          {emailSetupEntity !== null && !appState.loading && (
            <>
              <span>Email Setup</span> [<b>{emailSetupEntity.emailSetupName}</b>
              ]
              <dl className="row">
                <dt class="text-inverse  col-4 text-truncate">
                  <span id="name">
                    <span>Email Setup Name</span>
                  </span>
                </dt>
                <dd class="col-8 text-truncate">
                  {emailSetupEntity.emailSetupName}
                </dd>
              </dl>
              <Link className="btn btn-sm btn-default m-r-5" to="">
                <i className="fal fa-arrow-left fa-sm  mr-2"></i>
                Back
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TenantDetail;
