import axios from "axios";

const templateConfigurationBaseUrl = `${process.env.REACT_APP_APICONFIG_MICRO}api/template-configurations`;
const ntuTypeBaseUrl = `${process.env.REACT_APP_APICONFIG_MICRO}api/ntu-types`;
const deviceConfigurationsBaseUrl = `${process.env.REACT_APP_APICONFIG_MICRO}api/device-configurations-map/18`;

export const getTemplateConfigurations = () => {
  return axios.get(templateConfigurationBaseUrl);
};

export const getTemplateConfiguration = (id) => {
  return axios.get(`${templateConfigurationBaseUrl}/${id}`);
};

export const getNtuTypes = () => {
  return axios.get(ntuTypeBaseUrl);
};

export const getDeviceConfigurations = () => {
  return axios.get(deviceConfigurationsBaseUrl);
};

export const createTemplateConfiguration = (templateConfiguration) => {
  return axios.post(`${templateConfigurationBaseUrl}`, templateConfiguration);
};

export const updateTemplateConfiguration = (templateConfiguration) => {
  return axios.put(`${templateConfigurationBaseUrl}`, templateConfiguration);
};

export const deleteTemplateConfiguration = (id) => {
  return axios.delete(`${templateConfigurationBaseUrl}/${id}`);
};
