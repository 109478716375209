import React, { useState } from "react";

import { ServicesBP1 } from "../ServicesBP1";
import { AddAwsDirectForm } from "./AddAwsDirectForm";

const AWSCloudContainer = () => {
  const [showForm, setShowForm] = useState("");

  const hideForm = () => {
    setShowForm("");
  };

  return (
    <>
      <ServicesBP1
        title="AWS"
        subTitle="Configure the Amazon Direct Services"
        addBtnText="Add AWS Direct Private"
        addBtnText2="Add AWS Direct Public"
        setShowModal={() => setShowForm("private")}
        setShowModal2={() => setShowForm("public")}
        serviceLogoUrl="/assets/img/ntu/AWS-TILE.png"
      />
      <AddAwsDirectForm
        show={showForm === "private"}
        setShow={hideForm}
        //   onSave={onSave}
      />
      <AddAwsDirectForm show={showForm === "public"} setShow={hideForm} />
    </>
  );
};

export default AWSCloudContainer;
