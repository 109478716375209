import React, { useEffect, useState, Fragment } from "react";
import { Routes, Route, useLocation, Link } from "react-router-dom";

import CircuitConfigurations from "./CircuitConfigurations";
import CircuitConfigurationDetail from "./CircuitConfigurationDetail";
import CircuitConfigurationUpdate from "./CircuitConfigurationUpdate";
// import CircuitConfigurationDelete from "./CircuitConfigurationDeleteDialog";
import {
  getCircuitConfigurations,
  getServiceConfigurations,
} from "./api/circuitConfigurationsApi";
const CircuitConfigurationsContainer = () => {
  const ntu = localStorage.getItem("ntu");

  const location = useLocation();

  const [entities, setEntities] = useState([]);
  const [appState, setAppState] = useState({
    loading: false,
    err: false,
  });
  const [serviceConfigs, setServiceConfigs] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        setAppState({ loading: true, err: false });
        const res = await getCircuitConfigurations().then((res) => res.json());
        const serviceConfigRes = await getServiceConfigurations().then((res) =>
          res.json()
        );
        setAppState({ loading: false });
        setEntities(res);
        setServiceConfigs(serviceConfigRes);
      } catch (err) {
        setAppState({ loading: false, err: true });
      }
    })();
  }, []);

  return (
    <Fragment>
      <ol className="breadcrumb page-breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">ActivePort</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to="/administration">Settings</Link>
        </li>
        <li className="breadcrumb-item active">Circuit Configurations</li>
        <li className="position-absolute pos-top pos-right d-none d-sm-block">
          <span className="js-get-date"></span>
        </li>
      </ol>
      <div className="subheader">
        <h1 className="subheader-title">
          <i className="subheader-icon fal fa-table"></i> Circuit Configurations
        </h1>

        {ntu &&
          !location.pathname.includes("/new") &&
          !location.pathname.includes("/edit") && (
            <Link to={`new`}>
              <button
                type="button"
                className="btn btn-success waves-effect waves-themed"
              >
                <span className="fal fa-plus mr-1"></span>
                Create
              </button>
            </Link>
          )}
      </div>
      {!appState.loading && !appState.err && (
        <Routes>
          {ntu && (
            <>
              <Route
                exact
                path={`/new`}
                element={
                  <CircuitConfigurationUpdate serviceConfigs={serviceConfigs} />
                }
              />
              <Route
                exact
                path={`/edit/:id`}
                element={
                  <CircuitConfigurationUpdate serviceConfigs={serviceConfigs} />
                }
              />
            </>
          )}
          <Route exact path={`:id`} element={<CircuitConfigurationDetail />} />
          <Route
            path={`/`}
            element={<CircuitConfigurations entities={entities} />}
          />
        </Routes>
      )}
      {appState.loading && !appState.error && (
        <div className="border p-3">
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      )}
      {!appState.loading && appState.err && (
        <div className="alert alert-danger" role="alert">
          <strong>Oh snap!</strong> We are not able to connect to the database.
          Try again in few minutes. again.
        </div>
      )}
    </Fragment>
  );
};

export default CircuitConfigurationsContainer;
