import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SidebarMenu from './SidebarMenu';
import AuthContext from '../../store/auth-context';
import Logo from '../../assets/images/activeport-icon.png';
import Cover3 from '../../assets/images/card-backgrounds/cover-3-lg.png';
import UserAvatar from '../../assets/images/demo/avatars/avatar-admin.png';
import SettingsContext from '../../store/settings-context';

const MainNavigation = (props) => {
  const authCtx = useContext(AuthContext);
  const [ntuList, setNtuList] = useState([]);

  const [orgName, setOrgName] = useState('');
  const [tenantName, setTenantName] = useState('');
  const settingsCtx = useContext(SettingsContext);

  useEffect(() => {
    //  document.addEventListener('click', handleClickOutside, true);
    //get working org name

    const accountInfo = JSON.parse(localStorage.getItem('accountInfo'));
    setOrgName(accountInfo.org.name);
    setTenantName(accountInfo.firstName);
  }, [settingsCtx.NTU]);
  return (
    <aside className="page-sidebar">
      <div className="page-logo">
        <a
          href="#"
          className="page-logo-link press-scale-down d-flex align-items-center position-relative"
          data-toggle="modal"
          data-target="#modal-shortcut"
        >
          <img src={Logo} alt="Activeport Logo" aria-roledescription="logo" />
          <span className="page-logo-text mr-1">ActivePortal</span>
          <span className="position-absolute text-white opacity-50 small pos-top pos-right mr-2 mt-n2" />
          <i className="fal fa-angle-down d-inline-block ml-1 fs-lg color-primary-300" />
        </a>
      </div>
      {/* BEGIN PRIMARY NAVIGATION */}
      <nav id="js-primary-nav" className="primary-nav" role="navigation">
        <div className="nav-filter">
          <div className="position-relative">
            <input
              type="text"
              id="nav_filter_input"
              placeholder="Filter menu"
              className="form-control"
              tabIndex={0}
            />
            <a
              href="#"
              className="btn-primary btn-search-close js-waves-off"
              data-action="toggle"
              data-class="list-filter-active"
              data-target=".page-sidebar"
            >
              <i className="fal fa-chevron-up" />
            </a>
          </div>
        </div>
        <div className="info-card">
          <img
            src={UserAvatar}
            className="profile-image rounded-circle"
            alt="Dr. Codex Lantern"
          />
          <div className="info-card-text">
            <a href="#" className="d-flex align-items-center text-white">
              <span className="text-truncate text-truncate-sm d-inline-block">
                {tenantName}
              </span>
            </a>
            <span className="d-inline-block text-truncate text-truncate-sm">
              {orgName}
            </span>
          </div>
          <img src={Cover3} className="cover" alt="cover" />
          <a
            href="#"
            className="pull-trigger-btn"
            data-action="toggle"
            data-class="list-filter-active"
            data-target=".page-sidebar"
            data-focus="nav_filter_input"
          >
            <i className="fal fa-angle-down" />
          </a>
        </div>
        {/* 
						TIP: The menu items are not auto translated. You must have a residing lang file associated with the menu saved inside dist/media/data with reference to each 'data-i18n' attribute.
						*/}
        <SidebarMenu ntu={props.ntu} />
        <div className="filter-message js-filter-message bg-success-600" />
      </nav>
      {/* END PRIMARY NAVIGATION */}
      {/* NAV FOOTER */}
      <div className="nav-footer shadow-top">
        <a
          href="#"
          data-action="toggle"
          data-class="nav-function-minify"
          className="hidden-md-down"
        >
          <i className="ni ni-chevron-right" />
          <i className="ni ni-chevron-right" />
        </a>
      </div>{' '}
      {/* END NAV FOOTER */}
    </aside>
  );
};

export default MainNavigation;
