import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";

const initialValues = {
  name: "",
  rangeType: "",
  startPolicer: "",
  stopPolicer: "",
  bandwidth: "",
};

export const AddPolicerForm = ({ show, setShow }) => {
  const onHide = (e) => {
    e.preventDefault();
    setShow();
  };

  const saveAttribute = (values) => {};

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) => {
          saveAttribute(values);
          resetForm();
        }}
      >
        {() => {
          return (
            <div
              className={`modal fade overflow-auto ${show && "show d-block"}`}
            >
              <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <div className="d-flex w-100 align-items-center justify-content-between">
                      <h2>Create or edit a Plolicer Range</h2>
                      <button type="button" className="close" onClick={onHide}>
                        <span aria-hidden="true">
                          <i className="fal fa-times"></i>
                        </span>
                      </button>
                    </div>
                  </div>
                  <div className="modal-body">
                    <Form>
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Service name
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="name"
                            className="form-control m-b-5"
                          />
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Range Type
                        </label>
                        <div className="col-md-9">
                          <Field
                            as="select"
                            name="rangeType"
                            className={`form-control m-b-5`}
                          >
                            <option value=""></option>
                            {[
                              "EVERYDAY",
                              "WEEKDAYS",
                              "WEEKENDS",
                              "DAYS",
                              "FROM_TO-DAY",
                            ].map((type, i) => (
                              <option key={i} value={type}>
                                {type}
                              </option>
                            ))}
                          </Field>
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Start Plolicer
                        </label>
                        <div className="col-md-9"></div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          End Plolicer
                        </label>
                        <div className="col-md-9"></div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Bandwidth
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="bandwidth"
                            className="form-control m-b-5"
                          />
                        </div>
                      </div>

                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary waves-effect waves-themed"
                          onClick={onHide}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary waves-effect waves-themed"
                        >
                          Validate
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
    </>
  );
};
