export const getTenants = () => {
  const token = localStorage.getItem('token');
  const baseUrl =
    'https://test.activeport.com.au/api/rest/v1/tenants?size=99999999';

  return fetch(baseUrl, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getTenant = (id) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/rest/v1/tenants/';

  const requestUrl = `${baseUrl}${id}`;
  return fetch(requestUrl, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getTenantTiles = () => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/rest/v1/tenants';

  return fetch(
    'https://test.activeport.com.au/api/tile-configurations/tenants',
    {
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    }
  );
};

export const createTenant = (tenant) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/rest/v1/tenants';

  return fetch(baseUrl, {
    method: 'POST',
    body: JSON.stringify(tenant),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const updateTenant = (tenant) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/rest/v1/tenants/';

  return fetch(baseUrl, {
    method: 'PUT',
    body: JSON.stringify(tenant),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const deleteTenant = (id) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/rest/v1/tenants/';
  const requestUrl = `${baseUrl}${id}`;
  return fetch(requestUrl, {
    method: 'DELETE',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};
