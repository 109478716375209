import React from 'react';
import { useTable, useSortBy, useExpanded, usePagination } from 'react-table';
import PropTypes from 'prop-types';

/**
 * Tables documentation - using react-tables
 * <a href="https://react-table.tanstack.com/">React table homepage</a>
 * @component
 * @prop {array} data - data to display from api.
 * @prop {array} columns - table columns
 * @prop {node} renderRowSubComponent - jsx for nested tables
 * @prop {bool} showPagination - to show the pagination or not
 */

const Table = ({ data, columns, renderRowSubComponent, showPagination }) => {
  if (showPagination == null || showPagination == undefined) {
    showPagination = true;
  }
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    visibleColumns,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    { columns, data, initialState: { pageIndex: 0, pageSize: 10 } },
    useSortBy,

    useExpanded,
    usePagination
  );

  return (
    <div>
      <div className="table-responsive">
        <table
          id="dt-basic-example"
          className="table table-bordered table-hover table-striped w-100"
        >
          <thead>
            {headerGroups?.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers?.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className="d-flex" style={{ minWidth: '100px' }}>
                      <span>{column.render('Header')}</span>
                      <span className="ml-auto">
                        {column.sortable ? (
                          column.isSorted ? (
                            column.isSortedDesc ? (
                              <i className="fal fa-sort-down fa-fw f-s-14 text-blue"></i>
                            ) : (
                              <i className="fal fa-sort-up fa-fw f-s-14 text-blue"></i>
                            )
                          ) : (
                            <i className="fal fa-sort fa-fw f-s-14 opacity-3"></i>
                          )
                        ) : (
                          ''
                        )}
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page?.map((row, i) => {
              prepareRow(row);
              return (
                <React.Fragment key={i + '_frag'}>
                  <tr {...row.getRowProps()}>
                    {row?.cells?.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps({
                            className: cell.column.className,
                          })}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                  {row.isExpanded ? (
                    <tr>
                      <td colSpan={visibleColumns.length}>
                        {/*
                          Inside it, call our renderRowSubComponent function. In reality,
                          you could pass whatever you want as props to
                          a component like this, including the entire
                          table instance. But for this example, we'll just
                          pass the row
                        */}
                        {renderRowSubComponent({ row })}
                      </td>
                    </tr>
                  ) : null}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
      {showPagination && (
        <div className="w-100 d-flex align-items-center justify-content-center">
          <div className="d-flex align-items-center justify-content-center">
            <ul className="pagination mb-0">
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  <i className="fal fa-angle-double-left"></i>
                </button>
              </li>
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  <i className="fal fa-angle-left"></i>
                </button>
              </li>
              <li className="page-item d-flex align-items-center px-2">
                <div>
                  Page{' '}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>
                </div>
              </li>
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  <i className="fal fa-angle-right"></i>
                </button>
              </li>
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  <i className="fal fa-angle-double-right"></i>
                </button>
              </li>
            </ul>
            <div className="ml-3 mr-1">Go to page:</div>
            <div className="width-50 mx-2">
              <input
                className="form-control"
                type="number"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
              />
            </div>
            <div>
              <select
                className="form-control"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[5, 10, 20, 30, 40].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

Table.propTypes = {
  /**
   * Data
   */
  data: PropTypes.array.isRequired,
  /**
   * Table Columns
   */
  columns: PropTypes.array.isRequired,
  /**
   * JSX for nested table data
   */
  renderRowSubComponent: PropTypes.node,
  /**
   * Show pagination or not
   */
  showPagination: PropTypes.bool,
};
export default Table;
