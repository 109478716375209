import React, { useEffect, useState, Fragment } from 'react';
import { Routes, Route, useLocation, Link } from 'react-router-dom';

import ServiceDefinition from './ServiceDefinition';
import ServiceDefinitionDetail from './ServiceDefinitionDetails';
import ServiceDefinitionUpdate from './ServiceDefinitionUpdate';
// import ServiceDefinitionDelete from "./ServiceDefinitionDeleteDialog";
import {
  getServiceDefinitions,
  getServiceCommandsList,
} from './api/serviceDefinitionApi';
import { getProviderDefinitions } from '../ProviderDefinitions/api/providerDefinitionsApi';

const ServiceDefinitionsContainer = () => {
  const location = useLocation();

  const [entities, setEntities] = useState([]);
  const [providerConfigurationsData, setProviderConfigurationsData] = useState(
    []
  );
  const [serviceCommandsListData, setServiceCommandsListData] = useState([]);
  const [appState, setAppState] = useState({
    loading: false,
    err: false,
  });

  useEffect(() => {
    (async () => {
      try {
        setAppState({ loading: true, err: false });
        const res = await getServiceDefinitions().then((res) => res.json());
        setEntities(res);
        setAppState({ loading: false });
        const providerConfigurations = await getProviderDefinitions().then(
          (res) => res.json()
        );
        const serviceCommandsList = await getServiceCommandsList().then((res) =>
          res.json()
        );
        setProviderConfigurationsData(providerConfigurations);
        setServiceCommandsListData(serviceCommandsList);
      } catch (err) {
        setAppState({ loading: false, err: true });
      }
    })();
  }, [location.key]);

  return (
    <Fragment>
      <ol className="breadcrumb page-breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">ActivePort</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to="/sytem">System</Link>
        </li>
        <li className="breadcrumb-item active">Service Definition</li>
        <li className="position-absolute pos-top pos-right d-none d-sm-block">
          <span className="js-get-date"></span>
        </li>
      </ol>
      <div className="subheader">
        <h1 className="subheader-title">
          <i className="subheader-icon fal fa-table"></i> Service Definitions
        </h1>

        {!location.pathname.includes('/new') &&
          !location.pathname.includes('/edit') && (
            <Link to={`new`}>
              <button
                type="button"
                className="btn btn-success waves-effect waves-themed"
              >
                <span className="fal fa-plus mr-1"></span>
                Create
              </button>
            </Link>
          )}
      </div>
      {!appState.loading && !appState.err && (
        <Routes>
          <Route
            exact
            path={`/new`}
            element={
              <ServiceDefinitionUpdate
                providerConfigrationsData={providerConfigurationsData}
                serviceCommandsListData={serviceCommandsListData}
              />
            }
          />
          <Route
            exact
            path={`/edit/:id`}
            element={
              <ServiceDefinitionUpdate
                providerConfigurationsData={providerConfigurationsData}
                serviceCommandsListData={serviceCommandsListData}
              />
            }
          />
          <Route exact path={`:id`} element={<ServiceDefinitionDetail />} />

          <Route
            path={`/`}
            element={<ServiceDefinition entities={entities} />}
          />
        </Routes>
      )}
      {appState.loading && !appState.error && (
        <div className="border p-3">
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      )}
      {!appState.loading && appState.err && (
        <div className="alert alert-danger" role="alert">
          <strong>Oh snap!</strong> We are not able to connect to the database.
          Try again in few minutes. again.
        </div>
      )}
      {/* <Route
          exact
          path={`/:id/delete`}
          element={ServiceDefinitionDelete}
        /> */}
    </Fragment>
  );
};

export default ServiceDefinitionsContainer;
