import React, { useState, useEffect } from "react";
import { getNtuPorts } from "../api/NTUApi";

export const Ports = () => {
  const [ntuPorts, setNtuPorts] = useState([]);

  useEffect(() => {
    const fetcher = async () => {
      try {
        const res = await getNtuPorts().then((res) => res.json());
        setNtuPorts(res);
      } catch (error) {
        console.log(error);
      }
    };

    fetcher();
  }, []);
  return (
    <>
      <table className="table table-striped table-bordered mb-5">
        <thead>
          <tr>
            {["Port", "Port Type", "Port Speed", ""].map((item, i) => (
              <th key={i}>{item}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {ntuPorts.map((ntuPort) => (
            <tr key={ntuPort.id}>
              <td>{ntuPort.name}</td>
              <td>{ntuPort.portType}</td>
              <td>{ntuPort.portSpeed}</td>
              <td className="text-right">
                <button className="btn btn-icon btn-circle btn-success mr-2">
                  <i className="fal fa-pencil-alt"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
