import React, { useMemo, useState, useEffect } from 'react';
import Table from '../../../common/Table';

import { handleDeleteAlert } from '../../../common/Alerts/DeleteAlert';
const BigRepsDataTable = ({ data }) => {
  console.log(data);
  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: (d) => {
          return d['_source']['@timestamp'];
        },
        sortable: true,
      },
      {
        Header: 'Source IP',
        accessor: (d) => {
          return d['_source']['source-address'];
        },
        sortable: true,
      },
      {
        Header: 'Dest IP',
        accessor: (d) => {
          return d['_source']['nat-destination-address'];
        },
        sortable: true,
      },
      {
        Header: 'Source Port',
        accessor: (d) => {
          return d['_source']['source-port'];
        },
        sortable: true,
      },
      {
        Header: 'Dest Port',
        accessor: (d) => {
          return d['_source']['nat-destination-port'];
        },
        sortable: true,
      },
      {
        Header: 'Reputations',
        accessor: (d) => {
          return d['_source']['rep_tags'];
        },
        sortable: true,
      },
      {
        Header: 'Geo Location',
        accessor: (d) => {
          if (!d['_source']['geoip_src']) return '';
          else return d['_source']['geoip_src']['country_name'];
        },
        sortable: true,
      },
    ],
    []
  );
  useEffect(() => {}, []);
  if (data.length == 0) return <p>No Data</p>;
  else return <Table data={data} columns={columns} heading="" />;
};

export default BigRepsDataTable;
