import React, { useState } from "react";
import { ServiceStatusDialog } from "./ServiceStatusDialog";

export const ServiceStatus = ({ connections }) => {
  const [showServiceStatusModal, setShowServiceStatusModal] = useState(false);

  return (
    <>
      <div className="border border-1">
        <div className="d-flex justify-content-between p-2">
          <p className="font-weight-bold">Service Status</p>
        </div>
        <div
          style={{ height: "200px" }}
          className="d-flex justify-content-center"
        >
          <div className="d-flex align-items-center">
            <h4>Service Status</h4>
            <input
              className="p-2 ml-2"
              value={connections[0]?.provisioningStatus}
              onClick={() => setShowServiceStatusModal(true)}
            />
          </div>
        </div>
      </div>

      <ServiceStatusDialog
        show={showServiceStatusModal}
        id="64"
        onHide={() => setShowServiceStatusModal(false)}
      />
      <ServiceStatusDialog />
    </>
  );
};
