import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Formik, Form, Field, yupToFormErrors } from 'formik';
import * as Yup from 'yup';
import Panel from '../../../common/Panel/Panel';

const LocationUpdate = () => {
  const match = useParams();
  const navigate = useNavigate();
  const [id, setId] = useState(match.id);
  const [initialData, setInitialData] = useState({
    code: '',
    description: '',
    address: '',
    scopeName: 'loopback',
    subnet: '',
    cloudPeLoopbackIP: '',
    mapcloudPePublicIP: '',
  });

  const [appState, setAppState] = useState({
    loading: false,
  });
  const [isNew, setIsNew] = useState(!match.params || !match.id);

  const schema = Yup.object().shape({
    code: Yup.string()
      .min(2, 'Too Short!')
      .max(250, 'Too Long!')
      .required('Required'),
    description: Yup.string()
      .min(2, 'Too Short!')
      .max(250, 'Too Long!')
      .required('Required'),
    address: Yup.string()
      .min(2, 'Too Short!')
      .max(250, 'Too Long!')
      .required('Required'),
    scopeName: Yup.string().required('Required'),
    subnet: Yup.string(),
    cloudPeLoopbackIP: Yup.string(),
    mapcloudPePublicIP: Yup.string(),
  });

  const submitNewLocation = () => {};

  const updateLocation = () => {};
  return (
    <div>
      <div className="row">
        <div className="col-xl-9">
          {appState.loading && <p>Loading...</p>}
          {!appState.loading && (
            <Panel heading={initialData.name}>
              <Formik
                enableReinitialize
                initialValues={initialData}
                validationSchema={schema}
                validateOnMount
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  isNew ? submitNewLocation(values) : updateLocation(values);
                }}
              >
                {({ errors, touched, isValid, setFieldValue, values }) => {
                  return (
                    <Form>
                      {' '}
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Code <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="name"
                            label="name"
                            className={`form-control m-b-5  ${
                              errors.code && touched.code ? 'is-invalid' : ''
                            }`}
                            placeholder="Enter code"
                          />
                          {errors.code && touched.code ? (
                            <div className="invalid-feedback">
                              {errors.code}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Description <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="name"
                            label="name"
                            className={`form-control m-b-5  ${
                              errors.description && touched.description
                                ? 'is-invalid'
                                : ''
                            }`}
                            placeholder="Enter description"
                          />
                          {errors.description && touched.description ? (
                            <div className="invalid-feedback">
                              {errors.description}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Address <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="name"
                            label="name"
                            className={`form-control m-b-5  ${
                              errors.address && touched.address
                                ? 'is-invalid'
                                : ''
                            }`}
                            placeholder="Enter description"
                          />
                          {errors.description && touched.address ? (
                            <div className="invalid-feedback">
                              {errors.address}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Default Scope <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="name"
                            label="name"
                            className={`form-control m-b-5  ${
                              errors.scopeName && touched.scopeName
                                ? 'is-invalid'
                                : ''
                            }`}
                            value="loopback"
                          />
                          {errors.scopeName && touched.scopeName ? (
                            <div className="invalid-feedback">
                              {errors.scopeName}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Default Subnet <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="name"
                            label="name"
                            className={`form-control m-b-5  ${
                              errors.subnet && touched.subnet
                                ? 'is-invalid'
                                : ''
                            }`}
                            placeholder="e.g. 10.1.20.0/24"
                          />
                          {errors.subnet && touched.subnet ? (
                            <div className="invalid-feedback">
                              {errors.subnet}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Cloud PE Loopback
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="name"
                            label="name"
                            className={`form-control m-b-5  ${
                              errors.cloudPeLoopbackIP &&
                              touched.cloudPeLoopbackIP
                                ? 'is-invalid'
                                : ''
                            }`}
                            placeholder=""
                          />
                          {errors.cloudPeLoopbackIP &&
                          touched.cloudPeLoopbackIP ? (
                            <div className="invalid-feedback">
                              {errors.cloudPeLoopbackIP}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Cloud PE Public IP
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="name"
                            label="name"
                            className={`form-control m-b-5  ${
                              errors.mapcloudPePublicIP &&
                              touched.mapcloudPePublicIP
                                ? 'is-invalid'
                                : ''
                            }`}
                          />
                          {errors.mapcloudPePublicIP &&
                          touched.mapcloudPePublicIP ? (
                            <div className="invalid-feedback">
                              {errors.mapcloudPePublicIP}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary btn-block btn-md m-b-5"
                        disabled={!isValid}
                      >
                        Submit
                      </button>
                    </Form>
                  );
                }}
              </Formik>
            </Panel>
          )}
        </div>
      </div>
    </div>
  );
};

export default LocationUpdate;
