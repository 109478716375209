import React, { useMemo, useState, useEffect } from 'react';
import Table from '../../../common/Table';

import { handleDeleteAlert } from '../../../common/Alerts/DeleteAlert';
const PublicRepDataTable = ({ data }) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Public Threat IP',
        accessor: (d) => {
          return d.key;
        },
        sortable: true,
      },
      {
        Header: 'Source Country',
        accessor: (d) => {
          return d.geo.buckets[0].key;
        },
        sortable: true,
      },
      {
        Header: 'Flow Records',
        accessor: (d) => {
          return d.doc_count;
        },
        sortable: true,
      },
    ],
    []
  );
  useEffect(() => {}, []);
  if (data.length == 0) return <p>No Data</p>;
  else return <Table data={data} columns={columns} heading="" />;
};

export default PublicRepDataTable;
