import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  useContext,
} from 'react';
import DateTimePicker from './DateTimePicker';
import { Link } from 'react-router-dom';
import moment from 'moment';
import tz from 'moment-timezone';
import Overview from './Overview';
import Panel from '../../../common/Panel/Panel';
import SettingsContext from '../../../store/settings-context';
import { useNavigate, Navigate } from 'react-router-dom';
import AnalyticsDashboard from './AnaylticsDashboard';

const AnalyticsContainer = () => {
  const settingsCtx = useContext(SettingsContext);
  const [NTUPorts, setNTUPorts] = useState([]);
  const [selectedPort, setSelectedPort] = useState('');
  const [lte, setLte] = useState(null);
  const [gte, setGte] = useState(null);
  const getDateTime = (gte, lte) => {
    console.log('update DATE TI');
    setGte(gte._d.getTime());
    setLte(lte._d.getTime());
  };

  const onNTUPortsChange = (ntuports) => {
    setNTUPorts(ntuports);
  };
  const setPort = (port) => {
    console.log(port);
    setSelectedPort(port);
  };
  let NTU = settingsCtx.NTU;
  if (NTU == '' || NTU == null) {
    if (localStorage.getItem('ntu') != '' || localStorage.getItem('ntu')) {
      settingsCtx.NTU = JSON.parse(localStorage.getItem('ntu'));
      NTU = settingsCtx.NTU;
    }
  }

  if (localStorage.getItem('ntu') == '' || !localStorage.getItem('ntu')) {
    return <Navigate to="/" />;
  } else
    return (
      <Fragment>
        <ol className="breadcrumb page-breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">ActivePort</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/dashboard/provisioning">Analytics Dashboard</Link>
          </li>
          <li className="position-absolute pos-top pos-right d-none d-sm-block">
            <span className="js-get-date">
              {moment().format('dddd, D MMMM, YYYY')}
            </span>
          </li>
        </ol>
        <div className="subheader">
          <h1 className="subheader-title">
            <i className="subheader-icon fal fa-chart-area" />
            &nbsp;Analytics Dashboard
            <small>
              The Analytics Dashboard shows statistical data from your NTU.
            </small>
          </h1>
        </div>
        <div className="row">
          <div className="col-12">
            <Panel heading="Pick date and time range">
              <DateTimePicker
                NTUPorts={NTUPorts}
                setPort={setPort}
                getDateTime={getDateTime}
              />
            </Panel>
          </div>

          <div className="col-lg-12">
            <AnalyticsDashboard
              NTU={NTU}
              NTUPorts={NTUPorts}
              onNTUPortsChange={onNTUPortsChange}
              currentPort={selectedPort}
              lte={lte}
              gte={gte}
            />
          </div>
        </div>
      </Fragment>
    );
};

export default AnalyticsContainer;
