import React, { useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Panel from '../../../common/Panel/Panel';
import Table from '../../../common/Table';
import { handleDeleteAlert } from '../../../common/Alerts/DeleteAlert';
const Organisations = ({ entities }) => {
  const data = entities;

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'row_id',
        sortable: true,
        Cell: ({ row }) => {
          return (
            <div>
              <Link to={`${row.original.tenantId}`}>{row.original.id}</Link>
            </div>
          );
        },
      },
      {
        Header: 'Name',
        accessor: 'name',
        sortable: true,
      },
      {
        Header: 'Organization ID',
        accessor: 'orgId',
        sortable: true,
      },
      {
        Header: 'Description',
        accessor: 'description',
        sortable: true,
      },
      {
        Header: 'Billing',
        accessor: (d) => (d.billing ? 'true' : 'false'),
        sortable: true,
      },
      {
        Header: 'SD-WAN Server',
        accessor: (d) =>
          d.sdWanAccount != null ? d.sdWanAccount.sdWanEndpoint : '',
        sortable: true,
      },

      {
        Header: 'SD-WAN Support',
        accessor: (d) =>
          d.sdWanAccount != null ? (
            <div className="d-flex justify-content-center">
              <p
                className={`text-center p-2 bg-${
                  d.sdWanAccount.sdWan ? 'success' : 'danger'
                } text-white  rounded`}
              >
                {d.sdWanAccount.sdWan ? 'Activated' : 'Deactivated'}
              </p>
            </div>
          ) : (
            ''
          ),
        sortable: true,
      },
      {
        Header: '',
        accessor: 'actionColumns',
        className: 'align-middle',
        Cell: ({ row }) => {
          return (
            <div className="text-center d-flex">
              <Link
                className="btn btn-icon btn-circle btn-default mr-2"
                to={`${row.original.tenantId}`}
              >
                <i className="fal fa-eye"></i>
              </Link>
              <Link
                className="btn btn-icon btn-circle btn-success mr-2"
                to={`../edit/${row.original.tenantId}`}
              >
                <i className="fal fa-pencil-alt"></i>
              </Link>
              <button
                className="btn btn-icon btn-circle btn-danger"
                onClick={handleDeleteAlert}
              >
                <i className="fal fa-trash"></i>
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <Panel>
      <Table data={data} columns={columns} />
    </Panel>
  );
};

export default Organisations;
