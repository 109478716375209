export const getUserProfiles = () => {
  const token = localStorage.getItem('token');
  const baseUrl1 = 'https://test.activeport.com.au/api/user-profiles/admin';
  return fetch(baseUrl1, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getUserProfile = (id) => {
  const token = localStorage.getItem('token');
  const baseUrl2 = 'https://test.activeport.com.au/api/user-profiles';

  const requestUrl = `${baseUrl2}/${id}`;
  return fetch(requestUrl, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getUserRoles = () => {
  const token = localStorage.getItem('token');
  const userRoles = 'https://test.activeport.com.au/api/user-roles';

  return fetch(userRoles, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const createUserProfile = (userProfile) => {
  const token = localStorage.getItem('token');
  const baseUrl2 = 'https://test.activeport.com.au/api/user-profiles';

  return fetch(baseUrl2, {
    method: 'POST',
    body: JSON.stringify(userProfile),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const updateUserProfile = (userProfile) => {
  const token = localStorage.getItem('token');
  const baseUrl2 = 'https://test.activeport.com.au/api/user-profiles';

  return fetch(baseUrl2, {
    method: 'PUT',
    body: JSON.stringify(userProfile),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const deleteUserProfile = (id) => {
  const token = localStorage.getItem('token');
  const baseUrl2 = 'https://test.activeport.com.au/api/user-profiles';

  const requestUrl = `${baseUrl2}${id}`;
  return fetch(requestUrl, {
    method: 'DELETE',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};
