import React from "react";
import { Field } from "formik";

export const Download = () => {
  return (
    <>
      <div className="form-group row m-b-15">
        <label className="col-form-label col-md-3 font-weight-bold">
          Config Backup
        </label>
        <div className="col-md-9 mt-2">
          <Field
            name="configBackup"
            type="checkbox"
            className="form-control m-b-5"
          />
        </div>
      </div>
      <div className="form-group row m-b-15">
        <label className="col-form-label col-md-3 font-weight-bold">
          Config Download
        </label>
        <div className="col-md-9">
          <button className="btn btn-light">Download</button>
        </div>
      </div>
      <div className="form-group row m-b-15">
        <label className="col-form-label col-md-3 font-weight-bold">
          Intial Configuration
        </label>
        <div className="col-md-9">
          <button className="btn btn-light">Download</button>
        </div>
      </div>
    </>
  );
};
