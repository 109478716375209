import { useContext, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/Layout/Layout';
import AuthPage from './pages/AuthPage';
import Dashboards from './components/Dashboards';
import AuthContext from './store/auth-context';
import { i18n } from './utils/i18n';
import Cookies from 'universal-cookie';
import {
  AdministrationRoutes,
  DashboardRoutes,
  SettingsRoutes,
  ComputeRoutes,
  ProvisioningRoutes,
} from './routes/';
import { UserRoutes } from './routes/';
import { SystemRoutes } from './routes/SystemRoutes';

function App() {
  const cookies = new Cookies();
  const authCtx = useContext(AuthContext);

  /**
   * Check if the user has authorisation.
   * @param {array} roleNames - An array of rolenames for that user.
   */
  function hasAuthority(roleNames) {
    if (roleNames && roleNames.length > 0) {
      if (authCtx.hasAuth(roleNames)) {
        return true;
      }
      return false;
    } else {
      return true;
    }
  }

  useEffect(() => {
    let lang = cookies.get('i18next');

    if (!lang) lang = 'en';

    i18n.changeLanguage(lang);
  }, []);

  return (
    <>
      <Routes>
        {!authCtx.isLoggedIn && (
          <Route element={<Layout />}>
            <Route index exact path="/" element={<AuthPage />} />
          </Route>
        )}
        {authCtx.isLoggedIn && (
          <Route element={<Layout />}>
            <Route index exact element={<Dashboards />} path="/"></Route>
            {SettingsRoutes.map((props) => (
              <Route {...props} key="random()" />
            ))}
            {/* AdministrationRoutes.map(({ path, component: C, roleNames }) => (
            <PrivateAdminRoute
              key={path}
              path={path}
              roleNames={roleNames}
              render={(props) => <C {...props} />}
            />
          )) */}

            {ComputeRoutes.map((props) => {
              if (hasAuthority(props.roleNames))
                return <Route {...props} key="random()" />;
            })}

            {AdministrationRoutes.map((props) => {
              if (hasAuthority(props.roleNames))
                return <Route {...props} key="random()" />;
            })}

            {SystemRoutes.map((props) => {
              if (hasAuthority(props.roleNames))
                return <Route {...props} key="random()" />;
            })}

            {DashboardRoutes.map((props) => (
              <Route {...props} key="random()" />
            ))}

            {ProvisioningRoutes.map((props) => (
              <Route {...props} key="random()" />
            ))}

            {UserRoutes.map((props) => (
              <Route {...props} key="random()" />
            ))}

            <Route
              path="*"
              element={<Navigate to="/dashboard/administration" />}
            />
          </Route>
        )}
        <Route element={<Layout />}>
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
