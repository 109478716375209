import React, { Fragment, useState, useContext } from 'react';
import DateTimePicker from './DateTimePicker';
import { Link } from 'react-router-dom';
import moment from 'moment';
import tz from 'moment-timezone';
import Panel from '../../../common/Panel/Panel';
import SettingsContext from '../../../store/settings-context';
import { useNavigate, Navigate } from 'react-router-dom';
import InternetDashbaord from './InternetDashbaord';

const InternetContainer = () => {
  const settingsCtx = useContext(SettingsContext);
  console.log('Internet Container', settingsCtx);
  const [NTUPorts, setNTUPorts] = useState([]);
  const nav = useNavigate();
  const [selectedPort, setSelectedPort] = useState('');
  const [lte, setLte] = useState(null);
  const [gte, setGte] = useState(null);
  const getDateTime = (gte, lte) => {
    console.log('update DATE TI');
    setGte(gte._d.getTime());
    setLte(lte._d.getTime());
  };

  let NTU = settingsCtx.NTU;
  if (NTU == '' || NTU == null) {
    if (localStorage.getItem('ntu') != '' || localStorage.getItem('ntu')) {
      settingsCtx.NTU = JSON.parse(localStorage.getItem('ntu'));
      NTU = settingsCtx.NTU;
    }
  }

  if (localStorage.getItem('ntu') == '' || !localStorage.getItem('ntu')) {
    return <Navigate to="/" />;
  } else
    return (
      <Fragment>
        <ol className="breadcrumb page-breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">ActivePort</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/dashboard/provisioning">Internet Dashboard</Link>
          </li>
          <li className="position-absolute pos-top pos-right d-none d-sm-block">
            <span className="js-get-date">
              {moment().format('dddd, D MMMM, YYYY')}
            </span>
          </li>
        </ol>
        <div className="subheader">
          <h1 className="subheader-title">
            <i className="subheader-icon fal fa-chart-area" />
            &nbsp;Internet Dashboard
            <small>
              The Internet Dashboard shows traffic data from your NTU.
            </small>
          </h1>
        </div>
        <div className="row">
          <div className="col-12">
            <Panel heading="Pick date and time range">
              <DateTimePicker getDateTime={getDateTime} />
            </Panel>
          </div>

          <div className="col-lg-12">
            <InternetDashbaord NTU={NTU} lte={lte} gte={gte} />
          </div>
        </div>
      </Fragment>
    );
};

export default InternetContainer;
