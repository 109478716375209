import React, { useState } from 'react';

const Alert = ({ alertType, msg }) => {
  const [show, setShow] = useState(true);

  let className = 'fade in show';
  let heading = '';
  let icon = '';
  if (!show) {
    className = 'fade out';
  }

  if (alertType == 'alert-danger') {
    heading = 'Error! ';
    icon = 'fa-exclamation-triangle';
  }

  if (show) {
    return (
      <div className={`alert ${alertType} ${className}`}>
        <button
          className="close"
          data-dismiss="alert"
          onClick={() => setShow(false)}
        >
          ×
        </button>
        <i className={`fa-fw fa ${icon}`}></i>&nbsp;
        <strong>{heading}</strong> {msg}
      </div>
    );
  }

  return <></>;
};

export default Alert;
