import React, { useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Panel from '../../../common/Panel/Panel';
import Table from '../../../common/Table';

import { handleDeleteAlert } from '../../../common/Alerts/DeleteAlert';
const InventoryItems = ({ entities }) => {
  const data = entities;

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'row_id',
        sortable: true,
        Cell: ({ row }) => {
          return (
            <div>
              <Link to={`${row.original.tenantId}`}>{row.original.id}</Link>
            </div>
          );
        },
      },
      {
        Header: 'Name',
        accessor: 'name',
        sortable: true,
      },
      {
        Header: 'Billing',
        accessor: 'api',
        sortable: true,
      },
      {
        Header: 'Use Local Price',
        accessor: (d) => {
          if (d.useLocalPrice)
            return <i class="fa fal fa-check text-success"></i>;
          else return <i class="fa fal fa-do-not-enter text-danger"></i>;
        },
        sortable: true,
      },
      {
        Header: 'Use Up Lift Price',
        accessor: (d) => {
          if (d.useUpLife) return <i class="fa fal fa-check text-success"></i>;
          else return <i class="fa fal fa-do-not-enter text-danger"></i>;
        },
        sortable: true,
      },
      {
        Header: 'Up Lift Price%',
        accessor: (d) => {
          if (d.upLift == null) {
            return `%`;
          }
          return `${d.upLift}%`;
        },
        sortable: true,
      },
      {
        Header: 'Monthly Price per Mbps',
        accessor: 'localPriceRate',
        sortable: true,
      },
      {
        Header: 'Monthly Fixed Price',
        accessor: 'localPriceMontlhy',
        sortable: true,
      },
      {
        Header: 'Activation Price',
        accessor: 'localPriceActivation',
        sortable: true,
      },
      {
        Header: 'Currency Code',
        accessor: (d) => {
          if (d.currencyCode == null) return '';
          else return d.currencyCode;
        },
        sortable: true,
      },

      {
        Header: '',
        accessor: 'actionColumns',
        className: 'align-middle',
        Cell: ({ row }) => {
          return (
            <div className="text-center d-flex">
              <Link
                className="btn btn-icon btn-circle btn-default mr-2"
                to={`${row.original.tenantId}`}
              >
                <i className="fal fa-eye"></i>
              </Link>
              <Link
                className="btn btn-icon btn-circle btn-success mr-2"
                to={`${row.original.tenantId}/edit`}
              >
                <i className="fal fa-pencil-alt"></i>
              </Link>
              <button
                className="btn btn-icon btn-circle btn-danger"
                onClick={handleDeleteAlert}
              >
                <i className="fal fa-trash"></i>
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <Panel>
      <Table data={data} columns={columns} />
    </Panel>
  );
};

export default InventoryItems;
