import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Formik, Form, Field, yupToFormErrors } from 'formik';

import * as Yup from 'yup';
import Panel from '../../../common/Panel/Panel';
import Alert from '../../../common/AlertControls/Alert';
import Autocomplete from 'react-google-autocomplete';
import { handleCreateNewAlert } from '../../../common/Alerts/CreateNewAlert';
import { handleUpdateAlert } from '../../../common/Alerts/EditAlert';
import './usermanagement.scss';
import { createUser, getUserProfiles } from './api/userManagementApi';

const UserManagementCreate = () => {
  const match = useParams();
  let t = [];
  const navigate = useNavigate();
  const accountInfo = JSON.parse(localStorage.getItem('accountInfo'));
  const [isActivated, setIsActivated] = useState(true);
  const [userError, setUserError] = useState(false);
  let defaultValues = {
    activated: isActivated,
    authorities: ['ROLE_USER'],
    createdBy: null,
    createdDate: null,
    defaultDashboard: 'default',
    disabledEdit: false,
    domain: accountInfo.domain,
    email: '',
    firstName: '',
    id: null,
    lastModifiedBy: null,
    lastModifiedDate: null,
    lastName: '',
    langKey: 'en',
    login: '',
    orgId: accountInfo.orgId,
    profileId: '',
    tenantId: accountInfo.tenant.tenantId,
    resetDate: null,
    resetKey: null,
  };

  const [initialData, setInitialData] = useState(defaultValues);
  const [entityData, setEntityData] = useState(null);
  const [appState, setAppState] = useState({
    loading: true,
  });

  const isNew = !match.id;
  const [alertShow, setAlertShow] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  // let alertShow = false;

  const schema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'Too Short!')
      .max(250, 'Too Long!')
      .required('Required'),
    lastName: Yup.string()
      .min(2, 'Too Short!')
      .max(250, 'Too Long!')
      .required('Required'),
    login: Yup.string().email('Invalid email').required('Required'),
    // tiles: Yup.array().required('Required'),
    // tenantId: Yup.string().required("Required"),
    profileId: Yup.string().required('Required'),
    // mapCenter: Yup.string().min(2, 'Too Short!').required('Required'),
    // mapZoom: Yup.number().required('Required'),
  });

  async function createNew(data) {
    try {
      const response = await createUser(data).then((res) => {
        res.json();
      });
      if (response.id) {
        handleCreateNewAlert(
          'New user profile for "' +
            data.firstName +
            ' ' +
            data.lastName +
            '" added successfully!'
        );
        navigate('../');
      }
    } catch (error) {
      //console.log(error);
      setUserError(true);
    }
  }

  function submitForm(formValues) {
    // let tiles = tenantTilesData.filter((t) =>
    //   formValues.tiles.includes(t.tileId)
    // );
    // formValues.tiles = te;
    formValues.email = formValues.login;
    createNew(formValues);
  }

  // effect runs on component mount
  useEffect(() => {
    let unmounted = false;
    // simulate async api call with set timeout
    let entityResponse;
    const fetcher = async () => {
      try {
        entityResponse = await getUserProfiles().then((res) => res.json());
        if (!unmounted) {
          setEntityData(entityResponse);
        }
        console.log(entityResponse);
      } catch (error) {
        console.log(error);
      }
    };
    fetcher();

    setAppState({ loading: false });
    return () => {
      unmounted = true;
    };
  }, []);

  const onSubmit = (data) => {
    alert(JSON.stringify(data));
  };

  return (
    <div>
      <div className="row">
        <div className="col-xl-9">
          {alertShow && (
            <Alert
              alertType="alert-success"
              msg="The tenant has been updated."
            />
          )}

          {userError && (
            <Alert
              alertType="alert-danger"
              msg="Sorry, this email is already in use."
            />
          )}

          {!appState.loading && initialData && (
            <Panel heading={initialData.name}>
              <Formik
                enableReinitialize
                initialValues={initialData}
                validationSchema={schema}
                validateOnMount
                onSubmit={submitForm}
              >
                {({ errors, touched, isValid, setFieldValue, values }) => {
                  return (
                    <Form>
                      {!isNew && (
                        <div className="form-group row m-b-15">
                          <label className="col-form-label col-md-3">ID</label>
                          <div className="col-md-9">
                            <Field
                              type="text"
                              name="id"
                              label="id"
                              className="form-control m-b-5"
                              readOnly
                            />
                          </div>
                        </div>
                      )}

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Tenant
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="tenantId"
                            readOnly
                            label="tenantId"
                            className={`form-control m-b-5`}
                            value={accountInfo.tenant.name}
                          />
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Login / Email <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="login"
                            label="login"
                            className={`form-control m-b-5  ${
                              errors.login && touched.login ? 'is-invalid' : ''
                            }`}
                            placeholder="Enter email address"
                          />
                          {errors.login && touched.login ? (
                            <div className="invalid-feedback">
                              {errors.login}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          First Name <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="firstName"
                            label="firstName"
                            className={`form-control m-b-5  ${
                              errors.firstName && touched.firstName
                                ? 'is-invalid'
                                : ''
                            }`}
                            placeholder="Enter first name"
                          />
                          {errors.firstName && touched.firstName ? (
                            <div className="invalid-feedback">
                              {errors.firstName}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Last Name <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="lastName"
                            label="lastName"
                            className={`form-control m-b-5  ${
                              errors.lastName && touched.lastName
                                ? 'is-invalid'
                                : ''
                            }`}
                            placeholder="Enter last name"
                          />
                          {errors.lastName && touched.lastName ? (
                            <div className="invalid-feedback">
                              {errors.lastName}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Profile <span className="text-danger"></span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            as="select"
                            name="profileId"
                            className={`form-control m-b-5  ${
                              errors.profileId && touched.profileId
                                ? 'is-invalid'
                                : ''
                            }`}
                          >
                            <option>Select a user profile</option>
                            {entityData?.map((profile) => (
                              <option
                                key={profile.profileId}
                                value={profile.id}
                              >
                                {profile.name}
                              </option>
                            ))}
                          </Field>
                          {errors.profileId && touched.profileId ? (
                            <div className="invalid-feedback">
                              {errors.profileId}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <div class="col-md-9 offset-md-3">
                          <div
                            className="custom-control custom-checkbox"
                            onClick={() => {
                              setFieldValue('activated', !isActivated);
                              setIsActivated(!isActivated);
                            }}
                          >
                            <Field
                              type="checkbox"
                              name="activated"
                              label="activated"
                              className={`form-control m-b-5 custom-control-input  ${
                                errors.activated && touched.activated
                                  ? 'is-invalid'
                                  : ''
                              }`}
                            />
                            <label
                              class="custom-control-label"
                              onClick={() => setIsActivated(!isActivated)}
                            >
                              Activated
                            </label>
                          </div>
                          {errors.activated && touched.activated ? (
                            <div className="invalid-feedback">
                              {errors.activated}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <button
                        type="submit"
                        className="btn btn-primary btn-block btn-lg m-b-5"
                        disabled={!isValid || isSubmitted}
                      >
                        Submit
                      </button>
                    </Form>
                  );
                }}
              </Formik>
            </Panel>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserManagementCreate;
