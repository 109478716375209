import React from 'react';

const FormSteps = ({
  totalSteps,
  currentStep,
  setFormSteps,
  isValid,
  isSubmitted,
}) => {
  return (
    <div>
      {currentStep > 1 && (
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            setFormSteps(currentStep - 1);
          }}
        >
          Back
        </button>
      )}
      {currentStep < totalSteps && (
        <button
          type="button"
          className="btn btn-primary ml-2"
          onClick={() => {
            setFormSteps(currentStep + 1);
          }}
        >
          Next
        </button>
      )}
      {currentStep == totalSteps && (
        <button
          type="submit"
          className="btn btn-primary ml-2 m-b-5"
          disabled={!isValid || isSubmitted}
        >
          Submit
        </button>
      )}
    </div>
  );
};

export default FormSteps;
