export const getBillingAccounts = () => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/tenant-billing';
  return fetch(baseUrl, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getBillingAccount = (id) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/billing-accounts';
  const requestUrl = `${baseUrl}/${id}`;
  return fetch(requestUrl, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const createBillingAccount = (billingAccount) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/billing-accounts';
  return fetch(baseUrl, {
    method: 'POST',
    body: JSON.stringify(billingAccount),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const updateBillingAccount = (billingAccount) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/billing-accounts';
  return fetch(baseUrl, {
    method: 'PUT',
    body: JSON.stringify(billingAccount),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const deleteBillingAccount = (id) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/billing-accounts';
  const requestUrl = `${baseUrl}/${id}`;
  return fetch(requestUrl, {
    method: 'DELETE',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};
