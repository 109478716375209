import React, { useEffect, useState, Fragment } from "react";
import { Routes, Route, useLocation, Link } from "react-router-dom";

import Regions from "./Regions";
import RegionDetail from "./RegionDetail";
import RegionUpdate from "./RegionUpdate";
// import RegionDelete from "./RegionDeleteDialog";
import { getRegions } from "./api/regionsApi";

const RegionsContainer = () => {
  const location = useLocation();

  const [entities, setEntities] = useState([
    {
      availabilityZone: "ap-southeast-2",
      description: "Perth, Australia",
      id: 1,
      name: "Perth",
      title: "acp-au-perth",
      uid: "14e9dc88-a2fd-442c-a9a0-db7c36d2c4d0",
    },
  ]);
  const [appState, setAppState] = useState({
    loading: false,
    err: false,
  });

  useEffect(() => {
    (async () => {
      try {
        setAppState({ loading: true, err: false });
        const res = await getRegions().then((res) => res.json());
        setAppState({ loading: false });
        setEntities(res);
      } catch (err) {
        setAppState({ loading: false, err: true });
      }
    })();
  }, []);

  return (
    <Fragment>
      <ol className="breadcrumb page-breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">ActivePort</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to="/administration">Administration</Link>
        </li>
        <li className="breadcrumb-item active">Regions</li>
        <li className="position-absolute pos-top pos-right d-none d-sm-block">
          <span className="js-get-date"></span>
        </li>
      </ol>
      <div className="subheader">
        <h1 className="subheader-title">
          <i className="subheader-icon fal fa-table"></i> Regions
        </h1>

        {!location.pathname.includes("/new") &&
          !location.pathname.includes("/edit") && (
            <Link to={`new`}>
              <button
                type="button"
                className="btn btn-success waves-effect waves-themed"
              >
                <span className="fal fa-plus mr-1"></span>
                Create
              </button>
            </Link>
          )}
      </div>
      {!appState.loading && (
        // && !appState.err
        <Routes>
          <Route exact path={`/new`} element={<RegionUpdate />} />
          <Route exact path={`/edit/:id`} element={<RegionUpdate />} />
          <Route exact path={`:id`} element={<RegionDetail />} />

          <Route path={`/`} element={<Regions entities={entities} />} />
        </Routes>
      )}
      {appState.loading && !appState.error && (
        <div className="border p-3">
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      )}
      {!appState.loading && appState.err && (
        <div className="alert alert-danger" role="alert">
          <strong>Oh snap!</strong> We are not able to connect to the database.
          Try again in few minutes. again.
        </div>
      )}
      {/* <Route
          exact
          path={`/:id/delete`}
          element={RegionDelete}
        /> */}
    </Fragment>
  );
};

export default RegionsContainer;
