import React from "react";
import { Field } from "formik";

export const Underlay = () => {
  return (
    <>
      <div className="form-group row m-b-15">
        <label className="col-form-label col-md-3">Primary Uplink Port</label>
        <div className="col-md-9">
          <Field type="text" name="uplinkPort" className="form-control m-b-5" />
        </div>
      </div>
      <div className="form-group row m-b-15">
        <label className="col-form-label col-md-3">Secondary Uplink Port</label>
        <div className="col-md-9">
          <Field
            type="text"
            name="secondUplinkPort"
            className="form-control m-b-5"
          />
        </div>
      </div>
      <div className="form-group row m-b-15">
        <label className="col-form-label col-md-3">NTU Loopback</label>
        <div className="col-md-9">
          <Field
            type="text"
            name="ipAddress"
            className="form-control m-b-5"
            readOnly
          />
        </div>
      </div>

      <div className="form-group row m-b-15">
        <label className="col-form-label col-md-3">PE Loopback</label>
        <div className="col-md-9">
          <Field
            type="text"
            name="peLoopbackIP"
            className="form-control m-b-5"
          />
        </div>
      </div>
    </>
  );
};
