import React, { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";

import Panel from "../../../common/Panel/Panel";
import { getGpuServer } from "./api/gpuServersApi";
import { convertCamelCaseToCapitalize } from "../../../utils/string-transformers";

const GpuServerDetail = () => {
  const match = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [gpuServerEntity, setGpuServerEntity] = useState({
    id: 1,
    name: "exotic-camel",
    displayName: "PERTH GPU 1",
    ipAddress: "103.228.188.245",
    uid: "a8806a89-dd22-45d0-b9f8-03860b749b91",
    serverState: "ENABLED",
    type: "GPU_SERVER",
    publicIp: "103.228.188.246",
    regionId: 1,
    regionName: "Perth",
    availabilityZone: "ap-southeast-2",
    networkName: null,
    networkId: null,
    ifaceOut: "eno2",
    maxCpus: 32,
    maxMemory: 128000,
    request: null,
    reponse: null,
  });
  const [appState, setAppState] = useState({
    loading: false,
  });

  useEffect(() => {
    setAppState({ loading: true });
    const fetchData = async () => {
      try {
        const result = await getGpuServer(match.id).then((res) => res.json());
        setGpuServerEntity(result);
        console.log("GpuServer Detail: ", result);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    setAppState({ loading: false });
  }, [location]);

  return (
    <div>
      <div className="row">
        <div className="col-xl-12">
          {appState.loading && <p>Loading...</p>}
          {gpuServerEntity !== null && !appState.loading && (
            <Panel>
              <dl className="row">
                {Object.keys(gpuServerEntity).map((entityKey) => (
                  <>
                    <dt className="text-inverse  col-4 ">
                      <span id="command">
                        <span>{convertCamelCaseToCapitalize(entityKey)}</span>
                      </span>
                    </dt>
                    <dd className="col-8 ">{gpuServerEntity[entityKey]}</dd>
                  </>
                ))}
              </dl>

              <div>
                <button
                  className="btn btn-sm btn-secondary mr-3"
                  onClick={() => navigate("../")}
                >
                  <i className="fal fa-arrow-left fa-sm  mr-2"></i>
                  Back
                </button>
                <button
                  className="btn btn-sm btn-primary m-r-5"
                  onClick={() => navigate("../edit/" + match.id)}
                >
                  <i className="fal fa-pencil-alt fa-sm  mr-2"></i>
                  Edit
                </button>
              </div>
            </Panel>
          )}
        </div>
      </div>
    </div>
  );
};

export default GpuServerDetail;
