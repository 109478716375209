import React, { useState } from "react";

import { ServicesBP1 } from "../ServicesBP1";
import { AddIBMPortForm } from "./AddIBMPortForm";

const IBMCloudContainer = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <ServicesBP1
        title="IBM Cloud"
        subTitle="Configure an IBM Cloud Service"
        addBtnText="Add IBM Port"
        showModal={showModal}
        setShowModal={setShowModal}
        serviceLogoUrl="/assets/img/ntu/IBM-TILE.png"
      />
      <AddIBMPortForm
        show={showModal}
        setShow={setShowModal}
        //   onSave={onSave}
      />
    </>
  );
};

export default IBMCloudContainer;
