import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Panel from '../../../common/Panel/Panel';
import Table from '../../../common/Table';

import { handleDeleteAlert } from '../../../common/Alerts/DeleteAlert';
const TilesConfig = ({ entities }) => {
  const data = entities;

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'row_id',
        sortable: true,
        Cell: ({ row }) => {
          return (
            <div>
              <Link to={`${row.original.tenantId}`}>{row.original.id}</Link>
            </div>
          );
        },
      },
      {
        Header: 'Name',
        accessor: 'name',
        sortable: true,
      },
      {
        Header: 'Tiles',
        accessor: 'serviceTypeCode',
        sortable: true,
      },
      {
        Header: 'Services',
        accessor: (d) =>
          d.services.map((item) => {
            // var index = CIRCUIT_TYPES[item]; // 1

            return (
              <button class="btn btn-light mr-1 mb-1">
                {item.name} [ {item.providerName} - {item.providerType} -{' '}
                {item.locationCode} -{' '}
                {item.hostedTypes.map((hostedType, key) => {
                  const rowLen = item.hostedTypes.length;
                  if (rowLen == key + 1) {
                    return <>{hostedType}</>;
                  }
                  return <>{hostedType}, </>;
                })}{' '}
                ]
              </button>
            );
          }),
        sortable: true,
      },

      {
        Header: '',
        accessor: 'actionColumns',
        className: 'align-middle',
        Cell: ({ row }) => {
          return (
            <div className="text-center d-flex">
              <Link
                className="btn btn-icon btn-circle btn-default mr-2"
                to={`${row.original.id}`}
              >
                <i className="fal fa-eye"></i>
              </Link>
              <Link
                className="btn btn-icon btn-circle btn-success mr-2"
                to={`${row.original.id}/edit`}
              >
                <i className="fal fa-pencil-alt"></i>
              </Link>
              <button
                className="btn btn-icon btn-circle btn-danger"
                onClick={handleDeleteAlert}
              >
                <i className="fal fa-trash"></i>
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <Panel>
      <Table data={data} columns={columns} />
    </Panel>
  );
};

export default TilesConfig;
