import React, { useState } from "react";

import { ServicesBP1 } from "../ServicesBP1";
import { AddInternetForm } from "./AddInternetForm";

const Oracle = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <ServicesBP1
        title="Oracle Cloud"
        subTitle="Configure ORACLE Service"
        addBtnText="Add ORACLE Service"
        showModal={showModal}
        setShowModal={setShowModal}
        serviceLogoUrl="/assets/img/ntu/ORACLE-TILE.png"
      />
      <AddInternetForm
        show={showModal}
        setShow={setShowModal}
        //   onSave={onSave}
      />
    </>
  );
};

export default Oracle;
