export const getCircuitConfigurations = () => {
  const token = localStorage.getItem("token");
  const baseUrl =
    "https://test.activeport.com.au/api/circuit-vlans?size=99999999";

  return fetch(baseUrl, {
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getCircuitConfiguration = (id) => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/api/circuit-vlans/";

  const requestUrl = `${baseUrl}${id}`;
  return fetch(requestUrl, {
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const createCircuitConfiguration = (circuitConfiguration) => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/api/circuit-vlans";

  return fetch(baseUrl, {
    method: "POST",
    body: JSON.stringify(circuitConfiguration),
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const updateCircuitConfiguration = (circuitConfiguration) => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/api/circuit-vlans/";

  return fetch(baseUrl, {
    method: "PUT",
    body: JSON.stringify(circuitConfiguration),
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const deleteCircuitConfiguration = (id) => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/api/circuit-vlans/";
  const requestUrl = `${baseUrl}${id}`;
  return fetch(requestUrl, {
    method: "DELETE",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getServiceConfigurations = () => {
  const token = localStorage.getItem("token");
  const baseUrl =
    "https://test.activeport.com.au/api/service-configurations/list?creationType=CIRCUITVLAN&profile=";
  const requestUrl = `${baseUrl}`;
  return fetch(requestUrl, {
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};
