import React, { useEffect, useState, Fragment } from 'react';
import {
  Routes,
  Route,
  useLocation,
  Link,
  useNavigate,
} from 'react-router-dom';
import { deleteMediaQuery, getMedia } from './api/mediaApi';
import ImageSelector from './ImageSelector';

const ImageSelectorContainer = ({ getFileName, field, setFieldValue }) => {
  console.log(field);
  const location = useLocation();
  const navigate = useNavigate();
  const [entities, setEntities] = useState([]);
  const [appState, setAppState] = useState({
    loading: false,
    err: false,
  });

  useEffect(() => {
    (async () => {
      try {
        setAppState({ loading: true, err: false });
        const res = await getMedia().then((res) => res.json());

        setEntities(res);
      } catch (err) {
        setAppState({ loading: false, err: true });
      }
    })();
    setAppState({ loading: false });
  }, [location]);

  return (
    <Fragment>
      <div className="subheader">
        <h1 className="subheader-title">
          <i className="subheader-icon fal fa-table"></i> Media Files
        </h1>
      </div>
      {!appState.loading && !appState.err && (
        <ImageSelector
          entities={entities}
          getFileName={getFileName}
          field={field}
          setFieldValue={setFieldValue}
        />
      )}
      {appState.loading && !appState.error && (
        <div className="border p-3">
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      )}
      {!appState.loading && appState.err && (
        <div className="alert alert-danger" role="alert">
          <strong>Oh snap!</strong> We are not able to connect to the database.
          Try again in few minutes. again.
        </div>
      )}
      {/* <Route
          exact
          path={`/:id/delete`}
          element={TenantDelete}
        /> */}
    </Fragment>
  );
};

export default ImageSelectorContainer;
