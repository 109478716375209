import React, { useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Panel from '../../../common/Panel/Panel';
import Table from '../../../common/Table';

import { handleDeleteAlert } from '../../../common/Alerts/DeleteAlert';
const Tenants = ({ entities }) => {
  const data = entities;

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'row_id',
        sortable: true,
        Cell: ({ row }) => {
          return (
            <div>
              <Link to={`${row.original.tenantId}`}>{row.original.id}</Link>
            </div>
          );
        },
      },
      {
        Header: 'Name',
        accessor: 'name',
        sortable: true,
      },
      {
        Header: 'Description',
        accessor: 'description',
        sortable: true,
      },
      {
        Header: 'Tenant ID',
        accessor: 'tenantId',
        sortable: true,
      },
      {
        Header: 'Tiles',
        accessor: (d) =>
          d.tiles?.map((tile) => (
            <button className="btn btn-light mr-1 mb-1" key={tile.tileId}>
              {tile.tileName}
            </button>
          )),
        sortable: true,
      },
      {
        Header: 'SD-WAN Support',
        accessor: (d) => (
          <div className="d-flex justify-content-center">
            <p
              className={`text-center p-2 bg-${
                d.sdWan ? 'success' : 'danger'
              } text-white  rounded`}
            >
              {d.sdWan ? 'Activated' : 'Deactivated'}
            </p>
          </div>
        ),
        sortable: true,
      },
      {
        Header: '',
        accessor: 'actionColumns',
        className: 'align-middle',
        Cell: ({ row }) => {
          return (
            <div className="text-center d-flex">
              <Link
                className="btn btn-icon btn-circle btn-default mr-2"
                to={`${row.original.tenantId}`}
              >
                <i className="fal fa-eye"></i>
              </Link>
              <Link
                className="btn btn-icon btn-circle btn-success mr-2"
                to={`../edit/${row.original.tenantId}`}
              >
                <i className="fal fa-pencil-alt"></i>
              </Link>
              <button
                className="btn btn-icon btn-circle btn-danger"
                onClick={handleDeleteAlert}
              >
                <i className="fal fa-trash"></i>
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <Panel>
      <Table data={data} columns={columns} />
    </Panel>
  );
};

export default Tenants;
