import { useState, useContext, useEffect } from "react";
import AuthContext from "../store/auth-context";

const userAuthorities = JSON.parse(
  localStorage.getItem("accountInfo")
)?.authorities;

export const useAuth = (specific_roles) => {
  const AthCtx = useContext(AuthContext);
  const [hasAuthority, setHasAuthority] = useState(false);

  let roles = userAuthorities;
  if (specific_roles) {
    roles = specific_roles;
  }

  useEffect(() => {
    if (roles) {
      const checkAuth = AthCtx.hasAuth(roles);
      setHasAuthority(checkAuth);
    }
  }, [AthCtx, roles]);

  return {
    hasAuthority,
  };
};
