import Locations from '../components/Settings/Locations';
import NTU from '../components/Settings/NTU';
import Settings from '../components/Settings';
import ServiceCommands from '../components/Settings/ServiceCommands';
import ServiceConfig from '../components/Settings/ServiceConfig';
import TilesConfig from '../components/Settings/TilesConfig';
import ProviderConfig from '../components/Settings/ProviderConfig';
import ProviderPorts from '../components/Settings/ProviderPorts';
import ProviderCredentials from '../components/Settings/ProviderCredentials';
import CircuitConfig from '../components/Settings/CircuitConfig';
import PEDownlinkPorts from '../components/Settings/PEDownlinkPorts';
import SDWANAggregators from '../components/Settings/SDWANAggregators';
import NFVConfig from '../components/Settings/NFVConfig';
import NFVBucket from '../components/Settings/NFVBucket';
import NFVProfiles from '../components/Settings/NFVProfiles';
import PE from '../components/Settings/PE';
import SDWANConfig from '../components/Settings/SDWANConfig';
import CircuitConfigurations from '../components/Settings/CircuitConfigurations';
import SubnetConfigurations from '../components/Settings/SubnetConfigurations';
import SDWANServers from '../components/Settings/SDWANServers';

export const SettingsRoutes = [
  {
    element: <Locations />,
    path: '/settings/locations/*',
  },
  {
    element: <ServiceCommands />,
    path: '/settings/service-commands/*',
  },
  {
    element: <ServiceConfig />,
    path: '/settings/service-config/*',
  },
  {
    element: <TilesConfig />,
    path: '/settings/tiles-config/*',
  },
  {
    element: <ProviderConfig />,
    path: '/settings/provider-config/*',
  },
  {
    element: <ProviderPorts />,
    path: '/settings/provider-ports/*',
  },
  {
    element: <ProviderCredentials />,
    path: '/settings/provider-credentials/*',
  },
  {
    element: <CircuitConfig />,
    path: '/settings/circuit-config/*',
  },
  {
    element: <NTU />,
    path: '/settings/ntu/*',
  },
  {
    element: <PEDownlinkPorts />,
    path: '/settings/pe-downlink-ports/*',
  },
  {
    element: <PE />,
    path: '/settings/pe/*',
  },
  {
    element: <SDWANConfig />,
    path: '/settings/sd-wan-config/*',
  },
  {
    element: <SDWANAggregators />,
    path: '/settings/sd-wan-aggregators/*',
  },
  {
    element: <NFVConfig />,
    path: '/settings/nfv-config/*',
  },
  {
    element: <NFVBucket />,
    path: '/settings/nfv-bucket/*',
  },
  {
    element: <NFVProfiles />,
    path: '/settings/nfv-os/*',
  },
  {
    element: <CircuitConfigurations />,
    path: '/settings/circuit-vlan/*',
  },
  {
    element: <SubnetConfigurations />,
    path: '/settings/realm-ip/*',
  },
  {
    element: <SDWANServers />,
    path: '/settings/sd-wan-servers/*',
  },
  {
    element: <Settings />,
    path: '/settings/*',
  },
];
