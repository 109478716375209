import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";

import * as Yup from "yup";
import Panel from "../../../common/Panel/Panel";
import Alert from "../../../common/AlertControls/Alert";
import { handleCreateNewAlert } from "../../../common/Alerts/CreateNewAlert";
import { handleUpdateAlert } from "../../../common/Alerts/EditAlert";

import {
  createHelpContent,
  getHelpContent,
  updateHelpContent,
} from "./api/helpContentsApi";

const HelpContentUpdate = () => {
  const match = useParams();
  const navigate = useNavigate();
  let defaultValues = {
    id: "",
    name: "",
    description: "",
    sortOrder: "",
  };
  const [initialData, setInitialData] = useState(defaultValues);
  const [appState, setAppState] = useState({
    loading: true,
  });

  const isNew = !match.id;
  const [alertShow, setAlertShow] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  // let alertShow = false;

  const schema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(250, "Too Long!")
      .required("Required"),
    description: Yup.string()
      .min(2, "Too Short!")
      .max(250, "Too Long!")
      .required("Required"),
    sortOrder: Yup.number()
      .min(0, "Too Short!")
      .max(250, "Too Long!")
      .required("Required"),
  });

  async function createNewHelpContent(helpContent) {
    const response = await createHelpContent(helpContent).then((res) =>
      res.json()
    );
    if (response.id) {
      handleCreateNewAlert(
        'New helpContent "' + helpContent.name + '" added successfully!'
      );
      navigate("../");
    }
  }

  async function updateHelpContents(helpContent) {
    const response = await updateHelpContent(helpContent).then((res) =>
      res.json()
    );

    if (response.id) {
      console.log("respnise id", response.id);
      handleUpdateAlert("helpContent", response.id);
      navigate("../" + response.id);
    }
  }

  function submitForm(formValues) {
    isNew ? createNewHelpContent(formValues) : updateHelpContents(formValues);
  }

  // effect runs on component mount
  useEffect(() => {
    let unmounted = false;
    // simulate async api call with set timeout
    const fetchHelpContent = async () => {
      try {
        const result = await getHelpContent(match.id).then((res) => res.json());
        if (!unmounted) {
          console.log("result: ", result);
          if (result.description == null) result.description = "";
          setInitialData(result);
          //reset(result);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (!isNew) fetchHelpContent();
    setAppState({ loading: false });
    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-xl-9">
          {alertShow && (
            <Alert
              alertType="alert-success"
              msg="The helpContent has been updated."
            />
          )}

          {!appState.loading && initialData && (
            <Panel heading={initialData.name}>
              <Formik
                enableReinitialize
                initialValues={initialData}
                validationSchema={schema}
                validateOnMount
                onSubmit={submitForm}
              >
                {({ errors, touched, isValid, setFieldValue, values }) => {
                  return (
                    <Form>
                      {!isNew && (
                        <div className="form-group row m-b-15">
                          <label className="col-form-label col-md-3">ID</label>
                          <div className="col-md-9">
                            <Field
                              type="text"
                              name="id"
                              label="id"
                              className="form-control m-b-5"
                              readOnly
                            />
                          </div>
                        </div>
                      )}

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Name <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="name"
                            label="name"
                            className={`form-control m-b-5  ${
                              errors.name && touched.name ? "is-invalid" : ""
                            }`}
                            placeholder="Enter Name"
                          />
                          {errors.name && touched.name ? (
                            <div className="invalid-feedback">
                              {errors.name}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Description <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="textarea"
                            as="textarea"
                            name="description"
                            label="description"
                            className={`form-control m-b-5  ${
                              errors.description && touched.description
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder="Enter Description"
                          />
                          {errors.description && touched.description ? (
                            <div className="invalid-feedback">
                              {errors.description}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Sort Order <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="number"
                            name="sortOrder"
                            label="sortOrder"
                            className={`form-control m-b-5  ${
                              errors.sortOrder && touched.sortOrder
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder="Enter HelpContent Sort Order"
                          />
                          {errors.sortOrder && touched.sortOrder ? (
                            <div className="invalid-feedback">
                              {errors.sortOrder}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary btn-block btn-lg m-b-5"
                        disabled={!isValid || isSubmitted}
                      >
                        Submit
                      </button>
                    </Form>
                  );
                }}
              </Formik>
            </Panel>
          )}
        </div>
      </div>
    </div>
  );
};

export default HelpContentUpdate;
