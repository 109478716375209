import React from "react";

export const DeviceConfigFields = ({ obj, fields, path }) => {
  return (
    <>
      {Object.keys(obj).map((key) => {
        let item = fields.find((el) => el.value === key);
        if (item) {
          const value = obj[key];
          return (
            <>
              <dt key={item.value} className="text-inverse col-2">
                {item.label}
              </dt>
              <dd className="col-10">{`{{${path}.${key}}}${
                value && ` => ${value}`
              }`}</dd>
            </>
          );
        } else {
          return <></>;
        }
      })}
    </>
  );
};

export const DeviceConfigField = ({ label, value, path }) => {
  return (
    <>
      <dt className="text-inverse col-2">{label}</dt>
      <dd className="col-10">
        {`{{#${path}}}} ${value ? ` => ${value}` : ""}`}
      </dd>
      <dt className="col-2" />
      <dd className="col-10">
        {`{{/${path}}}} ${value ? ` => ${value}` : ""}`}
      </dd>
    </>
  );
};

export const BasicConfigField = ({ label, value }) => {
  return (
    <>
      <dt className="text-inverse col-2">{label}</dt>
      <dd className="col-10">{value}</dd>
    </>
  );
};
