import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Formik, Form, Field, yupToFormErrors } from 'formik';
import * as Yup from 'yup';
import Panel from '../../../common/Panel/Panel';

import {
  createUserProfile,
  getUserProfile,
  updateUserProfile,
  getUserRoles,
} from './api/userProfilesApi';

const TenantUpdate = () => {
  const match = useParams();
  const navigate = useNavigate();
  const [id, setId] = useState(match.id);
  const [userRoles, setUserRoles] = useState([]);
  const [initialData, setInitialData] = useState({
    name: '',
    description: '',
    userRole: '',
  });
  const [appState, setAppState] = useState({
    loading: false,
  });
  const [isNew, setIsNew] = useState(!match.params || !match.id);

  const schema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Too Short!')
      .max(250, 'Too Long!')
      .required('Required'),
    description: Yup.string()
      .min(2, 'Too Short!')
      .max(250, 'Too Long!')
      .required('Required'),
    userRole: Yup.string(),
  });

  async function submitNewUserProfile(userProfile) {
    const response = await createUserProfile(userProfile);
    if (response.id) {
      navigate({
        pathname: '/administration/user-profiles',
        state: { id: response.id, action: 'Created' },
      });
    }
  }

  async function updateUserProfiles(userProfile) {
    const response = await updateUserProfile(userProfile);
    if (response.id) {
      navigate({
        pathname: '/administration/user-profiles',
        state: { id: response.id, action: 'Updated' },
      });
    }
  }

  useEffect(() => {
    if (!isNew) {
      setAppState({ loading: true });
      setId(match.id);
      const fetchData = async () => {
        try {
          const result = await getUserProfile(match.id).then((res) =>
            res.json()
          );
          console.log(result);
          setInitialData(result);
        } catch (error) {
          console.log(error);
        }
      };
      fetchData();
      setAppState({ loading: false });
    }
  }, []);

  useEffect(() => {
    const fetchUserRoles = async () => {
      try {
        const res = await getUserRoles().then((res) => res.json());
        setUserRoles(res);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUserRoles();
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-xl-9">
          {appState.loading && <p>Loading...</p>}
          {!appState.loading && (
            <Panel heading={initialData.name}>
              <Formik
                enableReinitialize
                initialValues={initialData}
                validationSchema={schema}
                validateOnMount
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  isNew
                    ? submitNewUserProfile(values)
                    : updateUserProfiles(values);
                }}
              >
                {({ errors, touched, isValid, setFieldValue, values }) => {
                  return (
                    <Form>
                      {!isNew && (
                        <div className="form-group row m-b-15">
                          <label className="col-form-label col-md-3">ID</label>
                          <div className="col-md-9">
                            <Field
                              readOnly
                              type="number"
                              name="id"
                              label="id"
                              className={`form-control m-b-5  ${
                                errors.name && touched.name ? 'is-invalid' : ''
                              }`}
                            />
                          </div>
                        </div>
                      )}
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Name <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="name"
                            label="name"
                            className={`form-control m-b-5  ${
                              errors.name && touched.name ? 'is-invalid' : ''
                            }`}
                            placeholder="Enter Profile Name"
                          />
                          {errors.name && touched.name ? (
                            <div className="invalid-feedback">
                              {errors.name}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Description <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="textarea"
                            as="textarea"
                            name="description"
                            label="description"
                            className={`form-control m-b-5  ${
                              errors.description && touched.description
                                ? 'is-invalid'
                                : ''
                            }`}
                            placeholder="Enter Description"
                          />
                          {errors.command && touched.command ? (
                            <div className="invalid-feedback">
                              {errors.command}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          User Role <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="userRole"
                            label="userRole"
                            as="select"
                            className={`form-control m-b-5  ${
                              errors.tenantId && touched.tenantId
                                ? 'is-invalid'
                                : ''
                            }`}
                            placeholder="Enter Tenant Id"
                          >
                            {userRoles?.map((opt) => (
                              <option key={opt.id} value={opt.name}>
                                {opt.name}
                              </option>
                            ))}
                          </Field>
                          {errors.tenantId && touched.tenantId ? (
                            <div className="invalid-feedback">
                              {errors.tenantId}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <button
                        type="submit"
                        className="btn btn-primary btn-block btn-lg m-b-5"
                        disabled={!isValid}
                      >
                        Submit
                      </button>
                    </Form>
                  );
                }}
              </Formik>
            </Panel>
          )}
        </div>
      </div>
    </div>
  );
};

export default TenantUpdate;
