/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import * as Yup from "yup";
import Panel from "../../../common/Panel/Panel";
import Alert from "../../../common/AlertControls/Alert";
import { handleCreateNewAlert } from "../../../common/Alerts/CreateNewAlert";
import { handleUpdateAlert } from "../../../common/Alerts/EditAlert";
import {
  createTemplateConfiguration,
  getTemplateConfiguration,
  updateTemplateConfiguration,
  getDeviceConfigurations,
} from "./api/templateConfigurationsApi";
import { TemplateConfigurationForm } from "./TemplateConfigurationForm";
import {
  DeviceConfigFields,
  DeviceConfigField,
  BasicConfigField,
} from "./DeviceConfigFields";

const TemplateConfigurationUpdate = () => {
  const ntu = localStorage.getItem("ntu");

  const match = useParams();
  const navigate = useNavigate();

  let defaultValues = {
    id: "",
    name: "",
    osType: "JUNOS",
    modelList: [],
    description: "",
    configuration: "",
  };

  const [initialData, setInitialData] = useState(defaultValues);
  const [appState, setAppState] = useState({
    loading: true,
  });

  const isNew = !match.id;

  const [activeTab, setActiveTab] = useState("Configuration");
  const [alertShow, setAlertShow] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [deviceConfiguration, setDeviceConfiguration] = useState({});

  const schema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(250, "Too Long!")
      .required("Required"),
    description: Yup.string()
      .min(2, "Too Short!")
      .max(250, "Too Long!")
      .required("Required"),
  });

  async function createNewTemplateConfiguration(templateConfiguration) {
    const response = await createTemplateConfiguration(
      templateConfiguration
    ).then((res) => res.json());
    if (response.id) {
      handleCreateNewAlert(
        'New templateConfiguration "' +
          templateConfiguration.name +
          '" added successfully!'
      );
      navigate("../");
    }
  }

  async function updateTemplateConfigurations(templateConfiguration) {
    const response = await updateTemplateConfiguration(
      templateConfiguration
    ).then((res) => res.json());

    if (response.id) {
      console.log("respnise id", response.id);
      handleUpdateAlert("templateConfiguration", templateConfiguration.id);
      navigate("../" + templateConfiguration.templateConfigurationId);
    }
  }

  function submitForm(formValues) {
    isNew
      ? createNewTemplateConfiguration(formValues)
      : updateTemplateConfigurations(formValues);
  }

  // effect runs on component mount
  useEffect(() => {
    let unmounted = false;
    // simulate async api call with set timeout
    const fetchTemplateConfiguration = async () => {
      try {
        const result = await getTemplateConfiguration(match.id).then(
          (res) => res.data
        );
        if (!unmounted) {
          if (result.description == null) result.description = "";
          setInitialData(result);
          //reset(result);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const fetchDeviceConfiguration = async () => {
      const deviceConfig = await getDeviceConfigurations().then(
        (res) => res.data
      );
      setDeviceConfiguration(deviceConfig);
    };

    if (!isNew) fetchTemplateConfiguration();

    if (ntu) fetchDeviceConfiguration();

    setAppState({ loading: false });
    return () => {
      unmounted = true;
    };
  }, [ntu]);

  const InitialForm = useMemo(
    () => (
      <TemplateConfigurationForm
        initialData={initialData}
        isNew={isNew}
        isSubmitted={isSubmitted}
        submitForm={submitForm}
      />
    ),
    [initialData, isNew, isSubmitted]
  );

  let ActiveForm = null;
  if (ntu) {
    switch (activeTab) {
      case "Configuration":
        ActiveForm = InitialForm;
        break;
      case "NTU Field":
        ActiveForm = (
          <div className="mt-5">
            <h1>{deviceConfiguration.scopes.ntu.name}</h1>
            <hr />
            <dl className="row">
              <DeviceConfigFields
                fields={[
                  {
                    label: "Name",
                    value: "name",
                  },
                  {
                    label: "Tenant ID",
                    value: "tenantId",
                  },
                  {
                    label: "description",
                    value: "Description",
                  },
                  {
                    label: "Serial Number",
                    value: "serialNumber",
                  },
                  {
                    label: "NTU Loopback",
                    value: "ipAddress",
                  },
                  {
                    label: "PE Loopback",
                    value: "peLoopbackIP",
                  },
                  {
                    label: "Firmware Version",
                    value: "firmwareVersion",
                  },
                  {
                    label: "Rest Endpoint",
                    value: "endpoint",
                  },
                  {
                    label: "Rest Username",
                    value: "restUsername",
                  },
                  {
                    label: "Rest Enabled",
                    value: "restEnabled",
                  },
                  {
                    label: "Mode",
                    value: "mode",
                  },
                  {
                    label: "Time Zone",
                    value: "timeZone",
                  },
                  {
                    label: "Min Rate",
                    value: "minRate",
                  },
                  {
                    label: "Max Rate",
                    value: "maxRate",
                  },
                  {
                    label: "Default Rate",
                    value: "defaultRate",
                  },
                  {
                    label: "Enable Bod",
                    value: "enableBod",
                  },
                  {
                    label: "Burst Time",
                    value: "burstTime",
                  },
                  {
                    label: "Primary Uplink Port",
                    value: "uplinkPort",
                  },
                  {
                    label: "Secondary Uplink Port",
                    value: "secondUplinkPort",
                  },
                ]}
                obj={deviceConfiguration.scopes.ntu}
                p
                path="ntu"
              />
              <dt className="text-inverse col-2 mb-2">
                <u>Global Attributes</u>
              </dt>
              <dd className="col-10" />
              <DeviceConfigFields
                obj={
                  deviceConfiguration.scopes.ntu.globalAttributes.defaultApiPort
                }
                fields={[
                  {
                    label: "[defaultApiPort]",
                    value: "value",
                  },
                ]}
                path="ntu.globalAttributes.defaultApiPort"
              />
              <DeviceConfigFields
                obj={
                  deviceConfiguration.scopes.ntu.globalAttributes
                    .defaultRestPort
                }
                fields={[
                  {
                    label: "[defaultRestPort]",
                    value: "value",
                  },
                ]}
                path="ntu.globalAttributes.defaultRestPort"
              />
              <dt className="text-inverse col-2 mb-2">
                <u>Attributes</u>
              </dt>
              <dd className="col-10" />
              <DeviceConfigField
                label="Syslog Hosts"
                path="ntu.syslogHosts.entrySet"
                value={deviceConfiguration.scopes.syslogHosts?.entrySet}
              />
              <DeviceConfigField
                label="Netflow Hosts"
                path="ntu.netflowHosts.entrySet"
                value={deviceConfiguration.scopes.netflowHosts?.entrySet}
              />
              <DeviceConfigField
                label="Apptrack"
                path="ntu.apptrack.entrySet"
                value={deviceConfiguration.scopes.apptrack?.entrySet}
              />
            </dl>
          </div>
        );
        break;
      case "Location Fields":
        ActiveForm = (
          <div className="mt-5">
            <h1>Location</h1>
            <hr />
            <dl className="row">
              <DeviceConfigFields
                obj={deviceConfiguration.scopes.location}
                fields={[
                  {
                    label: "Name",
                    value: "name",
                  },
                  {
                    label: "Default Scope",
                    value: "scopeName",
                  },
                ]}
                path="location"
              />
              <dt className="text-inverse col-2 mb-2">
                <u>Attributes</u>
              </dt>
              <dd className="col-10" />
              <DeviceConfigFields
                obj={
                  deviceConfiguration.scopes.location.attributes
                    .cloudPeLoopbackIP
                }
                fields={[
                  {
                    label: "[cloudPeLoopbackIP]",
                    value: "value",
                  },
                ]}
                path="location.globalAttributes.cloudPeLoopbackIP"
              />
              <DeviceConfigFields
                obj={
                  deviceConfiguration.scopes.location.attributes.cloudPePublicIP
                }
                fields={[
                  {
                    label: "[cloudPePublicIP]",
                    value: "value",
                  },
                ]}
                path="location.globalAttributes.cloudPePublicIP"
              />
            </dl>
          </div>
        );
        break;

      case "Subnet Fields":
        ActiveForm = (
          <div className="mt-5">
            {/* <h1>{deviceConfiguration.scopes.ntu.subnets.cloud.}</h1>
            <hr /> */}
            <dl className="row">
              <BasicConfigField label="Subnet Scope" value="cloud" />
              <DeviceConfigFields
                obj={deviceConfiguration.scopes.ntu.subnets.cloud}
                fields={[
                  {
                    label: "Network CIDR",
                    value: "networkCidr",
                  },
                  {
                    label: "Network Address",
                    value: "network",
                  },
                  {
                    label: "Network Address",
                    value: "network",
                  },
                  {
                    label: "Broadcast",
                    value: "broadcast",
                  },
                  {
                    label: "CIDR Mask",
                    value: "cidr",
                  },
                  {
                    label: "Lower IP CIDR",
                    value: "lowerIPCidr",
                  },
                  {
                    label: "Upper IP CIDR",
                    value: "upperIPCidr",
                  },
                  {
                    label: "Upper IP",
                    value: "upperIP",
                  },
                  {
                    label: "Lower IP",
                    value: "lowerIP",
                  },

                  {
                    label: "Subnet Size",
                    value: "subnetSize",
                  },
                  {
                    label: "Mask",
                    value: "mask",
                  },
                  {
                    label: "Shared Key",
                    value: "sharedKey",
                  },
                ]}
                path="ntu.subnets.cloud"
              />
              <BasicConfigField label="Subnet Scope" value="edge" />
              <DeviceConfigFields
                obj={deviceConfiguration.scopes.ntu.subnets.edge}
                fields={[
                  {
                    label: "Network CIDR",
                    value: "networkCidr",
                  },
                  {
                    label: "Network Address",
                    value: "network",
                  },
                  {
                    label: "Network Address",
                    value: "network",
                  },
                  {
                    label: "Broadcast",
                    value: "broadcast",
                  },
                  {
                    label: "CIDR Mask",
                    value: "cidr",
                  },
                  {
                    label: "Lower IP CIDR",
                    value: "lowerIPCidr",
                  },
                  {
                    label: "Lower IP",
                    value: "lowerIP",
                  },
                  {
                    label: "Upper IP CIDR",
                    value: "upperIPCidr",
                  },
                  {
                    label: "Upper IP",
                    value: "upperIP",
                  },
                  {
                    label: "Subnet Size",
                    value: "subnetSize",
                  },
                  {
                    label: "Mask",
                    value: "mask",
                  },
                  {
                    label: "Shared Key",
                    value: "sharedKey",
                  },
                ]}
                path="ntu.subnets.cloud"
              />
              <BasicConfigField label="Subnet Scope" value="loopback" />
              <DeviceConfigFields
                obj={deviceConfiguration.scopes.ntu.subnets.loopback}
                fields={[
                  {
                    label: "Network CIDR",
                    value: "networkCidr",
                  },
                  {
                    label: "Network Address",
                    value: "network",
                  },
                  {
                    label: "Network Address",
                    value: "network",
                  },
                  {
                    label: "Broadcast",
                    value: "broadcast",
                  },
                  {
                    label: "CIDR Mask",
                    value: "cidr",
                  },
                  {
                    label: "Lower IP CIDR",
                    value: "lowerIPCidr",
                  },
                  {
                    label: "Lower IP",
                    value: "lowerIP",
                  },
                  {
                    label: "Upper IP CIDR",
                    value: "upperIPCidr",
                  },
                  {
                    label: "Upper IP",
                    value: "upperIP",
                  },
                  {
                    label: "Subnet Size",
                    value: "subnetSize",
                  },
                  {
                    label: "Mask",
                    value: "mask",
                  },
                  {
                    label: "Shared Key",
                    value: "sharedKey",
                  },
                ]}
                path="ntu.subnets.cloud"
              />
            </dl>
          </div>
        );
        break;

      case "Conditional Fields":
        ActiveForm = (
          <div className="mt-5">
            <h1>Conditionals</h1>
            <hr />
            <BasicConfigField label="Conditionals" />
            <div className="ml-5 row">
              {Object.keys(deviceConfiguration.scopes.conditional).map(
                (conditionKey) => (
                  <BasicConfigField
                    label={`[${conditionKey}]`}
                    value={`{{#conditional.${conditionKey}}} {{#conditional.${conditionKey}}} => ${deviceConfiguration.scopes.conditional[conditionKey]}`}
                  />
                )
              )}
            </div>
          </div>
        );
        break;

      case "Result Configuration":
        ActiveForm = (
          <div className="mt-5">
            <h4>Configuration</h4>
            <textarea
              readOnly
              style={{
                height: "30em",
                width: "62em",
                border: "1px solid #e4e5e7",
                padding: "6px 1px 6px 12px",
                overflowY: "scroll",
                resize: false,
              }}
            >
              {deviceConfiguration.commands}
            </textarea>
          </div>
        );
        break;

      default:
        break;
    }
  }

  return (
    <div>
      <div className="row">
        <div className="col-xl-9">
          {alertShow && (
            <Alert
              alertType="alert-success"
              msg="The templateConfiguration has been updated."
            />
          )}

          {!appState.loading && initialData && (
            <Panel heading={initialData.name}>
              {ntu ? (
                <>
                  <ul className="nav nav-pills mb-3" role="tablist">
                    {[
                      "Configuration",
                      "NTU Field",
                      "Location Fields",
                      "Subnet Fields",
                      "Conditional Fields",
                      "Result Configuration",
                    ].map((item, i) => (
                      <li
                        key={i}
                        className="nav-item cursor-pointer"
                        onClick={() => setActiveTab(item)}
                      >
                        <a
                          className={`nav-link ${
                            activeTab === item && "active"
                          }`}
                          dataToggle="pill"
                        >
                          {item}
                        </a>
                      </li>
                    ))}
                  </ul>
                  {ActiveForm}
                </>
              ) : (
                <>{InitialForm}</>
              )}
            </Panel>
          )}
        </div>
      </div>
    </div>
  );
};

export default TemplateConfigurationUpdate;
