export const getEntityAudits = (entityType, limit) => {
  const token = localStorage.getItem("token");
  const baseUrl = `https://test.activeport.com.au/api/audits/entity/changes?cacheBuster=1650477867643&entityType=${entityType}&limit=${limit}`;
  return fetch(baseUrl, {
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getEntities = () => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/api/audits/entity/all";
  return fetch(baseUrl, {
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getEntityAudit = (id) => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/api/help-section";
  const requestUrl = `${baseUrl}/${id}`;
  return fetch(requestUrl, {
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};
