import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  useContext,
} from 'react';

import { Link } from 'react-router-dom';
import moment from 'moment';
import tz from 'moment-timezone';
import Uptime from './Uptime';
import './uptime.scss';
import { Vega } from 'react-vega';
import Panel from '../../../common/Panel/Panel';

import DateRangePicker from '../../../common/DateRangerPicker/DateRangePicker';
import UptimePingSpec from './graphs/uptime_pings_over_time_bar.json';
import UptimePingQuery from './graphs/uptime_pings_over_time_request.json';
import UptimeDeviceRequest from './graphs/uptime_devices_request.json';
import UptimeDeviceSpec from './graphs/uptime_device_pings_over_time_bar.json';
import SnmpLatestRequest from './graphs/snmp_latest_request.json';
import UptimeDevicePingRequest from './graphs/uptime_device_pings_request.json';

import AuthContext from '../../../store/auth-context';

import { getElasticSearchData } from './api/elasticsearch';
import { toNumber } from 'vega';
import { getNTUs } from './api/ntu';
import { MegaportUserService } from './api/megaporst-user-service';
import { NtuPortService } from './api/ntu-port-service';
import MonitorStatusDevices from './MonitorStatusDevices';
import MonitorStatusVntus from './MonitorStatusVntus';
import MonitorRTTS from './MonitorRTTS';

const UptimeContainer = () => {
  let now = new Date();
  const tz = moment.tz.guess();
  const account = JSON.parse(localStorage.getItem('accountInfo'));
  const [vegaSpec, setVegaSpec] = useState(UptimePingSpec);
  const [vegaData, setVegaData] = useState({});
  const [vegaData2, setVegaData2] = useState({});

  const vegaContainerRef = useRef(null);
  const vegaContainerRef2 = useRef(null);

  const [showGraph, setShowGraph] = useState(false);
  const [showGraph2, setShowGraph2] = useState(false);
  const [graphWidth, setGraphWidth] = useState(0);
  const [graphWidth2, setGraphWidth2] = useState(0);
  const authCtx = useContext(AuthContext);

  const [devicesState, setDevicesState] = useState({
    upDevices: 0,
    downDevices: 0,
    totalDevices: 0,
    allDevices: [],
    hostnames: [],
  });

  let totalUpDevices = 0;
  let totalDownDevices = 0;
  let allDevices = [];
  let totalAllDevices = [];

  const [allHostnames, setAllHostnames] = useState();
  let ntus = [];
  const [vntus, setVntus] = useState([]);
  let lte = '';
  let gte = '';
  const getLTE = () => {
    if (lte == '') {
      lte = new Date();
      lte = moment();
    }
    return lte;
  };
  const getGTE = () => {
    if (gte == '') {
      gte = new Date();
      gte = moment().subtract(15, 'minutes');
    }
    return gte;
  };

  useLayoutEffect(() => {
    // I don't think it can be null at this point, but better safe than sorry
    if (vegaContainerRef.current) {
      //  console.log(vegaContainerRef);
      setGraphWidth(vegaContainerRef.current.clientWidth);
    }

    if (vegaContainerRef2.current) {
      //  console.log(vegaContainerRef);
      setGraphWidth2(vegaContainerRef2.current.clientWidth);
    }
    // console.log(vegaContainerRef.current.clientWidth);
    UptimePingSpec.width = toNumber(graphWidth);
    if (window.innerHeight < 440) {
      UptimePingSpec.height = 200;
      UptimePingSpec.padding = 0;
    }

    UptimeDeviceSpec.width = toNumber(graphWidth2);
    if (window.innerHeight < 440) {
      UptimeDeviceSpec.height = 200;
      UptimeDeviceSpec.padding = 0;
    }
    //  console.log(UptimePingSpec);
  }, [graphWidth, graphWidth2]);

  const startDate = moment(
    new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      now.getHours(),
      now.getMinutes(),
      now.getSeconds()
    )
  ).subtract(15, 'minutes');

  const endDate = moment(new Date());

  const drawDeviceGraph = async (device) => {
    console.log('Draw Device Graph: ', device);
    var selectedDevice = [];
    for (var idx = 0; idx < devicesState.allDevices.length; idx++) {
      var updown =
        devicesState.allDevices[idx].latest.hits.hits[0]._source.monitor.status;
      //var updown = vm.AllDevices[idx]["updown"]["buckets"][0]["key"];

      if (updown == 'up') {
        selectedDevice.push(devicesState.allDevices[idx]);
      }
    }
    var deviceGraph = selectedDevice[device];
    var gte_n = getGTE()._d.getTime();
    var lte_n = getLTE()._d.getTime();

    UptimeDevicePingRequest.query.bool.must[1].range['@timestamp'].gte = gte_n;
    UptimeDevicePingRequest.query.bool.must[1].range['@timestamp'].lte = lte_n;

    UptimeDevicePingRequest.query.bool.must[0].query_string['query'] =
      'monitor.ip:' + deviceGraph.key;

    if (authCtx.hasAuth(['ROLE_ADMIN'])) {
      try {
        let elasticData = await getElasticSearchData('searchNoTenant', {
          index: 'heartbeat-*',
          size: 0,
          body: JSON.stringify(UptimeDevicePingRequest),
        }).then((res) => res.json());
        console.log(elasticData);
        const dataToObjects = elasticData.hits.hits;
        const newData = { table: dataToObjects };
        setVegaData2(newData);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        let elasticData = await getElasticSearchData('searchNoTenant', {
          index: 'heartbeat-*',
          size: 0,
          body: JSON.stringify(UptimeDevicePingRequest),
        }).then((res) => res.json());
        console.log(elasticData);
        const dataToObjects = elasticData.hits.hits;
        const newData = { table: dataToObjects };
        setVegaData2(newData);
      } catch (error) {
        console.log(error);
      }
    }
    setShowGraph2(true);
  };
  const drawGraph = async (request, gte, lte) => {
    console.log('REQUEST : ', request);
    if (gte == null || gte == '' || gte == 'undefined') {
      var gte_n = getGTE()._d.getTime();
      var lte_n = getLTE()._d.getTime();
    } else {
      var gte_n = gte._d.getTime();
      var lte_n = lte._d.getTime();
    }

    request.query.bool.must[1].range['@timestamp'].gte = gte_n;
    request.query.bool.must[1].range['@timestamp'].lte = lte_n;

    console.log(request);
    if (authCtx.hasAuth(['ROLE_ADMIN'])) {
      try {
        let elasticData = await getElasticSearchData('searchNoTenant', {
          index: 'heartbeat-*',
          size: 0,
          body: JSON.stringify(request),
        }).then((res) => res.json());
        //  console.log(elasticData);
        const dataToObjects = elasticData.aggregations.results.buckets;
        const newData = { table: dataToObjects };
        setVegaData(newData);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        let elasticData = await getElasticSearchData('searchNoTenant', {
          index: 'heartbeat-*',
          size: 0,
          body: JSON.stringify(request),
        }).then((res) => res.json());
        //  console.log(elasticData);
        const dataToObjects = elasticData.aggregations.results.buckets;
        const newData = { table: dataToObjects };
        setVegaData(newData);
      } catch (error) {
        console.log(error);
      }
    }
    setShowGraph(true);
  };

  const processUptime = async (response) => {
    console.log('ProcessUptime', response);
    var SyslogData = [];

    if (response != null) {
      allDevices = response.aggregations.by_ip.buckets;

      console.log('vm.AllDevices: ', response.aggregations.by_ip.buckets);

      totalAllDevices = response.aggregations.by_ip.buckets.length;
    }
    var gte_n = getGTE()._d.getTime();
    var lte_n = getLTE()._d.getTime();

    var promise_array = [];
    if (!(authCtx.hasAuth(['ROLE_ADMIN']) && account.tenant.name == 'ADMIN')) {
      for (var idx = allDevices.length - 1; idx >= 0; --idx) {
        var host = allDevices[idx].key;
        var found = false;

        for (var i = 0; i < ntus.length; i++) {
          if (ntus[i].ipAddress == host) {
            found = true;
            break;
          }
        }
        if (!found) {
          console.log('need to delete ntu from AllDevices!!: ', host);

          allDevices.splice(idx, 1);
        }
      }
    }
    console.log('alldevices', allDevices);
    var hostnames = [];
    for (var idx = 0; idx < allDevices.length; idx++) {
      var host = allDevices[idx].key;

      var updown = allDevices[idx].latest.hits.hits[0]._source.monitor.status;
      //var updown = vm.AllDevices[idx]["updown"]["buckets"][0]["key"];

      //console.log('updown: ', updown);
      if (updown == 'up') {
        totalUpDevices++;
      } else if (updown == 'down') {
        totalDownDevices++;
      }

      // get hostnames from syslog

      if (allDevices[idx].key !== '127.0.0.1') {
        for (var x = 0; x < ntus.length; x++) {
          if (ntus[x].ipAddress == host) {
            allDevices[idx].hostname = ntus[x].name;
            break;
          }
        }
      }
    }
    // setUpDevices(totalUpDevices);
    //setDownDevices(totalDownDevices);
    //setTotalDevices(allDevices.length);
    // setDevices(allDevices);
    // setAllHostnames(hostnames);

    setDevicesState({
      upDevices: totalUpDevices,
      downDevices: totalDownDevices,
      totalDevices: allDevices.length,
      allDevices: allDevices,
      hostnames: hostnames,
    });
  };

  const getUptime = async () => {
    var gte_n = getGTE()._d.getTime();
    var lte_n = getLTE()._d.getTime();
    UptimeDeviceRequest.query.bool.must[0].query_string['query'] = '*';

    UptimeDeviceRequest.query.bool.must[1].range['@timestamp'].gte = gte_n;
    UptimeDeviceRequest.query.bool.must[1].range['@timestamp'].lte = lte_n;

    console.log('UptimeDeviceRequest', UptimeDeviceRequest);

    if (authCtx.hasAuth(['ROLE_ADMIN'])) {
      try {
        let elasticData = await getElasticSearchData('searchNoTenant', {
          index: 'heartbeat-*',
          size: 1,
          body: JSON.stringify(UptimeDeviceRequest),
        }).then((res) => res.json());
        if (elasticData.aggregations.by_ip.buckets.length > 0) {
          //  processUptime(response);
          processUptime(elasticData);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        let elasticData = await getElasticSearchData('searchNoTenant', {
          index: 'heartbeat-*',
          size: 1,
          body: JSON.stringify(UptimeDeviceRequest),
        }).then((res) => res.json());
        if (elasticData.aggregations.by_ip.buckets.length > 0) {
          //  processUptime(response);
          processUptime(elasticData);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const onSuccess = async (ntuList) => {
    var promise2_array = [];
    var promise1_array = [];
    var vntus_array = [];
    console.log('vm.ntus ', ntuList);

    for (var j = 0; j < ntuList.length; j++) {
      console.log('ARRAY NTU');
      if (
        ntuList[j].mode === 'VIRTUAL' &&
        ntuList[j].peSerialNumber !== undefined
      ) {
        console.log('found vNTU: ', ntuList[j].peSerialNumber);
        console.log('found vNTU id: ', ntuList[j].id);
        var found = 0;

        for (var jj = 0; jj < ntuList.length; jj++) {
          if (vntus[jj.serialNumber]) {
            if (vntus[jj].serialNumber == ntuList[j].serialNumber) {
              console.log('FOUND DUP!');
              found = true;
              break;
            }
          }
        }
        if (!found) {
          vntus_array.push(ntuList[j]);
        }
      }
    }
    console.log('vm.vmntus: ', vntus_array);

    if (vntus_array.length > 0) {
      for (var j = 0; j < vntus_array.length; j++) {
        try {
          var response = await MegaportUserService(
            'queryAdmin',
            '?filter=' + vntus_array[j].id + '&unpaged=true'
          ).then((res) => res.json());
          console.log('MegaportUserService response', response);
          if (response.length > 0) {
            for (var idx = 0; idx < vntus_array.length; idx++) {
              if (vntus_array[idx].id == response[0].ntuId) {
                console.log('found ntu: ', response[0].ntuId);
                var vlan = response[0].vlanIdA;
                console.log('found vlanIdA: ', vlan);
                vntus_array[idx].vlanIdA = vlan;
                break;
              }
            }
          }

          promise1_array.push(response);
        } catch (error) {
          console.log(error);
        }
      }

      console.log('promise1_array completed...');

      for (var j = 0; j < vntus_array.length; j++) {
        try {
          var response = await NtuPortService(
            'get',
            '?ntuId=' + vntus_array[j].id + '&isValid=false'
          ).then((res) => res.json());
          if (response.length > 0) {
            for (var idx = 0; idx < vntus_array.length; idx++) {
              if (vntus_array[idx].id == response[0].ntuId) {
                console.log('found ntu: ', response[0].ntuId);
                var port = response[0].name;
                console.log('found port: ', response[0].name);
                port = port + '.' + vntus_array[idx].vlanIdA;
                vntus_array[idx].port = port;
                break;
              }
            }
          }
        } catch (error) {
          console.log(error);
        }

        promise2_array.push(response);
      }
      console.log('promise2_array completed...');

      SnmpLatestRequest.query.bool.must[1].range['@timestamp'].gte = 'now-5m';
      SnmpLatestRequest.query.bool.must[1].range['@timestamp'].lte = 'now';

      var promise_array = [];
      var found_count = 0;
      var SnmpData = [];
      for (var idx = 0; idx < vntus_array.length; idx++) {
        found_count++;
        SnmpLatestRequest.query.bool.must[0].query_string['query'] =
          "serial-number: '" + vntus_array[idx].peSerialNumber + "'";

        console.log(JSON.stringify(SnmpLatestRequest));
        let response = {};
        try {
          let elasticData = await getElasticSearchData('searchNoTenant', {
            index: 'snmp-interface-non-hc-*',
            size: 1,
            body: JSON.stringify(SnmpLatestRequest),
          }).then((res) => {
            return res.json();
          });
          console.log('Elastic Data:', elasticData);
          promise_array.push(elasticData);
        } catch (error) {
          console.log(error);
        }
      }
      console.log('promise_array completed: SnmpData: ', promise_array);
      for (var idx = 0; idx < vntus_array.length; idx++) {
        var peSerial = vntus_array[idx].peSerialNumber;

        console.log('SnmpData: found vNTU: ', vntus_array[idx].peSerialNumber);

        for (var j = 0; j < promise_array.length; j++) {
          if (promise_array[j].hits.hits !== undefined) {
            if (promise_array[j].hits.hits.length > 0) {
              if (
                promise_array[j].hits.hits[0]._source['serial-number'] ===
                peSerial
              ) {
                console.log('found matching snmpdata data');
                var sData = promise_array[j];
                // find port data in SnmpData
                if (sData.aggregations.ports.buckets !== undefined) {
                  for (
                    var k = 0;
                    k < sData.aggregations.ports.buckets.length;
                    k++
                  ) {
                    if (
                      sData.aggregations.ports.buckets[k].key ===
                      vntus_array[idx].port
                    ) {
                      console.log(
                        'found snmp data for port: ',
                        vntus_array[idx].port
                      );
                      var portData =
                        sData.aggregations.ports.buckets[k].results.hits.hits[0]
                          ._source.interfaces;
                      console.log('portData = ', portData);
                      vntus_array[idx].portData = portData;
                      vntus_array[idx].portStatus = portData.ifOperStatus;
                    }
                  }
                }
              }
            }
          }
        }
      }

      console.log('vm.vntus last: ', vntus_array);
      setVntus(vntus_array);
      getUptime();

      drawGraph(UptimePingQuery);
    }
  };
  useEffect(async () => {
    //vNTUsx`
    let ntuList;

    if (authCtx.hasAuth(['ROLE_ADMIN']) && account.tenant.name == 'ADMIN') {
      try {
        ntuList = await getNTUs('queryAdmin').then((res) => res.json());
        console.log('Query Admin');
        ntus = ntuList;
        await onSuccess(ntuList);
      } catch (error) {
        console.log(error);
      }
    } else if (
      authCtx.hasAuth(['ROLE_ORG_ADMIN']) &
      (account.tenant.name == 'ALL')
    ) {
      try {
        console.log('query system filter');

        ntuList = await getNTUs('querySystem').then((res) => res.json());
        ntus = ntuList;
        console.log('query system filter');
        await onSuccess(ntuList);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        console.log('query tenant filter');

        ntuList = await getNTUs('query', '?filter=' + account.tenant.id).then(
          (res) => res.json()
        );
        ntus = ntuList;
        console.log('query tenant filter');
        await onSuccess(ntuList);
      } catch (error) {
        console.log(error);
      }
    }
  }, []);
  return (
    <Fragment>
      <ol className="breadcrumb page-breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">ActivePort</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to="/dashboard">Dashboard</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to="/dashboard/provisioning">Uptime Dashboard</Link>
        </li>
        <li className="position-absolute pos-top pos-right d-none d-sm-block">
          <span className="js-get-date">
            {moment().format('dddd, D MMMM, YYYY')}
          </span>
        </li>
      </ol>
      <div className="subheader">
        <h1 className="subheader-title">
          <i className="subheader-icon fal fa-chart-area" />
          &nbsp;Uptime Dashboard
          <small>The Uptime Dashboard shows uptime data for your NTUs.</small>
        </h1>
      </div>
      <div className="form-group row">
        <div className="col-12 col-lg-6">
          <label className="col-form-label col-12  form-label ">
            Pick Date and Time Range
          </label>
          <div className="input-group date-time">
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              createGraph={drawGraph}
              request={UptimePingQuery}
            />
            <div className="input-group-append">
              <span className="input-group-text fs-xl">
                <i className="fal fa-calendar" />
              </span>
            </div>
          </div>
        </div>
        <div className="col-lg-2">
          <div className="p-3 bg-success-700 rounded overflow-hidden position-relative text-white mb-1">
            <div className>
              <h3 className="display-4 d-block l-h-n m-0 fw-500">
                {devicesState.upDevices}
                <small className="m-0 l-h-n">Up</small>
              </h3>
            </div>
            <i
              className="fal fa-arrow-up position-absolute pos-right pos-bottom opacity-15 mb-n1 mr-n1"
              style={{ fontSize: '6rem' }}
            />
          </div>
        </div>
        <div class="col-lg-2">
          {' '}
          <div className="p-3 bg-danger-900 rounded overflow-hidden position-relative text-white mb-1">
            <div className>
              <h3 className="display-4 d-block l-h-n m-0 fw-500">
                {devicesState.downDevices}
                <small className="m-0 l-h-n">Down</small>
              </h3>
            </div>
            <i
              className="fal fa-arrow-down position-absolute pos-right pos-bottom opacity-15  mb-n1 mr-n4"
              style={{ fontSize: '6rem' }}
            />
          </div>
        </div>
        <div class="col-lg-2">
          {' '}
          <div className="p-3 bg-info-700 rounded overflow-hidden position-relative text-white mb-g">
            <div className>
              <h3 className="display-4 d-block l-h-n m-0 fw-500">
                {devicesState.totalDevices}
                <small className="m-0 l-h-n">Total</small>
              </h3>
            </div>
            <i
              className="fal fa-server position-absolute pos-right pos-bottom opacity-15 mb-n5 mr-n6"
              style={{ fontSize: '8rem' }}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div id="panel-2" className="panel" data-panel-fullscreen="false">
            <div className="panel-hdr">
              <h2>Pings Over Time</h2>
            </div>
            <div className="panel-container show">
              <div
                className="panel-content vegaContainer"
                ref={vegaContainerRef}
              >
                <div>
                  {showGraph && <Vega spec={UptimePingSpec} data={vegaData} />}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          {devicesState.hostnames != [] && (
            <MonitorStatusDevices
              allDevices={devicesState.allDevices}
              hostnames={devicesState.hostnames}
              heading="Monitor Status Devices"
            />
          )}
        </div>
        <div className="col-lg-6">
          {vntus != [] && (
            <MonitorStatusVntus vntus={vntus} heading="Monitor Status vNTUs" />
          )}
        </div>
        <div className="col-lg-12">
          {devicesState.allDevices != [] && (
            <>
              <Panel heading="Monitor RTT(ms)">
                <MonitorRTTS
                  devices={devicesState}
                  drawDeviceGraph={drawDeviceGraph}
                />
                <div
                  className="panel-content vegaContainer"
                  ref={vegaContainerRef2}
                >
                  <div>
                    {showGraph2 && (
                      <Vega spec={UptimeDeviceSpec} data={vegaData2} />
                    )}
                  </div>
                </div>
              </Panel>
            </>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default UptimeContainer;
