import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Panel from '../../../common/Panel/Panel';
import { getBillingSystem } from './api/billingSystemApi';
import moment from 'moment';

const BillingSystemDetail = () => {
  const match = useParams();
  const [billingSystemEntity, setBillingSystemEntity] = useState(null);
  const [appState, setAppState] = useState({
    loading: false,
  });

  useEffect(() => {
    setAppState({ loading: true });
    const fetchData = async () => {
      const result = await getBillingSystem(match.id).then((res) => res.json());

      setBillingSystemEntity(result);
    };
    fetchData();
    setAppState({ loading: false });
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-xl-10">
          {appState.loading && <p>Loading...</p>}
          {billingSystemEntity !== null && !appState.loading && (
            <Panel heading={billingSystemEntity.name}>
              <dl className="row">
                <dt class="text-inverse  col-4 text-truncate">
                  <span id="description">
                    <span>Description</span>
                  </span>
                </dt>
                <dd class="col-8 text-truncate">
                  {billingSystemEntity.description}
                </dd>
                <dt class="text-inverse  col-4 text-truncate">
                  <span id="api">
                    <span>Api</span>
                  </span>
                </dt>
                <dd class="col-8 text-truncate">{billingSystemEntity.api}</dd>
                <dt class="text-inverse  col-4 text-truncate">
                  <span id="stage">
                    <span>Stage</span>
                  </span>
                </dt>
                <dd class="col-8 text-truncate">{billingSystemEntity.stage}</dd>
                <dt class="text-inverse  col-4 text-truncate">
                  <span id="username">
                    <span>Username</span>
                  </span>
                </dt>
                <dd class="col-8 text-truncate">
                  {billingSystemEntity.username}
                </dd>

                <dt class="text-inverse  col-4 text-truncate">
                  <span id="startBilling">
                    <span>Billing Day</span>
                  </span>
                </dt>
                <dd class="col-8 text-truncate">
                  {moment(billingSystemEntity.startBilling).format(
                    'MMM Do YYYY hh:mm:ss A'
                  )}
                </dd>
              </dl>

              <div>
                <Link
                  className="btn btn-sm btn-default mr-3"
                  to="/administration/billing-system"
                >
                  <i className="fal fa-arrow-left fa-sm  mr-2"></i>
                  Back
                </Link>
                <Link
                  className="btn btn-sm btn-primary m-r-5"
                  to={`/administration/billing-system/${billingSystemEntity.id}/edit`}
                >
                  <i className="fal fa-pencil-alt fa-sm  mr-2"></i>
                  Edit
                </Link>
              </div>
            </Panel>
          )}
        </div>
      </div>
    </div>
  );
};

export default BillingSystemDetail;
