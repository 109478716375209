import React, { useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Panel from '../../../common/Panel/Panel';
import Table from '../../../common/Table';

import { handleDeleteAlert } from '../../../common/Alerts/DeleteAlert';
const UserProfiles = ({ entities }) => {
  const data = entities;

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        sortable: true,
        Cell: ({ row }) => {
          return (
            <div>
              <Link to={`${row.original.id}`}>{row.original.id}</Link>
            </div>
          );
        },
      },
      {
        Header: 'Name',
        accessor: 'name',
        sortable: true,
      },
      {
        Header: 'Description',
        accessor: 'description',
        sortable: true,
      },
      {
        Header: 'Authority',
        accessor: 'roleName',
        sortable: false,
      },
      {
        Header: 'Roles',
        accessor: (d) =>
          d.authorities?.map((authority) => (
            <button className="btn btn-primary mr-1 mb-1" key={authority}>
              {authority}
            </button>
          )),
        sortable: false,
      },
      {
        Header: '',
        accessor: 'actionColumns',
        className: 'align-middle',
        Cell: ({ row }) => {
          return (
            <div class="text-center d-flex">
              <Link
                className="btn btn-icon btn-circle btn-default mr-2"
                to={`${row.original.id}`}
              >
                <i className="fal fa-eye"></i>
              </Link>
              <Link
                className="btn btn-icon btn-circle btn-success mr-2"
                to={`${row.original.id}/edit`}
              >
                <i className="fal fa-pencil-alt"></i>
              </Link>
              <button
                className="btn btn-icon btn-circle btn-danger"
                onClick={handleDeleteAlert}
              >
                <i className="fal fa-trash"></i>
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <Panel>
      <Table data={data} columns={columns} />
    </Panel>
  );
};

export default UserProfiles;
