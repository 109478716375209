import React, { useState } from 'react';
import moment from 'moment';
import tz from 'moment-timezone';
import DateRangePicker from '../../../common/DateRangerPicker/DateRangePicker';

const DateTimePicker = ({ getDateTime, severityChange, facilityChange }) => {
  let now = new Date();
  const tz = moment.tz.guess();
  let FacilityOptions = [
    'all',
    'kern',
    'user',
    'mail',
    'daemon',
    'syslog',
    'cron',
    'lpr',
    'uucp',
    'clock',
    'auth',
    'ftp',
    'ntp',
    'audit',
    'alert',
    'local0',
    'local1',
    'local2',
    'local3',
    'local4',
    'local5',
    'local6',
    'local7',
  ];
  let SeverityOptions = [
    'all',
    'EMERGENCY',
    'ALERT',
    'CRITICAL',
    'ERROR',
    'WARN',
    'NOTICE',
    'INFO',
    'DEBUG',
  ];
  const [FacilitySelected, setFacilitySelected] = useState('');
  const [SeveritySelected, setSeveritySelected] = useState('');

  const startDate = moment(
    new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      now.getHours(),
      now.getMinutes(),
      now.getSeconds()
    )
  ).subtract(1, 'hours');

  const endDate = moment(new Date());

  return (
    <div>
      <div className="row">
        <div className="col-6">
          <label class="form-label" for="example-select">
            Select Date & Time
          </label>
          <div className="input-group date-time">
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              getDateTime={getDateTime}
            />
            <div className="input-group-append">
              <span className="input-group-text fs-xl">
                <i className="fal fa-calendar" />
              </span>
            </div>
          </div>
        </div>
        <div className="col-3">
          {' '}
          <div class="form-group">
            <label class="form-label" for="example-select">
              Select Facility
            </label>

            <select
              class="form-control"
              id="example-select"
              value={FacilitySelected}
              onChange={(e) => {
                setFacilitySelected(e.target.value);
                facilityChange(e.target.value);
              }}
            >
              {FacilityOptions.map((item, key) => {
                return (
                  <option
                    selected={`${
                      FacilitySelected == item ? FacilitySelected : ''
                    }`}
                    value={item}
                  >
                    {item}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="col-3">
          <div class="form-group">
            <label class="form-label" for="example-select">
              Select Severity
            </label>

            <select
              class="form-control"
              id="example-select"
              value={SeveritySelected}
              onChange={(e) => {
                setSeveritySelected(e.target.value);
                severityChange(e.target.value);
              }}
            >
              {SeverityOptions.map((item, key) => {
                return (
                  <option
                    selected={`${
                      SeveritySelected == item ? SeveritySelected : ''
                    }`}
                    value={item}
                  >
                    {item}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateTimePicker;
