import React, { useEffect, useState, Fragment } from "react";
import { Routes, Route, useLocation, Link } from "react-router-dom";

import GpuServers from "./GpuServers";
import GpuServerDetail from "./GpuServerDetail";
import GpuServerUpdate from "./GpuServerUpdate";
// import GpuServerDelete from "./GpuServerDeleteDialog";
import { getGpuServers } from "./api/gpuServersApi";

const GpuServersContainer = () => {
  const location = useLocation();

  const [entities, setEntities] = useState([
    {
      id: 1,
      name: "exotic-camel",
      displayName: "PERTH GPU 1",
      ipAddress: "103.228.188.245",
      uid: "a8806a89-dd22-45d0-b9f8-03860b749b91",
      serverState: "ENABLED",
      type: "GPU_SERVER",
      publicIp: "103.228.188.246",
      regionId: 1,
      regionName: "Perth",
      availabilityZone: "ap-southeast-2",
      networkName: null,
      networkId: null,
      ifaceOut: "eno2",
      maxCpus: 32,
      maxMemory: 128000,
      request: null,
      reponse: null,
    },
  ]);
  const [appState, setAppState] = useState({
    loading: false,
    err: false,
  });

  useEffect(() => {
    (async () => {
      try {
        setAppState({ loading: true, err: false });
        const res = await getGpuServers().then((res) => res.json());
        setAppState({ loading: false });
        setEntities(res);
      } catch (err) {
        setAppState({ loading: false, err: true });
      }
    })();
  }, [location.key]);

  return (
    <Fragment>
      <ol className="breadcrumb page-breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">ActivePort</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to="/administration">Administration</Link>
        </li>
        <li className="breadcrumb-item active">GPU Servers</li>
        <li className="position-absolute pos-top pos-right d-none d-sm-block">
          <span className="js-get-date"></span>
        </li>
      </ol>
      <div className="subheader">
        <h1 className="subheader-title">
          <i className="subheader-icon fal fa-table"></i>GPU Servers
        </h1>

        {!location.pathname.includes("/new") &&
          !location.pathname.includes("/edit") && (
            <Link to={`new`}>
              <button
                type="button"
                className="btn btn-success waves-effect waves-themed"
              >
                <span className="fal fa-plus mr-1"></span>
                Create
              </button>
            </Link>
          )}
      </div>
      {!appState.loading && (
        // && !appState.err
        <Routes>
          <Route exact path={`/new`} element={<GpuServerUpdate />} />
          <Route exact path={`/edit/:id`} element={<GpuServerUpdate />} />
          <Route exact path={`:id`} element={<GpuServerDetail />} />

          <Route path={`/`} element={<GpuServers entities={entities} />} />
        </Routes>
      )}
      {appState.loading && !appState.error && (
        <div className="border p-3">
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      )}
      {!appState.loading && appState.err && (
        <div className="alert alert-danger" role="alert">
          <strong>Oh snap!</strong> We are not able to connect to the database.
          Try again in few minutes. again.
        </div>
      )}
      {/* <Route
          exact
          path={`/:id/delete`}
          element={GpuServerDelete}
        /> */}
    </Fragment>
  );
};

export default GpuServersContainer;
