export const getSubnetConfigurations = () => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/api/realm-ips?size=99999999";

  return fetch(baseUrl, {
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getSubnetConfiguration = (id) => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/api/realm-ips/";
  const requestUrl = `${baseUrl}${id}`;
  return fetch(requestUrl, {
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const createSubnetConfiguration = (subnetConfiguration) => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/api/realm-ips";

  return fetch(baseUrl, {
    method: "POST",
    body: JSON.stringify(subnetConfiguration),
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const updateSubnetConfiguration = (subnetConfiguration) => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/api/realm-ips/";

  return fetch(baseUrl, {
    method: "PUT",
    body: JSON.stringify(subnetConfiguration),
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const deleteSubnetConfiguration = (id) => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/api/realm-ips/";
  const requestUrl = `${baseUrl}${id}`;
  return fetch(requestUrl, {
    method: "DELETE",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};
