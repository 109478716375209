import React, { useState } from "react";
import { SubnetFormDialog } from "./SubnetFormDialog";

const selectedFields = [
  "network",
  "broadcast",
  "cidr",
  "lowerIPCidr",
  "lowerIP",
  "upperIPCidr",
  "upperIP",
  "subnetSize",
  "mask",
  "sharedKey",
];

export const SubnetsTable = ({ obj, th2 }) => {
  const [showForm, setShowForm] = useState(false);
  const [subnetCred, setSubnetCred] = useState({ id: "", key: "" });
  return (
    <>
      <button
        type="button"
        className="btn btn-success waves-effect waves-themed mb-1"
        onClick={() => {
          setShowForm(true);
        }}
      >
        <span className="fal fa-plus mr-1"></span>
        Add
      </button>
      <table className="table table-striped table-bordered mb-5">
        <thead>
          <tr>
            <th>Subnet Scope</th>
            <th>{th2}</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(obj)
            .filter((item) => selectedFields.includes(item))
            .map((key, i) => (
              <tr key={i}>
                <td width="20%">{key}</td>
                <td width="20%">{obj[key]}</td>
              </tr>
            ))}
          <tr>
            <td></td>
            <td className="d-flex justify-content-end">
              <button
                className="btn btn-icon btn-circle btn-success mr-2"
                onClick={() => {
                  setSubnetCred((prev) => ({ id: 18, key: th2 }));
                  setShowForm(true);
                }}
              >
                <i className="fal fa-pencil-alt"></i>
              </button>
              <button className="btn btn-icon btn-circle btn-danger">
                <i className="fal fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      {showForm && (
        <SubnetFormDialog
          show={showForm}
          setShow={setShowForm}
          id={subnetCred.id}
          subnetKey={subnetCred.key}
          setSubnetCred={setSubnetCred}
        />
      )}
    </>
  );
};
