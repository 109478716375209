import React, { useEffect, useState, useMemo } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import Table from '../../../common/Table';
import * as Yup from 'yup';
import Panel from '../../../common/Panel/Panel';
import Alert from '../../../common/AlertControls/Alert';

import { defaultValues } from './defaults';
import APModal from '../../../common/APModal/APModal';
import ImageSelector from '../../../common/ImageSelector';
import UploadField from '../../../common/UploadField/UploadField';
import { MyTextArea } from '../../../common/Form/FormFields';
import Button from '../../../common/Button';
import ProgressBar from '../../../common/ProgressBar';
const OrganisationCreate = ({ entities, sdWanServers, slaveOrgs }) => {
  const match = useParams();
  let t = [];
  const navigate = useNavigate();
  const accountInfo = JSON.parse(localStorage.getItem('accountInfo'));
  const [isActivated, setIsActivated] = useState(true);
  const [userError, setUserError] = useState(false);
  const [formSteps, setFormSteps] = useState(1);
  const [initialData, setInitialData] = useState(defaultValues);
  const [entityData, setEntityData] = useState(null);
  const [toggleModal, setToggleModal] = useState(false);
  const [toggleModal2, setToggleModal2] = useState(false);
  const [steps, setSteps] = useState([
    {
      id: 1,
      name: 'Info',
    },
    {
      id: 2,
      name: 'SD-WAN',
    },
    {
      id: 3,
      name: 'Slave Orgs',
    },
    {
      id: 4,
      name: 'Attributes',
    },
  ]);
  const [fileName, setFileName] = useState({});
  const [appState, setAppState] = useState({
    loading: true,
  });

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'row_id',
        sortable: true,
        Cell: ({ row }) => {
          return (
            <div>
              <Link to={`${row.original.id}`}>{row.original.id}</Link>
            </div>
          );
        },
      },
      {
        Header: 'Name',
        accessor: 'name',
        sortable: true,
      },
    ],
    []
  );

  const [alertShow, setAlertShow] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  // let alertShow = false;

  const schema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Too Short!')
      .max(250, 'Too Long!')
      .required('Required'),
    type: Yup.string()
      .min(2, 'Too Short!')
      .max(250, 'Too Long!')
      .required('Required'),
    masterOrgUid: Yup.string(),
    // tiles: Yup.array().required('Required'),
    // tenantId: Yup.string().required("Required"),
    parentUid: Yup.string(),
    // mapCenter: Yup.string().min(2, 'Too Short!').required('Required'),
    // mapZoom: Yup.number().required('Required'),
    favicon: Yup.string(),
    portal_tagline: Yup.string(),
    logo: Yup.string(),
    brand_json: Yup.string(),
    server_value: Yup.string(),
  });

  async function createNew(data) {
    console.log(data);
    /* try {
      const response = await createUser(data).then((res) => {
        res.json();
      });
      if (response.id) {
        handleCreateNewAlert(
          'New user profile for "' +
            data.firstName +
            ' ' +
            data.lastName +
            '" added successfully!'
        );
        navigate('../');
      }
    } catch (error) {
      //console.log(error);
      setUserError(true);
    } */
  }

  function submitForm(formValues) {
    // let tiles = tenantTilesData.filter((t) =>
    //   formValues.tiles.includes(t.tileId)
    // );
    // formValues.tiles = te;
    formValues.email = formValues.login;

    createNew(formValues);
  }

  // effect runs on component mount
  useEffect(() => {
    setAppState({ loading: false });
  }, []);

  const onSubmit = (data) => {
    console.log(data);
  };

  const closeModal = () => {
    document.body.classList.remove('modal-open');

    setToggleModal(false);
    setToggleModal2(false);
  };

  const getFileName = (field, filename, setFieldValue) => {
    console.log(field, filename);
    setFileName({ [field]: filename });
    setFieldValue(field, filename);
    closeModal();
  };
  return (
    <div>
      <div className="row">
        <div className="col-xl-9">
          {alertShow && (
            <Alert
              alertType="alert-success"
              msg="The tenant has been updated."
            />
          )}

          {userError && (
            <Alert
              alertType="alert-danger"
              msg="Sorry, this email is already in use."
            />
          )}

          {!appState.loading && initialData && (
            <Panel heading={initialData.name}>
              <Formik
                enableReinitialize
                initialValues={initialData}
                validationSchema={schema}
                validateOnMount
                onSubmit={onSubmit}
              >
                {({ errors, touched, isValid, setFieldValue, values }) => {
                  return (
                    <Form>
                      <div className="row">
                        <div className="col-md-12">
                          <ProgressBar steps={steps} activeStep={formSteps} />
                        </div>
                      </div>
                      {formSteps == 1 && (
                        <>
                          {' '}
                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Organization Name
                            </label>
                            <div className="col-md-9">
                              <Field
                                type="text"
                                name="name"
                                label="name"
                                placeholder="Foo Corp Ltd"
                                className={`form-control m-b-5  ${
                                  errors.name && touched.name
                                    ? 'is-invalid'
                                    : ''
                                }`}
                              />
                              {errors.name && touched.name ? (
                                <div className="invalid-feedback">
                                  {errors.name}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Type <span className="text-danger"></span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                as="select"
                                name="type"
                                className={`form-control m-b-5  ${
                                  errors.type && touched.type
                                    ? 'is-invalid'
                                    : ''
                                }`}
                              >
                                <option>Select a type</option>
                                <option key="0" value="MASTER">
                                  MASTER
                                </option>
                                <option key="1" value="SLAVE">
                                  SLAVE
                                </option>
                                <option key="2" value="COPY">
                                  COPY
                                </option>
                              </Field>
                              {errors.type && touched.type ? (
                                <div className="invalid-feedback">
                                  {errors.type}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Master Organisation{' '}
                              <span className="text-danger"></span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                as="select"
                                name="masterOrgUid"
                                className={`form-control m-b-5  ${
                                  errors.masterOrgUid && touched.masterOrgUid
                                    ? 'is-invalid'
                                    : ''
                                }`}
                              >
                                <option>Select a master org</option>
                                {entities?.map((org) => {
                                  if (org.type == 'MASTER') {
                                    return (
                                      <option key={org.id} value={org.id}>
                                        {org.name}
                                      </option>
                                    );
                                  }
                                })}
                              </Field>
                              {errors.masterOrgUid && touched.masterOrgUid ? (
                                <div className="invalid-feedback">
                                  {errors.masterOrgUid}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Parent Owner
                              <span className="text-danger"></span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                as="select"
                                name="parentUid"
                                className={`form-control m-b-5  ${
                                  errors.parentUid && touched.parentUid
                                    ? 'is-invalid'
                                    : ''
                                }`}
                              >
                                <option>Select a parent owner</option>
                                {entities?.map((org) => {
                                  if (org.parentUid == null) {
                                    return (
                                      <option key={org.id} value={org.id}>
                                        {org.name}
                                      </option>
                                    );
                                  }
                                })}
                              </Field>
                              {errors.parentUid && touched.parentUid ? (
                                <div className="invalid-feedback">
                                  {errors.parentUid}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </>
                      )}

                      {formSteps == 2 && (
                        <>
                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              SD-WAN Server
                              <span className="text-danger"></span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                as="select"
                                name="sdWanServerId"
                                className={`form-control m-b-5  ${
                                  errors.sdWanServerId && touched.sdWanServerId
                                    ? 'is-invalid'
                                    : ''
                                }`}
                              >
                                <option value="">Select SD-WAN server</option>
                                {sdWanServers != null &&
                                  sdWanServers.map((sdwan) => (
                                    <option key={sdwan.id} value={sdwan.id}>
                                      {sdwan.name}
                                    </option>
                                  ))}
                              </Field>
                              {errors.sdWanServerId && touched.sdWanServerId ? (
                                <div className="invalid-feedback">
                                  {errors.sdWanServerId}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </>
                      )}
                      {formSteps == 3 && (
                        <>
                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Slaves Organizations
                              <span className="text-danger"></span>
                            </label>
                            <div className="col-md-9">
                              <Table data={slaveOrgs} columns={columns} />
                            </div>
                          </div>
                        </>
                      )}

                      {formSteps == 4 && (
                        <>
                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              CSS Override file
                              <span className="text-danger"></span>
                            </label>

                            <div className="col-md-9">
                              <UploadField />

                              <Field
                                type="hidden"
                                name="attributes.css_file.value"
                                label="css_file"
                                className={`form-control-file m-b-5  ${
                                  errors.css_file && touched.css_file
                                    ? 'is-invalid'
                                    : ''
                                }`}
                              ></Field>
                            </div>
                          </div>
                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Server Name
                            </label>
                            <div className="col-md-9">
                              <Field
                                type="text"
                                name="attributes.server_name.value"
                                label="brand"
                                placeholder="Enter server name"
                                className={`form-control m-b-5  ${
                                  errors.server_name && touched.server_name
                                    ? 'is-invalid'
                                    : ''
                                }`}
                              />
                              {errors.server_name && touched.server_name ? (
                                <div className="invalid-feedback">
                                  {errors.server_name}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Favicon filename
                              <span className="text-danger"></span>
                            </label>
                            <div className="col-md-6">
                              <Field
                                type="text"
                                name="attributes.favicon.value"
                                label="favicon"
                                disabled
                                placeholder="Favicon"
                                className={`form-control m-b-5  ${
                                  errors.favicon && touched.favicon
                                    ? 'is-invalid'
                                    : ''
                                }`}
                              ></Field>
                            </div>
                            <div className="col-md-3">
                              <Button
                                onClick={() => setToggleModal(true)}
                                text="Select favicon"
                                type="button"
                                icon="file"
                              >
                                Media Files
                              </Button>
                              {toggleModal && (
                                <APModal
                                  title="Select favicon image"
                                  ismodalopen={toggleModal}
                                  onModalClose={closeModal}
                                  cancelBtn
                                >
                                  <ImageSelector
                                    getFileName={getFileName}
                                    field="attributes.favicon.value"
                                    setFieldValue={setFieldValue}
                                  />
                                </APModal>
                              )}
                            </div>
                          </div>
                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Portal Tagline
                            </label>
                            <div className="col-md-9">
                              <Field
                                type="text"
                                name="attributes.portal_tagline.value"
                                label="portal_tagline"
                                placeholder="Enter portal tagline"
                                className={`form-control m-b-5  ${
                                  errors.portal_tagline &&
                                  touched.portal_tagline
                                    ? 'is-invalid'
                                    : ''
                                }`}
                              />
                              {errors.portal_tagline &&
                              touched.portal_tagline ? (
                                <div className="invalid-feedback">
                                  {errors.portal_tagline}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Chat HTML
                            </label>
                            <div className="col-md-9">
                              <UploadField />

                              <Field
                                type="hidden"
                                name="attribute.chat_html.value"
                                label="chat_html"
                                className={`form-control-file m-b-5  ${
                                  errors.chat_html && touched.chat_html
                                    ? 'is-invalid'
                                    : ''
                                }`}
                              ></Field>
                            </div>
                            <div className="col-md-9">
                              {errors.chat_html && touched.chat_html ? (
                                <div className="invalid-feedback">
                                  {errors.chat_html}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Logo Override
                              <span className="text-danger"></span>
                            </label>
                            <div className="col-md-6">
                              <Field
                                type="text"
                                name="attributes.logo.value"
                                label="logo"
                                disabled
                                placeholder="Your organisation logo"
                                className={`form-control m-b-5  ${
                                  errors.logo && touched.logo
                                    ? 'is-invalid'
                                    : ''
                                }`}
                              ></Field>
                            </div>
                            <div className="col-md-3">
                              <Button
                                onClick={() => setToggleModal2(true)}
                                text="Select logo"
                                type="button"
                                icon="file"
                              >
                                Media Files
                              </Button>
                              {toggleModal2 && (
                                <APModal
                                  title="Select Logo Image"
                                  ismodalopen={toggleModal2}
                                  onModalClose={closeModal}
                                  cancelBtn
                                >
                                  <ImageSelector
                                    getFileName={getFileName}
                                    field="attributes.logo.value"
                                    setFieldValue={setFieldValue}
                                  />
                                </APModal>
                              )}
                            </div>
                          </div>
                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Portal title
                            </label>
                            <div className="col-md-9">
                              <Field
                                type="text"
                                name="attributes.portal_title.value"
                                label="portal_title"
                                placeholder="Enter portal title"
                                className={`form-control m-b-5  ${
                                  errors.portal_title && touched.portal_title
                                    ? 'is-invalid'
                                    : ''
                                }`}
                              />
                              {errors.portal_title && touched.portal_title ? (
                                <div className="invalid-feedback">
                                  {errors.portal_title}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <MyTextArea
                            label="Brand JSON"
                            name="attributes.brand.value"
                            rows="6"
                            placeholder="Enter your brand JSON"
                          />
                        </>
                      )}
                      <div className="d-flex justify-content-end">
                        {formSteps > 1 && (
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                              console.log(formSteps);
                              setFormSteps(formSteps - 1);
                            }}
                          >
                            Back
                          </button>
                        )}
                        {formSteps < 4 && (
                          <button
                            type="button"
                            className="btn btn-primary ml-2"
                            onClick={() => {
                              console.log(formSteps);
                              setFormSteps(formSteps + 1);
                            }}
                          >
                            Next
                          </button>
                        )}
                        {formSteps == 4 && (
                          <button
                            type="submit"
                            className="btn btn-primary ml-2 m-b-5"
                            disabled={!isValid || isSubmitted}
                          >
                            Submit
                          </button>
                        )}
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </Panel>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrganisationCreate;
