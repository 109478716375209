import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";

import { getConnection, getNtuPorts } from "../../api/peeringExchangeApi";
import FormSteps from "../../../../../common/FormSteps";
import ProgressBar from "../../../../../common/ProgressBar";

const initialValues = {
  id: "",
  partnerName: "",
  name: "",
  rateLimit: "",
  ntuPort: "",
  myrouting: "",
};

const steps = [
  {
    id: 1,
    name: "Service",
  },
  {
    id: 2,
    name: "NTU",
  },
  {
    id: 3,
    name: "FINAL",
  },
];

const serviceFields = ["id", "name", "createdBy", "createdDate"];

export const NtuSettingsDialog = ({ id, show, onHide }) => {
  const [connection, setConnection] = useState(null);
  const [formSteps, setFormSteps] = useState(1);
  const [ntuPorts, setNtuProts] = useState([]);

  useEffect(() => {
    const fetcher = async () => {
      try {
        const connRes = await getConnection(id).then((res) => res.json());
        const ntuPortsRes = await getNtuPorts(id).then((res) => res.json());
        setConnection(connRes);
        setNtuProts(ntuPortsRes);
      } catch (error) {}
    };
    if (!connection && id) {
      fetcher();
    }
  }, []);

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={connection ? connection : initialValues}
        onSubmit={(values) => {
          // saveAttribute(values);
          onHide();
        }}
      >
        {({ values, isValid }) => {
          return (
            <div
              className={`modal fade overflow-auto ${show && "show d-block"}`}
            >
              <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <div className="d-flex w-100 align-items-center justify-content-between">
                      <h1>Edit Service Peering Exchange Service</h1>
                      <button type="button" className="close" onClick={onHide}>
                        <span aria-hidden="true">
                          <i className="fal fa-times"></i>
                        </span>
                      </button>
                    </div>
                  </div>
                  <div className="modal-body">
                    <Form className="px-5">
                      {formSteps === 1 && (
                        <>
                          <h2>Provider Configuration</h2>
                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Provider
                            </label>
                            <div className="col-md-9">
                              <Field
                                readOnly
                                type="text"
                                name="partnerName"
                                className="form-control m-b-5"
                              />
                            </div>
                          </div>
                          <h2>Service Configuration</h2>
                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Provider
                            </label>
                            <div className="col-md-9">
                              <Field
                                type="text"
                                name="name"
                                className="form-control m-b-5"
                              />
                            </div>
                          </div>
                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Rate Limit
                            </label>
                            <div className="col-md-9">
                              <Field
                                readOnly
                                type="text"
                                name="rateLimit"
                                className="form-control m-b-5"
                              />
                            </div>
                          </div>
                        </>
                      )}

                      {formSteps === 2 && (
                        <>
                          <h2>Service</h2>
                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Id
                            </label>
                            <div className="col-md-9">
                              <Field
                                readOnly
                                type="text"
                                name="id"
                                className="form-control m-b-5"
                              />
                            </div>
                          </div>
                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Name
                            </label>
                            <div className="col-md-9">
                              <Field
                                readOnly
                                type="text"
                                name="name"
                                className="form-control m-b-5"
                              />
                            </div>
                          </div>
                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Service State
                            </label>
                            <div className="col-md-9">
                              <p className="p-2 badge badge-success">
                                {connection.provisioningStatus}
                              </p>
                            </div>
                          </div>
                          <h2>NTU</h2>
                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Ntu Port <span className="text-danger"></span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                as="select"
                                name="ntuPort"
                                className={`form-control m-b-5 `}
                              >
                                <option value=""></option>
                                {ntuPorts.map((port) => (
                                  <option key={port.id} value={port.id}>
                                    {port.name}
                                  </option>
                                ))}
                              </Field>
                            </div>
                          </div>

                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Routing Type <span className="text-danger"></span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                as="select"
                                name="myrouting"
                                className={`form-control m-b-5 `}
                              >
                                <option value="">None</option>
                              </Field>
                            </div>
                          </div>
                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              State
                            </label>
                            <div className="col-md-9">
                              <p className="p-2 badge badge-success">
                                {connection.state}
                              </p>
                            </div>
                          </div>
                        </>
                      )}

                      {formSteps === 3 && (
                        <>
                          <h2>Service</h2>
                          <div className="pl-5">
                            {serviceFields.map((field, i) => (
                              <div>
                                <span className="font-weight-bold">
                                  {field}
                                </span>
                                <span className="ml-2">
                                  {connection[field]}
                                </span>
                              </div>
                            ))}
                          </div>
                        </>
                      )}

                      <div className="row">
                        <div className="col-md-10 offset-2 mt-5 text-right">
                          <FormSteps
                            currentStep={formSteps}
                            totalSteps={steps.length}
                            setFormSteps={setFormSteps}
                            isValid={isValid}
                            isSubmitted={false}
                          />
                        </div>
                        <div className="col-md-12">
                          <ProgressBar steps={steps} activeStep={formSteps} />
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
    </>
  );
};
