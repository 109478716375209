import React, { Fragment } from 'react';
import { useContext, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

import Language from './Language';
import ProfilePanel from './ProfilePanel';
import NotificationsPanel from './NotificationsPanel';
import ShortcutsPanel from './ShortcutsPanel';
import AuthContext from '../../store/auth-context';
import './header.scss';
import HeaderAvatar from '../../assets/images/demo/avatars/user.png';
import { getNTUs } from './api/ntuapi';
import { usePopper } from 'react-popper';

const Header = (props) => {
  const theme = props.themeSettings;
  const navigate = useNavigate();
  const cookies = new Cookies();

  const authCtx = useContext(AuthContext);
  const [orgName, setOrgName] = useState('');
  const [tenantName, setTenantName] = useState('');
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const { toggleModal } = props;
  const {
    setTheme,
    removeFromTheme,
    setSkin,
    selectNTU,
    ntu,
    selectWorkingOrg,
  } = props;

  const { isLoading, setIsLoading } = useState(false);
  const [profilePanel, setProfilePanel] = useState(false);
  const [notificationsPanel, setNotificationsPanel] = useState(false);
  const [shortcutsPanel, setShortcutsPanel] = useState(false);
  const profileClose = useRef(null);
  const notificationsClose = useRef(null);
  const [ntuDropdown, setNtuDropdown] = useState(false);
  const ntuRef = useRef(null);
  const ntuRef2 = useRef(null);
  const [orgDropdown, setOrgDropdown] = useState(false);
  const [orgUpdate, setOrgUpdate] = useState(false);
  const orgRef = useRef(null);
  const orgRef2 = useRef(null);
  const [ntuList, setNtuList] = useState([]);
  const accountInfo = JSON.parse(localStorage.getItem('accountInfo'));
  const orgNames = [];
  const token = localStorage.getItem('token');

  //menu item
  // get menu item
  accountInfo.filter.filters.map((item, key) => {
    orgNames.push(item.orgName);
  });
  var occurrences = {};
  for (var i = 0, j = orgNames.length; i < j; i++) {
    occurrences[orgNames[i]] = (occurrences[orgNames[i]] || 0) + 1;
  }

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  var unique = orgNames.filter(onlyUnique);
  var newArray = {};
  var i = 1;
  var prevOrgName = '';
  var currOrgName = '';

  {
    accountInfo.filter.filters.map((item, key) => {
      if (item.isMasterOrg) return;
      currOrgName = item.orgName;
      if (currOrgName != prevOrgName) {
        newArray[currOrgName] = {};

        newArray[currOrgName][i++] = item;
      }
      if (currOrgName == prevOrgName) {
        newArray[currOrgName][i++] = item;
      }
      prevOrgName = currOrgName;
    });
  }

  var dropdownHide = {};
  var dropdownVisible = {
    position: 'absolute',
    top: '45px',
    left: '152px',
    willchange: 'top, left',
  };

  var orgDropdownVisible = {
    position: 'absolute',
    top: '45px',
    left: '0px',
    willchange: 'top, left',
  };

  const toggleDropdown = (dropdownName) => {
    if (dropdownName == 'profilePanel') {
      setNotificationsPanel(false);
      setShortcutsPanel(false);
      setProfilePanel(!profilePanel);
    }

    if (dropdownName == 'notificationsPanel') {
      setShortcutsPanel(false);
      setProfilePanel(false);
      setNotificationsPanel(!notificationsPanel);
    }
  };

  const handleClickOutside = (event) => {
    if (!profilePanel) {
      if (
        profileClose.current &&
        !profileClose.current.contains(event.target)
      ) {
        setProfilePanel(false);
      }
    }
    if (!notificationsPanel) {
      if (
        notificationsClose.current &&
        !notificationsClose.current.contains(event.target)
      ) {
        setNotificationsPanel(false);
      }
    }
  };

  function handleDocumentClick(event) {
    if (ntuRef.current.contains(event.target)) {
      return;
    }
    if (ntuRef2.current.contains(event.target)) {
      return;
    }
    if (orgRef.current.contains(event.target)) {
      return;
    }
    if (orgRef2.current.contains(event.target)) {
      return;
    }
    setNtuDropdown(false);
    setOrgDropdown(false);
  }

  useEffect(() => {
    console.log(ntu);
    document.addEventListener('click', handleClickOutside, true);
    //get working org name

    const userData = async () => {
      setOrgName(accountInfo.org.name);
      setTenantName(accountInfo.tenant.name);
      setUserName(accountInfo.firstName + ' ' + accountInfo.lastName);
      setUserEmail(accountInfo.email);
      localStorage.setItem('accountInfo', JSON.stringify(accountInfo));
    };
    userData();
  }, []);

  useEffect(() => {
    // listen for clicks and close dropdown on body

    const fetchData = async () => {
      const data = await getNTUs();
      setNtuList(data);
      // localStorage.setItem('ntu', '');
      // navigate('/dashboard/provisioning');
    };

    fetchData();
  }, [orgUpdate]);

  useEffect(() => {
    document.addEventListener('mousedown', handleDocumentClick);
    return () => {
      document.removeEventListener('mousedown', handleDocumentClick);
    };
  }, []);

  const tenantFilter = async (values) => {
    let url = 'https://test.activeport.com.au/api/authenticate/tenant-filter?';

    await fetch(url, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
      body: JSON.stringify({
        orgId: values.orgId,
        tenantId: values.tenantId,
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          return res.json().then((data) => {
            let errorMessage = 'Authentication failed!';
            // if (data && data.error && data.error.message) {
            //   errorMessage = data.error.message;
            // }

            throw new Error(errorMessage);
          });
        }
      })
      .then((data) => {
        authCtx.login(data.id_token, data.expire);
        localStorage.setItem('ntu', '');
        // navigate('/');
        setOrgUpdate(!orgUpdate);
      })
      .catch((err) => {
        console.log('auth error', err);
      });
  };

  const changeTenant = async (tenant) => {
    console.log('tenantID: ', tenant.tenantId);
    console.log('orgID: ', tenant.orgId);
    tenantFilter(tenant);
    selectWorkingOrg(tenant);
  };

  return (
    <Fragment>
      <header className="page-header" role="banner">
        {/* we need this logo when user switches to nav-function-top */}
        <div className="page-logo">
          <a
            className="page-logo-link press-scale-down d-flex align-items-center position-relative"
            data-toggle="modal"
            data-target="#modal-shortcut"
          >
            <img
              src="img/logo.png"
              alt="ActivePortal WebApp"
              aria-roledescription="logo"
            />
            <span className="page-logo-text mr-1">ActivePortal</span>
            <span className="position-absolute text-white opacity-50 small pos-top pos-right mr-2 mt-n2" />
            <i className="fal fa-angle-down d-inline-block ml-1 fs-lg color-primary-300" />
          </a>
        </div>
        {/* DOC: nav menu layout change shortcut */}
        <div className="hidden-md-down dropdown-icon-menu position-relative">
          <a
            onClick={() => {
              theme.navFunctionHidden
                ? removeFromTheme('navFunctionHidden')
                : setTheme({
                    navFunctionHidden: 'nav-function-hidden',
                  });
            }}
            className="header-btn btn js-waves-off"
            data-action="toggle"
            data-class="nav-function-hidden"
            title="Hide Navigation"
          >
            <i className="ni ni-menu" />
          </a>
          <ul>
            <li>
              <a
                onClick={() => {
                  theme.navFunctionMinify
                    ? removeFromTheme('navFunctionMinify')
                    : setTheme({
                        navFunctionMinify: 'nav-function-minify',
                      });
                }}
                className="btn js-waves-off"
                data-action="toggle"
                data-class="nav-function-minify"
                title="Minify Navigation"
              >
                <i className="ni ni-minify-nav" />
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  theme.navFunctionFixed
                    ? removeFromTheme('navFunctionFixed')
                    : setTheme({
                        navFunctionFixed: 'nav-function-fixed',
                      });
                }}
                className="btn js-waves-off"
                data-action="toggle"
                data-class="nav-function-fixed"
                title="Lock Navigation"
              >
                <i className="ni ni-lock-nav" />
              </a>
            </li>
          </ul>
        </div>
        {/* DOC: mobile button appears during mobile width */}
        <div className="hidden-lg-up">
          <a
            onClick={() => {
              theme.mobileNavOn
                ? removeFromTheme('mobileNavOn')
                : setTheme({
                    mobileNavOn: 'mobile-nav-on',
                  });
            }}
            className="header-btn btn press-scale-down"
            data-action="toggle"
            data-class="mobile-nav-on"
          >
            <i className="ni ni-menu" />
          </a>
        </div>
        <div className="position-relative header-org-selector hidden-sm-down">
          <div
            onClick={() => {
              setOrgDropdown(!orgDropdown);
            }}
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            className="org-dropdown"
          >
            <span className="fas fa-sitemap dropdown-item"></span>
            <div
              ref={orgRef}
              className={`dropdown-menu ${orgDropdown ? 'show ' : ''}`}
              style={orgDropdown ? orgDropdownVisible : dropdownHide}
            >
              {Object.keys(newArray).map(function (keyName, keyIndex) {
                // Pretty straightforward - use key for the key and value for the value.
                // Just to clarify: unlike object destructuring, the parameter names don't matter here.
                return (
                  <div className="dropdown-multilevel" key={keyIndex}>
                    <div className="dropdown-item">
                      <i className="fas fa-home" />
                      &nbsp; {keyName}
                    </div>
                    <div className="dropdown-menu">
                      {Object.keys(newArray[keyName]).map(function (
                        keyName2,
                        keyIndex2
                      ) {
                        return (
                          <a
                            key={keyIndex2}
                            className="dropdown-item"
                            onClick={() =>
                              changeTenant(newArray[keyName][keyName2])
                            }
                          >
                            <i className="fas fa-map-marker-alt" />
                            &nbsp; {newArray[keyName][keyName2].tenantName}
                          </a>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <ol className="breadcrumb m-0">
            <li className="breadcrumb-item">{accountInfo.org.name}</li>
            <li className="breadcrumb-item">{accountInfo.tenant.name}</li>
            <li className="breadcrumb-item text-danger">
              <a
                onClick={() => {
                  setNtuDropdown(!ntuDropdown);
                }}
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {ntu == '' || ntu == undefined ? 'Select NTU' : ntu.name}{' '}
                <i className="fal fa-angle-down" />
              </a>

              <div
                ref={ntuRef}
                className={`dropdown-menu ${ntuDropdown ? 'show ' : ''}`}
                style={ntuDropdown ? dropdownVisible : dropdownHide}
              >
                {ntuList.map((item, index) => (
                  <a
                    key={index}
                    className="dropdown-item"
                    onClick={() => {
                      setNtuDropdown(!ntuDropdown);
                      selectNTU(item);
                    }}
                  >
                    {item.name} - {item.locationCode}
                  </a>
                ))}
              </div>
            </li>
            {(ntu != '' || ntu != null) && (
              <li className="ntu-status-icon">
                <span>
                  <i
                    className={(() => {
                      switch (ntu.locked) {
                        case true:
                          return 'fa fa-lock';
                        case false: {
                          if (ntu.deviceConfig) return 'fa fa-unlock';
                          else return 'fa fa-exclamation';
                        }
                        default:
                          return 'fa fa-exclamation';
                      }
                    })()}
                  ></i>
                </span>
              </li>
            )}
          </ol>
        </div>

        <div className="ml-auto d-flex">
          {/* app settings */}
          <div className="hidden-md-down">
            <a
              className="header-icon"
              data-toggle="modal"
              data-target=".js-modal-settings"
              onClick={() => toggleModal('settingsPanel')}
            >
              <i className="fal fa-cog" />
            </a>
          </div>

          {/* app notification */}
          <div
            className={`${notificationsPanel ? 'show' : 'hide'}`}
            ref={notificationsClose}
          >
            <a
              className="header-icon"
              data-toggle="dropdown"
              title="You got 11 notifications"
              onClick={() => toggleDropdown('notificationsPanel')}
            >
              <i className="fal fa-bell" />
              <span className="badge badge-icon">11</span>
            </a>
            {notificationsPanel && <NotificationsPanel />}
          </div>
          {/* app user menu */}
          <div className={`${profilePanel ? 'show' : ''}`} ref={profileClose}>
            <a
              data-toggle="dropdown"
              title="drlantern@gotbootstrap.com"
              className="header-icon d-flex align-items-center justify-content-center ml-2"
              onClick={() => toggleDropdown('profilePanel')}
            >
              <img
                src={HeaderAvatar}
                className="profile-image rounded-circle"
                alt="Dr. Codex Lantern"
              />
              {/* you can also add username next to the avatar with the codes below:
									<span class="ml-1 mr-1 text-truncate text-truncate-header hidden-xs-down">Me</span>
									<i class="ni ni-chevron-down hidden-xs-down"></i> */}
            </a>
            {profilePanel && <ProfilePanel toggleModal={toggleModal} />}
          </div>
        </div>
      </header>
      <div className="position-relative header-org-selector hidden-md-up">
        <div
          onClick={() => {
            setOrgDropdown(!orgDropdown);
          }}
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          className="org-dropdown"
        >
          <span className="fas fa-sitemap dropdown-item"></span>
          <div
            ref={orgRef2}
            className={`dropdown-menu ${orgDropdown ? 'show ' : ''}`}
            style={orgDropdown ? orgDropdownVisible : dropdownHide}
          >
            {Object.keys(newArray).map(function (keyName, keyIndex) {
              // Pretty straightforward - use key for the key and value for the value.
              // Just to clarify: unlike object destructuring, the parameter names don't matter here.
              return (
                <div className="dropdown-multilevel" key={keyIndex}>
                  <div className="dropdown-item">
                    <i className="fas fa-home" />
                    &nbsp; {keyName}
                  </div>
                  <div className="dropdown-menu">
                    {Object.keys(newArray[keyName]).map(function (
                      keyName2,
                      keyIndex2
                    ) {
                      return (
                        <a
                          key={keyIndex2}
                          className="dropdown-item"
                          onClick={() =>
                            changeTenant(newArray[keyName][keyName2])
                          }
                        >
                          <i className="fas fa-map-marker-alt" />
                          &nbsp; {newArray[keyName][keyName2].tenantName}
                        </a>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <ol className="breadcrumb m-0">
          <li className="breadcrumb-item">{accountInfo.org.name}</li>
          <li className="breadcrumb-item">{accountInfo.tenant.name}</li>

          <li className="breadcrumb-item text-danger">
            <a
              onClick={() => {
                setNtuDropdown(!ntuDropdown);
              }}
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {ntu == '' ? 'Select NTU' : ntu.name}{' '}
              <i className="fal fa-angle-down" />
            </a>
            <div
              ref={ntuRef2}
              className={`dropdown-menu ${ntuDropdown ? 'show ' : ''}`}
              style={ntuDropdown ? dropdownVisible : dropdownHide}
            >
              {ntuList.map((item, index) => (
                <a
                  key={index}
                  className="dropdown-item"
                  onClick={() => {
                    setNtuDropdown(!ntuDropdown);
                    selectNTU(item);
                  }}
                >
                  {item.name} - {item.locationCode}
                </a>
              ))}
            </div>
          </li>
        </ol>
      </div>
    </Fragment>
  );
};

export default Header;
