import React, { useEffect, useState } from 'react';
import { Vega } from 'react-vega';

const DrawGraph = ({ esData, vegaGraph, graphDiv, width, height }) => {
  const [graphData, setGraphData] = useState({});
  const [showGraph, setShowGraph] = useState(false);
  const [graph, setGraph] = useState(vegaGraph);
  const drawGraph_response = async (response, div) => {
    console.log('Response', response.aggregations.results.buckets);
    var sum_rep_tags = 0;
    if (response != null) {
      if (response.aggregations.results.buckets.length == 0) {
      } else if (response.aggregations.results.buckets.length > 0) {
        setGraphData({ table: response.aggregations.results.buckets });
        //    console.log('result gtom graph', div);
        //    console.log(response.aggregations.results.buckets);
        setShowGraph(true);
      }
    }
  };

  useEffect(async () => {
    console.log(esData);
    drawGraph_response(esData, graphDiv);
  }, []);
  if (showGraph) {
    return (
      <div>
        <Vega spec={vegaGraph} data={graphData} />
      </div>
    );
  } else {
    if (graphData == {} || graphData == null) {
      return <p>No Data</p>;
    } else return <p>Loading...</p>;
  }
};

export default DrawGraph;
