import React, { useEffect, useState, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Formik, Form, Field, yupToFormErrors } from "formik";

import * as Yup from "yup";
import Panel from "../../../common/Panel/Panel";
import Alert from "../../../common/AlertControls/Alert";
import Autocomplete from "react-google-autocomplete";
import { handleCreateNewAlert } from "../../../common/Alerts/CreateNewAlert";
import { handleUpdateAlert } from "../../../common/Alerts/EditAlert";

import {
  createAuthorityRole,
  getAuthorityRole,
  updateAuthorityRole,
} from "./api/authorityRolesApi";

const AuthorityRoleUpdate = () => {
  const match = useParams();
  const navigate = useNavigate();
  let defaultValues = {
    id: "",
    name: "",
    description: "",
    authorities: [],
  };
  const [authorities, setAuthorities] = useState([
    "ROLE_ORG_ADMIN",
    "ROLE_BILLING",
    "ROLE_DEVELOPER",
    "ROLE_NETWORK_ADMIN",
    "ROLE_TENANT_ADMIN",
    "ROLE_USER",
    "ROLE_ADMIN",
    "ROLE_ORG_OWNER",
    "ROLE_COMPUTE",
  ]);
  const [initialData, setInitialData] = useState(defaultValues);
  const [appState, setAppState] = useState({
    loading: true,
  });

  const isNew = !match.id;
  const [alertShow, setAlertShow] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  // let alertShow = false;

  const schema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(250, "Too Long!")
      .required("Required"),
    description: Yup.string()
      .min(2, "Too Short!")
      .max(250, "Too Long!")
      .required("Required"),
    authorities: Yup.array().required("Required"),
  });

  async function createNewAuthorityRole(authorityRole) {
    const response = await createAuthorityRole(authorityRole).then((res) =>
      res.json()
    );
    if (response.id) {
      handleCreateNewAlert(
        'New authorityRole "' + authorityRole.name + '" added successfully!'
      );
      navigate("../");
    }
  }

  async function updateAuthorityRoles(authorityRole) {
    const response = await updateAuthorityRole(authorityRole).then((res) =>
      res.json()
    );

    if (response.id) {
      handleUpdateAlert("authorityRole", response.id);
      navigate("../" + response.id);
    }
  }

  function submitForm(formValues) {
    console.log(formValues);
    isNew
      ? createNewAuthorityRole(formValues)
      : updateAuthorityRoles(formValues);
  }

  // effect runs on component mount
  useEffect(() => {
    let unmounted = false;
    const fetchAuthorityRole = async () => {
      try {
        const result = await getAuthorityRole(match.id).then((res) =>
          res.json()
        );
        if (!unmounted) {
          if (result.description == null) result.description = "";
          setInitialData(result);
          //reset(result);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (!isNew) fetchAuthorityRole();
    setAppState({ loading: false });
    return () => {
      unmounted = true;
    };
  }, []);

  const onSubmit = (data) => {
    alert(JSON.stringify(data));
  };

  return (
    <div>
      <div className="row">
        <div className="col-xl-12">
          {alertShow && (
            <Alert
              alertType="alert-success"
              msg="The authorityRole has been updated."
            />
          )}

          {!appState.loading && initialData && (
            <Panel heading={initialData.name}>
              <Formik
                enableReinitialize
                initialValues={initialData}
                validationSchema={schema}
                validateOnMount
                onSubmit={submitForm}
              >
                {({ errors, touched, isValid, setFieldValue, values }) => {
                  return (
                    <Form>
                      {!isNew && (
                        <div className="form-group row m-b-15">
                          <label className="col-form-label col-md-3">ID</label>
                          <div className="col-md-9">
                            <Field
                              type="text"
                              name="id"
                              label="id"
                              className="form-control m-b-5"
                              readOnly
                            />
                          </div>
                        </div>
                      )}

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Name <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="name"
                            label="name"
                            className={`form-control m-b-5  ${
                              errors.name && touched.name ? "is-invalid" : ""
                            }`}
                            placeholder="Enter Authority Role Name"
                          />
                          {errors.name && touched.name ? (
                            <div className="invalid-feedback">
                              {errors.name}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Description <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="textarea"
                            as="textarea"
                            name="description"
                            label="description"
                            className={`form-control m-b-5  ${
                              errors.description && touched.description
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder="Enter Description"
                          />
                          {errors.description && touched.description ? (
                            <div className="invalid-feedback">
                              {errors.description}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Tiles <span className="text-danger"></span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            as="select"
                            multiple
                            name="authorities"
                            className={`form-control m-b-5  ${
                              errors.authorities && touched.authorities
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            {authorities?.map((tile, i) => (
                              <option key={i} value={tile}>
                                {tile}
                              </option>
                            ))}
                          </Field>
                          {errors.tiles && touched.tiles ? (
                            <div className="invalid-feedback">
                              {errors.tiles}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <button
                        type="submit"
                        className="btn btn-primary btn-block btn-lg m-b-5"
                        disabled={!isValid || isSubmitted}
                      >
                        Submit
                      </button>
                    </Form>
                  );
                }}
              </Formik>
            </Panel>
          )}
        </div>
      </div>
    </div>
  );
};

export default AuthorityRoleUpdate;
