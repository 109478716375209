import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Panel from '../../../common/Panel/Panel';
import { getEmailAddress } from './api/emailAddressesApi';

const EmailAddressDetail = () => {
  const match = useParams();
  const [emailAddressEntity, setEmailAddressEntity] = useState(null);
  const [appState, setAppState] = useState({
    loading: false,
  });

  useEffect(() => {
    setAppState({ loading: true });
    const fetchData = async () => {
      const result = await getEmailAddress(match.id).then((res) => res.json());
      console.log(result);
      setEmailAddressEntity(result);
    };
    fetchData();
    setAppState({ loading: false });
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-xl-6">
          {appState.loading && <p>Loading...</p>}
          {emailAddressEntity !== null && !appState.loading && (
            <>
              <span>Email Address</span> [
              <b>{emailAddressEntity.emailAddress}</b>]
              <dl className="row">
                <dt class="text-inverse  col-4 text-truncate">
                  <span id="name">
                    <span>Email Address</span>
                  </span>
                </dt>
                <dd class="col-8 text-truncate">
                  {emailAddressEntity.emailAddress}
                </dd>
              </dl>
              <div>
                <Link
                  className="btn btn-sm btn-default mr-3"
                  to="/administration/email-address"
                >
                  <i className="fal fa-arrow-left fa-sm  mr-2"></i>
                  Back
                </Link>
                <Link
                  className="btn btn-sm btn-primary m-r-5"
                  to={`/administration/email-address/${emailAddressEntity.id}/edit`}
                >
                  <i className="fal fa-pencil-alt fa-sm  mr-2"></i>
                  Edit
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmailAddressDetail;
