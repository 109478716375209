import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import Panel from '../../../common/Panel/Panel';
import Table from '../../../common/Table';

const NTU = ({ entities }) => {
  const organizations = JSON.parse(localStorage.getItem('accountInfo'))?.filter
    .filters;
  const { tenant } = JSON.parse(localStorage.getItem('accountInfo'));
  const ntu = localStorage.getItem('ntu');

  const data = entities;
  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'row_id',
        sortable: true,
        Cell: ({ row }) => {
          return (
            <div>
              <Link to={`${row.original.id}`}>{row.original.id}</Link>
            </div>
          );
        },
      },
      {
        Header: 'UID',
        accessor: 'uid',
        sortable: true,
      },
      {
        Header: 'Organization',
        accessor: (item) => {
          const org = organizations?.find((org) => org.orgId === item.orgId);
          return org?.orgName;
        },
        sortable: true,
      },
      {
        Header: 'Tenant',
        accessor: (d) => (tenant?.tenantId === d.tenantId ? tenant?.name : ''),
        sortable: true,
      },
      {
        Header: 'Name',
        accessor: 'name',
        sortable: true,
      },
      {
        Header: 'Type',
        accessor: 'ntutypeModel',
        sortable: true,
      },
      {
        Header: 'Serial No.',
        accessor: 'serialNumber',
        sortable: true,
      },
      {
        Header: 'Loopback Address',
        accessor: 'peLoopbackIP',
        sortable: true,
      },
      {
        Header: 'Version',
        accessor: 'firmwareVersion',
        sortable: true,
      },

      {
        Header: 'Mode',
        accessor: 'mode',
        sortable: true,
      },
      {
        Header: 'Port Location',
        accessor: 'locationCode',
        sortable: true,
      },
      {
        Header: '',
        accessor: 'actionColumns',
        className: 'align-middle',
        Cell: ({ row }) => {
          return (
            <div className="text-center d-flex">
              <Link
                className="btn btn-icon btn-circle btn-default mr-2"
                to={`${row.original.id}`}
              >
                <i className="fal fa-eye"></i>
              </Link>
              {ntu && (
                <>
                  <Link
                    className="btn btn-icon btn-circle btn-success mr-2"
                    to={`../edit/${row.original.id}`}
                  >
                    <i className="fal fa-pencil-alt"></i>
                  </Link>
                  <button className="btn btn-icon btn-circle btn-danger">
                    <i className="fal fa-trash"></i>
                  </button>
                </>
              )}
            </div>
          );
        },
      },
    ],
    []
  );
  return (
    <Panel>
      <Table data={data} columns={columns} />
    </Panel>
  );
};

export default NTU;
