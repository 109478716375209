import React, { useEffect, useState } from 'react';
import Panel from '../../../common/Panel/Panel';
import { Vega } from 'react-vega';
import geoip_internet_country_pie from '../../../common/graphs/vega/geoip_internet_country_pie.json';
import geoip_internet_city_pie from '../../../common/graphs/vega/geoip_internet_city_pie.json';
import geoip_internet_map from '../../../common/graphs/vega/geoip_internet_map.json';
import geoip_internet_map_country_src_request from '../../../common/graphs/vega/geoip_internet_map_country_src_request.json';
import geoip_internet_map_country_dst_request from '../../../common/graphs/vega/geoip_internet_map_country_dst_request.json';
import geoip_internet_map_city_src_request from '../../../common/graphs/vega/geoip_internet_map_city_src_request.json';
import geoip_internet_map_city_dst_request from '../../../common/graphs/vega/geoip_internet_map_city_dst_request.json';

import geoip_internet_map_request_src from '../../../common/graphs/vega/geoip_internet_map_request_src.json';
import DrawGraph from './DrawGraph';

const GeoIP = ({ lte, gte, ntu }) => {
  const [showGraph, setShowGraph] = useState(false);
  useEffect(() => {
    setShowGraph(true);
  }, []);
  return (
    <div>
      <div className="row">
        <div className="col-lg-3  col-md-6 col-sm-12 col-xs-12">
          <Panel heading="Countries Source">
            <div>
              <DrawGraph
                lte={lte}
                gte={gte}
                ntu={ntu}
                esRequest={geoip_internet_map_country_src_request}
                graphDiv={'#countries_source'}
                vegaGraph={geoip_internet_country_pie}
              />
            </div>
          </Panel>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
          <Panel heading="Countries Destination">
            <div>
              <DrawGraph
                lte={lte}
                gte={gte}
                ntu={ntu}
                esRequest={geoip_internet_map_country_dst_request}
                graphDiv={'#countries_dest'}
                vegaGraph={geoip_internet_country_pie}
              />
            </div>
          </Panel>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
          <Panel heading="Cities Source">
            <div>
              <DrawGraph
                lte={lte}
                gte={gte}
                ntu={ntu}
                esRequest={geoip_internet_map_city_src_request}
                graphDiv={'#cities_source'}
                vegaGraph={geoip_internet_city_pie}
              />
            </div>
          </Panel>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
          <Panel heading="Cities Destination">
            <div>
              <DrawGraph
                lte={lte}
                gte={gte}
                ntu={ntu}
                esRequest={geoip_internet_map_city_dst_request}
                graphDiv={'#cities_dest'}
                vegaGraph={geoip_internet_city_pie}
              />
            </div>
          </Panel>
        </div>
        <div className="col-md-12 col-sm-12 col-xs-12">
          <Panel heading="Internet Geo IP Source">
            <div>
              <DrawGraph
                lte={lte}
                gte={gte}
                ntu={ntu}
                esRequest={geoip_internet_map_request_src}
                graphDiv={'#internet_geoip_src'}
                vegaGraph={geoip_internet_map}
              />
            </div>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default GeoIP;
