import React from 'react';
import { Link } from 'react-router-dom';
import Panel from '../../../common/Panel/Panel';
import Table from '../../../common/Table';
import moment from 'moment';

import { handleDeleteAlert } from '../../../common/Alerts/DeleteAlert';
const BillingSystem = ({ entities }) => {
  const data = entities;

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        sortable: true,
        Cell: ({ row }) => {
          return (
            <div>
              <Link to={`${row.original.id}`}>{row.original.id}</Link>
            </div>
          );
        },
      },
      {
        Header: 'Name',
        accessor: 'name',
        sortable: true,
      },
      {
        Header: 'Description',
        accessor: 'description',
        sortable: true,
      },
      {
        Header: 'Api',
        accessor: 'api',
        sortable: true,
      },
      {
        Header: 'Stage',
        accessor: 'stage',
        sortable: true,
      },
      {
        Header: 'Username',
        accessor: 'username',
        sortable: true,
      },

      {
        Header: 'Currency Code',
        accessor: 'currencyCode',
        sortable: true,
      },
      {
        Header: 'Billing Day',
        accessor: (d) =>
          moment(d.startBilling).format('MMM Do YYYY hh:mm:ss A'),
        sortable: true,
      },
      {
        Header: '',
        accessor: 'actionColumns',
        className: 'align-middle',
        Cell: ({ row }) => {
          return (
            <div class="text-center d-flex">
              <Link
                className="btn btn-icon btn-circle btn-default mr-2"
                to={`${row.original.id}`}
              >
                <i className="fal fa-eye"></i>
              </Link>
              <Link
                className="btn btn-icon btn-circle btn-success mr-2"
                to={`${row.original.id}/edit`}
              >
                <i className="fal fa-pencil-alt"></i>
              </Link>
              <button
                className="btn btn-icon btn-circle btn-danger"
                onClick={handleDeleteAlert}
              >
                <i className="fal fa-trash"></i>
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <Panel>
      <Table data={data} columns={columns} />
    </Panel>
  );
};

export default BillingSystem;
