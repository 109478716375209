import React, { useEffect, useState } from 'react';
import Panel from '../../../common/Panel/Panel';
import DrawGraph from './DrawGraph';
import { getElasticSearchData } from './api/elasticsearch';
import moment from 'moment';
import tz from 'moment-timezone';

import applications_flow_source_pie_request from '../../../common/graphs/vega/applications/applications_flow_source_pie_request.json';
import applications_flow_source_pie from '../../../common/graphs/vega/applications/applications_flow_source_pie.json';

import applications_flow_dest_pie_request from '../../../common/graphs/vega/applications/applications_flow_dest_pie_request.json';
import applications_flow_dest_pie from '../../../common/graphs/vega/applications/applications_flow_dest_pie.json';

import application_client_bits_bar from '../../../common/graphs/vega/applications/application_client_bits_bar.json';
import application_client_bits_bar_request from '../../../common/graphs/vega/applications/application_client_bits_bar_request.json';

import application_server_bits_bar from '../../../common/graphs/vega/applications/application_server_bits_bar.json';
import application_server_bits_bar_request from '../../../common/graphs/vega/applications/application_server_bits_bar_request.json';

import application_client_packets_bar from '../../../common/graphs/vega/applications/application_client_packets_bar.json';
import application_client_packets_bar_request from '../../../common/graphs/vega/applications/application_client_packets_bar_request.json';

import application_server_packets_bar from '../../../common/graphs/vega/applications/application_server_packets_bar.json';
import application_server_packets_bar_request from '../../../common/graphs/vega/applications/application_server_packets_bar_request.json';

import applications_bits_bar_request from '../../../common/graphs/vega/applications/applications_bits_bar_request.json';
import applications_bits_per_second from '../../../common/graphs/vega/applications/applications_bits_per_second.json';

import applications_packets_bar_request from '../../../common/graphs/vega/applications/applications_packets_bar_request.json';
import applications_packets_per_second from '../../../common/graphs/vega/applications/applications_packets_per_second.json';

import applications_source_table_request from '../../../common/graphs/vega/applications/applications_source_table_request.json';
import applications_dest_table_request from '../../../common/graphs/vega/applications/applications_dest_table_request.json';

const TrafficDetails = ({ lte, gte, ntu }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [tableClientFlow, setTableClientFlow] = useState(0);
  const [tableServerFlow, setTableServerFlow] = useState(0);
  const [
    applications_flow_source_pie_data,
    set_applications_flow_source_pie_data,
  ] = useState({});

  const [applications_flow_dest_pie_data, set_applications_flow_dest_pie_data] =
    useState({});

  const [applications_bits_bar_data, set_applications_bits_bar_data] = useState(
    {}
  );

  const [applications_packets_bar_data, set_applications_packets_bar_data] =
    useState({});

  const [
    application_client_bits_bar_data,
    set_application_client_bits_bar_data,
  ] = useState({});

  const [
    application_server_bits_bar_data,
    set_application_server_bits_bar_data,
  ] = useState({});

  const [
    application_client_packets_bar_data,
    set_application_client_packets_bar_data,
  ] = useState({});

  const [
    application_server_packets_bar_data,
    set_application_server_packets_bar_data,
  ] = useState({});

  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  var serial;
  const excludeUnknown = false;
  var ignore_throttled = 1;
  const getLTE = () => {
    if (lte == null || lte == '') {
      lte = new Date();
      lte = moment();
      lte = lte._d.getTime();
    }
    return lte;
  };
  const getGTE = () => {
    if (gte == null || gte == '') {
      gte = new Date();
      gte = moment().subtract(3, 'hours');
      gte = gte._d.getTime();
    }
    return gte;
  };

  const getIgnoreThrottled = () => {
    var now = new Date();
    //var lte_diff = moment(now).diff(moment(vm.lte), 'days')
    var gte_diff = moment(now).diff(moment(getGTE()), 'months');
    //             console.log("lte_diff = " + lte_diff);
    console.log('gte_diff = ' + gte_diff);
    if (gte_diff > 0) {
      ignore_throttled = 0; // searchable snapshot
    }
    return ignore_throttled;
  };

  const elasticRequest = async (request, div) => {
    request.query.bool.filter.push({
      term: { 'serial-number.keyword': ntu.serialNumber },
    });
    //data.query.bool.must[0].query_string["query"] = "serial-number: " + vm.serial;
    request.query.bool.must[0].range['@timestamp'].gte = getGTE();
    request.query.bool.must[0].range['@timestamp'].lte = getLTE();

    try {
      let response = await getElasticSearchData('search', {
        ignoreThrottled: getIgnoreThrottled(),
        index: 'apptrack-*',
        size: 0,

        body: JSON.stringify(request),
      }).then((res) => res.json());

      if (div == '#applications_flow_source_pie') {
        set_applications_flow_source_pie_data(response);
      }
      if (div == '#applications_flow_dest_pie1') {
        set_applications_flow_dest_pie_data(response);
      }
      if (div == '#application_bits_per_second') {
        set_applications_bits_bar_data(response);
      }
      if (div == '#application_packets_per_second') {
        set_applications_packets_bar_data(response);
      }
      if (div == '#application_client_bits') {
        set_application_client_bits_bar_data(response);
      }
      if (div == '#application_server_bits') {
        set_application_server_bits_bar_data(response);
      }
      if (div == '#application_client_packets') {
        set_application_client_packets_bar_data(response);
      }
      if (div == '#application_server_packets') {
        set_application_server_packets_bar_data(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function handleResize() {
    setIsLoading(true);
    setIsLoading(false);
  }

  const TableClientFlow = async () => {
    applications_source_table_request.query.bool.filter.push({
      term: { 'serial-number.keyword': ntu.serialNumber },
    });

    applications_source_table_request.query.bool.filter.push({
      term: { 'apptrack_status.keyword': 'APPTRACK_SESSION_CLOSE' },
    });

    if (excludeUnknown === true) {
      applications_source_table_request.query.bool.must_not.push({
        term: { 'nested-application.keyword': 'UNKNOWN' },
      });
      applications_source_table_request.query.bool.must_not.push({
        term: { 'application.keyword': 'UNKNOWN' },
      });
    }

    //data.query.bool.must[0].query_string["query"] = "serial-number: " + serial;
    applications_source_table_request.query.bool.must[0].range[
      '@timestamp'
    ].gte = getGTE();
    applications_source_table_request.query.bool.must[0].range[
      '@timestamp'
    ].lte = getLTE();
    try {
      let response = await getElasticSearchData('search', {
        ignoreThrottled: ignore_throttled,
        index: 'apptrack-*',
        size: 0,

        body: JSON.stringify(applications_source_table_request),
      }).then((res) => res.json());
      console.log(response);

      setTableClientFlow(response.aggregations.results.buckets.length);

      setIsLoading1(true);
    } catch (error) {
      console.log(error);
    }
  };

  const TableServerFlow = async () => {
    applications_dest_table_request.query.bool.filter.push({
      term: { 'serial-number.keyword': ntu.serialNumber },
    });

    applications_dest_table_request.query.bool.filter.push({
      term: { 'apptrack_status.keyword': 'APPTRACK_SESSION_CLOSE' },
    });

    if (excludeUnknown === true) {
      applications_dest_table_request.query.bool.must_not.push({
        term: { 'nested-application.keyword': 'UNKNOWN' },
      });
      applications_dest_table_request.query.bool.must_not.push({
        term: { 'application.keyword': 'UNKNOWN' },
      });
    }

    //data.query.bool.must[0].query_string["query"] = "serial-number: " + serial;
    applications_dest_table_request.query.bool.must[0].range['@timestamp'].gte =
      getGTE();
    applications_dest_table_request.query.bool.must[0].range['@timestamp'].lte =
      getLTE();
    try {
      let response = await getElasticSearchData('search', {
        ignoreThrottled: ignore_throttled,
        index: 'apptrack-*',
        size: 0,

        body: JSON.stringify(applications_dest_table_request),
      }).then((res) => res.json());
      console.log(response);

      setTableServerFlow(response.aggregations.results.buckets.length);

      setIsLoading2(true);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(async () => {
    // application_zone_packets also for this

    await elasticRequest(
      applications_flow_source_pie_request,
      '#applications_flow_source_pie'
    );

    await elasticRequest(
      applications_flow_dest_pie_request,
      '#applications_flow_dest_pie1'
    );

    await elasticRequest(
      applications_bits_bar_request,
      '#application_bits_per_second'
    );

    await elasticRequest(
      applications_packets_bar_request,
      '#application_packets_per_second'
    );

    await elasticRequest(
      application_client_bits_bar_request,
      '#application_client_bits'
    );

    await elasticRequest(
      application_server_bits_bar_request,
      '#application_server_bits'
    );

    await elasticRequest(
      application_client_packets_bar_request,
      '#application_client_packets'
    );

    await elasticRequest(
      application_server_packets_bar_request,
      '#application_server_packets'
    );

    await TableClientFlow();
    await TableServerFlow();

    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    setIsLoading(false);
  }, []);

  if (isLoading)
    return (
      <div class="LoadingContainer text-center">
        <p>Loading</p>
      </div>
    );
  else
    return (
      <div>
        <div className="row">
          <div className="col-lg-4  col-md-6 col-sm-12 col-xs-12">
            <Panel
              heading="Clients (flow records)
"
            >
              <div>
                <DrawGraph
                  esData={applications_flow_source_pie_data}
                  graphDiv={'#applications_flow_source_pie'}
                  vegaGraph={applications_flow_source_pie}
                />
              </div>
            </Panel>
          </div>

          <div className="col-lg-4  col-md-6 col-sm-12 col-xs-12">
            <Panel
              heading="Servers (flow records)
"
            >
              <div>
                <DrawGraph
                  esData={applications_flow_dest_pie_data}
                  graphDiv={'#applications_flow_dest_pie1'}
                  vegaGraph={applications_flow_dest_pie}
                />
              </div>
              <div></div>
            </Panel>
          </div>
          <div className="col-lg-4">
            <div className="p-3 bg-warning-700 rounded overflow-hidden position-relative text-white mb-1">
              <div className>
                <h3 className="display-4 d-block l-h-n m-0 fw-500">
                  {tableClientFlow}
                  <small className="m-0 l-h-n">Clients</small>
                </h3>
              </div>
              <i
                className="fal fa-poll-people position-absolute pos-right pos-bottom opacity-15 mb-n1 mr-n1"
                style={{ fontSize: '6rem' }}
              />
            </div>
            <div className="p-3 bg-success-700 rounded overflow-hidden position-relative text-white mb-1">
              <div className>
                <h3 className="display-4 d-block l-h-n m-0 fw-500">
                  {tableServerFlow}
                  <small className="m-0 l-h-n">Servers</small>
                </h3>
              </div>
              <i
                className="fal fa-server position-absolute pos-right pos-bottom opacity-15 mb-n1 mr-n1"
                style={{ fontSize: '6rem' }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6  col-md-6 col-sm-12 col-xs-12">
            <Panel
              heading="Applications bits per second
"
            >
              <div>
                <DrawGraph
                  esData={applications_bits_bar_data}
                  graphDiv={'#application_bits_per_second'}
                  vegaGraph={applications_bits_per_second}
                />
              </div>
            </Panel>
          </div>
          <div className="col-lg-6  col-md-6 col-sm-12 col-xs-12">
            <Panel
              heading="Applications packets per second
"
            >
              <div>
                <DrawGraph
                  esData={applications_packets_bar_data}
                  graphDiv={'#application_packets_per_second'}
                  vegaGraph={applications_packets_per_second}
                />
              </div>
            </Panel>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6  col-md-6 col-sm-12 col-xs-12">
            <Panel
              heading="Client bits per second
"
            >
              <div>
                <DrawGraph
                  esData={application_client_bits_bar_data}
                  graphDiv={'#application_client_bits'}
                  vegaGraph={application_client_bits_bar}
                />
              </div>
            </Panel>
          </div>
          <div className="col-lg-6  col-md-6 col-sm-12 col-xs-12">
            <Panel
              heading="Server bits per second
"
            >
              <div>
                <DrawGraph
                  esData={application_server_bits_bar_data}
                  graphDiv={'#application_server_bits'}
                  vegaGraph={application_server_bits_bar}
                />
              </div>
            </Panel>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6  col-md-6 col-sm-12 col-xs-12">
            <Panel
              heading="Client packets per second

"
            >
              <div>
                <DrawGraph
                  esData={application_client_packets_bar_data}
                  graphDiv={'#application_client_packets'}
                  vegaGraph={application_client_packets_bar}
                />
              </div>
            </Panel>
          </div>
          <div className="col-lg-6  col-md-6 col-sm-12 col-xs-12">
            <Panel heading="Services packets per second">
              <div>
                <DrawGraph
                  esData={application_server_packets_bar_data}
                  graphDiv={'#application_server_packets'}
                  vegaGraph={application_server_packets_bar}
                />
              </div>
            </Panel>
          </div>
        </div>
      </div>
    );
};

export default TrafficDetails;
