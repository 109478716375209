import React from 'react';
import propTypes from 'prop-types';
import './progressbar.scss';
const index = ({ steps, activeStep }) => {
  const makeSteps = steps.map((step, id) => {
    return (
      <div
        key={id}
        className={`stepper-item ${step.id == activeStep ? 'active' : ''} ${
          step.id < activeStep ? 'completed' : ''
        }`}
      >
        <div className="step-counter">{step.id}</div>
        <div className="step-name">{step.name}</div>
      </div>
    );
  });
  return <div className="stepper-wrapper">{makeSteps} </div>;
};

export default index;
