import React, { useEffect, useState, Fragment } from "react";
import { Routes, Route, useLocation, Link } from "react-router-dom";
import moment from "moment";

import LoginAudits from "./LoginAudits";
import { getLoginAudits } from "./api/loginAuditsApi";
import DateRangePicker from "../../../common/DateRangerPicker/DateRangePicker";

let now = new Date();

const LoginAuditsContainer = () => {
  const location = useLocation();

  const [startDate, setStartDate] = useState(
    moment(new Date("2022-03-13 18:00")).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment(now).format("YYYY-MM-DD"));
  const [entities, setEntities] = useState([]);
  const [appState, setAppState] = useState({
    loading: false,
    err: false,
  });

  useEffect(() => {
    (async () => {
      try {
        setAppState({ loading: true, err: false });
        const res = await getLoginAudits(startDate, endDate).then((res) =>
          res.json()
        );
        setAppState({ loading: false });
        setEntities(res);
      } catch (err) {
        setAppState({ loading: false, err: true });
      }
    })();
  }, [startDate, endDate]);

  return (
    <Fragment>
      <ol className="breadcrumb page-breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">ActivePort</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to="/system">System</Link>
        </li>
        <li className="breadcrumb-item active">Login Audits</li>
        <li className="position-absolute pos-top pos-right d-none d-sm-block">
          <span className="js-get-date"></span>
        </li>
      </ol>
      <div className="subheader">
        <h1 className="subheader-title">
          <i className="subheader-icon fal fa-table"></i> Login Audits
        </h1>
        {!location.pathname.includes("/new") &&
          !location.pathname.includes("/edit") && (
            <Link to={`new`}>
              <button
                type="button"
                className="btn btn-success waves-effect waves-themed"
              >
                <span className="fal fa-plus mr-1"></span>
                Create
              </button>
            </Link>
          )}
      </div>
      <div className="bg-white p-4  mb-3">
        <h3>Pick start and end date</h3>
        <div className="input-group date-time">
          <DateRangePicker
            compStartDate={startDate}
            compEndDate={endDate}
            setCompStartDate={setStartDate}
            setCompEndDate={setEndDate}
          />
          <div className="input-group-append">
            <span className="input-group-text fs-xl">
              <i className="fal fa-calendar" />
            </span>
          </div>
        </div>
      </div>
      {!appState.loading && !appState.err && (
        <Routes>
          <Route path={`/`} element={<LoginAudits entities={entities} />} />
        </Routes>
      )}
      {appState.loading && !appState.error && (
        <div className="border p-3">
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      )}
      {!appState.loading && appState.err && (
        <div className="alert alert-danger" role="alert">
          <strong>Oh snap!</strong> We are not able to connect to the database.
          Try again in few minutes. again.
        </div>
      )}
    </Fragment>
  );
};

export default LoginAuditsContainer;
