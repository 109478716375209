import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import Panel from '../../../common/Panel/Panel';
import Table from '../../../common/Table';

import { handleDeleteAlert } from '../../../common/Alerts/DeleteAlert';
const PEDownlinkPorts = ({ entities }) => {
  const data = entities;

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'row_id',
        sortable: true,
        Cell: ({ row }) => {
          return (
            <div>
              <Link to={`${row.original.tenantId}`}>{row.original.id}</Link>
            </div>
          );
        },
      },
      {
        Header: 'Group name',
        accessor: 'name',
        sortable: true,
      },
      {
        Header: 'PE',
        accessor: 'centralSwitchName',
        sortable: true,
      },
      {
        Header: 'Location',
        accessor: 'locationCode',
        sortable: true,
      },

      {
        Header: 'PE Downlink Ports',
        accessor: (d) =>
          d.ports.map((item) => {
            return <button class="btn btn-light mr-1 mb-1">{item}</button>;
          }),
        sortable: true,
      },
      {
        Header: 'UID',
        accessor: 'assignedTenantId',
        sortable: true,
      },
      {
        Header: 'Description',
        accessor: 'description',
        sortable: true,
      },
      {
        Header: 'Organisation Name',
        accessor: 'assignedOrgName',
        sortable: true,
      },
      {
        Header: 'NTU Name',
        accessor: 'assignedVntuName',
        sortable: true,
      },

      {
        Header: '',
        accessor: 'actionColumns',
        className: 'align-middle',
        Cell: ({ row }) => {
          return (
            <div className="text-center d-flex">
              <Link
                className="btn btn-icon btn-circle btn-default mr-2"
                to={`/settings/locations/${row.original.id}`}
              >
                <i className="fal fa-eye"></i>
              </Link>
              <Link
                className="btn btn-icon btn-circle btn-success mr-2"
                to={`/settings/locations/${row.original.id}/edit`}
              >
                <i className="fal fa-pencil-alt"></i>
              </Link>
              <button
                className="btn btn-icon btn-circle btn-danger"
                onClick={handleDeleteAlert}
              >
                <i className="fal fa-trash"></i>
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <Panel>
      <Table data={data} columns={columns} />
    </Panel>
  );
};

export default PEDownlinkPorts;
