import React, { useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Panel from '../../../common/Panel/Panel';
import Table from '../../../common/Table';
import { CIRCUIT_TYPES } from '../../../constants/circuitTypeEnum';
import { handleDeleteAlert } from '../../../common/Alerts/DeleteAlert';

const filterOptions = [
  { text: 'PE [JUNOS]', value: 'PE-JUNOS' },
  { text: 'NTU [JUNOS]', value: 'NTU-JUNOS' },
  { text: 'NTU [MIKROTIK]', value: 'NTU-MIKROTIK' },
  { text: 'NTU SD-WAN [ DEBIAN ]', value: 'SD_WAN-DEBIAN' },
];

const ServiceCommands = ({ entities, params, setParams }) => {
  const data = entities;
  //const match = useParams();

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'row_id',
        sortable: true,
        Cell: ({ row }) => {
          return (
            <div>
              <Link to={`/${row.original.tenantId}`}>{row.original.id}</Link>
            </div>
          );
        },
      },
      {
        Header: 'Name',
        accessor: 'name',
        sortable: true,
      },
      {
        Header: 'Entry Type',
        accessor: 'entryType',
        sortable: true,
      },
      {
        Header: 'On Event',
        accessor: 'onEvent',
        sortable: true,
      },

      {
        Header: 'Device Type',
        accessor: 'deviceType',
        sortable: true,
      },
      {
        Header: 'Routing',
        accessor: 'routingType',
        sortable: true,
      },
      {
        Header: 'Filter Types',
        accessor: (d) =>
          d.circuitTypes.map((item) => {
            var index = CIRCUIT_TYPES[item]; // 1

            return <button class="btn btn-light mr-1 mb-1">{index}</button>;
          }),
        sortable: true,
      },
      {
        Header: 'OS Type',
        accessor: 'osType',
        sortable: true,
      },

      {
        Header: '',
        accessor: 'actionColumns',
        className: 'align-middle',
        Cell: ({ row }) => {
          return (
            <div className="text-center d-flex">
              <Link
                className="btn btn-icon btn-circle btn-default mr-2"
                to={`${row.original.id}`}
              >
                <i className="fal fa-eye"></i>
              </Link>
              <Link
                className="btn btn-icon btn-circle btn-success mr-2"
                to={`edit/${row.original.id}     `}
              >
                <i className="fal fa-pencil-alt"></i>
              </Link>
              <button
                className="btn btn-icon btn-circle btn-danger"
                onClick={handleDeleteAlert}
              >
                <i className="fal fa-trash"></i>
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  const handleParams = (rawValue) => {
    const [device, os] = rawValue.split('-');
    setParams({
      device,
      os,
    });
  };

  return (
    <Panel>
      <label>Filter</label>
      <select
        className="mb-3 form-control"
        onChange={(e) => handleParams(e.target.value)}
        value={`${params.device}-${params.os}`}
      >
        <option value="">Select an option</option>
        {filterOptions.map((opt, i) => (
          <option key={i} value={opt.value}>
            {opt.text}
          </option>
        ))}
      </select>
      <Table data={data} columns={columns} />
    </Panel>
  );
};

export default ServiceCommands;
