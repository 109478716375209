export const uploadMedia = (file) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/media/upload';
  var formData = new FormData();
  // Update the formData object
  formData.append('uploadfile', file, file.name);
  console.log(file);

  return fetch(baseUrl, {
    method: 'POST',
    body: formData,
  });
};
