import React, { useEffect, useState, Fragment } from 'react';
import { Routes, Route, useLocation, Link } from 'react-router-dom';

import SDWANAggregators from './SDWANAggregators';
// import SDWANAggregatorDetail from "./SDWANAggregatorDetail";
import SDWANAggregatorUpdate from './SDWANAggregatorUpdate';
// import SDWANAggregatorDelete from "./SDWANAggregatorDeleteDialog";
import {
  getSDWANAggregators,
  getPEForDropdown,
} from './api/sdwanAggregatorsApi';
import { getLocations } from '../Locations/api/LocationsApi';

const SDWANAggregatorsContainer = () => {
  const location = useLocation();

  const [entities, setEntities] = useState([]);
  const [locations, setLocations] = useState([]);
  const [pe, setPe] = useState([]);
  const [appState, setAppState] = useState({
    loading: false,
    err: false,
  });

  useEffect(() => {
    (async () => {
      try {
        setAppState({ loading: true, err: false });
        const res = await getSDWANAggregators().then((res) => res.json());
        const locationsRes = await getLocations().then((res) => res.json());
        const peRes = await getPEForDropdown().then((res) => res.json());
        setAppState({ loading: false });
        setEntities(res);
        setLocations(locationsRes);
        setPe(peRes);
      } catch (err) {
        setAppState({ loading: false, err: true });
      }
    })();
  }, [location.key]);

  return (
    <Fragment>
      <ol className="breadcrumb page-breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">ActivePort</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to="/administration">Settings</Link>
        </li>
        <li className="breadcrumb-item active">SDWAN Aggregators</li>
        <li className="position-absolute pos-top pos-right d-none d-sm-block">
          <span className="js-get-date"></span>
        </li>
      </ol>
      <div className="subheader">
        <h1 className="subheader-title">
          <i className="subheader-icon fal fa-table"></i> SDWAN Aggregators
        </h1>

        {!location.pathname.includes('/new') &&
          !location.pathname.includes('/edit') && (
            <Link to={`new`}>
              <button
                type="button"
                className="btn btn-success waves-effect waves-themed"
              >
                <span className="fal fa-plus mr-1"></span>
                Create
              </button>
            </Link>
          )}
      </div>
      {!appState.loading && !appState.err && (
        <Routes>
          <Route
            exact
            path={`/new`}
            element={<SDWANAggregatorUpdate locations={locations} pe={pe} />}
          />
          {/* <Route exact path={`/edit/:id`} element={<SDWANAggregatorUpdate />} /> */}
          {/* <Route exact path={`:id`} element={<SDWANAggregatorDetail />} /> */}

          <Route
            path={`/`}
            element={<SDWANAggregators entities={entities} />}
          />
        </Routes>
      )}
      {appState.loading && !appState.error && (
        <div className="border p-3">
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      )}
      {!appState.loading && appState.err && (
        <div className="alert alert-danger" role="alert">
          <strong>Oh snap!</strong> We are not able to connect to the database.
          Try again in few minutes. again.
        </div>
      )}
      {/* <Route
          exact
          path={`/:id/delete`}
          element={SDWANAggregatorDelete}
        /> */}
    </Fragment>
  );
};

export default SDWANAggregatorsContainer;
