import MediaFiles from '../components/System/MediaFiles';
import TemplateConfigurations from '../components/System/TemplateConfigurations';
import Organisations from '../components/System/Organisations';
import ProviderDefinitions from '../components/System/ProviderDefinitions';
import AuthorityRoles from '../components/System/AuthorityRoles';
import NtuTypes from '../components/System/NtuTypes';
import ServiceTypes from '../components/System/ServiceTypes';
import ServiceDefinition from '../components/System/ServiceDefinition';
import HelpSections from '../components/System/HelpSections';

import HelpContents from '../components/System/HelpContents';

import EntityAudits from '../components/System/EntityAudits';

import EntityManagers from '../components/System/EntityMangers';
import LoginAudits from '../components/System/LoginAudits';

export const SystemRoutes = [
  {
    element: <TemplateConfigurations />,
    path: '/system/template-configuration/*',
    roleNames: ['ROLE_ADMIN'],
  },
  {
    element: <Organisations />,
    path: '/system/organisations/*',
    roleNames: ['ROLE_ADMIN'],
  },
  {
    element: <EntityManagers />,
    path: '/system/entity-manager/*',
    roleNames: ['ROLE_ADMIN'],
  },
  {
    element: <ProviderDefinitions />,
    path: '/system/provider-configurations/*',
    roleNames: ['ROLE_ADMIN'],
  },
  {
    element: <ServiceDefinition />,
    path: '/system/service-code/*',
    roleNames: ['ROLE_ADMIN'],
  },
  {
    element: <AuthorityRoles />,
    path: '/system/user-roles/*',
    roleNames: ['ROLE_ADMIN'],
  },
  {
    element: <EntityAudits />,
    path: '/system/entity-audits/*',
    roleNames: ['ROLE_ADMIN'],
  },
  {
    element: <LoginAudits />,
    path: '/system/audits/*',
    roleNames: ['ROLE_ADMIN'],
  },
  {
    element: <ServiceTypes />,
    path: '/system/service-types/*',
    roleNames: ['ROLE_ADMIN'],
  },
  {
    element: <MediaFiles />,
    path: '/system/media/*',
    roleNames: ['ROLE_ADMIN'],
  },
  {
    element: <HelpSections />,
    path: '/system/help-section/*',
    roleNames: ['ROLE_ADMIN'],
  },
  {
    element: <HelpContents />,
    path: '/system/help-content/*',
    roleNames: ['ROLE_ADMIN'],
  },
  {
    element: <NtuTypes />,
    path: '/system/ntu-types/*',
    roleNames: ['ROLE_ADMIN'],
  },
];
