export const getHelpContents = () => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/api/help-content";
  return fetch(baseUrl, {
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getHelpContent = (id) => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/api/help-content";
  const requestUrl = `${baseUrl}/${id}`;
  return fetch(requestUrl, {
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const createHelpContent = (helpContent) => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/api/help-content";
  return fetch(baseUrl, {
    method: "POST",
    body: JSON.stringify(helpContent),
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const updateHelpContent = (helpContent) => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/api/help-content";
  return fetch(baseUrl, {
    method: "PUT",
    body: JSON.stringify(helpContent),
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const deleteHelpContent = (id) => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/api/help-content";
  const requestUrl = `${baseUrl}/${id}`;
  return fetch(requestUrl, {
    method: "DELETE",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};
