/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import Panel from "../../../../common/Panel/Panel";
import { getNTU } from "../api/NTUApi";
import { Details } from "./Details";
import { Rates } from "./Rates";
import { Subnets } from "./Subnets";
import { Underlay } from "./Underlay";
import { Ports } from "./Ports";
import { Services } from "./Services";
import { Download } from "./Download";

const initialData = {
  id: "",
  uid: "",
};

export const NtUUpdate = () => {
  const match = useParams();
  const isNew = !match.id;

  const [appState, setAppState] = useState({
    loading: true,
    data: {},
  });
  const [activeTab, setActiveTab] = useState("Details");

  // effect runs on component mount
  useEffect(() => {
    setAppState((prev) => ({ ...prev, loading: true }));
    let unmounted = false;
    // simulate async api call with set timeout
    const fetchNFVConfig = async () => {
      try {
        const result = await getNTU(match.id).then((res) => res.json());
        if (!unmounted) {
          if (result.description == null) result.description = "";
          setAppState({ loading: false, data: result });
        }
      } catch (error) {
        setAppState((prev) => ({ ...prev, loading: false }));
        console.log(error);
      }
    };
    if (!isNew) fetchNFVConfig();
    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <Panel heading={initialData.name}>
            <ul className="nav nav-pills mb-3" role="tablist">
              {[
                "Details",
                "Rates",
                "Subnets",
                "Underlay",
                "Ports",
                "Services",
                "Download",
              ].map((item, i) => (
                <li
                  key={i}
                  className="nav-item cursor-pointer"
                  onClick={() => setActiveTab(item)}
                >
                  <a
                    className={`nav-link ${activeTab === item && "active"}`}
                    dataToggle="pill"
                  >
                    {item}
                  </a>
                </li>
              ))}
            </ul>
            <Formik
              enableReinitialize
              initialValues={appState.data || initialData}
              // validationSchema={schema}
              validateOnMount
              onSubmit={() => {}}
            >
              {({ errors, touched, isValid, setFieldValue, values }) => {
                return (
                  <Form>
                    {activeTab === "Details" && <Details values={values} />}
                    {activeTab === "Rates" && <Rates />}
                    {activeTab === "Subnets" && (
                      <Subnets subnets={appState.data?.subnets} />
                    )}
                    {activeTab === "Underlay" && <Underlay />}
                    {activeTab === "Ports" && <Ports />}
                    {activeTab === "Services" && <Services id={match.id} />}
                    {activeTab === "Download" && <Download />}
                    <div className="d-flex justify-content-end">
                      <button
                        type="submit"
                        className="w-25 btn btn-primary btn-block btn-lg m-b-5"
                      >
                        Save
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </Panel>
        </div>
      </div>
    </>
  );
};
