import React, { useState, useEffect } from 'react';
import Panel from '../../../common/Panel/Panel';
import services_bits_bar_lg from '../../../common/graphs/vega/services_bits_bar_lg.json';
import services_bits_bar_request from '../../../common/graphs/vega/services_bits_bar_request.json';
import client_packets_bytes_table_request from '../../../common/graphs/vega/client_packets_bytes_table_request.json';
import server_packets_bytes_table_request from '../../../common/graphs/vega/server_packets_bytes_table_request.json';

import DrawGraph from './DrawGraph';
import { getElasticSearchData } from './api/elasticsearch';
import moment from 'moment';
import tz from 'moment-timezone';
import TopClients from './TopClients';
import TopServers from './TopServers';

const TopN = ({ lte, gte, ntu }) => {
  var serial;
  const [clientPacketsBytes, setClientPacketsBytes] = useState({});
  const [totalItemsClient, setTotalItemsClient] = useState({});
  const [queryCountClient, setQueryCountClient] = useState({});

  const [serverPacketsBytes, setServerPacketsBytes] = useState({});
  const [totalItemsServer, setTotalItemsServer] = useState({});
  const [queryCountServer, setQueryCountServer] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);

  const getLTE = () => {
    if (lte == null || lte == '') {
      lte = new Date();
      lte = moment();
      lte = lte._d.getTime();
    }
    return lte;
  };
  const getGTE = () => {
    if (gte == null || gte == '') {
      gte = new Date();
      gte = moment().subtract(3, 'hours');
      gte = gte._d.getTime();
    }
    return gte;
  };

  const TableClientPacketsBytes = async () => {
    serial = ntu.serialNumber;
    if (ntu.mode == 'VIRTUAL') {
      serial = ntu.peSerialNumber;
    }
    client_packets_bytes_table_request.query.bool.filter.push({
      term: { 'serial-number': serial },
    });
    //data.query.bool.must[0].query_string["query"] = "serial-number: " + vm.serial;
    client_packets_bytes_table_request.query.bool.must[0].range[
      '@timestamp'
    ].gte = getGTE();
    client_packets_bytes_table_request.query.bool.must[0].range[
      '@timestamp'
    ].lte = getLTE();

    try {
      let response = await getElasticSearchData('search', {
        ignoreThrottled: 1,
        index: 'elastiflow-3.5.1-*',
        size: 0,

        body: JSON.stringify(client_packets_bytes_table_request),
      }).then((res) => res.json());
      console.log(response);

      setClientPacketsBytes(response.aggregations.results.buckets);
      setTotalItemsClient(response.aggregations.results.buckets.length);
      setQueryCountClient(totalItemsClient);

      console.log(
        'client packets bytes: ',
        response.aggregations.results.buckets
      );
      setIsLoading(true);
    } catch (error) {
      console.log(error);
    }
  };

  const TableServerPacketsBytes = async () => {
    serial = ntu.serialNumber;
    if (ntu.mode == 'VIRTUAL') {
      serial = ntu.peSerialNumber;
    }
    server_packets_bytes_table_request.query.bool.filter.push({
      term: { 'serial-number': serial },
    });
    //data.query.bool.must[0].query_string["query"] = "serial-number: " + vm.serial;
    server_packets_bytes_table_request.query.bool.must[0].range[
      '@timestamp'
    ].gte = getGTE();
    server_packets_bytes_table_request.query.bool.must[0].range[
      '@timestamp'
    ].lte = getLTE();

    try {
      let response = await getElasticSearchData('search', {
        ignoreThrottled: 1,
        index: 'elastiflow-3.5.1-*',
        size: 0,

        body: JSON.stringify(server_packets_bytes_table_request),
      }).then((res) => res.json());
      console.log(response);

      setServerPacketsBytes(response.aggregations.results.buckets);
      setTotalItemsServer(response.aggregations.results.buckets.length);
      setQueryCountServer(totalItemsClient);

      console.log(
        'server packets bytes: ',
        response.aggregations.results.buckets
      );
      setIsLoading2(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    TableClientPacketsBytes();
    TableServerPacketsBytes();
  }, []);
  return (
    <div className="row">
      <div className="col-md-12 col-sm-12 col-xs-12">
        <Panel heading="Server and Clients (bytes)">
          <div>
            <DrawGraph
              lte={lte}
              gte={gte}
              ntu={ntu}
              esRequest={services_bits_bar_request}
              graphDiv={'#services_bits'}
              vegaGraph={services_bits_bar_lg}
            />
          </div>
        </Panel>
      </div>
      <div className="col-md-6 col-sm-12 col-xs-12">
        <Panel heading="Top Clients">
          {isLoading && <TopClients allClients={clientPacketsBytes} />}
        </Panel>
      </div>
      <div className="col-md-6 col-sm-12 col-xs-12">
        <Panel heading="Top Servers">
          {isLoading2 && <TopServers allServers={serverPacketsBytes} />}
        </Panel>
      </div>
    </div>
  );
};

export default TopN;
