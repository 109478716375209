import React, { useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Panel from '../Panel/Panel';
import Table from '../Table';
import Button from '../Button';
const mediaImg = {
  width: 'auto',
  maxWidth: '200px',
  height: 'auto',
};
const ImageSelector = ({ entities, getFileName, field, setFieldValue }) => {
  const data = entities;

  const columns = useMemo(
    () => [
      {
        Header: 'Image Preview',
        accessor: (d) => (
          <div className="">
            <img
              style={mediaImg}
              src={`https://test.activeport.com.au/media/download?filename=${d.filename}`}
            />
          </div>
        ),
        sortable: true,
      },

      {
        Header: 'Filename',
        accessor: 'filename',
        sortable: true,
      },
      {
        Header: '',
        accessor: 'actionColumns',
        className: 'align-middle',
        Cell: ({ row }) => {
          return (
            <div className="text-center d-flex">
              <Link
                className="btn btn-icon btn-circle btn-default mr-2"
                to={`${row.original.id}`}
              >
                <i className="fal fa-eye"></i>
              </Link>
              <Button
                onClick={() =>
                  getFileName(field, row.original.filename, setFieldValue)
                }
              >
                Select
              </Button>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <Panel>
      <Table data={data} columns={columns} />
    </Panel>
  );
};

export default ImageSelector;
