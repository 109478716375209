import React, { useState, useEffect } from 'react';
import Panel from '../../../common/Panel/Panel';
import DrawGraph from './DrawGraph';
import { getElasticSearchData } from './api/elasticsearch';
import moment from 'moment';
import tz from 'moment-timezone';
import RepDataTable from './RepDataTable';
import PublicRepDataTable from './PublicRepDataTable';
import RiskDataTable from './RiskDataTable';
import BigRepsDataTable from './BigRepsDataTable';
import autonomous_systems_source_bits_bar from '../../../common/graphs/vega/internet_threats_ip_reputations_bar.json';
import autonomous_systems_source_bits_bar_request from '../../../common/graphs/vega/internet_threats_ip_reputations_bar_request.json';

import internet_threats_ip_reputations_table_request from '../../../common/graphs/vega/internet_threats_ip_reputations_table_request.json';
import internet_threats_ip_reputations_public_table_request from '../../../common/graphs/vega/internet_threats_ip_reputations_public_table_request.json';
import internet_threats_ip_reputations_at_risk_request from '../../../common/graphs/vega/internet_threats_ip_reputations_public_table_request.json';
import internet_threats_ip_reputations_big_table_request from '../../../common/graphs/vega/internet_threats_ip_reputations_big_table_request.json';

const Threats = ({ lte, gte, ntu }) => {
  var serial;
  var ignore_throttled = 1;
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const [isLoading4, setIsLoading4] = useState(false);
  const [repData, setRepData] = useState({});
  const [repPublicData, setRepPublicData] = useState({});
  const [riskData, setRiskData] = useState({});
  const [repBigData, setRepBigData] = useState({});
  const getLTE = () => {
    if (lte == null || lte == '') {
      lte = new Date();
      lte = moment();
      lte = lte._d.getTime();
    }
    return lte;
  };
  const getGTE = () => {
    if (gte == null || gte == '') {
      gte = new Date();
      gte = moment().subtract(3, 'hours');
      gte = gte._d.getTime();
    }
    return gte;
  };
  const TableReps = async () => {
    serial = ntu.serialNumber;
    if (ntu.mode == 'VIRTUAL') {
      serial = ntu.peSerialNumber;
    }
    internet_threats_ip_reputations_table_request.query.bool.filter.push({
      term: { 'serial-number': serial },
    });
    //data.query.bool.must[0].query_string["query"] = "serial-number: " + ntu.serialNumber;
    internet_threats_ip_reputations_table_request.query.bool.must[0].range[
      '@timestamp'
    ].gte = getGTE();
    internet_threats_ip_reputations_table_request.query.bool.must[0].range[
      '@timestamp'
    ].lte = getLTE();

    try {
      let response = await getElasticSearchData('search', {
        ignoreThrottled: ignore_throttled,
        index: 'elastiflow-3.5.1-*',
        size: 0,

        body: JSON.stringify(internet_threats_ip_reputations_table_request),
      }).then((res) => res.json());
      console.log(response);

      setRepData(response.aggregations.results.buckets);

      setIsLoading(true);
    } catch (error) {
      console.log(error);
    }
  };
  const TablePublicReps = async () => {
    serial = ntu.serialNumber;
    if (ntu.mode == 'VIRTUAL') {
      serial = ntu.peSerialNumber;
    }
    internet_threats_ip_reputations_public_table_request.query.bool.filter.push(
      { term: { 'serial-number': serial } }
    );
    //data.query.bool.must[0].query_string["query"] = "_exists_:flow.rep_tags AND !flow.src_autonomous_system:private";
    internet_threats_ip_reputations_public_table_request.query.bool.must[0].range[
      '@timestamp'
    ].gte = getGTE();
    internet_threats_ip_reputations_public_table_request.query.bool.must[0].range[
      '@timestamp'
    ].lte = getLTE();

    try {
      let response = await getElasticSearchData('search', {
        ignoreThrottled: 1,
        index: 'elastiflow-3.5.1-*',
        size: 0,

        body: JSON.stringify(
          internet_threats_ip_reputations_public_table_request
        ),
      }).then((res) => res.json());
      console.log(response);
      var rep_data = [];
      //                             rep_public_data = response.aggregations.results.buckets;
      for (var x = 0; x < response.aggregations.results.buckets.length; x++) {
        if (response.aggregations.results.buckets[x].rep_tags !== undefined) {
          if (
            response.aggregations.results.buckets[x].rep_tags.buckets.length > 0
          ) {
            rep_data.push(response.aggregations.results.buckets[x]);
          }
        }
      }
      setRepPublicData(rep_data);

      setIsLoading2(true);
    } catch (error) {
      console.log(error);
    }
  };
  const TableRisk = async () => {
    serial = ntu.serialNumber;
    if (ntu.mode == 'VIRTUAL') {
      serial = ntu.peSerialNumber;
    }
    internet_threats_ip_reputations_at_risk_request.query.bool.filter.push({
      term: { 'serial-number': serial },
    });
    //data.query.bool.must[0].query_string["query"] = "flow.dst_autonomous_system:private AND _exists_:flow.rep_tags";
    internet_threats_ip_reputations_at_risk_request.query.bool.must[0].range[
      '@timestamp'
    ].gte = getGTE();
    internet_threats_ip_reputations_at_risk_request.query.bool.must[0].range[
      '@timestamp'
    ].lte = getLTE();

    try {
      let response = await getElasticSearchData('search', {
        ignoreThrottled: 1,
        index: 'elastiflow-3.5.1-*',
        size: 0,

        body: JSON.stringify(internet_threats_ip_reputations_at_risk_request),
      }).then((res) => res.json());
      console.log(response);
      var rep_data = [];
      for (var x = 0; x < response.aggregations.results.buckets.length; x++) {
        if (response.aggregations.results.buckets[x].rep_tags !== undefined) {
          if (
            response.aggregations.results.buckets[x].rep_tags.buckets.length > 0
          ) {
            rep_data.push(response.aggregations.results.buckets[x]);
          }
        }
      }

      setRiskData(rep_data);
      setIsLoading3(true);
    } catch (error) {
      console.log(error);
    }
  };
  const TableBigReps = async () => {
    serial = ntu.serialNumber;
    if (ntu.mode == 'VIRTUAL') {
      serial = ntu.peSerialNumber;
    }
    internet_threats_ip_reputations_big_table_request.query.bool.filter.push({
      term: { 'serial-number': serial },
    });
    //data.query.bool.must[0].query_string["query"] = "_exists_:flow.rep_tags";
    internet_threats_ip_reputations_big_table_request.query.bool.must[0].range[
      '@timestamp'
    ].gte = getGTE();
    internet_threats_ip_reputations_big_table_request.query.bool.must[0].range[
      '@timestamp'
    ].lte = getLTE();

    try {
      let response = await getElasticSearchData('search', {
        ignoreThrottled: 1,
        index: 'elastiflow-3.5.1-*',
        size: 0,

        body: JSON.stringify(internet_threats_ip_reputations_big_table_request),
      }).then((res) => res.json());
      console.log(response);
      var rep_data = [];
      var cnt = 0;
      //rep_big_data = response.aggregations.results.buckets;
      for (var x = 0; x < response.aggregations.results.buckets.length; x++) {
        if (response.aggregations.results.buckets[x].src !== undefined) {
          if (response.aggregations.results.buckets[x].src.buckets.length > 0) {
            for (
              var y = 0;
              y < response.aggregations.results.buckets[x].src.buckets.length;
              y++
            ) {
              if (
                response.aggregations.results.buckets[x].src.buckets[y].rep_tags
                  .buckets.length > 0
              ) {
                var ostats = {
                  key: response.aggregations.results.buckets[x].key,
                  results:
                    response.aggregations.results.buckets[x].src.buckets[y],
                };
                rep_data[cnt] = ostats;
                cnt++;
              }
            }
          }
        }
      }

      //                         }
      setRepBigData(rep_data);

      setIsLoading4(true);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    TableReps();
    TablePublicReps();
    TableRisk();
    TableBigReps();
  }, []);
  return (
    <div className="row">
      <div className="col-lg-12  col-md-12 col-sm-12 col-xs-12">
        <Panel heading="Threats">
          <div>
            <DrawGraph
              lte={lte}
              gte={gte}
              ntu={ntu}
              esRequest={autonomous_systems_source_bits_bar_request}
              graphDiv={'#reputation'}
              vegaGraph={autonomous_systems_source_bits_bar}
            />
          </div>
        </Panel>
      </div>
      <div className="col-md-6 col-sm-12 col-xs-12">
        <Panel heading="Reputation">
          {isLoading && <RepDataTable data={repData} />}
        </Panel>
      </div>
      <div className="col-md-6 col-sm-12 col-xs-12">
        <Panel heading="Public Reputation">
          {isLoading2 && <PublicRepDataTable data={repPublicData} />}
        </Panel>
      </div>
      <div className="col-md-6 col-sm-12 col-xs-12">
        <Panel heading="Risks">
          {isLoading3 && <RiskDataTable data={riskData} />}
        </Panel>
      </div>
      <div className="col-md-12 col-sm-12 col-xs-12">
        <Panel heading="Reputation Big Data">
          {isLoading4 && <BigRepsDataTable data={repBigData} />}
        </Panel>
      </div>
    </div>
  );
};
export default Threats;
