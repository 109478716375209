export const getNTUs = async () => {
  const token = localStorage.getItem('token');

  const baseUrl = 'https://test.activeport.com.au/api/ntus/';
  const data = await fetch(baseUrl, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
  if (!data.ok) {
    const message = `An error has occured: ${data.status}`;
    throw new Error(message);
  }
  const response = await data.json();

  return response;
};

export const getDeviceConfig = (ntuID) => {
  const token = localStorage.getItem('token');
  // let ntuID = JSON.parse(localStorage.getItem('ntu')).id;
  console.log('ntu api ntuID:', ntuID);
  return fetch(`https://test.activeport.com.au/api/ntus/config/${ntuID}`, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getServiceList = async (mode) => {
  const token = localStorage.getItem('token');
  console.log('API NTU : ', mode);

  const data = await fetch(
    `https://test.activeport.com.au/api/tile-tenant-configurations/service-list?mode=${mode}`,
    {
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    }
  );
  if (!data.ok) {
    const message = `An error has occured: ${data.status}`;
    throw new Error(message);
  }
  const response = await data.json();

  return response;
};
