export const getProviders = async () => {
  const token = localStorage.getItem("token");
  const ntuId = JSON.parse(localStorage.getItem("ntu")).id;
  return fetch(
    `https://test.activeport.com.au/api/tile-configurations/by-name/IBM?cacheBuster=1652341498905&ntuId=${ntuId}&profile=`,
    {
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    }
  );
};
