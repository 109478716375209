import React, { useEffect, useState } from 'react';
import Panel from '../../../common/Panel/Panel';
import { Vega } from 'react-vega';

import ServerBytesPie from '../../../common/graphs/vega/server_bytes_pie.json';
import ServicesBytesPie from '../../../common/graphs/vega/services_bytes_pie.json';
import AutonomousSystemsBytesPie from '../../../common/graphs/vega/autonomous_systems_bytes_pie.json';
import IPVersionBytesPie from '../../../common/graphs/vega/ip_versions_bytes_pie.json';
const Overview = ({
  ServerBytesData,
  ServicesBytesData,
  AutonomousSystemsBytesData,
  IPVersionsBytesData,
}) => {
  const [showGraph, setShowGraph] = useState(false);

  useEffect(() => {
    // ServicesBytesPie.data[0].values = ServicesBytesData;

    // AutonomousSystemsBytesPie.data[0].values = AutonomousSystemsBytesData;
    console.log('AUTONOMOUSE:', AutonomousSystemsBytesData);
    setShowGraph(true);
  }, []);
  return (
    <div className="row">
      {' '}
      <div className="col-md-6 col-sm-6 col-xs-12">
        <Panel heading="Server and Clients (bytes)">
          <div>
            {ServerBytesData.table != {} && showGraph && (
              <Vega spec={ServerBytesPie} data={ServerBytesData} />
            )}
          </div>
        </Panel>
      </div>
      <div className="col-md-6 col-sm-6 col-xs-12">
        <Panel heading="Services (bytes)">
          <div>
            {ServicesBytesData.table != {} && showGraph && (
              <Vega spec={ServicesBytesPie} data={ServicesBytesData} />
            )}
          </div>
        </Panel>
      </div>
      <div className="col-md-6 col-sm-6 col-xs-12">
        <Panel heading="Autonomous Systems (bytes)">
          <div>
            {AutonomousSystemsBytesData.table != {} && showGraph && (
              <Vega
                spec={AutonomousSystemsBytesPie}
                data={AutonomousSystemsBytesData}
              />
            )}
          </div>
        </Panel>
      </div>
      <div className="col-md-6 col-sm-6 col-xs-12">
        <Panel heading="IP Versions and Protocols (bytes)">
          <div>
            {IPVersionsBytesData.table != {} && showGraph && (
              <Vega spec={IPVersionBytesPie} data={IPVersionsBytesData} />
            )}
          </div>
        </Panel>
      </div>
    </div>
  );
};

export default Overview;
