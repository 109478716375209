import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";

import HelpText from "../../../../common/Form/HelpText/HelpText";
import { getService, getNtuPorts } from "../api/localNetApi";

const initialValues = {
  service: "",
  spaceType: "Route",
  port: "",
  ipAddress: "",
  bandwidth: 10,
};

export const AddInternetForm = ({ show, setShow, onSaveAttribute }) => {
  const [data, setData] = useState(initialValues);
  const [ntuPorts, setNtuPorts] = useState([]);

  useEffect(() => {
    const fetcher = async () => {
      try {
        const service = await getService().then((res) => res.json());
        const ntuPortsRes = await getNtuPorts().then((res) => res.json());
        setData((prev) => ({
          ...prev,
          service: service.services[0].name,
        }));
        setNtuPorts(ntuPortsRes);
      } catch (error) {
        console.log(error);
      }
    };
    fetcher();
  }, []);

  const onHide = (e) => {
    e.preventDefault();
    setShow(!show);
  };

  const saveAttribute = (values) => {
    onSaveAttribute(values);
    setShow(false);
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={data}
        onSubmit={(values, { resetForm }) => {
          saveAttribute(values);
          resetForm();
        }}
      >
        {({ values }) => {
          return (
            <div
              className={`modal fade overflow-auto ${show && "show d-block"}`}
            >
              <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <div className="d-flex w-100 align-items-center justify-content-between">
                      <img src="/assets/img/ntu/ACTIVEPORT-TILE.png" alt="" />
                      <button type="button" className="close" onClick={onHide}>
                        <span aria-hidden="true">
                          <i className="fal fa-times"></i>
                        </span>
                      </button>
                    </div>
                  </div>
                  <div className="modal-body">
                    <Form>
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Service
                        </label>
                        <div className="col-md-9">
                          <Field
                            readOnly
                            type="text"
                            name="service"
                            className="form-control m-b-5"
                          />
                        </div>
                      </div>
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Space Type
                        </label>
                        <div className="col-md-9">
                          <Field
                            as="select"
                            name="spaceType"
                            className={`form-control m-b-5`}
                          >
                            {["Route", "NAT"].map((type, i) => (
                              <option key={i} value={type}>
                                {type}
                              </option>
                            ))}
                          </Field>
                        </div>
                      </div>
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Set Port
                        </label>
                        <div className="col-md-9">
                          <Field
                            as="select"
                            name="port"
                            className={`form-control m-b-5`}
                          >
                            <option value=""></option>
                            {ntuPorts?.map((port) => (
                              <option key={port.id} value={port.name}>
                                {port.name}
                              </option>
                            ))}
                          </Field>
                        </div>
                      </div>
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Bandwidth (Mbps)
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="number"
                            name="bandwidth"
                            className="form-control m-b-5"
                          />
                          <HelpText text="Committed Rate Limit Mbit/s" />
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary waves-effect waves-themed"
                          onClick={onHide}
                        >
                          Close
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary waves-effect waves-themed"
                        >
                          Next
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
    </>
  );
};
