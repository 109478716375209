export const getEntityManagers = () => {
  const token = localStorage.getItem("token");
  const baseUrl =
    "https://test.activeport.com.au/api/entity-managers?size=99999999";

  return fetch(baseUrl, {
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getEntityManager = (id) => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/api/entity-managers/";

  const requestUrl = `${baseUrl}${id}`;
  return fetch(requestUrl, {
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const createEntityManager = (entityManager) => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/api/entity-managers";

  return fetch(baseUrl, {
    method: "POST",
    body: JSON.stringify(entityManager),
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const updateEntityManager = (entityManager) => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/api/entity-managers/";

  return fetch(baseUrl, {
    method: "PUT",
    body: JSON.stringify(entityManager),
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const deleteEntityManager = (id) => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/api/entity-managers/";
  const requestUrl = `${baseUrl}${id}`;
  return fetch(requestUrl, {
    method: "DELETE",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};
