import './administration.css';
import { useContext, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import SettingsContext from '../../store/settings-context';
import { Link } from 'react-router-dom';

const Administration = () => {
  const settingsCtx = useContext(SettingsContext);

  const { t } = useTranslation();

  return (
    <Fragment>
      <ol className="breadcrumb page-breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">ActivePort</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to="/administration">Dashboard</Link>
        </li>
      </ol>
      <div className="subheader">
        <h1 className="subheader-title">
          <i className="subheader-icon fal fa-globe" />
          {t('global.dashboard', 'Dashboard')}
          {settingsCtx.NTU != '' && (
            <small>Selected NTU: {settingsCtx.NTU.name}</small>
          )}
        </h1>
      </div>

      {/* Your main content goes below here: */}
      <div className="row">
        <div className="col-xl-8">
          <div id="panel-1" className="panel">
            <div className="panel-hdr">
              <h2>Settings</h2>
              <div className="panel-toolbar">
                <button
                  className="btn btn-panel"
                  data-action="panel-collapse"
                  data-toggle="tooltip"
                  data-offset="0,10"
                  data-original-title="Collapse"
                />
                <button
                  className="btn btn-panel"
                  data-action="panel-fullscreen"
                  data-toggle="tooltip"
                  data-offset="0,10"
                  data-original-title="Fullscreen"
                />
                <button
                  className="btn btn-panel"
                  data-action="panel-close"
                  data-toggle="tooltip"
                  data-offset="0,10"
                  data-original-title="Close"
                />
              </div>
            </div>
            <div className="panel-container show">
              <div className="panel-content">
                <div className="row admin-dashboard-settings">
                  <div className="col-xl-4">
                    <button
                      type="button"
                      className="btn btn-lg btn-block btn-outline-primary waves-effect waves-themed"
                    >
                      <span className="fal fa-book mr-1"></span>
                      API
                    </button>
                  </div>
                  <div className="col-xl-4">
                    <button
                      type="button"
                      className="btn btn-lg btn-block btn-outline-primary waves-effect waves-themed"
                    >
                      <span className="fal fa-inventory mr-1"></span>
                      Inventory
                    </button>
                  </div>
                  <div className="col-xl-4">
                    <button
                      type="button"
                      className="btn btn-lg btn-block btn-outline-primary waves-effect waves-themed"
                    >
                      <span className="fal fa-users mr-1"></span>
                      User Profiles
                    </button>
                  </div>
                  <div className="col-xl-4">
                    <button
                      type="button"
                      className="btn btn-lg btn-block btn-outline-primary waves-effect waves-themed"
                    >
                      <span className="fal fa-users-cog mr-1"></span>
                      User Management
                    </button>
                  </div>
                  <div className="col-xl-4">
                    <button
                      type="button"
                      className="btn btn-lg btn-block btn-outline-primary waves-effect waves-themed"
                    >
                      <span className="fal fa-house-user mr-1"></span>
                      Tenants
                    </button>
                  </div>
                  <div className="col-xl-4">
                    <button
                      type="button"
                      className="btn btn-lg btn-block btn-outline-primary waves-effect waves-themed"
                    >
                      <span className="fal fa-book mr-1"></span>
                      Email Templates
                    </button>
                  </div>
                  <div className="col-xl-4">
                    <button
                      type="button"
                      className="btn btn-lg btn-block btn-outline-primary waves-effect waves-themed"
                    >
                      <span className="fal fa-book mr-1"></span>
                      Notification Emails
                    </button>
                  </div>
                  <div className="col-xl-4">
                    <button
                      type="button"
                      className="btn btn-lg btn-block btn-outline-primary waves-effect waves-themed"
                    >
                      <span className="fal fa-book mr-1"></span>
                      Email Setup
                    </button>
                  </div>
                  <div className="col-xl-4">
                    <button
                      type="button"
                      className="btn btn-lg btn-block btn-outline-primary waves-effect waves-themed"
                    >
                      <span className="fal fa-book mr-1"></span>
                      Billing System
                    </button>
                  </div>
                  <div className="col-xl-4">
                    <button
                      type="button"
                      className="btn btn-lg btn-block btn-outline-primary waves-effect waves-themed"
                    >
                      <span className="fal fa-book mr-1"></span>
                      Email Addresses
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* this overlay is activated only when mobile menu is triggered */}
      <div
        className="page-content-overlay"
        data-action="toggle"
        data-class="mobile-nav-on"
      ></div>
    </Fragment>
  );
};

export default Administration;
