import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Panel from "../../../common/Panel/Panel";
import { getSubnetConfiguration } from "./api/subnetConfigurationsApi";

const SubnetConfigurationDetail = ({ hasAuthority }) => {
  const match = useParams();
  const ntu = localStorage.getItem("ntu");
  const navigate = useNavigate();
  const [subnetConfigurationEntity, setSubnetConfigurationEntity] =
    useState(null);
  const [appState, setAppState] = useState({
    loading: false,
  });

  useEffect(() => {
    setAppState({ loading: true });
    const fetchData = async () => {
      try {
        const result = await getSubnetConfiguration(match.id).then((res) =>
          res.json()
        );
        setSubnetConfigurationEntity(result);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    setAppState({ loading: false });
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-xl-10">
          {appState.loading && <p>Loading...</p>}
          {subnetConfigurationEntity !== null && !appState.loading && (
            <Panel heading={subnetConfigurationEntity.name}>
              <dl className="row">
                <dt className="text-inverse  col-4 ">
                  <span>
                    <span>Subnet</span>
                  </span>
                </dt>
                <dd className="col-8 ">{subnetConfigurationEntity.subnet}</dd>

                <dt className="text-inverse  col-4 ">
                  <span>
                    <span>Name</span>
                  </span>
                </dt>
                <dd className="col-8 ">{subnetConfigurationEntity.name}</dd>

                <dt className="text-inverse  col-4 ">
                  <span>
                    <span>Description</span>
                  </span>
                </dt>
                <dd className="col-8 ">
                  {subnetConfigurationEntity.desciption}
                </dd>

                <dt className="text-inverse  col-4 ">
                  <span>
                    <span>Mask</span>
                  </span>
                </dt>
                <dd className="col-8 ">{subnetConfigurationEntity.mask}</dd>

                <dt className="text-inverse  col-4 ">
                  <span>
                    <span>Subnet Size</span>
                  </span>
                </dt>
                <dd className="col-8 ">
                  {subnetConfigurationEntity.subnetSize}
                </dd>

                <dt className="text-inverse  col-4 ">
                  <span>
                    <span>First IP</span>
                  </span>
                </dt>
                <dd className="col-8 ">{subnetConfigurationEntity.firstIP}</dd>

                <dt className="text-inverse  col-4 ">
                  <span>
                    <span>Last IP</span>
                  </span>
                </dt>
                <dd className="col-8 ">{subnetConfigurationEntity.lastIP}</dd>

                <dt className="text-inverse  col-4 ">
                  <span>
                    <span>Broadcast</span>
                  </span>
                </dt>
                <dd className="col-8 ">
                  {subnetConfigurationEntity.broadcast}
                </dd>

                <dt className="text-inverse  col-4 ">
                  <span>
                    <span>Gen CIDR</span>
                  </span>
                </dt>
                <dd className="col-8 ">{subnetConfigurationEntity.genCidr}</dd>

                <dt className="text-inverse  col-4 ">
                  <span>
                    <span>Location</span>
                  </span>
                </dt>
                <dd className="col-8 ">
                  {subnetConfigurationEntity.locationCode}
                </dd>

                <dt className="text-inverse  col-4 ">
                  <span>
                    <span>Excluded Subnets</span>
                  </span>
                </dt>
                <dd className="col-8 ">
                  {subnetConfigurationEntity.excludedSubnets}
                </dd>
              </dl>

              <div>
                <button
                  className="btn btn-sm btn-secondary mr-3"
                  onClick={() => navigate("../")}
                >
                  <i className="fal fa-arrow-left fa-sm  mr-2"></i>
                  Back
                </button>
                {ntu && (
                  <button
                    className="btn btn-sm btn-primary m-r-5"
                    onClick={() => navigate("../edit/" + match.id)}
                  >
                    <i className="fal fa-pencil-alt fa-sm  mr-2"></i>
                    Edit
                  </button>
                )}
              </div>
            </Panel>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubnetConfigurationDetail;
