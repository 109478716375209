export const getNFVConfigs = () => {
  const token = localStorage.getItem("token");
  const baseUrl =
    "https://test.activeport.com.au/api/nfv-configs?size=99999999";

  return fetch(baseUrl, {
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getNFVConfig = (id) => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/api/nfv-configs/";

  const requestUrl = `${baseUrl}${id}`;
  return fetch(requestUrl, {
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const createNFVConfig = (nfvConfig) => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/api/nfv-configs";

  return fetch(baseUrl, {
    method: "POST",
    body: JSON.stringify(nfvConfig),
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const updateNFVConfig = (nfvConfig) => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/api/nfv-configs/";

  return fetch(baseUrl, {
    method: "PUT",
    body: JSON.stringify(nfvConfig),
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const deleteNFVConfig = (id) => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/api/nfv-configs/";
  const requestUrl = `${baseUrl}${id}`;
  return fetch(requestUrl, {
    method: "DELETE",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};
