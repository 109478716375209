import React, { useMemo, useState, useEffect } from 'react';
import Table from '../../../common/Table';

import { handleDeleteAlert } from '../../../common/Alerts/DeleteAlert';
const RiskDataTable = ({ data }) => {
  const columns = useMemo(
    () => [
      {
        Header: 'At Risk Devices',
        accessor: (d) => {
          return d.key;
        },
        sortable: true,
      },
      {
        Header: 'Flow Records',
        accessor: (d) => {
          return d.doc_count;
        },
        sortable: true,
      },
    ],
    []
  );
  useEffect(() => {}, []);
  if (data.length == 0) return <p>No Data</p>;
  else return <Table data={data} columns={columns} heading="" />;
};

export default RiskDataTable;
