import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  useContext,
} from 'react';
import moment from 'moment';
import tz from 'moment-timezone';
import DateRangePicker from '../../../common/DateRangerPicker/DateRangePicker';

const DateTimePicker = ({ NTUPorts, setPort, getDateTime }) => {
  console.log('Date Time', NTUPorts);
  let options = [];
  const [selected, setSelected] = useState('');
  let now = new Date();
  const tz = moment.tz.guess();

  let lte = '';
  let gte = '';
  const getLTE = () => {
    if (lte == '') {
      lte = new Date();
      lte = moment();
    }
    return lte;
  };
  const getGTE = () => {
    if (gte == '') {
      gte = new Date();
      gte = moment().subtract(3, 'hours');
    }
    return gte;
  };

  const startDate = moment(
    new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      now.getHours(),
      now.getMinutes(),
      now.getSeconds()
    )
  ).subtract(3, 'hours');

  const endDate = moment(new Date());

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <div className="input-group date-time">
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              getDateTime={getDateTime}
            />
            <div className="input-group-append">
              <span className="input-group-text fs-xl">
                <i className="fal fa-calendar" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateTimePicker;
