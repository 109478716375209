/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";

import * as Yup from "yup";
import Panel from "../../../common/Panel/Panel";
import Alert from "../../../common/AlertControls/Alert";
import { handleCreateNewAlert } from "../../../common/Alerts/CreateNewAlert";
import { handleUpdateAlert } from "../../../common/Alerts/EditAlert";

import {
  createSubnetConfiguration,
  getSubnetConfiguration,
  updateSubnetConfiguration,
} from "./api/subnetConfigurationsApi";

const SubnetConfigurationUpdate = ({ locations }) => {
  const match = useParams();
  const navigate = useNavigate();
  let defaultValues = {
    id: "",
    locationCode: "",
    name: "",
    desciption: "",
    ranges: [],
    scopeName: "",
    genCidr: 30,
    ntuAllocation: "FIRST",
    peAllocation: "NONE",
  };

  const [initialData, setInitialData] = useState(defaultValues);
  const [appState, setAppState] = useState({
    loading: true,
  });
  const [activeTab, setActiveTab] = useState("Setup");

  const isNew = !match.id;
  const [alertShow, setAlertShow] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [reservedIpRanges, setReservedIpRanges] = useState([]);
  // let alertShow = false;

  const schema = Yup.object().shape({
    memory: Yup.number()
      .min(2, "Too Short!")
      .max(250, "Too Long!")
      .required("Required"),
  });

  async function createNewSubnetConfiguration(subnetConfiguration) {
    const response = await createSubnetConfiguration(subnetConfiguration).then(
      (res) => res.json()
    );
    if (response.id) {
      handleCreateNewAlert(
        'New subnetConfiguration "' +
          subnetConfiguration.name +
          '" added successfully!'
      );
      navigate("../");
    }
  }

  async function updateSubnetConfigurations(subnetConfiguration) {
    const response = await updateSubnetConfiguration(subnetConfiguration).then(
      (res) => res.json()
    );
    if (response.id) {
      handleUpdateAlert("subnetConfiguration", subnetConfiguration.id);
      navigate("../" + subnetConfiguration.id);
    }
  }

  function submitForm(formValues) {
    delete formValues.reservedIp;
    isNew
      ? createNewSubnetConfiguration(formValues)
      : updateSubnetConfigurations(formValues);
  }

  const onAddRanges = (range, setFieldValue) => {
    const updatedRanges = [...reservedIpRanges, range];
    setReservedIpRanges(updatedRanges);
    setFieldValue("reservedIp", "");
    setFieldValue("ranges", updatedRanges);
  };

  // effect runs on component mount
  useEffect(() => {
    let unmounted = false;
    // simulate async api call with set timeout
    const fetchSubnetConfiguration = async () => {
      try {
        const result = await getSubnetConfiguration(match.id).then((res) =>
          res.json()
        );
        if (!unmounted) {
          if (result.description == null) result.description = "";
          setInitialData(result);
          setReservedIpRanges(result.ranges);
          //reset(result);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (!isNew) fetchSubnetConfiguration();
    setAppState({ loading: false });
    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-xl-9">
          {alertShow && (
            <Alert
              alertType="alert-success"
              msg="The subnetConfiguration has been updated."
            />
          )}

          {!appState.loading && initialData && (
            <Panel>
              <ul className="nav nav-pills mb-3" role="tablist">
                {["Setup", "Allocation Rules"].map((item, i) => (
                  <li
                    key={i}
                    className="nav-item cursor-pointer"
                    onClick={() => setActiveTab(item)}
                  >
                    <a
                      className={`nav-link ${activeTab === item && "active"}`}
                      dataToggle="pill"
                    >
                      {item}
                    </a>
                  </li>
                ))}
              </ul>

              <Formik
                enableReinitialize
                initialValues={initialData}
                // validationSchema={schema}
                validateOnMount
                onSubmit={submitForm}
              >
                {({ errors, touched, isValid, values, setFieldValue }) => {
                  let ActiveForm = null;
                  switch (activeTab) {
                    case "Setup":
                      ActiveForm = (
                        <>
                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Location
                              <span className="text-danger"></span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                as="select"
                                name="locationCode"
                                className={`form-control m-b-5  ${
                                  errors.locationCode && touched.locationCode
                                    ? "is-invalid"
                                    : ""
                                }`}
                              >
                                <option value=""></option>
                                {locations?.map(({ id, name }) => (
                                  <option key={id} value={name}>
                                    {name}
                                  </option>
                                ))}
                              </Field>
                              {errors.locationCode && touched.locationCode ? (
                                <div className="invalid-feedback">
                                  {errors.locationCode}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Name
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                type="text"
                                name="name"
                                className={`form-control m-b-5  ${
                                  errors.name && touched.name
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Enter Name"
                              />
                              {errors.name && touched.name ? (
                                <div className="invalid-feedback">
                                  {errors.name}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Description
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                type="text"
                                name="desciption"
                                className={`form-control m-b-5  ${
                                  errors.desciption && touched.desciption
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Enter Description"
                              />
                              {errors.desciption && touched.desciption ? (
                                <div className="invalid-feedback">
                                  {errors.desciption}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          {!isNew && (
                            <>
                              <div className="form-group row m-b-15">
                                <label className="col-form-label col-md-3">
                                  Mask
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="col-md-9">
                                  <Field
                                    type="text"
                                    name="mask"
                                    readOnly
                                    className={`form-control m-b-5  ${
                                      errors.mask && touched.mask
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    placeholder="Enter Description"
                                  />
                                  {errors.mask && touched.mask ? (
                                    <div className="invalid-feedback">
                                      {errors.mask}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="form-group row m-b-15">
                                <label className="col-form-label col-md-3">
                                  Subnet Size
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="col-md-9">
                                  <Field
                                    type="text"
                                    name="subnet"
                                    readOnly
                                    className={`form-control m-b-5  ${
                                      errors.subnet && touched.subnet
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    placeholder="Enter Description"
                                  />
                                  {errors.subnet && touched.subnet ? (
                                    <div className="invalid-feedback">
                                      {errors.subnet}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="form-group row m-b-15">
                                <label className="col-form-label col-md-3">
                                  First IP
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="col-md-9">
                                  <Field
                                    type="text"
                                    name="firstIP"
                                    readOnly
                                    className={`form-control m-b-5  ${
                                      errors.firstIP && touched.firstIP
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    placeholder="Enter Fast IP"
                                  />
                                  {errors.firstIP && touched.firstIP ? (
                                    <div className="invalid-feedback">
                                      {errors.firstIP}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="form-group row m-b-15">
                                <label className="col-form-label col-md-3">
                                  Last IP
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="col-md-9">
                                  <Field
                                    type="text"
                                    name="lastIP"
                                    readOnly
                                    className={`form-control m-b-5  ${
                                      errors.lastIP && touched.lastIP
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    placeholder="Enter Last IP"
                                  />
                                  {errors.lastIP && touched.lastIP ? (
                                    <div className="invalid-feedback">
                                      {errors.lastIP}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="form-group row m-b-15">
                                <label className="col-form-label col-md-3">
                                  Broadcast
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="col-md-9">
                                  <Field
                                    type="text"
                                    name="broadcast"
                                    readOnly
                                    className={`form-control m-b-5  ${
                                      errors.broadcast && touched.broadcast
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    placeholder="Enter Last IP"
                                  />
                                  {errors.broadcast && touched.broadcast ? (
                                    <div className="invalid-feedback">
                                      {errors.broadcast}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </>
                          )}
                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Add Reserved IP or Range
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                type="text"
                                name="reservedIp"
                                className={`form-control m-b-5`}
                                placeholder="192.168.100.254 or 192.168.100.1:192.168.100.254"
                              />
                              <button
                                className="btn btn-primary mt-1"
                                onClick={(e) => {
                                  e.preventDefault();
                                  onAddRanges(values.reservedIp, setFieldValue);
                                }}
                              >
                                Add
                              </button>
                            </div>
                          </div>
                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Reserved IP Ranges
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                type="text"
                                as="select"
                                multiple
                                name="ranges"
                                className={`form-control m-b-5`}
                                placeholder="Select Range"
                              >
                                <option value=""></option>
                                {reservedIpRanges.map((range, i) => (
                                  <option key={i} value={range}>
                                    {range}
                                  </option>
                                ))}
                              </Field>
                            </div>
                          </div>
                        </>
                      );
                      break;
                    case "Allocation Rules":
                      ActiveForm = (
                        <>
                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Scope Name
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                type="text"
                                name="scopeName"
                                className={`form-control m-b-5  ${
                                  errors.scopeName && touched.scopeName
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Enter Scope Name"
                              />
                              {errors.scopeName && touched.scopeName ? (
                                <div className="invalid-feedback">
                                  {errors.scopeName}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Gen CIDR
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                type="number"
                                name="genCidr"
                                className={`form-control m-b-5  ${
                                  errors.genCidr && touched.genCidr
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Enter Gen CIDR"
                              />
                              {errors.genCidr && touched.genCidr ? (
                                <div className="invalid-feedback">
                                  {errors.genCidr}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              NTU Allocation
                              <span className="text-danger"></span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                as="select"
                                name="ntuAllocation"
                                className={`form-control m-b-5  ${
                                  errors.ntuAllocation && touched.ntuAllocation
                                    ? "is-invalid"
                                    : ""
                                }`}
                              >
                                {["FIRST", "LAST", "RANDOM", "NONE"].map(
                                  (state, i) => (
                                    <option key={i} value={state}>
                                      {state}
                                    </option>
                                  )
                                )}
                              </Field>
                              {errors.ntuAllocation && touched.ntuAllocation ? (
                                <div className="invalid-feedback">
                                  {errors.ntuAllocation}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              PE Allocation
                              <span className="text-danger"></span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                as="select"
                                name="peAllocation"
                                className={`form-control m-b-5  ${
                                  errors.pe && touched.pe ? "is-invalid" : ""
                                }`}
                              >
                                {["FIRST", "LAST", "RANDOM", "NONE"].map(
                                  (state, i) => (
                                    <option key={i} value={state}>
                                      {state}
                                    </option>
                                  )
                                )}
                              </Field>
                              {errors.peAllocation && touched.peAllocation ? (
                                <div className="invalid-feedback">
                                  {errors.peAllocation}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </>
                      );
                      break;
                    default:
                      break;
                  }
                  return (
                    <Form>
                      {ActiveForm}

                      <button
                        type="submit"
                        className="btn btn-primary btn-block btn-lg m-b-5"
                        disabled={!isValid || isSubmitted}
                      >
                        Submit
                      </button>
                    </Form>
                  );
                }}
              </Formik>
            </Panel>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubnetConfigurationUpdate;
