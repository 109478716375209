import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Formik, Form, Field, yupToFormErrors } from 'formik';
import * as Yup from 'yup';
import Panel from '../../../common/Panel/Panel';

import {
  getEmailTemplate,
  getEmailSetups,
  createEmailTemplate,
  updateEmailTemplate,
} from './api/emailTemplatesApi';

const EmailTemplateUpdate = () => {
  const match = useParams();
  const navigate = useNavigate();
  const [id, setId] = useState(match.id);
  const [tenantTilesData, setTenantsTilesData] = useState(null);
  const [initialData, setInitialData] = useState({
    tenant: 'Acurix Test',
    templateName: '',
    useDefault: true,
    testEmail: 'info@activeport.com.au',
    emailSetup: '',
    emailTemplate: '',
  });

  const [emailSetupData, setEmailSetupData] = useState(null);
  const [appState, setAppState] = useState({
    loading: false,
  });
  const [isNew, setIsNew] = useState(!match.params || !match.id);
  const schema = Yup.object().shape({
    templateName: Yup.string(),
    testEmail: Yup.string().email(),
    emailSetup: Yup.string(),
  });

  async function submitNewEmailTemplate(emailTemplate) {
    const response = await createEmailTemplate(emailTemplate).then((res) =>
      res.json()
    );
    if (response.id) {
      navigate({
        pathname: '/administration/email-templates',
        state: { id: response.id, action: 'Created' },
      });
    }
  }

  async function submitUpdateEmailTemplate(emailTemplate) {
    const response = await updateEmailTemplate(emailTemplate).then((res) =>
      res.json()
    );
    if (response.id) {
      navigate({
        pathname: '/administration/email-templates',
        state: { id: response.id, action: 'Updated' },
      });
    }
  }

  useEffect(() => {
    if (!isNew) {
      setAppState({ loading: true });
      setId(match.id);
      const fetchData = async () => {
        const emailTemplateResult = await getEmailTemplate(match.id).then(
          (res) => res.json()
        );

        setInitialData({
          id: emailTemplateResult.id,
          tenant: 'Acurix Test',
          templateName: emailTemplateResult.templateName,
          emailSetup: '',
          useDefault: emailTemplateResult.useDefault,
          testEmail: 'info@activeport.com.au',
          emailTemplate: emailTemplateResult.template,
        });
      };
      fetchData();
      setAppState({ loading: false });
    } else {
      setAppState({ loading: true });
      const fetchEmailTemplate = async () => {
        const emailTemplate = await getEmailTemplate('19');
        setInitialData((prev) => ({
          ...prev,
          emailTemplate: emailTemplate.template,
        }));
      };
      fetchEmailTemplate();
      setAppState({ loading: false });
    }
  }, []);

  useEffect(() => {
    const fetcher = async () => {
      const emailSetupResult = await getEmailSetups().then((res) => res.json());
      setEmailSetupData(emailSetupResult);
    };
    fetcher();
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-xl-9">
          {appState.loading && <p>Loading...</p>}
          {!appState.loading && (
            <Panel heading={initialData.name}>
              <Formik
                enableReinitialize
                initialValues={initialData}
                validationSchema={schema}
                validateOnMount
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  isNew
                    ? submitNewEmailTemplate(values)
                    : submitUpdateEmailTemplate(values);
                }}
              >
                {({ errors, touched, isValid, setFieldValue, values }) => {
                  return (
                    <Form>
                      {!isNew && (
                        <div className="form-group row m-b-15">
                          <label className="col-form-label col-md-3">
                            ID <span className="text-danger">*</span>
                          </label>
                          <div className="col-md-9">
                            <Field
                              type="text"
                              name="id"
                              label="id"
                              readOnly
                              className={`form-control m-b-5  ${
                                errors.id && touched.id ? 'is-invalid' : ''
                              }`}
                              placeholder="Enter Id"
                            />
                            {errors.id && touched.id ? (
                              <div className="invalid-feedback">
                                {errors.id}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      )}

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Tenant <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="tenant"
                            label="tenant"
                            readOnly
                            className={`form-control m-b-5  ${
                              errors.tenant && touched.tenant
                                ? 'is-invalid'
                                : ''
                            }`}
                          />
                          {errors.tenant && touched.tenant ? (
                            <div className="invalid-feedback">
                              {errors.tenant}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Template Name <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="templateName"
                            label="templateName"
                            className={`form-control m-b-5  ${
                              errors.templateName && touched.templateName
                                ? 'is-invalid'
                                : ''
                            }`}
                          />
                          {errors.templateName && touched.templateName ? (
                            <div className="invalid-feedback">
                              {errors.templateName}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Use Default Template{' '}
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="checkbox"
                            name="useDefault"
                            className={`m-b-5 mt-2  ${
                              errors.useDefault && touched.useDefault
                                ? 'is-invalid'
                                : ''
                            }`}
                          />
                          {errors.useDefault && touched.useDefault ? (
                            <div className="invalid-feedback">
                              {errors.useDefault}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {!values.useDefault && (
                        <div className="form-group row m-b-15">
                          <label className="col-form-label col-md-3">
                            Email Template
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-md-9">
                            <div
                              style={{ width: '400px' }}
                              contenteditable="true"
                              dangerouslySetInnerHTML={{
                                __html: values.emailTemplate,
                              }}
                            />
                            {errors.emailTemplate && touched.emailTemplate ? (
                              <div className="invalid-feedback">
                                {errors.emailTemplate}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      )}
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Send Test Email To{' '}
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="testEmail"
                            label="testEmail"
                            className={`form-control m-b-5  ${
                              errors.testEmail && touched.testEmail
                                ? 'is-invalid'
                                : ''
                            }`}
                          />
                          {errors.testEmail && touched.testEmail ? (
                            <div className="invalid-feedback">
                              {errors.testEmail}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Email Setup
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            as="select"
                            name="emailSetup"
                            className={`form-control m-b-5  ${
                              errors.emailSetup && touched.emailSetup
                                ? 'is-invalid'
                                : ''
                            }`}
                          >
                            <option value=""></option>
                            {emailSetupData?.map((item) => (
                              <option key={item.id}>
                                {item.emailSetupName}
                              </option>
                            ))}
                          </Field>
                          {errors.emailSetup && touched.emailSetup ? (
                            <div className="invalid-feedback">
                              {errors.emailSetup}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <button
                        type="submit"
                        className="btn  btn-primary btn-block btn-lg m-b-5"
                        disabled={!isValid}
                      >
                        Submit
                      </button>
                    </Form>
                  );
                }}
              </Formik>
            </Panel>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmailTemplateUpdate;
