import React, { useState, useContext, useEffect } from 'react';
import './menu.scss';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AuthContext from '../../store/auth-context';

const MenuItem = ({ item, itemActive, setActiveItem }) => {
  const [subMenuItem, setsubMenuItem] = useState(
    localStorage.getItem('subMenuItem')
  );
  const { t } = useTranslation(['translation', 'global']);
  const authCtx = useContext(AuthContext);

  const getAuthorizeItem = (items) => {
    return items.find(
      (itm) => authCtx.hasAuth(itm.hasAuth) && itm.conditions()
    );
  };

  const addMenuItemToLocalStorage = (localItem, subMnuItm) => {
    console.log(subMnuItm);
    if (subMnuItm) {
      setsubMenuItem(subMnuItm.title);
    }
    setActiveItem(localItem);
  };

  const addSubMenuItemToLocalStoage = (subMenuItem) => {
    localStorage.setItem('subMenuItem', subMenuItem);
    setsubMenuItem(subMenuItem);
  };

  useEffect(() => {
    if (item.subMenu) {
      const authorizedSubMenuItem = getAuthorizeItem(item.subMenu);
      addSubMenuItemToLocalStoage(authorizedSubMenuItem?.title);
    }
  }, []);

  return (
    <>
      <Link
        to={item.link}
        title={item.title}
        data-filter-tags="application intel"
        onClick={() =>
          addMenuItemToLocalStorage(
            item.title,
            item.subMenu && getAuthorizeItem(item.subMenu)
          )
        }
        className={`
                ${authCtx.hasAuth(item.hasAuth) ? '' : 'd-none'} ${
          item.conditions() ? '' : 'd-none'
        }`}
      >
        <i className={`fal ${item.icon}`}></i>
        <span className="nav-link-text" data-i18n="nav.application_intel">
          {t(`global:menu.${item.key}`, item.title)}
        </span>
        {item.subMenu && (
          <b className="collapse-sign">
            <em
              className={` ${
                itemActive ? 'fal fa-angle-up' : 'fal fa-angle-down'
              }`}
            ></em>
          </b>
        )}
      </Link>

      {item.subMenu && itemActive && (
        <ul className="submenu">
          {item.subMenu.map((menuItem) => (
            <li
              key={menuItem.key}
              className={`${subMenuItem == menuItem.title ? 'active' : ''}
                ${authCtx.hasAuth(menuItem.hasAuth) ? '' : 'd-none'} ${
                menuItem.conditions() ? '' : 'd-none'
              }`}
            >
              <Link
                to={menuItem.link}
                title={menuItem.title}
                data-filter-tags="application intel analytics dashboard"
                onClick={() => addSubMenuItemToLocalStoage(menuItem.title)}
              >
                <span
                  className="nav-link-text"
                  data-i18n="nav.application_intel_analytics_dashboard"
                >
                  {t(`global:menu.${menuItem.key}`, menuItem.title)}
                </span>
              </Link>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default MenuItem;
