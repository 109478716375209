import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { SettingsContextProvider } from './store/settings-context';

import App from './App';
import { AuthContextProvider } from './store/auth-context';
import './scss/bootstrap.scss';
import './scss/app.icons.scss';
import './scss/theme-demo.scss';
import './scss/app.core.scss';
import './scss/_skins/skin-master.scss';
import './utils/i18n';

ReactDOM.render(
  <AuthContextProvider>
    <SettingsContextProvider>
      <Suspense fallback={null}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Suspense>
    </SettingsContextProvider>
  </AuthContextProvider>,
  document.getElementById('root')
);
