import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Formik, Form, Field, yupToFormErrors } from 'formik';
import * as Yup from 'yup';
import Panel from '../../../common/Panel/Panel';
import DatePicker from 'react-datepicker';

import {
  createBillingSystem,
  getBillingSystem,
  updateBillingSystem,
} from './api/billingSystemApi';

const TenantUpdate = () => {
  const match = useParams();
  const navigate = useNavigate();
  const [id, setId] = useState(match.id);

  const [initialData, setInitialData] = useState({
    name: '',
    description: '',
    api: '',
    stage: '',
    username: '',
    secret: '',
    privateKeyCert: '',
    privateKeyPassword: '',
    billingUid: '',
    startBilling: new Date(),
    currencyCode: '',
  });
  const [appState, setAppState] = useState({
    loading: false,
  });
  const [isNew, setIsNew] = useState(!match.params || !match.id);

  const schema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Too Short!')
      .max(250, 'Too Long!')
      .required('Required'),
    description: Yup.string()
      .min(2, 'Too Short!')
      .max(250, 'Too Long!')
      .required('Required'),
    api: Yup.string()
      .min(2, 'Too Short!')
      .max(250, 'Too Long!')
      .required('Required'),
    stage: Yup.string()
      .min(2, 'Too Short!')
      .max(250, 'Too Long!')
      .required('Required'),
    username: Yup.string()
      .min(2, 'Too Short!')
      .max(250, 'Too Long!')
      .required('Required'),
    secret: Yup.string()
      .min(2, 'Too Short!')
      .max(250, 'Too Long!')
      .required('Required'),
    privateKeyPassword: Yup.string()
      .min(2, 'Too Short!')
      .max(250, 'Too Long!')
      .required('Required'),
    startBilling: Yup.date().required('Required'),
    currencyCode: Yup.string()
      .min(2, 'Too Short!')
      .max(250, 'Too Long!')
      .required('Required'),
  });

  async function submitNewBillingSystem(billingSystem) {
    const response = await createBillingSystem(billingSystem).then((res) =>
      res.json()
    );
    if (response.id) {
      navigate({
        pathname: '/settings/service-command',
        state: { id: response.id, action: 'Created' },
      });
    }
  }

  async function updateBillingSystems(billingSystem) {
    const response = await updateBillingSystem(billingSystem).then((res) =>
      res.json()
    );
    if (response.id) {
      navigate({
        pathname: '/settings/service-command',
        state: { id: response.id, action: 'Updated' },
      });
    }
  }

  useEffect(() => {
    if (!isNew) {
      setAppState({ loading: true });
      setId(match.id);
      const fetchData = async () => {
        const result = await getBillingSystem(match.id).then((res) =>
          res.json()
        );
        setInitialData(result);
      };
      fetchData();
      setAppState({ loading: false });
    }
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-xl-9">
          {appState.loading && <p>Loading...</p>}
          {!appState.loading && (
            <Panel heading={initialData.name}>
              <Formik
                enableReinitialize
                initialValues={initialData}
                validationSchema={schema}
                validateOnMount
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  isNew
                    ? submitNewBillingSystem(values)
                    : updateBillingSystem(values);
                }}
              >
                {({ errors, touched, isValid, values, setFieldValue }) => {
                  return (
                    <Form>
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Name <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="name"
                            label="name"
                            className={`form-control m-b-5  ${
                              errors.name && touched.name ? 'is-invalid' : ''
                            }`}
                            placeholder="Enter Billing System Name"
                          />
                          {errors.name && touched.name ? (
                            <div className="invalid-feedback">
                              {errors.name}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Description <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="textarea"
                            as="textarea"
                            name="description"
                            label="description"
                            className={`form-control m-b-5  ${
                              errors.description && touched.description
                                ? 'is-invalid'
                                : ''
                            }`}
                            placeholder="Enter Description"
                          />
                          {errors.description && touched.description ? (
                            <div className="invalid-feedback">
                              {errors.description}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Api <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="api"
                            label="api"
                            as="select"
                            className={`form-control m-b-5  ${
                              errors.api && touched.api ? 'is-invalid' : ''
                            }`}
                          >
                            {['XERO', ' HOSTBILL', 'LOCAL'].map((apiOpt) => (
                              <option key={apiOpt} value={apiOpt}>
                                {apiOpt}
                              </option>
                            ))}
                          </Field>
                          {errors.api && touched.api ? (
                            <div className="invalid-feedback">{errors.api}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Stage <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="stage"
                            label="stage"
                            className={`form-control m-b-5  ${
                              errors.stage && touched.stage ? 'is-invalid' : ''
                            }`}
                            placeholder="Enter Stage"
                          />
                          {errors.stage && touched.stage ? (
                            <div className="invalid-feedback">
                              {errors.stage}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Username <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="username"
                            label="username"
                            className={`form-control m-b-5  ${
                              errors.stage && touched.stage ? 'is-invalid' : ''
                            }`}
                            placeholder="Enter Username"
                          />
                          {errors.username && touched.username ? (
                            <div className="invalid-feedback">
                              {errors.username}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Secret <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="secret"
                            label="secret"
                            className={`form-control m-b-5  ${
                              errors.stage && touched.stage ? 'is-invalid' : ''
                            }`}
                            placeholder="Enter Secret"
                          />
                          {errors.secret && touched.secret ? (
                            <div className="invalid-feedback">
                              {errors.secret}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Private Key Password
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="privateKeyPassword"
                            label="privateKeyPassword"
                            className={`form-control m-b-5  ${
                              errors.privateKeyPassword &&
                              touched.privateKeyPassword
                                ? 'is-invalid'
                                : ''
                            }`}
                            placeholder="Enter Private Key Password"
                          />
                          {errors.privateKeyPassword &&
                          touched.privateKeyPassword ? (
                            <div className="invalid-feedback">
                              {errors.privateKeyPassword}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Billing Day<span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <div className="customDatePickerWidth">
                            <DatePicker
                              selected={new Date(values.startBilling)}
                              dateFormat="d/MM/yy hh:mm"
                              className={`form-control m-b-5  ${
                                errors.startBilling && touched.startBilling
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              name="startBilling"
                              onChange={(date) =>
                                setFieldValue('startBilling', date)
                              }
                            />
                          </div>
                          {errors.startBilling && touched.startBilling ? (
                            <div className="invalid-feedback">
                              {errors.startBilling}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Currency Code <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="currencyCode"
                            label="currencyCode"
                            className={`form-control m-b-5  ${
                              errors.currencyCode && touched.currencyCode
                                ? 'is-invalid'
                                : ''
                            }`}
                            placeholder="Enter Currency Code"
                          />
                          {errors.currencyCode && touched.currencyCode ? (
                            <div className="invalid-feedback">
                              {errors.currencyCode}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="btn  btn-primary btn-block btn-lg m-b-5"
                        disabled={!isValid}
                      >
                        Submit
                      </button>
                    </Form>
                  );
                }}
              </Formik>
            </Panel>
          )}
        </div>
      </div>
    </div>
  );
};

export default TenantUpdate;
