import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";

import Panel from "../../../common/Panel/Panel";
import Alert from "../../../common/AlertControls/Alert";
import { handleCreateNewAlert } from "../../../common/Alerts/CreateNewAlert";
import { MyTextArea } from "../../../common/Form/FormFields";
import { createApiTemplate } from "./api/apiTemplatesApi";

const ApiTemplateUpdate = () => {
  const navigate = useNavigate();

  let defaultValues = {
    id: "",
    name: "",
    uid: "",
    xml: "",
    type: "",
  };

  const [alertShow, setAlertShow] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  // let alertShow = false;

  async function createNewApiTemplate(apiTemplate) {
    const response = await createApiTemplate(apiTemplate).then((res) =>
      res.json()
    );
    if (response.id) {
      handleCreateNewAlert(
        'New apiTemplate "' + apiTemplate.name + '" added successfully!'
      );
      navigate("../");
    }
  }

  function submitForm(formValues) {
    createNewApiTemplate(formValues);
  }

  return (
    <div>
      <div className="row">
        <div className="col-xl-12">
          {alertShow && (
            <Alert
              alertType="alert-success"
              msg="The apiTemplate has been updated."
            />
          )}

          <Panel>
            <Formik
              enableReinitialize
              initialValues={defaultValues}
              validateOnMount
              onSubmit={submitForm}
            >
              {({ errors, touched, isValid, setFieldValue, values }) => {
                return (
                  <Form>
                    <div className="form-group row m-b-15">
                      <label className="col-form-label col-md-3">
                        Name <span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9">
                        <Field
                          type="text"
                          name="name"
                          className={`form-control m-b-5  ${
                            errors.name && touched.name ? "is-invalid" : ""
                          }`}
                          placeholder="Enter Name"
                        />
                        {errors.name && touched.name ? (
                          <div className="invalid-feedback">{errors.name}</div>
                        ) : null}
                      </div>
                    </div>

                    <div className="form-group row m-b-15">
                      <label className="col-form-label col-md-3">
                        UID <span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9">
                        <Field
                          type="text"
                          name="uid"
                          className={`form-control m-b-5  ${
                            errors.uid && touched.uid ? "is-invalid" : ""
                          }`}
                          placeholder="Enter UID"
                        />
                        {errors.uid && touched.uid ? (
                          <div className="invalid-feedback">{errors.uid}</div>
                        ) : null}
                      </div>
                    </div>

                    <MyTextArea
                      label="XML"
                      name="xml"
                      rows="30"
                      placeholder="Enter XML"
                    />
                    <div className="form-group row m-b-15">
                      <label className="col-form-label col-md-3">
                        Type <span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9">
                        <Field
                          type="text"
                          name="type"
                          className={`form-control m-b-5  ${
                            errors.type && touched.type ? "is-invalid" : ""
                          }`}
                          placeholder="Enter Type"
                        />
                        {errors.type && touched.type ? (
                          <div className="invalid-feedback">{errors.type}</div>
                        ) : null}
                      </div>
                    </div>

                    <button
                      type="submit"
                      className="btn btn-primary btn-block btn-lg m-b-5"
                      disabled={!isValid || isSubmitted}
                    >
                      Submit
                    </button>
                  </Form>
                );
              }}
            </Formik>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default ApiTemplateUpdate;
