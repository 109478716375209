export const getApiTemplates = () => {
  const token = localStorage.getItem("token");
  const baseUrl =
    "https://test.activeport.com.au/gaming-api/vm-commands?size=99999999";

  return fetch(baseUrl, {
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getApiTemplate = (id) => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/gaming-api/vm-commands/";

  const requestUrl = `${baseUrl}${id}`;
  return fetch(requestUrl, {
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const createApiTemplate = (apiTemplate) => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/gaming-api/vm-commands";

  return fetch(baseUrl, {
    method: "POST",
    body: JSON.stringify(apiTemplate),
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const updateApiTemplate = (apiTemplate) => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/gaming-api/vm-commands/";

  return fetch(baseUrl, {
    method: "PUT",
    body: JSON.stringify(apiTemplate),
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const deleteApiTemplate = (id) => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/gaming-api/vm-commands/";
  const requestUrl = `${baseUrl}${id}`;
  return fetch(requestUrl, {
    method: "DELETE",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};
