import React, { useState, useEffect } from "react";

import { ServicesBP1 } from "../ServicesBP1";
import { getConnections } from "../api/peeringExchangeApi";
import { AddPeeringExchangeServiceForm } from "./AddPeeringExchangeServiceForm";
import { ServiceSettingsFrom } from "./ServiceSettingsFrom";
import { ServiceStatus } from "./ServiceStatus/ServiceStatus";
import { NtuSettings } from "./NtuSettings/NtuSettings";

const connectionTabs = [
  {
    label: "Service Status ",
    value: "serviceStatus",
    title: "Activate a Peering Exchange Connection Service",
    text: "Displayed below is the current status of the service. Select the service to change the service status",
  },
  {
    label: "Ntu Settings",
    value: "ntuSettings",
    title: "Select the NTU port to which this service will be delivered",
    text: "This page configures ActivePort EDGE port setting. This is the physical port on the NTU.",
  },
  {
    label: "Service Settings",
    value: "serviceSettings",
    title: "Configure a Megaport IX Connection service",
    text: "This configures a private link from your ActivePort NTU to a Megaport IX location. Megaport allows only one peering connection to any location",
  },
];

const PeeringExchangeContainer = () => {
  const [showModal, setShowModal] = useState(false);
  const [connections, setConnections] = useState([]);
  const [activeConnectionTab, setActiveConnectionTab] = useState({
    value: "serviceStatus",
    title: "Activate a Peering Exchange Connection Service",
    text: "Displayed below is the current status of the service. Select the service to change the service status",
  });

  useEffect(() => {
    try {
      const fetcher = async () => {
        const connRes = await getConnections().then((res) => res.json());
        setConnections(connRes);
      };
      fetcher();
    } catch (error) {}
  }, []);

  return (
    <>
      <ServicesBP1
        title="Peering Exchange Services"
        subTitle="Configure a Peering Exchange Service"
        addBtnText="Add Peering Exchange Service"
        showModal={showModal}
        setShowModal={setShowModal}
        serviceLogoUrl="/assets/img/ntu/PEERING-EXCHANGE-TILE.png"
      >
        <div className="p-2">
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <p className="nav-link active">Peering-Exchange-Route-Circuit</p>
            </li>
          </ul>
          <div className="border border-top-0 px-3 pt-2">
            <h6 className="font-weight-bold">Peering Exchange Connection</h6>
            <div className="mt-5">
              <ul className="nav nav-tabs">
                {connectionTabs.map(({ value, label, title, text }) => (
                  <li
                    key={value}
                    className="nav-item"
                    onClick={() =>
                      setActiveConnectionTab({
                        value,
                        title,
                        text,
                      })
                    }
                  >
                    <p
                      className={`nav-link ${
                        activeConnectionTab.value === value ? "active" : ""
                      }`}
                    >
                      {label}
                    </p>
                  </li>
                ))}
              </ul>

              <div className="border border-top-0 px-3">
                <div className="pt-3">
                  <h6 className="font-weight-bold">
                    {activeConnectionTab.title}
                  </h6>
                  <p>{activeConnectionTab.text}</p>
                </div>
              </div>
            </div>

            <div className="my-5">
              {activeConnectionTab.value === "serviceStatus" && (
                <ServiceStatus connections={connections} />
              )}
              {activeConnectionTab.value === "ntuSettings" && (
                <NtuSettings connections={connections} />
              )}
              {activeConnectionTab.value === "serviceSettings" && (
                <ServiceSettingsFrom connections={connections} />
              )}
            </div>
          </div>
        </div>
      </ServicesBP1>
      <AddPeeringExchangeServiceForm
        show={showModal}
        setShow={setShowModal}
        //   onSave={onSave}
      />
    </>
  );
};

export default PeeringExchangeContainer;
