import React, { useState } from 'react';
import S1 from '../../assets/images/demo/s-1.png';
const Footer = () => {
  const [stylesheet, setStylesheet] = useState('');

  return (
    <footer className="page-footer" role="contentinfo">
      <div className="d-flex align-items-center flex-1 text-muted">
        <span className="hidden-md-down fw-700">
          2021 © ActivePortal by&nbsp;
          <a
            href="https://www.activeport.com.au"
            className="text-primary fw-500"
            title="activeport.com.au"
            target="_blank"
          >
            Activeport
          </a>
        </span>
      </div>
      <div>
        <ul className="list-table m-0">
          <li>
            <a href="#" className="text-secondary fw-700">
              About
            </a>
          </li>
          <li className="pl-3">
            <a href="#" className="text-secondary fw-700">
              License
            </a>
          </li>
          <li className="pl-3">
            <a href="#" className="text-secondary fw-700">
              Documentation
            </a>
          </li>
          <li className="pl-3 fs-xl">
            <a href="#e" className="text-secondary" target="_blank">
              <i className="fal fa-question-circle" aria-hidden="true" />
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
