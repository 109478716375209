import { useState, useContext } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import './authform.css';
import AuthContext from '../../store/auth-context';
import { i18n } from '../../utils/i18n';
import { useTranslation } from 'react-i18next';

const AuthForm = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const [locale, setLocale] = useState('en');
  const { t } = useTranslation(['global', 'login']);

  const [currentLocale, setCurrentLocale] = useState(
    localStorage.getItem('i18nextLng')
  );
  const handleOnclick = (e) => {
    setCurrentLocale(e.target.value);
    i18n.changeLanguage(e.target.value);
  };

  const [isLogin, setIsLogin] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [initialData, setInitialData] = useState({
    username: '',
    password: '',
    rememberme: false,
    language: currentLocale,
  });

  const schema = Yup.object().shape({
    username: Yup.string()
      .min(2, 'Too Short!')
      .max(250, 'Too Long!')
      .required('You missed this.'),
    password: Yup.string()
      .min(2, 'Too Short!')
      .max(250, 'Too Long!')
      .required('This is required.'),
  });

  const authenticateUser = async (values) => {
    let url = 'https://test.activeport.com.au/api/authenticate';

    await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        username: values.username,
        password: values.password,
        domain: 'ACTIVEPORT',
        tenantId: null,
        rememberMe: true,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          return res.json().then((data) => {
            let errorMessage = 'Authentication failed!';
            // if (data && data.error && data.error.message) {
            //   errorMessage = data.error.message;
            // }

            throw new Error(errorMessage);
          });
        }
      })
      .then((data) => {
        authCtx.login(data.id_token, data.expire);
        console.log('logged in');
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setAuthError(true);
        setIsLoading(false);
      });
  };

  const submitHandler = (values) => {
    setIsLoading(true);

    authenticateUser(values);
  };
  if (authCtx.isLoggedIn) return navigate('/');
  return (
    <Formik
      initialValues={initialData}
      validationSchema={schema}
      enableReinitialize={true}
      validateOnBlur={true}
      validateOnMount
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        submitHandler(values);
      }}
    >
      {({ errors, touched, isValid }) => {
        return (
          <Form className={`${authError ? 'was-validated' : ''}`}>
            <div className="form-group">
              <label className="form-label">
                {t('global:form.username', 'Username')}
              </label>
              <Field
                required
                type="text"
                name="username"
                id="username"
                className="form-control "
                placeholder={t('login:youridoremail', 'Your id or email')}
              />
              {errors.username && touched.username ? (
                <div className="invalid-feedback">{errors.username}</div>
              ) : null}

              <div className="help-block">
                {t('login:yourusername', 'Your unique username to app')}{' '}
              </div>
            </div>
            <div className="form-group">
              <label className="form-label">
                {t('global:form.password', 'Password')}
              </label>
              <Field
                required
                type="password"
                id="password"
                name="password"
                className="form-control "
                placeholder={t('login:yourpassword', 'your password')}
              />
              {errors.password && touched.password ? (
                <div className="invalid-feedback">{errors.password}</div>
              ) : null}
              <div className="help-block">
                {' '}
                {t('login:yourpassword', 'Your password')}
              </div>
            </div>
            <div className="form-group">
              <label className="form-label">
                {t('global:form.language', 'Language')}
              </label>
              <Field
                as="select"
                id="language"
                name="language"
                className="form-control "
                placeholder="password"
                onChange={handleOnclick}
                value={currentLocale}
              >
                <option value="en">English (default)</option>
                <option value="es">Español</option>
                <option value="fr">Français</option>
                <option value="ja">日本語</option>
              </Field>

              <div className="help-block">
                {t('login:preferredlang', 'Select your preferred language')}
              </div>
            </div>
            <div className="form-group text-left">
              <div className="custom-control custom-checkbox">
                <Field
                  type="checkbox"
                  name="rememberme"
                  className="custom-control-input"
                  id="rememberme"
                />
                <label className="custom-control-label">
                  {t('login:rememberme', 'Remember me for the next 30 days')}
                </label>
              </div>
            </div>
            {authError && (
              <div className="form-group">
                <div className="auth-error">
                  Sorry, wrong username or password
                </div>
              </div>
            )}
            <div className="row no-gutters">
              <div className="col-lg-4 pl-lg-1 my-2">
                {!isLoading && (
                  <button
                    type="submit"
                    className="btn btn-primary btn-block"
                    disabled={!isValid}
                  >
                    {t('global:form.login', 'Login')}
                  </button>
                )}

                {isLoading && !authError && (
                  <div>
                    <button className="btn btn-primary btn-block" disabled>
                      <span className="fas fa-spinner fa-spin"></span>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AuthForm;
