import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Formik, Form, Field, yupToFormErrors } from 'formik';
import * as Yup from 'yup';
import Panel from '../../../common/Panel/Panel';

import {
  createEmailAddress,
  getEmailAddress,
  updateEmailAddress,
} from './api/emailAddressesApi';

const EmailAddressUpdate = () => {
  const match = useParams();
  const navigate = useNavigate();
  const [id, setId] = useState(match.id);
  const [tenantTilesData, setTenantsTilesData] = useState(null);
  const [initialData, setInitialData] = useState({
    id: '',
    tenant: 'Acurix Test',
    emailAddress: '',
  });
  const [appState, setAppState] = useState({
    loading: false,
  });
  const [isNew, setIsNew] = useState(!match.params || !match.id);

  const schema = Yup.object().shape({
    emailAddress: Yup.string().email().required('Required'),
  });

  async function submitNewEmailAddress(emailAddress) {
    const emailAddressDetails = {
      tenant: emailAddress.tenant,
      emailAddress: emailAddress.emailAddress,
    };
    const response = await createEmailAddress(emailAddressDetails).then((res) =>
      res.json()
    );
    if (response.id) {
      navigate({
        pathname: '/administration/email-addresses',
        state: { id: response.id, action: 'Created' },
      });
    }
  }

  async function submitUpdateEmailAddress(emailAddress) {
    const response = await updateEmailAddress(emailAddress).then((res) =>
      res.json()
    );
    if (response.id) {
      navigate({
        pathname: '/administration/email-addresses',
        state: { id: response.id, action: 'Updated' },
      });
    }
  }

  useEffect(() => {
    if (!isNew) {
      setAppState({ loading: true });
      setId(match.id);
      const fetchData = async () => {
        const result = await getEmailAddress(match.id).then((res) =>
          res.json()
        );
        setInitialData((prev) => ({
          ...prev,
          ...result,
        }));
      };
      fetchData();
      setAppState({ loading: false });
    }
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-xl-9">
          {appState.loading && <p>Loading...</p>}
          {!appState.loading && (
            <Panel heading={initialData.name}>
              <Formik
                enableReinitialize
                initialValues={initialData}
                validationSchema={schema}
                validateOnMount
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  isNew
                    ? submitNewEmailAddress(values)
                    : submitUpdateEmailAddress(values);
                }}
              >
                {({ errors, touched, isValid, setFieldValue, values }) => {
                  return (
                    <Form>
                      {!isNew && (
                        <div className="form-group row m-b-15">
                          <label className="col-form-label col-md-3">
                            ID <span className="text-danger">*</span>
                          </label>
                          <div className="col-md-9">
                            <Field
                              type="text"
                              name="id"
                              label="id"
                              readOnly
                              className={`form-control m-b-5  ${
                                errors.id && touched.id ? 'is-invalid' : ''
                              }`}
                              placeholder="Enter Id"
                            />
                            {errors.id && touched.id ? (
                              <div className="invalid-feedback">
                                {errors.id}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      )}
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Tenant <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="tenant"
                            label="tenant"
                            readOnly
                            className={`form-control m-b-5  ${
                              errors.tenant && touched.tenant
                                ? 'is-invalid'
                                : ''
                            }`}
                            placeholder="Enter Description"
                          />
                          {errors.tenant && touched.tenant ? (
                            <div className="invalid-feedback">
                              {errors.tenant}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Email Address <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="emailAddress"
                            label="emailAddress"
                            className={`form-control m-b-5  ${
                              errors.emailAddress && touched.emailAddress
                                ? 'is-invalid'
                                : ''
                            }`}
                            placeholder="Enter Email Address"
                          />
                          {errors.emailAddress && touched.emailAddress ? (
                            <div className="invalid-feedback">
                              {errors.emailAddress}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <button
                        type="submit"
                        className="btn btn-primary btn-block btn-lg m-b-5"
                        disabled={!isValid}
                      >
                        Submit
                      </button>
                    </Form>
                  );
                }}
              </Formik>
            </Panel>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmailAddressUpdate;
