import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import Panel from "../../../common/Panel/Panel";
import Table from "../../../common/Table";

import { handleDeleteAlert } from "../../../common/Alerts/DeleteAlert";
const Tenants = ({ entities }) => {
  const ntu = localStorage.getItem("ntu");
  const data = entities;

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "row_id",
        sortable: true,
        Cell: ({ row }) => {
          return (
            <div>
              <Link to={`${row.original.id}`}>{row.original.id}</Link>
            </div>
          );
        },
      },
      {
        Header: "Organization",
        accessor: "orgId",
        sortable: true,
      },
      {
        Header: "Subnet",
        accessor: "subnet",
        sortable: true,
      },
      {
        Header: "Name",
        accessor: "name",
        sortable: true,
      },
      {
        Header: "Description",
        accessor: "description",
        sortable: true,
      },
      {
        Header: "Mask",
        accessor: "mask",
        sortable: true,
      },
      {
        Header: "Broadcast",
        accessor: "lastIP",
        sortable: true,
      },
      {
        Header: "Reserved Ips",
        accessor: (d) =>
          d.ranges?.map((range, i) => (
            <button className="btn btn-light mr-1 mb-1" key={i}>
              {range}
            </button>
          )),
        sortable: true,
      },
      {
        Header: "Scope Name",
        accessor: "scopeName",
        sortable: true,
      },
      {
        Header: "Default Scope",
        accessor: "defaultScope",
        sortable: true,
      },
      {
        Header: "Gen CIDR",
        accessor: "genCidr",
        sortable: true,
      },
      {
        Header: "NTU Allocation",
        accessor: "peAllocation",
        sortable: true,
      },
      {
        Header: "PE Allocation",
        accessor: "ntuAllocation",
        sortable: true,
      },
      {
        Header: "Location",
        accessor: "locationCode",
        sortable: true,
      },
      {
        Header: "",
        accessor: "actionColumns",
        className: "align-middle",
        Cell: ({ row }) => {
          return (
            <div className="text-center d-flex">
              {ntu && (
                <>
                  <Link
                    className="btn btn-icon btn-circle btn-default mr-2"
                    to={`${row.original.id}`}
                  >
                    <i className="fal fa-eye"></i>
                  </Link>
                  <Link
                    className="btn btn-icon btn-circle btn-success mr-2"
                    to={`../edit/${row.original.id}`}
                  >
                    <i className="fal fa-pencil-alt"></i>
                  </Link>
                  <button
                    className="btn btn-icon btn-circle btn-danger"
                    onClick={handleDeleteAlert}
                  >
                    <i className="fal fa-trash"></i>
                  </button>
                </>
              )}
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <Panel>
      <Table data={data} columns={columns} />
    </Panel>
  );
};

export default Tenants;
