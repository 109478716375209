export const getBillingSystems = () => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/billing-systems';
  return fetch(baseUrl, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getBillingSystem = (id) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/billing-systems';
  const requestUrl = `${baseUrl}/${id}`;
  return fetch(requestUrl, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const createBillingSystem = (billingSystem) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/billing-systems';
  return fetch(baseUrl, {
    method: 'POST',
    body: JSON.stringify(billingSystem),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const updateBillingSystem = (billingSystem) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/billing-systems';
  return fetch(baseUrl, {
    method: 'PUT',
    body: JSON.stringify(billingSystem),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const deleteBillingSystem = (id) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/billing-systems';
  const requestUrl = `${baseUrl}/${id}`;
  return fetch(requestUrl, {
    method: 'DELETE',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};
