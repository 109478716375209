import InstanceType from '../components/Compute/InstanceTypes';

import ApiTemplates from '../components/Compute/ApiTemplates';

import Regions from '../components/Compute/Regions';
import GpuServer from '../components/Compute/GpuServer';

export const ComputeRoutes = [
  {
    element: <InstanceType />,
    path: '/compute/instance-type/*',
  },
  {
    element: <ApiTemplates />,
    path: '/compute/vm-command/*',
  },
  {
    element: <Regions />,
    path: '/compute/regions/*',
  },
  {
    element: <GpuServer />,
    path: '/compute/gpu-server/*',
  },
];
