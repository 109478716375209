import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";

import Panel from "../../../common/Panel/Panel";
import Alert from "../../../common/AlertControls/Alert";
import { handleCreateNewAlert } from "../../../common/Alerts/CreateNewAlert";
import { handleUpdateAlert } from "../../../common/Alerts/EditAlert";

import {
  createInstanceType,
  getInstanceType,
  updateInstanceType,
} from "./api/instanceTypesApi";

const InstanceTypeUpdate = () => {
  const match = useParams();
  const navigate = useNavigate();

  let defaultValues = {
    id: "",
    bandwidth: "",
    cores: "",
    gpu: "",
    maxMemory: "",
    memory: "",
    name: "",
    threads: "",
    unit: "",
    vcpu: "",
  };

  const [initialData, setInitialData] = useState({
    id: 1,
    bandwidth: 100,
    cores: 2,
    gpu: 1,
    maxMemory: 15258,
    memory: 8192,
    name: "acp.large",
    threads: 2,
    unit: "MiB",
    vcpu: 4,
  });
  const [appState, setAppState] = useState({
    loading: true,
  });

  const isNew = !match.id;
  const [alertShow, setAlertShow] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  // let alertShow = false;

  async function createNewInstanceType(instanceType) {
    const response = await createInstanceType(instanceType).then((res) =>
      res.json()
    );
    if (response.id) {
      handleCreateNewAlert(
        'New instanceType "' + instanceType.name + '" added successfully!'
      );
      navigate("../");
    }
  }

  async function updateInstanceTypes(instanceType) {
    const response = await updateInstanceType(instanceType).then((res) =>
      res.json()
    );
    if (response.id) {
      handleUpdateAlert("instanceType", response.id);
      navigate("../" + response.id);
    }
  }

  function submitForm(formValues) {
    isNew ? createNewInstanceType(formValues) : updateInstanceTypes(formValues);
  }

  // effect runs on component mount
  useEffect(() => {
    let unmounted = false;
    const fetchInstanceType = async () => {
      try {
        const result = await getInstanceType(match.id).then((res) =>
          res.json()
        );
        if (!unmounted) {
          console.log("result: ", result);
          if (result.description == null) result.description = "";
          setInitialData(result);
          //reset(result);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (!isNew) fetchInstanceType();
    setAppState({ loading: false });
    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-xl-12">
          {alertShow && (
            <Alert
              alertType="alert-success"
              msg="The instanceType has been updated."
            />
          )}

          {!appState.loading && initialData && (
            <Panel heading={initialData.name}>
              <Formik
                enableReinitialize
                initialValues={initialData}
                validateOnMount
                onSubmit={submitForm}
              >
                {({ errors, touched, isValid, setFieldValue, values }) => {
                  return (
                    <Form>
                      {!isNew && (
                        <div className="form-group row m-b-15">
                          <label className="col-form-label col-md-3">ID</label>
                          <div className="col-md-9">
                            <Field
                              type="text"
                              name="id"
                              label="id"
                              className="form-control m-b-5"
                              readOnly
                            />
                          </div>
                        </div>
                      )}
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Name <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="name"
                            className={`form-control m-b-5  ${
                              errors.name && touched.name ? "is-invalid" : ""
                            }`}
                            placeholder="Enter Name"
                          />
                          {errors.name && touched.name ? (
                            <div className="invalid-feedback">
                              {errors.name}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">Vcpu</label>
                        <div className="col-md-9">
                          <Field
                            type="number"
                            name="vcpu"
                            className={`form-control m-b-5  ${
                              errors.vcpu && touched.vcpu ? "is-invalid" : ""
                            }`}
                            placeholder="Enter Vcpu"
                          />
                          {errors.vcpu && touched.vcpu ? (
                            <div className="invalid-feedback">
                              {errors.vcpu}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Memory
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="number"
                            name="memory"
                            className={`form-control m-b-5  ${
                              errors.memory && touched.memory
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder="Enter Memory"
                          />
                          {errors.memory && touched.memory ? (
                            <div className="invalid-feedback">
                              {errors.memory}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Max Memory
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="number"
                            name="maxMemory"
                            className={`form-control m-b-5  ${
                              errors.maxMemory && touched.maxMemory
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder="Enter Max Memory"
                          />
                          {errors.maxMemory && touched.maxMemory ? (
                            <div className="invalid-feedback">
                              {errors.maxMemory}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Memory Unit
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="unit"
                            className="form-control m-b-5"
                          />
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">GPU</label>
                        <div className="col-md-9">
                          <Field
                            type="number"
                            name="gpu"
                            className={`form-control m-b-5  ${
                              errors.gpu && touched.gpu ? "is-invalid" : ""
                            }`}
                            placeholder="Enter GPU"
                          />
                          {errors.gpu && touched.gpu ? (
                            <div className="invalid-feedback">{errors.gpu}</div>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Bandwidth
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="number"
                            name="bandwidth"
                            className={`form-control m-b-5  ${
                              errors.bandwidth && touched.bandwidth
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder="Enter Bandwidth"
                          />
                          {errors.bandwidth && touched.bandwidth ? (
                            <div className="invalid-feedback">
                              {errors.bandwidth}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">Cores</label>
                        <div className="col-md-9">
                          <Field
                            type="number"
                            name="cores"
                            className={`form-control m-b-5  ${
                              errors.cores && touched.cores ? "is-invalid" : ""
                            }`}
                            placeholder="Enter Cores"
                          />
                          {errors.cores && touched.cores ? (
                            <div className="invalid-feedback">
                              {errors.cores}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Threads
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="number"
                            name="threads"
                            className={`form-control m-b-5  ${
                              errors.threads && touched.threads
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder="Enter Threads"
                          />
                          {errors.threads && touched.threads ? (
                            <div className="invalid-feedback">
                              {errors.threads}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <button
                        type="submit"
                        className="btn btn-primary btn-block btn-lg m-b-5"
                        disabled={!isValid || isSubmitted}
                      >
                        Submit
                      </button>
                    </Form>
                  );
                }}
              </Formik>
            </Panel>
          )}
        </div>
      </div>
    </div>
  );
};

export default InstanceTypeUpdate;
