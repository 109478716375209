import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import Panel from '../../../common/Panel/Panel';
import Table from '../../../common/Table';

import { handleDeleteAlert } from '../../../common/Alerts/DeleteAlert';
const ProviderConfig = ({ entities }) => {
  const data = entities;

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'row_id',
        sortable: true,
        Cell: ({ row }) => {
          return (
            <div>
              <Link to={`${row.original.tenantId}`}>{row.original.id}</Link>
            </div>
          );
        },
      },
      {
        Header: 'Organisation',
        accessor: 'orgName',
        sortable: true,
      },
      {
        Header: 'Name',
        accessor: 'name',
        sortable: true,
      },
      {
        Header: 'PE/Aggregator',
        accessor: 'centralSwitchName',
        sortable: true,
      },
      {
        Header: 'PE Port [CCC]',
        accessor: 'port',
        sortable: true,
      },
      {
        Header: 'PE Port [VLAN]',
        accessor: 'vlanPort',
        sortable: true,
      },
      {
        Header: 'VLANs Range',
        Header: 'Services',
        accessor: (d) => {
          return (
            <>
              {d.poolVlanStart} - {d.poolVlanEnd}
            </>
          );
        },
        sortable: true,
      },
      {
        Header: 'Location',
        accessor: 'locationCode',
        sortable: true,
      },
      {
        Header: 'Provider Port',
        accessor: 'providerPortName',
        sortable: true,
      },
      {
        Header: 'Provider Definition',
        accessor: 'type',
        sortable: true,
      },
      {
        Header: '',
        accessor: 'actionColumns',
        className: 'align-middle',
        Cell: ({ row }) => {
          return (
            <div className="text-center d-flex">
              <Link
                className="btn btn-icon btn-circle btn-default mr-2"
                to={`/settings/locations/${row.original.id}`}
              >
                <i className="fal fa-eye"></i>
              </Link>
              <Link
                className="btn btn-icon btn-circle btn-success mr-2"
                to={`/settings/locations/${row.original.id}/edit`}
              >
                <i className="fal fa-pencil-alt"></i>
              </Link>
              <button
                className="btn btn-icon btn-circle btn-danger"
                onClick={handleDeleteAlert}
              >
                <i className="fal fa-trash"></i>
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <Panel>
      <Table data={data} columns={columns} />
    </Panel>
  );
};

export default ProviderConfig;
