export const getProviders = async () => {
  const token = localStorage.getItem("token");
  const ntuId = JSON.parse(localStorage.getItem("ntu")).id;
  return fetch(
    `https://test.activeport.com.au/api/tile-configurations/by-name/IX?cacheBuster=1652341498905&ntuId=${ntuId}&profile=`,
    {
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    }
  );
};

export const getConnections = async () => {
  const token = localStorage.getItem("token");
  const ntuId = JSON.parse(localStorage.getItem("ntu")).id;
  return fetch(
    `https://test.activeport.com.au/api/_search/ix-connections?cacheBuster=1652698435482&ntuId=${ntuId}&profile=&type=IX`,
    {
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    }
  );
};

export const getConnection = (id) => {
  const token = localStorage.getItem("token");
  const baseUrl = `https://test.activeport.com.au/api/ix-connections/${id}?cacheBuster=1653038814525&profile=`;

  const requestUrl = `${baseUrl}`;
  return fetch(requestUrl, {
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getNtuPorts = async (serviceId) => {
  const token = localStorage.getItem("token");
  return fetch(
    `https://test.activeport.com.au/api/ntu-ports?cacheBuster=1653900914037&isValid=true&ntuId=18&profile=&serviceId=${serviceId}`,
    {
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    }
  );
};
