import React, { useEffect, useState } from "react";
import {
  Routes,
  Route,
  useLocation,
  Link,
  useParams,
  useNavigate,
} from "react-router-dom";
import Panel from "../../../common/Panel/Panel";
import { getAuthorityRole } from "./api/authorityRolesApi";
import { handleUpdateAlert } from "../../../common/Alerts/EditAlert";

const AuthorityRoleDetail = () => {
  const match = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [authorityRoleEntity, setAuthorityRoleEntity] = useState(null);
  const [appState, setAppState] = useState({
    loading: false,
  });

  useEffect(() => {
    setAppState({ loading: true });
    const fetchData = async () => {
      try {
        const result = await getAuthorityRole(match.id).then((res) =>
          res.json()
        );
        setAuthorityRoleEntity(result);
        console.log("AuthorityRole Detail: ", result);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    setAppState({ loading: false });
  }, [location]);

  return (
    <div>
      <div className="row">
        <div className="col-xl-12">
          {appState.loading && <p>Loading...</p>}
          {authorityRoleEntity !== null && !appState.loading && (
            <Panel heading={authorityRoleEntity.name}>
              <dl className="row">
                <dt className="text-inverse  col-4 ">
                  <span id="name">
                    <span>Name</span>
                  </span>
                </dt>
                <dd className="col-8 ">{authorityRoleEntity.name}</dd>
                <dt className="text-inverse  col-4 ">
                  <span id="command">
                    <span>Description</span>
                  </span>
                </dt>
                <dd className="col-8 ">{authorityRoleEntity.description} </dd>

                <dt className="text-inverse  col-4 ">
                  <span id="command">
                    <span>Authorities</span>
                  </span>
                </dt>
                <dd className="col-8  authorityRole-tiles-list">
                  {authorityRoleEntity.authorities.map((authority) => {
                    return (
                      <div class="btn bg-faded waves-effect waves-themed mr-2">
                        {authority}
                      </div>
                    );
                  })}
                </dd>
              </dl>

              <div>
                <button
                  className="btn btn-sm btn-secondary mr-3"
                  onClick={() => navigate("../")}
                >
                  <i className="fal fa-arrow-left fa-sm  mr-2"></i>
                  Back
                </button>
                <button
                  className="btn btn-sm btn-primary m-r-5"
                  onClick={() => navigate("../edit/" + match.id)}
                >
                  <i className="fal fa-pencil-alt fa-sm  mr-2"></i>
                  Edit
                </button>
              </div>
            </Panel>
          )}
        </div>
      </div>
    </div>
  );
};

export default AuthorityRoleDetail;
