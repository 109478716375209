import React from "react";
import { Field } from "formik";

export const Rates = () => {
  return (
    <>
      <div className="form-group row m-b-15">
        <label className="col-form-label col-md-3">Bandwidth on Demand</label>
        <div className="col-md-9">
          <Field
            type="checkbox"
            name="enableBod"
            className="form-control m-b-5"
          />
        </div>
      </div>

      <div className="form-group row m-b-15">
        <label className="col-form-label col-md-3">Max Rate Allowed</label>
        <div className="col-md-9">
          <Field type="number" name="maxRate" className="form-control m-b-5" />
        </div>
      </div>

      <div className="form-group row m-b-15">
        <label className="col-form-label col-md-3">Min Rate Allowed</label>
        <div className="col-md-9">
          <Field type="number" name="minRate" className="form-control m-b-5" />
        </div>
      </div>

      <div className="form-group row m-b-15">
        <label className="col-form-label col-md-3">Default Rate Allowed</label>
        <div className="col-md-9">
          <Field
            type="number"
            name="defaultRate"
            className="form-control m-b-5"
          />
        </div>
      </div>

      <div className="form-group row m-b-15">
        <label className="col-form-label col-md-3">Burst Time</label>
        <div className="col-md-9">
          <Field
            type="number"
            name="burstTime"
            className="form-control m-b-5"
          />
        </div>
      </div>
    </>
  );
};
