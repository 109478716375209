export const getRegions = () => {
  const token = localStorage.getItem("token");
  const baseUrl =
    "https://test.activeport.com.au/gaming-api/regions?size=99999999";

  return fetch(baseUrl, {
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getRegion = (id) => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/gaming-api/regions/";

  const requestUrl = `${baseUrl}${id}`;
  return fetch(requestUrl, {
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const createRegion = (region) => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/gaming-api/regions";

  return fetch(baseUrl, {
    method: "POST",
    body: JSON.stringify(region),
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const updateRegion = (region) => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/gaming-api/regions/";

  return fetch(baseUrl, {
    method: "PUT",
    body: JSON.stringify(region),
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const deleteRegion = (id) => {
  const token = localStorage.getItem("token");
  const baseUrl = "https://test.activeport.com.au/gaming-api/regions/";
  const requestUrl = `${baseUrl}${id}`;
  return fetch(requestUrl, {
    method: "DELETE",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};
