export const ItemCode = async (queryType, queryBody) => {
  const token = localStorage.getItem('token');
  let resourceUrl = process.env.REACT_APP_APICONFIG_MICRO + 'api/item-codes/';
  var queryFilters = {};
  switch (queryType) {
    case 'query':
      queryFilters = { method: 'GET', isArray: true };
      break;
    case 'getAllBillingItems':
      queryFilters = { method: 'GET', isArray: false };
      resourceUrl =
        process.env.REACT_APP_APICONFIG_MICRO + 'api/item-codes/all-items';
      break;
    case 'get':
      queryFilters = { method: 'GET' };
      break;
    case 'getType':
      queryFilters = { method: 'GET', isArray: false };
      resourceUrl =
        process.env.REACT_APP_APICONFIG_MICRO + 'api/item-codes/billing';
      break;
    case 'update':
      queryFilters = { method: 'PUT' };
      break;
  }
  console.log(resourceUrl);

  return await fetch(resourceUrl, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
    queryFilters,
  });
};
