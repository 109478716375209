import React, { useState, useEffect, useRef } from 'react';
import { getElasticSearchData } from './api/elasticsearch';
import Overview from './Overview';
import TopN from './TopN';
import Sankey from './Sankey';
import Threats from './Threats';
import moment from 'moment';
import tz from 'moment-timezone';

import GeoIP from './GeoIP';
import TrafficDetails from './TrafficDetails';
import Zones from './Zones';
import Firewall from './Firewall';
const ApplicationsDashboard = ({ NTU, lte, gte }) => {
  const ntu = NTU;

  const [tab, setTab] = useState('overview');

  return (
    <>
      <div className="row">
        <div className="col-12">
          <ul className="nav nav-pills nav-justified mb-4" f role="tablist">
            <li className="nav-item">
              <a
                className={`nav-link  py-3 px-5 ${
                  tab == 'overview' ? 'active' : 'inactive'
                }`}
                data-toggle="tab"
                onClick={() => setTab('overview')}
              >
                Overview
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link  py-3 px-5 ${
                  tab == 'top-n' ? 'active' : 'inactive'
                }`}
                data-toggle="tab"
                onClick={() => {
                  return setTab('top-n');
                }}
              >
                Top-N
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link  py-3 px-5 ${
                  tab == 'threats' ? 'active' : 'inactive'
                }`}
                data-toggle="tab"
                onClick={() => {
                  return setTab('threats');
                }}
              >
                Threats
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link  py-3 px-5 ${
                  tab == 'sankey' ? 'active' : 'inactive'
                }`}
                data-toggle="tab"
                onClick={() => {
                  return setTab('sankey');
                }}
              >
                Sankey
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link  py-3 px-5 ${
                  tab == 'zones' ? 'active' : 'inactive'
                }`}
                data-toggle="tab"
                onClick={() => {
                  return setTab('zones');
                }}
              >
                Zones
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link  py-3 px-5 ${
                  tab == 'geo-ip' ? 'active' : 'inactive'
                }`}
                data-toggle="tab"
                onClick={() => {
                  return setTab('geo-ip');
                }}
              >
                Geo IP
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link  py-3 px-5 ${
                  tab == 'firewall' ? 'active' : 'inactive'
                }`}
                data-toggle="tab"
                onClick={() => {
                  return setTab('firewall');
                }}
              >
                Firewall
              </a>
            </li>

            <li className="nav-item">
              <a
                className={`nav-link  py-3 px-5 ${
                  tab == 'traffic-details' ? 'active' : 'inactive'
                }`}
                data-toggle="tab"
                onClick={() => {
                  return setTab('traffic-details');
                }}
              >
                Traffic Details
              </a>
            </li>
          </ul>
        </div>
      </div>
      {tab == 'overview' && <Overview lte={lte} gte={gte} ntu={NTU} />}
      {tab == 'top-n' && <TopN lte={lte} gte={gte} ntu={NTU} />}
      {tab == 'threats' && <Threats lte={lte} gte={gte} ntu={NTU} />}
      {tab == 'zones' && <Zones lte={lte} gte={gte} ntu={ntu} />}
      {tab == 'sankey' && <Sankey lte={lte} gte={gte} ntu={NTU} />}
      {tab == 'geo-ip' && <GeoIP lte={lte} gte={gte} ntu={NTU} />}
      {tab == 'firewall' && <Firewall lte={lte} gte={gte} ntu={NTU} />}
      {tab == 'traffic-details' && (
        <TrafficDetails lte={lte} gte={gte} ntu={NTU} />
      )}
    </>
  );
};

export default ApplicationsDashboard;
