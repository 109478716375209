import React, { useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Panel from '../../../common/Panel/Panel';
import Table from '../../../common/Table';

import { handleDeleteAlert } from '../../../common/Alerts/DeleteAlert';
const NotificationEmails = ({ entities }) => {
  const data = entities;
  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        sortable: true,
        Cell: ({ row }) => {
          return (
            <div>
              <Link
                to={`/administration/notification-emails/${row.original.id}`}
              >
                {row.original.id}
              </Link>
            </div>
          );
        },
      },
      {
        Header: 'Name',
        accessor: 'name',
        sortable: true,
      },
      {
        Header: 'Org',
        accessor: () => 'ActivePort',
        sortable: true,
      },
      {
        Header: 'Tenant',
        accessor: () => 'Acurix Test',
        sortable: true,
      },
      {
        Header: 'Notification Types',
        accessor: (d) =>
          d?.notificationTypes?.map((type) => (
            <button className="btn btn-light mr-1 mb-1" key={type}>
              {type}
            </button>
          )),
        sortable: true,
      },
      {
        Header: 'Emails',
        accessor: (d) => d?.emails?.map((email) => <p key={email}>{email}</p>),
        sortable: true,
      },
      {
        Header: '',
        accessor: 'actionColumns',
        className: 'align-middle',
        Cell: ({ row }) => {
          return (
            <div class="text-center d-flex">
              <Link
                className="btn btn-icon btn-circle btn-default mr-2"
                to={`/administration/notification-email/${row.original.id}`}
              >
                <i className="fal fa-eye"></i>
              </Link>
              <Link
                className="btn btn-icon btn-circle btn-success mr-2"
                to={`/administration/notification-email/${row.original.id}/edit`}
              >
                <i className="fal fa-pencil-alt"></i>
              </Link>
              <Link
                className="btn btn-icon btn-circle btn-danger"
                to={`/administration/notification-email/${row.original.id}/delete`}
              >
                <i className="fal fa-trash"></i>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );
  return (
    <Panel>
      <Table data={data} columns={columns} />
    </Panel>
  );
};

export default NotificationEmails;
