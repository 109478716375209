import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";

import Panel from "../../../common/Panel/Panel";
import Alert from "../../../common/AlertControls/Alert";
import { handleCreateNewAlert } from "../../../common/Alerts/CreateNewAlert";
import { handleUpdateAlert } from "../../../common/Alerts/EditAlert";

import {
  createGpuServer,
  getGpuServer,
  updateGpuServer,
} from "./api/gpuServersApi";

const GpuServerUpdate = () => {
  const match = useParams();
  const navigate = useNavigate();

  let defaultValues = {
    id: "",
    bandwidth: "",
    cores: "",
    gpu: "",
    maxMemory: "",
    memory: "",
    name: "",
    threads: "",
    unit: "",
    vcpu: "",
  };

  const [initialData, setInitialData] = useState({
    id: 1,
    name: "exotic-camel",
    displayName: "PERTH GPU 1",
    ipAddress: "103.228.188.245",
    uid: "a8806a89-dd22-45d0-b9f8-03860b749b91",
    serverState: "ENABLED",
    type: "GPU_SERVER",
    publicIp: "103.228.188.246",
    regionId: 1,
    regionName: "Perth",
    availabilityZone: "ap-southeast-2",
    networkName: null,
    networkId: null,
    ifaceOut: "eno2",
    maxCpus: 32,
    maxMemory: 128000,
    request: null,
    reponse: null,
  });
  const [appState, setAppState] = useState({
    loading: true,
  });

  const isNew = !match.id;
  const [alertShow, setAlertShow] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  // let alertShow = false;

  async function createNewGpuServer(gpuServer) {
    const response = await createGpuServer(gpuServer).then((res) => res.json());
    if (response.id) {
      handleCreateNewAlert(
        'New gpuServer "' + gpuServer.name + '" added successfully!'
      );
      navigate("../");
    }
  }

  async function updateGpuServers(gpuServer) {
    const response = await updateGpuServer(gpuServer).then((res) => res.json());
    if (response.id) {
      handleUpdateAlert("gpuServer", response.id);
      navigate("../" + response.id);
    }
  }

  function submitForm(formValues) {
    isNew ? createNewGpuServer(formValues) : updateGpuServers(formValues);
  }

  // effect runs on component mount
  useEffect(() => {
    let unmounted = false;
    const fetchGpuServer = async () => {
      try {
        const result = await getGpuServer(match.id).then((res) => res.json());
        if (!unmounted) {
          console.log("result: ", result);
          if (result.description == null) result.description = "";
          setInitialData(result);
          //reset(result);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (!isNew) fetchGpuServer();
    setAppState({ loading: false });
    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-xl-12">
          {alertShow && (
            <Alert
              alertType="alert-success"
              msg="The gpuServer has been updated."
            />
          )}

          {!appState.loading && initialData && (
            <Panel heading={initialData.name}>
              <Formik
                enableReinitialize
                initialValues={initialData}
                validateOnMount
                onSubmit={submitForm}
              >
                {({ errors, touched, isValid, setFieldValue, values }) => {
                  return (
                    <Form>
                      {!isNew && (
                        <>
                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              ID
                            </label>
                            <div className="col-md-9">
                              <Field
                                type="text"
                                name="id"
                                label="id"
                                className="form-control m-b-5"
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              UID
                            </label>
                            <div className="col-md-9">
                              <Field
                                type="text"
                                name="uid"
                                className="form-control m-b-5"
                                readOnly
                              />
                            </div>
                          </div>
                        </>
                      )}

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Name <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="displayName"
                            className={`form-control m-b-5`}
                            placeholder="Enter Name"
                          />
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Hostname/ID <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="displayName"
                            className={`form-control m-b-5`}
                            placeholder="Enter Name"
                          />
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          IP Address <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="ipAddress"
                            className={`form-control m-b-5`}
                            placeholder="Enter IP Address"
                          />
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Type<span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="type"
                            className={`form-control m-b-5`}
                            placeholder="Enter Type"
                          />
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          State<span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="serverState"
                            className={`form-control m-b-5`}
                            placeholder="Enter State"
                          />
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          VMs Public IP<span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="publicIp"
                            className={`form-control m-b-5`}
                            placeholder="Enter VM Public IP"
                          />
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Max VCPU<span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="number"
                            name="maxCpus"
                            className={`form-control m-b-5`}
                            placeholder="Enter VCPU"
                          />
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Max Memory<span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="number"
                            name="maxMemory"
                            className={`form-control m-b-5`}
                            placeholder="Enter Memory"
                          />
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Iface Out <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="ifaceOut"
                            className={`form-control m-b-5`}
                            placeholder="Enter Iface Out"
                          />
                        </div>
                      </div>

                      <button
                        type="submit"
                        className="btn btn-primary btn-block btn-lg m-b-5"
                        disabled={!isValid || isSubmitted}
                      >
                        Submit
                      </button>
                    </Form>
                  );
                }}
              </Formik>
            </Panel>
          )}
        </div>
      </div>
    </div>
  );
};

export default GpuServerUpdate;
