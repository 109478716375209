export const getServiceDefinitions = () => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/service-codes';

  return fetch(baseUrl, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getServiceDefinition = (id) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/service-codes';

  const requestUrl = `${baseUrl}/${id}`;
  return fetch(requestUrl, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getServiceTypes = (id) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/service-types';

  const requestUrl = `${baseUrl}`;
  return fetch(requestUrl, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const createServiceDefinition = (ServiceDefinition) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api';

  return fetch(baseUrl, {
    method: 'POST',
    body: JSON.stringify(ServiceDefinition),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const updateServiceDefinition = (ServiceDefinition) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/service-codes';

  return fetch(baseUrl, {
    method: 'PUT',
    body: JSON.stringify(ServiceDefinition),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const deleteServiceDefinition = (id) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/service-codes';
  const requestUrl = `${baseUrl}/${id}`;
  return fetch(requestUrl, {
    method: 'DELETE',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getServiceCommandsList = () => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/service-commands/list';

  return fetch(baseUrl, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};
