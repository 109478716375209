import React, { useEffect, useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import Panel from '../../../common/Panel/Panel';
import { getServiceType } from './api/serviceTypesApi';

const ServiceTypeDetail = () => {
  const match = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [serviceTypeEntity, setServiceTypeEntity] = useState(null);
  const [appState, setAppState] = useState({
    loading: false,
  });

  useEffect(() => {
    setAppState({ loading: true });
    const fetchData = async () => {
      try {
        const result = await getServiceType(match.id).then((res) => res.json());
        setServiceTypeEntity(result);
        console.log('ServiceType Detail: ', result);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    setAppState({ loading: false });
  }, [location]);

  return (
    <div>
      <div className="row">
        <div className="col-xl-10">
          {appState.loading && <p>Loading...</p>}
          {serviceTypeEntity !== null && !appState.loading && (
            <Panel heading={serviceTypeEntity.name}>
              <dl className="row">
                <dt className="text-inverse  col-4 ">
                  <span id="command">
                    <span>Code</span>
                  </span>
                </dt>
                <dd className="col-8 ">{serviceTypeEntity.code} </dd>
                <dt className="text-inverse  col-4 ">
                  <span id="name">
                    <span>Name</span>
                  </span>
                </dt>
                <dd className="col-8 ">{serviceTypeEntity.name}</dd>
                <dt className="text-inverse  col-4 ">
                  <span id="command">
                    <span>Description</span>
                  </span>
                </dt>
                <dd className="col-8 ">{serviceTypeEntity.description} </dd>
              </dl>
              <div>
                <button
                  className="btn btn-sm btn-secondary mr-3"
                  onClick={() => navigate('../')}
                >
                  <i className="fal fa-arrow-left fa-sm  mr-2"></i>
                  Back
                </button>
                <button
                  className="btn btn-sm btn-primary m-r-5"
                  onClick={() => navigate('../edit/' + match.id)}
                >
                  <i className="fal fa-pencil-alt fa-sm  mr-2"></i>
                  Edit
                </button>
              </div>
            </Panel>
          )}
        </div>
      </div>
    </div>
  );
};

export default ServiceTypeDetail;
