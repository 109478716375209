import React, { useState, useEffect } from "react";

import { Modal } from "./Modal";

export const LabelButtonTable = ({
  title,
  tableHeads,
  fieldsData,
  tableData,
  initialValues,
  addNewRowHandler,
  deleteRowHandler,
}) => {
  const [show, setShow] = useState(false);
  const [initialValuesForModal, setInitialValuesForModal] = useState(null);

  const onHide = () => {
    setInitialValuesForModal(null);
    setShow(!show);
  };

  return (
    <>
      <div className="d-flex align-items-center mt-3">
        <p className="font-weight-bold">{title}</p>
        <button
          className="btn btn-light ml-3 mb-3"
          type="button"
          onClick={() => setShow(true)}
        >
          Add
        </button>
      </div>
      <table className="table table-striped table-bordered">
        <thead>
          <tr>
            {tableHeads.map((item, i) => (
              <td key={i}>{item}</td>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.map((item, i) => (
            <tr key={i}>
              {Object.values(item).map((val, j) => (
                <td key={j}>
                  {typeof val === "boolean" ? JSON.stringify(val) : val}
                </td>
              ))}
              <td className="d-flex justify-content-end">
                <button
                  className="btn btn-icon btn-circle btn-success mr-2"
                  onClick={() => {
                    setInitialValuesForModal({ ...item, id: i });
                    setShow(true);
                  }}
                >
                  <i className="fal fa-pencil-alt"></i>
                </button>
                <button
                  className="btn btn-icon btn-circle btn-danger"
                  onClick={() => deleteRowHandler(title, i)}
                >
                  <i className="fal fa-trash"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {show && (
        <Modal
          title={title}
          initialValues={initialValuesForModal || initialValues}
          show={show}
          onHide={onHide}
          fieldsData={fieldsData}
          addNewRowHandler={addNewRowHandler}
        />
      )}
    </>
  );
};
