import React, { useLayoutEffect, useRef, useState } from 'react';
import Panel from '../../../common/Panel/Panel';
import { Vega } from 'react-vega';

import SystemCPUPie from './graphs/system_latest_cpu_pie.json';
import SystemMemPie from './graphs/system_latest_mem_pie.json';
import SNMPSystemCPULoadLine from './graphs/snmp/snmp_system_cpu_load_line.json';
import SNMPSystemMemoryLine from './graphs/snmp/snmp_system_memory_line.json';

const Overview = ({
  NTU,
  systemStats,
  systemCPUData,
  systemMemData,
  snmpSystemCPULoadLineData,
  snmpSystemMemoryLineData,
}) => {
  const SNMPSystemCPULoadLineRef = useRef(null);
  const SNMPSystemMemoryLineRef = useRef(null);
  const [showGraphs, setShowGraphs] = useState(false);

  useLayoutEffect(() => {
    SNMPSystemCPULoadLine.width =
      SNMPSystemCPULoadLineRef.current.clientWidth - 50;
    SNMPSystemMemoryLine.width =
      SNMPSystemMemoryLineRef.current.clientWidth - 50;
    setShowGraphs(true);
  });
  console.log('Overview:', systemCPUData);
  return (
    <div>
      <div className="row">
        <div className="col-md-4 col-sm-6">
          <Panel heading="System Information">
            <div className="system-information">
              <div>
                <dl className="row">
                  <dt className="col-sm-3">Hostname</dt>
                  <dd className="col-sm-9">{NTU.name}</dd>
                  <dt className="col-sm-3">IP Address</dt>
                  <dd className="col-sm-9">{NTU.ipAddress}</dd>
                  <dt className="col-sm-3">Model</dt>
                  <dd className="col-sm-9">{NTU.ntutypeModel}</dd>
                  <dt className="col-sm-3 text-truncate">Serial Number</dt>
                  <dd className="col-sm-9">{NTU.serialNumber}</dd>
                  <dt className="col-sm-3 text-truncate">Uptime</dt>
                  <dd className="col-sm-9">{systemStats['up-time']}</dd>
                  <dt className="col-sm-3 text-truncate">Load</dt>
                  <dd className="col-sm-9">
                    {systemStats['load-average-one']}%{' '}
                    {systemStats['load-average-five']}%{' '}
                    {systemStats['load-average-fifteen']}%{' '}
                  </dd>
                  <dt className="col-sm-3 text-truncate">Temperature</dt>
                  <dd className="col-sm-9">{systemStats['Temperature']}</dd>
                </dl>
              </div>
            </div>
          </Panel>
        </div>

        <div className="col-md-4 col-sm-6 col-xs-12">
          <Panel heading="CPU Status">
            <div>
              <Vega spec={SystemCPUPie} data={systemCPUData} />
            </div>
          </Panel>
        </div>

        <div className="col-md-4 col-sm-6 col-xs-12">
          <Panel heading="System Mem Graph">
            <div>
              <Vega spec={SystemMemPie} data={systemMemData} />
            </div>
          </Panel>
        </div>

        <div className="col-sm-6 col-xs-12" ref={SNMPSystemCPULoadLineRef}>
          <Panel heading="SNMP System CPU Load Line">
            <div>
              {showGraphs && (
                <Vega
                  spec={SNMPSystemCPULoadLine}
                  data={snmpSystemCPULoadLineData}
                />
              )}
            </div>
          </Panel>
        </div>

        <div className="col-sm-6 col-xs-12" ref={SNMPSystemMemoryLineRef}>
          <Panel heading="SNMP System Memory Line">
            <div>
              {showGraphs && (
                <Vega
                  spec={SNMPSystemMemoryLine}
                  data={snmpSystemMemoryLineData}
                />
              )}
            </div>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default Overview;
