import { useContext, Fragment, useState, useEffect } from "react";
import { useNavigate, Navigate, Link } from "react-router-dom";
import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";

import SettingsContext from "../../../store/settings-context";
import { getTenantServiceTiles } from "./api/provisioningApi";
import { tileNames, tileImage } from "../../../constants/ServiceCodeEnum";
import AuthContext from "../../../store/auth-context";

import "../dashboards.scss";

const ProvisioningContainer = () => {
  const ntuId = JSON.parse(localStorage.getItem("ntu")).id;

  let navigate = useNavigate();
  const cookies = new Cookies();

  const settingsCtx = useContext(SettingsContext);
  const authCtx = useContext(AuthContext);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [tenantTiles, setTenantTiles] = useState(
    cookies.get("serviceList") ||
      (settingsCtx.serviceList || [])?.map((item) => ({
        ...item,
        path: `/dashboard/provisioning/${String(tileNames[item.serviceTypeCode])
          .replace(/\s/g, "-")
          .toLowerCase()}`,
      })) ||
      []
  );

  const [tilesData, setTilesData] = useState([]);
  const [activeTiles, setActiveTiles] = useState([]);
  const [serviceTilesCode, setServiceTilesCode] = useState([]);
  const [activeTilesCode, setActiveTilesCode] = useState([]);
  const { t } = useTranslation();
  let vxFound = true;
  const [NTU, setNTU] = useState(settingsCtx.NTU);
  const [configureOption, setConfigureOption] = useState(false);

  const accountInfo = JSON.parse(localStorage.getItem("accountInfo"));

  const handleClickOutside = (event) => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    if (settingsCtx.NTU != "") {
      setServiceTilesCode(
        tenantTiles.map((tile) => {
          return tile.serviceTypeCode;
        })
      );

      const fetcher = async () => {
        try {
          const getTiles = await getTenantServiceTiles().then((res) =>
            res.json()
          );

          setTilesData(getTiles);
          let getActiveTiles = getTiles.filter(function (tile) {
            return tile.ntuId == NTU.id;
          });
          setActiveTiles(getActiveTiles);

          setActiveTilesCode(
            getActiveTiles.map((tile) => {
              return tile.type;
            })
          );
        } catch (error) {
          console.log(error);
        }
      };
      fetcher();
    }
  }, [settingsCtx.NTU]);

  useEffect(() => {
    setTimeout(() => {
      if (isDropdownOpen) {
        window.addEventListener("click", handleClickOutside, true);
      } else {
        window.removeEventListener("click", handleClickOutside, true);
      }
    }, 0);
    return () => {};
  }, [isDropdownOpen]);

  console.log("aa", tenantTiles);

  if (settingsCtx.NTU == "") {
    return <Navigate to="/" replace />;
  } else
    return (
      <Fragment>
        <ol className="breadcrumb page-breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">ActivePort</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/dashboard/provisioning">Provisioning Dashboard</Link>
          </li>
        </ol>
        <div className="subheader">
          <h1 className="subheader-title">
            <i className="subheader-icon fal fa-globe" />
            {t("global.provisioningdashboard", "Provisioning Dashboard")}
            {settingsCtx.NTU != "" && (
              <small>Selected NTU: {settingsCtx.NTU.name}</small>
            )}
          </h1>
        </div>

        {/* Your main content goes below here: */}
        <div className="row">
          <div className="col-xl-12">
            <div id="panel-1" className="panel">
              <div className="panel-hdr">
                <h2>Settings</h2>
                <div className="panel-toolbar">
                  <button
                    className="btn btn-panel"
                    data-action="panel-collapse"
                    data-toggle="tooltip"
                    data-offset="0,10"
                    data-original-title="Collapse"
                  />
                  <button
                    className="btn btn-panel"
                    data-action="panel-fullscreen"
                    data-toggle="tooltip"
                    data-offset="0,10"
                    data-original-title="Fullscreen"
                  />
                  <button
                    className="btn btn-panel"
                    data-action="panel-close"
                    data-toggle="tooltip"
                    data-offset="0,10"
                    data-original-title="Close"
                  />
                </div>
              </div>
              <div className="panel-container show">
                <div className="panel-content">
                  <div className="row ">
                    <div className="col-xl-6">
                      <div
                        className={`alert ${
                          NTU != "" ? "border-success" : "border-danger"
                        } bg-transparent text-info fade show provision-box`}
                        role="alert"
                      >
                        <div className="d-flex align-items-center">
                          <div className="alert-icon">
                            <img
                              src={`/assets/img/ntu/${NTU.ntutypeModel}.png`}
                              alt="..."
                            />
                          </div>
                          <div className="flex-1">
                            <span className="h5 m-0 fw-700">NTU Status</span>
                          </div>

                          <div className="btn-group ">
                            <button
                              type="button"
                              className="btn btn-outline-primary rounded-circle btn-icon waves-effect waves-themed mr-2"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="true"
                              onClick={() => {
                                setIsDropdownOpen(!isDropdownOpen);
                              }}
                            >
                              <i className="fas  fa-ellipsis-h" />
                            </button>

                            <div
                              className={`dropdown-menu ${
                                isDropdownOpen ? "show" : ""
                              }`}
                              style={{
                                position: "absolute",
                                top: 35,
                                left: -86,
                                willChange: "top, left",
                              }}
                            >
                              <a
                                className={`dropdown-item ${
                                  NTU.mode == "SD_WAN" &&
                                  authCtx.hasAuth(["ROLE_USER"])
                                    ? ""
                                    : "d-none"
                                }`}
                              >
                                Network Live
                              </a>
                              <Link
                                className={`dropdown-item ${
                                  NTU.mode != "SD_WAN" &&
                                  authCtx.hasAuth(["ROLE_USER"])
                                    ? ""
                                    : "d-none"
                                }`}
                                to="/dashboard/analytics"
                              >
                                Analytics
                              </Link>
                              <Link
                                className={`dropdown-item ${
                                  NTU.mode == "SD_WAN" &&
                                  authCtx.hasAuth(["ROLE_USER"])
                                    ? ""
                                    : "d-none"
                                }`}
                                to="/dashboard/analytics"
                              >
                                Analytics
                              </Link>

                              <Link
                                className={`dropdown-item ${
                                  NTU.has_netflow != null &&
                                  NTU.has_netflow == true &&
                                  authCtx.hasAuth(["ROLE_USER"])
                                    ? ""
                                    : "d-none"
                                }`}
                                to="/dashboard/internet"
                              >
                                Internet
                              </Link>

                              <Link
                                className={`dropdown-item ${
                                  NTU.has_apptrack != null &&
                                  NTU.has_apptrack == true &&
                                  authCtx.hasAuth(["ROLE_USER"])
                                    ? ""
                                    : "d-none"
                                }`}
                                to="/dashboard/applications"
                              >
                                Applications
                              </Link>

                              <Link
                                className={`dropdown-item ${
                                  NTU.mode != "VIRTUAL" &&
                                  NTU.mode != "SD_WAN" &&
                                  authCtx.hasAuth(["ROLE_USER"])
                                    ? ""
                                    : "d-none"
                                }`}
                                to="/dashboard/syslogs"
                              >
                                Syslogs
                              </Link>
                              <a
                                className={`dropdown-item ${
                                  NTU.mode == "SD_WAN" &&
                                  authCtx.hasAuth(["ROLE_NETWORK_ADMIN"])
                                    ? ""
                                    : "d-none"
                                }`}
                              >
                                SD-WAN Setup
                              </a>

                              <Link
                                className={`dropdown-item ${
                                  NTU.deviceConfig != null && NTU.enableBod
                                    ? ""
                                    : "d-none"
                                }`}
                                to="/dashboard/provisioning/policer-schedule"
                              >
                                OnDemand
                              </Link>
                              <Link
                                className={`dropdown-item ${
                                  NTU.deviceConfig != null &&
                                  authCtx.hasAuth(["ROLE_NETWORK_ADMIN"])
                                    ? ""
                                    : "d-none"
                                }`}
                                to={`/settings/ntu/edit/${ntuId}`}
                              >
                                NTU Setup
                              </Link>
                            </div>
                          </div>
                          <button
                            type="button"
                            className={`  btn disabled ${
                              NTU != "" ? "btn-success" : "btn-danger"
                            } btn-pills btn-sm btn-w-m  mr-1 waves-effect waves-themed`}
                          >
                            {`${NTU != "" ? "Active" : "Inactive"}`}
                          </button>
                        </div>
                      </div>
                    </div>

                    {tenantTiles.map((item, index) => {
                      if (item.serviceTypeCode == "BOD") return;
                      // if (
                      //   (item.serviceTypeCode == "VXC" ||
                      //     item.serviceTypeCode == "XC") &&
                      //   vxFound
                      // ) {
                      //   vxFound = false;
                      //   return (
                      //     <div className="col-xl-6" key={index}>
                      //       <div
                      //         className={`alert ${
                      //           activeTilesCode.indexOf(item.serviceTypeCode) >
                      //           -1
                      //             ? "border-success"
                      //             : "border-danger"
                      //         } bg-transparent text-info fade show provision-box`}
                      //         role="alert"
                      //       >
                      //         <div className="d-flex align-items-center">
                      //           <div className="alert-icon">
                      //             <img
                      //               src={`/assets/img/ntu/${
                      //                 tileImage[item.serviceTypeCode]
                      //               }`}
                      //               alt="..."
                      //             />
                      //           </div>
                      //           <div className="flex-1">
                      //             <span className="h5 m-0 fw-700">
                      //               Network Connect
                      //             </span>
                      //           </div>
                      //           <button
                      //             type="button"
                      //             className={`btn btn-outline-primary
                      //            btn-pills btn-sm btn-w-m  mr-1 waves-effect waves-themed`}
                      //           >
                      //             Configure
                      //           </button>
                      //           <button
                      //             type="button"
                      //             className={`btn disabled ${
                      //               activeTilesCode.indexOf(
                      //                 item.serviceTypeCode
                      //               ) > -1
                      //                 ? "btn-success"
                      //                 : "btn-danger"
                      //             } btn-pills btn-sm btn-w-m  mr-1 waves-effect waves-themed `}
                      //           >
                      //             {`${
                      //               activeTilesCode.indexOf(
                      //                 item.serviceTypeCode
                      //               ) > -1
                      //                 ? "Active"
                      //                 : "Inactive"
                      //             }`}
                      //           </button>
                      //         </div>
                      //       </div>
                      //     </div>
                      //   );
                      // }
                      else if (
                        (item.serviceTypeCode == "VXC" ||
                          item.serviceTypeCode == "XC") &&
                        !vxFound
                      ) {
                        return;
                      }
                      return (
                        <div className="col-xl-6" key={index}>
                          <div
                            className={`alert ${
                              activeTilesCode.indexOf(item.serviceTypeCode) > -1
                                ? "border-success"
                                : "border-danger"
                            } bg-transparent text-info fade show provision-box`}
                            role="alert"
                          >
                            <div className="d-flex align-items-center">
                              <div className="alert-icon">
                                <img
                                  src={`/assets/img/ntu/${
                                    tileImage[item.serviceTypeCode]
                                  }`}
                                  alt="..."
                                />
                              </div>
                              <div className="flex-1">
                                <span className="h5 m-0 fw-700">
                                  {tileNames[item.serviceTypeCode]}
                                </span>
                              </div>
                              <Link
                                type="button"
                                className={`btn btn-outline-primary
                                 btn-pills btn-sm btn-w-m  mr-1 waves-effect waves-themed`}
                                to={item.path}
                              >
                                Configure
                              </Link>
                              <button
                                type="button"
                                className={`btn disabled ${
                                  activeTilesCode.indexOf(
                                    item.serviceTypeCode
                                  ) > -1
                                    ? "btn-success"
                                    : "btn-danger"
                                } btn-pills btn-sm btn-w-m  mr-1 waves-effect waves-themed `}
                              >
                                {`${
                                  activeTilesCode.indexOf(
                                    item.serviceTypeCode
                                  ) > -1
                                    ? "Active"
                                    : "Inactive"
                                }`}
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
};

export default ProvisioningContainer;
