import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Formik, Form, Field, yupToFormErrors } from 'formik';

import * as Yup from 'yup';
import Panel from '../../../common/Panel/Panel';
import Alert from '../../../common/AlertControls/Alert';
import Autocomplete from 'react-google-autocomplete';
import { handleCreateNewAlert } from '../../../common/Alerts/CreateNewAlert';
import { handleUpdateAlert } from '../../../common/Alerts/EditAlert';

import {
  createTenant,
  getTenant,
  updateTenant,
  getTenantTiles,
} from './api/tenantsApi';

const TenantUpdate = () => {
  const match = useParams();
  let t = [];
  const navigate = useNavigate();
  const accountInfo = JSON.parse(localStorage.getItem('accountInfo'));
  let defaultValues = {
    id: '',
    name: '',
    description: '',
    tiles: [],
    ilmDays: 90,
    slmDays: 365,
    address: {},
    zoom: '',
    orgId: accountInfo.orgId,
    gateways: null,
    spaceKey: '',
    apptrackLite: '',
    sdWan: false,
  };
  const [tenantTilesData, setTenantsTilesData] = useState(null);
  const [initialData, setInitialData] = useState(defaultValues);
  const [appState, setAppState] = useState({
    loading: true,
  });

  const isNew = !match.id;
  const [alertShow, setAlertShow] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  // let alertShow = false;

  const schema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Too Short!')
      .max(250, 'Too Long!')
      .required('Required'),
    description: Yup.string()
      .min(2, 'Too Short!')
      .max(250, 'Too Long!')
      .required('Required'),
    // tiles: Yup.array().required('Required'),
    // tenantId: Yup.string().required("Required"),
    ilmDays: Yup.number().required('Required'),
    slmDays: Yup.number().required('Required'),
    // mapCenter: Yup.string().min(2, 'Too Short!').required('Required'),
    // mapZoom: Yup.number().required('Required'),
  });

  async function createNewTenant(tenant) {
    const response = await createTenant(tenant).then((res) => res.json());
    if (response.id) {
      handleCreateNewAlert(
        'New tenant "' + tenant.name + '" added successfully!'
      );
      navigate('../');
    }
  }

  async function updateTenants(tenant) {
    const response = await updateTenant(tenant).then((res) => res.json());

    if (response.id) {
      console.log('respnise id', response.id);
      handleUpdateAlert('tenant', tenant.id);
      navigate('../' + tenant.tenantId);
    }
  }

  function submitForm(formValues) {
    if (formValues.tiles && formValues.tiles.length > 0) {
      for (let i = 0; i < formValues.tiles.length; i++) {
        tenantTilesData.map((tenantTile) => {
          if (formValues.tiles[i] == tenantTile.tileId) {
            formValues.tiles[i] = tenantTile;
          }
        });
      }

      // let tiles = tenantTilesData.filter((t) =>
      //   formValues.tiles.includes(t.tileId)
      // );
      // formValues.tiles = te;
    }
    console.log(formValues);
    isNew ? createNewTenant(formValues) : updateTenants(formValues);
  }

  // effect runs on component mount
  useEffect(() => {
    let unmounted = false;
    // simulate async api call with set timeout
    let tenantTiles;
    const fetcher = async () => {
      try {
        tenantTiles = await getTenantTiles().then((res) => res.json());
        if (!unmounted) {
          setTenantsTilesData(tenantTiles);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetcher();

    const fetchTenant = async () => {
      try {
        const result = await getTenant(match.id).then((res) => res.json());
        if (!unmounted) {
          result.tiles.map((tile) => {
            t.push(tile.tileId);
          });
          result.tiles = t;
          console.log('result: ', result);
          if (result.description == null) result.description = '';
          setInitialData(result);
          //reset(result);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (!isNew) fetchTenant();
    setAppState({ loading: false });
    return () => {
      unmounted = true;
    };
  }, []);

  const onSubmit = (data) => {
    alert(JSON.stringify(data));
  };

  return (
    <div>
      <div className="row">
        <div className="col-xl-9">
          {alertShow && (
            <Alert
              alertType="alert-success"
              msg="The tenant has been updated."
            />
          )}

          {!appState.loading && initialData && (
            <Panel heading={initialData.name}>
              <Formik
                enableReinitialize
                initialValues={initialData}
                validationSchema={schema}
                validateOnMount
                onSubmit={submitForm}
              >
                {({ errors, touched, isValid, setFieldValue, values }) => {
                  return (
                    <Form>
                      {!isNew && (
                        <div className="form-group row m-b-15">
                          <label className="col-form-label col-md-3">ID</label>
                          <div className="col-md-9">
                            <Field
                              type="text"
                              name="id"
                              label="id"
                              className="form-control m-b-5"
                              readOnly
                            />
                          </div>
                        </div>
                      )}

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Name <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="name"
                            label="name"
                            className={`form-control m-b-5  ${
                              errors.name && touched.name ? 'is-invalid' : ''
                            }`}
                            placeholder="Enter Tenant Name"
                          />
                          {errors.name && touched.name ? (
                            <div className="invalid-feedback">
                              {errors.name}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Description <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="textarea"
                            as="textarea"
                            name="description"
                            label="description"
                            className={`form-control m-b-5  ${
                              errors.description && touched.description
                                ? 'is-invalid'
                                : ''
                            }`}
                            placeholder="Enter Description"
                          />
                          {errors.description && touched.description ? (
                            <div className="invalid-feedback">
                              {errors.description}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Tiles <span className="text-danger"></span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            as="select"
                            multiple
                            name="tiles"
                            className={`form-control m-b-5  ${
                              errors.tiles && touched.tiles ? 'is-invalid' : ''
                            }`}
                          >
                            {tenantTilesData?.map((tile) => (
                              <option key={tile.tileId} value={tile.tileId}>
                                {tile.tileName}
                              </option>
                            ))}
                          </Field>
                          {errors.tiles && touched.tiles ? (
                            <div className="invalid-feedback">
                              {errors.tiles}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Data Retention - Index Lifecycle Management (Days){' '}
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="number"
                            name="ilmDays"
                            label="ilmDays"
                            className={`form-control m-b-5  ${
                              errors.ilmDays && touched.ilmDays
                                ? 'is-invalid'
                                : ''
                            }`}
                            placeholder="Enter Ilm Days"
                          />
                          {errors.ilmDays && touched.ilmDays ? (
                            <div className="invalid-feedback">
                              {errors.ilmDays}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Data Retention - Snapshot Lifecycle Management (Days){' '}
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="number"
                            name="slmDays"
                            label="slmDays"
                            className={`form-control m-b-5  ${
                              errors.slmDays && touched.slmDays
                                ? 'is-invalid'
                                : ''
                            }`}
                            placeholder="Enter Slm Days"
                          />

                          {errors.slmDays && touched.slmDays ? (
                            <div className="invalid-feedback">
                              {errors.slmDays}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Map Center <span className="text-danger"></span>
                        </label>
                        <div className="col-md-9">
                          <Autocomplete
                            className="form-control m-b-5"
                            apiKey="AIzaSyA7GB8PKnkVQCFE7LG-2BuzNM_ouNVFLCc"
                            options={{
                              types: ['address'],
                            }}
                            onPlaceSelected={(place) => {
                              let address = values.address
                                ? values.address
                                : {};
                              if (place) {
                                if (place.address_components) {
                                  if (
                                    typeof place.address_components[0] !==
                                    'undefined'
                                  ) {
                                    address.streetNumber =
                                      place.address_components[0].short_name;
                                  } else {
                                    address.streetNumber = null;
                                  }

                                  if (
                                    typeof place.address_components[1] !==
                                    'undefined'
                                  ) {
                                    address.street =
                                      place.address_components[1].short_name;
                                  } else {
                                    address.street = null;
                                  }

                                  if (
                                    typeof place.address_components[2] !==
                                    'undefined'
                                  ) {
                                    address.city =
                                      place.address_components[2].short_name;
                                  } else {
                                    address.city = null;
                                  }

                                  if (
                                    typeof place.address_components[4] !==
                                    'undefined'
                                  ) {
                                    address.state =
                                      place.address_components[4].short_name;
                                  } else {
                                    address.state = null;
                                  }

                                  if (
                                    typeof place.address_components[5] !==
                                    'undefined'
                                  ) {
                                    address.countryCode =
                                      place.address_components[5].short_name;
                                    address.country =
                                      place.address_components[5].long_name;
                                  } else {
                                    address.countryCode = null;
                                    address.country = null;
                                  }
                                }

                                address.name = place.formatted_address
                                  ? place.formatted_address
                                  : null;
                                address.latitud = place.geometry.location
                                  ? place.geometry.location.lat()
                                  : null;
                                address.longitud = place.geometry.location
                                  ? place.geometry.location.lng()
                                  : null;
                                address.placeId = place.place_id;
                                setFieldValue('address', address);
                              }
                            }}
                            defaultValue={
                              values.address && values.address.name
                                ? values.address.name
                                : null
                            }
                          />
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Map Zoom <span className="text-danger"></span>
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="number"
                            name="zoom"
                            label="mapZoom"
                            className={`form-control m-b-5  ${
                              errors.mapZoom && touched.mapZoom
                                ? 'is-invalid'
                                : ''
                            }`}
                            placeholder="Enter Map Zoom"
                          />

                          {errors.mapZoom && touched.mapZoom ? (
                            <div className="invalid-feedback">
                              {errors.mapZoom}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <button
                        type="submit"
                        className="btn btn-primary btn-block btn-lg m-b-5"
                        disabled={!isValid || isSubmitted}
                      >
                        Submit
                      </button>
                    </Form>
                  );
                }}
              </Formik>
            </Panel>
          )}
        </div>
      </div>
    </div>
  );
};

export default TenantUpdate;
