export const getTenantServiceTiles = async () => {
  const token = localStorage.getItem('token');
  let tenantId = JSON.parse(localStorage.getItem('accountInfo')).tenantId;
  let ntuID = JSON.parse(localStorage.getItem('ntu')).id;
  return fetch(
    `https://test.activeport.com.au/api/cloud-service-dto?filter=${ntuID}`,
    {
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    }
  );
};

export const getDeviceConfig = () => {
  const token = localStorage.getItem('token');
  let ntuID = JSON.parse(localStorage.getItem('ntu')).id;
  console.log('provisioning ntuID:', ntuID);
  return fetch(`https://test.activeport.com.au/api/ntus/config/${ntuID}`, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};
