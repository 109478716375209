const token = localStorage.getItem('token');
const baseUrl = 'https://test.activeport.com.au/api/notification-email/';

export const getNotificationEmails = () => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/notification-email/';

  return fetch(baseUrl, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getNotificationEmailTypes = async () => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/notification-email/';

  return fetch('https://test.activeport.com.au/api/notification-email/types', {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getEmailTemplates = () => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/notification-email/';

  return fetch('https://test.activeport.com.au/api/email-template', {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getEmailSetups = () => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/notification-email/';

  return fetch('https://test.activeport.com.au/api/email-setup', {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getEmailAddresses = () => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/notification-email/';

  return fetch('https://test.activeport.com.au/api/email-address', {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const getNotificationEmail = (id) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/notification-email/';

  const requestUrl = `${baseUrl}${id}`;
  return fetch(requestUrl, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const createNotificationEmail = (notificationEmail) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/notification-email/';

  return fetch(baseUrl, {
    method: 'POST',
    body: JSON.stringify(notificationEmail),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const updateNotificationEmail = (notificationEmail) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/notification-email/';

  return fetch(baseUrl, {
    method: 'PUT',
    body: JSON.stringify(notificationEmail),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};

export const deleteNotificationEmail = (id) => {
  const token = localStorage.getItem('token');
  const baseUrl = 'https://test.activeport.com.au/api/notification-email/';

  const requestUrl = `${baseUrl}${id}`;
  return fetch(requestUrl, {
    method: 'DELETE',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};
