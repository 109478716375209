import React, { useState } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import Button from '../Button';
Modal.setAppElement('#root');

const customStyles = {
  content: {
    display: 'block',
  },
};

/**
 * @category COMMON
 * @component
 * @description Modal dialogs for lightboxes, user notifications, or completely custom content
 * <br/>
 * <a href="https://www.gotbootstrap.com/themes/smartadmin/4.5.1/ui_panels.html">Click here to view smart admin UI Modal</a>

 */

const APModal = ({
  children,
  title,
  ismodalopen,
  onModalClose,

  cancelBtn,
}) => {
  const [isOpen, setIsOpen] = useState(ismodalopen);

  function toggleModal() {
    document.body.classList.remove('modal-open');
    setIsOpen(!isOpen);
    onModalClose();
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    document.body.classList.add('modal-open');
  }
  return (
    <>
      <Modal
        style={customStyles}
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        className="modal fade show"
        onAfterOpen={afterOpenModal}
      >
        <div
          className="modal-dialog modal-lg modal-dialog-center"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              {title != '' && <h5 className="modal-title">{title}</h5>}
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={toggleModal}
              >
                <span aria-hidden="true">
                  <i className="fal fa-times"></i>
                </span>
              </button>
            </div>
            <div className="modal-body">{children}</div>
            <div className="modal-footer">
              {cancelBtn && (
                <Button
                  onClick={toggleModal}
                  type="button"
                  data-dismiss="modal"
                  name="secondary"
                >
                  Cancel
                </Button>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

APModal.propTypes = {
  /**
   * Modal content
   */
  children: PropTypes.node.isRequired,
  /**
   * Modal Title
   */
  title: PropTypes.string,
  /**
   * Button
   */
  cta: PropTypes.string,
  /**
   * Icon
   */
  icon: PropTypes.string,
  /**
   * Cancel button ?
   */
  cancelBtn: PropTypes.bool,
};
export default APModal;
