import React, { useEffect, useState } from "react";
import { Field } from "formik";
import { getTimeZones, getTemplateConfigurations } from "../api/NTUApi";

export const Details = ({ values }) => {
  const { tenant } = JSON.parse(localStorage.getItem("accountInfo"));

  const [timeZones, setTimeZones] = useState([]);
  const [templateConfigurations, setTemplateConfigurations] = useState([]);

  useEffect(() => {
    const fetcher = async () => {
      try {
        const timeZonesRes = await getTimeZones().then((res) => res.json());
        const templateConfigurationsRes =
          await getTemplateConfigurations().then((res) => res.json());
        setTimeZones(timeZonesRes);
        setTemplateConfigurations(templateConfigurationsRes);
      } catch (error) {
        console.log(error);
      }
    };
    fetcher();
  }, []);

  return (
    <>
      <div className="form-group row m-b-15">
        <label className="col-form-label col-md-3">ID</label>
        <div className="col-md-9">
          <Field
            type="text"
            name="id"
            className="form-control m-b-5"
            readOnly
          />
        </div>
      </div>

      <div className="form-group row m-b-15">
        <label className="col-form-label col-md-3">UID</label>
        <div className="col-md-9">
          <Field
            type="text"
            name="uid"
            className="form-control m-b-5"
            readOnly
          />
        </div>
      </div>

      <div className="form-group row m-b-15">
        <label className="col-form-label col-md-3">Tenant</label>
        <div className="col-md-9">
          <Field
            type="text"
            name="tenantId"
            className="form-control m-b-5"
            readOnly
            value={tenant.tenantId === values.tenantId ? tenant?.name : ""}
          />
        </div>
      </div>
      <div className="form-group row m-b-15">
        <label className="col-form-label col-md-3">Device Location</label>
        <div className="col-md-9">
          <Field
            type="text"
            name="locationCode"
            className="form-control m-b-5"
            readOnly
          />
        </div>
      </div>
      <div className="form-group row m-b-15">
        <label className="col-form-label col-md-3">Device Provider</label>
        <div className="col-md-9">
          <Field
            type="text"
            name="serviceConfigurationProviderName"
            className="form-control m-b-5"
            readOnly
          />
        </div>
      </div>

      <div className="form-group row m-b-15">
        <label className="col-form-label col-md-3">Device Type</label>
        <div className="col-md-9">
          <Field
            type="text"
            name="ntutypeModel"
            className="form-control m-b-5"
            readOnly
          />
        </div>
      </div>

      <div className="form-group row m-b-15">
        <label className="col-form-label col-md-3">
          Mode <span className="text-danger"></span>
        </label>
        <div className="col-md-9">
          <Field as="select" name="mode" className={`form-control m-b-5`}>
            {["EDGE", "CLOUD", "SD_WAN"].map((mode, i) => (
              <option key={i} value={mode}>
                {mode}
              </option>
            ))}
          </Field>
        </div>
      </div>

      <div className="form-group row m-b-15">
        <label className="col-form-label col-md-3">Name</label>
        <div className="col-md-9">
          <Field type="text" name="name" className="form-control m-b-5" />
        </div>
      </div>

      <div className="form-group row m-b-15">
        <label className="col-form-label col-md-3">Management IP Address</label>
        <div className="col-md-9">
          <Field type="text" name="ipAddress" className="form-control m-b-5" />
        </div>
      </div>

      <div className="form-group row m-b-15">
        <label className="col-form-label col-md-3">Serial Number</label>
        <div className="col-md-9">
          <Field
            type="text"
            name="serialNumber"
            className="form-control m-b-5"
          />
        </div>
      </div>

      <div className="form-group row m-b-15">
        <label className="col-form-label col-md-3">
          TimeZone <span className="text-danger"></span>
        </label>
        <div className="col-md-9">
          <Field as="select" name="timeZone" className={`form-control m-b-5`}>
            {timeZones?.map((timeZone, i) => (
              <option key={i} value={timeZone}>
                {timeZone}
              </option>
            ))}
          </Field>
        </div>
      </div>

      <div className="form-group row m-b-15">
        <label className="col-form-label col-md-3">Location</label>
        <div className="col-md-9">
          <Field
            type="text"
            name="address.name"
            className="form-control m-b-5"
          />
        </div>
      </div>

      <div className="form-group row m-b-15">
        <label className="col-form-label col-md-3">Description</label>
        <div className="col-md-9">
          <Field
            type="text"
            name="description"
            className="form-control m-b-5"
          />
        </div>
      </div>

      <div className="form-group row m-b-15">
        <label className="col-form-label col-md-3">Firmware Version</label>
        <div className="col-md-9">
          <Field
            type="text"
            name="firmwareVersion"
            className="form-control m-b-5"
          />
        </div>
      </div>

      <div className="form-group row m-b-15">
        <label className="col-form-label col-md-3">
          Configuration Template <span className="text-danger"></span>
        </label>
        <div className="col-md-9">
          <Field
            as="select"
            name="configurationTemplateName"
            className={`form-control m-b-5`}
          >
            {templateConfigurations.map((templateConfig) => (
              <option key={templateConfig.id} value={templateConfig.name}>
                {templateConfig.name}
              </option>
            ))}
          </Field>
        </div>
      </div>
    </>
  );
};
