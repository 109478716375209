/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";

import * as Yup from "yup";
import Panel from "../../../common/Panel/Panel";
import Alert from "../../../common/AlertControls/Alert";
import { handleCreateNewAlert } from "../../../common/Alerts/CreateNewAlert";
import { handleUpdateAlert } from "../../../common/Alerts/EditAlert";
import { MyTextArea } from "../../../common/Form/FormFields";

import {
  createSDWANServer,
  getSDWANServer,
  updateSDWANServer,
} from "./api/sdwanServersApi";

const SDWANServerUpdate = () => {
  const match = useParams();
  const navigate = useNavigate();
  let defaultValues = {
    id: "",
    name: "",
    sdWanEndpoint: "",
    apiUsername: "",
    password: "",
    spaceName: "",
    spaceKey: "",
    defaultSpace: "",
    defaultBond: "",
    defaultUser: "",
    orgGroups: "",
  };
  const [initialData, setInitialData] = useState(defaultValues);
  const [appState, setAppState] = useState({
    loading: true,
  });
  const [activeTab, setActiveTab] = useState("Configuration");

  const isNew = !match.id;
  const [alertShow, setAlertShow] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  // let alertShow = false;

  const schema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(250, "Too Long!")
      .required("Required"),
    sdWanEndpoint: Yup.string()
      .min(2, "Too Short!")
      .max(250, "Too Long!")
      .required("Required"),
    apiUsername: Yup.string()
      .min(2, "Too Short!")
      .max(250, "Too Long!")
      .required("Required"),
    password: Yup.string().required("Required"),
    spaceName: Yup.string()
      .min(2, "Too Short!")
      .max(250, "Too Long!")
      .required("Required"),
    spaceKey: Yup.string()
      .min(2, "Too Short!")
      .max(250, "Too Long!")
      .required("Required"),
    defaultSpace: Yup.string().required("Required"),
    defaultBond: Yup.string().required("Required"),
    defaultUser: Yup.string().required("Required"),
    orgGroups: Yup.string().required("Required"),
  });

  async function createNewSDWANServer(sdwanServer) {
    const response = await createSDWANServer(sdwanServer).then((res) =>
      res.json()
    );
    if (response.id) {
      handleCreateNewAlert(
        'New sdwanServer "' + sdwanServer.name + '" added successfully!'
      );
      navigate("../");
    }
  }

  async function updateSDWANServers(sdwanServer) {
    const response = await updateSDWANServer(sdwanServer).then((res) =>
      res.json()
    );
    if (response.id) {
      handleUpdateAlert("sdwanServer", sdwanServer.id);
      navigate("../" + sdwanServer.id);
    }
  }

  function submitForm(formValues) {
    isNew ? createNewSDWANServer(formValues) : updateSDWANServers(formValues);
  }

  // effect runs on component mount
  useEffect(() => {
    let unmounted = false;
    // simulate async api call with set timeout
    const fetchSDWANServer = async () => {
      try {
        const result = await getSDWANServer(match.id).then((res) => res.json());
        if (!unmounted) {
          if (result.description == null) result.description = "";
          setInitialData(result);
          //reset(result);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (!isNew) fetchSDWANServer();
    setAppState({ loading: false });
    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-xl-9">
          {alertShow && (
            <Alert
              alertType="alert-success"
              msg="The sdwanServer has been updated."
            />
          )}

          {!appState.loading && initialData && (
            <Panel heading={initialData.name}>
              <ul className="nav nav-pills mb-3" role="tablist">
                {["Configuration", "Space", "Bond", "User", "Auth Groups"].map(
                  (item, i) => (
                    <li
                      key={i}
                      className="nav-item cursor-pointer"
                      onClick={() => setActiveTab(item)}
                    >
                      <a
                        className={`nav-link ${activeTab === item && "active"}`}
                        dataToggle="pill"
                      >
                        {item}
                      </a>
                    </li>
                  )
                )}
              </ul>
              <Formik
                enableReinitialize
                initialValues={initialData}
                validationSchema={schema}
                validateOnMount
                onSubmit={submitForm}
              >
                {({ errors, touched, isValid }) => {
                  let ActiveForm = null;
                  switch (activeTab) {
                    case "Configuration":
                      ActiveForm = (
                        <>
                          {!isNew && (
                            <div className="form-group row m-b-15">
                              <label className="col-form-label col-md-3">
                                ID
                              </label>
                              <div className="col-md-9">
                                <Field
                                  type="text"
                                  name="id"
                                  label="id"
                                  className="form-control m-b-5"
                                  readOnly
                                />
                              </div>
                            </div>
                          )}

                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Name <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                type="text"
                                name="name"
                                label="name"
                                className={`form-control m-b-5  ${
                                  errors.name && touched.name
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Enter NFV Profile Name"
                              />
                              {errors.name && touched.name ? (
                                <div className="invalid-feedback">
                                  {errors.name}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              SD-WAN Endpoint{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                type="text"
                                name="sdWanEndpoint"
                                label="sdWanEndpoint"
                                className={`form-control m-b-5  ${
                                  errors.sdWanEndpoint && touched.sdWanEndpoint
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Enter NFV Profile SDWanEndpoint"
                              />
                              {errors.sdWanEndpoint && touched.sdWanEndpoint ? (
                                <div className="invalid-feedback">
                                  {errors.sdWanEndpoint}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              API Username{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                type="text"
                                name="apiUsername"
                                label="apiUsername"
                                className={`form-control m-b-5  ${
                                  errors.apiUsername && touched.apiUsername
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Enter NFV Profile API Username"
                              />
                              {errors.apiUsername && touched.apiUsername ? (
                                <div className="invalid-feedback">
                                  {errors.apiUsername}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Password <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                type="password"
                                name="password"
                                label="password"
                                className={`form-control m-b-5  ${
                                  errors.password && touched.password
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Enter Password"
                              />
                              {errors.password && touched.password ? (
                                <div className="invalid-feedback">
                                  {errors.password}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Space <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                type="text"
                                name="spaceName"
                                label="spaceName"
                                className={`form-control m-b-5  ${
                                  errors.spaceName && touched.spaceName
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Enter NFV Profile Space"
                              />
                              {errors.spaceName && touched.spaceName ? (
                                <div className="invalid-feedback">
                                  {errors.spaceName}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group row m-b-15">
                            <label className="col-form-label col-md-3">
                              Space Key<span className="text-danger">*</span>
                            </label>
                            <div className="col-md-9">
                              <Field
                                type="text"
                                name="spaceKey"
                                label="spaceKey"
                                className={`form-control m-b-5  ${
                                  errors.spaceKey && touched.spaceKey
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Enter NFV Profile Space Key"
                              />
                              {errors.spaceKey && touched.spaceKey ? (
                                <div className="invalid-feedback">
                                  {errors.spaceKey}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </>
                      );
                      break;
                    case "Space":
                      ActiveForm = (
                        <MyTextArea
                          label="Default Space"
                          name="defaultSpace"
                          rows="30"
                          placeholder="Enter Default Space"
                        />
                      );
                      break;
                    case "Bond":
                      ActiveForm = (
                        <MyTextArea
                          label="Default Bond"
                          name="defaultBond"
                          rows="30"
                          placeholder="Enter Default Bond"
                        />
                      );
                      break;
                    case "User":
                      ActiveForm = (
                        <MyTextArea
                          label="Default User"
                          name="defaultUser"
                          rows="30"
                          placeholder="Enter Default User"
                        />
                      );
                      break;
                    case "Auth Groups":
                      ActiveForm = (
                        <MyTextArea
                          label="Org Groups"
                          name="orgGroups"
                          rows="30"
                          placeholder="Enter Org Groups"
                        />
                      );
                      break;
                    default:
                      break;
                  }
                  return (
                    <Form>
                      {ActiveForm}

                      <button
                        type="submit"
                        className="btn btn-primary btn-block btn-lg m-b-5"
                        disabled={!isValid || isSubmitted}
                      >
                        Submit
                      </button>
                    </Form>
                  );
                }}
              </Formik>
            </Panel>
          )}
        </div>
      </div>
    </div>
  );
};

export default SDWANServerUpdate;
