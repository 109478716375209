import React from "react";

export const ServiceStatus = () => {
  return (
    <div className="border border-1">
      <div className="d-flex justify-content-between p-2">
        <p className="font-weight-bold">Service Status</p>
      </div>
    </div>
  );
};
