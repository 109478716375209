import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";

import { getSubnetAllocation } from "../../api/NTUApi";

const initialValues = {
  scopeName: "",
  subnet: "",
  deviceName: "",
  mode: "",
  deviceId: "",
  deviceType: "",
  serialNumber: "",
  mask: "",
  subnetSize: "",
};

export const SubnetFormDialog = ({
  show,
  setShow,
  id,
  subnetKey,
  setSubnetCred,
}) => {
  const [subnetAllocation, setSubnetAllocation] = useState({});

  useEffect(() => {
    const fetcher = async () => {
      try {
        const res = await getSubnetAllocation(id, subnetKey).then((res) =>
          res.json()
        );
        setSubnetAllocation(res);
      } catch (error) {
        console.log(error);
      }
    };
    if (id && subnetKey) {
      fetcher();
    }
  }, []);

  const onHide = (e) => {
    e.preventDefault();
    setSubnetAllocation({});
    setSubnetCred({ id: "", key: "" });
    setShow(!show);
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={subnetAllocation || initialValues}
        onSubmit={(values) => {}}
      >
        {() => {
          return (
            <div
              className={`modal fade overflow-auto ${show && "show d-block"}`}
            >
              <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <div className="d-flex w-100 align-items-center justify-content-between">
                      <h2>Create or edit a Subnet Allocation</h2>
                      <button type="button" className="close" onClick={onHide}>
                        <span aria-hidden="true">
                          <i className="fal fa-times"></i>
                        </span>
                      </button>
                    </div>
                  </div>
                  <div className="modal-body">
                    <Form>
                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">Scope</label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="scopeName"
                            className="form-control m-b-5"
                            readOnly
                          />
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Subnet
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="subnet"
                            className="form-control m-b-5"
                          />
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Device Name
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="deviceName"
                            className="form-control m-b-5"
                            readOnly
                          />
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Device Mode
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="mode"
                            className="form-control m-b-5"
                            readOnly
                          />
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Device Id
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="deviceId"
                            className="form-control m-b-5"
                            readOnly
                          />
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Device Type
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="deviceType"
                            className="form-control m-b-5"
                            readOnly
                          />
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Serial Number
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="serialNumber"
                            className="form-control m-b-5"
                            readOnly
                          />
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">Mask</label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="mask"
                            className="form-control m-b-5"
                            readOnly
                          />
                        </div>
                      </div>

                      <div className="form-group row m-b-15">
                        <label className="col-form-label col-md-3">
                          Subnet Size
                        </label>
                        <div className="col-md-9">
                          <Field
                            type="text"
                            name="subnetSize"
                            className="form-control m-b-5"
                            readOnly
                          />
                        </div>
                      </div>

                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary waves-effect waves-themed"
                          onClick={onHide}
                        >
                          Close
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary waves-effect waves-themed"
                        >
                          Save
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
    </>
  );
};
