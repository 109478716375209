export const getLoginAudits = (startDate, endDate) => {
  console.log("sss = ", startDate, endDate);

  const token = localStorage.getItem("token");
  const baseUrl = `https://test.activeport.com.au/management/audits?fromDate=${startDate}&toDate=${endDate}`;
  return fetch(baseUrl, {
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};
