import React, { useEffect, useState, Fragment } from "react";
import { Routes, Route, Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { isObject } from "lodash";

import EntityAudits from "./EntityAudits";
// import EntityAuditDetail from './EntityAuditDetail';
import { getEntities, getEntityAudits } from "./api/entityAuditsApi";

const initialValues = {
  entity: "",
  limit: "25",
};

const EntityAuditsContainer = () => {
  const [entities, setEntities] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [entityId, setEntityId] = useState("");
  const [filteredEntities, setFilteredEntities] = useState([]);
  const [appState, setAppState] = useState({
    loading: false,
    err: false,
  });
  const [dropdownEntities, setDropdownEntities] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        setAppState({ err: false });
        const res = await getEntities().then((res) => res.json());
        setDropdownEntities(res);
      } catch (err) {
        setAppState({ err: true });
      }
    })();
  }, []);

  const onLoadList = async (values) => {
    const { entity, limit } = values;

    try {
      setAppState({ loading: true, err: false });
      const res = await getEntityAudits(entity, limit).then((res) =>
        res.json()
      );
      setEntities(res);
      setAppState({ loading: false });
    } catch (err) {
      setAppState({ loading: false, err: true });
    }
  };

  const applyEntityIdFilter = (filterValue) => {
    if (filterValue) {
      const updatedEntities = entities.filter(
        (entity) => entity.entityId == filterValue
      );
      setFilteredEntities(updatedEntities);
    }
  };

  const applyGlobalFilter = (filterValue) => {
    if (filterValue) {
      const updatedEntities = [];
      entities.forEach((entity) => {
        let { entityId, action, commitVersion } = entity;
        if (
          entityId.toString().includes(filterValue) ||
          action.toLowerCase().includes(filterValue.toLowerCase()) ||
          commitVersion.toString().includes(filterValue)
        ) {
          updatedEntities.push(entity);
        }
      });
      if (!updatedEntities.length) {
        entities.forEach((entity) => {
          let { entityValue } = entity;
          entityValue = JSON.parse(entityValue);
          Object.keys(entityValue)
            .filter((key) => entityValue[key] && !isObject(entityValue[key]))
            .forEach((key) => {
              if (
                entityValue[key]
                  .toString()
                  .toLowerCase()
                  .includes(filterValue.toLowerCase())
              ) {
                updatedEntities.push(entity);
              }
            });
        });
      }
      setFilteredEntities(updatedEntities);
    }
  };

  return (
    <Fragment>
      <ol className="breadcrumb page-breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">ActivePort</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to="/system">System</Link>
        </li>
        <li className="breadcrumb-item active">Entity Audits</li>
        <li className="position-absolute pos-top pos-right d-none d-sm-block">
          <span className="js-get-date"></span>
        </li>
      </ol>
      <div className="subheader">
        <h1 className="subheader-title">
          <i className="subheader-icon fal fa-table"></i> Entity Audits
        </h1>
      </div>

      <div className="bg-white p-3 mb-5">
        <h3>Filter</h3>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validateOnMount
          onSubmit={onLoadList}
        >
          {({ values }) => {
            return (
              <Form>
                <div className="form-group row m-b-15">
                  <div className="col-md-8">
                    <label className="col-form-label">Entity/Table</label>
                    <Field
                      as="select"
                      name="entity"
                      className="form-control m-b-5"
                    >
                      <option value=""></option>
                      {dropdownEntities.map((entity, i) => (
                        <option key={i} value={entity}>
                          {entity.split(".")[4]}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <div className="col-md-4">
                    <label className="col-form-label">Limit to</label>
                    <Field
                      as="select"
                      name="limit"
                      className="form-control m-b-5"
                    >
                      <option value=""></option>
                      {["25", "50", "100", "200"].map((limit, i) => (
                        <option key={i} value={limit}>
                          {limit}
                        </option>
                      ))}
                    </Field>
                  </div>
                </div>

                <div
                  className={`d-flex justify-content-${
                    entities.length > 0 ? "between" : "end"
                  } align-items-center`}
                >
                  {entities.length > 0 && (
                    <div>
                      Last <b>{values.limit}</b> Changes for{" "}
                      <b>{values.entity.split(".")[4]}</b>
                    </div>
                  )}
                  <button
                    type="submit"
                    className="w-25 btn btn-primary btn-block btn-lg m-b-5"
                  >
                    Load Change List
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>

      <div className="bg-white p-3 mb-3">
        <div className="d-flex align-items-center">
          <h5>Filter:</h5>
          <input
            className="form-control mx-3 w-25"
            placeholder="global filter"
            onChange={(e) => {
              setGlobalFilter(e.target.value);
              applyGlobalFilter(e.target.value);
            }}
          />
          <input
            className="form-control w-25"
            placeholder="entity id"
            onChange={(e) => {
              setEntityId(e.target.value);
              applyEntityIdFilter(e.target.value);
            }}
          />
        </div>
      </div>

      {!appState.loading && !appState.err && entities.length > 0 && (
        <Routes>
          {/* <Route exact path={`:id`} element={<EntityAuditDetail />} />  */}
          <Route
            path={`/`}
            element={
              <EntityAudits
                entities={
                  globalFilter || entityId ? filteredEntities : entities
                }
              />
            }
          />
        </Routes>
      )}
      {appState.loading && !appState.error && (
        <div className="border p-3">
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      )}
      {!appState.loading && appState.err && (
        <div className="alert alert-danger" role="alert">
          <strong>Oh snap!</strong> We are not able to connect to the database.
          Try again in few minutes. again.
        </div>
      )}
    </Fragment>
  );
};

export default EntityAuditsContainer;
